import { push } from 'connected-react-router';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateShowcaseFeedbackPosition } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateShowcaseFeedbackPositionMeta,
  UpdateShowcaseFeedbackPositionPayload,
} from 'types/store/UpdateShowcaseFeedbackPositionState';

import { updateShowcaseFeedbackPositionActions, getShowcaseCompanyActions } from '../actions';

function* updateShowcaseFeedbackPositionRequest(
  action: AsyncAction<UpdateShowcaseFeedbackPositionMeta, UpdateShowcaseFeedbackPositionPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateShowcaseFeedbackPosition,
        action.meta,
        state.auth.token
      );
      yield put(updateShowcaseFeedbackPositionActions.success(data));
    } catch (e: any) {
      yield put(updateShowcaseFeedbackPositionActions.failed(e.message));
    }
  }
}

function* updateShowcaseFeedbackPositionSuccess(
  action: AsyncAction<UpdateShowcaseFeedbackPositionMeta, UpdateShowcaseFeedbackPositionPayload>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {
  // yield put(updateShowcaseFeedbackPositionActions.clear());
  //   yield put(getShowcaseCompanyActions.request({}));
}

function* updateShowcaseFeedbackPositionWatcher(): any {
  yield takeLatest(updateShowcaseFeedbackPositionActions.REQUEST, updateShowcaseFeedbackPositionRequest);
  yield takeLatest(updateShowcaseFeedbackPositionActions.SUCCESS, updateShowcaseFeedbackPositionSuccess);
}

export default updateShowcaseFeedbackPositionWatcher;
