import {
  UpdateSfpStatusMeta,
  UpdateSfpStatusPayload,
} from 'types/store/UpdateSfpStatusState';
import { createAsyncReducer } from 'utils/Redux';

import { updateSfpStatusActions } from '../actions';

export default createAsyncReducer<
  UpdateSfpStatusMeta,
  UpdateSfpStatusPayload
>(updateSfpStatusActions);
