import { BulkCreateContentPackagesMeta, BulkCreateContentPackagesPayload } from 'types/store/BulkCreateContentPackages';
import { createAsyncAction } from 'utils/Redux';

const ns = 'BULK_CREATE_CONTENT_PACKAGES_NS';

const asyncAction = createAsyncAction<BulkCreateContentPackagesMeta, BulkCreateContentPackagesPayload>(ns);

const bulkCreateContentPackagesActions = { ...asyncAction };

export default bulkCreateContentPackagesActions;
