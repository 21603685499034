import {
  UpsertStoryApprovalsMeta,
  UpsertStoryApprovalsPayload,
} from 'types/store/UpsertStoryApprovalsState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertStoryApprovalsActions } from '../actions';

export default createAsyncReducer<
  UpsertStoryApprovalsMeta,
  UpsertStoryApprovalsPayload
>(upsertStoryApprovalsActions);
