import {
  GetSubtitlesMeta,
  GetSubtitlesPayload,
} from 'types/store/GetSubtitles';
import { createAsyncReducer } from 'utils/Redux';

import { getSubtitlesActions } from '../actions';

export default createAsyncReducer<GetSubtitlesMeta, GetSubtitlesPayload>(
  getSubtitlesActions,
);
