import { Reducer } from 'redux';
import { createAsyncReducer } from 'utils/Redux';
import {
  getImportFromUrlActions,
} from 'store/actions';
import { Action } from 'types/Action';
import { ScanLinkStore, GetImportFromUrlMeta, GetImportFromUrlPayload } from 'types/store/JobsState';

const initState: ScanLinkStore = {
  isLoading: false,
  isScanningLink: false,
  scannedLinkData: { status: 0, message: '', data: {} },
  scanLinkError: false,
};

export const JobsScanLinkReducer: Reducer<ScanLinkStore, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case getImportFromUrlActions.REQUEST: {
      return {
        ...state,
        isLoading: true,
        isScanningLink: true,
        notesAIError: '',
        scanLinkError: false,
      };
    }
    case getImportFromUrlActions.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isScanningLink: false,
        scannedLinkData: payload,
        scanLinkError: false,
      };
    }
    case getImportFromUrlActions.FAILED: {
      return {
        ...state,
        isLoading: false,
        isScanningLink: false,
        scannedLinkData: payload,
        scanLinkError: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default createAsyncReducer<GetImportFromUrlMeta, GetImportFromUrlPayload>(getImportFromUrlActions);
