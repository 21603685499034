/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const UncheckCircle = (props: SVGProps): JSX.Element => {
  const { width, height, fill, rotate, style } = props;
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.7559 8.08926C15.0814 7.76382 15.0814 7.23618 14.7559 6.91074C14.4305 6.58531 13.9028 6.58531 13.5774 6.91074L8.33333 12.1548L5.58926 9.41074C5.26382 9.08531 4.73618 9.08531 4.41074 9.41074C4.08531 9.73618 4.08531 10.2638 4.41074 10.5893L7.74408 13.9226C8.06952 14.248 8.59715 14.248 8.92259 13.9226L14.7559 8.08926Z"
        fill={fill || '#D9D9D9'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM18.3333 10C18.3333 14.6024 14.6024 18.3333 10 18.3333C5.39763 18.3333 1.66667 14.6024 1.66667 10C1.66667 5.39763 5.39763 1.66667 10 1.66667C14.6024 1.66667 18.3333 5.39763 18.3333 10Z"
        fill={fill || '#D9D9D9'}
      />
    </svg>
  );
};

export default UncheckCircle;
