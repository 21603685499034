import { GetContentMarketplaceMeta, GetContentMarketplacePayload } from 'types/store/GetContentMarketplace';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_CONTENT_MARKETPLACE_NS';

const asyncAction = createAsyncAction<GetContentMarketplaceMeta, GetContentMarketplacePayload>(ns);

const getContentMarketplaceActions = { ...asyncAction };

export default getContentMarketplaceActions;
