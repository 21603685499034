import {
  SendEmployerBrandingInvitationMeta,
  SendEmployerBrandingInvitationPayload,
} from 'types/store/SendEmployerBrandingInvitation';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SEND_EMPLOYER_BRANDING_INVITATION';

const asyncAction = createAsyncAction<
  SendEmployerBrandingInvitationMeta,
  SendEmployerBrandingInvitationPayload
>(ns);

const sendEmployerBrandingInvitationActions = {
  ...asyncAction,
};

export default sendEmployerBrandingInvitationActions;
