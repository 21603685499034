import { NudgeApprovalMeta, NudgeApprovalPayload } from 'types/store/NudgeApproval';
import { createAsyncAction } from 'utils/Redux';

const ns = 'NUDGE_APPROVAL_NS';

const asyncAction = createAsyncAction<NudgeApprovalMeta, NudgeApprovalPayload>(ns);

const nudgeApprovalActions = { ...asyncAction };

export default nudgeApprovalActions;
