import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getTransactionByStripeId } from 'services/transaction';
import getSavedCards from 'store/reducers/getSavedCards';
import { AsyncAction } from 'types/Action';
import {
  GetTransactionByStripeIdMeta,
  GetTransactionByStripeIdPayload
} from 'types/store/GetTransactionByStripeId';
import { Store } from 'types/store/Store';

import {
  getTransactionByStripeIdActions,
} from '../actions';

function* getTransactionByStripeIdRequest(
  action: AsyncAction<GetTransactionByStripeIdMeta, GetTransactionByStripeIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getTransactionByStripeId, action.meta, state.auth.token);
      yield put(getTransactionByStripeIdActions.success(data));
    } catch (e: any) {
      yield put(getTransactionByStripeIdActions.failed(e.message));
    }
  }
}

function* getTransactionByStripeIdSuccess(
  action: AsyncAction<GetTransactionByStripeIdMeta, GetTransactionByStripeIdPayload>
) {
  // 
}

function* getTransactionByStripeIdWatcher(): any {
  yield takeLatest(getTransactionByStripeIdActions.REQUEST, getTransactionByStripeIdRequest);
  yield takeLatest(getTransactionByStripeIdActions.SUCCESS, getTransactionByStripeIdSuccess);
}

export default getTransactionByStripeIdWatcher;
