import {
  GetTrendingByCommunityMeta,
  GetTrendingByCommunityPayload,
} from 'types/store/GetTrendingByCommunityState';
import { createAsyncReducer } from 'utils/Redux';

import { getTrendingByCommunityActions } from '../actions';

export default createAsyncReducer<
  GetTrendingByCommunityMeta,
  GetTrendingByCommunityPayload
>(getTrendingByCommunityActions);
