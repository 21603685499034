import {
    PurchaseCreditsMeta,
    PurchaseCreditsPayload
  } from 'types/store/PurchaseCredits';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { purchaseCreditsActions } from '../actions';
  
  export default createAsyncReducer<PurchaseCreditsMeta, PurchaseCreditsPayload>(
    purchaseCreditsActions
  );
  