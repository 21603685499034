import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticContentMetricsMeta {
  time: string;
  provider: string;
  communities: string[];
}

const contentMetricsNS = 'ANALYTIC_CONTENT_METRICS';

const getContentMetricsAsync = createAsyncAction<
  IAnalyticContentMetricsMeta,
  GenericResponse
>(contentMetricsNS);

export default getContentMetricsAsync;
