import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateStoryDiscussionComment } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateStoryDiscussionCommentMeta, UpdateStoryDiscussionCommentPayload } from 'types/store/UpdateStoryDiscussionComment';

import { updateStoryDiscussionCommentActions } from '../actions';

function* updateStoryDiscussionCommentRequest(action: AsyncAction<UpdateStoryDiscussionCommentMeta, UpdateStoryDiscussionCommentPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(updateStoryDiscussionComment, action.meta, state.auth.token);
      if (data.status === 200) {
        console.log(data);
      }
      yield put(updateStoryDiscussionCommentActions.success(data));
    } catch (e: any) {
      yield put(updateStoryDiscussionCommentActions.failed(e.message));
    }
  }
}

// function* updateStoryDiscussionCommentSuccess(action: AsyncAction<UpdateStoryDiscussionCommentMeta, UpdateStoryDiscussionCommentPayload>): any {
//   yield put()
// }

function* updateStoryDiscussionCommentWatcher(): any {
  yield takeLatest(updateStoryDiscussionCommentActions.REQUEST, updateStoryDiscussionCommentRequest);
  // yield takeLatest(updateStoryDiscussionCommentActions.SUCCESS, updateStoryDiscussionCommentSuccess);
}

export default updateStoryDiscussionCommentWatcher;
