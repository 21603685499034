export enum GET_PARAGRAPH_VERSION {
  GET_PARAGRAPH_VERSION_REQUEST = '@@_GET_PARAGRAPH_VERSION_REQUEST',
  GET_PARAGRAPH_VERSION_SUCCESS = '@@_GET_PARAGRAPH_VERSION_SUCCESS',
  GET_PARAGRAPH_VERSION_ERROR = '@@_GET_PARAGRAPH_VERSION_ERROR',
}

export enum LOADING {
  LOADING_REQUEST = '@@_LOADING_REQUEST',
  LOADING_CLEAR = '@@_LOADING_CLEAR',
}

export enum LOADING_INFO_DETAIL {
  LOADING_REQUEST = '@@_LOADING_INFO_DETAIL_REQUEST',
  LOADING_CLEAR = '@@_LOADING_INFO_DETAIL_CLEAR',
}

export enum OPEN_VIEW_INFO_DETAIL {
  OPEN_VIEW_INFO_DETAIL_REQUEST = '@@_OPEN_VIEW_INFO_DETAIL_REQUEST',
  OPEN_VIEW_INFO_DETAIL_CLOSE = '@@_OPEN_VIEW_INFO_DETAIL_CLOSE',
}

export enum SELECT_FILE {
  SELECT_FILE_REQUEST = '@@_SELECT_FILE_REQUEST',
  SELECT_FILE_CLEAR = '@@_SELECT_FILE_CLEAR',
}

export enum SELECT_STEP {
  SELECT_STEP_REQUEST = '@@_SELECT_STEP_REQUEST',
  BACK_STEP_REQUEST = '@@_BACK_STEP_REQUEST',
  SELECT_STEP_CLEAR = '@@_SELECT_STEP_CLEAR',
}

export enum GET_ASSET_LIBRARY {
  GET_ASSET_LIBRARY_REQUEST = '@@_GET_ASSET_LIBRARY_REQUEST',
  GET_LIST_TYPES_ASSET_LIBRARY_REQUEST = '@@_GET_LIST_TYPES_ASSET_LIBRARY_REQUEST',
  GET_ASSET_LIBRARY_SUCCESS = '@@_GET_ASSET_LIBRARY_SUCCESS',
  GET_ASSET_LIBRARY_ERROR = '@@_GET_ASSET_LIBRARY_ERROR',
}

export enum GET_ASSET_LIBRARY_FOLDER {
  GET_ASSET_LIBRARY_FOLDER_REQUEST = '@@_GET_ASSET_LIBRARY_FOLDER_REQUEST',
  GET_ASSET_LIBRARY_FOLDER_SUCCESS = '@@_GET_ASSET_LIBRARY_FOLDER_SUCCESS',
  GET_ASSET_LIBRARY_FOLDER_CLEAR = '@@_GET_ASSET_LIBRARY_FOLDER_CLEAR',
  GET_ASSET_LIBRARY_FOLDER_ERROR = '@@_GET_ASSET_LIBRARY_FOLDER_ERROR',
}

export enum GET_ASSET_INFO_DETAIL {
  GET_ASSET_INFO_DETAIL_REQUEST = '@@_GET_ASSET_INFO_DETAIL_REQUEST',
  GET_ASSET_INFO_DETAIL_SUCCESS = '@@_GET_ASSET_INFO_DETAIL_SUCCESS',
  GET_ASSET_INFO_DETAIL_CLEAR = '@@_GET_ASSET_INFO_DETAIL_CLEAR',
  GET_ASSET_INFO_DETAIL_ERROR = '@@_GET_ASSET_INFO_DETAIL_ERROR',
}

export enum SELECT_ASSET_FILE {
  SELECT_ASSET_FILE_REQUEST = '@@_SELECT_ASSET_FILE_REQUEST',
  SELECT_ASSET_FILE_DETAIL_REQUEST = '@@SELECT_ASSET_FILE_DETAIL_REQUEST',
  SELECT_ASSET_FILE_OPEN_PREVIEW_REQUEST = '@@SELECT_ASSET_FILE_OPEN_PREVIEW_REQUEST',
  SELECT_ASSET_FILE_CLEAR = '@@_SELECT_ASSET_FILE_CLEAR',
}

export enum SEARCH_ASSET_LIBRARY {
  SEARCH_ASSET_LIBRARY_REQUEST = '@@_SEARCH_ASSET_LIBRARY_REQUEST',
  SEARCH_ASSET_LIBRARY_SUCCESS = '@@SEARCH_ASSET_LIBRARY_SUCCESS',
  SEARCH_ASSET_LIBRARY_CLEAR = '@@_SEARCH_ASSET_LIBRARY_CLEAR',
}

export enum GET_TABS_SMART_UPLOADS {
  GET_TABS_SMART_UPLOADS_REQUEST = '@@_GET_TABS_SMART_UPLOADS_REQUEST',
  GET_TABS_SMART_UPLOADS_SUCCESS = '@@GET_TABS_SMART_UPLOADS_SUCCESS',
  GET_TABS_SMART_UPLOADS_CLEAR = '@@_GET_TABS_SMART_UPLOADS_CLEAR',
}

export enum SELECT_SMART_UPLOAD {
  SELECT_SMART_UPLOAD_REQUEST = '@@_SELECT_SMART_UPLOAD_REQUEST',
  SELECT_SMART_UPLOAD_CLEAR = '@@_SELECT_SMART_UPLOAD_CLEAR',
}

export enum SELECT_INNER_SMART_UPLOAD {
  SELECT_INNER_SMART_UPLOAD_REQUEST = '@@_SELECT_INNER_SMART_UPLOAD_REQUEST',
  SELECT_INNER_SMART_UPLOAD_CLEAR = '@@_SELECT_INNER_SMART_UPLOAD_CLEAR',
}

export enum SEARCH_VALUE {
  SEARCH_VALUE_REQUEST = '@@_SEARCH_VALUE_REQUEST',
  SEARCH_VALUE_CLEAR = '@@_SEARCH_VALUE_CLEAR',
}

export enum GET_IMAGES_MY_STORIES {
  GET_IMAGES_MY_STORIES_REQUEST = '@@_GET_IMAGES_MY_STORIES_REQUEST',
  GET_IMAGES_MY_STORIES_SUCCESS = '@@GET_IMAGES_MY_STORIES_SUCCESS',
  GET_IMAGES_MY_STORIES_CLEAR = '@@_GET_IMAGES_MY_STORIES_CLEAR',
  GET_IMAGES_BY_ADVOCATE_REQUEST = '@@_GET_IMAGES_BY_ADVOCATE_REQUEST',
  GET_IMAGES_BY_ADVOCATE_SUCCESS = '@@GET_IMAGES_BY_ADVOCATE_SUCCESS',
  GET_IMAGES_BY_ADVOCATE_CLEAR = '@@_GET_IMAGES_BY_ADVOCATE_CLEAR',
}

export enum EDIT_IMAGES_MY_STORIES {
  EDIT_IMAGES_MY_STORIES_REQUEST = '@@_EDIT_IMAGES_MY_STORIES_REQUEST',
  EDIT_IMAGES_MY_STORIES_CLEAR = '@@_EDIT_IMAGES_MY_STORIES_CLEAR',
}

export enum SELECTED_ADVOCATE {
  SELECTED_ADVOCATE_REQUEST = '@@_SELECTED_ADVOCATE_REQUEST',
  SELECTED_ADVOCATE_CLEAR = '@@_SELECTED_ADVOCATE_CLEAR',
}

export enum SET_LIMIT_DURATION {
  SET_LIMIT_DURATION_REQUEST = '@@_SET_LIMIT_DURATION_REQUEST',
}
