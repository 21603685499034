import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getCompanyInfoByCompanyId } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { GetCompanyInfoByCompanyIdMeta, GetCompanyInfoByCompanyIdPayload } from 'types/store/GetCompanyInfoByCompanyId';
import { Store } from 'types/store/Store';

import { getCompanyInfoByCompanyIdActions } from '../actions';

function* getCompanyInfoByCompanyIdRequest(action: AsyncAction<GetCompanyInfoByCompanyIdMeta, GetCompanyInfoByCompanyIdPayload>): any {
  try {
    const { data } = yield call(getCompanyInfoByCompanyId, action.meta);
    yield put(getCompanyInfoByCompanyIdActions.success(data));
  } catch (e: any) {
    yield put(getCompanyInfoByCompanyIdActions.failed(e.message));
  }
}

// function* getCompanyInfoByCompanyIdSuccess(action: AsyncAction<GetCompanyInfoByCompanyIdMeta, GetCompanyInfoByCompanyIdPayload>): any {
//   yield put()
// }

function* getCompanyInfoByCompanyIdWatcher(): any {
  yield takeLatest(getCompanyInfoByCompanyIdActions.REQUEST, getCompanyInfoByCompanyIdRequest);
  // yield takeLatest(getCompanyInfoByCompanyIdActions.SUCCESS, getCompanyInfoByCompanyIdSuccess);
}

export default getCompanyInfoByCompanyIdWatcher;
