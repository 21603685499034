import { GetValueCompanyTrackingContentPackageMeta, GetValueCompanyTrackingContentPackagePayload } from 'types/store/GetValueCompanyTrackingContentPackage';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_VALUE_COMPANY_TRACKING_CONTENT_PACKAGE_NS';

const asyncAction = createAsyncAction<GetValueCompanyTrackingContentPackageMeta, GetValueCompanyTrackingContentPackagePayload>(ns);

const getValueCompanyTrackingContentPackageActions = { ...asyncAction };

export default getValueCompanyTrackingContentPackageActions;
