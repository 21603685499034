import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateEmailTemplate } from 'services/email-template';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateEmailTemplateMeta, UpdateEmailTemplatePayload } from 'types/store/UpdateEmailTemplate';

import { updateEmailTemplateActions } from '../actions';

function* updateEmailTemplateRequest(action: AsyncAction<UpdateEmailTemplateMeta, UpdateEmailTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(updateEmailTemplate, action.meta, state.auth.token);
      yield put(updateEmailTemplateActions.success(data));
    } catch (e: any) {
      yield put(updateEmailTemplateActions.failed(e.message));
    }
  }
}

// function* updateEmailTemplateSuccess(action: AsyncAction<UpdateEmailTemplateMeta, UpdateEmailTemplatePayload>): any {
//   yield put()
// }

function* updateEmailTemplateWatcher(): any {
  yield takeLatest(updateEmailTemplateActions.REQUEST, updateEmailTemplateRequest);
  // yield takeLatest(updateEmailTemplateActions.SUCCESS, updateEmailTemplateSuccess);
}

export default updateEmailTemplateWatcher;
