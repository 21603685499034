import {
  CreateStoryMeta,
  CreateStoryPayload,
} from 'types/store/CreateStoryState';
import { createAsyncReducer } from 'utils/Redux';

import { createStoryActions } from '../actions';

export default createAsyncReducer<CreateStoryMeta, CreateStoryPayload>(
  createStoryActions
);
