import React from 'react';

import TextArea from 'components/base/TextArea';
import { useField } from 'formik';

import { FormikTextAreaProps } from './FormikTextArea.props';

const FormikTextArea = (props: FormikTextAreaProps): JSX.Element => {
  const { name, mt, ...textFieldProps } = props;
  const [field, meta] = useField<string>(name);

  return (
    <TextArea
      {...field}
      {...textFieldProps}
      id={name}
      error={meta.touched ? meta.error : undefined}
      mt={mt}
    />
  );
};

export default React.memo(FormikTextArea);
