import { StoryEditorState } from 'types/store/StoryEditorState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'STORY_EDITOR';
const updateAction = createUpdateAction<StoryEditorState>(ns);
const clearAction = createClearAction(ns);

const storyEditorActions = {
  ...updateAction,
  ...clearAction,
};

export default storyEditorActions;
