import axios from 'axios';
import { API } from 'consts';
import { IUpdateTagsMeta } from 'store/actions/library/updateTags';
import { ICreateTagsMeta } from 'store/actions/library/createTags';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const TAG_URL = `${BASE_URL}/tag`;

export const updateTags = (data: IUpdateTagsMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${TAG_URL}/set-tags`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const createTags = (data: ICreateTagsMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${TAG_URL}/create-tags`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getTags = (token: string): any => {
  return axios({
    method: 'get',
    url: `${TAG_URL}/get-list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
