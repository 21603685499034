import Typography from 'components/base/Typography';
import FormikTextArea from 'components/module/FormikTextArea';
import FormikTextField from 'components/module/FormikTextField';
import styled from 'utils/styled';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px 32px;
  margin-top: 8px;
  margin-bottom: 8px;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;

  border: 1px solid ${({ theme }) => theme.grey.shade4};

  textarea {
    resize: none;
    padding: 8px 12px;
  }
`;

export const Title = styled(Typography)`
  padding-bottom: 8px;
  border-bottom: 1px solid #f0ecee;
  width: 100%;
`;

export const FormContainer = styled.div`
  width: 100%;
`;

export const FieldContainer = styled.div<{
  marginTop?: number;
  optional?: boolean;
}>`
  margin-top: ${({ marginTop }) => marginTop || 24}px;
  width: 100%;
  position: relative;

  ${({ optional, theme }) =>
    optional
      ? `
        &:after {
            content: 'optional';
            font-family: 'Objective';
            color: ${theme.product.information.primary};
            font-size: 12px;
            line-height: 16px;
            position: absolute;
            right: 0;
            top: 0;
        }
    `
      : null}
`;

export const TextField = styled(FormikTextField)`
  width: 100%;
`;

export const Label = styled(Typography)`
  font-weight: 700;
  text-transform: uppercase;
`;

export const Textarea = styled(FormikTextArea)``;

export const DropdownContainer = styled.div`
  & > div:first-of-type {
    margin-top: 4px;
  }
`;

export const AttachFileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 8px;
`;

export const AllowFileText = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;
  color: ${({ theme }) => theme.grey.shade6};
`;

export const SelectedFileName = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  margin: 0 4px 0 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.product.information.primary};
`;

export const SelectedFileContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

export const CloseButton = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 6px 12px 0px #B5ADB033;
  margin-left: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Description = styled(Typography)`
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  color: ${({ theme }) => theme.grey.shade6};
`;

export const ActionButton = styled.div`
  display: flex;
  algin-items: center;
  justify-content: space-between;
  margin-top: 36px;
`;

export const ButtonContainer = styled.div`
  width: calc(50% - 12px);

  button {
    width: 100%;
  }
`;

export const ErrorMessage = styled(Typography)`
  font-size: 0.75rem;
  line-height: 16px;
  font-family: 'Objective';
  font-weight: 500;
  color: #FF7991;
  text-align: left;
  margin: 0;
  margin-top: 4px;
`;

export const TooltipText = styled(Typography)`
  color: ${({ theme }) => theme.neutral.white};
`;

export const BorderedContainer = styled.div`
  border: 1px solid #B5ADB0;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 24px;
  max-height: 476px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const Tag = styled.button`
  padding: 6px 8px;
  box-shadow: 0px 6px 12px rgba(181, 173, 176, 0.2);
  border-radius: 4px;
  background: ${({ theme }) => theme.brand.dove};
  border: transparent;
  display: flex;
  margin-bottom: 16px;

  svg {
    margin-top: 1px;
  }
`;

export const TagText = styled(Typography)`
  color: ${({ theme }) => theme.brand.primary};
  margin-left: 7px;
`;