import React, { useState, useRef } from 'react';

import { createPortal } from 'react-dom';

import { ToolTipProps } from './ToolTip.props';
import { Container, Content, ToolTipArrow } from './ToolTip.style';

const ToolTip = (props: ToolTipProps): JSX.Element => {
  const {
    active: isActive = false,
    content,
    children,
    width = 'max-content',
    disabled,
    maxWidth,
    maxHeight,
    background,
    position,
    onClick,
    showOnClick,
    hoverColor,
    textColor,
    style,
    horizontalPos,
    borderRadius,
    textAlign,
  } = props;

  const [active, setActive] = useState(isActive);
  const [direction, setDirection] = useState({
    horizontal: horizontalPos || 'left',
    vertical: 'bottom',
  });
  const childRef: React.RefObject<any> = useRef();

  const checkPosition = (coordinates: any) => {
    if (coordinates) {
      const { width, height, bottom, right, left, top } = coordinates;
      const passMiddleVerticalScreen =
        bottom - height / 2 > window.innerHeight / 2;
      const passMiddleHorizontalScreen =
        right - width / 2 > window.innerWidth / 2;
      const getHorizontal = horizontalPos
        ? horizontalPos
        : passMiddleHorizontalScreen
        ? 'right'
        : 'left';
      const getVertical =
        position || passMiddleVerticalScreen ? 'top' : 'bottom';
      const direction = {
        horizontal: getHorizontal,
        vertical: getVertical,
        ...(passMiddleVerticalScreen || position === 'top'
          ? { bottom: `${window.innerHeight - top + 16}px`, top: 'unset' }
          : { top: `${bottom + 16}px`, bottom: 'unset' }),
        //@ts-ignore
        ...(passMiddleHorizontalScreen && horizontalPos !== 'left'
          ? { left: 'unset', right: `${window.innerWidth - right - 20}px` }
          : { left: `${left - 20}px`, right: 'unset' }),
      };
      setDirection(direction);
    }
  };

  const showTip = () => {
    if (childRef.current && childRef.current?.getBoundingClientRect) {
      checkPosition(childRef.current?.getBoundingClientRect());
    }
    if (!disabled && !isActive) setActive(true);
  };

  const hideTip = () => {
    if (!disabled && !isActive) setActive(false);
  };

  const onClickButton = () => {
    if (active) {
      showTip();
    } else {
      hideTip();
    }
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Container
        onMouseEnter={!showOnClick ? showTip : undefined}
        onMouseLeave={!showOnClick ? hideTip : undefined}
        onClick={onClickButton}
        ref={childRef}
        hoverColor={hoverColor}
        disabled={disabled}
        style={style}
      >
        {React.Children.map(children, (child: any) => {
          return React.cloneElement(child);
        })}
      </Container>
      {active &&
        content &&
        createPortal(
          <ToolTipArrow
            direction={direction}
            maxWidth={maxWidth}
            maxHeight={maxHeight}
            style={{
              width,
              borderRadius: borderRadius,
            }}
            background={background}
          >
            {typeof content === 'object' ? (
              content
            ) : (
              <Content
                altVariant="label"
                altColor={textColor || 'noshade'}
                weight="400"
                textAlign={textAlign}
              >
                {content}
              </Content>
            )}
          </ToolTipArrow>,
          document.body,
        )}
    </>
  );
};

export default ToolTip;
