import {
  UpsertShowcaseArticleMeta,
  UpsertShowcaseArticlePayload,
} from 'types/store/UpsertShowcaseArticleState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertShowcaseArticleActions } from '../actions';

export default createAsyncReducer<
  UpsertShowcaseArticleMeta,
  UpsertShowcaseArticlePayload
>(upsertShowcaseArticleActions);
