import {
  GetCategoriesMeta,
  GetCategoriesPayload,
} from 'types/store/GetCategories';
import { createAsyncReducer } from 'utils/Redux';

import { getCategoriesActions } from '../actions';

export default createAsyncReducer<GetCategoriesMeta, GetCategoriesPayload>(
  getCategoriesActions
);
