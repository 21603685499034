import {
  AddGetSupportNotificationStateMeta,
  AddGetSupportNotificationStatePayload,
} from 'types/store/AddGetSupportNotificationState';
import { createAsyncReducer } from 'utils/Redux';

import { addGetSupportNotificationActions } from '../actions';

export default createAsyncReducer<
  AddGetSupportNotificationStateMeta,
  AddGetSupportNotificationStatePayload
>(addGetSupportNotificationActions);
