import React from 'react'

import { Action } from 'types/Action'


export enum TOAST {
  TOAST_OPEN = '@@_TOAST_OPEN',
  TOAST_CLOSE = '@@_TOAST_CLOSE'
}

export type TypeToast = 'error' | 'success' | 'information' | 'alert' | 'warning'

export enum FLOAT_MESSAGE {
  FLOAT_MESSAGE_OPEN = '@@_FLOAT_MESSAGE_OPEN',
  FLOAT_MESSAGE_CLOSE = '@@_FLOAT_MESSAGE_CLOSE'
}

export const openToast = (message: string, type: TypeToast, headline?: string): Action => ({
  type: TOAST.TOAST_OPEN,
  payload: { message, type, headline }
})

export const closeToast = (): Action => ({
  type: TOAST.TOAST_CLOSE,
  payload: {}
})

export const showFloatMessage = (payload: { message?: string, delay?: number }): Action => ({
  type: FLOAT_MESSAGE.FLOAT_MESSAGE_OPEN,
  payload
})

export const closeFloatMessage = (): Action => ({
  type: FLOAT_MESSAGE.FLOAT_MESSAGE_CLOSE,
  payload: {}
})
