import { push } from 'connected-react-router';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateShowcaseArticlePosition } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateShowcaseArticlePositionMeta,
  UpdateShowcaseArticlePositionPayload,
} from 'types/store/UpdateShowcaseArticlePositionState';

import { updateShowcaseArticlePositionActions, getShowcaseCompanyActions } from '../actions';

function* updateShowcaseArticlePositionRequest(
  action: AsyncAction<UpdateShowcaseArticlePositionMeta, UpdateShowcaseArticlePositionPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateShowcaseArticlePosition,
        action.meta,
        state.auth.token
      );
      yield put(updateShowcaseArticlePositionActions.success(data));
    } catch (e: any) {
      yield put(updateShowcaseArticlePositionActions.failed(e.message));
    }
  }
}

function* updateShowcaseArticlePositionSuccess(
  action: AsyncAction<UpdateShowcaseArticlePositionMeta, UpdateShowcaseArticlePositionPayload>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
) {
  // yield put(updateShowcaseArticlePositionActions.clear());
  //   yield put(getShowcaseCompanyActions.request({}));
}

function* updateShowcaseArticlePositionWatcher(): any {
  yield takeLatest(updateShowcaseArticlePositionActions.REQUEST, updateShowcaseArticlePositionRequest);
  yield takeLatest(updateShowcaseArticlePositionActions.SUCCESS, updateShowcaseArticlePositionSuccess);
}

export default updateShowcaseArticlePositionWatcher;
