import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fetchEmployeeAdvocacy } from 'services/analytics';
import { analyticEmployeeAdvocacyActions } from 'store/actions';
import { IAnalyticEmployeeAdvocacyMeta } from 'store/actions/analytics/ROI/employeeAdvocacy';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getEmployeeAdvocacy(
  action: AsyncAction<IAnalyticEmployeeAdvocacyMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchEmployeeAdvocacy,
        action.meta,
        state.auth.token,
      );
      yield put(analyticEmployeeAdvocacyActions.success(data));
    } catch (e: any) {
      yield put(analyticEmployeeAdvocacyActions.failed(e.message));
    }
  }
}

function* analyticWatcher(): any {
  yield takeLatest(
    analyticEmployeeAdvocacyActions.REQUEST,
    getEmployeeAdvocacy,
  );
}

export default analyticWatcher;
