import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAsset } from 'services/homepage';
import { AsyncAction } from 'types/Action';
import {
    GetAssetStateMeta,
    GetAssetStatePayload,
} from 'types/store/getAssetState';
import { Store } from 'types/store/Store';

import { getAssetActions } from '../actions';

function* getAssetRequest(
    action: AsyncAction<GetAssetStateMeta, GetAssetStatePayload>
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            const { data } = yield call(getAsset, action.meta, state.auth.token);
            yield put(getAssetActions.success(data));
        } catch (e: any) {
            yield put(getAssetActions.failed(e.message));
        }
    } else {
        yield put(getAssetActions.failed('Token not found'));
    }
}

function* getAssetSuccess(
    action: AsyncAction<GetAssetStateMeta, GetAssetStatePayload>
) {
    // yield put(getAssetActions.clear());
}

function* getAssetWatcher(): any {
    yield takeLatest(
        getAssetActions.REQUEST,
        getAssetRequest
    );
    yield takeLatest(
        getAssetActions.SUCCESS,
        getAssetSuccess
    );
}

export default getAssetWatcher;
