import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { CreateAppointmentMeta } from 'types/store/CreateAppointment';
import { CreateAppointmentByAdvocateMeta } from 'types/store/CreateAppointmentByAdvocate';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const APPOINTMENT_URL = `${BASE_URL}/appointment`;

export const createAppointment = (
  data: CreateAppointmentMeta,
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${APPOINTMENT_URL}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const createAppointmentByAdvocate = (
  data: CreateAppointmentByAdvocateMeta,
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${APPOINTMENT_URL}/createByAdvocate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getCompanyCalendlyLink = (
  token: string
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${APPOINTMENT_URL}/getCompanyCalendlyLink`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};