import { DeleteInvitedTeamMeta, DeleteInvitedTeamPayload } from 'types/store/DeleteInvitedTeam';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_INVITED_TEAM_NS';

const asyncAction = createAsyncAction<DeleteInvitedTeamMeta, DeleteInvitedTeamPayload>(ns);

const deleteInvitedTeamActions = { ...asyncAction };

export default deleteInvitedTeamActions;
