import {
    GetAssetStatePayload,
  } from 'types/store/getAssetState';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { getAssetActions } from '../actions';
  
  export default createAsyncReducer<
    null,
    GetAssetStatePayload
  >(getAssetActions);
  