import {
  AdvocateBulkCallMeta,
  AdvocateBulkCallPayload,
} from 'types/store/AdvocateBulkCall';
import { createAsyncReducer } from 'utils/Redux';

import { advocateBulkCallActions } from '../actions';

export default createAsyncReducer<
  AdvocateBulkCallMeta,
  AdvocateBulkCallPayload
>(advocateBulkCallActions);
