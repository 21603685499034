import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';
export const queryClient = new QueryClient();

queryClient.setDefaultOptions({
  queries: {
    onError(error: any) {
      if (error?.response?.data?.status === 401) return;
      console.error(error?.response?.data, 'queryError');
      // toast.error(error?.response?.data?.message || 'Something went wrong', {
      //   position: 'top-right',
      //   autoClose: 5000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   theme: 'light',
      // });
    },
  },
  mutations: {
    onError(error: any, variables, context) {
      if (error?.response?.data?.status === 401) return;
      console.error(error?.response?.data, 'mutationError');
      toast.error(error?.response?.data?.message || 'Action performed failed', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
    },
  },
});
