import { CreateDiscussStoryMeta, CreateDiscussStoryPayload } from 'types/store/CreateDiscussStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_DISCUSS_STORY_NS';

const asyncAction = createAsyncAction<CreateDiscussStoryMeta, CreateDiscussStoryPayload>(ns);

const createDiscussStoryActions = { ...asyncAction };

export default createDiscussStoryActions;
