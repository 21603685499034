import { createValidator } from 'utils/Validation';

const constraints = {
    topic: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    content_length: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    content_language: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    tone_of_voice: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    target_audience: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    //
    intent: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    key_points: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    publish_place: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    // brand_guilines: {
    //     presence: {
    //         message: '^Please enter target audience',
    //         allowEmpty: false,
    //     },
    // },
    // estimate_publish_tine: '',
    // call_to_action: '',
    // suggested_keywords: '',
    // additional_instructions: '',
    // content_examples: ''
}

export const validate = createValidator(constraints);
