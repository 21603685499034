import { Action } from 'types/Action';

export enum GET_WORKSPACES_TAG {
  REQUEST = 'GET_WORKSPACES_TAG_REQUEST',
  SUCCESS = 'GET_WORKSPACES_TAG_SUCCESS',
  EROR = 'GET_WORKSPACES_TAG_EROR',
}

export enum CREATE_WORKSPACES_TAG {
  REQUEST = 'CREATE_WORKSPACES_TAG_REQUEST',
  SUCCESS = 'CREATE_WORKSPACES_TAG_SUCCESS',
  EROR = 'CREATE_WORKSPACES_TAG_EROR',
}

export const getWorkspacesTag = (): Action => ({
  type: GET_WORKSPACES_TAG.REQUEST,
  payload: {},
});

export const createWorkspacesTags = (
  payload: { name: string; id?: string }[],
): Action => ({
  type: CREATE_WORKSPACES_TAG.REQUEST,
  payload,
});
