import { GetAdvocateDetailsMeta, GetAdvocateDetailsPayload } from 'types/store/GetAdvocateDetails';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATE_DETAILS_NS';

const asyncAction = createAsyncAction<GetAdvocateDetailsMeta, GetAdvocateDetailsPayload>(ns);

const getAdvocateDetailsActions = { ...asyncAction };

export default getAdvocateDetailsActions;
