/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconImage = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6673 2.66669H3.33398C2.80355 2.66669 2.29484 2.8774 1.91977 3.25247C1.5447 3.62755 1.33398 4.13625 1.33398 4.66669V11.3334C1.33398 11.8638 1.5447 12.3725 1.91977 12.7476C2.29484 13.1226 2.80355 13.3334 3.33398 13.3334H12.6673C13.1978 13.3334 13.7065 13.1226 14.0815 12.7476C14.4566 12.3725 14.6673 11.8638 14.6673 11.3334V4.66669C14.6673 4.13625 14.4566 3.62755 14.0815 3.25247C13.7065 2.8774 13.1978 2.66669 12.6673 2.66669ZM3.33398 12C3.15717 12 2.9876 11.9298 2.86258 11.8048C2.73756 11.6797 2.66732 11.5102 2.66732 11.3334V9.72002L4.86732 7.52669C4.99194 7.40454 5.15948 7.33612 5.33398 7.33612C5.50849 7.33612 5.67603 7.40454 5.80065 7.52669L10.274 12H3.33398ZM13.334 11.3334C13.334 11.5102 13.2637 11.6797 13.1387 11.8048C13.0137 11.9298 12.8441 12 12.6673 12H12.154L9.61398 9.44669L10.2007 8.86002C10.3253 8.73787 10.4928 8.66945 10.6673 8.66945C10.8418 8.66945 11.0094 8.73787 11.134 8.86002L13.334 11.0534V11.3334ZM13.334 9.17335L12.0807 7.92669C11.7007 7.56164 11.1942 7.35777 10.6673 7.35777C10.1404 7.35777 9.63394 7.56164 9.25398 7.92669L8.66732 8.51335L6.74732 6.59335C6.36736 6.22831 5.86089 6.02444 5.33398 6.02444C4.80708 6.02444 4.30061 6.22831 3.92065 6.59335L2.66732 7.84002V4.66669C2.66732 4.48988 2.73756 4.32031 2.86258 4.19528C2.9876 4.07026 3.15717 4.00002 3.33398 4.00002H12.6673C12.8441 4.00002 13.0137 4.07026 13.1387 4.19528C13.2637 4.32031 13.334 4.48988 13.334 4.66669V9.17335Z"
        fill={fill || '#0D729D'}
      />
    </svg>
  );
};

export default IconImage;
