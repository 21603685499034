import React from 'react';

import { SVGProps } from './SVG.props';

const Code = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 26 18"
      fill={fill || 'none'}
      {...props}
    >
      <path d="M9.94662 1.38659C9.82266 1.26161 9.6752 1.16242 9.51272 1.09473C9.35024 1.02704 9.17596 0.992188 8.99995 0.992188C8.82393 0.992188 8.64965 1.02704 8.48717 1.09473C8.3247 1.16242 8.17723 1.26161 8.05328 1.38659L1.38659 8.05325C1.26161 8.1772 1.16242 8.32467 1.09473 8.48715C1.02704 8.64963 0.992188 8.8239 0.992188 8.99992C0.992187 9.17593 1.02704 9.35021 1.09473 9.51269C1.16242 9.67516 1.26161 9.82263 1.38659 9.94658L8.05328 16.6132C8.17723 16.7382 8.3247 16.8374 8.48717 16.9051C8.64965 16.9728 8.82393 17.0076 8.99995 17.0076C9.17596 17.0076 9.35024 16.9728 9.51272 16.9051C9.6752 16.8374 9.82266 16.7382 9.94662 16.6132C10.0716 16.4893 10.1708 16.3418 10.2385 16.1794C10.3062 16.0169 10.341 15.8426 10.341 15.6666C10.341 15.4906 10.3062 15.3163 10.2385 15.1538C10.1708 14.9913 10.0716 14.8439 9.94662 14.7199L4.21326 8.99992L9.94662 3.27992C10.0716 3.15597 10.1708 3.0085 10.2385 2.84602C10.3062 2.68354 10.341 2.50927 10.341 2.33325C10.341 2.15724 10.3062 1.98296 10.2385 1.82048C10.1708 1.658 10.0716 1.51054 9.94662 1.38659ZM24.6133 8.05325L17.9466 1.38659C17.8223 1.26227 17.6747 1.16365 17.5123 1.09637C17.3499 1.02909 17.1758 0.994463 17 0.994463C16.6449 0.994463 16.3044 1.13551 16.0533 1.38659C15.929 1.5109 15.8304 1.65849 15.7631 1.82092C15.6958 1.98335 15.6612 2.15744 15.6612 2.33325C15.6612 2.68832 15.8022 3.02885 16.0533 3.27992L21.7867 8.99992L16.0533 14.7199C15.9283 14.8439 15.8291 14.9913 15.7614 15.1538C15.6938 15.3163 15.6589 15.4906 15.6589 15.6666C15.6589 15.8426 15.6938 16.0169 15.7614 16.1794C15.8291 16.3418 15.9283 16.4893 16.0533 16.6132C16.1773 16.7382 16.3247 16.8374 16.4872 16.9051C16.6497 16.9728 16.824 17.0076 17 17.0076C17.176 17.0076 17.3503 16.9728 17.5127 16.9051C17.6752 16.8374 17.8227 16.7382 17.9466 16.6132L24.6133 9.94658C24.7383 9.82263 24.8375 9.67516 24.9052 9.51269C24.9729 9.35021 25.0077 9.17593 25.0077 8.99992C25.0077 8.8239 24.9729 8.64963 24.9052 8.48715C24.8375 8.32467 24.7383 8.1772 24.6133 8.05325Z" fill="#070550" />
    </svg>

  );
};

export default React.memo(Code);
