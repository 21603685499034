import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import {
  StoryEditorState,
  StoryEditorPayload,
} from 'types/store/StoryEditorState';

import { storyEditorActions } from '../actions';

const DEFAULT_STATE: StoryEditorState = {
  page: 1,
  pathList: ['Stories', 'Story Workspace'],
  paid: false,
  showUpselling: false,
  ticked: false,
  fileInFocus: undefined,
  editedFinalWrittenStory: '',
  currentTab: 'Raw Responses',
  showPaidOnly: undefined,
  payload: {
    currentPage: 1,
    
  }
};

const DEFAULT_ACTION: Action<StoryEditorPayload> = {
  type: '',
  payload: {
    storyId: '',
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  const localReducer = (): StoryEditorPayload => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [storyEditorActions.UPDATE]: localReducer(),
    [storyEditorActions.CLEAR]: DEFAULT_STATE,
  });
};
