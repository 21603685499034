import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStripeCustomerId, purchaseCredits, getSavedCards, chargeCard } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  PurchaseCreditsMeta,
  PurchaseCreditsPayload
} from 'types/store/PurchaseCredits';
import { Store } from 'types/store/Store';

import {
  purchaseCreditsActions,
  chargeCardActions,
  getStripeCustomerIdActions,
  getSavedCardsActions,
  getCompanyCreditsActions
} from '../actions';

function* purchaseCreditsRequest(
  action: AsyncAction<PurchaseCreditsMeta, PurchaseCreditsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(purchaseCredits, action.meta, state.auth.token);
      yield put(purchaseCreditsActions.success(data));
    } catch (e: any) {
      yield put(purchaseCreditsActions.failed(e.message));
    }
  }
}

function* purchaseCreditsSuccess(
  action: AsyncAction<PurchaseCreditsMeta, PurchaseCreditsPayload>
) {
  // 
}

function* purchaseCreditsWatcher(): any {
  yield takeLatest(purchaseCreditsActions.REQUEST, purchaseCreditsRequest);
  yield takeLatest(purchaseCreditsActions.SUCCESS, purchaseCreditsSuccess);
}

export default purchaseCreditsWatcher;
