import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowLeft = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 62.5}
      height={height || 40}
      viewBox="0 0 44 28"
      fill={fill || 'none'}
      {...props}
    >
      <path
        d="M24.923 21.103c-.043.034-1.098.934-2.82.934-1.723 0-2.777-.891-2.82-.934l.008.008-1.723 1.903c.18.163 1.835 1.586 4.535 1.586s4.354-1.423 4.534-1.586l-1.723-1.903.009-.008zM32.843 10.929c-2.7 0-4.355 1.422-4.535 1.585l1.723 1.903-.008.009c.042-.035 1.097-.935 2.82-.935 1.722 0 2.777.892 2.82.935l-.01-.009 1.724-1.903c-.18-.163-1.835-1.585-4.535-1.585zM20.585 12.429A9.423 9.423 0 0011.157 3a9.423 9.423 0 00-9.428 9.429 9.423 9.423 0 009.428 9.428 9.423 9.423 0 009.428-9.428zm-9.428 6.857c-3.78 0-6.857-3.077-6.857-6.857 0-3.78 3.077-6.858 6.857-6.858 3.78 0 6.857 3.078 6.857 6.858s-3.077 6.857-6.857 6.857z"
        fill="#91DBB6"
      />
      <path
        d="M11.328 15.206a2.571 2.571 0 100-5.143 2.571 2.571 0 000 5.143zM42.27 12.429A9.423 9.423 0 0032.843 3a9.423 9.423 0 00-9.428 9.429 9.423 9.423 0 009.428 9.428 9.256 9.256 0 003.283-.591 12.56 12.56 0 01-5.485 3.257L31.334 27c3.086-.866 5.871-2.76 7.834-5.314a14.86 14.86 0 003.103-9.137v-.12zm-16.285 0c0-3.78 3.078-6.858 6.858-6.858s6.856 3.078 6.856 6.858-3.077 6.857-6.856 6.857c-3.78 0-6.858-3.077-6.858-6.857z"
        fill="#91DBB6"
      />
    </svg>
  );
};

export default ArrowLeft;
