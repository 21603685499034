import {
    GetListGoogleFontsMeta,
    GetListGoogleFontsPayload,
} from 'types/store/GetListGoogleFontsState';
import { createAsyncReducer } from 'utils/Redux';

import { getListGoogleFontsActions } from '../actions';

export default createAsyncReducer<
    GetListGoogleFontsMeta,
    GetListGoogleFontsPayload
>(getListGoogleFontsActions);
