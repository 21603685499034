export enum StoryDiscussionAction {
  ACCEPT = 'ACCEPT',
  DECLINE = 'DECLINE',
  SUBMIT = 'SUBMIT',
  CANCEL = 'CANCEL',
}

export interface StoryDiscussionBoxProps {
  onClose?: (event: any) => void;
  containerStyle?: any;
  className?: string;
}

export interface QuestionGroup {
  title: string;
  page: number;
  items: any[];
}
