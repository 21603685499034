import { createValidator } from 'utils/Validation';
import * as Yup from 'yup';

const constraints = {
    topic: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    video_length: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    video_aspect: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    // border: {
    //     presence: {
    //         message: '^Please enter card number',
    //         allowEmpty: false,
    //     },
    // },
    // border_color: {
    //     presence: {
    //         message: '^Please enter target audience',
    //         allowEmpty: false,
    //     },
    // },
    //
    // logo_url: {
    //     presence: {
    //         message: '^*Required',
    //         allowEmpty: false,
    //     },
    // },
    fonts: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    music: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    },
    video_questions: {
        presence: {
            message: '^*Required',
            allowEmpty: false,
        },
    }
    // intro_slide_question: {
    //     presence: {
    //         message: '^Please enter intro slide question',
    //         allowEmpty: false,
    //     },
    // },
    // intro_slide_name: {
    //     presence: {
    //         message: '^Please enter intro slide name',
    //         allowEmpty: false,
    //     },
    // },
    // intro_slide_job_title: {
    //     presence: {
    //         message: '^Please enter intro slide job title',
    //         allowEmpty: false,
    //     },
    // }
}

export const validateSchema = Yup.object().shape({
    // about: Yup.string()
    //   .max(50, 'Must be at most 50 characters.')
    //   .when('url', {
    //     is: (url: any) => !!url,
    //     then: Yup.string().required('Maximum 50 characters')
    //   }),
    // intro_slide_question: Yup.array().of(
    //     Yup.object().shape(
    //         {
    //             content: Yup.string().required('*Required'),
    //             type: Yup.string().required('*Required'),
    //         }
    //     )
    // ),
    // intro_slide_job_title: Yup.array().of(
    //     Yup.object().shape(
    //         {
    //             content: Yup.string().required('*Required'),
    //             type: Yup.string().required('*Required'),
    //         }
    //     )
    // ),
    // intro_slide_name: Yup.array().of(
    //     Yup.object().shape(
    //         {
    //             content: Yup.string().required('*Required'),
    //             type: Yup.string().required('*Required'),
    //         }
    //     )
    // ),
});


export const validate = createValidator(constraints);
