import {
  UpdateSfpStatusMeta,
  UpdateSfpStatusPayload,
} from 'types/store/UpdateSfpStatusState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SFP_STATUS';
const asyncAction = createAsyncAction<
  UpdateSfpStatusMeta,
  UpdateSfpStatusPayload
>(ns);

const updateSfpStatusActions = {
  ...asyncAction,
};

export default updateSfpStatusActions;
