import React from 'react';

import { SVGProps } from './SVG.props';

const ClockFill = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '9'}
      height={height || '9'}
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="4.42188" cy="4.55469" r="4" fill={fill || '#939194'} />
      <path
        d="M4.42188 1.98438V4.84152L5.85045 6.27009"
        stroke="#F8F8F8"
        strokeWidth="0.599206"
      />
    </svg>
  );
};

export default ClockFill;
