import { put, call, takeLatest, select } from 'redux-saga/effects';
import { bulkCreateContentPackages } from 'services/content-package';
import { AsyncAction } from 'types/Action';
import { BulkCreateContentPackagesMeta, BulkCreateContentPackagesPayload } from 'types/store/BulkCreateContentPackages';
import { Store } from 'types/store/Store';

import { bulkCreateContentPackagesActions } from '../actions';

function* bulkCreateContentPackagesRequest(action: AsyncAction<BulkCreateContentPackagesMeta, BulkCreateContentPackagesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(bulkCreateContentPackages, action.meta, state.auth.token);
      yield put(bulkCreateContentPackagesActions.success(data));
    } catch (e: any) {
      yield put(bulkCreateContentPackagesActions.failed(e.message));
    }
  }
}

// function* bulkCreateContentPackagesSuccess(action: AsyncAction<BulkCreateContentPackagesMeta, BulkCreateContentPackagesPayload>): any {
//   yield put()
// }

function* bulkCreateContentPackagesWatcher(): any {
  yield takeLatest(bulkCreateContentPackagesActions.REQUEST, bulkCreateContentPackagesRequest);
  // yield takeLatest(bulkCreateContentPackagesActions.SUCCESS, bulkCreateContentPackagesSuccess);
}

export default bulkCreateContentPackagesWatcher;
