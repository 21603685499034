import {
    GetShowcasePageDetailsMeta,
    GetShowcasePageDetailsPayload,
} from 'types/store/GetShowcasePageDetailsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_PAGE_DETAILS';
const asyncAction = createAsyncAction<
    GetShowcasePageDetailsMeta,
    GetShowcasePageDetailsPayload
>(ns);

const getShowcasePageDetailsActions = {
    ...asyncAction,
};

export default getShowcasePageDetailsActions;
