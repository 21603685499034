import { Action } from 'types/Action';

export enum UPDATE_WORKSPACE {
  REQUEST = 'UPDATE_WORKSPACE_REQUEST',
  SUCCESS = 'UPDATE_WORKSPACE_SUCCESS',
  EROR = 'UPDATE_WORKSPACE_EROR',
}

export const updateWorkspaces = (payload: {
  [field: string]: string;
}): Action => ({
  type: UPDATE_WORKSPACE.REQUEST,
  payload,
});
