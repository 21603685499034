import {
  GetStoriesAnalyticsMeta,
  GetStoriesAnalyticsPayload,
} from 'types/store/GetStoriesAnalytics';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORIES_ANALYTICS';
const asyncAction = createAsyncAction<
  GetStoriesAnalyticsMeta,
  GetStoriesAnalyticsPayload
>(ns);

const getStoriesAnalyticsAction = {
  ...asyncAction,
};

export default getStoriesAnalyticsAction;
