import React from 'react';

import { SVGProps } from './SVG.props';

const Copy = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 18}
      viewBox='0 0 16 18'
      fill={fill || 'none'}
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M11.333 15.667H4.667a2.5 2.5 0 0 1-2.5-2.5V4.834a.833.833 0 0 0-1.667 0v8.333a4.167 4.167 0 0 0 4.167 4.167h6.666a.833.833 0 0 0 0-1.667zm-5-5.833a.833.833 0 0 0 .834.833h4.166a.833.833 0 0 0 0-1.667H7.167a.833.833 0 0 0-.834.834zM15.5 6.45a1.09 1.09 0 0 0-.05-.225V6.15a.893.893 0 0 0-.158-.233l-5-5a.892.892 0 0 0-.234-.158.266.266 0 0 0-.075 0 .733.733 0 0 0-.275-.092H6.333a2.5 2.5 0 0 0-2.5 2.5V11.5a2.5 2.5 0 0 0 2.5 2.5H13a2.5 2.5 0 0 0 2.5-2.5V6.45zm-5-2.941 2.158 2.158h-1.325a.833.833 0 0 1-.833-.833V3.509zm3.333 7.991a.834.834 0 0 1-.833.834H6.333A.833.833 0 0 1 5.5 11.5V3.167a.833.833 0 0 1 .833-.833h2.5v2.5a2.5 2.5 0 0 0 .15.833H7.167a.833.833 0 1 0 0 1.667h6.666V11.5z'
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Copy;
