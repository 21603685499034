import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DYNAMIC_FILTER_NS';

const asyncAction = createAsyncAction<Record<string, never>, GenericResponse>(
  ns,
);

const dynamicFilterActions = { ...asyncAction };

export default dynamicFilterActions;
