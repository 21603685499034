import styled from 'utils/styled';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
`;

export const Backdrop = styled.div`
  width: 100%;
  height: 100%;
`;
