import { put, call, takeLatest, select } from 'redux-saga/effects';
import { archiveStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { ArchiveStoryMeta, ArchiveStoryPayload } from 'types/store/ArchiveStory';
import { Store } from 'types/store/Store';

import { archiveStoryActions } from '../actions';

function* archiveStoryRequest(action: AsyncAction<ArchiveStoryMeta, ArchiveStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(archiveStory, action.meta, state.auth.token);
      yield put(archiveStoryActions.success(data));
    } catch (e: any) {
      yield put(archiveStoryActions.failed(e.message));
    }
  }
}

function* archiveStoryWatcher(): any {
  yield takeLatest(archiveStoryActions.REQUEST, archiveStoryRequest);
}

export default archiveStoryWatcher;
