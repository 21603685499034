import { CheckEmailRegisterMeta, CheckEmailRegisterPayload } from 'types/store/CheckEmailRegister';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHECK_EMAIL_REGISTER_NS';

const asyncAction = createAsyncAction<CheckEmailRegisterMeta, CheckEmailRegisterPayload>(ns);

const checkEmailRegisterActions = { ...asyncAction };

export default checkEmailRegisterActions;
