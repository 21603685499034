import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  getAssignedAdvocatesByStory,
  updateAdvocatesForStory,
} from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  GetAssignedAdvocatesByStoryMeta,
  GetAssignedAdvocatesByStoryPayload,
  UpdateAdvocatesForStoryMeta,
  UpdateAdvocatesForStoryPayload,
} from 'types/store/Story';

import {
  getAssignedAdvocatesByStoryActions,
  updateAdvocatesForStoryActions,
} from '../actions';

function* getAssignedAdvocatesByStoryRequest(
  action: AsyncAction<
    GetAssignedAdvocatesByStoryMeta,
    GetAssignedAdvocatesByStoryPayload
  >
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getAssignedAdvocatesByStory,
        action.meta,
        state.auth.token
      );
      yield put(
        getAssignedAdvocatesByStoryActions.success({
          id: action.meta.storyId,
          assignedAdvocates: data.data,
        })
      );
    } catch (e: any) {
      yield put(getAssignedAdvocatesByStoryActions.failed(e.message));
    }
  }
}

function* updateAdvocatesForStoryRequest(
  action: AsyncAction<
    UpdateAdvocatesForStoryMeta,
    UpdateAdvocatesForStoryPayload
  >
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateAdvocatesForStory,
        action.meta,
        state.auth.token
      );
      yield put(updateAdvocatesForStoryActions.success(data.status));
    } catch (e: any) {
      yield put(updateAdvocatesForStoryActions.failed(e.message));
    }
  }
}

// function* storySuccess(action: AsyncAction<StoryMeta, StoryPayload>): any {
//   yield put()
// }

function* storyWatcher(): any {
  yield takeLatest(
    getAssignedAdvocatesByStoryActions.REQUEST,
    getAssignedAdvocatesByStoryRequest
  );
  yield takeLatest(
    updateAdvocatesForStoryActions.REQUEST,
    updateAdvocatesForStoryRequest
  );
  // yield takeLatest(storyActions.SUCCESS, storySuccess);
}

export default storyWatcher;
