import React from 'react';

import { SVGProps } from './SVG.props';

const Comments = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 14}
      height={height || 14}
      viewBox='0 0 14 14'
      fill='none'
      {...props}
    >
      <path
        d='M12.74 11.793a4.668 4.668 0 00-1.826-7.046 5.334 5.334 0 10-9.46 4.193l-.927.92a.667.667 0 00-.14.727.667.667 0 00.614.413h3.793a4.667 4.667 0 004.207 2.667h4a.668.668 0 00.653-.798.667.667 0 00-.18-.342l-.733-.734zM4.335 9c.001.223.019.446.053.667h-1.78l.234-.227a.667.667 0 000-.947 3.953 3.953 0 01-1.174-2.826 4 4 0 014-4 3.96 3.96 0 013.767 2.666h-.433A4.667 4.667 0 004.334 9zm7.027 3.333l.033.034H9.001a3.334 3.334 0 112.36-.974.667.667 0 00-.2.467.668.668 0 00.2.473z'
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Comments;
