import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createLibrary } from 'services/library';
import { AsyncAction } from 'types/Action';
import {
  CreateLibraryMeta,
  CreateLibraryPayload,
} from 'types/store/CreateLibraryState';
import { Store } from 'types/store/Store';

import {
  createLibraryActions,
} from '../actions';

function* createLibraryRequest(
  action: AsyncAction<CreateLibraryMeta, CreateLibraryPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createLibrary, action.meta, state.auth.token);
      yield put(createLibraryActions.success(data));
    } catch (e: any) {
      yield put(createLibraryActions.failed(e.message));
    }
  }
}

function* createLibrarySuccess(
  action: AsyncAction<CreateLibraryMeta, CreateLibraryPayload>
) {
  // yield put(createLibraryActions.clear());
}

function* createLibraryWatcher(): any {
  yield takeLatest(createLibraryActions.REQUEST, createLibraryRequest);
  yield takeLatest(createLibraryActions.SUCCESS, createLibrarySuccess);
}

export default createLibraryWatcher;
