import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_APPROVERS';
const asyncAction = createAsyncAction(ns);

const getApproversActions = {
  ...asyncAction,
};

export default getApproversActions;
