import {
  GetApproversStatePayload,
} from 'types/store/GetApproversState';
import { createAsyncReducer } from 'utils/Redux';

import { getApproversActions } from '../actions';

export default createAsyncReducer<
  null,
  GetApproversStatePayload
>(getApproversActions);
