import { GetAdvocatesByUserIdMeta, GetAdvocatesByUserIdPayload } from 'types/store/GetAdvocatesByUserId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATES_BY_USER_ID_NS';

const asyncAction = createAsyncAction<GetAdvocatesByUserIdMeta, GetAdvocatesByUserIdPayload>(ns);

const getAdvocatesByUserIdActions = { ...asyncAction };

export default getAdvocatesByUserIdActions;
