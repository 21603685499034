import { Reducer } from 'redux';
import { creativeStudioAction } from 'store/actions';
import { CreativeAction } from 'store/actions/creativeStudio.action';
import { Action } from 'types/Action';
import { v4 as uuid } from 'uuid';

export interface ICreativeStudio {
  pageStoryNo: number;
  pageLibraryNo: number;
  colors: any[];
  logos: any[];
  fonts: any[];
  template: any;
  isLogoLoading: boolean;
  isFontLoading: boolean;
  isColorLoading: boolean;
  recentVideo: any;
  isUploadVideo: boolean;
  isOpenBrowserVideo: boolean;
  templateId: string;
  isOpenModalTemplate: boolean;
  createTemplate: boolean;
  favoritesList: any;
  editedFavoriteItem: {
    templateId: string;
    action: string;
  };
}

const initState: ICreativeStudio = {
  pageStoryNo: 1,
  pageLibraryNo: 1,
  colors: [],
  logos: [],
  fonts: [],
  template: null,
  isLogoLoading: false,
  isFontLoading: false,
  isColorLoading: false,
  recentVideo: [],
  isUploadVideo: false,
  isOpenBrowserVideo: false,
  templateId: '',
  isOpenModalTemplate: false,
  createTemplate: false,
  favoritesList: null,
  editedFavoriteItem: {
    templateId: '',
    action: '',
  },
};

const CreativeReducer: Reducer<any, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case CreativeAction.BROWS_TOGGLE: {
      return {
        ...state,
        isOpenBrowserVideo: !state.isOpenBrowserVideo,
      };
    }
    case CreativeAction.TL_TOGGLE_MODAL_TEMPLATE: {
      return {
        ...state,
        isOpenModalTemplate: !state.isOpenModalTemplate,
      };
    }
    case CreativeAction.TL_UPDATE_TEMPLATE: {
      return {
        ...state,
        templateId: payload.id,
      };
    }
    case CreativeAction.BK_REMOVE_COLOR: {
      return {
        ...state,
        colors: state.colors.filter((item: any) => item.uid !== payload.id),
      };
    }
    case CreativeAction.BK_ADD_COLOR: {
      const colors = [...state.colors];
      colors.unshift({ uid: uuid(), color: payload.color });
      return {
        ...state,
        colors: colors,
      };
    }
    case CreativeAction.BK_REMOVE_FONT: {
      return {
        ...state,
        fonts: state.fonts.filter((item: any) => item.uid !== payload.id),
      };
    }
    case CreativeAction.BK_REMOVE_LOGO: {
      return {
        ...state,
        logos: state.logos.filter((item: any) => item.uid !== payload.id),
      };
    }
    case creativeStudioAction.getBrandKitAction.SUCCESS: {
      return {
        ...state,
        colors:
          payload.data.colors?.map((item: any) => {
            return { color: item, uid: uuid() };
          }) || [],
        logos:
          payload.data.logos?.map((item: any) => {
            return { ...item, uid: uuid() };
          }) || [],
        fonts:
          payload.data.fonts?.map((item: any) => {
            return { ...item, uid: uuid() };
          }) || [],
      };
    }
    case creativeStudioAction.addLogoBrandKitAction.REQUEST: {
      return {
        ...state,
        isLogoLoading: true,
      };
    }
    case creativeStudioAction.addFontBrandKitAction.REQUEST: {
      return {
        ...state,
        isFontLoading: true,
      };
    }
    case creativeStudioAction.addLogoBrandKitAction.SUCCESS: {
      const logos = [...state.logos];
      logos.unshift({ uid: uuid(), ...payload.data[0] });
      return {
        ...state,
        logos: logos,
        isLogoLoading: false,
      };
    }
    case creativeStudioAction.addFontBrandKitAction.SUCCESS: {
      const fonts = [...state.fonts];
      fonts.unshift({ uid: uuid(), ...payload.data[0] });
      return {
        ...state,
        fonts: fonts,
        isFontLoading: false,
      };
    }
    case creativeStudioAction.getTemplateAction.SUCCESS: {
      return {
        ...state,
        template: payload.data,
      };
    }
    case creativeStudioAction.getFavoritesList.SUCCESS: {
      return {
        ...state,
        favoritesList: payload.data,
      };
    }
    case creativeStudioAction.setFavoriteAction.SUCCESS: {
      return {
        ...state,
        editedFavoriteItem: payload,
      };
    }
    case creativeStudioAction.getRecentVideo.SUCCESS: {
      return {
        ...state,
        recentVideo: payload.data,
      };
    }
    case creativeStudioAction.uploadVideoStudio.REQUEST: {
      return {
        ...state,
        isUploadVideo: true,
      };
    }
    case creativeStudioAction.uploadVideoStudio.SUCCESS: {
      return {
        ...state,
        isUploadVideo: false,
      };
    }
    case creativeStudioAction.uploadVideoStudio.FAILED: {
      return {
        ...state,
        isUploadVideo: false,
      };
    }
    case creativeStudioAction.createTemplate.REQUEST: {
      return {
        ...state,
        createTemplate: true,
      };
    }
    case creativeStudioAction.createTemplate.SUCCESS: {
      return {
        ...state,
        createTemplate: false,
      };
    }
    case creativeStudioAction.createTemplate.FAILED: {
      return {
        ...state,
        createTemplate: false,
      };
    }
    case creativeStudioAction.editATemplate.REQUEST: {
      return {
        ...state,
        createTemplate: true,
      };
    }
    case creativeStudioAction.editATemplate.SUCCESS: {
      return {
        ...state,
        createTemplate: false,
      };
    }
    case creativeStudioAction.editATemplate.FAILED: {
      return {
        ...state,
        createTemplate: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default CreativeReducer;
