import {
  GetCommunitiesMeta,
  GetCommunitiesPayload,
} from 'types/store/GetCommunitiesState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_COMMUNITIES';

const asyncAction = createAsyncAction<
  GetCommunitiesMeta,
  GetCommunitiesPayload
>(ns);
const clearAction = createClearAction(ns);

const getCommunitiesActions = {
  ...asyncAction,
  ...clearAction,
};

export default getCommunitiesActions;
