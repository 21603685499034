import {
  UpsertShowcaseArticleImageMeta,
  UpsertShowcaseArticleImagePayload,
} from 'types/store/UpsertShowcaseArticleImageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_SHOWCASE_ARTICLE_IMAGE';
const asyncAction = createAsyncAction<
  UpsertShowcaseArticleImageMeta,
  UpsertShowcaseArticleImagePayload
>(ns);

const upsertShowcaseArticleImageActions = {
  ...asyncAction,
};

export default upsertShowcaseArticleImageActions;
