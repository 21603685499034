import { put, call, takeLatest, select } from 'redux-saga/effects';
// import { getApprovers } from 'services/approvers';
import { getApprovers } from 'services/team';
import { AsyncAction } from 'types/Action';
import { GetApproversStateMeta, GetApproversStatePayload } from 'types/store/GetApproversState';
import { Store } from 'types/store/Store';

import {
  getApproversActions,
} from '../actions';

function* getApproversRequest(action: AsyncAction<GetApproversStateMeta, GetApproversStatePayload>) {
  const state: Store = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getApprovers,
        action.meta,
        state.auth.token
      );
      yield put(getApproversActions.success(data));
    } catch (e: any) {
      yield put(getApproversActions.failed(e.message));
    }
  } else {
    yield put(getApproversActions.failed('Token not found'));
  }
}

function* getApproversWatcher(): any {
  yield takeLatest(
    getApproversActions.REQUEST,
    getApproversRequest
  );
}

export default getApproversWatcher;
