import { put, call, takeLatest, select } from 'redux-saga/effects';
import { checkInvite } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  CheckInviteMeta,
  CheckInvitePayload,
} from 'types/store/CheckInviteState';
import { Store } from 'types/store/Store';
import { AxiosError } from 'axios';

import { checkInviteActions, openToast } from '../actions';

function* checkInviteRequest(
  action: AsyncAction<CheckInviteMeta, CheckInvitePayload>,
) {
  const state: Store = yield select();
  // if (state.auth.token) {
  try {
    const { data } = yield call(checkInvite, action.meta);
    yield put(checkInviteActions.success(data));
    // yield put(getPagesbyCompanyIdActions.success(data));
  } catch (e: unknown) {
    const errorMessage = e as AxiosError;
    yield put(openToast(errorMessage?.response?.data.message, 'error'));
    yield put(checkInviteActions.failed(errorMessage?.response?.data.message));
  }
  //} else {
  // yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  //}
}

function* checkInviteWatcher(): any {
  yield takeLatest(checkInviteActions.REQUEST, checkInviteRequest);
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default checkInviteWatcher;
