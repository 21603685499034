import { WriteStoryPayload } from 'types/store/WriteStoryState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'WRITE_STORY';
const updateAction = createUpdateAction<WriteStoryPayload>(ns);
const clearAction = createClearAction(ns);

const writeStoryActions = {
  ...updateAction,
  ...clearAction,
};

export default writeStoryActions;
