import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { AddApproversV2Meta } from 'types/store/AddApproversV2';
import { CheckEmailRegisterMeta } from 'types/store/CheckEmailRegister';
import { DeleteApproverMeta } from 'types/store/DeleteApprover';
import { DeleteInvitedTeamMeta } from 'types/store/DeleteInvitedTeam';
import { EditApproverMeta } from 'types/store/EditApprover';
import { GetAdminsMeta } from 'types/store/GetAdmins';
import { GetApproversStateMeta } from 'types/store/GetApproversState';
import { GetInvitedTeamsMeta } from 'types/store/GetInvitedTeams';
import { GetInviteLinkTeamMeta } from 'types/store/GetInviteLinkTeam';
import { GetRecruiterMeta } from 'types/store/GetRecruiters';
import { InviteTeamMeta } from 'types/store/InviteTeam';
import { ResendInvitationMeta } from 'types/store/ResendInvitation';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const TEAM_URL = `${BASE_URL}/team`;
const EMPLOYER_URL = `${BASE_URL}/employee`;

export const addApproversV2 = (
    data: AddApproversV2Meta,
    token: string
): any => {
    return axios({
        method: 'post',
        url: `${TEAM_URL}/addApprovers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const getApprovers = (
    data: GetApproversStateMeta,
    token: string
): any => {
    return axios({
        method: 'get',
        url: `${TEAM_URL}/getApprovers`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: data
    })
}

export const editApprover = (
    data: EditApproverMeta,
    token: string
): any => {
    return axios({
        method: 'post',
        url: `${TEAM_URL}/editApprover`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const deleteApprover = (
    data: DeleteApproverMeta,
    token: string
): any => {
    return axios({
        method: 'delete',
        url: `${TEAM_URL}/deleteApprover`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const getInvitedTeams = (
    data: GetInvitedTeamsMeta,
    token: string
): any => {
    return axios({
        method: 'get',
        url: `${TEAM_URL}/getInvitedTeam`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: data
    })
}

export const inviteTeam = (
    data: InviteTeamMeta,
    token: string
): any => {
    return axios({
        method: 'post',
        url: `${TEAM_URL}/inviteTeam`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data,
    })
}

export const getAdmins = (
    data: GetAdminsMeta,
    token: string
): any => {
    return axios({
        method: 'get',
        url: `${TEAM_URL}/getAdmins`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: data
    })
}

export const getRecruiters = (
    data: GetRecruiterMeta,
    token: string,
): any => {
    return axios({
        method: 'get',
        url: `${TEAM_URL}/get-recruiters`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        params: data
    })
}

export const deleteInvitedTeam = (
    data: DeleteInvitedTeamMeta,
    token: string
): any => {
    return axios({
        method: 'post',
        url: `${TEAM_URL}/deleteInvitedTeam`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data,
    })
}

export const resendInvitation = (
    data: ResendInvitationMeta,
    token: string
): any => {
    return axios({
        method: 'post',
        url: `${TEAM_URL}/resendInvitation`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data: data,
    })
}

export const getInviteLink = (
    meta: GetInviteLinkTeamMeta,
    token: string
): any => {
    return axios({
        method: 'get',
        url: `${TEAM_URL}/getInviteToken?role=${meta.role}`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}

export const checkEmailRegister = (
    data: CheckEmailRegisterMeta
): any => {
    return axios({
        method: 'post',
        url: `${TEAM_URL}/checkEmailRegister`,
        data: data
    })
}

export const getRoleInviteTeam = (
    token: string
): AxiosPromise<GenericResponse> => {
    return axios({
        method: 'get',
        url: `${EMPLOYER_URL}/get-list-role`,
        headers: {
            Authorization: `Bearer ${token}`,
        }
    })
}
