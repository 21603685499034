import { ChangeStoryDueDateMeta, ChangeStoryDueDatePayload } from 'types/store/ChangeStoryDueDate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHANGE_STORY_DUE_DATE_NS';

const asyncAction = createAsyncAction<ChangeStoryDueDateMeta, ChangeStoryDueDatePayload>(ns);

const changeStoryDueDateActions = { ...asyncAction };

export default changeStoryDueDateActions;
