import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.4443 3.6853C8.08879 2.58649 10.0222 2 12 2C13.3132 2 14.6136 2.25866 15.8268 2.7612C17.0401 3.26375 18.1425 4.00035 19.0711 4.92893C19.9997 5.85752 20.7363 6.95991 21.2388 8.17317C21.7413 9.38642 22 10.6868 22 12C22 13.9778 21.4135 15.9112 20.3147 17.5557C19.2159 19.2002 17.6541 20.4819 15.8268 21.2388C13.9996 21.9957 11.9889 22.1937 10.0491 21.8079C8.10929 21.422 6.32746 20.4696 4.92894 19.0711C3.53041 17.6725 2.578 15.8907 2.19215 13.9509C1.8063 12.0111 2.00433 10.0004 2.76121 8.17317C3.51809 6.3459 4.79981 4.78412 6.4443 3.6853ZM12.3803 7.08C12.1368 6.97998 11.8637 6.97998 11.6203 7.08C11.4975 7.12759 11.3854 7.19896 11.2903 7.29C11.2019 7.3872 11.1309 7.49881 11.0803 7.62C11.0243 7.73868 10.9969 7.86882 11.0003 8C10.9995 8.1316 11.0247 8.26206 11.0745 8.3839C11.1243 8.50574 11.1976 8.61656 11.2903 8.71C11.3875 8.79833 11.4991 8.86935 11.6203 8.92C11.7718 8.98224 11.9362 9.00631 12.0992 8.99011C12.2622 8.97391 12.4187 8.91792 12.555 8.82706C12.6913 8.73621 12.8031 8.61328 12.8808 8.46907C12.9584 8.32486 12.9995 8.16378 13.0003 8C12.9966 7.73523 12.893 7.48163 12.7103 7.29C12.6152 7.19896 12.503 7.12759 12.3803 7.08ZM12.0003 11C11.7351 11 11.4807 11.1054 11.2932 11.2929C11.1056 11.4804 11.0003 11.7348 11.0003 12V16C11.0003 16.2652 11.1056 16.5196 11.2932 16.7071C11.4807 16.8946 11.7351 17 12.0003 17C12.2655 17 12.5198 16.8946 12.7074 16.7071C12.8949 16.5196 13.0003 16.2652 13.0003 16V12C13.0003 11.7348 12.8949 11.4804 12.7074 11.2929C12.5198 11.1054 12.2655 11 12.0003 11Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
}

export default SvgComponent;
