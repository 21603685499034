import { GenerateSocialCopyMeta, GenerateSocialCopyPayload } from 'types/store/GenerateSocialCopy';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GENERATE_SOCIAL_COPY_NS';

const asyncAction = createAsyncAction<GenerateSocialCopyMeta, GenerateSocialCopyPayload>(ns);

const generateSocialCopyActions = { ...asyncAction };

export default generateSocialCopyActions;
