import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticContentPipelineMeta {
  time: string;
  communities: string[];
}

const contentPipelineNS = 'ANALYTIC_CONTENT_PIPELINE';

const getContentPipelineAsync = createAsyncAction<
  IAnalyticContentPipelineMeta,
  GenericResponse
>(contentPipelineNS);

export default getContentPipelineAsync;
