import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAllItems } from 'services/item';
import { AsyncAction } from 'types/Action';
import {
  GetAllItemsMeta,
  GetAllItemsPayload
} from 'types/store/GetAllItems';
import { Store } from 'types/store/Store';

import { getAllItemsActions } from '../actions';

function* getAllItemsRequest(
  action: AsyncAction<GetAllItemsMeta, GetAllItemsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getAllItems, action.meta, state.auth.token);
      yield put(getAllItemsActions.success(data));
    } catch (e: any) {
      yield put(getAllItemsActions.failed(e.message));
    }
  } else {
    yield put(getAllItemsActions.failed('Token not found'));
  }
}

function* getAllItemsSuccess(
  action: AsyncAction<GetAllItemsMeta, GetAllItemsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getAllItemsWatcher(): any {
  yield takeLatest(getAllItemsActions.REQUEST, getAllItemsRequest);
  yield takeLatest(getAllItemsActions.SUCCESS, getAllItemsSuccess);
}

export default getAllItemsWatcher;
