import { put, call, takeLatest, select } from 'redux-saga/effects';
import { markStoryAsReadyToPublish } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { MarkStoryAsReadyToPublishMeta, MarkStoryAsReadyToPublishPayload } from 'types/store/MarkStoryAsReadyToPublish';
import { Store } from 'types/store/Store';

import { markStoryAsReadyToPublishActions } from '../actions';

function* markStoryAsReadyToPublishRequest(action: AsyncAction<MarkStoryAsReadyToPublishMeta, MarkStoryAsReadyToPublishPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(markStoryAsReadyToPublish, action.meta, state.auth.token);
      yield put(markStoryAsReadyToPublishActions.success(data));
    } catch (e: any) {
      yield put(markStoryAsReadyToPublishActions.failed(e.message));
    }
  }
}

// function* markStoryAsReadyToPublishSuccess(action: AsyncAction<MarkStoryAsReadyToPublishMeta, MarkStoryAsReadyToPublishPayload>): any {
//   yield put()
// }

function* markStoryAsReadyToPublishWatcher(): any {
  yield takeLatest(markStoryAsReadyToPublishActions.REQUEST, markStoryAsReadyToPublishRequest);
  // yield takeLatest(markStoryAsReadyToPublishActions.SUCCESS, markStoryAsReadyToPublishSuccess);
}

export default markStoryAsReadyToPublishWatcher;
