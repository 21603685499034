import { UpdateEmailTemplateMeta, UpdateEmailTemplatePayload } from 'types/store/UpdateEmailTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_EMAIL_TEMPLATE_NS';

const asyncAction = createAsyncAction<UpdateEmailTemplateMeta, UpdateEmailTemplatePayload>(ns);

const updateEmailTemplateActions = { ...asyncAction };

export default updateEmailTemplateActions;
