import React from 'react';

import { GenericResponse } from 'types/GenericResponse';
import {
  SetFavoriteMeta,
  TemplateStudioMeta,
  UploadVideoStudioMeta,
} from 'types/store/CreativeStudio';
import { createAsyncAction } from 'utils/Redux';

const brandKitAsyncAction = createAsyncAction<null, GenericResponse>(
  'GET_BRAND_KIT',
);

const templateAsyncAction = createAsyncAction<
  TemplateStudioMeta,
  GenericResponse
>('GET_TEMPLATE');

const recentVideoAsyncAction = createAsyncAction<null, GenericResponse>(
  'GET_RECENT_VIDEO',
);

const updateBrandKitAsyncAction = createAsyncAction<null, GenericResponse>(
  'UPDATE_BRAND_KIT',
);

const addLogoAsyncAction = createAsyncAction<File[], GenericResponse>(
  'ADD_LOGO_BRAND_KIT',
);

const addFontAsyncAction = createAsyncAction<File[], GenericResponse>(
  'ADD_FONT_BRAND_KIT',
);

const uploadVideo = createAsyncAction<UploadVideoStudioMeta, GenericResponse>(
  'UPLOAD_VIDEO',
);

const createTemplateAction =
  createAsyncAction<GenericResponse>('CREATE_TEMPLATE');

const editTemplate = createAsyncAction<any, GenericResponse>('EDIT_TEMPLATE');

const getFavorites = createAsyncAction<string, GenericResponse>(
  'GET_FAVORITES',
);

const setFavorite = createAsyncAction<SetFavoriteMeta, GenericResponse>(
  'SET_FAVORITE',
);

const getBrandKitAction = { ...brandKitAsyncAction };
const getTemplateAction = { ...templateAsyncAction };
const updateBrandKitAction = { ...updateBrandKitAsyncAction };
const addLogoBrandKitAction = { ...addLogoAsyncAction };
const addFontBrandKitAction = { ...addFontAsyncAction };
const getRecentVideo = { ...recentVideoAsyncAction };
const uploadVideoStudio = { ...uploadVideo };
const createTemplate = { ...createTemplateAction };
const editATemplate = { ...editTemplate };
const getFavoritesList = { ...getFavorites };
const setFavoriteAction = { ...setFavorite };
const creativeStudioAction = {
  getBrandKitAction,
  getTemplateAction,
  updateBrandKitAction,
  addLogoBrandKitAction,
  addFontBrandKitAction,
  getRecentVideo,
  uploadVideoStudio,
  createTemplate,
  editATemplate,
  getFavoritesList,
  setFavoriteAction,
};

export default creativeStudioAction;
