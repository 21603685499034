import { GetInterestsByCategoryIdMeta, GetInterestsByCategoryIdPayload } from 'types/store/GetInterestsByCategoryId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_INTERESTS_BY_CATEGORY_ID_NS';

const asyncAction = createAsyncAction<GetInterestsByCategoryIdMeta, GetInterestsByCategoryIdPayload>(ns);

const getInterestsByCategoryIdActions = { ...asyncAction };

export default getInterestsByCategoryIdActions;
