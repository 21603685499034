import FormikTextArea from 'components/module/FormikTextArea';
import styled from 'utils/styled';


export const Container = styled.div``;

export const RowContainer = styled.div<{
    marginTop?: number;
}>`
    display: flex;
    align-items: self-start;
    ${({ marginTop }) => marginTop ? `margin-top: ${marginTop}px` : null}
    flex-direction: row;
    margin-bottom: 16px;
`;

export const TextFieldContainer = styled.div`
    width: 100%;

    textarea {
        overflow: hidden;
    }
`;

export const ButtonContainer = styled.div<{
    variant: 'mint' | 'error'
}>`
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme, variant }) => theme.brand[variant]};

    &:hover {
        opacity: 0.7;
    }
`;

export const CustomFormikTextArea = styled(FormikTextArea)`
`;

export const VideoContent = styled.div`
    display: flex;
    flex-direction: row;
    margin-bottom: 16px;
    width: 100%;
`;

export const UserContent = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;

    div {
        margin-bottom: 8px;
    }
`;

export const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    margin-left: 13.31px;
    width: 100%;
`;

export const ShowButton = styled.div`
  padding: 0;
  display: flex;
  margin-top: 8px;
  margin-bottom: 16px;
  background: none;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
  p {
    margin-left: 5px;
  }
`;

export const ArrowContainer = styled.div<{ flipped?: boolean }>`
  transform: rotate(${({ flipped }) => (flipped ? '180deg' : '')});
  color: ${({ theme }) => theme.brand.primary};
  svg {
    margin-top: ${({ flipped }) => (flipped ? '' : '0px')};
  }
`;

export const ItemContainer = styled.div`
    border-bottom: 1px solid #F0ECEE;
    margin-bottom: 16px;
`;