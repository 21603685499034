import React from 'react';

import { SVGProps } from './SVG.props';

const Settings = (props: SVGProps): JSX.Element => {
  const { width = 14, height = 14, fill = '#070550' } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      {...props}
    >
      <path
        d="M13.213 5.366l-1.26-.42.594-1.186A.667.667 0 0012.42 3L11 1.58a.666.666 0 00-.767-.127l-1.186.593-.42-1.26A.667.667 0 008 .333H6a.667.667 0 00-.633.453l-.42 1.26-1.187-.593A.667.667 0 003 1.58L1.58 3a.667.667 0 00-.127.766l.594 1.187-1.26.42A.667.667 0 00.333 6v2a.667.667 0 00.454.633l1.26.42-.594 1.187a.667.667 0 00.127.76L3 12.42a.667.667 0 00.767.126l1.186-.593.42 1.26a.667.667 0 00.634.453h2a.667.667 0 00.633-.453l.42-1.26 1.187.593A.666.666 0 0011 12.42L12.42 11a.667.667 0 00.127-.767l-.594-1.187 1.26-.42A.667.667 0 0013.667 8V6a.667.667 0 00-.454-.634zm-.88 2.154l-.8.266a1.334 1.334 0 00-.773 1.88l.38.76-.733.734-.74-.4a1.334 1.334 0 00-1.86.773l-.267.8H6.48l-.267-.8a1.333 1.333 0 00-1.88-.773l-.76.38-.733-.734.4-.74a1.333 1.333 0 00-.773-1.88l-.8-.266V6.48l.8-.267a1.333 1.333 0 00.773-1.88l-.38-.74.733-.733.74.38a1.333 1.333 0 001.88-.774l.267-.8h1.04l.267.8a1.333 1.333 0 001.88.774l.76-.38.733.733-.4.74a1.333 1.333 0 00.773 1.86l.8.267v1.06zM7 4.333a2.667 2.667 0 100 5.333 2.667 2.667 0 000-5.333zm0 4a1.333 1.333 0 110-2.667 1.333 1.333 0 010 2.667z"
        fill={fill}
      />
    </svg>
  );
};

export default Settings;
