import {
  UploadRevisedVideoMeta,
  UploadRevisedVideoPayload,
} from 'types/store/UploadRevisedVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPLOAD_REVISED_VIDEO';
const asyncAction = createAsyncAction<
  UploadRevisedVideoMeta,
  UploadRevisedVideoPayload
>(ns);

const uploadRevisedVideoActions = {
  ...asyncAction,
};

export default uploadRevisedVideoActions;
