import React from 'react';

import { SVGProps } from './SVG.props';

const Showcase = (props: SVGProps): JSX.Element => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M7 .333a6.667 6.667 0 00-4.9 11.173 6.667 6.667 0 009.8 0A6.667 6.667 0 007 .333zm0 12a5.333 5.333 0 01-3.7-1.5 4 4 0 017.4 0 5.333 5.333 0 01-3.7 1.5zM5.667 5.666a1.333 1.333 0 112.666 0 1.333 1.333 0 01-2.666 0zm5.94 4A5.333 5.333 0 009 7.413a2.667 2.667 0 10-4 0 5.333 5.333 0 00-2.607 2.253A5.28 5.28 0 011.667 7a5.333 5.333 0 0110.666 0 5.28 5.28 0 01-.726 2.666z"
        fill="#070550"
      />
    </svg>
  );
};

export default Showcase;
