import {
    GetOwnPageDetailsMeta,
    GetOwnPageDetailsPayload
} from 'types/store/getOwnPageDetailsState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_OWN_PAGE_DETAIL';
const asyncAction = createAsyncAction<
    GetOwnPageDetailsMeta,
    GetOwnPageDetailsPayload
>(ns);
const clearAction = createClearAction(ns);

const getOwnPageDetailsActions = {
    ...asyncAction,
    ...clearAction
};

export default getOwnPageDetailsActions;