import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateBrandGuidelinesByCompanyId } from 'services/company';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateBrandGuidelinesByCompanyIdMeta,
  UpdateBrandGuidelinesByCompanyIdPayload,
} from 'types/store/UpdateBrandGuidelinesByCompanyId';

import { updateBrandGuidelinesByCompanyIdActions } from '../actions';

function* updateBrandGuidelinesByCompanyIdRequest(
  action: AsyncAction<
    UpdateBrandGuidelinesByCompanyIdMeta,
    UpdateBrandGuidelinesByCompanyIdPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateBrandGuidelinesByCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(updateBrandGuidelinesByCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(updateBrandGuidelinesByCompanyIdActions.failed(e.message));
    }
  }
}

function* updateBrandGuidelinesByCompanyIdSuccess(
  action: AsyncAction<
    UpdateBrandGuidelinesByCompanyIdMeta,
    UpdateBrandGuidelinesByCompanyIdPayload
  >
) {
  //
}

function* updateBrandGuidelinesByCompanyIdWatcher(): any {
  yield takeLatest(
    updateBrandGuidelinesByCompanyIdActions.REQUEST,
    updateBrandGuidelinesByCompanyIdRequest
  );
  yield takeLatest(
    updateBrandGuidelinesByCompanyIdActions.SUCCESS,
    updateBrandGuidelinesByCompanyIdSuccess
  );
}

export default updateBrandGuidelinesByCompanyIdWatcher;
