import React from 'react';

import { SVGProps } from './SVG.props';

const LockFill = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 4.6665V5.99984C2.89543 5.99984 2 6.89527 2 7.99984V13.3332C2 14.4377 2.89543 15.3332 4 15.3332H12C13.1046 15.3332 14 14.4377 14 13.3332V7.99984C14 6.89527 13.1046 5.99984 12 5.99984V4.6665C12 2.45736 10.2091 0.666504 8 0.666504C5.79086 0.666504 4 2.45736 4 4.6665ZM8 1.99984C6.52724 1.99984 5.33333 3.19374 5.33333 4.6665V5.99984H10.6667V4.6665C10.6667 3.19374 9.47276 1.99984 8 1.99984ZM10 10.6665C10 11.7711 9.10457 12.6665 8 12.6665C6.89543 12.6665 6 11.7711 6 10.6665C6 9.56193 6.89543 8.6665 8 8.6665C9.10457 8.6665 10 9.56193 10 10.6665Z"
        fill={fill || '#9D0D11'}
      />
    </svg>
  );
};

export default LockFill;
