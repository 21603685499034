import {
  UpdatePageNameMeta,
  UpdatePageNamePayload,
} from 'types/store/UpdatePageNameState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_PAGE_NAME';

const asyncAction = createAsyncAction<
  UpdatePageNameMeta,
  UpdatePageNamePayload
>(ns);

const updatePageNameActions = {
  ...asyncAction,
};

export default updatePageNameActions;
