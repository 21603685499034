import {
   UpdateCardDetailsMeta,
   UpdateCardDetailsPayload
  } from 'types/store/UpdateCardDetails';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { updateCardDetailsActions } from '../actions';
  
  export default createAsyncReducer<UpdateCardDetailsMeta, UpdateCardDetailsPayload>(
    updateCardDetailsActions
  );
  