import React from 'react';

import { SVGProps } from './SVG.props';

const Infomation: React.FC<SVGProps> = (props: SVGProps) => {
  const { width = 16, height = 16, fill = '#F1B422' } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 16 16"
    >
      <g fill={fill} clipPath="url(#clip0_0_6104)">
        <path d="M6 6.667C6 6.298 6.298 6 6.667 6H8c.368 0 .667.298.667.667V10H10a.667.667 0 010 1.333H6A.667.667 0 116 10h1.333V7.333h-.666A.667.667 0 016 6.667zM7.667 5.333a1 1 0 100-2 1 1 0 000 2z"></path>
        <path
          fillRule="evenodd"
          d="M0 8a8 8 0 1116 0A8 8 0 010 8zm8-6.667a6.667 6.667 0 100 13.334A6.667 6.667 0 008 1.333z"
          clipRule="evenodd"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_0_6104">
          <path fill="#fff" d="M0 0H16V16H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default Infomation;
