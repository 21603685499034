import {
  UpdateEmployeeProfileByAuthMeta,
  UpdateEmployeeProfileByAuthPayload,
} from 'types/store/UpdateEmployeeProfileByAuth';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'UPDATE_EMPLOYEE_PROFILE_BY_AUTH';
const asyncAction = createAsyncAction<
  UpdateEmployeeProfileByAuthMeta,
  UpdateEmployeeProfileByAuthPayload
>(ns);
const clearAction = createClearAction(ns);

const updateEmployeeProfileByAuthActions = {
  ...asyncAction,
  ...clearAction
};

export default updateEmployeeProfileByAuthActions;
