import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GetUserSettingMeta } from 'types/store/GetUserSettingState';
import { ITranslate } from 'types/store/Setting';
import { UpdateUserSettingMeta } from 'types/store/UpdateUserSettingState';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const SETTINGS_URL = `${BASE_URL}/setting`;
const STORY = `${BASE_URL}/story`;

export const getUserSettings = (
  data: GetUserSettingMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SETTINGS_URL}/user`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const updateUserSetting = (
  data: UpdateUserSettingMeta,
  token: string
): any => {
  return axios({
    method: 'put',
    url: `${SETTINGS_URL}/updateUserSettings`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getListLanguage = (token: string): AxiosPromise => {
  return axios({
    method: 'get',
    url: `${SETTINGS_URL}/get-languages-support`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export const translateLanguage = (data: ITranslate, token: string): AxiosPromise => {
  return axios({
    method: 'POST',
    url: `${STORY}/augmented-writing/translate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  });
}
