import {
  GetHeadlineAIMeta,
} from 'types/store/GetAdvocateInterest';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_HEADLINE_AI';
const asyncAction = createAsyncAction<
  GetHeadlineAIMeta,
  any
>(ns);

const getHeadlineAIActions = {
  ...asyncAction,
};

export default getHeadlineAIActions;
