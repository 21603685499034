import {
    GetShowcasePagesByCompanyIdMeta,
    GetShowcasePagesByCompanyIdPayload,
} from 'types/store/GetShowcasePagesByCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_PAGES_BY_COMPANY_ID';
const asyncAction = createAsyncAction<
    GetShowcasePagesByCompanyIdMeta,
    GetShowcasePagesByCompanyIdPayload
>(ns);

const getShowcasePagesByCompanyIdActions = {
    ...asyncAction,
};

export default getShowcasePagesByCompanyIdActions;
