import {
  UpdateArticlePositionMeta,
  UpdateArticlePositionPayload
} from 'types/store/UpdateArticlePosition';
import { createAsyncReducer } from 'utils/Redux';

import { updateArticlePositionActions } from '../actions';

export default createAsyncReducer<
  UpdateArticlePositionMeta,
  UpdateArticlePositionPayload
>(updateArticlePositionActions);
