import {
    GetLibraryAssetsMeta,
    GetLibraryAssetsPayload,
  } from 'types/store/GetLibraryAssetsState';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'GET_LIBRARY_ASSETS';
  
  const asyncAction = createAsyncAction<GetLibraryAssetsMeta, GetLibraryAssetsPayload>(ns);
  
  const getLibraryAssetsActions = {
    ...asyncAction,
  };
  
  export default getLibraryAssetsActions;
  