import { push } from 'connected-react-router';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateCustomerDetails } from 'services/payment';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateStripeCustomerDetailsMeta,
  UpdateStripeCustomerDetailsPayload,
} from 'types/store/UpdateStripeCustomerDetails';

import {
  getStripeCustomerIdActions,
  updateStripeCustomerDetailsActions,
} from '../actions';

function* updateStripeCustomerDetailsRequest(
  action: AsyncAction<
    UpdateStripeCustomerDetailsMeta,
    UpdateStripeCustomerDetailsPayload
  >,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateCustomerDetails,
        action.meta,
        state.auth.token,
      );
      yield put(updateStripeCustomerDetailsActions.success(data));
    } catch (e: any) {
      yield put(updateStripeCustomerDetailsActions.failed(e.message));
    }
  }
}

function* updateStripeCustomerDetailsSuccess(
  action: AsyncAction<
    UpdateStripeCustomerDetailsMeta,
    UpdateStripeCustomerDetailsPayload
  >,
) {
  yield put(getStripeCustomerIdActions.request({}));
  yield put(updateStripeCustomerDetailsActions.clear());
}

function* updateStripeCustomerDetailsWatcher(): any {
  yield takeLatest(
    updateStripeCustomerDetailsActions.REQUEST,
    updateStripeCustomerDetailsRequest,
  );
  yield takeLatest(
    updateStripeCustomerDetailsActions.SUCCESS,
    updateStripeCustomerDetailsSuccess,
  );
}

export default updateStripeCustomerDetailsWatcher;
