import React from 'react';

import { SVGProps } from './SVG.props';

const France = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M39.9998 29.9156C39.9998 32.3701 38.0098 34.3601 35.5554 34.3601H26.6665V5.47119H35.5554C38.0098 5.47119 39.9998 7.46119 39.9998 9.91564V29.9156Z" fill="#ED2939"/>
      <path d="M4.44444 5.47119C1.99 5.47119 0 7.46119 0 9.91564V29.9156C0 32.3701 1.99 34.3601 4.44444 34.3601H13.3333V5.47119H4.44444Z" fill="#002495"/>
      <path d="M13.3335 5.47119H26.6668V34.3601H13.3335V5.47119Z" fill="#EEEEEE"/>
    </svg>
  );
};

export default France;
