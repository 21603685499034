import {
    UpdateShowcasePagePositionMeta,
    UpdateShowcasePagePositionPayload
} from 'types/store/UpdateShowcasePagePositionState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SHOWCASE_PAGE_POSITION';

const asyncAction = createAsyncAction<
    UpdateShowcasePagePositionMeta,
    UpdateShowcasePagePositionPayload
>(ns);

const updateShowcasePagePositionActions = {
    ...asyncAction,
};

export default updateShowcasePagePositionActions;
