import {
  GetSuggestedQuestionsMeta,
  GetSuggestedQuestionsPayload,
} from 'types/store/GetSuggestedQuestions';
import { createAsyncReducer } from 'utils/Redux';

import { getSuggestedQuestionsActions } from '../actions';

export default createAsyncReducer<
  GetSuggestedQuestionsMeta,
  GetSuggestedQuestionsPayload
>(getSuggestedQuestionsActions);
