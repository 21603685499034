import {
  GetCategoryByStoryIdMeta,
  GetCategoryByStoryIdPayload
} from 'types/store/GetCategoryByStoryId';
import { createAsyncReducer } from 'utils/Redux';

import { getCategoryByStoryIdActions } from '../actions';

export default createAsyncReducer<GetCategoryByStoryIdMeta, GetCategoryByStoryIdPayload>(
  getCategoryByStoryIdActions
);
