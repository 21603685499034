import {
    GetFinalStoryPiecesMeta,
    GetFinalStoryPiecesPayload,
  } from 'types/store/GetFinalStoryPieces';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { getFinalStoryPiecesActions } from '../actions';
  
  export default createAsyncReducer<
    GetFinalStoryPiecesMeta,
    GetFinalStoryPiecesPayload
  >(getFinalStoryPiecesActions);
  