import React from 'react'

import { Action } from 'types/Action'
import { ExtractFileContentMeta, ExtractFileContentPayload } from 'types/store/JobsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'JOBS_EXTRACT_CONTENT_REQUEST';

const asyncAction = createAsyncAction<ExtractFileContentMeta, ExtractFileContentPayload>(ns);

const extractFileContentActions = { ...asyncAction };

export default extractFileContentActions;


