import {
  UpdateArticlePositionMeta,
  UpdateArticlePositionPayload
} from 'types/store/UpdateArticlePosition';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_ARTICLE_POSITION';

const asyncAction = createAsyncAction<
  UpdateArticlePositionMeta,
  UpdateArticlePositionPayload
>(ns);

const updateArticlePositionActions = {
  ...asyncAction,
};

export default updateArticlePositionActions;
