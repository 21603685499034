import { put, call, takeLatest, select } from 'redux-saga/effects';
import { downloadStoryVideo } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  DownloadStoryVideoMeta,
  DownloadStoryVideoPayload,
} from 'types/store/DownloadStoryVideo';
import { Store } from 'types/store/Store';
import { API } from 'consts';

import { downloadStoryVideoActions, TOAST } from '../actions';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const STORY_URL = `${BASE_URL}/story`;

function* downloadStoryVideoRequest(
  action: AsyncAction<DownloadStoryVideoMeta, DownloadStoryVideoPayload>,
) {
  const state: Store = yield select();
  const fileTitle: string = state.StoryHubReducer.titleFile;
  if (state.auth.token) {
    try {
      console.log('action: ', action.meta);
      const { data, headers } = yield call(
        downloadStoryVideo,
        action.meta,
        state.auth.token,
      );
      const type = headers['content-type'];
      console.log('type: ', type);

      const blob = new Blob([data], { type: type });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      if (fileTitle) link.download = fileTitle;
      link.click();
      yield put(downloadStoryVideoActions.success(data));
    } catch (e: any) {
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: e.message || 'Download all failed', type: 'error' },
      });
      yield put(downloadStoryVideoActions.failed(e.message));
    }
  }
}

function* downloadStoryVideoRequestV2(
  action: AsyncAction<DownloadStoryVideoMeta, DownloadStoryVideoPayload>,
): Generator<any, void, any> {
  const state: Store = yield select();
  const fileTitle: string = state.StoryHubReducer.titleFile;
  const endpoint = `${STORY_URL}/downloadStoryVideo?story_id=${action.meta.story_id}&is_zip=${action.meta?.is_zip}`;

  if (state.auth.token) {
    try {
      const headers = {
        Authorization: `Bearer ${state.auth.token}`,
      };

      const response: Response = yield call(fetch, endpoint, { headers });

      if (!response.ok) {
        throw new Error('Something went wrong with the network');
      }

      const contentType =
        response.headers.get('content-type') || 'application/octet-stream';
      const contentLength = response.headers.get('content-length');

      const streamSaver = window.streamSaver; // Use streamSaver from the global window object

      // Create a MessageChannel
      const channel = new MessageChannel();

      const fileStream = streamSaver.createWriteStream(
        `${fileTitle}.zip` || 'downloadedFile.zip',
        {
          size: contentLength ? parseInt(contentLength, 10) : undefined,
        },
      );
      const writer = fileStream.getWriter();
      const reader = response.body?.getReader();

      if (!reader) {
        throw new Error('ReadableStream not supported in this browser');
      }

      // Set up the MessageChannel for communication
      navigator.serviceWorker.controller?.postMessage('ping', [channel.port2]);

      const pump = (): Promise<void> =>
        reader.read().then(({ done, value }) => {
          if (done) {
            writer.close();
            return Promise.resolve();
          }
          writer.write(value).then(pump);
          return Promise.resolve();
        });

      yield call(pump);

      yield put(downloadStoryVideoActions.success());
    } catch (e: any) {
      yield put({
        type: TOAST.TOAST_OPEN,
        payload: { message: e.message || 'Download all failed', type: 'error' },
      });
      yield put(downloadStoryVideoActions.failed(e.message));
    }
  }
}

function* downloadStoryVideoWatcher(): any {
  yield takeLatest(
    downloadStoryVideoActions.REQUEST,
    downloadStoryVideoRequestV2,
  );
}

export default downloadStoryVideoWatcher;
