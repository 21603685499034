import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  getContentPackages,
  createContentPackage,
  updateContentPackage,
  deleteContentPackage,
  getContentPackageInCart
} from 'services/content-package';
import { getContactSaleInfoShoppingCart, updateContactSaleInfoShoppingCart } from 'services/payment';
import { createRecipeDetail, updateRecipeDetail } from 'services/recipe';
import { AsyncAction } from 'types/Action';
import {
  GetContentPackagesMeta,
  GetContentPackagesPayload,
  CreateContentPackageMeta,
  CreateContentPackagePayload,
  UpdateContentPackageMeta,
  UpdateContentPackagePayload,
  DeleteContentPackageMeta,
  DeleteContentPackagePayload,
  GetContentPackageInCartMeta,
  GetContentPackageInCartPayload,
  UpdateContentPackageInCartMeta,
  UpdateContentPackageInCartPayload,
  DeleteContentPackageInCartMeta,
  DeleteContentPackageInCartPayload,
  EditRecipeInCartMeta,
  EditRecipeInCartPayload,
  GetContactSaleInfoShoppingCartMeta,
  GetContactSaleInfoShoppingCartPayload,
  UpdateContactSaleInfoShoppingCartMeta,
  UpdateContactSaleInfoShoppingCartPayload
} from 'types/store/ContentPackage';
import { Store } from 'types/store/Store';

import {
  getContentPackagesActions,
  createContentPackageActions,
  updateContentPackageActions,
  deleteContentPackageActions,
  getContentPackageInCartActions,
  updateContentPackageInCartActions,
  deleteContentPackageInCartActions,
  editRecipeInCartActions,
  getContactSaleInfoShoppingCartActions,
  updateContactSaleInfoShoppingCartActions
} from '../actions';

function* getContentPackagesRequest(
  action: AsyncAction<GetContentPackagesMeta, GetContentPackagesPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getContentPackages, state.auth.token);
      yield put(
        getContentPackagesActions.success({ data: data.data, status: data.status })
      );
    } catch (e: any) {
      yield put(getContentPackagesActions.failed(e));
    }
  }
}

function* createContentPackageRequest(
  action: AsyncAction<CreateContentPackageMeta, CreateContentPackagePayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createContentPackage,
        action.meta,
        state.auth.token
      );
      const getAllContentPackages = state.contentPackage.allContentPackages;
      yield put(
        createContentPackageActions.success({
          data: [...getAllContentPackages.data, data.data],
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(createContentPackageActions.failed(e));
    }
  }
}

function* updateContentPackageRequest(
  action: AsyncAction<UpdateContentPackageMeta, UpdateContentPackagePayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const updateData = action.meta;
      const { data } = yield call(
        updateContentPackage,
        action.meta,
        state.auth.token
      );
      const newContentPackages = yield state.contentPackage.allContentPackages.data.map((_) => (_.id === updateData.id ? updateData : _));
      yield put(
        updateContentPackageActions.success({
          data: newContentPackages,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(updateContentPackageActions.failed(e));
    }
  }
}

function* deleteContentPackageRequest(
  action: AsyncAction<DeleteContentPackageMeta, DeleteContentPackagePayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteContentPackage,
        action.meta,
        state.auth.token
      );
      const deleteData = action.meta;
      const newContentPackages = yield state.contentPackage.allContentPackages.data.filter((_) =>
        _.id !== deleteData.id
      )
      yield put(
        deleteContentPackageActions.success({
          data: newContentPackages,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(deleteContentPackageActions.failed(e));
    }
  }
}

function* getContentPackagesInCartRequest(
  action: AsyncAction<GetContentPackageInCartMeta, GetContentPackageInCartPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getContentPackageInCart,
        state.auth.token
      );
      yield put(
        getContentPackageInCartActions.success({
          data: data.data,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(getContentPackageInCartActions.failed(e));
    }
  }
}

function* updateContentPackageInCartRequest(
  action: AsyncAction<UpdateContentPackageInCartMeta, UpdateContentPackageInCartPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateContentPackage,
        action.meta,
        state.auth.token
      );
      const updateData = action.meta;
      const newContentPackages = yield state.contentPackage.contentPackagesInCart.data.map((_) => (_.id === updateData.id ? updateData : _));
      yield put(
        updateContentPackageInCartActions.success({
          data: newContentPackages,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(updateContentPackageInCartActions.failed(e));
    }
  }
}

function* deleteContentPackageInCartRequest(
  action: AsyncAction<DeleteContentPackageInCartMeta, DeleteContentPackageInCartPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteContentPackage,
        action.meta,
        state.auth.token
      );
      const deleteData = action.meta;
      const newContentPackages = yield state.contentPackage.contentPackagesInCart.data.filter((_) =>
        _.id !== deleteData.id
      )
      yield put(
        deleteContentPackageInCartActions.success({
          data: newContentPackages,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(deleteContentPackageInCartActions.failed(e));
    }
  }
}

function* editRecipeInShoppingCartRequest(
  action: AsyncAction<EditRecipeInCartMeta, EditRecipeInCartPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    const requestData = { ...action.meta };
    try {
      let responseData: any = null;
      if (requestData.id) {
        const { data } = yield call(
          updateRecipeDetail,
          requestData,
          requestData.id,
          state.auth.token
        );
        responseData = data.data ? { ...data.data } : { ...action.meta };
      } else {
        const { data } = yield call(
          createRecipeDetail,
          requestData,
          state.auth.token
        );
        responseData = data.data ? { ...data.data } : { ...action.meta };
      }
      const { data } = yield call(getContentPackageInCart, state.auth.token);
      yield put(
        getContentPackageInCartActions.success({
          data: data.data,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(editRecipeInCartActions.failed(e));
    }
  }
}

function* getContactSalesInfoShoppingCartRequest(
  action: AsyncAction<GetContactSaleInfoShoppingCartMeta, GetContactSaleInfoShoppingCartPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getContactSaleInfoShoppingCart,
        state.auth.token
      );
      yield put(
        getContactSaleInfoShoppingCartActions.success({
          data: data.data,
          status: data.status,
        })
      );
    } catch (e: any) {
      yield put(getContactSaleInfoShoppingCartActions.failed(e));
    }
  }
}

function* updateContactSalesInfoShoppingCartRequest(
  action: AsyncAction<UpdateContactSaleInfoShoppingCartMeta, UpdateContactSaleInfoShoppingCartPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateContactSaleInfoShoppingCart,
        action.meta,
        state.auth.token
      );
    } catch (e: any) {
      yield put(updateContactSaleInfoShoppingCartActions.failed(e));
    }
  }
}

function* createContentPackageWatcher(): any {
  yield takeLatest(getContentPackagesActions.REQUEST, getContentPackagesRequest);
  yield takeLatest(createContentPackageActions.REQUEST, createContentPackageRequest);
  yield takeLatest(updateContentPackageActions.REQUEST, updateContentPackageRequest);
  yield takeLatest(deleteContentPackageActions.REQUEST, deleteContentPackageRequest);
  yield takeLatest(getContentPackageInCartActions.REQUEST, getContentPackagesInCartRequest);
  yield takeLatest(updateContentPackageInCartActions.REQUEST, updateContentPackageInCartRequest);
  yield takeLatest(deleteContentPackageInCartActions.REQUEST, deleteContentPackageInCartRequest);
  yield takeLatest(editRecipeInCartActions.REQUEST, editRecipeInShoppingCartRequest);
  yield takeLatest(getContactSaleInfoShoppingCartActions.REQUEST, getContactSalesInfoShoppingCartRequest);
  yield takeLatest(updateContactSaleInfoShoppingCartActions.REQUEST, updateContactSalesInfoShoppingCartRequest);
}

export default createContentPackageWatcher;