import { TranscribeVideoMeta, TranscribeVideoPayload } from 'types/store/TranscribeVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'TRANSCRIBE_VIDEO_NS';

const asyncAction = createAsyncAction<TranscribeVideoMeta, TranscribeVideoPayload>(ns);

const transcribeVideoActions = { ...asyncAction };

export default transcribeVideoActions;
