import { push } from 'connected-react-router';
import { MAIN_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { downloadResponses } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  DownloadResponsesMeta,
  DownloadResponsesPayload,
} from 'types/store/DownloadResponsesState';
import { Store } from 'types/store/Store';

import { downloadResponsesActions } from '../actions';

function* downloadResponsesRequest(
  action: AsyncAction<DownloadResponsesMeta, DownloadResponsesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        downloadResponses,
        action.meta,
        state.auth.token
      );
      yield put(downloadResponsesActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield put(push(MAIN_ROUTES.NOT_FOUND));
      } else {
        yield put(downloadResponsesActions.failed(e.message));
      }
    }
  } else {
    yield put(downloadResponsesActions.failed('Token not found'));
  }
}

// function* downloadResponsesSuccess(
//   action: AsyncAction<DownloadResponsesMeta, DownloadResponsesPayload>
// ) {
// }

function* downloadResponsesWatcher(): any {
  yield takeLatest(downloadResponsesActions.REQUEST, downloadResponsesRequest);
  // yield takeLatest(downloadResponsesActions.SUCCESS, downloadResponsesSuccess);
}

export default downloadResponsesWatcher;
