import FileSaver from 'file-saver';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateAnwer } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateAnswerByIdMeta,
  UpdateAnswerByIdPayload,
} from 'types/store/UpdateAnswerByIdState';

import { updateAnswerByIdActions } from '../actions';
function* updateAnswerByIdRequest(
  action: AsyncAction<UpdateAnswerByIdMeta, UpdateAnswerByIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateAnwer, action.meta, state.auth.token);
      yield put(updateAnswerByIdActions.success(data));
    } catch (e: any) {
      yield put(updateAnswerByIdActions.failed(e.message));
    }
  }
}

function* updateAnswerByIdSuccess(
  action: AsyncAction<UpdateAnswerByIdMeta, UpdateAnswerByIdPayload>
) {
  //
}

function* updateAnswerByIdWatcher(): any {
  yield takeLatest(updateAnswerByIdActions.REQUEST, updateAnswerByIdRequest);
  yield takeLatest(updateAnswerByIdActions.SUCCESS, updateAnswerByIdSuccess);
}

export default updateAnswerByIdWatcher;
