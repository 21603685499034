import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStoriesByCompanyId } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload,
} from 'types/store/GetStoriesByCompanyIdState';
import { Store } from 'types/store/Store';

import { getStoriesByCompanyIdActions } from '../actions';

function* getStoriesByCompanyIdRequest(
  action: AsyncAction<GetStoriesByCompanyIdMeta, GetStoriesByCompanyIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getStoriesByCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(getStoriesByCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getStoriesByCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getStoriesByCompanyIdActions.failed('Token not found'));
  }
}

function* getStoriesByCompanyIdSuccess(
  action: AsyncAction<GetStoriesByCompanyIdMeta, GetStoriesByCompanyIdPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getStoriesByCompanyIdWatcher(): any {
  yield takeLatest(
    getStoriesByCompanyIdActions.REQUEST,
    getStoriesByCompanyIdRequest
  );
  yield takeLatest(
    getStoriesByCompanyIdActions.SUCCESS,
    getStoriesByCompanyIdSuccess
  );
}

export default getStoriesByCompanyIdWatcher;
