import React from 'react';

import { SVGProps } from './SVG.props';

const PlusSquareDashed = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 64}
      height={height || 64}
      viewBox="0 0 66 66"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M33.6666 28.3334C33.6666 27.9652 33.3681 27.6667 33 27.6667C32.6318 27.6667 32.3333 27.9652 32.3333 28.3334V32.3333L28.3333 32.3333C27.9651 32.3333 27.6666 32.6318 27.6666 33C27.6666 33.3681 27.9651 33.6666 28.3333 33.6666L32.3333 33.6666V37.6667C32.3333 38.0349 32.6318 38.3334 33 38.3334C33.3681 38.3334 33.6666 38.0349 33.6666 37.6667V33.6666L37.6666 33.6666C38.0348 33.6666 38.3333 33.3681 38.3333 33C38.3333 32.6318 38.0348 32.3333 37.6666 32.3333L33.6666 32.3333V28.3334Z"
        fill="#030220"
      />
      <rect
        x="1"
        y="1"
        width="64"
        height="64"
        rx="4"
        stroke="#E6E3E4"
        strokeLinecap="round"
        strokeDasharray="6 6"
      />
    </svg>
  );
};

export default PlusSquareDashed;
