import {
  ExtractTextFromFileMeta,
  ExtractTextFromFilePayload,
} from 'types/store/ExtractTextFromFileState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'EXTRACT_TEXT_FROM_FILE';
const asyncAction = createAsyncAction<
  ExtractTextFromFileMeta,
  ExtractTextFromFilePayload
>(ns);

const extractTextFromFileActions = {
  ...asyncAction,
};

export default extractTextFromFileActions;
