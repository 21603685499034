import { DownloadStoryVideoMeta, DownloadStoryVideoPayload } from 'types/store/DownloadStoryVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DOWNLOAD_STORY_VIDEO_NS';

const asyncAction = createAsyncAction<DownloadStoryVideoMeta, DownloadStoryVideoPayload>(ns);

const downloadStoryVideoActions = { ...asyncAction };

export default downloadStoryVideoActions;
