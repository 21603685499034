import React from 'react';

import { SVGProps } from './SVG.props';

const CheckCircle = (props: SVGProps): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="20" height="20" rx="4" fill={props.fill || "#91DBB6"} strokeWidth="2"/>
      <path strokeWidth="2" d="M11.36 8.395L9.215 10.545L8.39 9.72C8.34518 9.66766 8.29002 9.62515 8.22799 9.59514C8.16595 9.56513 8.09839 9.54826 8.02953 9.5456C7.96067 9.54294 7.892 9.55454 7.82784 9.57968C7.76368 9.60482 7.7054 9.64295 7.65668 9.69168C7.60795 9.7404 7.56982 9.79868 7.54468 9.86284C7.51955 9.927 7.50794 9.99567 7.5106 10.0645C7.51326 10.1334 7.53013 10.201 7.56014 10.263C7.59015 10.325 7.63266 10.3802 7.685 10.425L8.86 11.605C8.90672 11.6513 8.96213 11.688 9.02305 11.7129C9.08397 11.7378 9.1492 11.7504 9.215 11.75C9.34617 11.7494 9.47187 11.6974 9.565 11.605L12.065 9.105C12.1119 9.05852 12.1491 9.00322 12.1744 8.94229C12.1998 8.88136 12.2129 8.81601 12.2129 8.75C12.2129 8.68399 12.1998 8.61864 12.1744 8.55771C12.1491 8.49678 12.1119 8.44148 12.065 8.395C11.9713 8.30187 11.8446 8.2496 11.7125 8.2496C11.5804 8.2496 11.4537 8.30187 11.36 8.395ZM10 5C9.0111 5 8.0444 5.29324 7.22215 5.84265C6.39991 6.39206 5.75904 7.17295 5.3806 8.08658C5.00217 9.00021 4.90315 10.0055 5.09608 10.9755C5.289 11.9454 5.76521 12.8363 6.46447 13.5355C7.16373 14.2348 8.05465 14.711 9.02455 14.9039C9.99446 15.0969 10.9998 14.9978 11.9134 14.6194C12.8271 14.241 13.6079 13.6001 14.1573 12.7779C14.7068 11.9556 15 10.9889 15 10C15 9.34339 14.8707 8.69321 14.6194 8.08658C14.3681 7.47995 13.9998 6.92876 13.5355 6.46447C13.0712 6.00017 12.52 5.63188 11.9134 5.3806C11.3068 5.12933 10.6566 5 10 5ZM10 14C9.20888 14 8.43552 13.7654 7.77772 13.3259C7.11992 12.8864 6.60723 12.2616 6.30448 11.5307C6.00173 10.7998 5.92252 9.99556 6.07686 9.21964C6.2312 8.44372 6.61216 7.73098 7.17158 7.17157C7.73099 6.61216 8.44372 6.2312 9.21964 6.07686C9.99557 5.92252 10.7998 6.00173 11.5307 6.30448C12.2616 6.60723 12.8864 7.11992 13.3259 7.77772C13.7654 8.43552 14 9.20888 14 10C14 11.0609 13.5786 12.0783 12.8284 12.8284C12.0783 13.5786 11.0609 14 10 14Z" fill="#070550"/>
    </svg>
  );
};

export default CheckCircle;
