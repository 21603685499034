import axios, { AxiosResponse } from 'axios';
import { API } from 'consts';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const UPLOAD_URL = `${BASE_URL}/upload`;

export const uploadFile = async (opts: {
  file: File;
  token: string;
  folder?: string;
  customFileName?: string;
  onUploadProgress?: (receive: number, total: number) => void;
}): Promise<string> => {
  const { file, token, folder, customFileName, onUploadProgress } = opts;
  const params = new URLSearchParams();
  folder && params.append('folder', folder);
  params.append('fileType', file.type);
  params.append('fileName', customFileName ? `${customFileName}` : file.name);
  const getSignUrl = await axios({
    method: 'get',
    url: `${UPLOAD_URL}/get-upload-link?${params}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (getSignUrl?.status === 200) {
    const url = getSignUrl?.data?.data?.url;
    let uploadRes: AxiosResponse;
    if (getSignUrl?.data?.data?.serviceName === 's3') {
      const formData = new FormData();
      for (const key in getSignUrl?.data.data?.fields) {
        formData.append(key, getSignUrl?.data?.data?.fields[key]);
      }
      const axiosMethod: any = {
        url,
        data: formData,
        headers: {
          'Content-Type': file.type,
        },
      };
      axiosMethod.method = 'POST';
      formData.append('file', file, customFileName || file.name);
      uploadRes = await axios(axiosMethod);
      if (uploadRes.status === 200 || uploadRes.status === 204) {
        return getSignUrl?.data?.data?.publicUrl;
      }
      throw new Error(uploadRes?.statusText);
    } else {
      await uploadFileByPresignUrl({
        file,
        url,
      });

      return getSignUrl?.data?.data?.publicUrl;
    }
  } else {
    throw new Error(getSignUrl?.statusText);
  }
};

export const uploadFileByPresignUrl = ({
  file,
  url,
}: {
  file: File;
  url: string;
}): Promise<string> => {
  const reader = new FileReader();
  reader.readAsArrayBuffer(file);
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const data = reader.result;
      const uploadRes = await axios.put(url, data, {
        headers: {
          'Content-Type': file.type,
        },
      });
      if (uploadRes.status === 200 || uploadRes.status === 204) {
        resolve(url);
      } else {
        reject(uploadRes?.statusText);
      }
    };
  });
};
