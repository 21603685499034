import {
  GetTimelineEventsMeta,
  GetTimelineEventsPayload,
} from 'types/store/GetTimelineEventsState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_TIMELINE_EVENTS';

const asyncAction = createAsyncAction<
  GetTimelineEventsMeta,
  GetTimelineEventsPayload
>(ns);
const clearAction = createClearAction(ns);

const getTimelineEventsActions = {
  ...asyncAction,
  ...clearAction,
};

export default getTimelineEventsActions;
