import {
  GetBillingAddressMeta,
  GetBillingAddressPayload
} from 'types/store/GetBillingAddress';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_BILLING_ADDRESS';
const asyncAction = createAsyncAction<
  GetBillingAddressMeta,
  GetBillingAddressPayload
>(ns);

const getBillingAddressActions = {
  ...asyncAction,
};

export default getBillingAddressActions;
