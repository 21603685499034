import { push } from 'connected-react-router';
import { MAIN_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { uploadApprovalStory } from 'services/approvers';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UploadApprovalStoryMeta,
  UploadApprovalStoryPayload,
} from 'types/store/UploadApprovalStoryState';

import {
  uploadApprovalStoryActions,
} from '../actions';

function* uploadApprovalStoryRequest(
  action: AsyncAction<
    UploadApprovalStoryMeta,
    UploadApprovalStoryPayload
  >
) {
  const state: Store = yield select();

  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadApprovalStory,
        action.meta,
        state.auth.token
      );
      yield put(uploadApprovalStoryActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield put(push(MAIN_ROUTES.NOT_FOUND));
      } else {
        yield put(uploadApprovalStoryActions.failed(e.message));
      }
    }
  } else {
    yield put(uploadApprovalStoryActions.failed('Token not found'));
  }
}

function* uploadApprovalStoryWatcher(): any {
  yield takeLatest(
    uploadApprovalStoryActions.REQUEST,
    uploadApprovalStoryRequest
  );
}

export default uploadApprovalStoryWatcher;
