import { put, call, takeLatest, select } from 'redux-saga/effects';
import { sendUpgradeMail } from 'services/company';
import { AsyncAction } from 'types/Action';
import { SendUpgradeMailMeta, SendUpgradeMailPayload } from 'types/store/SendUpgradeMail';
import { Store } from 'types/store/Store';

import { sendUpgradeMailActions } from '../actions';

function* sendUpgradeMailRequest(action: AsyncAction<SendUpgradeMailMeta, SendUpgradeMailPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(sendUpgradeMail, action.meta, state.auth.token);
      yield put(sendUpgradeMailActions.success(data));
    } catch (e: any) {
      yield put(sendUpgradeMailActions.failed(e.message));
    }
  }
}

function* sendUpgradeMailSuccess(action: AsyncAction<SendUpgradeMailMeta, SendUpgradeMailPayload>) {
  // yield put()
}

function* sendUpgradeMailWatcher(): any {
  yield takeLatest(sendUpgradeMailActions.REQUEST, sendUpgradeMailRequest);
  yield takeLatest(sendUpgradeMailActions.SUCCESS, sendUpgradeMailSuccess);
}

export default sendUpgradeMailWatcher;
