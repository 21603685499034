import { AnswerMultiQuestionMeta, AnswerMultiQuestionPayload } from 'types/store/AnswerMultiQuestion';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ANSWER_MULTI_QUESTION_NS';

const asyncAction = createAsyncAction<AnswerMultiQuestionMeta, AnswerMultiQuestionPayload>(ns);

const answerMultiQuestionActions = { ...asyncAction };

export default answerMultiQuestionActions;
