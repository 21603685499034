import {
    CreateShowcaseMeta,
    CreateShowcasePayload,
} from 'types/store/CreateShowcaseState';
import { createAsyncReducer } from 'utils/Redux';

import { createShowcaseActions } from '../actions';

export default createAsyncReducer<
    CreateShowcaseMeta,
    CreateShowcasePayload
>(createShowcaseActions);
