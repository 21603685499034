import { GenericResponse } from 'types/GenericResponse';
import { createAsyncReducer } from 'utils/Redux';

import recipeStoryActions, {
  IRecipeStoryMeta,
} from '../../actions/contentPlanner/recipeStory';

export default createAsyncReducer<IRecipeStoryMeta, GenericResponse>(
  recipeStoryActions,
);
