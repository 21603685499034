import { put, call, takeLatest, select } from 'redux-saga/effects';
import { addStoryDiscussionComment } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  AddStoryDiscussionCommentMeta,
  AddStoryDiscussionCommentPayload,
} from 'types/store/AddStoryDiscussionComment';
import { Store } from 'types/store/Store';

import {
  addStoryDiscussionCommentActions,
  getStoryDiscussionCommentsActions,
} from '../actions';

function* addStoryDiscussionCommentRequest(
  action: AsyncAction<
    AddStoryDiscussionCommentMeta,
    AddStoryDiscussionCommentPayload
  >
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        addStoryDiscussionComment,
        action.meta,
        state.auth.token
      );
      if (data.status === 200) {
        const currentStoryComment = state.getStoryDiscussionComments.data;
        yield put(
          getStoryDiscussionCommentsActions.success({
            ...currentStoryComment,
            data: [...currentStoryComment?.data, data.data],
          })
        );
        yield put(addStoryDiscussionCommentActions.clear());
      }
      // yield put(addStoryDiscussionCommentActions.success(data));
    } catch (e: any) {
      yield put(addStoryDiscussionCommentActions.failed(e.message));
    }
  }
}

// function* addStoryDiscussionCommentSuccess(action: AsyncAction<AddStoryDiscussionCommentMeta, AddStoryDiscussionCommentPayload>): any {
//   yield put()
// }

function* addStoryDiscussionCommentWatcher(): any {
  yield takeLatest(
    addStoryDiscussionCommentActions.REQUEST,
    addStoryDiscussionCommentRequest
  );
  // yield takeLatest(addStoryDiscussionCommentActions.SUCCESS, addStoryDiscussionCommentSuccess);
}

export default addStoryDiscussionCommentWatcher;
