import React from 'react';

import { SVGProps } from './SVG.props';

const Download = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox='0 0 16 16'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M13.9997 9.33399C13.8229 9.33399 13.6533 9.40422 13.5283 9.52925C13.4032 9.65427 13.333 9.82384 13.333 10.0007V12.6673C13.333 12.8441 13.2628 13.0137 13.1377 13.1387C13.0127 13.2637 12.8432 13.334 12.6663 13.334H3.33301C3.1562 13.334 2.98663 13.2637 2.8616 13.1387C2.73658 13.0137 2.66634 12.8441 2.66634 12.6673V10.0007C2.66634 9.82384 2.5961 9.65427 2.47108 9.52925C2.34605 9.40422 2.17649 9.33399 1.99967 9.33399C1.82286 9.33399 1.65329 9.40422 1.52827 9.52925C1.40325 9.65427 1.33301 9.82384 1.33301 10.0007V12.6673C1.33301 13.1978 1.54372 13.7065 1.91879 14.0815C2.29387 14.4566 2.80257 14.6673 3.33301 14.6673H12.6663C13.1968 14.6673 13.7055 14.4566 14.0806 14.0815C14.4556 13.7065 14.6663 13.1978 14.6663 12.6673V10.0007C14.6663 9.82384 14.5961 9.65427 14.4711 9.52925C14.3461 9.40422 14.1765 9.33399 13.9997 9.33399ZM7.52634 10.474C7.58974 10.5347 7.66451 10.5823 7.74634 10.614C7.82614 10.6493 7.91243 10.6675 7.99968 10.6675C8.08692 10.6675 8.17321 10.6493 8.25301 10.614C8.33484 10.5823 8.40961 10.5347 8.47301 10.474L11.1397 7.80732C11.2652 7.68178 11.3357 7.51152 11.3357 7.33399C11.3357 7.15645 11.2652 6.98619 11.1397 6.86065C11.0141 6.73512 10.8439 6.66459 10.6663 6.66459C10.4888 6.66459 10.3185 6.73512 10.193 6.86065L8.66634 8.39399V2.00065C8.66634 1.82384 8.5961 1.65427 8.47108 1.52925C8.34606 1.40422 8.17649 1.33398 7.99968 1.33398C7.82286 1.33398 7.6533 1.40422 7.52827 1.52925C7.40325 1.65427 7.33301 1.82384 7.33301 2.00065V8.39399L5.80634 6.86065C5.74418 6.79849 5.67039 6.74919 5.58917 6.71555C5.50796 6.6819 5.42091 6.66459 5.33301 6.66459C5.2451 6.66459 5.15806 6.6819 5.07684 6.71555C4.99563 6.74919 4.92183 6.79849 4.85967 6.86065C4.79752 6.92281 4.74821 6.9966 4.71457 7.07782C4.68093 7.15903 4.66361 7.24608 4.66361 7.33399C4.66361 7.42189 4.68093 7.50894 4.71457 7.59015C4.74821 7.67137 4.79752 7.74516 4.85967 7.80732L7.52634 10.474Z'
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Download;
