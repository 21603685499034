import InviteToShare from 'components/module/ModalShareLink/InviteToShare'

export interface EmailTemplateProps { }

export enum EmailTemplateType {
    ShareContentPlanner = 'SHARE_CONTENT_PLANNER',
    InviteTribeLeader = 'INVITE_TRIBE_LEADER',
    InviteForAdvocate = 'INVITE_FOR_ADVOCATE',
    AssignNewStory = 'ASSIGN_NEW_STORY',
    SendANudge = 'SEND_A_NUDGE',
    InviteToShare = 'INVITE_ADVOCATE_SHARE_STORY',
}

export const EmailTemplateTypeText: Record<string, string> = {
    INVITE_FOR_ADVOCATE: 'Invite Advocates',
    SEND_A_NUDGE: 'Nudge Stories',
    ASSIGN_NEW_STORY: 'Assign Stories',
    SHARE_CONTENT_PLANNER: 'Share Content Planner',
    INVITE_TRIBE_LEADER: 'Invite for Tribe Leaders',
    INVITE_ADVOCATE_SHARE_STORY: 'Share Stories',
}

export const EmailTemplateShortCode: Record<string, string[]> = {
    INVITE_FOR_ADVOCATE: ['advocateName', 'companyName', 'ebName'],
    SEND_A_NUDGE: ['advocateName', 'headline', 'questions', 'ebName'],
    ASSIGN_NEW_STORY: ['advocateName', 'headline', 'questions', 'ebName'],
    SHARE_CONTENT_PLANNER: ['name', 'ebName'],
    INVITE_TRIBE_LEADER: [],
    INVITE_ADVOCATE_SHARE_STORY: ['advocateName', 'companyName', 'ebName'],
}
