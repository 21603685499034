import { GetDataRecipeDetailMeta, GetDataRecipeDetailPayload } from 'types/store/GetDataRecipeDetail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_DATA_RECIPE_DETAIL_NS';

const asyncAction = createAsyncAction<GetDataRecipeDetailMeta, GetDataRecipeDetailPayload>(ns);

const getDataRecipeDetailActions = { ...asyncAction };

export default getDataRecipeDetailActions;
