import { put, call, takeLatest, select } from 'redux-saga/effects';
import { sendEmployerBrandingInvitation } from 'services/employee';
import { AsyncAction } from 'types/Action';
import {
  SendEmployerBrandingInvitationMeta,
  SendEmployerBrandingInvitationPayload,
} from 'types/store/SendEmployerBrandingInvitation';
import { Store } from 'types/store/Store';

import { sendEmployerBrandingInvitationActions } from '../actions';

function* sendEmployerBrandhingInvitationRequest(
  action: AsyncAction<
    SendEmployerBrandingInvitationMeta,
    SendEmployerBrandingInvitationPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        sendEmployerBrandingInvitation,
        action.meta,
        state.auth.token
      );
      yield put(sendEmployerBrandingInvitationActions.success(data));
    } catch (e: any) {
      //
    }
  } else {
    //
  }
}

function* sendEmployerBrandhingInvitationWatcher(): any {
  yield takeLatest(
    sendEmployerBrandingInvitationActions.REQUEST,
    sendEmployerBrandhingInvitationRequest
  );
}

export default sendEmployerBrandhingInvitationWatcher;
