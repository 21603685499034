import React from 'react';

import { SVGProps } from './SVG.props';

const Library = (props: SVGProps): JSX.Element => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M4 5.666h.667a.667.667 0 000-1.333H4a.667.667 0 000 1.333zm2.667 4H4A.667.667 0 004 11h2.667a.667.667 0 000-1.334zm0-2.666H4a.667.667 0 100 1.333h2.667a.667.667 0 000-1.333zm4.613-1.747a.666.666 0 00-.14-.727l-4-4A.713.713 0 006.953.4a.213.213 0 00-.06 0L6.707.333h-4.04a2 2 0 00-2 2v9.333a2 2 0 002 2h4a.667.667 0 100-1.333h-4A.666.666 0 012 11.666V2.333a.667.667 0 01.667-.667H6v2a2 2 0 002 2h2.667a.667.667 0 00.613-.413zM8 4.333a.667.667 0 01-.667-.667v-1.06L9.06 4.333H8zM12.667 7H9.333a.667.667 0 00-.666.666V13a.666.666 0 00.353.586.665.665 0 00.667-.033L11 12.686l1.333.867a.666.666 0 001-.553V7.666A.667.667 0 0012.667 7zM12 11.746l-.627-.42a.666.666 0 00-.746 0l-.627.42V8.333h2v3.413z"
        fill="#070550"
      />
    </svg>
  );
};

export default Library;
