import { EditAdvCustomFieldState } from 'types/store/EditAdvCustomFieldState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'EDIT_ADV_CUSTOM_FIELD';
const updateAction = createUpdateAction<EditAdvCustomFieldState>(ns);
const clearAction = createClearAction(ns);

const editAdvCustomFieldActions = {
  ...updateAction,
  ...clearAction,
};

export default editAdvCustomFieldActions;
