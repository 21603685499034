import {
  GetAllPlatformMeta,
  GetAllPlatformPayload,
} from 'types/store/GetAllPlatform';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ALL_PLATFORM_ACTIONS_NS';

const asyncAction = createAsyncAction<
  GetAllPlatformMeta,
  GetAllPlatformPayload
>(ns);

const getAllPlatformActions = { ...asyncAction };

export default getAllPlatformActions;
