import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getInterestsByCategoryId } from 'services/category';
import { AsyncAction } from 'types/Action';
import { GetInterestsByCategoryIdMeta, GetInterestsByCategoryIdPayload } from 'types/store/GetInterestsByCategoryId';
import { Store } from 'types/store/Store';

import { getInterestsByCategoryIdActions } from '../actions';

function* getInterestsByCategoryIdRequest(action: AsyncAction<GetInterestsByCategoryIdMeta, GetInterestsByCategoryIdPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getInterestsByCategoryId, action.meta, state.auth.token);
      yield put(getInterestsByCategoryIdActions.success(data));
    } catch (e: any) {
      yield put(getInterestsByCategoryIdActions.failed(e.message));
    }
  }
}

// function* getInterestsByCategoryIdSuccess(action: AsyncAction<GetInterestsByCategoryIdMeta, GetInterestsByCategoryIdPayload>): any {
//   yield put()
// }

function* getInterestsByCategoryIdWatcher(): any {
  yield takeLatest(getInterestsByCategoryIdActions.REQUEST, getInterestsByCategoryIdRequest);
  // yield takeLatest(getInterestsByCategoryIdActions.SUCCESS, getInterestsByCategoryIdSuccess);
}

export default getInterestsByCategoryIdWatcher;
