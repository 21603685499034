import {
  UpdateRevisionMeta,
  UpdateRevisionPayload,
} from 'types/store/UpdateRevisionState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_REVISION';
const asyncAction = createAsyncAction<
  UpdateRevisionMeta,
  UpdateRevisionPayload
>(ns);

const updateRevisionActions = {
  ...asyncAction,
};

export default updateRevisionActions;
