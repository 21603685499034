import {
  GetShowcaseFeedbacksByUrlMeta,
  GetShowcaseFeedbacksByUrlPayload,
} from 'types/store/GetShowcaseFeedbacksByUrlState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcaseFeedbacksByUrlActions } from '../actions';

export default createAsyncReducer<
  GetShowcaseFeedbacksByUrlMeta,
  GetShowcaseFeedbacksByUrlPayload
>(getShowcaseFeedbacksByUrlActions);
