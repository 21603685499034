import { Action, ActionCallback } from 'types/Action';
import { GenerateGif } from 'types/store/ShareLibrary';

export enum GET_LIST_MEDIA {
  GET_LIST_MEDIA_REQUEST = '@@_GET_LIST_MEDIA_REQUEST',
  GET_LIST_MEDIA_SUCCESS = '@@_GET_LIST_MEDIA_SUCCESS',
  GET_LIST_MEDIA_ERROR = '@@_GET_LIST_MEDIA_ERROR',
}

export enum GENERATE_GIF {
  GENERATE_GIF_RUN_ALL = '@@_GENERATE_GIF_RUN_ALL',
  GENERATE_GIF_REQUEST = '@@_GENERATE_GIF_REQUEST',
  GENERATE_GIF_SUCCESS = '@@_GENERATE_GIF_SUCCESS',
  GENERATE_GIF_ERROR = '@@_GENERATE_GIF_ERROR',
  GENERATE_GIF_CLEAR = '@@_GENERATE_GIF_CLEAR',
}

export enum SEND_EMBED_EMAIL {
  SEND_EMBED_EMAIL_REQUEST = '@@_SEND_EMBED_EMAIL_REQUEST',
  SEND_EMBED_EMAIL_SUCCESS = '@@_SEND_EMBED_EMAIL_SUCCESS',
}

export enum GET_EMBED_JS {
  GET_EMBED_JS_REQUEST = '@@_GET_EMBED_JS_REQUEST',
  GET_EMBED_JS_SUCCESS = '@@_GET_EMBED_JS_SUCCESS',
}

export enum SAVE_CONTENT_GENERATE {
  SAVE_CONTENT_GENERATE_REQUEST = '@@_SAVE_CONTENT_GENERATE_REQUEST',
}

export enum GET_LIST_EMAIL {
  GET_LIST_EMAIL_REQUEST = '@@_GET_LIST_EMAIL_REQUEST',
  GET_LIST_EMAIL_SUCCESS = '@@_GET_LIST_EMAIL_SUCCESS',
}

export enum GET_MAGIC_LINK {
  GET_MAGIC_LINK_REQUEST = '@@_GET_MAGIC_LINK_REQUEST',
  GET_MAGIC_LINK_SUCCESS = '@@_GET_MAGIC_LINK_SUCCESS',
}

export enum GET_OKTA_SETTING {
  GET_OKTA_SETTING_REQUEST = '@@_GET_OKTA_SETTING_REQUEST',
  GET_OKTA_SETTING_SUCCESS = '@@_GET_OKTA_SETTING_SUCCESS',
  GET_OKTA_SETTING_ERROR = '@@_GET_OKTA_SETTING_ERROR',
}

export enum OKTA_REMOVE_SSO {
  OKTA_REMOVE_SSO_REQUEST = '@@_OKTA_REMOVE_SSO_REQUEST',
}

export enum UPLOAD_SSO {
  UPLOAD_SSO_REQUEST = '@@_UPLOAD_SSO_REQUEST',
  UPLOAD_SSO_SUCCESS = '@@_UPLOAD_SSO_SUCCESS',
  UPLOAD_SSO_ERROR = '@@_UPLOAD_SSO_ERROR',
}

export enum RENEW_SSO_CERT {
  RENEW_SSO_CERT_REQUEST = '@@_RENEW_SSO_CERT_REQUEST',
  RENEW_SSO_CERT_SUCCESS = '@@_RENEW_SSO_CERT_SUCCESS',
  RENEW_SSO_CERT_ERROR = '@@_RENEW_SSO_CERT_ERROR',
}

export enum GET_HTML_GMAIL_CODE {
  GET_HTML_GMAIL_CODE_REQUEST = '@@_GET_HTML_GMAIL_CODE_REQUEST',
  GET_HTML_GMAIL_CODE_SUCCESS = '@@_GET_HTML_GMAIL_CODE_SUCCESS',
  GET_HTML_GMAIL_CODE_ERROR = '@@_GET_HTML_GMAIL_CODE_ERROR',
}

export const removeSSORequest = (): Action => ({
  type: OKTA_REMOVE_SSO.OKTA_REMOVE_SSO_REQUEST,
  payload: {},
});

export const getHtmlGmail_Code = (payload: {
  callback: (name: boolean) => void;
  content: string;
  libraryId?: string;
  storyId?: string;
}): any => ({
  type: GET_HTML_GMAIL_CODE.GET_HTML_GMAIL_CODE_REQUEST,
  payload,
});

export const getMagicLinkRequest = (payload: {
  storyId?: string;
  libraryId?: string;
  content?: string;
  type?: 'LIBRARY_SHARE';
  callback?: (name: boolean) => void;
}): any => ({
  type: GET_MAGIC_LINK.GET_MAGIC_LINK_REQUEST,
  payload,
});

export const getListMedia = (payload: {
  storyId: string;
  isStory: boolean;
}): any => ({
  type: GET_LIST_MEDIA.GET_LIST_MEDIA_REQUEST,
  payload,
});

export const saveContent = (payload: string): any => ({
  type: SAVE_CONTENT_GENERATE.SAVE_CONTENT_GENERATE_REQUEST,
  payload,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendEmbedEmail = (
  data: any,
  callback: () => void,
): ActionCallback => ({
  type: SEND_EMBED_EMAIL.SEND_EMBED_EMAIL_REQUEST,
  payload: data,
  callback,
});

export const getEmbedJsCode = (storyId: string, content?: string): any => ({
  type: GET_EMBED_JS.GET_EMBED_JS_REQUEST,
  payload: { storyId, content },
});

export const getListEmailAction = (): any => ({
  type: GET_LIST_EMAIL.GET_LIST_EMAIL_REQUEST,
});

export const getOktaSetting = (): any => ({
  type: GET_OKTA_SETTING.GET_OKTA_SETTING_REQUEST,
});

export const updateSSORequest = (payload: {
  certificate?: File;
  signture?: string;
  issuer: string | '';
  entryPoint: string | '';
  provider: string | '';
}): any => ({
  type: UPLOAD_SSO.UPLOAD_SSO_REQUEST,
  payload,
});

export const generateGifRequest = (payload: GenerateGif): Action => ({
  type: GENERATE_GIF.GENERATE_GIF_REQUEST,
  payload,
});

export const generateAllGif = (payload: number): Action => ({
  type: GENERATE_GIF.GENERATE_GIF_RUN_ALL,
  payload
})

export const renewSSOVerificationCert = (): any => ({
  type: RENEW_SSO_CERT.RENEW_SSO_CERT_REQUEST,
  payload: {},
});
