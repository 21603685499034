import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getHomePageShortcuts } from 'services/homepage';
import { AsyncAction } from 'types/Action';
import {
  GetHomePageShortcutsMeta,
  GetHomePageShortcutsPayload,
} from 'types/store/GetHomePageShortcutsState';
import { Store } from 'types/store/Store';

import { getHomePageShortcutsActions } from '../actions';

function* getHomePageShortcutsRequest(
  action: AsyncAction<GetHomePageShortcutsMeta, GetHomePageShortcutsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getHomePageShortcuts,
        action.meta,
        state.auth.token
      );
      yield put(getHomePageShortcutsActions.success(data));
    } catch (e: any) {
      yield put(getHomePageShortcutsActions.failed(e.message));
    }
  } else {
    yield put(getHomePageShortcutsActions.failed('Token not found'));
  }
}

function* getHomePageShortcutsSuccess(
  action: AsyncAction<GetHomePageShortcutsMeta, GetHomePageShortcutsPayload>
) {
  // yield put(getHomePageShortcutsActions.clear());
}

function* getHomePageShortcutsWatcher(): any {
  yield takeLatest(
    getHomePageShortcutsActions.REQUEST,
    getHomePageShortcutsRequest
  );
  yield takeLatest(
    getHomePageShortcutsActions.SUCCESS,
    getHomePageShortcutsSuccess
  );
}

export default getHomePageShortcutsWatcher;
