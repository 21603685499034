import { CreateSmsTemplateMeta, CreateSmsTemplatePayload } from 'types/store/CreateSmsTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_SMS_TEMPLATE_NS';

const asyncAction = createAsyncAction<CreateSmsTemplateMeta, CreateSmsTemplatePayload>(ns);

const createSmsTemplateActions = { ...asyncAction };

export default createSmsTemplateActions;
