import {
  GetAdvocateInterestMeta,
  GetAdvocateInterestPayload,
} from 'types/store/GetAdvocateInterest';
import { createAsyncReducer } from 'utils/Redux';

import { getAdvocateInterestActions } from '../actions';

export default createAsyncReducer<
  GetAdvocateInterestMeta,
  GetAdvocateInterestPayload
>(getAdvocateInterestActions);
