import React from 'react';

import { SVGProps } from './SVG.props';

const VerticalDots = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 17} height={height || 16} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="ellipsis-v">
        <path id="Vector" d="M8.74666 4.66667C9.01037 4.66667 9.26816 4.58847 9.48743 4.44196C9.70669 4.29545 9.87759 4.08721 9.9785 3.84358C10.0794 3.59994 10.1058 3.33185 10.0544 3.07321C10.0029 2.81457 9.87594 2.57699 9.68947 2.39052C9.503 2.20405 9.26543 2.07707 9.00679 2.02562C8.74814 1.97417 8.48005 2.00058 8.23642 2.10149C7.99279 2.20241 7.78455 2.37331 7.63804 2.59257C7.49153 2.81184 7.41333 3.06963 7.41333 3.33333C7.41333 3.68696 7.55381 4.02609 7.80386 4.27614C8.0539 4.52619 8.39304 4.66667 8.74666 4.66667ZM8.74666 11.3333C8.48296 11.3333 8.22517 11.4115 8.0059 11.558C7.78664 11.7045 7.61574 11.9128 7.51482 12.1564C7.41391 12.4001 7.3875 12.6681 7.43895 12.9268C7.4904 13.1854 7.61739 13.423 7.80386 13.6095C7.99033 13.7959 8.2279 13.9229 8.48654 13.9744C8.74519 14.0258 9.01327 13.9994 9.25691 13.8985C9.50054 13.7976 9.70878 13.6267 9.85529 13.4074C10.0018 13.1882 10.08 12.9304 10.08 12.6667C10.08 12.313 9.93952 11.9739 9.68947 11.7239C9.43943 11.4738 9.10029 11.3333 8.74666 11.3333ZM8.74666 6.66667C8.48296 6.66667 8.22517 6.74487 8.0059 6.89137C7.78664 7.03788 7.61574 7.24612 7.51482 7.48976C7.41391 7.73339 7.3875 8.00148 7.43895 8.26012C7.4904 8.51876 7.61739 8.75634 7.80386 8.94281C7.99033 9.12928 8.2279 9.25627 8.48654 9.30771C8.74519 9.35916 9.01327 9.33276 9.25691 9.23184C9.50054 9.13092 9.70878 8.96003 9.85529 8.74076C10.0018 8.52149 10.08 8.26371 10.08 8C10.08 7.64638 9.93952 7.30724 9.68947 7.05719C9.43943 6.80714 9.10029 6.66667 8.74666 6.66667Z" fill={fill || "#070550"} />
      </g>
    </svg>
  );
};

export default VerticalDots;



