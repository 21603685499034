import { P } from 'components/base/Typography/Typography.style';
import { push } from 'connected-react-router';
import { ADVOCATE_ROUTES, EMPLOYER_ROUTES } from 'consts';
import { i18n } from 'locales/i18n';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { login, getAllPlatform, accessRouter } from 'services/auth';
import { Action, AsyncAction } from 'types/Action';
import { LoginMeta, LoginPayload, PlatForm } from 'types/store/LoginState';
import { TrackAutoType } from 'types/store/TrackLoginMeta';
import { Store } from 'types/store/Store';
import accessRouterActions, {
  AccessRouterMeta,
} from 'store/actions/auth/accessRouter';
import { GenericResponse } from 'types/GenericResponse';

import {
  loginActions,
  authActions,
  registerEbWithExistedAccountActions,
  getOnboardActions,
  openToast,
  autoTrackLoginAction,
} from '../actions';

function* loginRequest(action: AsyncAction<LoginMeta, LoginPayload>) {
  try {
    const { fromSignup } = action.meta;
    const { data } = yield call(login, action.meta);

    yield put(loginActions.success({ ...data, fromSignup }));

    yield put(autoTrackLoginAction.request({ action: TrackAutoType.auto }));
  } catch (e: any) {
    let message;
    if (e && Object.keys(e).length === 0) {
      message = 'Server busy, please try again later';
    } else {
      message = e.response?.data?.message || e.message;
    }
    yield put(
      loginActions.failed(
        JSON.stringify({
          message,
          headline: e.response?.data?.messageHeader,
        }),
      ),
    );
  }
}

function* loginSuccess(action: AsyncAction<LoginMeta, LoginPayload>): any {
  const state: Store = yield select();
  const locationState: any = state.router.location.search;
  const urlParams: URLSearchParams | null = locationState
    ? new URLSearchParams(locationState)
    : null;
  const registerEBWithExistedEmail = urlParams?.get(
    'registerEBWithExistedEmail',
  );
  const redirectURL = urlParams?.get('redirect');
  const ebFillData = urlParams?.get('ebFillData');
  const languageCode = action.payload.data.language?.code;
  if (languageCode) i18n(languageCode);
  if (registerEBWithExistedEmail && ebFillData) {
    yield put(
      registerEbWithExistedAccountActions.request(JSON.parse(ebFillData)),
    );
  } else {
    yield put(
      authActions.update({
        token: action.payload.data.token,
        platform: action.payload.data.platform,
        refresh_token: action.payload.data.refresh_token,
        logoutUrl: action.payload.data?.logoutSSOUrl,
        isFirst: action.payload.data?.is_first,
        system_message: action.payload?.data?.system_message,
        permissionRouter: action.payload?.data?.access_routes || [],
      }),
    );
    yield put(getOnboardActions.request());
    if (redirectURL) {
      yield put(push(redirectURL));
    }
    if (action.payload.data?.storyId) {
      yield put(
        push(`${ADVOCATE_ROUTES.ROOT}/story/${action.payload.data.storyId}`),
      );
    } else if (action.payload.data.platform === PlatForm.ADVOCATE) {
      yield put(
        push(redirectURL ? redirectURL : ADVOCATE_ROUTES.STORY_MANAGEMENT),
      );
    } else if (
      action.payload.data.platform === PlatForm.EMPLOYER ||
      action.payload.data.platform === PlatForm.RECRUITER
    ) {
      if (action.payload.fromSignup) {
        yield put(push(redirectURL ? redirectURL : EMPLOYER_ROUTES.ONBOARDING));
      } else {
        let URL = EMPLOYER_ROUTES.CONTENT_CALENDAR;
        if (action.payload.data.token) {
          const { data } = yield call(
            getAllPlatform,
            action.payload.data.token,
          );
          if (action.payload.data?.platform === PlatForm.RECRUITER) {
            URL = data?.data?.recruiterJD
              ? EMPLOYER_ROUTES.HOMEPAGE_JD
              : EMPLOYER_ROUTES.HOMEPAGE;
          } else if (data?.data?.renderHomePage) URL = EMPLOYER_ROUTES.HOMEPAGE;
        }
        yield put(
          //push(EMPLOYER_ROUTES.CONTENT_CALENDAR)
          push(URL),
        );
      }
    }
  }
}

function* loginFailed(action: AsyncAction<LoginMeta, LoginPayload>) {
  const actionError = JSON.parse(action.error);
  let error = '';
  if (actionError?.message?.toLocaleLowerCase().includes('timeout')) {
    error = 'Uh-oh! Your login attempt has timed out.';
  } else error = actionError.message;
  yield put(openToast(error, 'error', actionError?.headline));
}

function* dismissMessage() {
  yield put(
    authActions.update({
      system_message: '',
    }),
  );
}

function* accessRoutesRequest(
  action: AsyncAction<AccessRouterMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token)
    try {
      const { data } = yield accessRouter(
        action.meta.platform,
        state.auth.token,
      );
      yield put(
        authActions.update({
          permissionRouter: data.data.access_routes || [],
        }),
      );
    } catch (e: unknown) {
      console.log('accessRoutesRequest', e);
    }
}

function* loginWatcher(): any {
  yield takeLatest(loginActions.REQUEST, loginRequest);
  yield takeLatest(loginActions.SUCCESS, loginSuccess);
  yield takeLatest(loginActions.FAILED, loginFailed);
  yield takeLatest('DISMISS_SYSTEM_MESSAGE', dismissMessage);
  yield takeLatest(accessRouterActions.REQUEST, accessRoutesRequest);
}

export default loginWatcher;
