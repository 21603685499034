import {
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload
} from 'types/store/GetHomePageHeaderState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_HOME_PAGE_SHORTCUTS';
const asyncAction = createAsyncAction<
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload
>(ns);

const getHomePageShortcutsActions = {
  ...asyncAction,
};

export default getHomePageShortcutsActions;
