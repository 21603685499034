import { push } from 'connected-react-router';
import { EMPLOYER_ROUTES } from 'consts';
import { put, call, takeLatest } from 'redux-saga/effects';
import { registerEbWithExistedAccount } from 'services/employee';
import { AsyncAction } from 'types/Action';
import {
  RegisterEbWithExistedAccountMeta,
  RegisterEbWithExistedAccountPayload,
} from 'types/store/RegisterEbWithExistedAccount';

import { registerEbWithExistedAccountActions, authActions } from '../actions';

function* registerEbWithExistedAccountRequest(
  action: AsyncAction<
    RegisterEbWithExistedAccountMeta,
    RegisterEbWithExistedAccountPayload
  >
) {
  try {
    const { data } = yield call(registerEbWithExistedAccount, action.meta);
    yield put(registerEbWithExistedAccountActions.success(data));
  } catch (e: any) {
    yield put(registerEbWithExistedAccountActions.failed(e.message));
  }
}

function* registerEbWithExistedAccountSuccess(
  action: AsyncAction<
    RegisterEbWithExistedAccountMeta,
    RegisterEbWithExistedAccountPayload
  >
) {
  yield put(
    authActions.update({
      token: action.payload.data.token,
      platform: action.payload.data.platform,
      refresh_token: action.payload.data.refresh_token,
    })
  );
  yield put(push(EMPLOYER_ROUTES.ONBOARDING));
}

function* registerEbWithExistedAccountWatcher(): any {
  yield takeLatest(
    registerEbWithExistedAccountActions.REQUEST,
    registerEbWithExistedAccountRequest
  );
  yield takeLatest(
    registerEbWithExistedAccountActions.SUCCESS,
    registerEbWithExistedAccountSuccess
  );
}

export default registerEbWithExistedAccountWatcher;
