import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStoryCalendarByCompany } from 'services/story';
import { AsyncAction } from 'types/Action';
import { getStoryCalendarByCompanyMeta, getStoryCalendarByCompanyPayload } from 'types/store/getStoryCalendarByCompanyState';
import { Store } from 'types/store/Store';

import { getStoryCalendarByCompanyActions } from '../actions';

function* getStoryCalendarByCompanyRequest(
  action: AsyncAction<getStoryCalendarByCompanyMeta, getStoryCalendarByCompanyPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(getStoryCalendarByCompany, action.meta, state.auth.token);
      yield put(getStoryCalendarByCompanyActions.success(data));
    } catch (e: any) {
      yield put(getStoryCalendarByCompanyActions.failed(e.message));
    }
  } else {
    yield put(getStoryCalendarByCompanyActions.failed('Token not found'));
  }
}

function* getStoryCalendarByCompanySuccess(
  action: AsyncAction<getStoryCalendarByCompanyMeta, getStoryCalendarByCompanyPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getStoryCalendarByCompanyWatcher(): any {
  yield takeLatest(
    getStoryCalendarByCompanyActions.REQUEST,
    getStoryCalendarByCompanyRequest
  );

  yield takeLatest(
    getStoryCalendarByCompanyActions.SUCCESS,
    getStoryCalendarByCompanySuccess
  );
}

export default getStoryCalendarByCompanyWatcher;
