import Typography from 'components/base/Typography';
import styled from 'utils/styled';

import { ShapeProps } from './Modal.props';

export const ModalWrapper = styled.div<{ isShareLink?: boolean }>`
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ isShareLink }) => (isShareLink ? `overflow: auto;` : ``)}
`;

export const Backdrop = styled.div<{ isOpen: boolean }>`
  background-color: rgba(9, 19, 29, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const ModalContainer = styled.div<{
  backgroundColor?: string;
  width?: number;
  minWidth?: number;
  padding?: string;
  fullscreen?: boolean;
  maxHeight?: string;
  height?: number;
  maxWidth?: string;
  fixedWidth?: string;
  fixedHeight?: string;
  isShareLink?: boolean;
  borderRadius?: string;
  overflow?: string;
}>`
  position: absolute;
  border-radius: 4px;
  ${({ borderRadius }) => borderRadius && `border-radius: ${borderRadius}px;`}
  ${({ isShareLink }) => isShareLink && `border-radius: 12px;`}
  box-sizing: border-box;
  max-height: ${({ maxHeight }) => maxHeight || `80vh`};
  max-width: ${({ maxWidth }) => maxWidth || '80vw'};
  padding: ${({ padding }) => padding || `32px`};
  min-width: ${({ minWidth }) => minWidth || 438}px;
  background-color: ${(props) => props.backgroundColor};
  z-index: ${({ theme }) => theme.zIndex.modal + 2};
  display: flex;
  flex-direction: column;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
  ${({ width, height, fullscreen }) => {
    if (fullscreen) {
      return `width: 100%; height: 100%; padding: 0;`;
    } else if (width && height) {
      return `width: ${width}vw; height: ${height}vh;`;
    }
  }}

  ${({ fixedWidth }) =>
    fixedWidth &&
    `
    width: ${fixedWidth};
    min-width: unset;
    max-width: unset;
  `}

  ${({ fixedHeight }) =>
    fixedHeight &&
    `
    height: ${fixedHeight};
    min-height: unset;
    max-height: unset;
  `}

  @media screen and (max-width: 800px) {
    width: calc(100% - 24px);
    min-width: unset;
    margin: auto;
    padding: ${({ padding }) => padding || `12px`};
  }

  & > div:first-of-type {
    height: 100%;

    ${({ overflow }) =>
      overflow &&
      `
      overflow: ${overflow};
    `}
  }
`;

export const ExitButton = styled.button<{
  insideClose?: boolean;
  isShareLink?: boolean;
  positionCLoseBtn?: ShapeProps;
  hasTitle?: boolean;
  closeShadown?: boolean;
}>`
  position: absolute;
  ${({ insideClose, hasTitle }) => {
    if (insideClose === true) {
      return `
        top: 23px;
        right:23px;    
      `;
    } else {
      return !hasTitle
        ? `
        top: -23px;
        right: -23px;    
      `
        : `top: -96px;
      right: -52px;`;
    }
  }}

  background: ${(props) => props.theme.grey.noshade};
  box-shadow: ${({ closeShadown }) =>
    !closeShadown && '0px 12px 24px rgba(181, 173, 176, 0.25)'};
  border-radius: 50%;
  height: 48px;
  width: 48px;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  ${({ isShareLink }) => {
    if (isShareLink)
      return `
      top: -9%;
      right: -3%;
      height: 48px;
      width: 48px;   
    `;
  }}

  ${({ positionCLoseBtn }) => {
    const { top, right, height, width } = { ...positionCLoseBtn };
    if (positionCLoseBtn)
      return `
      top: ${top ? top : ''};
      right: ${right ? right : ''};
      height: ${height ? height : ''};
      width: ${width ? width : ''};
    `;
  }}

  :focus {
    outline: none;
  }
  z-index: 2;
  @media screen and (max-width: 800px) {
    width: 40px;
    height: 40px;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  height: calc(100% - 50px) !important;
`;

export const Title = styled(Typography)`
  margin-bottom: 10px;
`;
