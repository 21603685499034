import { push } from 'connected-react-router';
import { EMPLOYER_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createPage, checkUrl } from 'services/page';
import { uploadPhoto } from 'services/photo';
import { AsyncAction } from 'types/Action';
import { CreatePageMeta, CreatePagePayload } from 'types/store/CreatePageState';
import { Store } from 'types/store/Store';

import {
  createPageActions,
  getPagesbyCompanyIdActions,
  previewPageActions,
  checkUrlActions,
} from '../actions';

let pageUrl = '';
let status = '';

function* createPageRequest(
  action: AsyncAction<CreatePageMeta, CreatePagePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    const { data } = yield call(
      checkUrl,
      { url: action.meta.url },
      state.auth.token
    );
    if (data.data.isAvailable) {
      try {
        let logoUrl = '';
        if (action.meta.logo instanceof File) {
          const { data } = yield call(
            uploadPhoto,
            action.meta.logo,
            state.auth.token
          );
          logoUrl = data.status === 200 ? data.data : '';
        }

        let heroUrl = '';
        if (action.meta.hero instanceof File) {
          const { data } = yield call(
            uploadPhoto,
            action.meta.hero,
            state.auth.token
          );
          heroUrl = data.status === 200 ? data.data : '';
        }

        let avatarUrl = '';
        if (action.meta.avatar instanceof File) {
          const { data } = yield call(
            uploadPhoto,
            action.meta.avatar,
            state.auth.token
          );
          avatarUrl = data.status === 200 ? data.data : '';
        }

        for (let x = 0; x < action.meta.photo_list.length; x++) {
          if (action.meta.photo_list[x] instanceof File) {
            const { data } = yield call(
              uploadPhoto,
              action.meta.photo_list[x] as File,
              state.auth.token
            );
            action.meta.media?.push({
              url: data.data,
              type: 'IMAGE',
            });
          }
        }

        for (let x = 0; x < action.meta.values.length; x++) {
          if (action.meta.values[x].image instanceof File) {
            const { data } = yield call(
              uploadPhoto,
              action.meta.values[x].image as File,
              state.auth.token
            );
            action.meta.values[x].image = data.status === 200 ? data.data : '';
          }
        }

        for (let x = 0; x < action.meta.stories.length; x++) {
          if (action.meta.stories[x].image instanceof File) {
            const { data } = yield call(
              uploadPhoto,
              action.meta.stories[x].image as File,
              state.auth.token
            );
            action.meta.stories[x].image = data.status === 200 ? data.data : '';
          }
        }

        action.meta.hero = heroUrl;
        action.meta.logo = logoUrl;
        action.meta.avatar = avatarUrl;
        pageUrl = action.meta.url;
        status = action.meta.status;

        const { data } = yield call(createPage, action.meta, state.auth.token);
        yield put(createPageActions.success(data));
      } catch (e: any) {
        yield put(createPageActions.failed(e.message));
      }
    } else {
      yield put(createPageActions.failed('URL Uniqueness Test Fail'));
      yield put(checkUrlActions.success(data));
    }
  }
}

function* createPageSuccess(
  action: AsyncAction<CreatePageMeta, CreatePagePayload>
) {
  yield put(getPagesbyCompanyIdActions.request({}));
  yield put(previewPageActions.clear());

  if (status === 'PUBLISHED') {
    window.open(`${window.location.origin}/company/${pageUrl}`);
    yield put(push(EMPLOYER_ROUTES.INSIDE_LOOK));
  } else {
    const pageId = action.payload.data.id;
    yield put(push(EMPLOYER_ROUTES.INSIDE_LOOK_UPDATE(pageId)));
  }
}

function* createPageWatcher(): any {
  yield takeLatest(createPageActions.REQUEST, createPageRequest);
  yield takeLatest(createPageActions.SUCCESS, createPageSuccess);
}

export default createPageWatcher;
