import {
  GetUserSettingMeta,
  GetUserSettingPayload,
} from 'types/store/GetUserSettingState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_USER_SETTINGS';
const asyncAction = createAsyncAction<
  GetUserSettingMeta,
  GetUserSettingPayload
>(ns);

const getUserSettingsActions = {
  ...asyncAction,
};

export default getUserSettingsActions;
