import { put, call, takeLatest, select } from 'redux-saga/effects';
import { checkUrl } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  ShowcasePageCheckUrlMeta,
  ShowcasePageCheckUrlPayload
} from 'types/store/ShowcasePageCheckUrlState';
import { Store } from 'types/store/Store';

import { showcasePageCheckUrlActions } from '../actions';
function* showcasePageCheckUrlRequest(
  action: AsyncAction<ShowcasePageCheckUrlMeta, ShowcasePageCheckUrlPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(checkUrl, action.meta, state.auth.token);
      yield put(showcasePageCheckUrlActions.success(data));
    } catch (e: any) {
      yield put(showcasePageCheckUrlActions.failed(e.message));
    }
  }
}

function* showcasePageCheckUrlSuccess(
  action: AsyncAction<ShowcasePageCheckUrlMeta, ShowcasePageCheckUrlPayload>
) {
  // yield put(showcasePageCheckUrlActions.clear());
}

function* showcasePageCheckUrlWatcher(): any {
  yield takeLatest(showcasePageCheckUrlActions.REQUEST, showcasePageCheckUrlRequest);
  yield takeLatest(showcasePageCheckUrlActions.SUCCESS, showcasePageCheckUrlSuccess);
}

export default showcasePageCheckUrlWatcher;
