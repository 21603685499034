import React from 'react';

import { SVGProps } from './SVG.props';

const Url = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      // xmlns="http://www.w3.org/2000/svg"
      width={width || 15}
      height={height || 15}
      viewBox="0 0 20 20"
      fill="none"
      {...props}
    >
      <path
        fill={fill || '#5487F5'}
        d="M8 15.55l-1.77 1.72a2.475 2.475 0 01-3.5-3.5l4.54-4.55a2.46 2.46 0 013.39-.09l.12.1a1 1 0 001.4-1.43 2.74 2.74 0 00-.18-.21 4.46 4.46 0 00-6.09.22l-4.6 4.55a4.48 4.48 0 006.33 6.33L9.37 17A1 1 0 008 15.55zM18.69 1.31a4.49 4.49 0 00-6.33 0L10.63 3A1 1 0 0012 4.45l1.73-1.72a2.475 2.475 0 013.5 3.5l-4.54 4.55a2.46 2.46 0 01-3.39.09l-.12-.1a1 1 0 10-1.4 1.43c.072.074.15.144.23.21a4.47 4.47 0 006.09-.22l4.55-4.55a4.49 4.49 0 00.04-6.33z"
      ></path>
    </svg>
  );
};

export default Url;
