import {
  InviteAdvocateMeta,
  InviteAdvocatePayload,
} from 'types/store/InviteAdvocateState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'INVITE_ADVOCATE';
const asyncAction = createAsyncAction<
  InviteAdvocateMeta,
  InviteAdvocatePayload
>(ns);
const clearAction = createClearAction(ns);

const inviteAdvocateActions = {
  ...asyncAction,
  ...clearAction,
};

export default inviteAdvocateActions;
