import {
  UpsertShowcaseFeedbackMeta,
  UpsertShowcaseFeedbackPayload,
} from 'types/store/UpsertShowcaseFeedbackState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertShowcaseFeedbackActions } from '../actions';

export default createAsyncReducer<
  UpsertShowcaseFeedbackMeta,
  UpsertShowcaseFeedbackPayload
>(upsertShowcaseFeedbackActions);
