import { put, call, takeLatest, select } from 'redux-saga/effects';
import { resendInvitation } from 'services/team';
import { AsyncAction } from 'types/Action';
import { ResendInvitationMeta, ResendInvitationPayload } from 'types/store/ResendInvitation';
import { Store } from 'types/store/Store';

import { resendInvitationActions } from '../actions';

function* resendInvitationRequest(action: AsyncAction<ResendInvitationMeta, ResendInvitationPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(resendInvitation, action.meta, state.auth.token);
      yield put(resendInvitationActions.success(data));
    } catch (e: any) {
      yield put(resendInvitationActions.failed(e.message));
    }
  }
}

// function* resendInvitationSuccess(action: AsyncAction<ResendInvitationMeta, ResendInvitationPayload>): any {
//   yield put()
// }

function* resendInvitationWatcher(): any {
  yield takeLatest(resendInvitationActions.REQUEST, resendInvitationRequest);
  // yield takeLatest(resendInvitationActions.SUCCESS, resendInvitationSuccess);
}

export default resendInvitationWatcher;
