import {
   AddCardMeta,
   AddCardPayload
  } from 'types/store/AddCard';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { addCardActions } from '../actions';
  
  export default createAsyncReducer<AddCardMeta, AddCardPayload>(
    addCardActions
  );
  