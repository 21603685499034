import {
  GetCommunitiesMeta,
  GetCommunitiesPayload,
} from 'types/store/GetCommunitiesState';
import { createAsyncReducer } from 'utils/Redux';

import { getCommunitiesActions } from '../actions';

export default createAsyncReducer<GetCommunitiesMeta, GetCommunitiesPayload>(
  getCommunitiesActions
);
