import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowCollapse = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 6}
      height={height || 8}
      viewBox="0 0 6 8"
      fill={fill || ''}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.15227 0.657894L0.89906 3.44642C0.593429 3.70839 0.558035 4.16853 0.820004 4.47417C0.844293 4.50251 0.870723 4.52894 0.89906 4.55323L4.15227 7.34175C4.4579 7.60372 4.91803 7.56833 5.18 7.26269C5.29323 7.13059 5.35547 6.96233 5.35547 6.78834L5.35547 1.2113C5.35547 0.808751 5.02915 0.482422 4.62661 0.482422C4.45262 0.482422 4.28437 0.544662 4.15227 0.657894Z"
        fill="#070550"
      />
    </svg>
  );
};

export default ArrowCollapse;
