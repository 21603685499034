import {
  GetAdvocateAnalyticsMeta,
  GetAdvocateAnalyticsPayload,
} from 'types/store/GetAdvocateAnalytics';
import { createAsyncReducer } from 'utils/Redux';

import { getAdvocateAnalyticsAction } from '../actions';

export default createAsyncReducer<
  GetAdvocateAnalyticsMeta,
  GetAdvocateAnalyticsPayload
>(getAdvocateAnalyticsAction);
