import React from 'react';

import { SVGProps } from './SVG.props';

const Hamburger = (props: SVGProps): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.12451 7.3125C3.12451 7.13991 3.26442 7 3.43701 7H16.562C16.7346 7 16.8745 7.13991 16.8745 7.3125V7.6875C16.8745 7.86009 16.7346 8 16.562 8H3.43701C3.26442 8 3.12451 7.86009 3.12451 7.6875V7.3125Z" fill="#070550"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.73389 7.3125C2.73389 6.92418 3.04869 6.60938 3.43701 6.60938H16.562C16.9503 6.60938 17.2651 6.92418 17.2651 7.3125V7.6875C17.2651 8.07582 16.9503 8.39063 16.562 8.39063H3.43701C3.04869 8.39063 2.73389 8.07583 2.73389 7.6875V7.3125ZM3.51514 7.39063V7.60938H16.4839V7.39063H3.51514Z" fill="#070550"/>
      <path d="M3.12451 12.3125C3.12451 12.1399 3.26442 12 3.43701 12H16.562C16.7346 12 16.8745 12.1399 16.8745 12.3125V12.6875C16.8745 12.8601 16.7346 13 16.562 13H3.43701C3.26442 13 3.12451 12.8601 3.12451 12.6875V12.3125Z" fill="#070550"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M2.73389 12.3125C2.73389 11.9242 3.04869 11.6094 3.43701 11.6094H16.562C16.9503 11.6094 17.2651 11.9242 17.2651 12.3125V12.6875C17.2651 13.0758 16.9503 13.3906 16.562 13.3906H3.43701C3.04869 13.3906 2.73389 13.0758 2.73389 12.6875V12.3125ZM3.51514 12.3906V12.6094H16.4839V12.3906H3.51514Z" fill="#070550"/>
    </svg>
  );
};

export default Hamburger;
