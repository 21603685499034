import {
    GetTransactionByStripeIdMeta,
    GetTransactionByStripeIdPayload
  } from 'types/store/GetTransactionByStripeId';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'GET_TRANSACTION_BY_STRIPE_ID';
  
  const asyncAction = createAsyncAction<GetTransactionByStripeIdMeta, GetTransactionByStripeIdPayload>(ns);
  
  const getTransactionByStripeIdActions = {
    ...asyncAction,
  };
  
  export default getTransactionByStripeIdActions;
  