import {
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload,
} from 'types/store/GetStoriesByCompanyIdState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORIES_COMPANY_ID';
const asyncAction = createAsyncAction<
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload
>(ns);

const getStoriesByCompanyIdActions = {
  ...asyncAction,
};

export default getStoriesByCompanyIdActions;
