import { GetDashboardStoriesMeta, GetDashboardStoriesPayload } from 'types/store/GetDashboardStories';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_DASHBOARD_STORIES_NS';

const asyncAction = createAsyncAction<GetDashboardStoriesMeta, GetDashboardStoriesPayload>(ns);

const getDashboardStoriesActions = { ...asyncAction };

export default getDashboardStoriesActions;
