import {
  GetStoryLinkMeta,
  GetStoryLinkPayload,
} from 'types/store/GetStoryLink';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_LINK_LOG_NS';

const asyncAction = createAsyncAction<GetStoryLinkMeta, GetStoryLinkPayload>(
  ns,
);

const getStoryLinkActions = { ...asyncAction };

export default getStoryLinkActions;
