import FileSaver from 'file-saver';
import _get from 'lodash/get';
import { call, tryCatch } from 'ramda';
import { put, takeLatest, select } from 'redux-saga/effects';
import { getMoreQuestionCustome } from 'services/story';
import {
  generateAiContent,
  genrateAiHeadingContent,
  getEmailTemplate,
  getAdvocateAnswer,
  getEmployeeByAuthApi,
  getArticleCompany,
  aiSupportBAPortal,
  getBetaVersion,
  generateAIContentInvidual,
  shareLinkContentRecipes,
  uploadLinkLinkedIn,
  cancelUploadLink,
  createTemplateEmployeeHub,
  downloadStory,
  getMediaStory,
  getSettingAW,
  generateHTMLDocx,
} from 'services/story-editor';
import storyEditorActions, {
  advocateRepsonseAction,
  ADVOCATE_PARAMS,
  AI_GENERATE,
  BETA_VERSION,
  CONTENT_ARITCLE_COMPANY,
  DOWNLOAD_STORY,
  emailTemplateAction,
  EMPLOYEE_PROFILE,
  GET_MORE_QUESTIONS_CUSTOM,
  NAVIGATE_STORY_BUILDER,
  SAVE_LENG_WORD,
  SHARE_STORY_LINK,
  UPLOAD_LINK_MODAL,
  SETTING_AW,
  DOWNLOAD_DOCX,
} from 'store/actions/storyHub.action';
import { AsyncAction, Action } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  GeneratePayload,
  IndividualContent,
  StoryEditorGenerate,
} from 'types/store/StoryEditorState';
import CountWords from 'utils/CountWords';
import { AxiosError } from 'axios';

import {
  getDataRecipeExampleDetailActions,
  openToast,
  storyHubStudioActions,
} from '../actions';

const DEFAULT_AI_ERROR_MESSAGE = 'The server is busy, please try again later.';

function exportHTML(data: string, userInfo: any) {
  const current_time = new Date().getTime().toString();
  const nameDocs = `${userInfo.user.first_name}-${userInfo.user.last_name}-${userInfo.audience_research.headline}`;
  const header =
    "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
    "xmlns:w='urn:schemas-microsoft-com:office:word' " +
    "xmlns='http://www.w3.org/TR/REC-html40'>" +
    "<head><meta charset='utf-8'><title>Export HTML to Word Document with JavaScript</title></head><body>";
  const footer = '</body></html>';
  const sourceHTML = header + data + footer;

  const source =
    'data:application/vnd.ms-word;charset=utf-8,' +
    encodeURIComponent(sourceHTML);
  const fileDownload = document.createElement('a');
  document.body.appendChild(fileDownload);
  fileDownload.href = source;
  fileDownload.download = `${nameDocs}_${current_time}.doc`;
  fileDownload.click();
  document.body.removeChild(fileDownload);
}

function* getAllEmaiTemplate(token: string) {
  const { data } = yield getEmailTemplate(token);
  yield put(emailTemplateAction.success(data.data));
}

function* storyEditorGenerateAIcontent(action: AsyncAction<GeneratePayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const noteAIContent = JSON.parse(
        localStorage.getItem('noteAIContent') || '[]',
      );
      const findAIExist = noteAIContent.find(
        (item: { idStory: string; noteAI: string }) =>
          item.idStory === action.meta.idStory,
      );
      action.meta.callback && action.meta.callback();
      action.meta.callback = undefined;
      if (findAIExist) {
        yield put(storyEditorActions.success(findAIExist.noteAIContent));
      } else {
        const { idStory, isDownLoad, ...requestData } = action.meta;

        const { data } = yield generateAIContentInvidual(
          requestData,
          state.auth.token,
        );
        yield generateAIContentForAdvocateReponses(
          data.data,
          requestData.headline,
          isDownLoad,
          idStory,
        );
      }

      yield getAllEmaiTemplate(state.auth.token);
    } catch (e: any) {
      const count = state.StoryHubReducer.countError;
      yield put(
        storyEditorActions.failed(
          e.response?.data?.message || DEFAULT_AI_ERROR_MESSAGE,
        ),
      );
    }
  }
}

function* advocateResponse(action: any) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield getAdvocateAnswer(
        action.meta.storyId,
        state.auth.token,
      );
      yield put({
        payload: data.data,
        type: ADVOCATE_PARAMS.AVOCATE_ANSWER,
      });
    } catch (e: unknown) {
      console.log('eeee', e);
    }
  }
}

const buildIndividualContent = (content: IndividualContent): string => {
  const introduction = `${content.introduction || ''}</br>`;
  return introduction.concat(
    content.sections?.reduce((out, value) => {
      const heading = value[0] || '';
      const paragraph = value[1] || '';
      return out.concat(
        `<p><span style="font-size:20px"><strong>${heading.trim()}</strong></span></p>${paragraph.trim()}</br>`,
      );
    }, '') || '',
  );
};

const buildCompanyContent = (content: [[string, string]]): string => {
  return content?.reduce((out, value) => {
    const heading = value[0] || '';
    const paragraph = value[1] || '';
    return out.concat(
      `<p><span style="font-size:20px"><strong>${heading.trim()}</strong></span></p>${paragraph.trim()}</br>`,
    );
  }, '');
};

const buildContentFormat4 = (data: any): string => {
  const mapSection = (values: any) => {
    return values.reduce((acc: any, value: string, index: number) => {
      return `<p><strong>${acc}</strong></p>` + value.trim();
    });
  };

  const question = (value: string) =>
    `<p><span style="font-size:20px"><strong>${value.trim()}</strong></span>\n</p>`;

  const section = (value: any) =>
    value.reduce(
      (out: any, int: any, index: number) =>
        out +
        mapSection(int) +
        (index < data.length - 1 && data.length > 2 ? '</br>' : ''),
      '',
    );

  const reponse = (value: any) =>
    `${question(value.question)}${section(value.sections)}`;

  return data.reduce((acc: any, value: any) => acc + reponse(value) + '\n', '');
};

function* generateAIContentForAdvocateReponses(
  response: IndividualContent,
  headline: string,
  isDownLoad?: boolean,
  idStory?: string,
) {
  const headlineFormatted = `<p><span style="font-size:20px"><strong>${headline}</strong></span></p>`;
  const dataGenerate: string = buildIndividualContent(response);
  const noteAI: string = `${headlineFormatted}${dataGenerate}`.replaceAll(
    '\r\n',
    '</br></br>',
  );
  const replaceAll = noteAI.replaceAll('\n', '</br>');
  yield put(storyEditorActions.success(replaceAll));
  const noteAIContent = JSON.parse(
    localStorage.getItem('noteAIContent') || '[]',
  );
  if (response.introduction || response.sections.length) {
    noteAIContent.push({
      noteAIContent: noteAI,
      idStory,
    });
  }
  localStorage.setItem('noteAIContent', JSON.stringify(noteAIContent));
  if (isDownLoad) {
    const state: Store = yield select();
    exportHTML(dataGenerate, state?.getStoryForEditor.data?.data?.story);
  }
}

function* getEmployeeProfileByAuth() {
  const state: Store = yield select();
  if (state.auth.token) {
    const { data } = yield getEmployeeByAuthApi(state.auth.token);
    yield put({
      type: EMPLOYEE_PROFILE.EMPLOYEE_PROFILE_SUCCESS,
      payload: data.data,
    });
  }
}

function* getArticleByCompany(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    const { id, isAPITemplate, ...apiRequest } = action.payload || {};
    try {
      action.payload.meta && action.payload.meta();
      const noteAIContent = JSON.parse(
        localStorage.getItem('noteAIContent') || '[]',
      );
      const findAIExist = noteAIContent.find(
        (item: { idStory: string; noteAIContent: string }) =>
          item.idStory === id,
      );
      if (findAIExist && !isAPITemplate) {
        yield put(storyEditorActions.success(findAIExist.noteAIContent));
      } else {
        const storyQuestionData = state.getStoryForEditor.data?.data.story;

        let noteAi = `<p><span style="font-size:20px"><strong>${storyQuestionData?.audience_research.headline}</strong></span>\n</p>`;
        const { data } = yield getArticleCompany(apiRequest, state.auth.token);
        if (data?.data?.formatType === 'INDIVIDUAL') {
          noteAi += buildIndividualContent(data?.data?.content);
        } else {
          /**
           * todo fix to multiples format
           * switch (contentFormat) {
           *   case 1: break;
           *   case 2: break;
           *   case 3: break;
           *   case 4: break;
           * }
           */
          noteAi +=
            data?.data?.contentFormat === 1
              ? buildCompanyContent(data?.data?.content)
              : buildContentFormat4(data?.data?.content);
        }
        noteAi = noteAi.replaceAll(`\r\n`, '</br></br>');
        noteAi = noteAi.replaceAll(`\n`, '</br>');
        noteAIContent.push({
          noteAIContent: noteAi,
          idStory: action.payload.id,
        });
        localStorage.setItem('noteAIContent', JSON.stringify(noteAIContent));
        yield put(storyEditorActions.success(noteAi));
      }
    } catch (e: any) {
      yield put(
        storyEditorActions.failed(
          e.response?.data?.message || DEFAULT_AI_ERROR_MESSAGE,
        ),
      );
    }
  }
}

function* getDataAISupport(action: any) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const findListAdvocate = JSON.parse(
        localStorage.getItem('listQuestion') || '[]',
      );
      const activeQuestion = findListAdvocate.find(
        (item: any) =>
          item.idAdvocate === action.payload.idAdvocate &&
          item.idQuestion === action.payload.idQuestion,
      );

      if (!activeQuestion || activeQuestion?.count < 5) {
        const { data } = yield aiSupportBAPortal(
          action.payload,
          state.auth.token,
        );
        yield put({
          type: AI_GENERATE.AI_GENERATE_SUCCESS,
          payload: data.data,
        });
        action.payload.handleChange(data.data);
        const newAnswerIsGenerate = action.payload.currentAnswer + data.data;
        const length = CountWords(newAnswerIsGenerate as string);
        yield put({
          type: SAVE_LENG_WORD.SAVE_LENG_WORD_BEGIN,
          payload: {
            length,
            answer: newAnswerIsGenerate,
          },
        });
        if (!activeQuestion) {
          const newActiveQuestion = {
            count: 1,
            idQuestion: action.payload.idQuestion,
            idAdvocate: action.payload.idAdvocate,
          };
          findListAdvocate.push(newActiveQuestion);
          localStorage.setItem(
            'listQuestion',
            JSON.stringify(findListAdvocate),
          );
          yield put({
            type: AI_GENERATE.AI_GENERATE_LIMIT,
            payload: 1,
            wordCound: action.payload.wordCound,
          });
        }

        if (activeQuestion?.count < 5) {
          const newListAdvocate = findListAdvocate.map((item: any) => {
            if (
              item.idAdvocate === action.payload.idAdvocate &&
              item.idQuestion === action.payload.idQuestion
            ) {
              return {
                ...item,
                count: item.count + 1,
              };
            }
            return item;
          });
          localStorage.setItem('listQuestion', JSON.stringify(newListAdvocate));
          yield put({
            type: AI_GENERATE.AI_GENERATE_LIMIT,
            payload: activeQuestion?.count + 1,
            wordCound: action.payload.wordCound,
          });
        }
      } else {
        yield put({
          type: AI_GENERATE.AI_GENERATE_LIMIT,
          payload: 5,
          wordCound: action.payload.wordCound,
        });
      }
    } catch (e: any) {
      console.log('ai support error', e);
    }
  }
}

function* checkLimitedAISupport(action: any) {
  const state: Store = yield select();
  const limitCallApi = state.getUserById.data?.data.profile.limit_call_ai;
  const findListAdvocate = JSON.parse(
    localStorage.getItem('listQuestion') || '[]',
  );
  const activeQuestion = findListAdvocate.find(
    (item: any) =>
      item.idAdvocate === action.payload.idAdvocate &&
      item.idQuestion === action.payload.idQuestion,
  );

  if (
    !activeQuestion ||
    (limitCallApi && activeQuestion?.count < limitCallApi)
  ) {
    if (limitCallApi && activeQuestion?.count < limitCallApi) {
      yield put({
        type: AI_GENERATE.AI_GENERATE_LIMIT,
        payload: activeQuestion?.count,
      });
    }
    if (!activeQuestion) {
      yield put({
        type: AI_GENERATE.AI_GENERATE_LIMIT,
        payload: 0,
      });
    }
  } else {
    yield put({
      type: AI_GENERATE.AI_GENERATE_LIMIT,
      payload: limitCallApi,
    });
  }
}

function* getBetaVersionMiddleware(any: any) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield getBetaVersion(state.auth.token);
      yield put({
        type: BETA_VERSION.GET_BETA_VERSION_SUCCESS,
        payload: data.data.ml_augmented_writing_eb,
      });
    } catch (e: any) {
      console.log('error beta', e);
    }
  }
}

function* watchGetMoreQuestionCustom(action: any) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const listKeyPoint = action.payload.keyPoint.split('\n');
      const params = new URLSearchParams();
      params.append('headline', action.payload.headline);
      params.append('topic', action.payload.topic);
      // eslint-disable-next-line prefer-const
      for (let i in listKeyPoint) {
        params.append('keyPoints[]', listKeyPoint[i]);
      }
      const { data } = yield getMoreQuestionCustome(
        params.toString(),
        state.auth.token,
      );
      yield put({
        type: GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_SUCCESS,
        payload: data.data,
      });
    } catch (e: any) {
      console.log('error beta', e);
    }
  }
}

function* createMagicLinkTemplateEmployeeHub(payload: any, token: string) {
  const { data } = yield call(createTemplateEmployeeHub, payload, token);
  yield put({
    type: SHARE_STORY_LINK.SHARE_STORY_LINK_SUCCESS,
    payload: data.data?.advocateInviteLink,
  });
}

function* watchGetShareLinkContentRecipes(action: any) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      if (action.payload.templateId) {
        yield createMagicLinkTemplateEmployeeHub(
          action.payload,
          state.auth.token,
        );
      } else {
        const { data } = yield shareLinkContentRecipes(
          action.payload,
          state.auth.token,
        );
        yield put({
          type: SHARE_STORY_LINK.SHARE_STORY_LINK_SUCCESS,
          payload: data.data?.advocateInviteLink,
        });
      }
    } catch (e: any) {
      console.log('error beta', e);
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* watchUploadLinkModal(
  action: Action<{ recipeId: string; url: string }>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield uploadLinkLinkedIn(
        action.payload,
        state.auth.token,
      );
      yield put({
        type: UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_SUCCESS,
        payload: data,
      });
    } catch (e: any) {
      yield put({
        type: UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_ERROR,
        payload: e.response?.data?.errors || [e.message],
      });
    }
  }
}

function* watchCancelUploadLink() {
  yield cancelUploadLink();
  yield put({
    type: UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_CLEAR,
  });
}

function* watchDownloadRequest(
  action: Action<{ story_id: string; advocate_ids: string; nameFile: string }>,
) {
  const token: string = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const params = new URLSearchParams();
      params.append('story_id', action.payload.story_id);
      params.append('advocate_ids', action.payload.advocate_ids);
      const { data } = yield downloadStory(params.toString(), token);
      const bytes = new Uint8Array(data.data.file.data);
      const blob = new Blob([bytes]);
      FileSaver.saveAs(blob, `${action.payload.nameFile}.docx`);
      yield put({
        type: DOWNLOAD_STORY.DOWNLOAD_STORY_SUCCESS,
      });
    } catch (error: any) {
      console.log('download error', error);
    }
  }
}

function* watchStoryHubStudioRequest(action: AsyncAction) {
  const token: string = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const { data } = yield getMediaStory(action.meta.storyId, token);
      const mapData = data.data.map((_: any) => ({
        ..._,
        checked: false,
      }));
      yield put(storyHubStudioActions.success(mapData));
    } catch (error: unknown) {
      const errorMessage = error as AxiosError;
      console.log('errorMessage', errorMessage);
    }
  }
}

function* watchSettingAWRequest(action: AsyncAction) {
  const token: string = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const { data } = yield getSettingAW(token);
      yield put({
        type: SETTING_AW.SETTING_AW_SUCCESS,
        payload: data.data,
      });
    } catch (error: unknown) {
      const errorMessage = error as AxiosError;
      console.log('errorMessage', errorMessage);
    }
  }
}

function* watchDownloadDocxRequest(action: AsyncAction) {
  const token: string = yield select((state: Store) => state.auth.token);
  if (token) {
    try {
      const { data } = yield generateHTMLDocx(action.payload, token);
      if (action.payload?.isDownload && data.data.url) {
        window.open(data.data.url);
      }
      yield put({
        type: DOWNLOAD_DOCX.DOWNLOAD_DOCX_SUCCESS,
        payload: data.data.url || '',
      });
    } catch (error: unknown) {
      const errorMessage = error as AxiosError;
      console.log('errorMessage', errorMessage);
    }
  }
}

function* watchStoryEditorGenerateAIcontent(): any {
  yield takeLatest(storyEditorActions.REQUEST, storyEditorGenerateAIcontent);
  yield takeLatest(advocateRepsonseAction.REQUEST, advocateResponse);
  yield takeLatest(
    EMPLOYEE_PROFILE.EMPLOYEE_PROFILE_REQUEST,
    getEmployeeProfileByAuth,
  );
  yield takeLatest(
    CONTENT_ARITCLE_COMPANY.CONTENT_ARITCLE_COMPANY_REQUEST,
    getArticleByCompany,
  );
  yield takeLatest(AI_GENERATE.AI_GENERATE_REQUEST, getDataAISupport);
  yield takeLatest(AI_GENERATE.AI_GENERATE_CHECK_LIMIT, checkLimitedAISupport);
  yield takeLatest(
    BETA_VERSION.GET_BETA_VERSION_REQUEST,
    getBetaVersionMiddleware,
  );
  yield takeLatest(
    GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_REQUEST,
    watchGetMoreQuestionCustom,
  );
  yield takeLatest(
    SHARE_STORY_LINK.SHARE_STORY_LINK_REQUEST,
    watchGetShareLinkContentRecipes,
  );
  yield takeLatest(
    UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_REQUEST,
    watchUploadLinkModal,
  );
  yield takeLatest(
    UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_CANCEL,
    watchCancelUploadLink,
  );
  yield takeLatest(DOWNLOAD_STORY.DOWNLOAD_STORY_REQUEST, watchDownloadRequest);
  yield takeLatest(storyHubStudioActions.REQUEST, watchStoryHubStudioRequest);
  yield takeLatest(SETTING_AW.SETTING_AW_REQUEST, watchSettingAWRequest);
  yield takeLatest(
    DOWNLOAD_DOCX.DOWNLOAD_DOCX_REQUEST,
    watchDownloadDocxRequest,
  );
}

export default watchStoryEditorGenerateAIcontent;
