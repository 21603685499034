import { v4 as uuid } from 'uuid';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const handleChangeFileName = (
  url: string,
  newName: string,
  extensionName: string,
  xhrResponse?: any,
): void => {
  const xhr = new XMLHttpRequest();
  const a = document.createElement('a');
  let file = null;
  if (url) xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    file = new Blob([xhrResponse ? xhrResponse : xhr.response], {
      type: 'application/octet-stream',
    });
    a.href = window.URL.createObjectURL(file);
    a.download = `${newName}.${extensionName}`; // Set to whatever file name you want
    // Now just click the link you created
    // Note that you may have to append the a element to the body somewhere
    // for this to work in Firefox
    a.click();
  };
  xhr.send();
};

export function downloadLink(filename: string, url: string) {
  const id = uuid();
  const element = document.createElement('a');
  element.setAttribute('id', id);
  element.setAttribute('href', url);
  element.setAttribute('download', filename);
  document.body.appendChild(element);
  element.click();
  const elementById = document.getElementById(id);
  document.body.removeChild(elementById || element);
}

export const generalFileName = (baName: string, headline: string): string =>
  `${baName === 'Responses' ? baName : `${baName}_responses`}_${
    headline !== undefined
      ? headline.length < 50
        ? headline
        : headline.substring(0, 49)
      : ''
  }_${new Date().getTime()}`;
