import { put, call, takeLatest, select } from 'redux-saga/effects';
import { generateSocialCopy } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GenerateSocialCopyMeta, GenerateSocialCopyPayload } from 'types/store/GenerateSocialCopy';
import { Store } from 'types/store/Store';

import { generateSocialCopyActions } from '../actions';

function* generateSocialCopyRequest(action: AsyncAction<GenerateSocialCopyMeta, GenerateSocialCopyPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(generateSocialCopy, action.meta, state.auth.token);
      yield put(generateSocialCopyActions.success(data));
    } catch (e: any) {
      yield put(generateSocialCopyActions.failed(e.message));
    }
  }
}

// function* generateSocialCopySuccess(action: AsyncAction<GenerateSocialCopyMeta, GenerateSocialCopyPayload>): any {
//   yield put()
// }

function* generateSocialCopyWatcher(): any {
  yield takeLatest(generateSocialCopyActions.REQUEST, generateSocialCopyRequest);
  // yield takeLatest(generateSocialCopyActions.SUCCESS, generateSocialCopySuccess);
}

export default generateSocialCopyWatcher;
