import {
  UpsertShowcaseFeedbackImageMeta,
  UpsertShowcaseFeedbackImagePayload,
} from 'types/store/UpsertShowcaseFeedbackImageState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertShowcaseFeedbackImageActions } from '../actions';

export default createAsyncReducer<
  UpsertShowcaseFeedbackImageMeta,
  UpsertShowcaseFeedbackImagePayload
>(upsertShowcaseFeedbackImageActions);
