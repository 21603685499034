import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createNewCustomer } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  CreateNewCustomerMeta,
  CreateNewCustomerPayload
} from 'types/store/CreateNewCustomer';
import { Store } from 'types/store/Store';

import {
  createNewCustomerActions, getStripeCustomerIdActions
} from '../actions';

function* createNewCustomerRequest(
  action: AsyncAction<CreateNewCustomerMeta, CreateNewCustomerPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createNewCustomer, action.meta, state.auth.token);
      // yield data;
      yield put(createNewCustomerActions.success(data));
    } catch (e: any) {
      yield put(createNewCustomerActions.failed(e.message));
    }
  }
}

function* createNewCustomerSuccess(
  action: AsyncAction<CreateNewCustomerMeta, CreateNewCustomerPayload>
) {
  // yield put (createNewCustomerActions.clear());
  yield put(getStripeCustomerIdActions.request({}));
}

function* createNewCustomerWatcher(): any {
  yield takeLatest(createNewCustomerActions.REQUEST, createNewCustomerRequest);
  yield takeLatest(createNewCustomerActions.SUCCESS, createNewCustomerSuccess);
}

export default createNewCustomerWatcher;
