import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface ISuggestionAdvocatesMeta {
  recipeId: string;
  contentPackageId: string;
}

const suggestionAdvocatesNS = 'CONTENT_PLANNER_SUGGESTION_ADVOCATES';

const getSuggestionAdvocatesAsync = createAsyncAction<
  ISuggestionAdvocatesMeta,
  GenericResponse
>(suggestionAdvocatesNS);

export default getSuggestionAdvocatesAsync;
