import axios from 'axios';
import { API } from 'consts';
import { IEditVideoByOwner } from 'hooks/temporarySwitchToken';
import {
  IDataset,
  IDatasetValuables,
  IDatasetWithValuables,
} from 'types/Dataset';
import { GenericResponse } from 'types/GenericResponse';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const DATASET_URL = `${BASE_URL}/temporary-token-switch`;

export interface ICreateDataset {
  name: string;
  valuables: Array<IDatasetValuables>;
}

export type IUpdateDataset = {
  refreshToken: string;
};

export const create = (
  token: string,
  refreshToken: string,
): Promise<GenericResponse<any>> => {
  console.log({ refreshToken });
  return axios({
    method: 'post',
    url: DATASET_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { refreshToken },
  }).then((res) => res.data);
};

export const getSSID = (
  token: string,
  payload: IEditVideoByOwner,
): Promise<GenericResponse<any>> => {
  return axios({
    method: 'post',
    url: `${DATASET_URL}/login-as`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: payload,
  }).then((res) => res.data);
};

export const saveLog = (
  token: string,
  action: string,
): Promise<GenericResponse<any>> => {
  return axios({
    method: 'post',
    url: `${DATASET_URL}/log`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { action },
  }).then((res) => res.data);
};
