import { GetLinkedinConnectionStatusMeta, GetLinkedinConnectionStatusPayload } from 'types/store/GetLinkedinConnectionStatus';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LINKEDIN_CONNECTION_STATUS_NS';

const asyncAction = createAsyncAction<GetLinkedinConnectionStatusMeta, GetLinkedinConnectionStatusPayload>(ns);

const getLinkedinConnectionStatusActions = { ...asyncAction };

export default getLinkedinConnectionStatusActions;
