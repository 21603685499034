import { Action } from 'types/Action';

export interface ITrackingActionPayLoad {
  actionCode: string;
  storyId: string;
}

export enum USER_ACTIVITY {
  TRACKING_ACTION = '@@_USER_ACTIVITY_TRACKING_ACTION',
}

export enum ACTION_MAPPING {
  generateByAI = 'EB_AUGMENT_WRITING_BUTTON',
  refreshGenerateByAI = 'EB_AUGMENT_WRITING_BUTTON_REFRESH',
  editingSupport = 'EB_MARKETPLACE_BUTTON',
  baAIAssistant = 'BA_AI_ASSISTANT_BUTTON',
}

export const trackUserActivity = (
  payload: ITrackingActionPayLoad,
): Action<ITrackingActionPayLoad> => ({
  type: USER_ACTIVITY.TRACKING_ACTION,
  payload,
});

const trackingActions = {
  trackUserActivity,
};
export default trackingActions;
