import {
  GetUserSettingMeta,
  GetUserSettingPayload,
} from 'types/store/GetUserSettingState';
import { createAsyncReducer } from 'utils/Redux';

import { getUserSettingsActions } from '../actions';

export default createAsyncReducer<GetUserSettingMeta, GetUserSettingPayload>(
  getUserSettingsActions
);
