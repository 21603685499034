import {
  // TranscribeVideoMeta,
  TranscribeVideoPayload,
} from 'types/store/TranscribeVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'TRANSCRIBE_STORY_NS';

const asyncAction = createAsyncAction<string, TranscribeVideoPayload>(ns);

const transcribeStoryActions = { ...asyncAction };

export default transcribeStoryActions;
