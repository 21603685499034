import {
  GetSubtitlesMeta,
  GetSubtitlesPayload,
} from 'types/store/GetSubtitles';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SUBTITLES';
const asyncAction = createAsyncAction<GetSubtitlesMeta, GetSubtitlesPayload>(
  ns,
);

const getSubtitlesActions = {
  ...asyncAction,
};

export default getSubtitlesActions;
