import { GetStoryActivityLogsMeta, GetStoryActivityLogsPayload } from 'types/store/GetStoryActivityLogs';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_ACTIVITY_LOGS_NS';

const asyncAction = createAsyncAction<GetStoryActivityLogsMeta, GetStoryActivityLogsPayload>(ns);

const getStoryActivityLogsActions = { ...asyncAction };

export default getStoryActivityLogsActions;
