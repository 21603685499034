import { put, call, takeLatest, select } from 'redux-saga/effects';
import { duplicatePage } from 'services/page';
import { AsyncAction } from 'types/Action';
import {
  DuplicatePageMeta,
  DuplicatePagePayload,
} from 'types/store/DuplicatePageState';
import { Store } from 'types/store/Store';

import { duplicatePageActions } from '../actions';

function* duplicatePageRequest(
  action: AsyncAction<DuplicatePageMeta, DuplicatePagePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(duplicatePage, action.meta, state.auth.token);
      yield put(duplicatePageActions.success(data));
    } catch (e: any) {
      yield put(duplicatePageActions.failed(e.message));
    }
  } else {
    yield put(duplicatePageActions.failed('Token not found'));
  }
}

// function* downloadResponsesSuccess(
//   action: AsyncAction<DownloadResponsesMeta, DownloadResponsesPayload>
// ) {
// }

function* duplicatePageWatcher(): any {
  yield takeLatest(duplicatePageActions.REQUEST, duplicatePageRequest);
  // yield takeLatest(downloadResponsesActions.SUCCESS, downloadResponsesSuccess);
}

export default duplicatePageWatcher;
