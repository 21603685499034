import {
    GetAllItemsMeta,
    GetAllItemsPayload
    } from 'types/store/GetAllItems';
    import { createAsyncAction } from 'utils/Redux';
    
    const ns = 'GET_ALL_ITEMS';
    const asyncAction = createAsyncAction<GetAllItemsMeta, GetAllItemsPayload>(
      ns
    );
    
    const getAllItemsActions = {
      ...asyncAction,
    };
    
    export default getAllItemsActions;
    