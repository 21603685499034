import {
  UpsertShowcaseFeedbackMeta,
  UpsertShowcaseFeedbackPayload,
} from 'types/store/UpsertShowcaseFeedbackState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_SHOWCASE_FEEDBACK';
const asyncAction = createAsyncAction<
  UpsertShowcaseFeedbackMeta,
  UpsertShowcaseFeedbackPayload
>(ns);

const upsertShowcaseFeedbackActions = {
  ...asyncAction,
};

export default upsertShowcaseFeedbackActions;
