import { RegisterUploadVideoLinkedinMeta, RegisterUploadVideoLinkedinPayload } from 'types/store/RegisterUploadVideoLinkedin';
import { createAsyncAction } from 'utils/Redux';

const ns = 'REGISTER_UPLOAD_VIDEO_LINKEDIN_NS';

const asyncAction = createAsyncAction<RegisterUploadVideoLinkedinMeta, RegisterUploadVideoLinkedinPayload>(ns);

const registerUploadVideoLinkedinActions = { ...asyncAction };

export default registerUploadVideoLinkedinActions;
