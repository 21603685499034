import {
    UpdateArticlePositionStoryPageMeta,
    UpdateArticlePositionStoryPagePayload
} from 'types/store/UpdateArticlePositionStoryPage';
import { createAsyncReducer } from 'utils/Redux';

import { updateArticlePositionActions } from '../actions';

export default createAsyncReducer<
    UpdateArticlePositionStoryPageMeta,
    UpdateArticlePositionStoryPagePayload
>(updateArticlePositionActions);
