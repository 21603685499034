import {
  DeleteShowcaseFeedbackMeta,
  DeleteShowcaseFeedbackPayload,
} from 'types/store/DeleteShowcaseFeedbackState';
import { createAsyncReducer } from 'utils/Redux';

import { deleteShowcaseFeedbackActions } from '../actions';

export default createAsyncReducer<
  DeleteShowcaseFeedbackMeta,
  DeleteShowcaseFeedbackPayload
>(deleteShowcaseFeedbackActions);
