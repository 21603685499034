import React from 'react';

import theme from 'utils/Theme';

// import { useTheme } from 'utils/Theme';
import { RadioProps } from './Radio.props';
import {
  Container,
  HiddenRadio,
  InnerCircle,
  CustomRadio,
  Label,
} from './Radio.style';

const Radio = ({
  checked = false,
  label,
  size = 24,
  color = theme.brand.primary,
  typographyProps,
  colorInner,
  customLabel,
  uncheckColor,
  ...props
}: RadioProps): JSX.Element => {
  // const theme = useTheme();

  const radioVariant =
    typographyProps?.variant === undefined ? 'label' : typographyProps?.variant;

  return (
    <Container onClick={props.onClick}>
      <HiddenRadio
        onChange={(event) => event.stopPropagation()}
        checked={checked}
        type="radio"
        {...props}
      />
      <CustomRadio checked={checked} size={size} color={color} disabled={props.disabled} uncheckColor={uncheckColor}>
        {checked && <InnerCircle size={size} />}
      </CustomRadio>
      {label && (
        <Label {...typographyProps} variant={radioVariant} color="primary">
          {label}
        </Label>
      )}
      {customLabel && customLabel}
    </Container>
  );
};

export default React.memo(Radio);
