import { Reducer } from 'redux';
import { createAsyncReducer } from 'utils/Redux';
import {
  extractFileContentActions,
} from 'store/actions';
import { Action } from 'types/Action';
import { FileContentStore, ExtractFileContentMeta, ExtractFileContentPayload } from 'types/store/JobsState';

const initState: FileContentStore = {
  isLoading: false,
  extractedFileData: { status: 0, message: '', data: {} },
  fileContentError: false,
};

export const JobsExtractFileContentReducer: Reducer<FileContentStore, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case extractFileContentActions.REQUEST: {
      return {
        ...state,
        isLoading: true,
        fileContentError: false,
      };
    }
    case extractFileContentActions.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        extractedFileData: payload,
        fileContentError: false,
      };
    }
    case extractFileContentActions.FAILED: {
      return {
        ...state,
        isLoading: false,
        extractedFileData: payload,
        fileContentError: true,
      };
    }
    default: {
      return state;
    }
  }
};

export default createAsyncReducer<ExtractFileContentMeta, ExtractFileContentPayload>(extractFileContentActions);
