import { GetStoryForBuilderMeta, GetStoryForBuilderPayload } from 'types/store/GetStoryForBuilder';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_FOR_BUILDER';

const asyncAction = createAsyncAction<GetStoryForBuilderMeta, GetStoryForBuilderPayload>(ns);

const getStoryForBuilderActions = { ...asyncAction };

export default getStoryForBuilderActions;
