import {
  UpsertApproversStateMeta,
  UpsertApproversStatePayload,
} from 'types/store/UpsertApproversState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_APPROVERS';
const asyncAction = createAsyncAction<
  UpsertApproversStateMeta,
  UpsertApproversStatePayload
>(ns);

const upsertApproversActions = {
  ...asyncAction,
};

export default upsertApproversActions;
