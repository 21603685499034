import {
    DefaultCardMeta,
    DefaultCardPayload
  } from 'types/store/UpdateDefaultCard';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'UPDATE_DEFAULT_CARD';
  
  const asyncAction = createAsyncAction<DefaultCardMeta, DefaultCardPayload>(ns);
  
  const updateDefaultCardActions = {
    ...asyncAction,
  };
  
  export default updateDefaultCardActions;
  