import { SendMailWithTemplateMeta, SendMailWithTemplatePayload } from 'types/store/SendMailWithTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SEND_MAIL_WITH_TEMPLATE_NS';

const asyncAction = createAsyncAction<SendMailWithTemplateMeta, SendMailWithTemplatePayload>(ns);

const sendMailWithTemplateActions = { ...asyncAction };

export default sendMailWithTemplateActions;
