import {
  UpdateAnswerByIdMeta,
  UpdateAnswerByIdPayload,
} from 'types/store/UpdateAnswerByIdState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_ANSWER_BY_ID';

const asyncAction = createAsyncAction<
  UpdateAnswerByIdMeta,
  UpdateAnswerByIdPayload
>(ns);

const updatePageActions = {
  ...asyncAction,
};

export default updatePageActions;
