import {
  MarkReadNotificationsStateMeta,
  MarkReadNotificationsStatePayload,
} from 'types/store/MarkReadNotificationsState';
import { createAsyncReducer } from 'utils/Redux';

import { markReadNotificationsActions } from '../actions';

export default createAsyncReducer<
  MarkReadNotificationsStateMeta,
  MarkReadNotificationsStatePayload
>(markReadNotificationsActions);
