import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getTopics } from 'services/topic';
import { AsyncAction } from 'types/Action';
import { GetTopicsMeta, GetTopicsPayload } from 'types/store/GetTopics';
import { Store } from 'types/store/Store';

import { getTopicsActions } from '../actions';

function* getTopicsRequest(
  action: AsyncAction<GetTopicsMeta, GetTopicsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getTopics, action.meta, state.auth.token);
      yield put(getTopicsActions.success(data));
    } catch (e: any) {
      yield put(getTopicsActions.failed(e.message));
    }
  } else {
    yield put(getTopicsActions.failed('Token not found'));
  }
}

function* getTopicsSuccess(
  action: AsyncAction<GetTopicsMeta, GetTopicsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getTopicsWatcher(): any {
  yield takeLatest(getTopicsActions.REQUEST, getTopicsRequest);
  yield takeLatest(getTopicsActions.SUCCESS, getTopicsSuccess);
}

export default getTopicsWatcher;
