import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { CheckLibraryTranscribeMeta } from 'types/store/CheckLibraryTranscribe';
import { CreateLibraryMeta } from 'types/store/CreateLibraryState';
import { DeleteLibraryMeta } from 'types/store/DeleteLibraryState';
import { TranscribeLibraryVideoMeta } from 'types/store/TranscribeLibraryVideo';
import { JobAddableVideoItem } from 'types/Library';

import { uploadFile } from './upload';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const LIBRARY_URL = `${BASE_URL}/library`;

export const createLibrary = async (
  data: CreateLibraryMeta,
  token: string,
): Promise<any> => {
  const fd = new FormData();

  try {
    if (data.asset_upload) {
      const fileLink = await uploadFile({
        file: data.asset_upload,
        token,
        folder: 'library',
      });

      delete data.asset_upload;
      data.file_link = fileLink;
    } else {
      data.file_link = data.asset_url;
      delete data.asset_url;
    }

    //mutate the db
    fd.append('data', JSON.stringify(data));
    return axios({
      method: 'POST',
      url: `${LIBRARY_URL}/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    });
  } catch (err: any) {
    console.log(err);
    throw new Error(err);
  }
};

export const deleteLibrary = (
  data: DeleteLibraryMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'POST',
    url: `${LIBRARY_URL}/deleteById`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getLibraryAssets = (
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'GET',
    url: `${LIBRARY_URL}/getLibraryAssets`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTags = (token: string): any => {
  return axios({
    method: 'get',
    url: `${LIBRARY_URL}/getTags`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const transcribeLibraryVideo = (
  data: TranscribeLibraryVideoMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${LIBRARY_URL}/transcribeLibraryVideo`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const checkLibraryTranscribe = (
  data: CheckLibraryTranscribeMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${LIBRARY_URL}/checkLibraryTranscribe`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const updateThumbnail = (
  data: {
    story_id?: string;
    library_id?: string | null;
    story_approval_version_file_id?: string;
    thumbnail_image: string;
    story_video_id?: string;
  },
  folder: 'library' | 'story',
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/${folder}/updateThumbnail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getListJobAddableVideos = ({
  offset,
  limit,
  token,
  search = '',
}: {
  offset: number;
  limit: number;
  token: string;
  search: string;
}): AxiosPromise<GenericResponse<JobAddableVideoItem[]>> => {
  return axios({
    method: 'get',
    url: `${LIBRARY_URL}/job-addable-videos?offset=${
      offset || 0
    }&limit=${limit}&search=${search}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
