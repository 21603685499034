/* eslint-disable import/order */
import { AxiosError } from 'axios';
import {
  put,
  call,
  takeLatest,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import {
  getAssetLibrary,
  getAssetLibraryFolder,
  getAssetInfoDetail as getAssInfoDetail,
  getFolderInfoDetail,
  searchAssetLibrarySv,
  getTabsAssetLibrarySv,
  getImageMyStoriesSv,
  getImageByAdvocateSv,
} from 'services/assetLibrary';
import { prevNextVerParagraph } from 'services/story-builder';

import {
  GET_ASSET_LIBRARY,
  GET_PARAGRAPH_VERSION,
  LOADING,
  LOADING_INFO_DETAIL,
  GET_ASSET_LIBRARY_FOLDER,
  GET_ASSET_INFO_DETAIL,
  SEARCH_ASSET_LIBRARY,
  GET_TABS_SMART_UPLOADS,
  GET_IMAGES_MY_STORIES,
} from 'store/actions/assetLibrary/assetLibrary.enum';

import { Action, AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';

function* getParagraphVersion(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        prevNextVerParagraph,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: GET_PARAGRAPH_VERSION.GET_PARAGRAPH_VERSION_SUCCESS,
        payload: data.data,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getListAssetLibrary(action: Action) {
  const state: Store = yield select();
  yield put({
    type: LOADING.LOADING_REQUEST,
    payload: {},
  });
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getAssetLibrary,
        state.auth.token,
        action.payload,
      );
      yield put({
        type: GET_ASSET_LIBRARY.GET_ASSET_LIBRARY_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: GET_ASSET_LIBRARY.GET_LIST_TYPES_ASSET_LIBRARY_REQUEST,
        payload: data.data?.assetLibraryCount,
      });
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getListAssetLibraryFolder(action: Action) {
  const state: Store = yield select();
  yield put({
    type: LOADING.LOADING_REQUEST,
    payload: {},
  });
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getAssetLibraryFolder,
        action.payload?.folderId ? action.payload?.folderId : action.payload,
        state.auth.token,
        action.payload?.advocateId,
      );
      yield put({
        type: GET_ASSET_LIBRARY_FOLDER.GET_ASSET_LIBRARY_FOLDER_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getAssetInfoDetail(action: Action) {
  const state: Store = yield select();
  yield put({
    type: LOADING_INFO_DETAIL.LOADING_REQUEST,
    payload: {},
  });
  if (state.auth.token) {
    try {
      const { data } = yield call(
        action.payload?.type === 'Folder' // check typpe Folder or Asset
          ? getFolderInfoDetail
          : getAssInfoDetail,
        action.payload?.id, // assetId or folderId
        state.auth.token,
      );
      yield put({
        type: GET_ASSET_INFO_DETAIL.GET_ASSET_INFO_DETAIL_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: LOADING_INFO_DETAIL.LOADING_CLEAR,
        payload: {},
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put({
        type: LOADING_INFO_DETAIL.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* searchAssetLibrary(action: Action) {
  const state: Store = yield select();
  yield put({
    type: LOADING.LOADING_REQUEST,
    payload: {},
  });
  if (state.auth.token) {
    try {
      const { data } = yield call(
        searchAssetLibrarySv,
        state.auth.token,
        action.payload,
      );
      yield put({
        type: SEARCH_ASSET_LIBRARY.SEARCH_ASSET_LIBRARY_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
    } catch (e: unknown) {
      // const errorMessage = e as AxiosError;
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getTabsSmartUploads(action: Action) {
  const state: Store = yield select();
  yield put({
    type: LOADING.LOADING_REQUEST,
    payload: {},
  });
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getTabsAssetLibrarySv,
        state.auth.token,
        // action.payload,
      );
      yield put({
        type: GET_TABS_SMART_UPLOADS.GET_TABS_SMART_UPLOADS_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
    } catch (e: unknown) {
      // const errorMessage = e as AxiosError;
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getImageMyStories(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getImageMyStoriesSv,
        state.auth.token,
        action.payload?.storyId,
      );
      yield put({
        type: GET_IMAGES_MY_STORIES.GET_IMAGES_MY_STORIES_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
    } catch (e: unknown) {
      // const errorMessage = e as AxiosError;
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getImageByAdvocate(action: Action) {
  const state: Store = yield select();
  yield put({
    type: LOADING.LOADING_REQUEST,
    payload: {},
  });
  if (state.auth.token) {
    try {
      // check advocateId != undefined to call
      if (action.payload?.advocateId) {
        const { data } = yield call(
          getImageByAdvocateSv,
          state.auth.token,
          action.payload?.storyId,
          action.payload?.advocateId,
        );
        yield put({
          type: GET_IMAGES_MY_STORIES.GET_IMAGES_BY_ADVOCATE_SUCCESS,
          payload: data.data,
        });
        yield put({
          type: LOADING.LOADING_CLEAR,
          payload: {},
        });
      }
    } catch (e: unknown) {
      // const errorMessage = e as AxiosError;
      yield put({
        type: LOADING.LOADING_CLEAR,
        payload: {},
      });
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* assetLibraryWatcher(): any {
  yield takeLatest(
    GET_PARAGRAPH_VERSION.GET_PARAGRAPH_VERSION_REQUEST,
    getParagraphVersion,
  );
  yield takeLatest(
    GET_ASSET_LIBRARY.GET_ASSET_LIBRARY_REQUEST,
    getListAssetLibrary,
  );
  yield takeLatest(
    GET_ASSET_LIBRARY_FOLDER.GET_ASSET_LIBRARY_FOLDER_REQUEST,
    getListAssetLibraryFolder,
  );
  yield takeLatest(
    GET_ASSET_INFO_DETAIL.GET_ASSET_INFO_DETAIL_REQUEST,
    getAssetInfoDetail,
  );
  yield takeLatest(
    SEARCH_ASSET_LIBRARY.SEARCH_ASSET_LIBRARY_REQUEST,
    searchAssetLibrary,
  );
  yield takeLatest(
    GET_TABS_SMART_UPLOADS.GET_TABS_SMART_UPLOADS_REQUEST,
    getTabsSmartUploads,
  );
  yield takeLatest(
    GET_IMAGES_MY_STORIES.GET_IMAGES_MY_STORIES_REQUEST,
    getImageMyStories,
  );
  yield takeLatest(
    GET_IMAGES_MY_STORIES.GET_IMAGES_BY_ADVOCATE_REQUEST,
    getImageByAdvocate,
  );
}

export default assetLibraryWatcher;
