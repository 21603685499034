export type Widget = {
  isLoading: boolean;
  listWidget: ListWidget;
  idWidget: string;
  filterParam: widgetFilterParam;
  initForm: InitFormWidget;
};

export interface ListWidget {
  pageNumber: number;
  perPage: number;
  pageCount: number;
  totalRowCount: number;
  rows: Row[];
}

export interface widgetFilterParam {
  page: number;
  status?: string[];
  categories?: string[];
}
export interface Row {
  widget: IWidget;
  widgetCode: string;
  setting?: Setting;
  contents: Content[];
}

export interface Content {
  title: string;
  description: string;
  content_id: string;
  type: string;
  is_default: boolean;
  short_link: Cta | null;
}

export interface IWidget {
  id: string;
  code: string;
  name: string;
  description: string;
  type: string;
  status: string;
  company_id: string;
  created_by: string;
}

export interface Setting {
  logo: string;
  sliders: Sliders;
  theme_color: string;
  font: string;
  position: string;
  play_icon?: PlayIcon;
  layout_style: number;
  enablement: Enablement;
  targets: Target[];
  bubble_button?: BubbleButton;
  content_highlights?: any[];
  content_setting?: ContentSetting;
  cta_content?: Cta;
  content_setting_conditions?: ContentSettingCondition[];
  team_message?: string;
  cta?: Cta;
}

export interface ContentSettingCondition {
  component: string;
  match_operation: string;
  pattern: string;
  tag_ids: string[];
}

export interface Cta {
  text?: string;
  text_color?: string;
  bg_color?: string;
  url?: string;
  code?: string;
}

export interface ContentSetting {
  source: string;
  tags: any[];
}
export interface BubbleButton {
  text_color: string;
  text: string;
  color: string;
}
export interface Target {
  component: string;
  match_operation: string;
  widget_type: string;
  negate: boolean;
  pattern: string;
}

export interface Enablement {
  title: boolean;
  button: boolean;
  advocate_name: boolean;
  tag: boolean;
  role: boolean;
}

export interface PlayIcon {
  bg: string;
  text: string;
}

export interface Sliders {
  subTitle?: string;
  title?: string;
  bgImage?: string;
}
export interface IWidgetItem {
  company_id: string;
  createdAt: string;
  created_by: string;
  description: string;
  id: string;
  name: string;
  type: string | WIDGET_TYPE.POPUP;
  updatedAt: string;
}

export interface InitFormWidget {
  tags: any;
  [K: string]: any;
}

export enum WIDGET_TYPE {
  EMBED = 'EMBED',
  POPUP = 'POPUP',
}

export enum FONT_STYLE {
  GOOGLE_FONT = 'GOOGLE_FONT',
  CUSTOME_FONT = 'CUSTOME_FONT',
}
