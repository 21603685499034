import { Reducer } from 'redux';
import {
  downloadResponsesActions,
  downloadStoryVideoActions,
  getMoreQuestionForCustomActions,
  storyHubStudioActions,
} from 'store/actions';
import { TRANSLATE } from 'store/actions/getListLanguage';
import storyEditorActions, {
  advocateRepsonseAction,
  ADVOCATE_PARAMS,
  AI_GENERATE,
  CONTENT_ARITCLE_COMPANY,
  DOWNLOAD_CONTENT_AI,
  emailTemplateAction,
  EMPLOYEE_PROFILE,
  CONFIRMATION_BOX_STORY_HUB_SCREEN,
  HANDLE_STEP_2,
  BETA_VERSION,
  SAVE_LENG_WORD,
  ADD_KEY_POINT,
  GET_MORE_QUESTIONS_CUSTOM,
  CREATE_ADD_CUSTOME_QUESTION,
  GET_READY_AI,
  NAVIGATE_STORY_BUILDER,
  SHARE_STORY_LINK,
  UPLOAD_LINK_MODAL,
  DOWNLOAD_STORY,
  CLEAR_STORY_HUB_NAVIGATE_STEP,
  CLEAR_STORY_HUB_LOADING_STATE,
  HANDLE_QUESTION_BACK_NEXT,
  TURN_OFF_MODAL_FAILED,
  SETTING_AW,
  DOWNLOAD_DOCX,
  EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT,
} from 'store/actions/storyHub.action';
import { ActionStoryReducer } from 'types/Action';
import { StoryHubStore } from 'types/store/StoryEditorState';

const initState: StoryHubStore = {
  isDownloadAll: false,
  isLoading: false,
  isBeta: false,
  downloadTemplateContent: false,
  isDownloadContent: false,
  isGenerate: false,
  limitedAISupport: false,
  AIbuttonLimit: 0,
  wordCound: 0,
  currentAnswer: '',
  isStep2: 0,
  oldValue: '',
  notesAI: '',
  notesAIError: '',
  aiSupportContent: '',
  advocateAnswer: {},
  advocatePayload: {},
  employeeProfile: {},
  emailTemplate: [],
  keyPoint: '',
  listQuestionCustom: [],
  listAddCustomeQuestion: [],
  AIready: false,
  audient_id: '',
  isContentRecipes: false,
  uploadLinkLikedInMessage: [],
  uploadLinkData: { status: 0, message: '', data: {} },
  magicLink: '',
  isUploadingLink: false,
  titleFile: '',
  isOpenModalFailed: false,
  isOpenWatingGenerate: false,
  countError: 0,
  listVideo: [],
  language: [],
  lengthOfOutCome: [],
  toneOfVoice: [],
  fileDocx: '',
  editQuestionStepReviewAndSubmit: false,
};

const StoryHubReducer: Reducer<StoryHubStore, ActionStoryReducer> = (
  state = initState,
  { type, payload, wordCound, error },
) => {
  switch (type) {
    case storyEditorActions.REQUEST: {
      return {
        ...state,
        isLoading: true,
        isGenerate: true,
        isStep2: 1,
        notesAIError: '',
        downloadTemplateContent: false,
      };
    }
    case TRANSLATE.TRANSLATE_LANGUAGE_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case storyEditorActions.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        notesAI: payload,
        downloadTemplateContent: true,
        notesAIError: '',
      };
    }
    case storyEditorActions.FAILED: {
      const count = state.countError;
      return {
        ...state,
        isLoading: true,
        notesAIError: error,
        isOpenWatingGenerate: count < 1 ? true : false,
        isOpenModalFailed: count >= 1 ? true : false,
        countError: count + 1,
      };
    }
    case emailTemplateAction.SUCCESS: {
      return {
        ...state,
        emailTemplate: payload,
      };
    }
    case ADVOCATE_PARAMS.SAVE_AVOCATE_TO_STORE: {
      return {
        ...state,
        advocatePayload: payload,
      };
    }
    case advocateRepsonseAction.SUCCESS: {
      return {
        ...state,
        notesAI: payload,
        isLoading: false,
      };
    }
    case ADVOCATE_PARAMS.AVOCATE_ANSWER: {
      return {
        ...state,
        advocateAnswer: payload,
      };
    }
    case downloadResponsesActions.REQUEST: {
      return {
        ...state,
        isDownloadContent: false,
      };
    }
    case downloadResponsesActions.SUCCESS: {
      return {
        ...state,
        isDownloadContent: true,
      };
    }
    case EMPLOYEE_PROFILE.EMPLOYEE_PROFILE_SUCCESS: {
      return {
        ...state,
        employeeProfile: payload,
      };
    }
    case DOWNLOAD_CONTENT_AI.DOWNLOAD_CONTENT_AI_REQUEST: {
      return {
        ...state,
        isDownloadContent: true,
      };
    }
    case DOWNLOAD_CONTENT_AI.DOWNLOAD_CONTENT_AI_CLEAR: {
      return {
        ...state,
        isDownloadContent: false,
        notesAI: '',
        isGenerate: false,
      };
    }
    case CONTENT_ARITCLE_COMPANY.CONTENT_ARITCLE_COMPANY_REQUEST: {
      return {
        ...state,
        isLoading: true,
        isGenerate: true,
        isStep2: 1,
        notesAIError: '',
      };
    }
    case CONFIRMATION_BOX_STORY_HUB_SCREEN.THREE_OPTIONS_SCREEN: {
      return {
        ...state,
        isGenerate: false,
      };
    }
    case AI_GENERATE.AI_GENERATE_REQUEST: {
      return {
        ...state,
        isLoading: true,
        aiSupportContent: '',
      };
    }
    case AI_GENERATE.AI_GENERATE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        aiSupportContent: payload,
      };
    }

    case 'SAVE_OLD': {
      return {
        ...state,
        oldValue: state.aiSupportContent,
      };
    }
    case AI_GENERATE.AI_GENERATE_LIMIT: {
      return {
        ...state,
        limitedAISupport: payload < 5,
        AIbuttonLimit: state.AIbuttonLimit + 1,
      };
    }
    case HANDLE_STEP_2.HANDLE_STEP_2_NEXT: {
      return {
        ...state,
        isStep2: 1,
      };
    }
    case HANDLE_STEP_2.HANDLE_STEP_2_BACK: {
      return {
        ...state,
        isStep2: 0,
        isGenerate: false,
      };
    }
    case BETA_VERSION.GET_BETA_VERSION_SUCCESS: {
      return {
        ...state,
        isBeta: payload,
      };
    }
    case SAVE_LENG_WORD.SAVE_LENG_WORD_BEGIN: {
      return {
        ...state,
        wordCound: payload.length,
        currentAnswer: payload.answer,
      };
    }
    case ADD_KEY_POINT.ADD_KEY_POINT: {
      return {
        ...state,
        keyPoint: payload,
      };
    }
    case ADD_KEY_POINT.CLEAR_KEY_POINT: {
      return {
        ...state,
        keyPoint: '',
        listQuestionCustom: [],
      };
    }
    case GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_REQUEST: {
      return {
        ...state,
        isLoading: true,
        listAddCustomeQuestion: [],
      };
    }
    case GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        listQuestionCustom: payload,
      };
    }
    case GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_CLEAR: {
      return {
        ...state,
        listQuestionCustom: [],
      };
    }
    case CREATE_ADD_CUSTOME_QUESTION.CREATE_ADD_CUSTOME_QUESTION_SUCCESS: {
      const listAddCustomeQuestion = [
        ...state.listAddCustomeQuestion,
        payload.data,
      ];
      return {
        ...state,
        isLoading: false,
        listAddCustomeQuestion: listAddCustomeQuestion,
      };
    }
    case getMoreQuestionForCustomActions.REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getMoreQuestionForCustomActions.SUCCESS: {
      return {
        ...state,
        listQuestionCustom: payload,
        isLoading: false,
      };
    }
    case getMoreQuestionForCustomActions.FAILED: {
      return {
        ...state,
        listQuestionCustom: payload,
        isLoading: false,
      };
    }
    case 'Audient_id': {
      return {
        ...state,
        audient_id: payload,
      };
    }
    case GET_READY_AI.GET_READY_AI_SUCCESS: {
      return {
        ...state,
        AIready: true,
      };
    }
    case NAVIGATE_STORY_BUILDER.NAVIGATE_STORY_BUILDER_REQUEST: {
      return {
        ...state,
        isContentRecipes: true,
      };
    }
    case NAVIGATE_STORY_BUILDER.NAVIGATE_STORY_BUILDER_CLEAR: {
      return {
        ...state,
        isContentRecipes: false,
      };
    }
    case SHARE_STORY_LINK.SHARE_STORY_LINK_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case SHARE_STORY_LINK.SHARE_STORY_LINK_SUCCESS: {
      return {
        ...state,
        magicLink: payload,
        isLoading: false,
      };
    }
    case SHARE_STORY_LINK.SHARE_STORY_LINK_CLEAR: {
      return {
        ...state,
        magicLink: '',
      };
    }
    case UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_REQUEST: {
      return {
        ...state,
        isUploadingLink: true,
        uploadLinkLikedInMessage: [],
      };
    }
    case UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_SUCCESS: {
      return {
        ...state,
        isUploadingLink: false,
        uploadLinkData: payload,
      };
    }
    case UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_ERROR: {
      return {
        ...state,
        isUploadingLink: false,
        uploadLinkLikedInMessage: payload,
      };
    }
    case UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_CLEAR: {
      return {
        ...state,
        isUploadingLink: false,
        uploadLinkData: {},
        uploadLinkLikedInMessage: [],
      };
    }
    case DOWNLOAD_STORY.DOWNLOAD_STORY_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case DOWNLOAD_STORY.DOWNLOAD_STORY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_STORY_HUB_NAVIGATE_STEP: {
      return {
        ...state,
        isStep2: 0,
      };
    }
    case DOWNLOAD_STORY.DOWNLOAD_STORY_CLEAR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case CLEAR_STORY_HUB_LOADING_STATE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case HANDLE_QUESTION_BACK_NEXT.LIMIT_AI_SUPPORT: {
      return {
        ...state,
        limitedAISupport: payload.limitSupport,
        AIbuttonLimit: payload.limitButton,
      };
    }
    case downloadStoryVideoActions.REQUEST: {
      return {
        ...state,
        isDownloadAll: true,
      };
    }
    case downloadStoryVideoActions.SUCCESS: {
      return {
        ...state,
        isDownloadAll: false,
      };
    }
    case downloadStoryVideoActions.FAILED: {
      return {
        ...state,
        isDownloadAll: false,
      };
    }
    case 'UPDATE_HEADLINE': {
      return {
        ...state,
        titleFile: payload.headLine,
      };
    }
    case TURN_OFF_MODAL_FAILED.OFF: {
      return {
        ...state,
        isOpenModalFailed: false,
        isOpenWatingGenerate: false,
      };
    }
    case TURN_OFF_MODAL_FAILED.REFRESH: {
      return {
        ...state,
        isOpenModalFailed: false,
        isOpenWatingGenerate: false,
        countError: 0,
      };
    }
    case storyHubStudioActions.REQUEST: {
      return {
        ...state,
        listVideo: [],
      };
    }
    case storyHubStudioActions.SUCCESS: {
      return {
        ...state,
        listVideo: payload,
      };
    }
    case SETTING_AW.SETTING_AW_REQUEST: {
      return {
        ...state,
        language: [],
        lengthOfOutCome: [],
        toneOfVoice: [],
      };
    }
    case SETTING_AW.SETTING_AW_SUCCESS: {
      return {
        ...state,
        language: payload.language.map((_: any) => ({
          ..._,
          label: _.text,
          value: _.value,
        })),
        lengthOfOutCome: payload.length_of_outcome.map((_: any) => ({
          ..._,
          label: _.text,
          value: _.value,
        })),
        toneOfVoice: payload.tone_of_voice,
      };
    }
    case DOWNLOAD_DOCX.DOWNLOAD_DOCX_REQUEST: {
      return {
        ...state,
        fileDocx: '',
      };
    }
    case DOWNLOAD_DOCX.DOWNLOAD_DOCX_SUCCESS: {
      return {
        ...state,
        fileDocx: payload,
      };
    }
    case DOWNLOAD_DOCX.DOWNLOAD_DOCX_DELETE: {
      return {
        ...state,
        fileDocx: '',
      };
    }
    case EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT.EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_REQUEST: {
      return {
        ...state,
        editQuestionStepReviewAndSubmit: true,
      };
    }
    case EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT.EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_CLEAR: {
      return {
        ...state,
        editQuestionStepReviewAndSubmit: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default StoryHubReducer;
