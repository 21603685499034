import {
  GetDataRecipeExampleDetailMeta,
  GetDataRecipeExampleDetailPayload,
} from 'types/store/GetDataRecipeExampleDetail';
import { createAsyncReducer } from 'utils/Redux';

import { getDataRecipeExampleDetailActions } from '../actions';

export default createAsyncReducer<
  GetDataRecipeExampleDetailMeta,
  GetDataRecipeExampleDetailPayload
>(getDataRecipeExampleDetailActions);
