import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAdvocatesByCompanyId } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  GetAdvocatesByCompanyIdMeta,
  GetAdvocatesByCompanyIdPayload,
} from 'types/store/GetAdvocatesByCompanyId';
import { Store } from 'types/store/Store';

import { getAdvocatesbyCompanyIdActions } from '../actions';

function* getAdvocatesByCompanyIdRequest(
  action: AsyncAction<
    GetAdvocatesByCompanyIdMeta,
    GetAdvocatesByCompanyIdPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getAdvocatesByCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(getAdvocatesbyCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getAdvocatesbyCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getAdvocatesbyCompanyIdActions.failed('Token not found'));
  }
}

function* getAdvocatesByCompanyIdSuccess(
  action: AsyncAction<
    GetAdvocatesByCompanyIdMeta,
    GetAdvocatesByCompanyIdPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getAdvocatesByCompanyIdWatcher(): any {
  yield takeLatest(
    getAdvocatesbyCompanyIdActions.REQUEST,
    getAdvocatesByCompanyIdRequest
  );
  yield takeLatest(
    getAdvocatesbyCompanyIdActions.SUCCESS,
    getAdvocatesByCompanyIdSuccess
  );
}

export default getAdvocatesByCompanyIdWatcher;
