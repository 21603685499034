import { put, call, takeLatest, select } from 'redux-saga/effects';
import _get from 'lodash/get'
import {
  extractFileContent,
} from 'services/jobs';
import { Store } from 'types/store/Store';
import { AsyncAction } from 'types/Action';
import {
  ExtractFileContentMeta,
  ExtractFileContentPayload
} from 'types/store/JobsState';

import {
  extractFileContentActions
} from '../../actions';

function* extractFileContentRequest(action: AsyncAction<ExtractFileContentMeta, ExtractFileContentPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(extractFileContent, action.meta, state.auth.token);
      yield put(extractFileContentActions.success(data));
    } catch (e: any) {
      yield put(extractFileContentActions.failed(e.message));
    }
  }
}

function* extractFileContentWatcher(): any {
  yield takeLatest(extractFileContentActions.REQUEST, extractFileContentRequest);
}

export default extractFileContentWatcher;
