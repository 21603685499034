import React from 'react';

import { SVGProps } from './SVG.props';

const Check = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 12}
      viewBox="0 0 16 12"
      fill={fill}
      {...props}
    >
      <path
        d="M14.71 1.21a.999.999 0 00-1.42 0L5.84 8.67 2.71 5.53A1.022 1.022 0 101.29 7l3.84 3.84a1 1 0 001.42 0l8.16-8.16a1.002 1.002 0 000-1.47z"
        fill={fill}
      />
    </svg>
  );
};

export default Check;
