import React from 'react';

import { SVGProps } from './SVG.props';

const UploadImage = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.996 6.61a5.393 5.393 0 000 10.785 5.39 5.39 0 005.391-5.393 5.39 5.39 0 00-5.391-5.393zm0 8.889a3.5 3.5 0 110-7 3.5 3.5 0 010 7z"
        fill={fill === undefined ? '#D93175' : fill}
      />
      <path
        d="M18.858 6.393a1.259 1.259 0 11-2.517 0 1.259 1.259 0 012.517 0z"
        fill={fill === undefined ? '#D93175' : fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.996 1.5c-2.85 0-3.209.012-4.33.063-1.116.05-1.879.229-2.545.487a5.116 5.116 0 00-1.859 1.213A5.208 5.208 0 002.05 5.122c-.258.666-.437 1.429-.487 2.55-.05 1.117-.063 1.475-.063 4.326 0 2.85.012 3.209.063 4.33.05 1.117.229 1.88.487 2.55.267.692.63 1.276 1.213 1.86.583.583 1.17.941 1.858 1.212.666.258 1.429.438 2.55.488 1.12.05 1.475.062 4.329.062 2.854 0 3.208-.012 4.33-.063 1.116-.05 1.878-.229 2.55-.487a5.116 5.116 0 001.858-1.213 5.208 5.208 0 001.212-1.858c.258-.667.438-1.43.488-2.551.05-1.121.062-1.475.062-4.33s-.012-3.209-.063-4.33c-.05-1.117-.229-1.88-.487-2.55a5.118 5.118 0 00-1.212-1.86 5.206 5.206 0 00-1.859-1.212c-.667-.258-1.429-.438-2.55-.488-1.125-.046-1.483-.058-4.333-.058zm0 1.892c2.804 0 3.133.013 4.242.063 1.024.045 1.579.216 1.95.362.491.192.841.417 1.208.784.366.366.596.716.783 1.208.142.371.317.925.363 1.95.05 1.11.062 1.438.062 4.243 0 2.805-.012 3.134-.062 4.243-.046 1.025-.217 1.58-.363 1.95a3.26 3.26 0 01-.783 1.209 3.206 3.206 0 01-1.209.783c-.37.142-.925.317-1.95.363-1.108.05-1.437.062-4.241.062-2.804 0-3.133-.012-4.242-.062-1.025-.046-1.579-.217-1.95-.363a3.258 3.258 0 01-1.208-.783 3.209 3.209 0 01-.784-1.209c-.141-.37-.316-.925-.362-1.95-.05-1.109-.063-1.438-.063-4.243 0-2.805.013-3.134.063-4.242.046-1.026.217-1.58.362-1.95.192-.493.417-.843.784-1.21a3.207 3.207 0 011.208-.783c.371-.142.925-.317 1.95-.362 1.109-.055 1.442-.063 4.242-.063z"
        fill={fill === undefined ? '#D93175' : fill}
      />
    </svg>
  );
};

export default UploadImage;
