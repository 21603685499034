import { FeedbackOverlayState } from 'types/store/FeedbackOverlayState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'FEEDBACK_OVERLAY';
const updateAction = createUpdateAction<FeedbackOverlayState>(ns);
const clearAction = createClearAction(ns);

const feedbackOverlayActions = {
  ...updateAction,
  ...clearAction,
};

export default feedbackOverlayActions;
