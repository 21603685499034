import {
    GetDataShowcasePageDetailsMeta,
    GetDataShowcasePageDetailsPayload,
} from 'types/store/GetDataShowcasePageDetailsState';
import { createAsyncReducer } from 'utils/Redux';

import { getDataShowcasePageDetailsActions } from '../actions';

export default createAsyncReducer<
    GetDataShowcasePageDetailsMeta,
    GetDataShowcasePageDetailsPayload
>(getDataShowcasePageDetailsActions);
