import {
  UpsertApproversStateMeta,
  UpsertApproversStatePayload,
} from 'types/store/UpsertApproversState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertApproversActions } from '../actions';

export default createAsyncReducer<
  UpsertApproversStateMeta,
  UpsertApproversStatePayload
>(upsertApproversActions);
