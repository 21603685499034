import { PublishShowcaseMeta, PublishShowcasePayload } from 'types/store/PublishShowcaseState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'PUBLISH_SHOWCASE';
const asyncAction = createAsyncAction<PublishShowcaseMeta, PublishShowcasePayload>(ns);

const publishShowcaseActions = {
    ...asyncAction,
};

export default publishShowcaseActions;
