import {
  GetAdvocateAnalyticsMeta,
  GetAdvocateAnalyticsPayload,
} from 'types/store/GetAdvocateAnalytics';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATE_ANALYTICS';
const asyncAction = createAsyncAction<
  GetAdvocateAnalyticsMeta,
  GetAdvocateAnalyticsPayload
>(ns);

const getAdvocateAnalyticsAction = {
  ...asyncAction,
};

export default getAdvocateAnalyticsAction;
