import {
  GetCompanyCreditsMeta,
  GetCompanyCreditsPayload,
} from 'types/store/GetCompanyCredits';
import { createAsyncReducer } from 'utils/Redux';

import { getCompanyCreditsActions } from '../actions';

export default createAsyncReducer<
  GetCompanyCreditsMeta,
  GetCompanyCreditsPayload
>(getCompanyCreditsActions);
