import { AdvocateStoryDetailsMeta, AdvocateStoryDetailsPayload } from 'types/store/AdvocateStoryDetails';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_STORY_DETAILS_NS';

const asyncAction = createAsyncAction<AdvocateStoryDetailsMeta, AdvocateStoryDetailsPayload>(ns);

const advocateStoryDetailsActions = { ...asyncAction };

export default advocateStoryDetailsActions;
