import React from 'react';

import { SVGProps } from './SVG.props';

const Russia = (props: SVGProps): JSX.Element => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M40 30.4444C40 32.8989 38.01 34.8889 35.5556 34.8889H4.44444C1.99 34.8889 0 32.8989 0 30.4444V26H40V30.4444Z" fill="#CE2028"/>
      <path d="M0 14.8887H40V25.9998H0V14.8887Z" fill="#22408C"/>
      <path d="M35.5556 6H4.44444C1.99 6 0 7.99 0 10.4444V14.8889H40V10.4444C40 7.99 38.01 6 35.5556 6Z" fill="#EEEEEE"/>
    </svg>
  );
};

export default Russia;
