import React from 'react';

import { SVGProps } from './SVG.props';

const Bold = (props: SVGProps): JSX.Element => {
  const { width, height } = props;

  return (
    <svg width={width || "18"} height={height || "18"} viewBox="0 0 18 18" fill="#070550" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M9.86464 3.75C10.6123 3.75 11.3293 4.06216 11.8579 4.61779C12.3866 5.17343 12.6836 5.92704 12.6836 6.71284C12.6836 7.49863 12.3866 8.25224 11.8579 8.80788C11.3293 9.36352 10.6123 9.67567 9.86464 9.67567H7.245C6.88996 9.67567 6.60214 9.37316 6.60214 9C6.60214 8.62683 6.88996 8.32432 7.245 8.32432H9.86464C10.2713 8.32432 10.6613 8.15454 10.9488 7.85233C11.2363 7.55012 11.3979 7.14023 11.3979 6.71284C11.3979 6.28544 11.2363 5.87556 10.9488 5.57334C10.6617 5.27156 10.2724 5.10183 9.86636 5.10135L5.46804 5.12837C5.11301 5.13055 4.82351 4.82981 4.82144 4.45665C4.81937 4.08349 5.1055 3.77922 5.46053 3.77704L9.86464 3.75Z" fill={"currentColor"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M8.70429 9C8.70429 8.62684 8.9921 8.32433 9.34714 8.32433H10.6811C11.4287 8.32433 12.1457 8.63648 12.6744 9.19212C13.203 9.74776 13.5 10.5014 13.5 11.2872C13.5 12.073 13.203 12.8266 12.6744 13.3822C12.1457 13.9378 11.4287 14.25 10.6811 14.25H5.14286C4.78782 14.25 4.5 13.9475 4.5 13.5743C4.5 13.2012 4.78782 12.8986 5.14286 12.8986H10.6811C11.0877 12.8986 11.4777 12.7289 11.7652 12.4267C12.0528 12.1244 12.2143 11.7146 12.2143 11.2872C12.2143 10.8598 12.0528 10.4499 11.7652 10.1477C11.4777 9.84546 11.0877 9.67568 10.6811 9.67568H9.34714C8.9921 9.67568 8.70429 9.37317 8.70429 9Z" fill={"currentColor"} />
      <path fillRule="evenodd" clipRule="evenodd" d="M6.42857 5.75676C6.78361 5.75676 7.07143 6.05927 7.07143 6.43243V11.5507C7.07143 11.9238 6.78361 12.2263 6.42857 12.2263C6.07353 12.2263 5.78571 11.9238 5.78571 11.5507V6.43243C5.78571 6.05927 6.07353 5.75676 6.42857 5.75676Z" fill={"currentColor"} />
    </svg>
  );
};

export default Bold;


