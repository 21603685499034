import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateVersionDetail } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateVersionDetailMeta, UpdateVersionDetailPayload } from 'types/store/UpdateVersionDetail';

import { updateVersionDetailActions } from '../actions';

function* updateVersionDetailRequest(action: AsyncAction<UpdateVersionDetailMeta, UpdateVersionDetailPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(updateVersionDetail, action.meta, state.auth.token);
      yield put(updateVersionDetailActions.success(data));
    } catch (e: any) {
      yield put(updateVersionDetailActions.failed(e.message));
    }
  }
}

// function* updateVersionDetailSuccess(action: AsyncAction<UpdateVersionDetailMeta, UpdateVersionDetailPayload>): any {
//   yield put()
// }

function* updateVersionDetailWatcher(): any {
  yield takeLatest(updateVersionDetailActions.REQUEST, updateVersionDetailRequest);
  // yield takeLatest(updateVersionDetailActions.SUCCESS, updateVersionDetailSuccess);
}

export default updateVersionDetailWatcher;
