import {
  GetAdvocatesByCompanyIdMeta,
  GetAdvocatesByCompanyIdPayload,
} from 'types/store/GetAdvocatesByCompanyId';
import { createAsyncReducer } from 'utils/Redux';

import { getAdvocatesbyCompanyIdActions } from '../actions';

export default createAsyncReducer<
  GetAdvocatesByCompanyIdMeta,
  GetAdvocatesByCompanyIdPayload
>(getAdvocatesbyCompanyIdActions);
