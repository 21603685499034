import {
    UpsertDataShowcasePageDetailsMeta,
    UpsertDataShowcasePageDetailsPayload,
} from 'types/store/UpsertDataShowcasePageDetailsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_DATA_SHOWCASE_PAGE_DETAILS';
const asyncAction = createAsyncAction<
    UpsertDataShowcasePageDetailsMeta,
    UpsertDataShowcasePageDetailsPayload
>(ns);

const upsertDataShowcasePageDetailsActions = {
    ...asyncAction,
};

export default upsertDataShowcasePageDetailsActions;
