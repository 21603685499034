import {
  UploadRevisedVideoMeta,
  UploadRevisedVideoPayload,
} from 'types/store/UploadRevisedVideo';
import { createAsyncReducer } from 'utils/Redux';

import { uploadRevisedVideoActions } from '../actions';

export default createAsyncReducer<
  UploadRevisedVideoMeta,
  UploadRevisedVideoPayload
>(uploadRevisedVideoActions);
