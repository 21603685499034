import Typography from 'components/base/Typography';
import styled from 'utils/styled';

export const VideoWrapper = styled.div`
  border-radius: 12px;
  overflow: hidden;
  position: relative;

  width: 100%;
  height: 100%;
  max-height: calc(80vh - 100px);
  background-color: #000;

  video {
    outline: none;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  z-index: 50;
`;

export const QuestionContainer = styled.div`
  z-index: 51;
  width: 375px;
  height: 125px;

  overflow: hidden;
`;

export const Question = styled(Typography)``;
