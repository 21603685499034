import {
  GetEmployeeProfileByAuthMeta,
  GetEmployeeProfileByAuthPayload,
} from 'types/store/GetEmployeeProfileByAuth';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_EMPLOYEE_PROFILE_BY_AUTH';
const asyncAction = createAsyncAction<
  GetEmployeeProfileByAuthMeta,
  GetEmployeeProfileByAuthPayload
>(ns);

const getEmployeeProfileByAuthActions = {
  ...asyncAction,
};

export default getEmployeeProfileByAuthActions;
