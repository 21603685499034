import {
  GetAdvocateInvitesMeta,
  GetAdvocateInvitesPayload,
} from 'types/store/GetAdvocateInvites';
import { createAsyncReducer } from 'utils/Redux';

import { getAdvocateInvitesAction } from '../actions';

export default createAsyncReducer<
  GetAdvocateInvitesMeta,
  GetAdvocateInvitesPayload
>(getAdvocateInvitesAction);
