import {
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload,
} from 'types/store/GetStoriesByCompanyIdState';
import { createAsyncReducer } from 'utils/Redux';

import { getStoriesLibraryByCompanyIdActions } from '../actions';

export default createAsyncReducer<
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload
>(getStoriesLibraryByCompanyIdActions);
