import { GenericResponse } from 'types/GenericResponse';
import { createAsyncReducer } from 'utils/Redux';

import setStoryCostActions, {
  SetStoryCostMeta,
} from '../../actions/settings/setStoryCost';

export default createAsyncReducer<SetStoryCostMeta, GenericResponse>(
  setStoryCostActions,
);
