import React from 'react';

import { SVGProps } from './SVG.props';

const Paypal = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.943 4.424c.18-1.132 0-1.898-.61-2.598-.677-.774-1.89-1.1-3.454-1.1h-4.52a.64.64 0 00-.635.546l-1.882 11.94a.386.386 0 00.383.447h2.786l-.196 1.222c-.032.204.13.391.334.391h2.354a.575.575 0 00.562-.48l.024-.123.44-2.81.032-.154a.563.563 0 01.562-.48h.35c2.28 0 4.064-.93 4.586-3.6.22-1.116.106-2.053-.473-2.712a2.17 2.17 0 00-.643-.489z"
        fill="#009BDE"
      />
      <path
        d="M12.943 4.424c.18-1.132 0-1.898-.61-2.598-.677-.774-1.89-1.1-3.454-1.1h-4.52a.64.64 0 00-.635.546l-1.882 11.94a.386.386 0 00.383.447h2.786l.7-4.446-.024.138a.64.64 0 01.635-.545h1.327c2.606 0 4.642-1.06 5.245-4.121.024-.09.033-.18.049-.261z"
        fill="#022169"
      />
      <path
        d="M6.468 4.44a.588.588 0 01.318-.423.599.599 0 01.244-.057h3.543c.423 0 .814.024 1.173.081.106.017.203.033.301.057.098.025.187.05.285.074.049.016.09.024.13.04.18.057.343.13.49.204.178-1.132 0-1.898-.612-2.598C11.656 1.052 10.443.727 8.88.727h-4.52a.64.64 0 00-.635.545l-1.882 11.94a.386.386 0 00.383.447h2.786l.7-4.446.757-4.773z"
        fill="#002F87"
      />
    </svg>
  );
};

export default Paypal;
