import { AnswerQuestionsMeta, AnswerQuestionsPayload } from 'types/store/AnswerQuestions';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ANSWER_QUESTIONS';

const asyncAction = createAsyncAction<AnswerQuestionsMeta, AnswerQuestionsPayload>(ns);

const answerQuestionsActions = { ...asyncAction };

export default answerQuestionsActions;
