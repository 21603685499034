import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createAppointment } from 'services/appointment';
import { AsyncAction } from 'types/Action';
import { CreateAppointmentMeta, CreateAppointmentPayload } from 'types/store/CreateAppointment';
import { Store } from 'types/store/Store';

import { createAppointmentActions } from '../actions';

function* createAppointmentRequest(action: AsyncAction<CreateAppointmentMeta, CreateAppointmentPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(createAppointment, action.meta, state.auth.token);
      yield put(createAppointmentActions.success(data));
    } catch (e: any) {
      yield put(createAppointmentActions.failed(e.message));
    }
  }
}

// function* createAppointmentSuccess(action: AsyncAction<CreateAppointmentMeta, CreateAppointmentPayload>): any {
//   yield put()
// }

function* createAppointmentWatcher(): any {
  yield takeLatest(createAppointmentActions.REQUEST, createAppointmentRequest);
  // yield takeLatest(createAppointmentActions.SUCCESS, createAppointmentSuccess);
}

export default createAppointmentWatcher;
