import {
    GetShowcasePageDetailsMeta,
    GetShowcasePageDetailsPayload,
} from 'types/store/GetShowcasePageDetailsState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcasePageDetailsActions } from '../actions';

export default createAsyncReducer<
    GetShowcasePageDetailsMeta,
    GetShowcasePageDetailsPayload
>(getShowcasePageDetailsActions);
