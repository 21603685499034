import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  UPDATE_THUMPNAIL,
  UpdateThumpnailMeta,
  openToast,
} from 'store/actions';
import { Action } from 'types/Action';
import { Store } from 'types/store/Store';
import { uploadFile } from 'services/upload';
import { updateThumbnail } from 'services/library';

function* uploadThumpnailRequest(action: Action<UpdateThumpnailMeta>): any {
  const token: string = yield select((_: Store) => _.auth.token);
  const {
    payload: {
      story_approval_version_file_id,
      story_id,
      story_video_id,
      library_id,
      file,
    },
  } = action;
  if (token) {
    try {
      let thumbnail_image = '';
      if (action.payload.file) {
        const image = yield uploadFile({
          file: action.payload.file[0],
          token,
          folder: 'library',
        });
        thumbnail_image = image;
      }
      const thumpnail = {
        story_id:
          !story_approval_version_file_id && !story_video_id
            ? story_id
            : undefined,
        library_id:
          !story_approval_version_file_id && !story_video_id
            ? library_id
            : undefined,
        story_approval_version_file_id,
        story_video_id,
        thumbnail_image,
      };
      yield call(
        updateThumbnail,
        thumpnail,
        action.payload.library_id ? 'library' : 'story',
        token,
      );
      yield action.payload.callback(thumbnail_image);
      yield put(openToast('Update Image Success', 'success'));
    } catch (e: any) {
      console.log('e', e);
      yield put(openToast('Some thing occur! Please try again later', 'alert'));
    }
  }
}

function* thumpnailWatcher(): any {
  yield takeLatest(
    UPDATE_THUMPNAIL.UPDATE_THUMPNAIL_REQUEST,
    uploadThumpnailRequest,
  );
}

export default thumpnailWatcher;
