import React from 'react';

import { SVGProps } from './SVG.props';

const Plus = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '10'}
      height={height || '10'}
      viewBox='0 0 10 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 0C4.0111 0 3.0444 0.293245 2.22215 0.842652C1.39991 1.39206 0.759043 2.17295 0.380605 3.08658C0.00216642 4.00021 -0.0968503 5.00555 0.0960759 5.97545C0.289002 6.94536 0.765206 7.83627 1.46447 8.53553C2.16373 9.2348 3.05465 9.711 4.02455 9.90393C4.99446 10.0969 5.99979 9.99784 6.91342 9.6194C7.82705 9.24096 8.60794 8.6001 9.15735 7.77785C9.70676 6.95561 10 5.98891 10 5C10 4.34339 9.87067 3.69321 9.6194 3.08658C9.36813 2.47995 8.99983 1.92876 8.53554 1.46447C8.07124 1.00017 7.52005 0.631876 6.91342 0.380602C6.30679 0.129329 5.65661 0 5 0ZM5 9C4.20888 9 3.43552 8.7654 2.77772 8.32588C2.11992 7.88635 1.60723 7.26164 1.30448 6.53073C1.00173 5.79983 0.92252 4.99556 1.07686 4.21964C1.2312 3.44372 1.61216 2.73098 2.17158 2.17157C2.73099 1.61216 3.44372 1.2312 4.21964 1.07686C4.99557 0.922518 5.79983 1.00173 6.53074 1.30448C7.26164 1.60723 7.88635 2.11992 8.32588 2.77772C8.76541 3.43552 9 4.20888 9 5C9 6.06087 8.57858 7.07828 7.82843 7.82843C7.07828 8.57857 6.06087 9 5 9ZM7 4.5H5.5V3C5.5 2.86739 5.44732 2.74022 5.35356 2.64645C5.25979 2.55268 5.13261 2.5 5 2.5C4.86739 2.5 4.74022 2.55268 4.64645 2.64645C4.55268 2.74022 4.5 2.86739 4.5 3V4.5H3C2.86739 4.5 2.74022 4.55268 2.64645 4.64645C2.55268 4.74022 2.5 4.86739 2.5 5C2.5 5.13261 2.55268 5.25979 2.64645 5.35355C2.74022 5.44732 2.86739 5.5 3 5.5H4.5V7C4.5 7.13261 4.55268 7.25979 4.64645 7.35355C4.74022 7.44732 4.86739 7.5 5 7.5C5.13261 7.5 5.25979 7.44732 5.35356 7.35355C5.44732 7.25979 5.5 7.13261 5.5 7V5.5H7C7.13261 5.5 7.25979 5.44732 7.35356 5.35355C7.44732 5.25979 7.5 5.13261 7.5 5C7.5 4.86739 7.44732 4.74022 7.35356 4.64645C7.25979 4.55268 7.13261 4.5 7 4.5Z'
        fill={fill || '#5487F5'}
      />
    </svg>
  );
};

export default Plus;
