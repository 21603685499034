import {
  InviteAdvocateMeta,
  InviteAdvocatePayload,
} from 'types/store/InviteAdvocateState';
import { createAsyncReducer } from 'utils/Redux';

import { inviteAdvocateActions } from '../actions';

export default createAsyncReducer<InviteAdvocateMeta, InviteAdvocatePayload>(
  inviteAdvocateActions
);
