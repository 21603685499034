import React from 'react';

import { MultiTextFieldGeneratedProps } from './MultiTextField.props';
import MultiTextFieldView from './MultiTextField.view';

const MultiTextField = (props: MultiTextFieldGeneratedProps): JSX.Element => {
  const generatedProps = {
    // generated props here
    ...props
  };
  return <MultiTextFieldView {...generatedProps} />;
};

export default MultiTextField;
