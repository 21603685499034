import {
  UpsertShowcaseFeedbackImageMeta,
  UpsertShowcaseFeedbackImagePayload,
} from 'types/store/UpsertShowcaseFeedbackImageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_SHOWCASE_FEEDBACK_IMAGE';
const asyncAction = createAsyncAction<
  UpsertShowcaseFeedbackImageMeta,
  UpsertShowcaseFeedbackImagePayload
>(ns);

const upsertShowcaseFeedbackImageActions = {
  ...asyncAction,
};

export default upsertShowcaseFeedbackImageActions;
