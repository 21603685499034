import { ResendInvitationMeta, ResendInvitationPayload } from 'types/store/ResendInvitation';
import { createAsyncAction } from 'utils/Redux';

const ns = 'RESEND_INVITATION_NS';

const asyncAction = createAsyncAction<ResendInvitationMeta, ResendInvitationPayload>(ns);

const resendInvitationActions = { ...asyncAction };

export default resendInvitationActions;
