import { ShowcaseV2Payload } from 'types/store/ShowcaseV2State';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'SHOWCASE_V2';
const updateAction = createUpdateAction<ShowcaseV2Payload>(ns);
const clearAction = createClearAction(ns);

const showcaseV2Actions = {
  ...updateAction,
  ...clearAction,
};

export default showcaseV2Actions;
