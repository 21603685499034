import {
  AdvocateStoryOverviewMeta,
  AdvocateStoryOverviewPayload,
} from 'types/store/AdvocateStoryOverview';
import { createAsyncReducer } from 'utils/Redux';

import { advocateStoryOverviewEarchTabsNumberStoriesActions } from '../actions';

export default createAsyncReducer<
  AdvocateStoryOverviewMeta,
  AdvocateStoryOverviewPayload
>(advocateStoryOverviewEarchTabsNumberStoriesActions);
