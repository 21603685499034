import {
  AdvocateStoryOverviewMeta,
  AdvocateStoryOverviewPayload,
} from 'types/store/AdvocateStoryOverview';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_STORY_OVERVIEW_EARCH_TABS_STORIES_NUMBER_NS';

const asyncAction = createAsyncAction<
  AdvocateStoryOverviewMeta,
  AdvocateStoryOverviewPayload
>(ns);

const advocateStoryOverviewEarchTabsNumberStoriesActions = { ...asyncAction };

export default advocateStoryOverviewEarchTabsNumberStoriesActions;
