import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getHeadlineAI } from 'services/topic';
import { AsyncAction } from 'types/Action';
import { GetHeadlineAIMeta, GetHeadlineAIPayload } from 'types/store/GetAdvocateInterest';
import { Store } from 'types/store/Store';

import { getHeadlineAIActions } from '../actions';

function* getHeadlineAiRequest(
  action: AsyncAction<GetHeadlineAIMeta, GetHeadlineAIPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      yield put(getHeadlineAIActions.success({isGenLoading: true}));
      const { data } = yield call(getHeadlineAI, action.meta, state.auth.token);
      
      yield put(getHeadlineAIActions.success({headline: data.data}));
    } catch (e: any) {
      yield put(getHeadlineAIActions.failed(e.message));
      yield put(getHeadlineAIActions.success({isGenLoading: false}));
    }
  } else {
    yield put(getHeadlineAIActions.failed('Token not found'));
  }
}

function* getHeadlineWatcher(): any {
  yield takeLatest(getHeadlineAIActions.REQUEST, getHeadlineAiRequest);
}

export default getHeadlineWatcher;
