import {
  ExtractTextFromUrlMeta,
  ExtractTextFromUrlPayload,
} from 'types/store/ExtractTextFromUrl';
import { createAsyncReducer } from 'utils/Redux';

import { extractTextFromUrlActions } from '../actions';

export default createAsyncReducer<
  ExtractTextFromUrlMeta,
  ExtractTextFromUrlPayload
>(extractTextFromUrlActions);
