import {
  GetSuggestedQuestionsMeta,
  GetSuggestedQuestionsPayload,
} from 'types/store/GetSuggestedQuestions';
import { createAsyncAction, createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'GET_QUESTIONS';
const updateAction = createUpdateAction<GetSuggestedQuestionsPayload>(ns);
const asyncAction = createAsyncAction<
  GetSuggestedQuestionsMeta,
  GetSuggestedQuestionsPayload
>(ns);
const clearAction = createClearAction(ns);

const getQuestionsActions = {
  ...updateAction,
  ...asyncAction,
  ...clearAction
};

export default getQuestionsActions;
