import {
  GetStoryEventsMeta,
  GetStoryEventsPayload,
} from 'types/store/GetStoryEventsState';
import { createAsyncReducer } from 'utils/Redux';

import { getStoryEventsActions } from '../actions';

export default createAsyncReducer<GetStoryEventsMeta, GetStoryEventsPayload>(
  getStoryEventsActions
);
