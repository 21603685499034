import {
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload,
} from 'types/store/GetHomePageHeaderState';
import { createAsyncReducer } from 'utils/Redux';

import { getHomePageShortcutsActions } from '../actions';

export default createAsyncReducer<
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload
>(getHomePageShortcutsActions);
