import {
  GetNotificationsByUserIdMeta,
  GetNotificationsByUserIdPayload,
} from 'types/store/GetNotificationsByUserIdState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_NOTIFICATIONS_BY_USER_ID';

const asyncAction = createAsyncAction<
  GetNotificationsByUserIdMeta,
  GetNotificationsByUserIdPayload
>(ns);

const getNotificationsByUserIdActions = {
  ...asyncAction,
};

export default getNotificationsByUserIdActions;
