import axios from 'axios';
import { API } from 'consts';
import { ExtractTextFromFileMeta } from 'types/store/ExtractTextFromFileState';
import { ExtractTextFromUrlMeta } from 'types/store/ExtractTextFromUrl';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const TEXT_URL = `${BASE_URL}/file`;

export const extractTextFromFile = (
  data: ExtractTextFromFileMeta,
  token: string
): any => {
  const fd = new FormData();

  if (data.document_story !== undefined) {
    fd.append('document_story', data.document_story);
  }
  // fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${TEXT_URL}/extractTextFromFile`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const extractTextFromUrl = (
  data: ExtractTextFromUrlMeta,
  token: string
): any => {
  return axios({
    method: 'POST',
    url: `${TEXT_URL}/extractTextFromUrl`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
