import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createShowcase } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  CreateShowcaseMeta,
  CreateShowcasePayload,
} from 'types/store/CreateShowcaseState';
import { Store } from 'types/store/Store';

import { createShowcaseActions } from '../actions';

function* createShowcaseRequest(
  action: AsyncAction<CreateShowcaseMeta, CreateShowcasePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createShowcase,
        action.meta,
        state.auth.token
      );
      yield put(createShowcaseActions.success(data));
    } catch (e: any) {
      yield put(createShowcaseActions.failed(e.message));
    }
  }
}

function* createShowcaseSuccess(
  action: AsyncAction<CreateShowcaseMeta, CreateShowcasePayload>
) {
  //   yield put(push(EMPLOYER_ROUTES.STORY_EDITOR));
  //   window.location.reload();
}

function* createShowcaseWatcher(): any {
  yield takeLatest(createShowcaseActions.REQUEST, createShowcaseRequest);
  yield takeLatest(createShowcaseActions.SUCCESS, createShowcaseSuccess);
}

export default createShowcaseWatcher;
