import {
  GetAllAdvocatesMeta,
  GetAllAdvocatesPayload,
} from 'types/store/Company';
import { createAsyncAction } from 'utils/Redux';

const GET_ALL_ADVOCATE_BY_COMPANY_NS = 'GET_ALL_ADVOCATE_BY_COMPANY_NS';

const getAllAdvocateByCompanyActions =
  // ...createAsyncAction<GetAllAdvocatesMeta, GetAllAdvocatesPayload>(
  //   GET_ALL_ADVOCATE_BY_COMPANY_NS
  // ),
  createAsyncAction<GetAllAdvocatesMeta>(GET_ALL_ADVOCATE_BY_COMPANY_NS);

export { getAllAdvocateByCompanyActions };
