import { GenericResponse } from 'types/GenericResponse';
import { createAsyncReducer } from 'utils/Redux';

import setStoryToRecipeActions, {
  ISetStoryToRecipeMeta,
} from '../../actions/recipeDetails/setStoryToRecipe';

export default createAsyncReducer<ISetStoryToRecipeMeta, GenericResponse>(
  setStoryToRecipeActions,
);
