import styled from 'utils/styled';
import theme from 'utils/Theme';

export const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 20px 20px 0 0;
  z-index: ${theme.zIndex.snackbar};
`;

export const Snackbar = styled.div`
  position: relative;
  display: flex;
  background-color: white;
  min-width: 470px;
  min-height: 72px;
  padding: 16px 16px 16px 24px;
  margin-top: 8px;
  filter: drop-shadow(0px 4px 12px rgba(41, 43, 50, 0.08));
  box-sizing: border-box;
  overflow: hidden;
  border-radius: 12px;
  animation-name: fadeIn;
  animation-duration: 200ms;
  animation-timing-function: ease-in-out;
  animation-fill-mode: forwards;
`;

export const Content = styled.div`
  flex: 1;
  padding: 0 12px;
`;

export const LeftBorder = styled.div<{ color: string }>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: ${({ color }) => color};
`;

export const CloseContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px;
  margin: -8px;
  transition: all 200ms ease;
  border-radius: 100px;
  width: auto;
  height: auto;

  &:hover {
    background-color: rgba(222, 222, 222, 0.4);
  }
`;

export const ActionBox = styled.div`
  display: flex;
  align-items: center;
`;
