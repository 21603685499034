import {
  GetStoryEventsMeta,
  GetStoryEventsPayload,
} from 'types/store/GetStoryEventsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_EVENTS';
const asyncAction = createAsyncAction<
  GetStoryEventsMeta,
  GetStoryEventsPayload
>(ns);

const getStoryEventsActions = {
  ...asyncAction,
};

export default getStoryEventsActions;
