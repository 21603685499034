import { push } from 'connected-react-router';
import { EMPLOYER_ROUTES } from 'consts';
import { put, call, takeLatest, delay } from 'redux-saga/effects';
import { forgotPassword } from 'services/auth';
import { AsyncAction } from 'types/Action';
import {
  ForgotPasswordMeta,
  ForgotPasswordPayload,
} from 'types/store/ForgotPasswordState';

import { forgotPasswordActions, openToast } from '../actions';

function* forgotPasswordRequest(
  action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>
) {
  try {
    const { data } = yield call(forgotPassword, {
      email: action.meta.email,
    });
    yield put(forgotPasswordActions.success(data));
  } catch (e: any) {
    let message;
    if (e && Object.keys(e).length === 0) {
      message = 'Server busy, please try again later'
    } else {
      message = e.response?.data?.message || e.message;
    }
    yield put(forgotPasswordActions.failed(message));
  }
}

function* forgotPasswordSuccess(
  action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>
) {
  yield put(openToast(action.payload.message, 'success'))
  yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* forgotPasswordWatcher(): any {
  yield takeLatest(forgotPasswordActions.REQUEST, forgotPasswordRequest);
  yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default forgotPasswordWatcher;
