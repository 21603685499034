import { push, goBack } from 'connected-react-router';
import { ADVOCATE_ROUTES } from 'consts';
import { put, call, select, takeLatest } from 'redux-saga/effects';
import { register, registerWidthExistedToken } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  RegisterAdvocateMeta,
  RegisterAdvocatePayload,
} from 'types/store/RegisterAdvocateState';
import { Store } from 'types/store/Store';
import accessRouterActions from 'store/actions/auth/accessRouter';
import { AxiosError } from 'axios';

import { registerAdvocateActions, authActions } from '../actions';

function* registerAdvocateRequest(
  action: AsyncAction<RegisterAdvocateMeta, RegisterAdvocatePayload>,
) {
  const state: Store = yield select();
  try {
    const { reference, token } = action.meta;
    if (reference && token) {
      const { data } = yield call(register, action.meta);
      yield put(registerAdvocateActions.success(data));
      yield put(push(ADVOCATE_ROUTES.ONBOARDING));
      if (action.meta.viaMagicLink) return;
    }
    if (state.auth.token) {
      const { data } = yield call(
        registerWidthExistedToken,
        action.meta,
        state.auth.token,
      );
      yield put(registerAdvocateActions.success(data));
      yield put(accessRouterActions.request({ platform: data.data.platform }));
      yield put(push(ADVOCATE_ROUTES.ONBOARDING));
    }
  } catch (e: unknown) {
    const errorMessage = e as AxiosError;
    if (errorMessage?.response?.status === 400) {
      yield put(
        authActions.update({
          messageError:
            errorMessage?.response?.data.message ||
            'Email already taken or password is incorrect.',
        }),
      );
      yield put(goBack());
    }
    yield put(
      registerAdvocateActions.failed(errorMessage?.response?.data.message),
    );
  }
}

function* registerAdvocateSuccess(
  action: AsyncAction<RegisterAdvocateMeta, RegisterAdvocatePayload>,
) {
  yield put(
    authActions.update({
      token: action.payload.data.token,
      platform: action.payload.data.platform,
      refresh_token: action.payload.data.refresh_token || '1',
      storyId: action.payload.data.storyId,
      shareStoryId: action.payload.data.shareStoryId,
      isFirst: action.payload.data.isFirst,
    }),
  );

  // if (action.payload.data.shareStoryId) {
  //   yield put(push(`${ADVOCATE_ROUTES.ONBOARDING}?shareStoryId=${action.payload.data.shareStoryId}`));
  // } else if (action.payload.data.storyId) {
  //   yield put(push(`${ADVOCATE_ROUTES.ONBOARDING}?storyId=${action.payload.data.storyId}`));
  // } else yield put(push(ADVOCATE_ROUTES.ONBOARDING));
}

// function* registerFailed(action: AsyncAction<RegisterMeta, RegisterPayload>) {}

function* registerAdvocateWatcher(): any {
  yield takeLatest(registerAdvocateActions.REQUEST, registerAdvocateRequest);
  yield takeLatest(registerAdvocateActions.SUCCESS, registerAdvocateSuccess);
  // yield takeLatest(registerActions.FAILED, registerFailed);
}

export default registerAdvocateWatcher;
