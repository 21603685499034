import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowUp = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg width={width || 24} height={height || 24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.4386 13.1858L12.5692 9.83823C12.2997 9.52374 11.8262 9.48732 11.5117 9.75689C11.4825 9.78188 11.4553 9.80908 11.4303 9.83823L8.56097 13.1857C8.2914 13.5002 8.32782 13.9737 8.64232 14.2433C8.77825 14.3598 8.95138 14.4238 9.13042 14.4238L14.8691 14.4238C15.2834 14.4238 15.6191 14.088 15.6191 13.6738C15.6191 13.4948 15.5551 13.3217 15.4386 13.1858Z" fill={fill || "#070550"} />
    </svg>
  );
};

export default ArrowUp;
