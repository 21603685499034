import {
  DeleteShowcaseFeedbackMeta,
  DeleteShowcaseFeedbackPayload,
} from 'types/store/DeleteShowcaseFeedbackState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_SHOWCASE_FEEDBACK';

const asyncAction = createAsyncAction<
  DeleteShowcaseFeedbackMeta,
  DeleteShowcaseFeedbackPayload
>(ns);

const deleteShowcaseFeedbackActions = {
  ...asyncAction,
};

export default deleteShowcaseFeedbackActions;
