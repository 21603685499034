import {
  GeneratePageMeta,
  GeneratePagePayload,
} from 'types/store/GeneratePage';
import { createAsyncReducer } from 'utils/Redux';

import { generatePageActions } from '../actions';

export default createAsyncReducer<GeneratePageMeta, GeneratePagePayload>(
  generatePageActions
);
