import {
  GetShowcaseCompanyByUrlMeta,
  GetShowcaseCompanyByUrlPayload,
} from 'types/store/GetShowcaseCompanyByUrlState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_COMPANY_BY_URL';
const asyncAction = createAsyncAction<
  GetShowcaseCompanyByUrlMeta,
  GetShowcaseCompanyByUrlPayload
>(ns);

const getShowcaseCompanyByUrlActions = {
  ...asyncAction,
};

export default getShowcaseCompanyByUrlActions;
