import {
  AddGetSupportNotificationStateMeta,
  AddGetSupportNotificationStatePayload,
} from 'types/store/AddGetSupportNotificationState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADD_GET_SUPPORT_NOTIFICATION';
const asyncAction = createAsyncAction<
  AddGetSupportNotificationStateMeta,
  AddGetSupportNotificationStatePayload
>(ns);

const addGetSupportNotificationActions = {
  ...asyncAction,
};

export default addGetSupportNotificationActions;
