import {
    DownloadResponsesVideoStoryMeta,
    DownloadResponsesVideoStoryPayload,
} from 'types/store/DownloadResponsesVideoStoryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DOWNLOAD_RESPONSES_VIDEO_STORY';
const asyncAction = createAsyncAction<
    DownloadResponsesVideoStoryMeta,
    DownloadResponsesVideoStoryPayload
>(ns);

const downloadResponsesActions = {
    ...asyncAction,
};

export default downloadResponsesActions;
