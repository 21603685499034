import {
  UpdateBillingAddressMeta,
  UpdateBillingAddressPayload
} from 'types/store/UpdateBillingAddress';
import { createAsyncReducer } from 'utils/Redux';

import { updateBillingAddressActions } from '../actions';

export default createAsyncReducer<
  UpdateBillingAddressMeta,
  UpdateBillingAddressPayload
>(updateBillingAddressActions);
