import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const Thunder = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63518 0.738786C9.9124 0.879557 10.0555 1.19327 9.9801 1.4949L8.85386 5.99988H13.3333C13.5977 5.99988 13.8371 6.1561 13.9436 6.3981C14.05 6.6401 14.0034 6.92214 13.8248 7.11702L6.49144 15.117C6.27572 15.3524 5.92345 15.4017 5.65135 15.2348C5.37924 15.0678 5.26369 14.7314 5.37579 14.4325L7.03801 9.99988H2.66667C2.408 9.99988 2.17267 9.85023 2.06295 9.61598C1.95323 9.38172 1.98893 9.10514 2.15453 8.90642L8.82119 0.906418C9.02024 0.667566 9.35796 0.598016 9.63518 0.738786Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Thunder;
