import React from 'react';

import { ISnackbarContext } from './types';

export default React.createContext<ISnackbarContext>({
  queue: [],
  info: () => null,
  warning: () => null,
  alert: () => null,
  success: () => null,
  error: () => null,
  remove: () => null,
});
