import { ShareToLinkedinMeta, ShareToLinkedinPayload } from 'types/store/ShareToLinkedin';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SHARE_TO_LINKEDIN_NS';

const asyncAction = createAsyncAction<ShareToLinkedinMeta, ShareToLinkedinPayload>(ns);

const shareToLinkedinActions = { ...asyncAction };

export default shareToLinkedinActions;
