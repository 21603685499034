import React from 'react';

import { SVGProps } from './SVG.props';

const InfoFilledGray = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <g opacity="0.5">
        <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.66667 4.33333C8.66667 4.88562 8.21895 5.33333 7.66667 5.33333C7.11438 5.33333 6.66667 4.88562 6.66667 4.33333C6.66667 3.78105 7.11438 3.33333 7.66667 3.33333C8.21895 3.33333 8.66667 3.78105 8.66667 4.33333ZM6.66667 6C6.29848 6 6 6.29848 6 6.66667C6 7.03486 6.29848 7.33333 6.66667 7.33333H7.33333V10H6C5.63181 10 5.33333 10.2985 5.33333 10.6667C5.33333 11.0349 5.63181 11.3333 6 11.3333H10C10.3682 11.3333 10.6667 11.0349 10.6667 10.6667C10.6667 10.2985 10.3682 10 10 10H8.66667V6.66667C8.66667 6.29848 8.36819 6 8 6H6.66667Z" fill="#030220" />
      </g>
    </svg>

  );
};

export default InfoFilledGray;
