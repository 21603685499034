import {
    ChangePasswordEBMeta,
    ChangePasswordEBPayload,
  } from 'types/store/ChangePasswordEBState';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { changePasswordEBActions } from '../actions';
  
  export default createAsyncReducer<ChangePasswordEBMeta, ChangePasswordEBPayload>(
    changePasswordEBActions
  );