import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStripeCustomerId } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  GetStripeCustomerIdMeta,
  GetStripeCustomerIdPayload
} from 'types/store/GetStripeCustomerId';
import { Store } from 'types/store/Store';

import { getStripeCustomerIdActions } from '../actions';

function* getStripeCustomerIdRequest(
  action: AsyncAction<GetStripeCustomerIdMeta, GetStripeCustomerIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStripeCustomerId, action.meta, state.auth.token);
      yield put(getStripeCustomerIdActions.success(data));
    } catch (e: any) {
      yield put(getStripeCustomerIdActions.failed(e.message));
    }
  } else {
    yield put(getStripeCustomerIdActions.failed('Token not found'));
  }
}

function* getStripeCustomerIdSuccess(
  action: AsyncAction<GetStripeCustomerIdMeta, GetStripeCustomerIdPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getStripeCustomerIdWatcher(): any {
  yield takeLatest(getStripeCustomerIdActions.REQUEST, getStripeCustomerIdRequest);
  yield takeLatest(getStripeCustomerIdActions.SUCCESS, getStripeCustomerIdSuccess);
}

export default getStripeCustomerIdWatcher;
