import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

interface Tag {
  name: string;
  group: string;
}

export interface ICreateTagsMeta {
  tags: Tag[];
}

const createTagsNS = 'LIBRARY_CREATE_TAGS';

const createTags = createAsyncAction<ICreateTagsMeta, GenericResponse>(
  createTagsNS,
);

export default createTags;
