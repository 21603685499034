import {
  UnlockAdvocateCallPayload,
  UnlockAdvocateCalllMeta,
} from 'types/store/UnlockAdvocateCall';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_UNCLOCK_CALL';
const asyncAction = createAsyncAction<
  UnlockAdvocateCalllMeta,
  UnlockAdvocateCallPayload
>(ns);

const unClockAdvocateCallActions = {
  ...asyncAction,
};

export default unClockAdvocateCallActions;
