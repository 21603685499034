import { CreateStoryVersionMeta, CreateStoryVersionPayload } from 'types/store/CreateStoryVersion';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_STORY_VERSION_NS';

const asyncAction = createAsyncAction<CreateStoryVersionMeta, CreateStoryVersionPayload>(ns);

const createStoryVersionActions = { ...asyncAction };

export default createStoryVersionActions;
