import {
    CreateLibraryMeta,
    CreateLibraryPayload,
  } from 'types/store/CreateLibraryState';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { createLibraryActions } from '../actions';
  
  export default createAsyncReducer<CreateLibraryMeta, CreateLibraryPayload>(
    createLibraryActions
  );
  