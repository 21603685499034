import {
    ChangePasswordEBMeta,
    ChangePasswordEBPayload,
} from 'types/store/ChangePasswordEBState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHANGE_PASSWORD_EB_TAGS';
const asyncAction = createAsyncAction<ChangePasswordEBMeta, ChangePasswordEBPayload>(ns);

const changePasswordEBActions = {
    ...asyncAction,
};

export default changePasswordEBActions;
