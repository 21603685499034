import {
    AddCardMeta,
    AddCardPayload
  } from 'types/store/AddCard';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'ADD_CARD';
  
  const asyncAction = createAsyncAction<AddCardMeta, AddCardPayload>(ns);
  
  const addCardActions = {
    ...asyncAction,
  };
  
  export default addCardActions;
  