import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fetchSocialReach } from 'services/analytics';
import { analyticSocialReachActions } from 'store/actions';
import { IAnalyticSocialReachMeta } from 'store/actions/analytics/ROI/socialReach';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getSocialReach(
  action: AsyncAction<IAnalyticSocialReachMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchSocialReach,
        action.meta,
        state.auth.token,
      );
      yield put(analyticSocialReachActions.success(data));
    } catch (e: any) {
      yield put(analyticSocialReachActions.failed(e.message));
    }
  }
}

function* analyticWatcher(): any {
  yield takeLatest(analyticSocialReachActions.REQUEST, getSocialReach);
}

export default analyticWatcher;
