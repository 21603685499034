import { GetSearchBarPayload } from 'types/store/GetSearchBar';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'GET_SEARCH_BAR_NS';

const updateAction = createUpdateAction<GetSearchBarPayload>(ns);
const clearAction = createClearAction(ns);

const getSearchBarActions = { ...updateAction, ...clearAction };

export default getSearchBarActions;
