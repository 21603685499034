import * as React from 'react';

import { SVGProps } from './SVG.props';

const SVGComponent = (props: SVGProps): JSX.Element => {
  const { fill } = props;
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.4989 6.99237H6.90892L8.20892 5.70237C8.30216 5.60913 8.37612 5.49844 8.42658 5.37662C8.47704 5.2548 8.50301 5.12423 8.50301 4.99237C8.50301 4.86051 8.47704 4.72995 8.42658 4.60812C8.37612 4.4863 8.30216 4.37561 8.20892 4.28237C8.11568 4.18913 8.00499 4.11517 7.88317 4.06471C7.76135 4.01425 7.63078 3.98828 7.49892 3.98828C7.36706 3.98828 7.23649 4.01425 7.11467 4.06471C6.99285 4.11517 6.88216 4.18913 6.78892 4.28237L3.78892 7.28237C3.69788 7.37748 3.62651 7.48962 3.57892 7.61237C3.4789 7.85583 3.4789 8.12891 3.57892 8.37237C3.62651 8.49512 3.69788 8.60727 3.78892 8.70237L6.78892 11.7024C6.88188 11.7961 6.99248 11.8705 7.11434 11.9213C7.2362 11.972 7.36691 11.9982 7.49892 11.9982C7.63093 11.9982 7.76164 11.972 7.8835 11.9213C8.00536 11.8705 8.11596 11.7961 8.20892 11.7024C8.30265 11.6094 8.37704 11.4988 8.42781 11.3769C8.47858 11.2551 8.50472 11.1244 8.50472 10.9924C8.50472 10.8604 8.47858 10.7297 8.42781 10.6078C8.37704 10.4859 8.30265 10.3753 8.20892 10.2824L6.90892 8.99237H18.4989C18.7641 8.99237 19.0185 8.88702 19.206 8.69948C19.3936 8.51194 19.4989 8.25759 19.4989 7.99237C19.4989 7.72716 19.3936 7.4728 19.206 7.28527C19.0185 7.09773 18.7641 6.99237 18.4989 6.99237Z"
        fill={fill || '#070550'}
      />
      <path
        d="M4.50393 16.0023H16.0939L14.7939 14.7123C14.7007 14.619 14.6267 14.5083 14.5763 14.3865C14.5258 14.2647 14.4998 14.1341 14.4998 14.0023C14.4998 13.8704 14.5258 13.7398 14.5763 13.618C14.6267 13.4962 14.7007 13.3855 14.7939 13.2923C14.8872 13.199 14.9979 13.1251 15.1197 13.0746C15.2415 13.0241 15.3721 12.9982 15.5039 12.9982C15.6358 12.9982 15.7664 13.0241 15.8882 13.0746C16.01 13.1251 16.1207 13.199 16.2139 13.2923L19.2139 16.2923C19.305 16.3874 19.3763 16.4995 19.4239 16.6223C19.524 16.8657 19.524 17.1388 19.4239 17.3823C19.3763 17.505 19.305 17.6172 19.2139 17.7123L16.2139 20.7123C16.121 20.806 16.0104 20.8804 15.8885 20.9312C15.7667 20.9819 15.6359 21.0081 15.5039 21.0081C15.3719 21.0081 15.2412 20.9819 15.1194 20.9312C14.9975 20.8804 14.8869 20.806 14.7939 20.7123C14.7002 20.6193 14.6258 20.5087 14.575 20.3868C14.5243 20.265 14.4981 20.1343 14.4981 20.0023C14.4981 19.8702 14.5243 19.7395 14.575 19.6177C14.6258 19.4958 14.7002 19.3852 14.7939 19.2923L16.0939 18.0023H4.50393C4.23872 18.0023 3.98436 17.8969 3.79683 17.7094C3.60929 17.5218 3.50393 17.2675 3.50393 17.0023C3.50393 16.737 3.60929 16.4827 3.79683 16.2952C3.98436 16.1076 4.23872 16.0023 4.50393 16.0023Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default SVGComponent;
