import {
  UpdateShowcaseUrlMeta,
  UpdateShowcaseUrlPayload,
} from 'types/store/UpdateShowcaseUrlState';
import { createAsyncReducer } from 'utils/Redux';

import { updateShowcaseUrlActions } from '../actions';

export default createAsyncReducer<
  UpdateShowcaseUrlMeta,
  UpdateShowcaseUrlPayload
>(updateShowcaseUrlActions);
