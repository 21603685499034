import { ApproversApproveMeta, ApproversApprovePayload } from 'types/store/ApproversApprove';
import { createAsyncAction } from 'utils/Redux';

const ns = 'APPROVERS_APPROVE_NS';

const asyncAction = createAsyncAction<ApproversApproveMeta, ApproversApprovePayload>(ns);

const approversApproveActions = { ...asyncAction };

export default approversApproveActions;
