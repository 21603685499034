import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAllWithDiscussionStatus } from 'services/content-package';
import { AsyncAction } from 'types/Action';
import { GetAllContentPackageWithDiscussionStatusMeta, GetAllContentPackageWithDiscussionStatusPayload } from 'types/store/GetAllContentPackageWithDiscussionStatus';
import { Store } from 'types/store/Store';

import { getAllContentPackageWithDiscussionStatusActions } from '../actions';

function* getAllContentPackageWithDiscussionStatusRequest(action: AsyncAction<GetAllContentPackageWithDiscussionStatusMeta, GetAllContentPackageWithDiscussionStatusPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getAllWithDiscussionStatus, action.meta, state.auth.token);
      yield put(getAllContentPackageWithDiscussionStatusActions.success(data));
    } catch (e: any) {
      yield put(getAllContentPackageWithDiscussionStatusActions.failed(e.message));
    }
  }
}

// function* getAllContentPackageWithDiscussionStatusSuccess(action: AsyncAction<GetAllContentPackageWithDiscussionStatusMeta, GetAllContentPackageWithDiscussionStatusPayload>): any {
//   yield put()
// }

function* getAllContentPackageWithDiscussionStatusWatcher(): any {
  yield takeLatest(getAllContentPackageWithDiscussionStatusActions.REQUEST, getAllContentPackageWithDiscussionStatusRequest);
  // yield takeLatest(getAllContentPackageWithDiscussionStatusActions.SUCCESS, getAllContentPackageWithDiscussionStatusSuccess);
}

export default getAllContentPackageWithDiscussionStatusWatcher;
