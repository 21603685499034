import React from 'react';

import { SVGProps } from './SVG.props';

const ImageSlash = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill={fill || 'none'}
      {...props}
      // xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.666H6.667a.667.667 0 000 1.333H13a.666.666 0 01.667.667v4.507l-1.254-1.254a2 2 0 00-.76-.473.668.668 0 00-.426 1.267.546.546 0 01.24.153l2.206 2.194a.44.44 0 000 .1.557.557 0 000 .1.785.785 0 00.087.12.32.32 0 00.06.073.6.6 0 00.133.093.607.607 0 00.32.093.667.667 0 00.667-.666V4.666a2 2 0 00-1.94-2zM2.14 1.526a.67.67 0 10-.947.947l.927.926a2 2 0 00-.453 1.267v6.667a2 2 0 002 2h8.393l1.133 1.14a.666.666 0 00.947 0 .668.668 0 000-.947l-12-12zM3 4.666a.667.667 0 01.08-.307l1.813 1.807a2 2 0 00-.666.42L3 7.84V4.666zM3.667 12A.667.667 0 013 11.333V9.719l2.2-2.193a.667.667 0 01.933 0L10.607 12h-6.94z"
        fill={fill || '#DED9DB'}
      />
    </svg>
  );
};

export default ImageSlash;
