import { push } from 'connected-react-router';
import { MAIN_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { sendApprovalNotification } from 'services/notifications';
import { AsyncAction } from 'types/Action';
import {
  SendApprovalNotificationMeta,
  SendApprovalNotificationPayload,
} from 'types/store/SendApprovalNotificationState';
import { Store } from 'types/store/Store';

import { sendApprovalNotificationActions } from '../actions';

function* sendApprovalNotificationRequest(
  action: AsyncAction<
    SendApprovalNotificationMeta,
    SendApprovalNotificationPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        sendApprovalNotification,
        action.meta,
        state.auth.token
      );
      yield put(sendApprovalNotificationActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield put(push(MAIN_ROUTES.NOT_FOUND));
      } else {
        yield put(sendApprovalNotificationActions.failed(e.message));
      }
    }
  } else {
    yield put(sendApprovalNotificationActions.failed('Token not found'));
  }
}

// function* getStoryByIdSuccess(
//   action: AsyncAction<GetStoryByIdMeta, GetStoryByIdPayload>
// ) {
// }

function* sendApprovalNotificationWatcher(): any {
  yield takeLatest(
    sendApprovalNotificationActions.REQUEST,
    sendApprovalNotificationRequest
  );
  // yield takeLatest(getStoryByIdActions.SUCCESS, getStoryByIdSuccess);
}

export default sendApprovalNotificationWatcher;
