import pathOr from 'ramda/es/pathOr';
import saveDynamicFilterActions from 'store/actions/saveDynamicFilter';
import { Action } from 'types/Action';
import { SaveDynamicMetaState } from 'types/store/saveDynamicFilter';

const DEFAULT_STATE: SaveDynamicMetaState = {};

const DEFAULT_ACTION: Action<any> = {
  type: '',
  payload: {},
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  const localReducer = (): any => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [saveDynamicFilterActions.UPDATE]: localReducer(),
    [saveDynamicFilterActions.CLEAR]: DEFAULT_STATE,
  });
};
