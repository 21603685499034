import { put, call, takeLatest, select } from 'redux-saga/effects';
import { checkEmailRegister } from 'services/team';
import { AsyncAction } from 'types/Action';
import {
  CheckEmailRegisterMeta,
  CheckEmailRegisterPayload,
} from 'types/store/CheckEmailRegister';

import { checkEmailRegisterActions } from '../actions';

function* checkEmailRegisterRequest(
  action: AsyncAction<CheckEmailRegisterMeta, CheckEmailRegisterPayload>,
): any {
  try {
    const { data } = yield call(checkEmailRegister, {
      email: action.meta.email,
    });
    if (data.data.isExist && action.meta.cb) {
      action.meta.cb();
    } else action.meta.cb?.(true);
    yield put(checkEmailRegisterActions.success(data));
  } catch (e: any) {
    yield put(checkEmailRegisterActions.failed(e.message));
  }
}

// function* checkEmailRegisterSuccess(action: AsyncAction<CheckEmailRegisterMeta, CheckEmailRegisterPayload>): any {
//   yield put()
// }

function* checkEmailRegisterWatcher(): any {
  yield takeLatest(
    checkEmailRegisterActions.REQUEST,
    checkEmailRegisterRequest,
  );
  // yield takeLatest(checkEmailRegisterActions.SUCCESS, checkEmailRegisterSuccess);
}

export default checkEmailRegisterWatcher;
