import { GetSmsTemplateMeta, GetSmsTemplatePayload } from 'types/store/GetSmsTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SMS_TEMPLATE_NS';

const asyncAction = createAsyncAction<GetSmsTemplateMeta, GetSmsTemplatePayload>(ns);

const getSmsTemplateActions = { ...asyncAction };

export default getSmsTemplateActions;
