import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const EyeOff = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5286 8.86193C9.26825 9.12228 9.26825 9.54439 9.5286 9.80474C9.78895 10.0651 10.2111 10.0651 10.4714 9.80474L13.8047 6.47141C14.0651 6.21106 14.0651 5.78895 13.8047 5.5286L10.4714 2.19526C10.2111 1.93491 9.78895 1.93491 9.5286 2.19526C9.26825 2.45561 9.26825 2.87772 9.5286 3.13807L11.7239 5.33334H8C6.20021 5.33334 4.6965 5.87494 3.63488 6.84004C2.56944 7.80863 2 9.15916 2 10.6667V13.3333C2 13.7015 2.29848 14 2.66667 14C3.03486 14 3.33333 13.7013 3.33333 13.3332V10.6667C3.33333 9.50751 3.76389 8.52471 4.53178 7.82663C5.3035 7.12507 6.46645 6.66667 8 6.66667H11.7239L9.5286 8.86193Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default EyeOff;
