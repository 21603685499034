import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticWebCrawTrafficMeta {
  crawData: string[];
  isEdit: boolean
}

const addLinkTraffic = 'ANALYTIC_ADD_WEB_TRAFFIC';

const createCrawWebTrafficAsync = createAsyncAction<
  IAnalyticWebCrawTrafficMeta,
  GenericResponse
>(addLinkTraffic);

export default createCrawWebTrafficAsync;
