import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getDashboardStories } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GetDashboardStoriesMeta, GetDashboardStoriesPayload } from 'types/store/GetDashboardStories';
import { Store } from 'types/store/Store';

import { getDashboardStoriesActions } from '../actions';

function* getDashboardStoriesRequest(action: AsyncAction<GetDashboardStoriesMeta, GetDashboardStoriesPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getDashboardStories, action.meta, state.auth.token);
      yield put(getDashboardStoriesActions.success(data));
    } catch (e: any) {
      yield put(getDashboardStoriesActions.failed(e.message));
    }
  }
}

function* getDashboardStoriesSuccess(action: AsyncAction<GetDashboardStoriesMeta, GetDashboardStoriesPayload>) {
  // yield put()
}

function* getDashboardStoriesWatcher(): any {
  yield takeLatest(getDashboardStoriesActions.REQUEST, getDashboardStoriesRequest);
  yield takeLatest(getDashboardStoriesActions.SUCCESS, getDashboardStoriesSuccess);
}

export default getDashboardStoriesWatcher;
