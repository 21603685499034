import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getUserById } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { AuthState } from 'types/store/AuthState';
import { GetUserByIdMeta, GetUserByIdPayload } from 'types/store/GetUserById';
import { Store } from 'types/store/Store';

import { getUserByIdActions, authActions } from '../actions';

function* getUserByIdRequest(
  action: AsyncAction<GetUserByIdMeta, GetUserByIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getUserById, action.meta, state.auth.token);
      yield put(getUserByIdActions.success(data));
    } catch (e: any) {
      yield put(getUserByIdActions.failed(e.message));
    }
  } else {
    yield put(getUserByIdActions.failed('Token not found'));
  }
}

function* getUserByIdSuccess(
  action: AsyncAction<GetUserByIdMeta, GetUserByIdPayload>
) {
  const auth: AuthState = yield select((state: Store) => state.auth);
  yield put(authActions.update({ ...auth, email: action.payload.data.profile.email }));
}

function* getUserByIdWatcher(): any {
  yield takeLatest(getUserByIdActions.REQUEST, getUserByIdRequest);
  yield takeLatest(getUserByIdActions.SUCCESS, getUserByIdSuccess);
}

export default getUserByIdWatcher;
