import React from 'react';

import { SVGProps } from './SVG.props';

const LinkCopy = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666992 7.99984C0.666992 9.84079 2.15938 11.3332 4.00033 11.3332H6.00033C6.36851 11.3332 6.66699 11.0347 6.66699 10.6665C6.66699 10.2983 6.36851 9.99984 6.00033 9.99984H4.00033C2.89576 9.99984 2.00033 9.10441 2.00033 7.99984C2.00033 6.89527 2.89576 5.99984 4.00033 5.99984H6.00033C6.36851 5.99984 6.66699 5.70136 6.66699 5.33317C6.66699 4.96498 6.36851 4.6665 6.00033 4.6665H4.00033C2.15938 4.6665 0.666992 6.15889 0.666992 7.99984Z"
        fill={fill || '#060548'}
      />
      <path
        d="M9.33366 10.6665C9.33366 11.0347 9.63213 11.3332 10.0003 11.3332H12.0003C13.8413 11.3332 15.3337 9.84079 15.3337 7.99984C15.3337 6.15889 13.8413 4.6665 12.0003 4.6665H10.0003C9.63213 4.6665 9.33366 4.96498 9.33366 5.33317C9.33366 5.70136 9.63213 5.99984 10.0003 5.99984H12.0003C13.1049 5.99984 14.0003 6.89527 14.0003 7.99984C14.0003 9.10441 13.1049 9.99984 12.0003 9.99984H10.0003C9.63213 9.99984 9.33366 10.2983 9.33366 10.6665Z"
        fill={fill || '#060548'}
      />
      <path
        d="M5.33366 8.6665C4.96547 8.6665 4.66699 8.36803 4.66699 7.99984C4.66699 7.63165 4.96547 7.33317 5.33366 7.33317H10.667C11.0352 7.33317 11.3337 7.63165 11.3337 7.99984C11.3337 8.36803 11.0352 8.6665 10.667 8.6665H5.33366Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default LinkCopy;
