import { UpdateSfpSkippedAllMeta, UpdateSfpSkippedAllPayload } from 'types/store/UpdateSfpSkippedAll';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SFP_SKIPPED_ALL_NS';

const asyncAction = createAsyncAction<UpdateSfpSkippedAllMeta, UpdateSfpSkippedAllPayload>(ns);

const updateSfpSkippedAllActions = { ...asyncAction };

export default updateSfpSkippedAllActions;
