import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

interface Tag {
  name: string;
  group?: string;
}

export interface IUpdateTagsMeta {
  libraryId?: string;
  storyId?: string;
  storyVideoId?: string;
  newTags?: Tag[];
  setTags: any[];
}

const updateTagsNS = 'LIBRARY_UPDATE_TAGS';

const updateTags = createAsyncAction<IUpdateTagsMeta, GenericResponse>(
  updateTagsNS,
);

export default updateTags;
