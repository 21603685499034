/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useMemo, useRef, useState, useEffect } from 'react';

import { VIDEO_STATUS } from 'types/store/GetSaveTemplatesByCompanyId';
import {
  SEDownload,
  SESendBack,
  SESendBack2,
  SEUploadFinal,
  Check
} from 'components/base/SVG';
import FloatingAlert from 'components/module/FloatingAlert';
import SELinkCopy from 'components/base/SVG/SELinkCopy';
import VerticalDots from 'components/base/SVG/ThreeDots';
import Portal from 'components/layout/Portal';

import {
  Action,
  ActionItem,
  ActionList,
  ContainerAction,
  DownloadAction,
  SendBackAction,
  TextActionItem,
  UploadFinalAction,
} from '../../VideoPreview.style';
import { DownloadVideoProps } from '../DownloadVideo.props';
import { FloatingAlertContainer, DisabledMenuWrapper } from '../DownloadVideo.style';
import Transcribe from '../Transcribe';

const ActionIcon = (props: DownloadVideoProps): JSX.Element => {
  const {
    status,
    onUploadFinal = () => { },
    onDownload = () => { },
    onSendBack = () => { },
    onTranscribe = () => { },
    handleDownloadVideo = () => { },
    url,
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [copyUrl, setCopyUrl] = useState(false);
  const actionRef = useRef<any>();

  useEffect(() => {
    if (copyUrl) {
      setTimeout(() => {
        setCopyUrl(false);
      }, 6000);
    }
  }, [copyUrl]);

  const showPopupAction = useCallback(() => {
    setShowPopup(false);
  }, []);

  const copyToClipboard = (copyLink: string) => {
    try {
      navigator.clipboard.writeText(copyLink || '');
      setCopyUrl(true);
      // setClosePopover(true);
    } catch (error: any) {
      alert('Copied failed ! - ' + 'error: ' + error);
    }
  };

  const getPopupPosition = useMemo(() => {
    const getChildrenPosition = actionRef.current?.getBoundingClientRect();
    const top = getChildrenPosition?.y ? getChildrenPosition?.y : 0;
    const left = getChildrenPosition?.x ? getChildrenPosition?.x - 60 : 0;
    return {
      top: top,
      left: left,
    };
  }, [showPopup]);

  return (
    <>
      {props.showActionIcons && (
        <>
          <ActionList
            onClick={(e) => e.stopPropagation()}
            className="action_btn"
          >
            <SendBackAction
              title={'More'}
              ref={actionRef}
              onClick={(e) => {
                e.stopPropagation();
                setShowPopup(true);
              }}
            >
              <VerticalDots />
            </SendBackAction>
            <Portal visible={showPopup} onClick={() => setShowPopup(false)}>
              <ContainerAction
                top={getPopupPosition.top}
                left={getPopupPosition.left}
              >
                {
                  status?.toUpperCase() !== VIDEO_STATUS.SENT_BACK && (
                    <ActionItem>
                      <div
                        title='Upload Video'
                        onClick={() =>
                          onUploadFinal()
                        }
                      >
                        <Action>
                          <SEUploadFinal />
                          <TextActionItem>Upload Video</TextActionItem>
                        </Action>
                      </div>
                    </ActionItem>
                  )}
                <ActionItem>
                  <div
                    title='Download Video'
                    onClick={() =>
                      handleDownloadVideo()
                    }>
                    <Action>
                      <SEDownload />
                      <TextActionItem>Download Video</TextActionItem>
                    </Action>
                  </div>
                </ActionItem>
                <ActionItem
                  onClick={() => {
                    setShowPopup(false);
                  }}
                >
                  <div
                    title='Copy Link'
                    onClick={() =>
                      copyToClipboard(url)
                    }
                  >
                    <Action>
                      <SELinkCopy />
                      <TextActionItem>Copy Link</TextActionItem>
                    </Action>
                  </div>
                </ActionItem>
                <ActionItem
                  onClick={() => {
                    setShowPopup(false);
                  }}
                >
                  <div
                    title={
                      status?.toUpperCase() !== VIDEO_STATUS.SENT_BACK
                        ? 'Send Back'
                        : 'Sent Back'
                    }
                    onClick={() =>
                      status?.toUpperCase() !== VIDEO_STATUS.SENT_BACK &&
                      onSendBack()
                    }
                  >
                    <Action>
                      {status?.toUpperCase() !== VIDEO_STATUS.SENT_BACK ? (
                        <>
                          <SESendBack />
                          <TextActionItem>Send Back</TextActionItem>
                        </>
                      ) : (
                        <DisabledMenuWrapper>
                          <SESendBack2 />
                          <TextActionItem>Sent Back</TextActionItem>
                        </DisabledMenuWrapper>
                      )}
                    </Action>
                  </div>
                </ActionItem>
                <Transcribe
                  onTranscribe={onTranscribe}
                  showPopupAction={showPopupAction}
                  transcribe={props.transcribe}
                  noneTranscribe={props.noneTranscribe}
                  wasTranscribed={props.wasTranscribed}
                />
              </ContainerAction>
            </Portal>
          </ActionList>
          <FloatingAlertContainer open={copyUrl}>
            <FloatingAlert
              text='Link copied to clipboard'
              icon={<Check width={10} height={7} fill='#fff' />}
            />
          </FloatingAlertContainer>
        </>
      )
      }
    </>
  );
};

export default React.memo(ActionIcon);
