import React from 'react';

import { SVGProps } from './SVG.props';

const Circle = (props: SVGProps): JSX.Element => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="20" height="20" rx="4" fill="#F7F3F4"/>
      <path d="M10 5C9.0111 5 8.0444 5.29324 7.22215 5.84265C6.39991 6.39206 5.75904 7.17295 5.3806 8.08658C5.00217 9.00021 4.90315 10.0055 5.09608 10.9755C5.289 11.9454 5.76521 12.8363 6.46447 13.5355C7.16373 14.2348 8.05465 14.711 9.02455 14.9039C9.99446 15.0969 10.9998 14.9978 11.9134 14.6194C12.8271 14.241 13.6079 13.6001 14.1573 12.7779C14.7068 11.9556 15 10.9889 15 10C15 9.34339 14.8707 8.69321 14.6194 8.08658C14.3681 7.47995 13.9998 6.92876 13.5355 6.46447C13.0712 6.00017 12.52 5.63188 11.9134 5.3806C11.3068 5.12933 10.6566 5 10 5ZM10 14C9.20888 14 8.43552 13.7654 7.77772 13.3259C7.11992 12.8864 6.60723 12.2616 6.30448 11.5307C6.00173 10.7998 5.92252 9.99556 6.07686 9.21964C6.2312 8.44372 6.61216 7.73098 7.17158 7.17157C7.73099 6.61216 8.44372 6.2312 9.21964 6.07686C9.99557 5.92252 10.7998 6.00173 11.5307 6.30448C12.2616 6.60723 12.8864 7.11992 13.3259 7.77772C13.7654 8.43552 14 9.20888 14 10C14 11.0609 13.5786 12.0783 12.8284 12.8284C12.0783 13.5786 11.0609 14 10 14Z" fill="#B5ADB0"/>
    </svg>
  );
};

export default Circle;
