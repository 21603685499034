import { SendUpgradeMailMeta, SendUpgradeMailPayload } from 'types/store/SendUpgradeMail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SEND_UPGRADE_MAIL_NS';

const asyncAction = createAsyncAction<SendUpgradeMailMeta, SendUpgradeMailPayload>(ns);

const sendUpgradeMailActions = { ...asyncAction };

export default sendUpgradeMailActions;
