import {
  ExtractTextFromFileMeta,
  ExtractTextFromFilePayload,
} from 'types/store/ExtractTextFromFileState';
import { createAsyncReducer } from 'utils/Redux';

import { extractTextFromFileActions } from '../actions';

export default createAsyncReducer<
  ExtractTextFromFileMeta,
  ExtractTextFromFilePayload
>(extractTextFromFileActions);
