import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ConvertedToObjectType } from 'types/Locale';

import cs from './cs/translation.json';
import de from './de/translation.json';
import en from './en/translation.json';
import es from './es/translation.json';
import frCA from './fr-CA/translation.json';
import fr from './fr/translation.json';
import hi from './hi/translation.json';
import id from './id/translation.json';
import it from './it/translation.json';
import ja from './ja/translation.json';
import nl from './nl/translation.json';
import pl from './pl/translation.json';
import pt from './pt/translation.json';
import ru from './ru/translation.json';
import sv from './sv/translation.json';
import zhTW from './zh-TW/translation.json';
import zh from './zh/translation.json';

// Add json translations here
const translationsJson = {
  en: {
    translation: en,
  },
  cs: {
    translation: cs,
  },
  de: {
    translation: de,
  },
  es: {
    translation: es,
  },
  fr: {
    translation: fr,
  },
  frCA: {
    translation: frCA,
  },
  hi: {
    translation: hi,
  },
  id: {
    translation: id,
  },
  it: {
    translation: it,
  },
  ja: {
    translation: ja,
  },
  nl: {
    translation: nl,
  },
  pl: {
    translation: pl,
  },
  pt: {
    translation: pt,
  },
  ru: {
    translation: ru,
  },
  sv: {
    translation: sv,
  },
  zh: {
    translation: zh,
  },
  zhTW: {
    translation: zhTW,
  },
};

type LanguageCodesType = {
  [key: string]: string;
};

export const languageCodes: LanguageCodesType = {
  English: 'en',
  Spanish: 'es',
  Polish: 'pl',
  Portuguese: 'pt',
  Mandarin: 'zh',
  French: 'fr',
  German: 'de',
  Hindi: 'hi',
  Russian: 'ru',
  Japanese: 'ja',
  Czech: 'cs',
  'French (Canada)': 'fr-CA',
  Dutch: 'nl',
  Indonesian: 'id',
  Swedish: 'sv',
  Italian: 'it',
  'Chinese (Traditional)': 'zh-TW',
};

export type TranslationResource = typeof en;
export type LanguageKey = keyof TranslationResource;

export const translations: ConvertedToObjectType<TranslationResource> =
  {} as any;

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still have the intellisense support
 * along with type-safety
 */
const convertLanguageJsonToObject = (obj: any, dict: any, current?: string) => {
  Object.keys(obj).forEach((key) => {
    const currentLookupKey = current ? `${current}.${key}` : key;
    if (typeof obj[key] === 'object') {
      dict[key] = {};
      convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey);
    } else {
      dict[key] = currentLookupKey;
    }
  });
};

export const i18n = (language: string): any =>
  i18next
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init(
      {
        resources: translationsJson,
        fallbackLng: language,
        debug: process.env.NODE_ENV !== 'production',
        react: {
          useSuspense: true,
        },
      },
      () => {
        convertLanguageJsonToObject(en, translations);
      }
    );
