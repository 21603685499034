import { DeletePageMeta, DeletePagePayload } from 'types/store/DeletePageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_PAGE';

const asyncAction = createAsyncAction<DeletePageMeta, DeletePagePayload>(ns);

const deletePageActions = {
  ...asyncAction,
};

export default deletePageActions;
