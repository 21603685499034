import { GetInviteLinkPayload, GetInviteLinkMeta } from 'types/store/GetLinkInvite';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LINK_INVITE_DATA_NS';

const asyncAction = createAsyncAction<GetInviteLinkMeta, GetInviteLinkPayload>(ns);

const getLinkInviteActions = { ...asyncAction };

export default getLinkInviteActions;
