import {
  GetStoryByIdMeta,
  GetStoryByIdPayload,
} from 'types/store/GetStoryByIdState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_BY_ID';
const asyncAction = createAsyncAction<GetStoryByIdMeta, GetStoryByIdPayload>(
  ns
);

const getStoryByIdActions = {
  ...asyncAction,
};

export default getStoryByIdActions;
