import {
  UseCreditsMeta,
  UseCreditsPayload
  } from 'types/store/UseCredits';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'USE_CREDITS';
  
  const asyncAction = createAsyncAction<UseCreditsMeta, UseCreditsPayload>(ns);
  
  const useCreditsActions = {
    ...asyncAction,
  };
  
  export default useCreditsActions;
  