import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAdvocateByStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GetAdvocateByStoryMeta, GetAdvocateByStoryPayload } from 'types/store/GetAdvocateByStory';
import { Store } from 'types/store/Store';

import { getAdvocateByStoryActions } from '../actions';

function* getAdvocateByStoryRequest(action: AsyncAction<GetAdvocateByStoryMeta, GetAdvocateByStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getAdvocateByStory, action.meta, state.auth.token);
      yield put(getAdvocateByStoryActions.success(data));
    } catch (e: any) {
      yield put(getAdvocateByStoryActions.failed(e.message));
    }
  }
}

// function* getAdvocateByStorySuccess(action: AsyncAction<GetAdvocateByStoryMeta, GetAdvocateByStoryPayload>): any {
//   yield put()
// }

function* getAdvocateByStoryWatcher(): any {
  yield takeLatest(getAdvocateByStoryActions.REQUEST, getAdvocateByStoryRequest);
  // yield takeLatest(getAdvocateByStoryActions.SUCCESS, getAdvocateByStorySuccess);
}

export default getAdvocateByStoryWatcher;
