import { PageNameState } from 'types/store/PageNameState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'PAGE_NAME';
const updateAction = createUpdateAction<PageNameState>(ns);
const clearAction = createClearAction(ns);

const pageNameActions = {
  ...updateAction,
  ...clearAction,
};

export default pageNameActions;
