import { put, call, takeLatest, select } from 'redux-saga/effects';
import { discussionStoryDetails } from 'services/story';
import { AsyncAction } from 'types/Action';
import { DiscussionStoryDetailsMeta, DiscussionStoryDetailsPayload } from 'types/store/DiscussionStoryDetails';
import { Store } from 'types/store/Store';

import { discussionStoryDetailsActions } from '../actions';

function* discussionStoryDetailsRequest(action: AsyncAction<DiscussionStoryDetailsMeta, DiscussionStoryDetailsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(discussionStoryDetails, action.meta, state.auth.token);
      yield put(discussionStoryDetailsActions.success(data));
    } catch (e: any) {
      yield put(discussionStoryDetailsActions.failed(e.message));
    }
  }
}

// function* discussionStoryDetailsSuccess(action: AsyncAction<DiscussionStoryDetailsMeta, DiscussionStoryDetailsPayload>): any {
//   yield put()
// }

function* discussionStoryDetailsWatcher(): any {
  yield takeLatest(discussionStoryDetailsActions.REQUEST, discussionStoryDetailsRequest);
  // yield takeLatest(discussionStoryDetailsActions.SUCCESS, discussionStoryDetailsSuccess);
}

export default discussionStoryDetailsWatcher;
