import { SwitchPlatformsMeta, SwitchPlatformsPayload } from 'types/store/SwitchPlatforms';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SWITCH_PLATFORMS_NS';

const asyncAction = createAsyncAction<SwitchPlatformsMeta, SwitchPlatformsPayload>(ns);

const switchPlatformsActions = { ...asyncAction };

export default switchPlatformsActions;
