
import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_WIDGET';

const asyncAction = createAsyncAction<GenericResponse>(ns);

const createWidgetAction = {
  ...asyncAction
};

export default createWidgetAction;
