import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export type SetStoryCostMeta = {
  storyId: string;
  cost: string;
  constType: string;
  currencyName: string;
  currencyCode: string;
  comment?: string;
};
const ns = 'SET_STORY_COST';

const asyncAction = createAsyncAction<SetStoryCostMeta, GenericResponse>(ns);

const setStoryCostActions = {
  ...asyncAction,
};

export default setStoryCostActions;
