import React from 'react';

import { SVGProps } from './SVG.props';

const HistoryClock = (props: SVGProps): JSX.Element => {
  const { width = 16, height = 16, fill = '#060548' } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4421_10876)">
        <path
          d="M8 0C8.36819 0 8.66667 0.298477 8.66667 0.666667C8.66667 1.03486 8.36819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667C9.68136 14.6667 11.2159 14.0451 12.3889 13.0183C12.6659 12.7758 13.0871 12.8038 13.3296 13.0808C13.5722 13.3579 13.5442 13.779 13.2671 14.0215C11.8603 15.253 10.0166 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0Z"
          fill={fill}
        />
        <path
          d="M8.66665 3.33333C8.66665 2.96514 8.36817 2.66667 7.99998 2.66667C7.63179 2.66667 7.33331 2.96514 7.33331 3.33333V8C7.33331 8.17681 7.40355 8.34638 7.52858 8.47141L9.86191 10.8047C10.1223 11.0651 10.5444 11.0651 10.8047 10.8047C11.0651 10.5444 11.0651 10.1223 10.8047 9.86193L8.66665 7.72386V3.33333Z"
          fill={fill}
        />
        <path
          d="M14.6901 11.0894C14.3713 10.9053 13.9635 11.0146 13.7794 11.3335C13.5954 11.6523 13.7046 12.06 14.0235 12.2441C14.3423 12.4282 14.75 12.319 14.9341 12.0001C15.1182 11.6813 15.009 11.2735 14.6901 11.0894Z"
          fill={fill}
        />
        <path
          d="M14.5711 9.1575C14.6351 8.79491 14.9809 8.5528 15.3434 8.61673C15.706 8.68067 15.9482 9.02644 15.8842 9.38903C15.8203 9.75163 15.4745 9.99374 15.1119 9.92981C14.7493 9.86587 14.5072 9.5201 14.5711 9.1575Z"
          fill={fill}
        />
        <path
          d="M15.1118 6.06994C14.7492 6.13388 14.5071 6.47965 14.5711 6.84225C14.635 7.20484 14.9808 7.44695 15.3434 7.38302C15.706 7.31908 15.9481 6.97331 15.8841 6.61071C15.8202 6.24812 15.4744 6.00601 15.1118 6.06994Z"
          fill={fill}
        />
        <path
          d="M13.7791 4.66679C13.595 4.34793 13.7043 3.9402 14.0231 3.75611C14.342 3.57201 14.7497 3.68126 14.9338 4.00012C15.1179 4.31899 15.0087 4.72671 14.6898 4.91081C14.3709 5.0949 13.9632 4.98565 13.7791 4.66679Z"
          fill={fill}
        />
        <path
          d="M12.2085 1.95378C11.9718 2.23583 12.0086 2.65633 12.2907 2.893C12.5727 3.12967 12.9932 3.09288 13.2299 2.81083C13.4666 2.52878 13.4298 2.10827 13.1477 1.87161C12.8657 1.63494 12.4452 1.67173 12.2085 1.95378Z"
          fill={fill}
        />
        <path
          d="M10.2859 1.73552C9.93992 1.60959 9.76153 1.22703 9.88745 0.881047C10.0134 0.535062 10.3959 0.35667 10.7419 0.482599C11.0879 0.608527 11.2663 0.991089 11.1404 1.33707C11.0144 1.68306 10.6319 1.86145 10.2859 1.73552Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_4421_10876">
          <rect width={width} height={height} fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HistoryClock;
