import React from 'react';

import { SVGProps } from './SVG.props';

const PinMap = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '9'}
      height={height || '10'}
      viewBox="0 0 9 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.6906 3.70276C7.6906 5.93015 4.33505 9.56534 4.33505 9.56534C4.33505 9.56534 0.979492 5.65052 0.979492 3.70276C0.979492 1.75499 2.48183 0.617188 4.33505 0.617188C6.18827 0.617188 7.6906 1.99864 7.6906 3.70276Z"
        fill={fill || '#939194'}
      />
      <circle cx="4.33532" cy="3.97399" r="1.11852" fill="#F8F8F8" />
    </svg>
  );
};

export default PinMap;
