import {
  UpdateRevisionMeta,
  UpdateRevisionPayload,
} from 'types/store/UpdateRevisionState';
import { createAsyncReducer } from 'utils/Redux';

import { updateRevisionActions } from '../actions';

export default createAsyncReducer<
  UpdateRevisionMeta,
  UpdateRevisionPayload
>(updateRevisionActions);
