import { put, call, takeLatest, select } from 'redux-saga/effects';
import { changePassword } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  ChangePasswordMeta,
  ChangePasswordPayload,
} from 'types/store/ChangePasswordState';
import { Store } from 'types/store/Store';

import {
  changePasswordActions,
} from '../actions';

function* changePasswordRequest(
  action: AsyncAction<ChangePasswordMeta, ChangePasswordPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(changePassword, action.meta, state.auth.token);
      yield put(changePasswordActions.success(data));
    } catch (e: any) {
      yield put(changePasswordActions.failed(e.message));
    }
  }
}

function* changePasswordSuccess(
  action: AsyncAction<ChangePasswordMeta, ChangePasswordPayload>
) {
  // yield put(changePasswordActions.clear());
}

function* changePasswordWatcher(): any {
  yield takeLatest(changePasswordActions.REQUEST, changePasswordRequest);
  yield takeLatest(changePasswordActions.SUCCESS, changePasswordSuccess);
}

export default changePasswordWatcher;
