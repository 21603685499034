import React from 'react';

import { SVGProps } from './SVG.props';

const CheckFilled = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg width={width || 16} height={height || 16} viewBox='0 0 16 16'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.29588 2.45654C5.3922 1.724 6.68114 1.33301 7.99968 1.33301C8.87516 1.33301 9.74206 1.50545 10.5509 1.84048C11.3597 2.17551 12.0947 2.66657 12.7137 3.28563C13.3328 3.90469 13.8238 4.63961 14.1589 5.44845C14.4939 6.25729 14.6663 7.1242 14.6663 7.99968C14.6663 9.31822 14.2754 10.6071 13.5428 11.7035C12.8103 12.7998 11.7691 13.6543 10.5509 14.1589C9.33273 14.6635 7.99228 14.7955 6.69908 14.5382C5.40587 14.281 4.21798 13.6461 3.28563 12.7137C2.35328 11.7814 1.71834 10.5935 1.46111 9.30028C1.20387 8.00707 1.3359 6.66663 1.84048 5.44845C2.34506 4.23028 3.19955 3.18909 4.29588 2.45654ZM9.81272 5.85968L6.95272 8.72635L5.85272 7.62635C5.79295 7.55656 5.71941 7.49988 5.6367 7.45986C5.55399 7.41985 5.4639 7.39736 5.37209 7.39381C5.28027 7.39027 5.18872 7.40574 5.10317 7.43926C5.01762 7.47277 4.93992 7.52361 4.87495 7.58858C4.80998 7.65355 4.75914 7.73125 4.72563 7.8168C4.69211 7.90235 4.67664 7.9939 4.68018 8.08572C4.68373 8.17753 4.70622 8.26762 4.74623 8.35033C4.78625 8.43304 4.84293 8.50658 4.91272 8.56635L6.47938 10.1397C6.54168 10.2015 6.61555 10.2503 6.69678 10.2835C6.778 10.3167 6.86498 10.3335 6.95272 10.333C7.12761 10.3323 7.29521 10.2628 7.41938 10.1397L10.7527 6.80635C10.8152 6.74437 10.8648 6.67064 10.8986 6.5894C10.9325 6.50816 10.9499 6.42102 10.9499 6.33301C10.9499 6.245 10.9325 6.15787 10.8986 6.07663C10.8648 5.99539 10.8152 5.92165 10.7527 5.85968C10.6278 5.73551 10.4588 5.66582 10.2827 5.66582C10.1066 5.66582 9.93763 5.73551 9.81272 5.85968Z'
        fill={fill || '#00C48C'}
      />
    </svg>
  );
};

export default CheckFilled;
