import React from 'react';

import { SVGProps } from './SVG.props';

const UploadImage = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const { width, height, fill } = props;
  return (
    <svg width={width || 15} height={height || 15} viewBox="0 0 15 15" fill="none" {...props}>
      <path
        d="M11.667 8.667a.667.667 0 00-.667.666v.254l-.987-.987a1.86 1.86 0 00-2.62 0l-.466.467-1.654-1.654a1.9 1.9 0 00-2.62 0l-.986.987V4.667A.667.667 0 012.333 4H7a.667.667 0 000-1.333H2.333a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V9.333a.667.667 0 00-.666-.666zm-9.334 4.666a.667.667 0 01-.666-.666v-2.38L3.6 8.353a.527.527 0 01.727 0l2.113 2.114 2.867 2.866H2.333zM11 12.667a.593.593 0 01-.12.353L7.873 10l.467-.467a.513.513 0 01.733 0L11 11.473v1.194zm3.14-9.807l-2-2a.666.666 0 00-.22-.14.666.666 0 00-.507 0 .666.666 0 00-.22.14l-2 2a.67.67 0 00.947.947L11 2.94v3.727a.667.667 0 001.333 0V2.94l.86.867a.666.666 0 001.093-.217.666.666 0 00-.146-.73z"
        fill={fill || "#070550"}
      />
    </svg>
  );
};

export default UploadImage;
