import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import { CommonState } from 'types/store/Common';

import { commonActions } from '../actions';

const DEFAULT_STATE: CommonState = {
  mobileMenuToggle: false,
};

const DEFAULT_ACTION: Action<CommonState> = {
  type: '',
  payload: {
    mobileMenuToggle: false,
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  return pathOr(state, [action.type], {
    [commonActions.UPDATE]: {
      ...state,
      ...action.payload,
    },
  });
};
