import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { GetAllItemsMeta } from 'types/store/GetAllItems';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const ITEM_URL = `${BASE_URL}/item`;

export const getAllItems = (data: GetAllItemsMeta, token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ITEM_URL}/get-all-items`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};