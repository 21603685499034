import { StylesConfig } from 'react-select';
import styled from 'utils/styled';

export const Container = styled.div<{
  margin?: string;
}>`
  margin: ${({ margin }) => margin || '16px 0 0'};
  flex: 1;
`;

export const LabelContainer = styled.div<{ marginLabel: string }>`
  margin-bottom: ${({ marginLabel }) => marginLabel}; ;
`;

export const MenuContainer = styled.div`
  select {
    width: 100%;
    height: 48px;
  }

  option {
    background-color: red;
  }
`;

export const TipIcon = styled.div`
  position: absolute;
  right: -20px;
  top: 50%;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    position: relative;
    right: unset;
    top: unset;
    margin-left: 4px;
    margin-top: 4px;
  }
`;

export const SelectWrapper = styled.div<{
  isFullWidth?: boolean;
}>`
  display: flex;

  & > div:first-of-type {
    flex: 1;
    ${({ isFullWidth }) =>
      isFullWidth
        ? `
      width: 100%;
    `
        : ``}
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: row;
    align-items: center;
  }
`;

export const CreateLabel = styled.div<{
  active?: boolean;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding-top: 8px;
  padding-bottom: 8px;

  p {
    line-height: 1;
    margin-left: 10px;
    padding-top: 2px;

    ${({ theme, active }) =>
      active
        ? `
    color: ${theme.brand.secondary};
  `
        : null}
  }
`;
