import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getOnboard } from 'services/story';
import { AsyncAction } from 'types/Action';
import { GetOnboardMeta, GetOnboardPayload } from 'types/store/GetOnboardState';
import { Store } from 'types/store/Store';

import { getOnboardActions } from '../actions';

function* getOnboardRequest(action: AsyncAction<GetOnboardMeta, GetOnboardPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getOnboard, action.meta, state.auth.token);
      yield put(getOnboardActions.success(data));
    } catch (e: any) {
      yield put(getOnboardActions.failed(e.message));
    }
  }
}

function* getOnboardSuccess(action: AsyncAction<GetOnboardMeta, GetOnboardPayload>) {
  // yield put()
}

function* getOnboardWatcher(): any {
  yield takeLatest(getOnboardActions.REQUEST, getOnboardRequest);
  yield takeLatest(getOnboardActions.SUCCESS, getOnboardSuccess);
}

export default getOnboardWatcher;
