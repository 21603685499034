import React from 'react';

import { Action } from 'types/Action';

export enum CreativeAction {
  BK_REMOVE_FONT = '@@_REMOVE_FONT',
  BK_REMOVE_COLOR = '@@_REMOVE_COLOR',
  BK_REMOVE_LOGO = '@@_REMOVE_LOGO',
  BK_ADD_FONT = '@@_ADD_FONT',
  BK_ADD_COLOR = '@@_ADD_COLOR',
  BK_ADD_LOGO = '@@_ADD_LOGO',
  BROWS_TOGGLE = '@@_BROWS_TOGGLE',
  TL_UPDATE_TEMPLATE = '@@_TL_UPDATE_TEMPLATE',
  TL_TOGGLE_MODAL_TEMPLATE = '@@_TL_TOGGLE_MODAL_TEMPLATE',
}

export const removeFontAction = (id: string): Action => ({
  type: CreativeAction.BK_REMOVE_FONT,
  payload: { id },
});

export const removeColorAction = (id: string): Action => ({
  type: CreativeAction.BK_REMOVE_COLOR,
  payload: { id },
});

export const removeLogoAction = (id: string): Action => ({
  type: CreativeAction.BK_REMOVE_LOGO,
  payload: { id },
});

export const addFontAction = (color: string): Action => ({
  type: CreativeAction.BK_REMOVE_FONT,
  payload: { color },
});

export const addColorAction = (color: string): Action => ({
  type: CreativeAction.BK_ADD_COLOR,
  payload: { color },
});

export const addLogoAction = (id: string): Action => ({
  type: CreativeAction.BK_REMOVE_LOGO,
  payload: { id },
});

export const onToggleBrowserVideo = (): Action => ({
  type: CreativeAction.BROWS_TOGGLE,
  payload: {},
});

export const updateTemplateId = (id: string): Action => ({
  type: CreativeAction.TL_UPDATE_TEMPLATE,
  payload: { id },
});

export const onToggleModalTemplate = (): Action => ({
  type: CreativeAction.TL_TOGGLE_MODAL_TEMPLATE,
  payload: {},
});
