import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateContentMarketplace } from 'services/marketplace';
import { uploadCompanyLogo } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateContentMarketplaceMeta, UpdateContentMarketplacePayload } from 'types/store/UpdateContentMarketplace';

import { updateContentMarketplaceActions } from '../actions';

function* updateContentMarketplaceRequest(action: AsyncAction<UpdateContentMarketplaceMeta, UpdateContentMarketplacePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { marketplace_attachments: ma, video_form } = action.meta?.marketplace;
      for (let i = 0; i < ma.length; i++) {
        if (ma[i].url instanceof File) {
          const { data } = yield call(
            uploadCompanyLogo,
            ma[i].url,
            state.auth.token
          )

          ma[i].url = data.data?.image?.Location || '';
        }
      }

      if (video_form?.logo_url instanceof File) {
        const { data } = yield call(
          uploadCompanyLogo,
          video_form?.logo_url,
          state.auth.token
        )
        action.meta.marketplace.video_form.logo_url = data.data?.image?.Location || '';
      }
      const { data } = yield call(updateContentMarketplace, action.meta, state.auth.token);
      yield put(updateContentMarketplaceActions.success(data));
    } catch (e: any) {
      yield put(updateContentMarketplaceActions.failed(e.message));
    }
  }
}

// function* updateContentMarketplaceSuccess(action: AsyncAction<UpdateContentMarketplaceMeta, UpdateContentMarketplacePayload>): any {
//   yield put()
// }

function* updateContentMarketplaceWatcher(): any {
  yield takeLatest(updateContentMarketplaceActions.REQUEST, updateContentMarketplaceRequest);
  // yield takeLatest(updateContentMarketplaceActions.SUCCESS, updateContentMarketplaceSuccess);
}

export default updateContentMarketplaceWatcher;
