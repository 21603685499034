import {
    GetFinalStoryPiecesMeta,
    GetFinalStoryPiecesPayload,
  } from 'types/store/GetFinalStoryPieces';
  import { createAsyncAction, createClearAction } from 'utils/Redux';
  
  const ns = 'GET_FINAL_STORY_PIECES';
  
  const asyncAction = createAsyncAction<
    GetFinalStoryPiecesMeta,
    GetFinalStoryPiecesPayload
  >(ns);
  const clearAction = createClearAction(ns);
  
  const getFinalStoryPiecesActions = {
    ...asyncAction,
    ...clearAction,
  };
  
  export default getFinalStoryPiecesActions;
  