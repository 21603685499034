import React from 'react'

import { SVGProps } from './SVG.props';

const Translate = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 16}
      height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_25583_16766)">
        <path
          d="M5.11953 6.92898L4.75703 8.00065H3.83203L5.3837 3.83398H6.4537L7.9987 8.00065H7.0262L6.6637 6.92898H5.11953ZM6.4812 6.31565L5.91536 4.63065H5.87453L5.3087 6.31565H6.48203H6.4812Z"
          fill={fill || "#070550"}
        />
        <path
          d="M1.33203 3.00065C1.33203 2.55862 1.50763 2.1347 1.82019 1.82214C2.13275 1.50958 2.55667 1.33398 2.9987 1.33398H8.83203C9.27406 1.33398 9.69798 1.50958 10.0105 1.82214C10.3231 2.1347 10.4987 2.55862 10.4987 3.00065V5.50065H12.9987C13.4407 5.50065 13.8646 5.67625 14.1772 5.98881C14.4898 6.30137 14.6654 6.72529 14.6654 7.16732V13.0007C14.6654 13.4427 14.4898 13.8666 14.1772 14.1792C13.8646 14.4917 13.4407 14.6673 12.9987 14.6673H7.16536C6.72334 14.6673 6.29941 14.4917 5.98685 14.1792C5.67429 13.8666 5.4987 13.4427 5.4987 13.0007V10.5007H2.9987C2.55667 10.5007 2.13275 10.3251 1.82019 10.0125C1.50763 9.69993 1.33203 9.27601 1.33203 8.83398V3.00065ZM2.9987 2.16732C2.77768 2.16732 2.56572 2.25512 2.40944 2.4114C2.25316 2.56768 2.16536 2.77964 2.16536 3.00065V8.83398C2.16536 9.055 2.25316 9.26696 2.40944 9.42324C2.56572 9.57952 2.77768 9.66732 2.9987 9.66732H8.83203C9.05305 9.66732 9.26501 9.57952 9.42129 9.42324C9.57757 9.26696 9.66537 9.055 9.66537 8.83398V3.00065C9.66537 2.77964 9.57757 2.56768 9.42129 2.4114C9.26501 2.25512 9.05305 2.16732 8.83203 2.16732H2.9987ZM8.94703 10.4965C9.10786 10.7473 9.28203 10.9823 9.47203 11.2015C8.8487 11.6807 8.07787 12.0357 7.16536 12.2782C7.3137 12.459 7.5412 12.8073 7.62786 13.0007C8.56536 12.7015 9.3612 12.2973 10.0329 11.7557C10.6804 12.3098 11.482 12.7265 12.4745 12.9823C12.5854 12.7707 12.8195 12.4215 12.9987 12.2407C12.0612 12.0298 11.2845 11.6623 10.6487 11.1707C11.2162 10.5482 11.667 9.79482 11.9995 8.87315H12.9987V8.00065H10.4987V8.87315H11.1362C10.8712 9.57648 10.5195 10.1615 10.0762 10.6482C9.95375 10.5177 9.83831 10.3808 9.73036 10.2382C9.49521 10.3889 9.22575 10.4778 8.94703 10.4965Z"
          fill={fill || "#070550"}
        />
      </g>
      <defs>
        <clipPath id="clip0_25583_16766">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Translate;
