import {
    UpdateShowcaseFeedbackPositionMeta,
    UpdateShowcaseFeedbackPositionPayload,
} from 'types/store/UpdateShowcaseFeedbackPositionState';
import { createAsyncReducer } from 'utils/Redux';

import { updateShowcaseFeedbackPositionActions } from '../actions';

export default createAsyncReducer<
    UpdateShowcaseFeedbackPositionMeta,
    UpdateShowcaseFeedbackPositionPayload
>(updateShowcaseFeedbackPositionActions);
