import {
  GetShowcaseFeedbacksMeta,
  GetShowcaseFeedbacksPayload,
} from 'types/store/GetShowcaseFeedbacksState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcaseFeedbacksActions } from '../actions';

export default createAsyncReducer<
  GetShowcaseFeedbacksMeta,
  GetShowcaseFeedbacksPayload
>(getShowcaseFeedbacksActions);
