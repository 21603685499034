import {
  UpdateStoryToPublishMeta,
  UpdateStoryToPublishPayload,
} from 'types/store/UpdateStoryToPublishState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STORY_TO_PUBLISH';

const asyncAction = createAsyncAction<
  UpdateStoryToPublishMeta,
  UpdateStoryToPublishPayload
>(ns);

const updateStoryToPublishActions = {
  ...asyncAction,
};

export default updateStoryToPublishActions;
