import { getStoryCalendarByCompanyMeta, getStoryCalendarByCompanyPayload } from 'types/store/getStoryCalendarByCompanyState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORIES_CALENDAR_COMPANY_ID';
const asyncAction = createAsyncAction<getStoryCalendarByCompanyMeta, getStoryCalendarByCompanyPayload>(ns);

const getStoryCalendarByCompanyActions = {
  ...asyncAction,
};

export default getStoryCalendarByCompanyActions;
