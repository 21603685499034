import {
  CheckInviteMeta,
  CheckInvitePayload,
} from 'types/store/CheckInviteState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHECK_INVITE';

const asyncAction = createAsyncAction<CheckInviteMeta, CheckInvitePayload>(ns);

const checkInviteActions = {
  ...asyncAction,
};

export default checkInviteActions;
