import { Action } from 'types/Action';
import { CompanyState } from 'types/store/Company';

import { getAllAdvocateByCompanyActions } from '../actions';

const DEFAULT_STATE: CompanyState = {
  allAdvocates: [],
  allAdvocatesLoading: null,
};

const DEFAULT_ACTION: Action<CompanyState> = {
  type: '',
  payload: DEFAULT_STATE,
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  switch (action.type) {
    case getAllAdvocateByCompanyActions.REQUEST:
      return {
        ...state,
        allAdvocatesLoading: true,
      };
    case getAllAdvocateByCompanyActions.SUCCESS:
      return {
        ...state,
        allAdvocates: action.payload,
        allAdvocatesLoading: false,
      };
    default:
      return state;
  }
};
