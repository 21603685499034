import {
  GetListRoleMeta,
  GetListRolePayload,
} from 'types/store/GetListRole';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LIST_ROLE_INVITE_TEAM';

const asyncAction = createAsyncAction<
  GetListRoleMeta,
  GetListRolePayload
>(ns);

const getListRoleActions = {
  ...asyncAction,
};

export default getListRoleActions;
