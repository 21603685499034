import {
    UpdateShowcaseFeedbackPositionMeta,
    UpdateShowcaseFeedbackPositionPayload
} from 'types/store/UpdateShowcaseFeedbackPositionState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SHOWCASE_FEEDBACK_POSITION';

const asyncAction = createAsyncAction<
    UpdateShowcaseFeedbackPositionMeta,
    UpdateShowcaseFeedbackPositionPayload
>(ns);

const updateShowcaseFeedbackPositionActions = {
    ...asyncAction,
};

export default updateShowcaseFeedbackPositionActions;
