import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { PayloadSync } from 'store/actions/jobs/generateContent';
import { GenericResponse } from 'types/GenericResponse';
import {
  GetImportFromUrlMeta,
  JobGenerateMeta,
  ExtractFileContentMeta,
} from 'types/store/JobsState';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const JD_URL = `${BASE_URL}/job-description`;

export const importFromUrl = (
  data: GetImportFromUrlMeta,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${JD_URL}/crawl-url?linkUrl=${data.linkUrl}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const generateJobAIContent = (
  data: JobGenerateMeta,
  token: string,
): any => {
  const endpoint = data.jd ? '/generate-jd' : '/generate-ja';

  return axios({
    method: 'post',
    url: `${JD_URL}${endpoint}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const extractFileContent = (
  data: ExtractFileContentMeta,
  token: string,
): any => {
  const fd = new FormData();
  fd.append('file', data.file);
  return axios({
    method: 'post',
    url: `${JD_URL}/extract-file`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const syncAPIToSmartRecruiter = (
  data: PayloadSync,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'PUT',
    url: `${JD_URL}/ats/update-job-ads`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
