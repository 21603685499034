import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getCompanyThreshold } from 'services/company';
import { AsyncAction } from 'types/Action';
import { GetCompanyThresholdMeta, GetCompanyThresholdPayload } from 'types/store/GetCompanyThreshold';
import { Store } from 'types/store/Store';

import { getCompanyThresholdActions } from '../actions';

function* getCompanyThresholdRequest(action: AsyncAction<GetCompanyThresholdMeta, GetCompanyThresholdPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getCompanyThreshold, action.meta, state.auth.token);
      yield put(getCompanyThresholdActions.success(data));
    } catch (e: any) {
      yield put(getCompanyThresholdActions.failed(e.message));
    }
  }
}

function* getCompanyThresholdSuccess(action: AsyncAction<GetCompanyThresholdMeta, GetCompanyThresholdPayload>) {
  // yield put()
}

function* getCompanyThresholdWatcher(): any {
  yield takeLatest(getCompanyThresholdActions.REQUEST, getCompanyThresholdRequest);
  yield takeLatest(getCompanyThresholdActions.SUCCESS, getCompanyThresholdSuccess);
}

export default getCompanyThresholdWatcher;
