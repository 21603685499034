import React, { useEffect, useRef } from 'react';

import Profile from 'components/base/SVG/Profile';
import Typography from 'components/base/Typography';
import SquircleBox from 'components/module/SquircleBox';
import { useField } from 'formik';
import { useTheme } from 'utils/Theme';

import { AddProps } from './Add.props';
import {
  Container,
  ProfileImageContainer,
  DoubleQuoteIcon,
  UploadImageIconContainer,
  UploadImageIcon,
  BlankAvatar,
} from './Add.style';

const Add = (props: AddProps): JSX.Element => {
  const theme = useTheme();

  const {
    name,
    title,
    withTitle = false,
    onClick,
    Svg,
    onClickImage,
    height,
    isProfilePicture,
    fileTypes = '',
    acceptFile = '*',
    width,
    padding,
    margin,
    size = 112,
    text,
    isNew,
    svgSize = 16,
    svgColor,
    disabled = false,
    subText,
    multiple,
  } = props;

  const [field, meta] = name ? useField(name) : [undefined, undefined];

  const enableImagePicker = onClickImage !== undefined;
  const imagePicker = useRef<HTMLInputElement | null>(null);
  const dropRef = useRef<HTMLInputElement | null>(null);

  const validationFileType = (files: File[]) => {
    let imageFiles: Record<string, any> = [];

    if (fileTypes) {
      if (fileTypes === 'csv-txt') {
        // accept ony csv
        imageFiles = files.filter(
          ({ type }) =>
            type === '.csv' ||
            type === 'text/plain' ||
            type === 'application/vnd.ms-excel' ||
            type === 'text/csv',
        );
      } else if (
        fileTypes ===
        '.pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      ) {
        imageFiles = files.filter(
          ({ type }) =>
            type === 'application/pdf' ||
            type === '.doc' ||
            type === '.docx' ||
            type === '.xml' ||
            type === 'application/msword' ||
            type ===
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        );
      } else if (fileTypes === '.docx') {
        imageFiles = files.filter(
          ({ type }) =>
            type ===
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        );
      }
    } else {
      // accept ony jpg and png
      imageFiles = files.filter(
        ({ type }) =>
          type === 'image/jpeg' ||
          type === 'image/png' ||
          type === 'image/svg+xml',
      );
    }

    return imageFiles;
  };

  const handleOnClick = () => {
    if (!disabled) {
      if (enableImagePicker) {
        // handle image
        if (imagePicker && imagePicker.current) {
          imagePicker.current.click();
        }
      } else if (onClick !== undefined) {
        onClick();
      }
    }
  };

  const handleDrag = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOut = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDropHandler = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    if (onClickImage && typeof onClickImage === 'function') {
      const files: File[] = Array.from(e.dataTransfer.files || []);
      const receivedFiled = validationFileType(files);

      if (receivedFiled.length > 0) {
        onClickImage(receivedFiled[0]);
      } else {
        alert('File type is not allowed!');
        e.target.value = '';
      }
    }
  };

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onClickImage && typeof onClickImage === 'function') {
      const files = Array.from(e.target.files || []);
      const receivedFiled: Record<string, any> = validationFileType(files);

      if (receivedFiled.length > 0) {
        onClickImage(receivedFiled[0]);
      } else {
        alert('File type is not allowed!');
        e.target.value = '';
      }
    }
  };

  useEffect(() => {
    const div = dropRef.current;
    if (div !== null) {
      div.addEventListener('dragenter', handleDragIn);
      div.addEventListener('dragleave', handleDragOut);
      div.addEventListener('dragover', handleDrag);
      div.addEventListener('drop', (event) => handleDropHandler(event));
    }

    return () => {
      const div = dropRef.current;
      if (div !== null) {
        div.removeEventListener('dragenter', handleDragIn);
        div.removeEventListener('dragleave', handleDragOut);
        div.removeEventListener('dragover', handleDrag);
        div.removeEventListener('drop', handleDropHandler);
      }
    };
  }, []);

  let imageSelector = (
    <Container
      error={meta?.touched && meta?.error}
      height={height}
      onClick={handleOnClick}
      ref={dropRef}
      width={width}
      padding={padding}
      margin={margin}
    >
      <div className="content">
        <div className="svg-container">
          <Svg
            fill={svgColor || theme.brand.primary}
            width={svgSize}
            height={svgSize}
            style={{ marginBottom: '8px' }}
          />
          {text && (
            <Typography variant="label" color="shade6">
              {text}
            </Typography>
          )}
          {subText && (
            <Typography variant="label" color="shade6">
              {subText}
            </Typography>
          )}
        </div>
        {withTitle && (
          <Typography
            variant="label"
            color="primary"
            style={{
              marginTop: '10px',
              overflow: 'hidden',
              wordBreak: 'break-word',
              display: '-webkit-box',
              WebkitLineClamp: 2,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {title}
          </Typography>
        )}
        {meta?.touched && meta?.error && (
          <Typography
            variant="caption"
            color="error"
            style={{ marginTop: '10px' }}
          >
            {meta?.error}
          </Typography>
        )}
      </div>
    </Container>
  );

  if (isProfilePicture === true) {
    imageSelector = isNew ? (
      <ProfileImageContainer
        size={size - 2}
        onClick={handleOnClick}
        ref={dropRef}
        // isNew
      >
        <Profile />
        <UploadImageIconContainer isNew>
          <UploadImageIcon></UploadImageIcon>
        </UploadImageIconContainer>
      </ProfileImageContainer>
    ) : (
      <ProfileImageContainer
        size={size + 8}
        onClick={handleOnClick}
        ref={dropRef}
      >
        {/* <SquircleBox size={size}>
          <DoubleQuoteIcon></DoubleQuoteIcon>
        </SquircleBox> */}
        <BlankAvatar>
          <DoubleQuoteIcon></DoubleQuoteIcon>
        </BlankAvatar>
        <UploadImageIconContainer>
          <UploadImageIcon></UploadImageIcon>
        </UploadImageIconContainer>
      </ProfileImageContainer>
    );
  }

  return (
    <>
      {enableImagePicker && (
        <input
          ref={imagePicker}
          type="file"
          hidden
          name="test"
          onChange={handleFileChange}
          accept={acceptFile || ''}
          multiple={multiple}
        />
      )}
      {imageSelector}
    </>
  );
};

export default React.memo(Add);
