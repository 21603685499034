import {
  AdvocatePortalStatisticalMeta,
  AdvocatePortalStatisticalPayload,
} from 'types/store/AdvocatePortalStatistical';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_STATISTICAL_NS';

const asyncAction = createAsyncAction<
  AdvocatePortalStatisticalMeta,
  AdvocatePortalStatisticalPayload
>(ns);

const advocatePortalOverviewActions = { ...asyncAction };

export default advocatePortalOverviewActions;
