import React from 'react';

import { SVGProps } from './SVG.props';

const DataSetPlus = (props: SVGProps): JSX.Element => {
  const { width = 16, height = 16, fill = '#167E60' } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66602 3.33341C8.66602 2.96522 8.36754 2.66675 7.99935 2.66675C7.63116 2.66675 7.33268 2.96522 7.33268 3.33341V7.33335L3.33268 7.33335C2.96449 7.33335 2.66602 7.63183 2.66602 8.00002C2.66602 8.36821 2.96449 8.66669 3.33268 8.66668L7.33268 8.66669V12.6667C7.33268 13.0349 7.63116 13.3334 7.99935 13.3334C8.36754 13.3334 8.66602 13.0349 8.66602 12.6667V8.66669L12.666 8.66669C13.0342 8.66669 13.3327 8.36821 13.3327 8.00002C13.3327 7.63183 13.0342 7.33335 12.666 7.33335L8.66602 7.33335V3.33341Z"
        fill={fill}
      />
    </svg>
  );
};

export default DataSetPlus;
