import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import { GetSearchBarMeta, GetSearchBarPayload } from 'types/store/GetSearchBar';

import { getSearchBarActions } from '../actions';

const initState: GetSearchBarMeta = {
    searchKey: null
}

const initAction: Action<GetSearchBarPayload> = {
    type: '',
    payload: {}
}

export default (state = initState, action = initAction): any => {
    const reducer = () => {
        return {
            ...state,
            ...action.payload
        }
    }

    return pathOr(state, [action.type], {
        [getSearchBarActions.UPDATE]: reducer(),
        [getSearchBarActions.CLEAR]: initState
    })
}
