import React from 'react';

import { SVGProps } from './SVG.props';

const CheckSoft = (props: SVGProps): JSX.Element => {
  // const {
  //   width = 12,
  //   height = 12,
  //   fill = '#070550',
  // } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g id="check">
        <path
          id="line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.4716 3.52827C14.7319 3.78862 14.7319 4.21073 14.4716 4.47108L6.47157 12.4711C6.21122 12.7314 5.78911 12.7314 5.52876 12.4711L1.52876 8.47108C1.26841 8.21073 1.26841 7.78862 1.52876 7.52827C1.78911 7.26792 2.21122 7.26792 2.47157 7.52827L6.00016 11.0569L13.5288 3.52827C13.7891 3.26792 14.2112 3.26792 14.4716 3.52827Z"
          fill="#070550"
        />
      </g>
    </svg>
  );
};

export default CheckSoft;
