import { CreatePageMeta, CreatePagePayload } from 'types/store/CreatePageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_PAGE';

const asyncAction = createAsyncAction<CreatePageMeta, CreatePagePayload>(ns);

const createPageActions = {
  ...asyncAction,
};

export default createPageActions;
