import Typography from 'components/base/Typography';
import styled from 'utils/styled';

export const Container = styled.div``;

export const FieldContainer = styled.div<{
  mt?: number;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ded9db;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.grey.noshade};
  align-items: center;
  margin-top: ${({ mt }) => mt}px;
`;

export const Field = styled.textarea`
  font-family: 'Objective';
  resize: vertical;
  min-height: 48px;
  background-color: ${({ theme }) => theme.grey.noshade};
  display: flex;
  flex: 1;
  border-radius: 12px;
  padding: 12px 16px;
  border: 0;
  color: ${({ theme }) => theme.brand.primary};
  height: 100%;

  line-height: 24px;
  font-size: 14px;

  ::placeholder {
    font-weight: normal;
    color: ${({ theme }) => theme.grey.shade6};
  }
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${({ theme }) => theme.grey.shade3};
    color: ${({ theme }) => theme.grey.shade6};
  }
`;

export const LeftComponentContainer = styled.div`
  display: flex;
  width: 48px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.grey.shade5};
`;

export const VisibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-right: 10px;
  padding-left: 4px;
`;

export const Error = styled(Typography)`
  margin-top: 4px;
`;

export const MainLabel = styled(Typography)`
  margin-bottom: 4px;
  flex: 1;
`;

export const StateBox = styled.span`
  margin-bottom: 4px;
  margin-right: 8px;
  display: flex;
  align-items: center;
  min-height: 16px;
`;

export const StatusText = styled(Typography)<{ length?: number; max?: number }>`
  margin-left: 8px;
  margin-bottom: -4px;
  color: ${({ length, theme, max }) =>
    length === 0
      ? theme.grey.shade6
      : (length as number) < (max as number)
      ? theme.product.alert.primary
      : theme.product.success.primary};
`;

export const Header = styled.div`
  display: flex;
`;

export const SaveStatusBox = styled.span<{ saving: boolean }>`
  display: flex;
  align-items: center;

  ${({ saving }) =>
    saving
      ? `
  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  svg {
    animation: rotation 1s infinite linear;
  }
  `
      : ''}
`;

export const RightComponentContainer = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
