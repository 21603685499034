import {
  TranscribeCheckMeta,
  TranscribeCheckPayload,
} from 'types/store/TranscribeCheck';
import { createAsyncAction } from 'utils/Redux';

const ns = 'TRANSCRIBE_CHECK';

const asyncAction = createAsyncAction<
  TranscribeCheckMeta,
  TranscribeCheckPayload
>(ns);

const transcribeCheckActions = {
  ...asyncAction,
};

export default transcribeCheckActions;
