import {
    GetMagicLinkMeta,
    GetMagicLinkPayload,
  } from 'types/store/GetMagicLink';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'GET_MAGIC_LINK';
  const asyncAction = createAsyncAction<
  GetMagicLinkMeta,
  GetMagicLinkPayload
  >(ns);
  
  const getMagicLinkActions = {
    ...asyncAction,
  };
  
  export default getMagicLinkActions;
  