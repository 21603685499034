import {
    GetDataShowcasePublicPageDetailsMeta,
    GetDataShowcasePublicPageDetailsPayload,
} from 'types/store/GetDataShowcasePublicPageDetailsState';
import { createAsyncReducer } from 'utils/Redux';

import { getDataShowcasePublicPageDetailsActions } from '../actions';

export default createAsyncReducer<
    GetDataShowcasePublicPageDetailsMeta,
    GetDataShowcasePublicPageDetailsPayload
>(getDataShowcasePublicPageDetailsActions);
