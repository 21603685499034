import React from 'react';

import ReactDOM from 'react-dom';

import { PortalGeneratedProps } from './Portal.props';
import { Container, Backdrop } from './Portal.style';

const bodyTarget = document.body;

const PortalView = (props: PortalGeneratedProps): JSX.Element => {
  const { children, visible, onClick } = props;

  if (!visible) {
    return <></>;
  }

  return ReactDOM.createPortal(
    <Container
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      {/* <Backdrop
       
        /> */}
      {children}
    </Container>,
    bodyTarget,
  );
};

export default React.memo(PortalView);
