import { put, call, takeLatest, select } from 'redux-saga/effects';
import { deleteLibrary } from 'services/library';
import { AsyncAction } from 'types/Action';
import {
  DeleteLibraryMeta,
  DeleteLibraryPayload,
} from 'types/store/DeleteLibraryState';
import { Store } from 'types/store/Store';

import {
  deleteLibraryActions,
} from '../actions';

function* deleteLibraryRequest(
  action: AsyncAction<DeleteLibraryMeta, DeleteLibraryPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(deleteLibrary, action.meta, state.auth.token);
      yield put(deleteLibraryActions.success(data));
    } catch (e: any) {
      yield put(deleteLibraryActions.failed(e.message));
    }
  }
}

function* deleteLibrarySuccess(
  action: AsyncAction<DeleteLibraryMeta, DeleteLibraryPayload>
) {
  // yield put(deleteLibraryActions.clear());
}

function* deleteLibraryWatcher(): any {
  yield takeLatest(deleteLibraryActions.REQUEST, deleteLibraryRequest);
  yield takeLatest(deleteLibraryActions.SUCCESS, deleteLibrarySuccess);
}

export default deleteLibraryWatcher;
