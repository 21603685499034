import {
  GetStoriesByUserIdMeta,
  GetStoriesByUserIdPayload,
} from 'types/store/GetStoriesByUserId';
import { createAsyncReducer } from 'utils/Redux';

import { getStoriesByUserIdActions } from '../actions';

export default createAsyncReducer<
  GetStoriesByUserIdMeta,
  GetStoriesByUserIdPayload
>(getStoriesByUserIdActions);
