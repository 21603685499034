import { CreateAppointmentByAdvocateMeta, CreateAppointmentByAdvocatePayload } from 'types/store/CreateAppointmentByAdvocate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_APPOINTMENT_BY_ADVOCATE_NS';

const asyncAction = createAsyncAction<CreateAppointmentByAdvocateMeta, CreateAppointmentByAdvocatePayload>(ns);

const createAppointmentByAdvocateActions = { ...asyncAction };

export default createAppointmentByAdvocateActions;
