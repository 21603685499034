import { put, call, takeLatest, select } from 'redux-saga/effects';
import { upsertDataShowcasePageDetails } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertDataShowcasePageDetailsMeta,
  UpsertDataShowcasePageDetailsPayload,
} from 'types/store/UpsertDataShowcasePageDetailsState';

import { upsertDataShowcasePageDetailsActions } from '../actions';

function* upsertDataShowcasePageDetailsRequest(
  action: AsyncAction<UpsertDataShowcasePageDetailsMeta, UpsertDataShowcasePageDetailsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        upsertDataShowcasePageDetails,
        action.meta,
        state.auth.token
      );
      yield put(upsertDataShowcasePageDetailsActions.success(data));
    } catch (e: any) {
      yield put(upsertDataShowcasePageDetailsActions.failed(e.message));
    }
  } else {
    yield put(upsertDataShowcasePageDetailsActions.failed('Token not found'));
  }
}

function* upsertDataShowcasePageDetailsSuccess(
  action: AsyncAction<UpsertDataShowcasePageDetailsMeta, UpsertDataShowcasePageDetailsPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* upsertDataShowcasePageDetailsWatcher(): any {
  yield takeLatest(upsertDataShowcasePageDetailsActions.REQUEST, upsertDataShowcasePageDetailsRequest);
  yield takeLatest(upsertDataShowcasePageDetailsActions.SUCCESS, upsertDataShowcasePageDetailsSuccess);
}

export default upsertDataShowcasePageDetailsWatcher;
