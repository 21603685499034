import Typography from 'components/base/Typography';
import styled from 'utils/styled';

export const Container = styled.div<{
  shouldDisplay: boolean;
}>`
  position: absolute;
  top: 50px;
  right: 50px;

  width: 200px;
  background-color: #f1f6f9;
  padding: 16px;
  border-radius: 12px;
  opacity: ${({ shouldDisplay }) => {
    if (shouldDisplay) {
      return 0.9;
    } else {
      return 0;
    }
  }};
  transition: opacity 0.25s ease-out;
  z-index: -5;
`;

export const Message = styled(Typography)``;
