import { 
  UpdateStripeCustomerDetailsMeta,
  UpdateStripeCustomerDetailsPayload
 } from 'types/store/UpdateStripeCustomerDetails';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STRIPE_CUSTOMER_DETAILS';

const asyncAction = createAsyncAction<UpdateStripeCustomerDetailsMeta, UpdateStripeCustomerDetailsPayload>(ns);

const updateStripeCustomerDetailsActions = {
  ...asyncAction,
};

export default updateStripeCustomerDetailsActions;
