import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getRecipeDiscussionDetail } from 'services/recipe-detail';
import { AsyncAction } from 'types/Action';
import { GetRecipeDiscussionDetailMeta, GetRecipeDiscussionDetailPayload } from 'types/store/GetRecipeDiscussionDetail';
import { Store } from 'types/store/Store';

import { getRecipeDiscussionDetailActions } from '../actions';

function* getRecipeDiscussionDetailRequest(action: AsyncAction<GetRecipeDiscussionDetailMeta, GetRecipeDiscussionDetailPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getRecipeDiscussionDetail, action.meta, state.auth.token);
      yield put(getRecipeDiscussionDetailActions.success(data));
    } catch (e: any) {
      yield put(getRecipeDiscussionDetailActions.failed(e.message));
    }
  }
}

// function* getRecipeDiscussionDetailSuccess(action: AsyncAction<GetRecipeDiscussionDetailMeta, GetRecipeDiscussionDetailPayload>): any {
//   yield put()
// }

function* getRecipeDiscussionDetailWatcher(): any {
  yield takeLatest(getRecipeDiscussionDetailActions.REQUEST, getRecipeDiscussionDetailRequest);
  // yield takeLatest(getRecipeDiscussionDetailActions.SUCCESS, getRecipeDiscussionDetailSuccess);
}

export default getRecipeDiscussionDetailWatcher;
