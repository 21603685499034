import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getInviteLink, getRoleInviteTeam } from 'services/team';
import { AsyncAction } from 'types/Action';
import { GetInviteLinkTeamMeta, GetInviteLinkTeamPayload } from 'types/store/GetInviteLinkTeam';
import { Store } from 'types/store/Store';

import { getInviteLinkTeamActions } from '../actions';

function* getInviteLinkTeamRequest(action: AsyncAction<GetInviteLinkTeamMeta, GetInviteLinkTeamPayload>): any {
  const token: string | null = yield select((_: Store) => _.auth.token);
  if (token) {
    try {
      const { data } = yield call(getInviteLink, action.meta, token);
      yield put(getInviteLinkTeamActions.success(data));
    } catch (e: any) {
      yield put(getInviteLinkTeamActions.failed(e.message));
    }
  }
}

// function* getInviteLinkTeamSuccess(action: AsyncAction<GetInviteLinkTeamMeta, GetInviteLinkTeamPayload>): any {
//   yield put()
// }

function* getInviteLinkTeamWatcher(): any {
  yield takeLatest(getInviteLinkTeamActions.REQUEST, getInviteLinkTeamRequest);
  // yield takeLatest(getInviteLinkTeamActions.SUCCESS, getInviteLinkTeamSuccess);
}

export default getInviteLinkTeamWatcher;
