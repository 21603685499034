import {
  GetAssignedAdvocatesByStoryMeta,
  GetAssignedAdvocatesByStoryPayload,
  UpdateAdvocatesForStoryMeta,
  UpdateAdvocatesForStoryPayload,
} from 'types/store/Story';
import { createAsyncAction } from 'utils/Redux';

const GET_ASSIGNED_ADVOCATE_BY_STORY_NS = 'GET_ASSIGNED_ADVOCATE_BY_STORY_NS';
const UPDATE_ADVOCATES_FOR_STORY_NS = 'UPDATE_ADVOCATES_FOR_STORY_NS';

const getAssignedAdvocatesByStoryActions = {
  ...createAsyncAction<
    GetAssignedAdvocatesByStoryMeta,
    GetAssignedAdvocatesByStoryPayload
  >(GET_ASSIGNED_ADVOCATE_BY_STORY_NS),
};

const updateAdvocatesForStoryActions = {
  ...createAsyncAction<
    UpdateAdvocatesForStoryMeta,
    UpdateAdvocatesForStoryPayload
  >(UPDATE_ADVOCATES_FOR_STORY_NS),
};

export { getAssignedAdvocatesByStoryActions, updateAdvocatesForStoryActions };
