import { put, call, takeLatest, select } from 'redux-saga/effects';
import { transcribeStory } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  // TranscribeVideoMeta,
  TranscribeVideoPayload,
} from 'types/store/TranscribeVideo';

import { transcribeStoryActions } from '../actions';

function* transcribeStoryRequest(
  action: AsyncAction<string, TranscribeVideoPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        transcribeStory,
        action.meta,
        state.auth.token,
      );
      yield put(transcribeStoryActions.success(data));
    } catch (e: any) {
      yield put(transcribeStoryActions.failed(e.message));
    }
  }
}

// function* transcribeVideoSuccess(action: AsyncAction<TranscribeVideoMeta, TranscribeVideoPayload>): any {
//   yield put()
// }

function* transcribeStoryWatcher(): any {
  yield takeLatest(transcribeStoryActions.REQUEST, transcribeStoryRequest);
  // yield takeLatest(transcribeVideoActions.SUCCESS, transcribeVideoSuccess);
}

export default transcribeStoryWatcher;
