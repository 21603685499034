/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const CheckCircleFilled = (props: SVGProps): JSX.Element => {
  const { width, height, fill, rotate, style } = props;
  return (
    <svg
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM14.7559 6.91074C15.0814 7.23618 15.0814 7.76382 14.7559 8.08926L8.92259 13.9226C8.59715 14.248 8.06952 14.248 7.74408 13.9226L4.41074 10.5893C4.08531 10.2638 4.08531 9.73618 4.41074 9.41074C4.73618 9.08531 5.26382 9.08531 5.58926 9.41074L8.33333 12.1548L13.5774 6.91074C13.9028 6.58531 14.4305 6.58531 14.7559 6.91074Z"
        fill={fill || '#91DBB6'}
      />
    </svg>
  );
};

export default CheckCircleFilled;
