import { GetPageMeta, GetPagePayload } from 'types/store/GetPageState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_PAGE';

const asyncAction = createAsyncAction<GetPageMeta, GetPagePayload>(ns);
const clearAction = createClearAction(ns);

const getPageActions = {
  ...asyncAction,
  ...clearAction,
};

export default getPageActions;
