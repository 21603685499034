import { CheckUrlMeta, CheckUrlPayload } from 'types/store/CheckUrlState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHECK_URL';

const asyncAction = createAsyncAction<CheckUrlMeta, CheckUrlPayload>(ns);

const checkUrlActions = {
  ...asyncAction,
};

export default checkUrlActions;
