import { AddQuestionsForAdvocateMeta, AddQuestionsForAdvocatePayload } from 'types/store/AddQuestionsForAdvocate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADD_QUESTIONS_FOR_ADVOCATE';

const asyncAction = createAsyncAction<AddQuestionsForAdvocateMeta, AddQuestionsForAdvocatePayload>(ns);

const addQuestionsForAdvocateActions = { ...asyncAction };

export default addQuestionsForAdvocateActions;
