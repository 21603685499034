import { GetStoryEventLogMeta, GetStoryEventLogPayload } from 'types/store/GetStoryEventLog';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_EVENT_LOG_NS';

const asyncAction = createAsyncAction<GetStoryEventLogMeta, GetStoryEventLogPayload>(ns);

const getStoryEventLogActions = { ...asyncAction };

export default getStoryEventLogActions;
