import React from 'react';

import { SVGProps } from './SVG.props';

const Wave = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width="245"
      height="42"
      viewBox="0 0 245 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="10.5664" y="18" width="2" height="6" rx="1" fill="#E1AD21" />
      <rect x="21.1309" y="15" width="2" height="12" rx="1" fill="#E1AD21" />
      <rect x="31.6953" width="2" height="42" rx="1" fill="#E1AD21" />
      <rect x="42.2617" y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="52.8262" y="12" width="2" height="18" rx="1" fill="#E1AD21" />
      <rect x="63.3906" y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="73.957" y="18" width="2" height="6" rx="1" fill="#E1AD21" />
      <rect x="84.5215" y="9" width="2" height="24" rx="1" fill="#E1AD21" />
      <rect x="95.0879" y="9" width="2" height="24" rx="1" fill="#E1AD21" />
      <rect x="105.652" y="15" width="2" height="12" rx="1" fill="#E1AD21" />
      <rect x="116.219" y="18" width="2" height="6" rx="1" fill="#E1AD21" />
      <rect x="126.781" y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="137.348" y="12" width="2" height="18" rx="1" fill="#E1AD21" />
      <rect x="147.912" y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="158.479" y="18" width="2" height="6" rx="1" fill="#E1AD21" />
      <rect x="169.043" y="18" width="2" height="6" rx="1" fill="#E1AD21" />
      <rect x="179.609" y="18" width="2" height="6" rx="1" fill="#E1AD21" />
      <rect x="190.174" width="2" height="42" rx="1" fill="#E1AD21" />
      <rect x="200.738" y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="211.305" y="15" width="2" height="12" rx="1" fill="#E1AD21" />
      <rect x="221.869" y="4.5" width="2" height="33" rx="1" fill="#E1AD21" />
      <rect x="232.434" y="12" width="2" height="18" rx="1" fill="#E1AD21" />
      <rect x="243" y="12" width="2" height="18" rx="1" fill="#E1AD21" />
    </svg>
  );
};

export default Wave;
