import { DeleteApproverMeta, DeleteApproverPayload } from 'types/store/DeleteApprover';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_APPROVER_NS';

const asyncAction = createAsyncAction<DeleteApproverMeta, DeleteApproverPayload>(ns);

const deleteApproverActions = { ...asyncAction };

export default deleteApproverActions;
