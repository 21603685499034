import { push, getLocation } from 'connected-react-router';
import { EMPLOYER_ROUTES, ADVOCATE_ROUTES, API } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { switchPlatforms, getAllPlatform } from 'services/auth';
import { AsyncAction } from 'types/Action';
import { PlatForm } from 'types/store/LoginState';
import { Store } from 'types/store/Store';
import {
  SwitchPlatformsMeta,
  SwitchPlatformsPayload,
} from 'types/store/SwitchPlatforms';
import {
  SwitchOtherCompanyMeta,
  SwitchOtherCompanyMetaPayload,
} from 'types/store/SwitchOtherCompany';
import addIdentity from 'utils/addIdentity';

import {
  switchPlatformsActions,
  switchOtherCompanyActions,
  authActions,
} from '../actions';

function* switchPlatformsRequest(
  action: AsyncAction<SwitchPlatformsMeta, SwitchPlatformsPayload>,
) {
  const state: Store = yield select();
  try {
    if (state.auth.token) {
      const { data } = yield call(
        switchPlatforms,
        action.meta,
        state.auth.token,
      );
      data.data.logoutUrl = state.auth.logoutUrl;
      data.data.switchPlatform = state.auth.platform;
      data.data.urlNavigate = action.meta.urlNavigate;
      yield put(switchPlatformsActions.success(data));
    }
  } catch (e: any) {
    yield put(switchPlatformsActions.failed(e.message));
  }
}

function* switchPlatformsSuccess(
  action: AsyncAction<SwitchPlatformsMeta, SwitchPlatformsPayload>,
) {
  if (action.payload?.data?.platform) {
    if (
      action.payload.data.platform === PlatForm.EMPLOYER ||
      action.payload.data.platform === PlatForm.RECRUITER
    ) {
      let URL = EMPLOYER_ROUTES.CONTENT_CALENDAR;
      if (action.payload.data.token) {
        const { data } = yield call(getAllPlatform, action.payload.data.token);
        const jd = action.payload.data.platform === PlatForm.RECRUITER && data?.data?.recruiterJD;
        if (data?.data?.renderHomePage) URL = jd ? EMPLOYER_ROUTES.HOMEPAGE_JD : EMPLOYER_ROUTES.HOMEPAGE;
      }
      yield put(push(URL, { switchPlatform: 1 }));
    } else {
      yield put(
        push(
          action.payload.data.urlNavigate
            ? action.payload.data.urlNavigate
            : ADVOCATE_ROUTES.STORY_MANAGEMENT,
          { switchPlatform: 1 },
        ),
      );
    }
    yield put(
      authActions.update({
        ...action.payload.data,
        permissionRouter: action.payload.data.access_routes,
      }),
    );
    yield addIdentity(action.payload?.data);
  }
}

function* switchOtherCompanyRequest(
  action: AsyncAction<SwitchOtherCompanyMeta, SwitchOtherCompanyMetaPayload>,
) {
  const state: Store = yield select();
  try {
    if (state.auth.token) {
      const { data } = yield call(
        switchPlatforms,
        action.meta,
        state.auth.token,
      );
      data.data.logoutUrl = state.auth.logoutUrl;
      data.data.switchPlatform = state.auth.platform;
      yield put(switchOtherCompanyActions.success(data));
    }
  } catch (e: any) {
    yield put(switchOtherCompanyActions.failed(e.message));
  }
}

function* switchOtherCompanySuccess(
  action: AsyncAction<SwitchPlatformsMeta, SwitchPlatformsPayload>,
) {
  if (action.payload?.data?.platform) {
    yield put(authActions.update(action.payload.data));
    yield addIdentity(action.payload?.data);
    location.reload();
  }
}

function* switchPlatformsWatcher(): any {
  yield takeLatest(switchPlatformsActions.REQUEST, switchPlatformsRequest);
  yield takeLatest(switchPlatformsActions.SUCCESS, switchPlatformsSuccess);
  yield takeLatest(
    switchOtherCompanyActions.REQUEST,
    switchOtherCompanyRequest,
  );
  yield takeLatest(
    switchOtherCompanyActions.SUCCESS,
    switchOtherCompanySuccess,
  );
}

export default switchPlatformsWatcher;
