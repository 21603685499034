import { GetCalendarEventsMeta, GetCalendarEventsPayload } from 'types/store/GetCalendarEvents';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_CALENDAR_EVENTS';

const asyncAction = createAsyncAction<GetCalendarEventsMeta, GetCalendarEventsPayload>(ns);

const getCalendarEventsActions = { ...asyncAction };

export default getCalendarEventsActions;
