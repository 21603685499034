import React from 'react';

import { SVGProps } from './SVG.props';

const Quotes = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 96}
      height={height || 96}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity={0.5} fill="#fff">
        <path d="M85.478 42.45a16.87 16.87 0 00-4.146-10.93 17.152 17.152 0 00-10.295-5.682 17.24 17.24 0 00-11.56 2.261 16.986 16.986 0 00-7.344 9.131 16.693 16.693 0 00.99 12.885 16.859 16.859 0 004.112 5.177 17.031 17.031 0 005.802 3.217c.25.081.5.159.746.225-2.034 5.438-6.196 8.335-9.056 9.826a.925.925 0 00-.208 1.502c.145.136.33.223.53.248 3.91.462 7.876.079 11.622-1.125a27.28 27.28 0 0010.079-5.847 26.977 26.977 0 006.678-9.494 26.763 26.763 0 002.05-11.394zM43.92 42.45c.004-3.12-.863-6.18-2.503-8.843a17.027 17.027 0 00-6.794-6.246 17.217 17.217 0 00-17.788 1.343 16.958 16.958 0 00-5.761 7.194 16.792 16.792 0 00-1.124 9.116 16.863 16.863 0 003.844 8.356 17.094 17.094 0 008.427 5.364c-2.045 5.438-6.192 8.335-9.055 9.826a.936.936 0 00-.486.993.93.93 0 00.28.51.945.945 0 00.53.247c3.911.462 7.877.079 11.623-1.125a27.28 27.28 0 0010.079-5.847 26.977 26.977 0 006.678-9.494 26.765 26.765 0 002.05-11.394z" />
      </g>
    </svg>
  );
};

export default Quotes;
