import axios from 'axios';
import { API } from 'consts';
import { EbCommentOnRecipeMeta } from 'types/store/EbCommentOnRecipe';
import { GetDataRecipeDetailMeta } from 'types/store/GetDataRecipeDetail';
import { GetRecipeDiscussionDetailMeta } from 'types/store/GetRecipeDiscussionDetail';
import { GuestCommentOnRecipeMeta } from 'types/store/GuestCommentOnRecipe';
import { IRecipeStoryMeta } from 'store/actions/contentPlanner/recipeStory';
import { ISetStoryToRecipeMeta } from 'store/actions/recipeDetails/setStoryToRecipe';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const MAIN_URL = `${BASE_URL}/recipe-detail`;

export const ebCommentOnRecipe = (
  data: EbCommentOnRecipeMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${MAIN_URL}/${data.recipeDetailId}/comment`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      comment: data.comment,
      email: data.email,
    },
  });
};

export const guestCommentOnRecipe = (data: GuestCommentOnRecipeMeta): any => {
  return axios({
    method: 'post',
    url: `${MAIN_URL}/${data.recipeDetailId}/guest-comment`,
    data,
  });
};

export const getRecipeDiscussionDetail = (
  data: GetRecipeDiscussionDetailMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${MAIN_URL}/${data.recipeDetailId}/recipe-detail-discussion`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDataRecipeDetail = (
  data: GetDataRecipeDetailMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${MAIN_URL}/${data.recipeDetailId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getContentRecipes = (data: any): any => {
  return [];
};

export const getRecipeStory = (data: IRecipeStoryMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${MAIN_URL}/get-story-by-recipe/${data.recipeId}/${data.contentPackageId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const setStoryToRecipe = (
  data: ISetStoryToRecipeMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${MAIN_URL}/set-story-to-recipe`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
