import { ShareVideoToLinkedinMeta, ShareVideoToLinkedinPayload } from 'types/store/ShareVideoToLinkedin';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SHARE_VIDEO_TO_LINKEDIN_NS';

const asyncAction = createAsyncAction<ShareVideoToLinkedinMeta, ShareVideoToLinkedinPayload>(ns);

const shareVideoToLinkedinActions = { ...asyncAction };

export default shareVideoToLinkedinActions;
