import React from 'react';

import { SVGProps } from './SVG.props';

const Bubble = (props: SVGProps): JSX.Element => {
  const { width, height } = props;
  return (
    <svg
      width={26 || width}
      height={16 || height}
      viewBox="0 0 26 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.658 8c0 4.058-3.268 7.348-7.298 7.348S.062 12.058.062 8C.063 3.943 3.33.652 7.36.652S14.658 3.942 14.658 8zM22.663 8c0 3.82-1.633 6.918-3.649 6.918-2.015 0-3.648-3.098-3.648-6.918 0-3.82 1.633-6.917 3.648-6.917S22.663 4.179 22.663 8M25.938 8c0 3.422-.575 6.197-1.284 6.197-.709 0-1.283-2.775-1.283-6.197s.575-6.197 1.283-6.197c.709 0 1.284 2.775 1.284 6.197z"
        fill="#000"
      />
    </svg>
  );
};

export default Bubble;
