import {
  GetBillingAddressMeta,
  GetBillingAddressPayload
} from 'types/store/GetBillingAddress';
import { createAsyncReducer } from 'utils/Redux';

import { getBillingAddressActions } from '../actions';

export default createAsyncReducer<GetBillingAddressMeta, GetBillingAddressPayload>(
  getBillingAddressActions
);
