import { UpdateStoryTopicMeta, UpdateStoryTopicPayload } from 'types/store/UpdateStoryTopic';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STORY_TOPIC_NS';

const asyncAction = createAsyncAction<UpdateStoryTopicMeta, UpdateStoryTopicPayload>(ns);

const updateStoryTopicActions = { ...asyncAction };

export default updateStoryTopicActions;
