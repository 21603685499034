import { push } from 'connected-react-router';
import { EMPLOYER_ROUTES, MAIN_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateStoryToPublish } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateStoryToPublishMeta,
  UpdateStoryToPublishPayload,
} from 'types/store/UpdateStoryToPublishState';

import { updateStoryToPublishActions } from '../actions';

function* updateStoryToPublishRequest(
  action: AsyncAction<UpdateStoryToPublishMeta, UpdateStoryToPublishPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateStoryToPublish,
        action.meta,
        state.auth.token
      );
      yield put(updateStoryToPublishActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield put(push(MAIN_ROUTES.NOT_FOUND));
      } else {
        yield put(updateStoryToPublishActions.failed(e.message));
      }
    }
  }
}

function* updateStoryToPublishSuccess(
  action: AsyncAction<UpdateStoryToPublishMeta, UpdateStoryToPublishPayload>
) {
  yield put(push(EMPLOYER_ROUTES.STORY_EDITOR));
  window.location.reload();
}

function* updateStoryToPublishWatcher(): any {
  yield takeLatest(
    updateStoryToPublishActions.REQUEST,
    updateStoryToPublishRequest
  );
  yield takeLatest(
    updateStoryToPublishActions.SUCCESS,
    updateStoryToPublishSuccess
  );
}

export default updateStoryToPublishWatcher;
