import React from 'react';

import { SVGProps } from './SVG.props';

const Martec = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12.0609 16.1459C11.041 16.1459 10.4169 15.6207 10.3915 15.5954L10.3966 15.6005L9.37675 16.7215C9.4833 16.8175 10.4626 17.6558 12.0609 17.6558C13.6592 17.6558 14.6385 16.8175 14.745 16.7215L13.7252 15.6005C13.7201 15.6055 13.0909 16.1459 12.0609 16.1459Z"
        fill={fill || 'white'}
      />
      <path
        d="M11.1628 10.485C11.1628 7.41469 8.66638 4.93018 5.5814 4.93018C2.49641 4.93018 0 7.41469 0 10.485C0 13.5553 2.49641 16.0398 5.5814 16.0398C8.66638 16.0398 11.1628 13.5553 11.1628 10.485ZM5.5814 14.5249C3.34376 14.5249 1.5222 12.712 1.5222 10.485C1.5222 8.25802 3.34376 6.44513 5.5814 6.44513C7.81903 6.44513 9.64059 8.25802 9.64059 10.485C9.64059 12.712 7.81903 14.5249 5.5814 14.5249Z"
        fill={fill || 'white'}
      />
      <path
        d="M20.093 11.9999C20.9337 11.9999 21.6152 11.3217 21.6152 10.485C21.6152 9.64831 20.9337 8.97004 20.093 8.97004C19.2523 8.97004 18.5708 9.64831 18.5708 10.485C18.5708 11.3217 19.2523 11.9999 20.093 11.9999Z"
        fill={fill || 'white'}
      />
      <path
        d="M7.10359 11.9999C7.94428 11.9999 8.62579 11.3217 8.62579 10.485C8.62579 9.64831 7.94428 8.97004 7.10359 8.97004C6.26291 8.97004 5.5814 9.64831 5.5814 10.485C5.5814 11.3217 6.26291 11.9999 7.10359 11.9999Z"
        fill={fill || 'white'}
      />
      <path
        d="M24 10.485C24 7.41469 21.5036 4.93018 18.4186 4.93018C15.3336 4.93018 12.8372 7.41469 12.8372 10.485C12.8372 13.5553 15.3336 16.0398 18.4186 16.0398C19.1036 16.0398 19.7581 15.9186 20.3619 15.6914C19.4639 16.5953 18.3374 17.2669 17.1146 17.6103L17.5256 19.0697C19.3522 18.5597 21.0013 17.4437 22.1632 15.9388C23.3658 14.3835 24 12.5201 24 10.5557C24 10.5304 24 10.5102 24 10.485ZM14.3594 10.485C14.3594 8.25802 16.181 6.44513 18.4186 6.44513C20.6562 6.44513 22.4778 8.25802 22.4778 10.485C22.4778 12.712 20.6562 14.5249 18.4186 14.5249C16.181 14.5249 14.3594 12.712 14.3594 10.485Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default Martec;
