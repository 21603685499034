import { put, call, takeLatest, select } from 'redux-saga/effects';
import { countDashboardStories } from 'services/story';
import { AsyncAction } from 'types/Action';
import { CountDashboardStoriesMeta, CountDashboardStoriesPayload } from 'types/store/CountDashboardStories';
import { Store } from 'types/store/Store';

import { countDashboardStoriesActions } from '../actions';

function* countDashboardStoriesRequest(action: AsyncAction<CountDashboardStoriesMeta, CountDashboardStoriesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(countDashboardStories, state.auth.token);
      yield put(countDashboardStoriesActions.success(data));
    } catch (e: any) {
      yield put(countDashboardStoriesActions.failed(e.message));
    }
  }
}

// function* countDashboardStoriesSuccess(action: AsyncAction<CountDashboardStoriesMeta, CountDashboardStoriesPayload>): any {
//   yield put()
// }

function* countDashboardStoriesWatcher(): any {
  yield takeLatest(countDashboardStoriesActions.REQUEST, countDashboardStoriesRequest);
  // yield takeLatest(countDashboardStoriesActions.SUCCESS, countDashboardStoriesSuccess);
}

export default countDashboardStoriesWatcher;
