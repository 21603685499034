import {
  GetAdvocateInterestMeta,
  GetAdvocateInterestPayload,
} from 'types/store/GetAdvocateInterest';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATE_INTEREST';
const asyncAction = createAsyncAction<
  GetAdvocateInterestMeta,
  GetAdvocateInterestPayload
>(ns);

const getAdvocateInterestActions = {
  ...asyncAction,
};

export default getAdvocateInterestActions;
