import {
    UpdateShowcaseArticlePositionMeta,
    UpdateShowcaseArticlePositionPayload
} from 'types/store/UpdateShowcaseArticlePositionState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SHOWCASE_ARICLE_POSITION';

const asyncAction = createAsyncAction<
    UpdateShowcaseArticlePositionMeta,
    UpdateShowcaseArticlePositionPayload
>(ns);

const updateShowcaseArticlePositionActions = {
    ...asyncAction,
};

export default updateShowcaseArticlePositionActions;
