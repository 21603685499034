import {
  GetListeningPathsByCommunityMeta,
  GetListeningPathsByCommunityPayload,
} from 'types/store/GetListeningPathsByCommunityState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LISTENING_PATHS_BY_COMMUNITY';

const asyncAction = createAsyncAction<
  GetListeningPathsByCommunityMeta,
  GetListeningPathsByCommunityPayload
>(ns);

const getListeningPathsByCommunityActions = {
  ...asyncAction,
};

export default getListeningPathsByCommunityActions;
