import {
  GetContentPlannerRecipeMeta,
  GetContentPlannerRecipePayload,
  EditPlanMeta,
  EditPlanPayload,
  CreateRecipeMeta,
  CreateRecipePayload,
  EditAssetRecipeMeta,
} from 'types/store/ContentPlanner';
import {
  createAsyncAction,
  createUpdateAction,
  createClearAction,
} from 'utils/Redux';

const get_content_planner_recipe_ns = 'GET_CONTENT_PLANNER_RECIPE_NS';
const get_all_content_planner_recipe_details_ns =
  'GET_ALL_CONTENT_PLANNER_RECIPE_DETAILS_NS';
const edit_plan_ns = 'EDIT_PLAN_NS';
const create_recipe_ns = 'CREATE_RECIPE_NS';
const edit_asset_recipe = 'EDIT_ASSET_RECIPE';
const moving_plan_ns = 'MOVING_PLAN_NS';
const edit_recipe_ns = 'EDIT_RECIPE_NS';

const getContentPlannerRecipesActions = {
  ...createAsyncAction<
    GetContentPlannerRecipeMeta,
    GetContentPlannerRecipePayload
  >(get_content_planner_recipe_ns),
};

const getAllContentPlannerRecipeDetailsActions = {
  ...createAsyncAction<
    GetContentPlannerRecipeMeta,
    GetContentPlannerRecipePayload
  >(get_all_content_planner_recipe_details_ns),
};

const editPlanActions = {
  ...createAsyncAction<EditPlanMeta, EditPlanPayload>(edit_plan_ns),
};

const movingPlanActions = {
  ...createAsyncAction<EditPlanMeta, EditPlanPayload>(moving_plan_ns),
};

const createRecipeActions = {
  ...createAsyncAction<CreateRecipeMeta, CreateRecipePayload>(create_recipe_ns),
};

const editRecipeActions = {
  ...createAsyncAction<EditPlanMeta, CreateRecipePayload>(edit_recipe_ns),
};

const editAssetRecipeActions = {
  ...createUpdateAction<EditAssetRecipeMeta>(edit_asset_recipe),
  ...createClearAction(edit_asset_recipe),
};

export {
  getContentPlannerRecipesActions,
  editPlanActions,
  getAllContentPlannerRecipeDetailsActions,
  createRecipeActions,
  editAssetRecipeActions,
  movingPlanActions,
  editRecipeActions,
};
