import React from 'react'

import { Action } from 'types/Action'
import { GetImportFromUrlMeta, GetImportFromUrlPayload } from 'types/store/JobsState';
import { createAsyncAction } from 'utils/Redux';

export enum JOBS_SCAN_LINK {
  JOBS_SCAN_LINK_REQUEST = '@@_JOBS_SCAN_LINK_REQUEST',
  JOBS_SCAN_LINK_SUCCESS = '@@_JOBS_SCAN_LINK_SUCCESS',
  JOBS_SCAN_LINK_ERROR = '@@_JOBS_SCAN_LINK_ERROR',
}

const ns = 'JOBS_SCAN_LINK_REQUEST';

const asyncAction = createAsyncAction<GetImportFromUrlMeta, GetImportFromUrlPayload>(ns);

const getImportFromUrlActions = { ...asyncAction };

export default getImportFromUrlActions;


