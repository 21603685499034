import { GetRecipesMeta, GetRecipesPayload } from 'types/store/GetRecipes';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_RECIPES_NS';

const asyncAction = createAsyncAction<GetRecipesMeta, GetRecipesPayload>(ns);

const getRecipesActions = { ...asyncAction };

export default getRecipesActions;
