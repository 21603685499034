import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { AdvocateBulkCallMeta } from 'types/store/AdvocateBulkCall';
import { AdvocateSendEmailMeta } from 'types/store/AdvocateSendEmail';
import { AdvocateStoryDetailsMeta } from 'types/store/AdvocateStoryDetails';
import { AdvocateStoryOverviewMeta } from 'types/store/AdvocateStoryOverview';
import { AdvocateUpdateStoryMeta } from 'types/store/AdvocateUpdateStory';
import { ChangePasswordMeta } from 'types/store/ChangePasswordState';
import { CheckColleagueEmailsMeta } from 'types/store/CheckColleagueEmails';
import { CheckInviteMeta } from 'types/store/CheckInviteState';
import { GetAdvocateDetailsMeta } from 'types/store/GetAdvocateDetails';
import { GetAdvocateInvitesMeta } from 'types/store/GetAdvocateInvites';
import { GetAdvocatesByCompanyIdMeta } from 'types/store/GetAdvocatesByCompanyId';
import { GetAdvocatesByUserIdMeta } from 'types/store/GetAdvocatesByUserId';
import { GetCompanyInfoByCompanyIdMeta } from 'types/store/GetCompanyInfoByCompanyId';
import { GetEmployeeHubDataMeta } from 'types/store/GetEmployeeHubData';
import { GetMagicLinkMeta } from 'types/store/GetMagicLink';
import { GetUserByIdMeta } from 'types/store/GetUserById';
import { InviteAdvocateMeta } from 'types/store/InviteAdvocateState';
import {
  RegisterAdvocateMeta,
  RegisterAdvocateWidthExistedTokenMeta,
} from 'types/store/RegisterAdvocateState';
import { UnlockAdvocateCalllMeta } from 'types/store/UnlockAdvocateCall';
import { UpdateUserByIdMeta } from 'types/store/UpdateUserById';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const ADVOCATE_URL = `${BASE_URL}/advocate`;

export const advocateSendEmail = (
  data: AdvocateSendEmailMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/sendEmail`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getCompanyInfoByCompanyId = (
  data: GetCompanyInfoByCompanyIdMeta,
): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getCompanyInfo`,
    params: data,
  });
};

//company_id is already embeded in the token
export const getAdvocatesByCompanyId = (
  data: GetAdvocatesByCompanyIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getAdvocatesByCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getMagicLink = (data: GetMagicLinkMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getMagicLink`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const inviteAdvocate = (
  data: InviteAdvocateMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/generateInvite`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getLinkInvite = (token: string): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getLinkInvite`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const checkInvite = (data: CheckInviteMeta): any => {
  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/checkInvite`,
    data: data,
  });
};

export const register = (data: RegisterAdvocateMeta): any => {
  const fd = new FormData();

  if (data.profilePicture !== undefined) {
    fd.append('profile_picture', data.profilePicture);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/signup`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
    data: fd,
  });
};

export const registerWidthExistedToken = (
  data: RegisterAdvocateWidthExistedTokenMeta,
  token: string,
): any => {
  const fd = new FormData();

  if (data.profilePicture !== undefined) {
    fd.append('profile_picture', data.profilePicture);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/signUpWithExistedToken`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const checkColleagueEmails = (
  data: CheckColleagueEmailsMeta,
  token: string,
): any => {
  
  data.emails = data.emails
    ?.map((e) => e.trim())
    .filter((e) => (e ? true : false));

  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/checkColleagueEmails`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserById = (data: GetUserByIdMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getUserById`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateUserById = (
  data: UpdateUserByIdMeta,
  token: string,
): any => {
  const fd = new FormData();
  if (data.profilePicture !== undefined) {
    fd.append('profile_picture', data.profilePicture);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/updateUserById`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const advocateBulkCall = (
  data: AdvocateBulkCallMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/advocateBulkCall`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAdvocateInvites = (
  data: GetAdvocateInvitesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getAdvocateInvites`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const changePassword = (
  data: ChangePasswordMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${ADVOCATE_URL}/changePassword`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListOptions = (token: string): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getListOptions`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getStoryOverview = (
  data: AdvocateStoryOverviewMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/stories/overview`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getAdvocatesByUserIds = (
  data: GetAdvocatesByUserIdMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/getByUserIds`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getEmployeeHubData = (
  data: GetEmployeeHubDataMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ADVOCATE_URL}/getEmployeeHubData`,
    params: data,
  });
};

export const getAdvocateInfo = (
  data: GetAdvocateDetailsMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${ADVOCATE_URL}/getAdvocateInfo`,
    params: data,
  });
};

export const getAdvocateStoryDetails = (
  data: AdvocateStoryDetailsMeta,
  token: string,
): any => {
  return axios({
    method: 'GET',
    url: `${ADVOCATE_URL}/story/${data.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const advocateUpdateStory = (
  data: AdvocateUpdateStoryMeta,
  token: string,
): any => {
  return axios({
    method: 'POST',
    url: `${ADVOCATE_URL}/story/${data.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const advocatePortalStatistical = (token: string): any => {
  return axios({
    method: 'GET',
    url: `${ADVOCATE_URL}/portal/statistical`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getNanlQuestionsInfo = (token: string): any => {
  return axios({
    method: 'get',
    withCredentials: true,
    url: `${ADVOCATE_URL}/collection-link/story/${token}`,
  });
};

export const postNanlQuestionsInfo = (session_id?: string, data?: any): any => {
  return axios({
    method: 'post',
    withCredentials: true,
    url: `${ADVOCATE_URL}/collection-link/response/${session_id}`,
    data: data,
  });
};

export const getQRCodeVideoStory = (token: string): any => {
  return axios({
    method: 'GET',
    url: `${BASE_URL}/setting/get-link-qrcode`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTokenGenerateAI = (session_id: string): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/collection-link/generate-token/${session_id}`,
  });
};

export const getPresignUrlVideoUpload = (token: string, data: any): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/nanl/get-upload-link/${token}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const getLimitDurationVideo = (storyId?: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/company/get-video-upload-config-by-story/${storyId}`,
  });
};

export const getEncodeVideoLink = (session_id: string, data: any): any => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/nanl/get-encoded-upload-link/${session_id}`,
    // headers: {
    //   Authorization: `Bearer ${session_id}`,
    // },
    params: data,
    timeout: 10 * 60 * 1000,
  });
};

export const unClockAdvocate = (
  token: string,
  data: UnlockAdvocateCalllMeta,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    url: `${BASE_URL}/employee/unlock-user-login-fail`,
    data,
  });
};

export const getAdvocateStoriesEachTab = (
  token: string,
  data: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${ADVOCATE_URL}/stories/statusOverview`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};
