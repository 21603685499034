import { UpdateStoryDiscussionMeta, UpdateStoryDiscussionPayload } from 'types/store/UpdateStoryDiscussion';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STORY_DISCUSSION_NS';

const asyncAction = createAsyncAction<UpdateStoryDiscussionMeta, UpdateStoryDiscussionPayload>(ns);

const updateStoryDiscussionActions = { ...asyncAction };

export default updateStoryDiscussionActions;
