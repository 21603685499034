import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowDown = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 12}
      height={height || 12}
      viewBox="0 0 12 12"
      fill={fill || '#DED9DB'}
      {...props}
    >
      <path
        d="M5.744 11.332a.667.667 0 00.726-.142l4.714-4.714a.667.667 0 00-.943-.943L6.663 9.111l.005-7.82a.667.667 0 00-.926-.62.667.667 0 00-.413.62l.005 7.82-3.578-3.578a.667.667 0 00-.943.943l4.714 4.714c.063.061.136.109.217.142z"
        fill={fill || '#fff'}
      />
    </svg>
  );
};

export default ArrowDown;
