import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_WORK_SPACES';

const asyncAction = createAsyncAction<any, GenericResponse>(ns);

const getWorkspacesActions = {
  ...asyncAction,
};

export default getWorkspacesActions;
