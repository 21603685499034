import pathOr from 'ramda/es/pathOr';
import editAdvCustomFieldActions from 'store/actions/editAdvCustomField';
import { Action, AsyncAction } from 'types/Action';
import {
  EditAdvCustomFieldPayload,
  EditAdvCustomFieldState,
} from 'types/store/EditAdvCustomFieldState';

const initState: EditAdvCustomFieldState = {
  advocate_types: undefined,
  field_names: undefined,
  field_values: undefined,
  field_ids: undefined,
  field_valuable_ids: undefined,
};

const DEFAULT_ACTION: Action<EditAdvCustomFieldPayload> = {
  type: '',
  payload: {
    // storyId: '',
  },
};

export default (state = initState, action = DEFAULT_ACTION): any => {
  const localReducer = (): EditAdvCustomFieldPayload => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [editAdvCustomFieldActions.UPDATE]: localReducer(),
    [editAdvCustomFieldActions.CLEAR]: initState,
  });
};
