import { StoryStatus } from 'types/Story';
import i18next from 'i18next';
import { translations } from 'locales/i18n';

import { RencentlyScheduledStories } from '../Overview.props';

export type StoriesProps = {
  companyCalendlyLink?: undefined | null | string;
  rencentlyScheduledStories?: RencentlyScheduledStories[];
  currentTab: string;
  companyIds: string[];
  searchText: string;
  setSelectedStory?: (story: any) => void;
  setSelectedShareContentStory?: (story: any) => void;
  indexTabs: number;
};

export const CALENDLY_DEFAULT_HOST =
  process.env.REACT_APP_CALENDLY_DEFAULT_HOST;

export const storiesToComplete = [
  StoryStatus.IN_PROGRESS,
  StoryStatus.ACCEPTED,
  StoryStatus.APPROVED_WITH_CHANGES,
  StoryStatus.ASSIGNED,
];

export const storiesUnderReview = [StoryStatus.REVIEW, StoryStatus.IN_EDIT];

export const StoryStatuses: any = {
  [i18next.t(translations.ToComplete)]: storiesToComplete,
  [i18next.t(translations.Write2UnderReview)]: storiesUnderReview,
  [i18next.t(translations.ToApprove)]: [StoryStatus.IN_APPROVAL],
  [i18next.t(translations.WriteStoryApproved)]: [StoryStatus.READY_TO_PUBLISH],
  [i18next.t(translations.Write2StoriesPublished)]: [StoryStatus.PUBLISHED],
};

export const statusReponse = [
  storiesToComplete,
  storiesUnderReview,
  [StoryStatus.IN_APPROVAL],
  [StoryStatus.READY_TO_PUBLISH],
  [StoryStatus.PUBLISHED],
];
