import {
  CreateCustomQuestionMeta,
  CreateCustomQuestionPayload,
} from 'types/store/CreateCustomQuestionState';
import { createAsyncReducer } from 'utils/Redux';

import { createCustomQuestionActions } from '../actions';

export default createAsyncReducer<
  CreateCustomQuestionMeta,
  CreateCustomQuestionPayload
>(createCustomQuestionActions);
