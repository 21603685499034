import {
  AdvocateUpdateStoryMeta,
  AdvocateUpdateStoryPayload,
} from 'types/store/AdvocateUpdateStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_UPDATE_STORY_NS';

const asyncAction = createAsyncAction<
  AdvocateUpdateStoryMeta,
  AdvocateUpdateStoryPayload
>(ns);

const advocateUpdateActions = { ...asyncAction };

export default advocateUpdateActions;
