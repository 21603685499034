import { 
  UpdateStripeCustomerDetailsMeta,
  UpdateStripeCustomerDetailsPayload
} from 'types/store/UpdateStripeCustomerDetails';
import { createAsyncReducer } from 'utils/Redux';

import { updateStripeCustomerDetailsActions } from '../actions';

export default createAsyncReducer<UpdateStripeCustomerDetailsMeta,   UpdateStripeCustomerDetailsPayload
>(
  updateStripeCustomerDetailsActions
);
