import {
    GetLibraryAssetsMeta,
    GetLibraryAssetsPayload,
  } from 'types/store/GetLibraryAssetsState';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { getLibraryAssetsActions } from '../actions';
  
  export default createAsyncReducer<GetLibraryAssetsMeta, GetLibraryAssetsPayload>(
    getLibraryAssetsActions
  );
  