import { put, call, takeLatest, select } from 'redux-saga/effects';
import { deleteShowcaseArticle } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  DeleteShowcaseArticleMeta,
  DeleteShowcaseArticlePayload,
} from 'types/store/DeleteShowcaseArticleState';
import { Store } from 'types/store/Store';

import {
  deleteShowcaseArticleActions,
  showcaseArticleActions,
  getShowcaseArticlesActions,
} from '../actions';

function* deleteShowcaseArticleRequest(
  action: AsyncAction<DeleteShowcaseArticleMeta, DeleteShowcaseArticlePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteShowcaseArticle,
        action.meta,
        state.auth.token
      );
      yield put(deleteShowcaseArticleActions.success(data));
    } catch (e: any) {
      yield put(deleteShowcaseArticleActions.failed(e.message));
    }
  }
}

function deleteShowcaseArticleSuccess(
  action: AsyncAction<DeleteShowcaseArticleMeta, DeleteShowcaseArticlePayload>
) {
  // yield put(showcaseArticleActions.clear());
  // yield put(getShowcaseArticlesActions.request({}));
}

function* deleteShowcaseArticleWatcher(): any {
  yield takeLatest(
    deleteShowcaseArticleActions.REQUEST,
    deleteShowcaseArticleRequest
  );
  yield takeLatest(
    deleteShowcaseArticleActions.SUCCESS,
    deleteShowcaseArticleSuccess
  );
}

export default deleteShowcaseArticleWatcher;
