import React from 'react';

import { SVGProps } from './SVG.props';

const Close = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg width={69} height={69} viewBox="0 0 69 69" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M34.81 16.363c.22 0 .438.04.641.118.203.078.387.192.542.335.155.143.277.313.36.5.084.187.126.387.125.59V52.41h-3.336V17.906a1.429 1.429 0 01.124-.59c.084-.187.207-.357.362-.5.155-.144.34-.258.542-.335.203-.078.421-.118.64-.118z"
          fill="#5E6C84"
        />
        <path d="M34.77 14.184h-1.628v40.58h1.627v-40.58z" fill="#344563" />
        <path
          d="M34.77 51.078c4.65-.007 9.221 1.101 13.264 3.214.045.023.08.06.1.105.02.044.024.093.01.14a.218.218 0 01-.083.116.25.25 0 01-.144.046H21.625a.25.25 0 01-.144-.046.217.217 0 01-.084-.117.202.202 0 01.01-.14.225.225 0 01.102-.104c4.041-2.113 8.612-3.22 13.261-3.214z"
          fill="url(#prefix__paint0_linear)"
        />
        <path
          d="M49.843 47.285H23.569a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.815 47.285H23.541a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06H49.82c.262 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.786 47.285H23.512a.85.85 0 01-.316-.061.793.793 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06H49.79c.262 0 .513.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.758 47.285H23.484a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.73 47.285H23.46a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.32-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.208-.06.316-.06h26.274c.262 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.7 47.285H23.44a.852.852 0 01-.324-.058.793.793 0 01-.269-.175l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.791.791 0 01.268-.173.85.85 0 01.32-.06h26.275c.262 0 .513.096.699.267.185.17.289.401.289.642v10.173a.848.848 0 01-.08.347.91.91 0 01-.218.294 1 1 0 01-.324.193c-.12.045-.25.066-.38.065z"
          fill="#FFE380"
        />
        <path
          d="M49.673 47.285H23.398a.85.85 0 01-.315-.061.795.795 0 01-.263-.172l-5.306-5.257a.696.696 0 01-.212-.495c0-.183.075-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.315-.06h26.275c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.846.846 0 01-.08.347.911.911 0 01-.218.294c-.093.083-.204.149-.324.193-.121.044-.25.066-.38.065z"
          fill="#FFE380"
        />
        <path
          d="M49.645 47.285H23.371a.85.85 0 01-.316-.061.792.792 0 01-.262-.172l-5.317-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.264-.172c.1-.04.207-.06.315-.06H49.65c.261 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.615 47.285H23.341a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.699.266.185.17.289.401.289.642v10.173a.875.875 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.588 47.285H23.314a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.56 47.285H23.287a.85.85 0 01-.315-.061.792.792 0 01-.264-.172l-5.316-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.264-.172c.1-.04.207-.06.315-.06h26.275c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.531 47.285H23.257a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.699.266.185.17.289.401.289.642v10.173a.875.875 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.503 47.285H23.229a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.476 47.285H23.203a.85.85 0 01-.315-.061.792.792 0 01-.264-.172l-5.317-5.257a.696.696 0 01-.212-.495c0-.183.075-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.315-.06H49.48c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.446 47.285H23.172a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06H49.45c.262 0 .514.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.418 47.285H23.144a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.389 47.285H23.114a.85.85 0 01-.315-.061.792.792 0 01-.263-.172l-5.317-5.257a.696.696 0 01-.211-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.315-.06h26.275c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.361 47.285H23.087a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .514.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.333 47.285H23.059a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.304 47.285H23.029a.849.849 0 01-.314-.061.793.793 0 01-.262-.172l-5.318-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.315-.06h26.275c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.276 47.285h-26.27a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.32-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06H49.28c.262 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.248 47.285H22.974a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.219 47.285H22.945a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.075-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.191 47.285H22.917a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.163 47.285H22.89a.85.85 0 01-.316-.061.793.793 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.134 47.285H22.86a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.106 47.285H22.832a.85.85 0 01-.315-.061.794.794 0 01-.262-.172l-5.318-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06H49.11c.262 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.078 47.285H22.804a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M49.049 47.285H22.775a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M49.022 47.285H22.747a.85.85 0 01-.316-.061.793.793 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M48.991 47.285H22.717a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.314-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.295.638 1.035 1.035 0 01-.699.26z"
          fill="#FFE380"
        />
        <path
          d="M48.964 47.285H22.69a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M48.937 47.285H22.662a.85.85 0 01-.316-.061.792.792 0 01-.262-.172l-5.317-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.264-.172c.1-.04.207-.06.315-.06H48.94c.263 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M48.906 47.285H22.632a.85.85 0 01-.314-.062.792.792 0 01-.26-.17l-5.32-5.258a.696.696 0 01-.213-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06H48.91c.262 0 .513.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M48.879 47.285H22.605a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M48.852 47.285H22.577a.85.85 0 01-.315-.061.792.792 0 01-.264-.172l-5.316-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.211-.495l5.32-5.268a.792.792 0 01.264-.172c.1-.04.207-.06.315-.06h26.274c.263 0 .514.095.699.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M48.821 47.285h-26.27a.85.85 0 01-.316-.061.793.793 0 01-.263-.172l-5.32-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .514.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M48.794 47.285H22.52a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M48.767 47.285H22.492a.85.85 0 01-.315-.061.792.792 0 01-.263-.172l-5.317-5.257a.696.696 0 01-.211-.495c0-.183.075-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.315-.06h26.275c.261 0 .513.095.698.266.185.17.29.401.29.642v10.173a.874.874 0 01-.295.637 1.034 1.034 0 01-.697.262z"
          fill="#FFE380"
        />
        <path
          d="M48.736 47.285H22.462a.85.85 0 01-.316-.061.792.792 0 01-.263-.172l-5.316-5.257a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.792.792 0 01.263-.172c.1-.04.207-.06.316-.06H48.74c.262 0 .514.095.699.266.185.17.289.401.289.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M48.709 47.285H22.435a.85.85 0 01-.314-.062.793.793 0 01-.261-.17l-5.32-5.258a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="#FFE380"
        />
        <path
          d="M48.709 47.285H22.435a.85.85 0 01-.314-.062.793.793 0 01-.261-.17l-5.32-5.258a.696.696 0 01-.212-.495c0-.183.076-.36.212-.495l5.32-5.268a.793.793 0 01.263-.172c.1-.04.207-.06.316-.06h26.274c.262 0 .513.095.698.266.186.17.29.401.29.642v10.173a.874.874 0 01-.294.637 1.034 1.034 0 01-.698.262z"
          fill="url(#prefix__paint1_linear)"
        />
        <path
          style={{
            mixBlendMode: 'multiply',
          }}
          opacity={0.62}
          d="M22.439 35.305a.849.849 0 00-.316.06c-.1.04-.19.099-.263.172l-5.32 5.268a.696.696 0 00-.212.495c0 .184.076.36.212.495l4.03 3.987a6.565 6.565 0 011.438-1.998c4.5-4.238 12.87.31 20.707-3.515 1.58-.775 3.744-2.18 5.775-4.955l-26.051-.01z"
          fill="url(#prefix__paint2_linear)"
        />
        <path
          d="M46.052 33.062H19.58a.826.826 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.083-.378.232-.514a.827.827 0 01.558-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.796.796 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.08 33.062H19.607a.827.827 0 01-.56-.212.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.108 33.062H19.636a.827.827 0 01-.559-.212.699.699 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.137 33.062H19.665a.827.827 0 01-.559-.212.699.699 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.796.796 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.158 33.063H19.687a.827.827 0 01-.56-.213.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213h26.471c.111-.002.22.017.323.056.102.039.194.097.27.171l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.36-.212.495l-5.32 5.268a.796.796 0 01-.27.17.85.85 0 01-.323.054z"
          fill="#0052CC"
        />
        <path
          d="M46.192 33.062H19.72a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.262.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.075.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.313.054z"
          fill="#0052CC"
        />
        <path
          d="M46.222 33.062H19.75a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.25 33.062H19.776a.827.827 0 01-.558-.212.699.699 0 01-.232-.514V21.813c0-.193.084-.378.232-.514a.827.827 0 01.558-.213H46.25a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.277 33.062H19.805a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.315.06c.1.04.19.1.264.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.212.495l-5.32 5.268a.798.798 0 01-.264.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.307 33.062H19.835a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.826.826 0 01.56-.213h26.47a.85.85 0 01.317.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.796.796 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.334 33.062H19.862a.827.827 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.084-.378.232-.514a.827.827 0 01.559-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.356 33.063H19.884a.827.827 0 01-.559-.213.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472c.11-.002.22.017.322.056.102.039.194.097.27.171l5.32 5.268a.696.696 0 01.212.495c0 .184-.075.36-.212.495l-5.32 5.268a.797.797 0 01-.27.17.85.85 0 01-.322.054z"
          fill="#0052CC"
        />
        <path
          d="M46.392 33.062H19.924a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.797.797 0 01-.267.165.851.851 0 01-.316.053z"
          fill="#0052CC"
        />
        <path
          d="M46.42 33.062H19.946a.826.826 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.084-.378.232-.514a.827.827 0 01.558-.213H46.42a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.796.796 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.45 33.062H19.977a.827.827 0 01-.56-.212.699.699 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.313.054z"
          fill="#0052CC"
        />
        <path
          d="M46.477 33.062H20.005a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.798.798 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.504 33.062H20.032a.827.827 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.083-.378.232-.514a.827.827 0 01.558-.213h26.472a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.534 33.062H20.063a.827.827 0 01-.56-.212.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.798.798 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.554 33.063H20.082a.827.827 0 01-.559-.213.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.56-.213h26.471c.11-.002.22.017.322.056.103.039.195.097.27.171l5.32 5.268a.696.696 0 01.212.495c0 .184-.075.36-.211.495l-5.32 5.268a.796.796 0 01-.271.17.85.85 0 01-.322.054z"
          fill="#0052CC"
        />
        <path
          d="M46.59 33.062H20.12a.827.827 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.083-.378.232-.514a.827.827 0 01.558-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.799.799 0 01-.267.165.852.852 0 01-.316.053z"
          fill="#0052CC"
        />
        <path
          d="M46.62 33.062H20.147a.826.826 0 01-.56-.212.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.826.826 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.796.796 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.647 33.062H20.175a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.674 33.062H20.202a.826.826 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.083-.378.232-.514a.826.826 0 01.558-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.703 33.062H20.232a.826.826 0 01-.56-.212.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.826.826 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.732 33.062H20.26a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.262.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.075.361-.211.495l-5.32 5.268a.796.796 0 01-.265.164.851.851 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.751 33.063H20.28a.827.827 0 01-.56-.213.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213H46.75c.11-.002.22.017.323.056.102.039.194.097.27.171l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.36-.212.495l-5.32 5.268a.797.797 0 01-.27.17.85.85 0 01-.323.054z"
          fill="#0052CC"
        />
        <path
          d="M46.788 33.062H20.317a.827.827 0 01-.56-.212.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.817 33.062H20.345a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.315.06c.1.04.19.1.264.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.313.054z"
          fill="#0052CC"
        />
        <path
          d="M46.846 33.062H20.374a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.873 33.062H20.402a.826.826 0 01-.56-.212.698.698 0 01-.23-.514V21.813c0-.193.083-.378.23-.514a.827.827 0 01.56-.213h26.471a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.796.796 0 01-.265.164.851.851 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.902 33.062H20.43a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.315.06c.1.04.19.1.264.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.212.495l-5.32 5.268a.798.798 0 01-.264.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.93 33.062H20.46a.826.826 0 01-.56-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.56-.213h26.47a.85.85 0 01.317.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.798.798 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M46.949 33.063H20.477a.826.826 0 01-.559-.213.698.698 0 01-.232-.514V21.813c0-.193.084-.378.232-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.322.056c.102.039.194.097.27.171l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.36-.212.495l-5.32 5.268a.798.798 0 01-.27.17.85.85 0 01-.322.054z"
          fill="#0052CC"
        />
        <path
          d="M46.987 33.062H20.515a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.826.826 0 01.559-.213h26.472a.85.85 0 01.315.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.075.361-.212.495l-5.32 5.268a.797.797 0 01-.264.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M47.016 33.062H20.544a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.826.826 0 01.56-.213h26.47a.85.85 0 01.317.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M47.044 33.062H20.576a.826.826 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.083-.378.232-.514a.827.827 0 01.558-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.267.165.852.852 0 01-.316.053z"
          fill="#0052CC"
        />
        <path
          d="M47.072 33.062H20.6a.827.827 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.315.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.798.798 0 01-.265.164.85.85 0 01-.313.054z"
          fill="#0052CC"
        />
        <path
          d="M47.1 33.062H20.63a.827.827 0 01-.56-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.56-.213H47.1a.85.85 0 01.317.06c.1.04.189.1.263.172L53 26.587a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.798.798 0 01-.265.164.85.85 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M47.128 33.062H20.656a.826.826 0 01-.558-.212.698.698 0 01-.232-.514V21.813c0-.193.084-.378.232-.514a.826.826 0 01.558-.213h26.472a.85.85 0 01.316.06c.1.04.19.1.263.172l5.32 5.268a.696.696 0 01.212.495c0 .184-.076.361-.212.495l-5.32 5.268a.797.797 0 01-.265.164.852.852 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M47.157 33.062H20.684a.826.826 0 01-.559-.212.698.698 0 01-.232-.514V21.813c0-.193.084-.378.232-.514a.826.826 0 01.559-.213h26.472a.85.85 0 01.317.058c.1.039.19.096.265.17l5.32 5.267a.696.696 0 01.212.495c0 .184-.076.36-.212.495l-5.32 5.268a.796.796 0 01-.266.168.851.851 0 01-.316.055z"
          fill="#0052CC"
        />
        <path
          d="M47.186 33.062H20.714a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.796.796 0 01-.265.164.851.851 0 01-.314.054z"
          fill="#0052CC"
        />
        <path
          d="M47.186 33.062H20.714a.826.826 0 01-.559-.212.698.698 0 01-.231-.514V21.813c0-.193.083-.378.231-.514a.827.827 0 01.559-.213h26.472a.85.85 0 01.316.06c.1.04.189.1.263.172l5.32 5.268a.696.696 0 01.211.495c0 .184-.075.361-.211.495l-5.32 5.268a.796.796 0 01-.265.164.851.851 0 01-.314.054z"
          fill="url(#prefix__paint3_linear)"
        />
        <path
          d="M34.302 30.15l-3.47-3.079 1.402-1.29 2.068 1.788 4.326-3.94 1.403 1.29-5.729 5.231z"
          fill="#fff"
          stroke="#fff"
          strokeWidth={2}
          strokeLinejoin="round"
        />
        <path
          d="M38.885 53.227c-1.885-.463-3.842-.992-4.115-2.149-4.65-.006-9.22 1.102-13.262 3.215a.225.225 0 00-.1.105.202.202 0 00-.01.14.217.217 0 00.083.117.25.25 0 00.144.045h23.854a169.031 169.031 0 01-6.594-1.473z"
          fill="url(#prefix__paint4_linear)"
        />
        <path
          d="M38.136 39.124l-1.403-1.29-2.36 2.17-2.363-2.17-1.403 1.29 2.361 2.172-2.36 2.171 1.402 1.29 2.363-2.17 2.36 2.17 1.403-1.29-2.36-2.17 2.36-2.173z"
          stroke="#42526E"
          strokeWidth={2}
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={21.388}
          y1={52.889}
          x2={48.154}
          y2={52.889}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0065FF" />
          <stop offset={1} stopColor="#4C9AFF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={16.328}
          y1={41.294}
          x2={49.697}
          y2={41.294}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF991F" />
          <stop offset={1} stopColor="#FFC400" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={12.739}
          y1={40.54}
          x2={49.159}
          y2={40.54}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFC400" />
          <stop offset={1} stopColor="#FFF0B2" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={627.972}
          y1={870.825}
          x2={6264.94}
          y2={870.825}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0065FF" />
          <stop offset={1} stopColor="#4C9AFF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={21.092}
          y1={52.889}
          x2={48.012}
          y2={52.889}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0052CC" />
          <stop offset={0.76} stopColor="#2684FF" />
        </linearGradient>
        <clipPath id="prefix__clip0">
          <path
            fill="#fff"
            transform="translate(16.328 14.184)"
            d="M0 0h36.968v40.58H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Close;
