import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getCategories } from 'services/category';
import { AsyncAction } from 'types/Action';
import {
  GetCategoriesMeta,
  GetCategoriesPayload,
} from 'types/store/GetCategories';
import { Store } from 'types/store/Store';

import { getCategoriesActions } from '../actions';

function* getCategoriesRequest(
  action: AsyncAction<GetCategoriesMeta, GetCategoriesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(getCategories, action.meta, state.auth.token);
      yield put(getCategoriesActions.success(data));
    } catch (e: any) {
      yield put(getCategoriesActions.failed(e.message));
    }
  } else {
    yield put(getCategoriesActions.failed('Token not found'));
  }
}

function* getCategoriesSuccess(
  action: AsyncAction<GetCategoriesMeta, GetCategoriesPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getCategoriesWatcher(): any {
  yield takeLatest(getCategoriesActions.REQUEST, getCategoriesRequest);
  yield takeLatest(getCategoriesActions.SUCCESS, getCategoriesSuccess);
}

export default getCategoriesWatcher;
