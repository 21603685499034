import { DeleteEmailTemplateMeta, DeleteEmailTemplatePayload } from 'types/store/DeleteEmailTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_EMAIL_TEMPLATE_NS';

const asyncAction = createAsyncAction<DeleteEmailTemplateMeta, DeleteEmailTemplatePayload>(ns);

const deleteEmailTemplateActions = { ...asyncAction };

export default deleteEmailTemplateActions;
