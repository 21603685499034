import {
  TranscribeAudioMeta,
  TranscribeAudioPayload,
} from 'types/store/TranscribeAudio';
import { createAsyncReducer } from 'utils/Redux';

import { transcribeAudioActions } from '../actions';

export default createAsyncReducer<TranscribeAudioMeta, TranscribeAudioPayload>(
  transcribeAudioActions
);
