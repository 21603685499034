import {
    GetMagicLinkMeta,
    GetMagicLinkPayload,
  } from 'types/store/GetMagicLink';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { getMagicLinkActions } from '../actions';
  
  export default createAsyncReducer<
  GetMagicLinkMeta,
  GetMagicLinkPayload
  >(getMagicLinkActions);
  