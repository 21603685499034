import {
  GetPagesbyCompanyIdMeta,
  GetPagesbyCompanyIdPayload,
} from 'types/store/GetPagesbyCompanyId';
import { createAsyncReducer } from 'utils/Redux';

import { getPagesbyCompanyIdActions } from '../actions';

export default createAsyncReducer<
  GetPagesbyCompanyIdMeta,
  GetPagesbyCompanyIdPayload
>(getPagesbyCompanyIdActions);
