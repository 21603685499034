import { EmployeeHubState } from 'types/store/EmployeeHubState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'EMPLOYEE_HUB';
const updateAction = createUpdateAction<EmployeeHubState>(ns);
const clearAction = createClearAction(ns);

const employeeHubActions = {
  ...updateAction,
  ...clearAction,
};

export default employeeHubActions;