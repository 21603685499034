import { GetEmailTemplatesMeta, GetEmailTemplatesPayload } from 'types/store/GetEmailTemplates';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_EMAIL_TEMPLATES_NS';

const asyncAction = createAsyncAction<GetEmailTemplatesMeta, GetEmailTemplatesPayload>(ns);

const getEmailTemplatesActions = { ...asyncAction };

export default getEmailTemplatesActions;
