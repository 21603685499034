import { GetContentPackageDiscussionStatusMeta, GetContentPackageDiscussionStatusPayload } from 'types/store/GetContentPackageDiscussionStatus';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_CONTENT_PACKAGE_DISCUSSION_STATUS_NS';

const asyncAction = createAsyncAction<GetContentPackageDiscussionStatusMeta, GetContentPackageDiscussionStatusPayload>(ns);

const getContentPackageDiscussionStatusActions = { ...asyncAction };

export default getContentPackageDiscussionStatusActions;
