import {
    GetListOptionAdvocateMeta,
    GetListOptionAdvocatePayload,
} from 'types/store/GetListOptionAdvocateState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LIST_OPTION_ADVOCATE';

const asyncAction = createAsyncAction<
    GetListOptionAdvocateMeta,
    GetListOptionAdvocatePayload
>(ns);

const getListOptionAdvocateActions = {
    ...asyncAction,
};

export default getListOptionAdvocateActions;
