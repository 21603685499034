import React from 'react';

import { SVGProps } from './SVG.props';

const CloseV2 = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const {
    width = 12,
    height = 12,
    fill = '#070550',
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill='none'
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M2.94945 8.89013C2.87623 8.8169 2.87623 8.69818 2.94945 8.62496L8.62398 2.95043C8.6972 2.8772 8.81592 2.8772 8.88914 2.95043L9.04824 3.10953C9.12147 3.18275 9.12147 3.30147 9.04824 3.37469L3.37371 9.04922C3.30049 9.12245 3.18177 9.12245 3.10855 9.04922L2.94945 8.89013Z" fill={fill} />
      <path d="M3.10856 2.95043C3.18178 2.8772 3.3005 2.8772 3.37372 2.95043L9.04825 8.62496C9.12148 8.69818 9.12148 8.8169 9.04825 8.89012L8.88916 9.04922C8.81593 9.12245 8.69721 9.12245 8.62399 9.04922L2.94946 3.37469C2.87624 3.30147 2.87624 3.18275 2.94946 3.10952L3.10856 2.95043Z" fill={fill} />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.94283 2.7847C3.10758 2.61994 3.3747 2.61994 3.53945 2.7847L5.99885 5.2441L8.45825 2.7847C8.623 2.61995 8.89012 2.61995 9.05487 2.7847L9.21397 2.9438C9.37872 3.10855 9.37873 3.37567 9.21397 3.54042L6.75457 5.99982L9.21398 8.45923C9.37874 8.62398 9.37874 8.8911 9.21398 9.05585L9.05488 9.21495C8.89013 9.3797 8.62302 9.3797 8.45826 9.21495L5.99885 6.75554L3.53944 9.21495C3.37469 9.3797 3.10757 9.3797 2.94282 9.21495L2.78372 9.05585C2.61897 8.8911 2.61897 8.62398 2.78372 8.45923L5.24313 5.99982L2.78373 3.54042C2.61898 3.37566 2.61898 3.10855 2.78373 2.9438L2.94283 2.7847ZM5.57459 6.33128L3.14832 8.75754L3.24113 8.85035L5.6674 6.42408L5.57459 6.33128ZM5.99885 6.09263L5.90604 5.99982L5.99885 5.90701L6.09166 5.99982L5.99885 6.09263ZM6.33031 6.42408L8.75657 8.85035L8.84938 8.75754L6.42312 6.33128L6.33031 6.42408ZM6.42312 5.66836L8.84937 3.24211L8.75656 3.1493L6.33031 5.57555L6.42312 5.66836ZM5.6674 5.57555L5.57459 5.66836L3.14833 3.24211L3.24114 3.1493L5.6674 5.57555ZM3.27428 8.8835C3.27425 8.88347 3.2743 8.88352 3.27428 8.8835V8.8835Z" fill={fill} />
    </svg>
  );
};

export default CloseV2;
