import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface ISetStoryToRecipeMeta {
  recipeId: string;
  storyIds: string[];
  contentPackageId: string;
  unlinkIds: string[];
}

const setStoryToRecipeNS = 'SET_STORY_TO_RECIPE';

const setStoryToRecipe = createAsyncAction<
  ISetStoryToRecipeMeta,
  GenericResponse
>(setStoryToRecipeNS);

export default setStoryToRecipe;
