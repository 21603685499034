import { AddStoryDiscussionCommentMeta, AddStoryDiscussionCommentPayload } from 'types/store/AddStoryDiscussionComment';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADD_STORY_DISCUSSION_COMMENT_NS';

const asyncAction = createAsyncAction<AddStoryDiscussionCommentMeta, AddStoryDiscussionCommentPayload>(ns);

const addStoryDiscussionCommentActions = { ...asyncAction };

export default addStoryDiscussionCommentActions;
