import { put, call, takeLatest, select } from 'redux-saga/effects';
import { ebCommentOnContentPackage } from 'services/content-package';
import { AsyncAction } from 'types/Action';
import { EbCommentOnContentPackageMeta, EbCommentOnContentPackagePayload } from 'types/store/EbCommentOnContentPackage';
import { Store } from 'types/store/Store';

import { ebCommentOnContentPackageActions } from '../actions';

function* ebCommentOnContentPackageRequest(action: AsyncAction<EbCommentOnContentPackageMeta, EbCommentOnContentPackagePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(ebCommentOnContentPackage, action.meta, state.auth.token);
      yield put(ebCommentOnContentPackageActions.success(data));
    } catch (e: any) {
      yield put(ebCommentOnContentPackageActions.failed(e.message));
    }
  }
}

// function* ebCommentOnContentPackageSuccess(action: AsyncAction<EbCommentOnContentPackageMeta, EbCommentOnContentPackagePayload>): any {
//   yield put()
// }

function* ebCommentOnContentPackageWatcher(): any {
  yield takeLatest(ebCommentOnContentPackageActions.REQUEST, ebCommentOnContentPackageRequest);
  // yield takeLatest(ebCommentOnContentPackageActions.SUCCESS, ebCommentOnContentPackageSuccess);
}

export default ebCommentOnContentPackageWatcher;
