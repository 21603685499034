import { put, takeLatest, select } from 'redux-saga/effects';
import { IUserExist } from 'routes/Advocate/StoryDetails/Approval/Rating/Rating.props';
import { RATING } from 'store/actions/rating.action';
import { AsyncAction } from 'types/Action';
import { AuthState } from 'types/store/AuthState';
import { Store } from 'types/store/Store';
import Cookies from 'universal-cookie';

const cookies = new Cookies()


function* watchRatingRequest(action: AsyncAction) {
  const state: AuthState = yield select((_: Store) => _.auth);
  const email: string = yield select((_: Store) => _.getUserById.data?.data.profile.email);
  try {
    const currentDay = Date.now();
    const userLoginInOneDevice: IUserExist[] | undefined = cookies.get('expired-rating');
    const findUserExist: IUserExist | undefined = userLoginInOneDevice?.find((item: IUserExist) => item.email === email)
    let checkTime = 0;
    if (findUserExist) {
      checkTime = Math.round(Math.abs((currentDay) - (findUserExist.expiredRating)) / 8.64e7);
    }
    if (checkTime > 90 && !state.isFirst || !state.isFirst && !findUserExist) {
      yield put({
        type: RATING.RATTING_SUCCESS,
      })
    }
  } catch (e: any) {
    console.log("rating error")
  }
}


function* ratingFeedbackWatcher(): any {
  yield takeLatest(RATING.RATING_REQUEST, watchRatingRequest);
}

export default ratingFeedbackWatcher;
