import {
    UpdateShowcaseFeedbackHighlightMeta,
    UpdateShowcaseFeedbackHighlightPayload,
} from 'types/store/UpdateShowcaseFeedbackHighlightState';
import { createAsyncReducer } from 'utils/Redux';

import { updateShowcaseFeedbackHighlightActions } from '../actions';

export default createAsyncReducer<
    UpdateShowcaseFeedbackHighlightMeta,
    UpdateShowcaseFeedbackHighlightPayload
>(updateShowcaseFeedbackHighlightActions);
