import {
  GetTrendingByCommunityMeta,
  GetTrendingByCommunityPayload,
} from 'types/store/GetTrendingByCommunityState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_TRENDING_BY_COMMUNITY';

const asyncAction = createAsyncAction<
  GetTrendingByCommunityMeta,
  GetTrendingByCommunityPayload
>(ns);

const getTrendingByCommunityActions = {
  ...asyncAction,
};

export default getTrendingByCommunityActions;
