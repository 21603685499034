import { GetStoryVersionOptionsMeta, GetStoryVersionOptionsPayload } from 'types/store/GetStoryVersionOptions';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_VERSION_OPTIONS_NS';

const asyncAction = createAsyncAction<GetStoryVersionOptionsMeta, GetStoryVersionOptionsPayload>(ns);

const getStoryVersionOptionsActions = { ...asyncAction };

export default getStoryVersionOptionsActions;
