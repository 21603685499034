import React from 'react';

import { SVGProps } from './SVG.props';

const LongArrowRight = (props: SVGProps): JSX.Element => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M19.9914 12.3847C20.0925 12.1401 20.0925 11.8655 19.9914 11.621C19.9427 11.4997 19.8706 11.3892 19.7793 11.2957L12.7083 4.22464C12.5207 4.0371 12.2664 3.93174 12.0012 3.93174C11.736 3.93174 11.4816 4.0371 11.2941 4.22464C11.1065 4.41217 11.0012 4.66653 11.0012 4.93174C11.0012 5.19696 11.1065 5.45131 11.2941 5.63885L16.661 11.0058L4.93014 10.9987C4.79813 10.9982 4.66732 11.0238 4.54526 11.0741C4.42319 11.1243 4.31229 11.1983 4.21894 11.2916C4.1256 11.385 4.05166 11.4959 4.00139 11.6179C3.95112 11.74 3.92552 11.8708 3.92606 12.0028C3.92552 12.1348 3.95112 12.2656 4.00139 12.3877C4.05166 12.5098 4.1256 12.6207 4.21894 12.714C4.31229 12.8074 4.42319 12.8813 4.54526 12.9316C4.66732 12.9819 4.79814 13.0075 4.93015 13.0069L16.661 12.9998L11.2941 18.3668C11.1065 18.5543 11.0012 18.8087 11.0012 19.0739C11.0012 19.3391 11.1065 19.5935 11.2941 19.781C11.4816 19.9685 11.736 20.0739 12.0012 20.0739C12.2664 20.0739 12.5207 19.9685 12.7083 19.781L19.7793 12.7099C19.8706 12.6164 19.9427 12.5059 19.9914 12.3847Z" fill="#070550" />
    </svg>


  );
};

export default LongArrowRight;


