import { UpdateStoryDiscussionCommentMeta, UpdateStoryDiscussionCommentPayload } from 'types/store/UpdateStoryDiscussionComment';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STORY_DISCUSSION_COMMENT_NS';

const asyncAction = createAsyncAction<UpdateStoryDiscussionCommentMeta, UpdateStoryDiscussionCommentPayload>(ns);

const updateStoryDiscussionCommentActions = { ...asyncAction };

export default updateStoryDiscussionCommentActions;
