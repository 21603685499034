import { DashboardState } from 'types/store/DashboardState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'DASHBOARD';
const updateAction = createUpdateAction<DashboardState>(ns);
const clearAction = createClearAction(ns);

const dashboardActions = {
  ...updateAction,
  ...clearAction,
};

export default dashboardActions;
