import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAdvocateStoriesEachTab } from 'services/advocate';
import { FEEDBACK_CHECKING } from 'store/actions/rating.action';
import { AsyncAction } from 'types/Action';
import {
  AdvocateStoryOverviewMeta,
  AdvocateStoryOverviewPayload,
} from 'types/store/AdvocateStoryOverview';
import { Store } from 'types/store/Store';
import {
  storiesToComplete,
  storiesUnderReview,
} from 'routes/Advocate/StoryManagement/Overview/Stories/Stories.props';
import { StoryStatus } from 'types/Story';

import {
  advocateStoryOverviewActions,
  advocateStoryOverviewEarchTabsNumberStoriesActions,
} from '../actions';

function* advocateStoryOverviewRequest(
  action: AsyncAction<AdvocateStoryOverviewMeta, AdvocateStoryOverviewPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getAdvocateStoriesEachTab, state.auth.token, {
        ...action.meta,
        tab1: storiesToComplete,
        tab2: storiesUnderReview,
        tab3: [StoryStatus.IN_APPROVAL],
        tab4: [StoryStatus.READY_TO_PUBLISH],
        tab5: [StoryStatus.PUBLISHED],
      });
      yield put(
        advocateStoryOverviewEarchTabsNumberStoriesActions.success(data),
      );
    } catch (e: any) {
      yield put(advocateStoryOverviewActions.failed(e.message));
    }
  }
}

function* advocateStoryOverviewEarchTabsNumberStories(): any {
  yield takeLatest(
    advocateStoryOverviewEarchTabsNumberStoriesActions.REQUEST,
    advocateStoryOverviewRequest,
  );
}

export default advocateStoryOverviewEarchTabsNumberStories;
