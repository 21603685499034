/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconFolder = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666016 3.33333C0.666016 2.22876 1.56145 1.33333 2.66602 1.33333H6.66602C6.88892 1.33333 7.09707 1.44473 7.22072 1.63019L8.35614 3.33333H13.3327C14.4373 3.33333 15.3327 4.22876 15.3327 5.33333V12.6667C15.3327 13.7712 14.4373 14.6667 13.3327 14.6667H2.66602C1.56145 14.6667 0.666016 13.7712 0.666016 12.6667V3.33333Z"
        fill={fill || '#91DBB6'}
      />
    </svg>
  );
};

export default IconFolder;
