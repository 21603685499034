import {
    GetTagsMeta,
    GetTagsPayload,
} from 'types/store/GetTagsState';
import { createAsyncReducer } from 'utils/Redux';

import { getTagsActions } from '../actions';

export default createAsyncReducer<
    GetTagsMeta,
    GetTagsPayload
>(getTagsActions);
