import {
   DefaultCardMeta,
   DefaultCardPayload
  } from 'types/store/UpdateDefaultCard';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { updateDefaultCardActions } from '../actions';
  
  export default createAsyncReducer<DefaultCardMeta, DefaultCardPayload>(
    updateDefaultCardActions
  );
  