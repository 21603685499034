import {
  GetListRoleMeta,
  GetListRolePayload,
} from 'types/store/GetListRole';
import { createAsyncReducer } from 'utils/Redux';

import { getListRoleActions } from '../actions';

export default createAsyncReducer<
  GetListRoleMeta,
  GetListRolePayload
>(getListRoleActions);
