import {
  UpdateFinalStoryMeta,
  UpdateFinalStoryPayload,
} from 'types/store/UpdateFinalStoryState';
import { createAsyncReducer } from 'utils/Redux';

import { updateFinalStoryActions } from '../actions';

export default createAsyncReducer<
  UpdateFinalStoryMeta,
  UpdateFinalStoryPayload
>(updateFinalStoryActions);
