import { ContentPackageState } from 'types/store/ContentPackage';

import {
    getContentPackagesActions,
    createContentPackageActions,
    updateContentPackageActions,
    deleteContentPackageActions,
    getContentPackageInCartActions,
    updateContentPackageInCartActions,
    deleteContentPackageInCartActions,
    getContactSaleInfoShoppingCartActions
} from '../actions';

const DEFAULT_STATE: ContentPackageState = {
    allContentPackages: {
        data: [],
        loading: null,
        error: null,
        status: null,
    },
    create: {
        loading: null,
        error: null,
        status: null,
    },
    update: {
        loading: null,
        error: null,
        status: null,
    },
    delete: {
        loading: null,
        error: null,
        status: null,
    },
    contentPackagesInCart: {
        data: [], 
        loading: null,
        error: null,
        status: null,
    },
    cartInfo: {
        data: {},
        loading: null,
        error: null,
        status: null,
    }
}

const DEFAULT_ACTION: any = {
    type: '',
    payload: {},
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
    switch (action.type) {
        case getContentPackagesActions.REQUEST:
            return {
                ...state,
                allContentPackages: { ...state.allContentPackages, loading: true },
            }
        case getContentPackagesActions.SUCCESS:
            return {
                ...state,
                allContentPackages: {
                    ...state.allContentPackages,
                    loading: false,
                    ...action.payload,
                },
            };
        case getContentPackagesActions.FAILED:
            return {
                ...state,
                allContentPackages: {
                    ...state.allContentPackages,
                    loading: false,
                    error: action.error,
                },
            };
        case getContentPackagesActions.CLEAR:
            return {
                ...state,
                allContentPackages: DEFAULT_STATE.allContentPackages,
            };
        case createContentPackageActions.REQUEST:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: true,
                },
            };
        case createContentPackageActions.SUCCESS:
            return {
                ...state,
                allContentPackages: {
                    ...state.allContentPackages,
                    data: action.payload.data,
                },
                create: {
                    ...state.create,
                    loading: false,
                    status: action.payload.status,
                },
            };
        case createContentPackageActions.FAILED:
            return {
                ...state,
                create: {
                    ...state.create,
                    loading: false,
                    error: action.error,
                },
            };
        case createContentPackageActions.CLEAR:
            return {
                ...state,
                create: DEFAULT_STATE.create,
            };
        case updateContentPackageActions.SUCCESS:
            return {
                ...state,
                allContentPackages: {
                    ...state.allContentPackages,
                    data: action.payload.data,
                    status: action.payload.status,
                },
                update: {
                    ...state.update,
                    loading: false,
                    status: action.payload.status,
                },
            };
        case updateContentPackageActions.FAILED:
            return {
                ...state,
                update: {
                    ...state.update,
                    loading: false,
                    error: action.error,
                },
            };
        case updateContentPackageActions.CLEAR:
            return {
                ...state,
                update: DEFAULT_STATE.update,
            };
        case deleteContentPackageActions.SUCCESS:
            return {
                ...state,
                allContentPackages: {
                    ...state.allContentPackages,
                    data: action.payload.data,
                },
                delete: {
                    ...state.delete,
                    loading: false,
                    status: action.payload.status,
                },
            };
        case deleteContentPackageActions.FAILED:
            return {
                ...state,
                delete: {
                    ...state.delete,
                    loading: false,
                    error: action.error,
                },
            };
        case deleteContentPackageActions.CLEAR:
            return {
                ...state,
                delete: DEFAULT_STATE.delete,
            };
        case getContentPackageInCartActions.SUCCESS:
            return {
                ...state,
                contentPackagesInCart: {
                    ...state.contentPackagesInCart,
                    data: action.payload.data,
                    status: action.payload.status,
                },
            };
        case updateContentPackageInCartActions.SUCCESS:
            return {
                ...state,
                contentPackagesInCart: {
                    ...state.contentPackagesInCart,
                    data: action.payload.data,
                    status: action.payload.status,
                },
                update: {
                    ...state.update,
                    loading: false,
                    status: action.payload.status,
                },
            };
        case deleteContentPackageInCartActions.SUCCESS:
            return {
                ...state,
                contentPackagesInCart: {
                    ...state.contentPackagesInCart,
                    data: action.payload.data,
                    status: action.payload.status,
                },
                delete: {
                    ...state.delete,
                    loading: false,
                    status: action.payload.status,
                },
            };
        case getContactSaleInfoShoppingCartActions.SUCCESS:
            return {
                ...state,
                cartInfo: {
                    ...state.cartInfo,
                    loading: false,
                    status: action.payload.status,
                    data: action.payload.data,
                },
            };
        default:
            return state;
    }
}