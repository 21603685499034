import React from 'react';

import { SVGProps } from './SVG.props';

const RepeatTrans = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill={fill || 'none'}
    >
      <path
        d="M12.4716 1.52827C12.2112 1.26792 11.7891 1.26792 11.5288 1.52827C11.2684 1.78862 11.2684 2.21073 11.5288 2.47108L12.3907 3.33301H4.66683C3.19407 3.33301 2.00016 4.52692 2.00016 5.99967V6.66634C2.00016 7.03453 2.29864 7.33301 2.66683 7.33301C3.03502 7.33301 3.3335 7.03453 3.3335 6.66634V5.99967C3.3335 5.26329 3.93045 4.66634 4.66683 4.66634H12.3907L11.5288 5.52827C11.2684 5.78862 11.2684 6.21073 11.5288 6.47108C11.7891 6.73143 12.2112 6.73143 12.4716 6.47108L14.4716 4.47108C14.7319 4.21073 14.7319 3.78862 14.4716 3.52827L12.4716 1.52827Z"
        fill={fill || '#070550'}
      />
      <path
        d="M4.47157 10.4711C4.73192 10.2107 4.73192 9.78862 4.47157 9.52827C4.21122 9.26792 3.78911 9.26792 3.52876 9.52827L1.52876 11.5283C1.26841 11.7886 1.26841 12.2107 1.52876 12.4711L3.52876 14.4711C3.78911 14.7314 4.21122 14.7314 4.47157 14.4711C4.73192 14.2107 4.73192 13.7886 4.47157 13.5283L3.60964 12.6663H12.0002C13.4729 12.6663 14.6668 11.4724 14.6668 9.99967V9.33301C14.6668 8.96482 14.3684 8.66634 14.0002 8.66634C13.632 8.66634 13.3335 8.96482 13.3335 9.33301V9.99967C13.3335 10.7361 12.7365 11.333 12.0002 11.333H3.60964L4.47157 10.4711Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default RepeatTrans;
