import {
  DeleteShowcaseArticleMeta,
  DeleteShowcaseArticlePayload,
} from 'types/store/DeleteShowcaseArticleState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_SHOWCASE_ARTICLE';

const asyncAction = createAsyncAction<
  DeleteShowcaseArticleMeta,
  DeleteShowcaseArticlePayload
>(ns);

const deleteShowcaseArticleActions = {
  ...asyncAction,
};

export default deleteShowcaseArticleActions;
