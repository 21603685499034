import React from 'react';

import { SVGProps } from './SVG.props';

const SkipCircle = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <rect width='24' height='24' rx='8' fill={fill || '#FFB8AD'} />
      <path
        d='M14.332 8.66732C14.1552 8.66732 13.9857 8.73756 13.8606 8.86258C13.7356 8.9876 13.6654 9.15717 13.6654 9.33398V10.4607L10.9987 8.92065C10.7961 8.80368 10.5663 8.74208 10.3324 8.74202C10.0984 8.74196 9.86861 8.80345 9.66596 8.92031C9.46331 9.03717 9.29497 9.2053 9.17785 9.4078C9.06072 9.6103 8.99894 9.84005 8.9987 10.074V13.9273C8.99894 14.1611 9.06066 14.3908 9.17768 14.5932C9.29469 14.7956 9.46288 14.9637 9.66537 15.0807C9.86806 15.1977 10.098 15.2593 10.332 15.2593C10.5661 15.2593 10.796 15.1977 10.9987 15.0807L13.6654 13.5407V14.6673C13.6654 14.8441 13.7356 15.0137 13.8606 15.1387C13.9857 15.2637 14.1552 15.334 14.332 15.334C14.5088 15.334 14.6784 15.2637 14.8034 15.1387C14.9285 15.0137 14.9987 14.8441 14.9987 14.6673V9.33398C14.9987 9.15717 14.9285 8.9876 14.8034 8.86258C14.6784 8.73756 14.5088 8.66732 14.332 8.66732ZM13.6654 12.0007L10.332 13.9273V10.074L13.6654 11.994V12.0007ZM11.9987 5.33398C10.6802 5.33398 9.39123 5.72498 8.2949 6.45752C7.19857 7.19006 6.34409 8.23125 5.8395 9.44943C5.33492 10.6676 5.2029 12.008 5.46013 13.3013C5.71737 14.5945 6.35231 15.7823 7.28466 16.7147C8.21701 17.647 9.40489 18.282 10.6981 18.5392C11.9913 18.7965 13.3318 18.6644 14.5499 18.1599C15.7681 17.6553 16.8093 16.8008 17.5418 15.7045C18.2744 14.6081 18.6654 13.3192 18.6654 12.0007C18.6654 11.1252 18.4929 10.2583 18.1579 9.44943C17.8229 8.64059 17.3318 7.90566 16.7127 7.28661C16.0937 6.66755 15.3588 6.17649 14.5499 5.84145C13.7411 5.50642 12.8742 5.33398 11.9987 5.33398ZM11.9987 17.334C10.9439 17.334 9.91272 17.0212 9.03566 16.4352C8.1586 15.8491 7.47501 15.0162 7.07134 14.0416C6.66768 13.0671 6.56206 11.9947 6.76785 10.9602C6.97363 9.9256 7.48158 8.9753 8.22746 8.22942C8.97334 7.48354 9.92365 6.97558 10.9582 6.7698C11.9928 6.56401 13.0651 6.66963 14.0397 7.07329C15.0142 7.47696 15.8472 8.16055 16.4332 9.03761C17.0192 9.91467 17.332 10.9458 17.332 12.0007C17.332 13.4151 16.7701 14.7717 15.7699 15.7719C14.7697 16.7721 13.4132 17.334 11.9987 17.334Z'
        fill='#070550'
      />
    </svg>
  );
};

export default SkipCircle;
