import {
  ExtractTextFromUrlMeta,
  ExtractTextFromUrlPayload,
} from 'types/store/ExtractTextFromUrl';
import { createAsyncAction } from 'utils/Redux';

const ns = 'EXTRACT_TEXT_FROM_URL';
const asyncAction = createAsyncAction<
  ExtractTextFromUrlMeta,
  ExtractTextFromUrlPayload
>(ns);

const extractTextFromUrlActions = {
  ...asyncAction,
};

export default extractTextFromUrlActions;
