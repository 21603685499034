import {
  GetUnpaidStoriesMeta,
  GetUnpaidStoriesPayload
} from 'types/store/GetUnpaidStories';
import { createAsyncReducer } from 'utils/Redux';

import { getUnpaidStoriesActions } from '../actions';

export default createAsyncReducer<GetUnpaidStoriesMeta, GetUnpaidStoriesPayload>(
  getUnpaidStoriesActions
);
