import { put, call, takeLatest, select } from 'redux-saga/effects';
import { deleteShowcaseFeedback } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  DeleteShowcaseFeedbackMeta,
  DeleteShowcaseFeedbackPayload,
} from 'types/store/DeleteShowcaseFeedbackState';
import { Store } from 'types/store/Store';

import {
  deleteShowcaseFeedbackActions,
  showcaseArticleActions,
  getShowcaseFeedbacksActions,
} from '../actions';

function* deleteShowcaseFeedbackRequest(
  action: AsyncAction<DeleteShowcaseFeedbackMeta, DeleteShowcaseFeedbackPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        deleteShowcaseFeedback,
        action.meta,
        state.auth.token
      );
      yield put(deleteShowcaseFeedbackActions.success(data));
    } catch (e: any) {
      yield put(deleteShowcaseFeedbackActions.failed(e.message));
    }
  }
}

function* deleteShowcaseFeedbackSuccess(
  action: AsyncAction<DeleteShowcaseFeedbackMeta, DeleteShowcaseFeedbackPayload>
) {
  yield put(showcaseArticleActions.clear());
  yield put(getShowcaseFeedbacksActions.request({}));
}

function* deleteShowcaseFeedbackWatcher(): any {
  yield takeLatest(
    deleteShowcaseFeedbackActions.REQUEST,
    deleteShowcaseFeedbackRequest
  );
  yield takeLatest(
    deleteShowcaseFeedbackActions.SUCCESS,
    deleteShowcaseFeedbackSuccess
  );
}

export default deleteShowcaseFeedbackWatcher;
