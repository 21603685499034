import { GetUserByIdMeta, GetUserByIdPayload } from 'types/store/GetUserById';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_USER_BY_ID';
const asyncAction = createAsyncAction<GetUserByIdMeta, GetUserByIdPayload>(ns);

const getUserByIdActions = {
  ...asyncAction,
};

export default getUserByIdActions;
