import {
  UpdateBrandGuidelinesByCompanyIdMeta,
  UpdateBrandGuidelinesByCompanyIdPayload,
} from 'types/store/UpdateBrandGuidelinesByCompanyId';
import { createAsyncReducer } from 'utils/Redux';

import { updateBrandGuidelinesByCompanyIdActions } from '../actions';

export default createAsyncReducer<
  UpdateBrandGuidelinesByCompanyIdMeta,
  UpdateBrandGuidelinesByCompanyIdPayload
>(updateBrandGuidelinesByCompanyIdActions);
