import React from 'react';

import { SVGProps } from './SVG.props';

const YellowStarUnFill = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="star" clipPath="url(#clip0_208_976)">
        <path
          id="line"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.04825 1.32238C5.34624 0.398237 6.65375 0.398236 6.95174 1.32238L7.78053 3.89265L10.4706 3.89265C11.4375 3.89265 11.8415 5.12837 11.0613 5.69952L8.87846 7.2976L9.71069 9.87852C10.0082 10.8012 8.95044 11.565 8.16823 10.9923L5.99999 9.40493L3.83176 10.9923C3.04955 11.565 1.99179 10.8012 2.2893 9.87852L3.12153 7.2976L0.938671 5.69953C0.15852 5.12838 0.562511 3.89265 1.52939 3.89265H4.21946L5.04825 1.32238ZM6.82878 4.19954L5.99999 1.62927L5.17121 4.19954C5.038 4.61264 4.65351 4.89265 4.21946 4.89265H1.52939L3.71224 6.49072C4.06006 6.74536 4.20556 7.19422 4.07327 7.60449L3.24104 10.1854L5.40928 8.59805C5.761 8.34055 6.23899 8.34055 6.59071 8.59805L8.75894 10.1854L7.92672 7.60449C7.79443 7.19422 7.93993 6.74536 8.28775 6.49072L10.4706 4.89265H7.78053C7.34648 4.89265 6.96199 4.61264 6.82878 4.19954Z"
          fill={fill || '#D9A21F'}
        />
      </g>
      <defs>
        <clipPath id="clip0_208_976">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default YellowStarUnFill;
