import { DiscussionStoriesMeta, DiscussionStoriesPayload } from 'types/store/DiscussionStories';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DISCUSSION_STORIES_NS';

const asyncAction = createAsyncAction<DiscussionStoriesMeta, DiscussionStoriesPayload>(ns);

const discussionStoriesActions = { ...asyncAction };

export default discussionStoriesActions;
