import {
  GetShowcaseArticlesMeta,
  GetShowcaseArticlesPayload,
} from 'types/store/GetShowcaseArticlesState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_ARTICLES';
const asyncAction = createAsyncAction<
  GetShowcaseArticlesMeta,
  GetShowcaseArticlesPayload
>(ns);

const getShowcaseArticlesActions = {
  ...asyncAction,
};

export default getShowcaseArticlesActions;
