import { Action } from 'types/Action'


export enum RATING {
  RATING_REQUEST = '@@_RATING_REQUEST',
  RATTING_SUCCESS = '@@_RATING_SUCCESS',
  RATING_CLOSE = '@@_RATING_CLOSE',
}

export enum FEEDBACK_CHECKING {
  SUCCESS = '@@_FEEDBACK_CHECKING'
}

export const openRatingRequest = (): Action => ({
  type: RATING.RATING_REQUEST,
  payload: {}
})

export const closeRating = (): any => ({
  type: RATING.RATING_CLOSE,
  payload: {}
})
