import {
  GetAppbarInfoMeta,
  GetAppbarInfoPayload,
} from 'types/store/GetAppbarInfoState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_APPBAR_INFO';

const asyncAction = createAsyncAction<GetAppbarInfoMeta, GetAppbarInfoPayload>(
  ns
);
const clearAction = createClearAction(ns);

const getAppbarInfoActions = {
  ...asyncAction,
  ...clearAction,
};

export default getAppbarInfoActions;
