import { put, call, takeLatest, select } from 'redux-saga/effects';
import { setStoryToRecipe } from 'services/recipe-detail';
import { openToast, setStoryToRecipeActions } from 'store/actions';
import { ISetStoryToRecipeMeta } from 'store/actions/recipeDetails/setStoryToRecipe';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* setStoryToRecipeSaga(
  action: AsyncAction<ISetStoryToRecipeMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        setStoryToRecipe,
        action.meta,
        state.auth.token,
      );
      yield put(setStoryToRecipeActions.success(data));
      yield put(openToast('Link story successfully', 'success'));
    } catch (e: any) {
      yield put(
        setStoryToRecipeActions.failed(e.response?.data?.message || e.message),
      );
      yield put(openToast('Error on link story', 'error'));
      console.error(e);
    }
  }
}

function* setStoryToRecipeWatcher(): any {
  yield takeLatest(setStoryToRecipeActions.REQUEST, setStoryToRecipeSaga);
}

export default setStoryToRecipeWatcher;
