import React from 'react';

import { SVGProps } from './SVG.props';

const SEDownload = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="download">
        <g id="line">
          <path d="M7.33329 9.05752V1.33366C7.33329 0.965469 7.63177 0.666992 7.99996 0.666992C8.36815 0.666992 8.66663 0.965469 8.66663 1.33366V9.05752L10.8619 6.86225C11.1222 6.6019 11.5443 6.6019 11.8047 6.86225C12.065 7.1226 12.065 7.54471 11.8047 7.80506L8.47136 11.1384C8.21101 11.3987 7.7889 11.3987 7.52855 11.1384L4.19522 7.80506C3.93487 7.54471 3.93487 7.1226 4.19522 6.86225C4.45557 6.6019 4.87768 6.6019 5.13803 6.86225L7.33329 9.05752Z" fill={fill || '#060548'} />
          <path d="M1.33329 10.0003C1.70148 10.0003 1.99996 10.2988 1.99996 10.667V13.3337C1.99996 13.7018 2.29844 14.0003 2.66663 14.0003H13.3333C13.7015 14.0003 14 13.7018 14 13.3337V10.667C14 10.2988 14.2984 10.0003 14.6666 10.0003C15.0348 10.0003 15.3333 10.2988 15.3333 10.667V13.3337C15.3333 14.4382 14.4379 15.3337 13.3333 15.3337H2.66663C1.56206 15.3337 0.666626 14.4382 0.666626 13.3337V10.667C0.666626 10.2988 0.965103 10.0003 1.33329 10.0003Z" fill={fill || '#060548'} />
        </g>
      </g>
    </svg>
  );
};

export default SEDownload;
