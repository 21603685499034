import {
  GetApprovalsByStoryIdMeta,
  GetApprovalsByStoryIdPayload,
} from 'types/store/GetApprovalsByStoryIdState';
import { createAsyncReducer } from 'utils/Redux';

import { getApprovalsByStoryIdActions } from '../actions';

export default createAsyncReducer<
  GetApprovalsByStoryIdMeta,
  GetApprovalsByStoryIdPayload
>(getApprovalsByStoryIdActions);
