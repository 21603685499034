import { EbGetStoriesEditorMeta, EbGetStoriesEditorPayload } from 'types/store/EbGetStoriesEditor';
import { createAsyncAction } from 'utils/Redux';

const ns = 'EB_GET_STORIES_EDITOR_NS';

const asyncAction = createAsyncAction<EbGetStoriesEditorMeta, EbGetStoriesEditorPayload>(ns);

const ebGetStoriesEditorActions = { ...asyncAction };

export default ebGetStoriesEditorActions;
