import { GetInviteLinkTeamMeta, GetInviteLinkTeamPayload } from 'types/store/GetInviteLinkTeam';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_INVITE_LINK_TEAM_NS';

const asyncAction = createAsyncAction<GetInviteLinkTeamMeta, GetInviteLinkTeamPayload>(ns);

const getInviteLinkTeamActions = { ...asyncAction };

export default getInviteLinkTeamActions;
