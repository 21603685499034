import {
  MarkReadNotificationsStateMeta,
  MarkReadNotificationsStatePayload,
} from 'types/store/MarkReadNotificationsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'MARK_READ_NOTIFICATIONS';
const asyncAction = createAsyncAction<
  MarkReadNotificationsStateMeta,
  MarkReadNotificationsStatePayload
>(ns);

const markReadNotificationsActions = {
  ...asyncAction,
};

export default markReadNotificationsActions;
