import i18next from 'i18next';
import { translations } from 'locales/i18n';
import { put, call, takeLatest, select, delay } from 'redux-saga/effects';
import { getStoryOverview } from 'services/advocate';
import { FEEDBACK_CHECKING } from 'store/actions/rating.action';
import { AsyncAction } from 'types/Action';
import { StoryOverview } from 'types/store/AdvocatePortal';
import {
  AdvocateStoryOverviewMeta,
  AdvocateStoryOverviewPayload,
} from 'types/store/AdvocateStoryOverview';
import { Store } from 'types/store/Store';
import { AdvocateStoryClassifyArray, FinalPieceStatus } from 'types/Story';
import { advocateStoriesClassify } from 'utils/Story';

import { advocateStoryOverviewActions } from '../actions';

function* advocateStoryOverviewRequest(
  action: AsyncAction<AdvocateStoryOverviewMeta, AdvocateStoryOverviewPayload>,
) {
  const state: Store = yield select();
  const storiesListStore: any[] = yield select(
    (state: Store) => state.advocateStoryOverview.data?.data.storiesList || [],
  );
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getStoryOverview,
        action.meta,
        state.auth.token,
      );
      const storiesList = data?.data?.storiesList;
      yield put(
        advocateStoryOverviewActions.success({
          data: {
            storiesList: storiesListStore.concat(storiesList),
            page: data?.data?.pageNumber,
            maxPages: data?.data?.pageCount,
            maxItems: data?.data?.totalRowCount,
          },
        }),
      );
      // if (Array.isArray(storiesList) && storiesList.length > 0) {
      //   const storiesToPublish: StoryOverview[] = [];
      //   const storiesToComplete: StoryOverview[] = [];
      //   const videoStoriesToComplete: StoryOverview[] = [];
      //   const publishedStories: StoryOverview[] = [];
      //   const submittedStories: StoryOverview[] = [];
      //   const storiesToApprovers: StoryOverview[] = [];
      //   const approvedStories: StoryOverview[] = [];

      //   storiesList?.forEach((item: StoryOverview) => {
      //     const useStoryStatus = item.user_story_status;
      //     const storyStatus = item.status;
      //     const storyApprovalVersionDetailStatus =
      //       item.story_approval_version_detail_status;
      //     const sub_type = item.sub_type;
      //     switch (
      //       advocateStoriesClassify(
      //         useStoryStatus,
      //         storyStatus,
      //         sub_type,
      //         storyApprovalVersionDetailStatus,
      //       )
      //     ) {
      //       default:
      //       case AdvocateStoryClassifyArray.approvedStories:
      //         approvedStories.push(item);
      //         break;
      //       case AdvocateStoryClassifyArray.publishedStories:
      //         publishedStories.push(item);
      //         break;
      //       case AdvocateStoryClassifyArray.videoStoriesToComplete:
      //         videoStoriesToComplete.push(item);
      //         break;
      //       case AdvocateStoryClassifyArray.storiesToApprovers:
      //         storiesToApprovers.push(item);
      //         break;
      //       case AdvocateStoryClassifyArray.submittedStories:
      //         submittedStories.push(item);
      //         break;
      //       case AdvocateStoryClassifyArray.storiesToComplete:
      //         storiesToComplete.push(item);
      //         break;
      //     }
      //   });

      //   const storiesAsGroupTemp = [
      //     {
      //       title: i18next.t(translations.Write2StoriesComplete),
      //       stories: storiesToComplete,
      //       code: 0,
      //     },
      //     {
      //       title: i18next.t(translations.Write2UnderReview),
      //       stories: [],
      //       submittedStories: submittedStories,
      //       code: 1,
      //     },
      //     {
      //       title: i18next.t(translations.WriteStoryStoriesToApprove),
      //       stories: storiesToApprovers,
      //       code: 2,
      //     },
      //     {
      //       title: i18next.t(translations.WriteStoryApprovedStories),
      //       stories: approvedStories,
      //       code: 2,
      //     },
      //     {
      //       title: i18next.t(translations.Write2StoriesPublish),
      //       stories: storiesToPublish,
      //       code: 3,
      //     },
      //     {
      //       title: i18next.t(translations.Write2StoriesPublished),
      //       stories: publishedStories,
      //       code: 3,
      //     },
      //     {
      //       title: i18next.t(translations.Write2VideoStories),
      //       stories: videoStoriesToComplete,
      //       code: 3,
      //     },
      //   ];
      //   const storiesAsGroupDisplay = storiesAsGroupTemp.filter(
      //     (group) =>
      //       group.stories.length > 0 ||
      //       (Array.isArray(group?.submittedStories) &&
      //         group.submittedStories.length > 0)
      //   );
      //   yield put(
      //     advocateStoryOverviewActions.success({
      //       data: { storiesList: storiesAsGroupDisplay },
      //     })
      //   );
      // } else {
      //   yield put(
      //     advocateStoryOverviewActions.success({
      //       data: { storiesList: [] },
      //     })
      //   );
      // }
    } catch (e: any) {
      yield put(advocateStoryOverviewActions.failed(e.message));
    }
  }
}

function* advocateStoryOverviewSuccess(
  action: AsyncAction<AdvocateStoryOverviewMeta, AdvocateStoryOverviewPayload>,
) {
  const isShownFeedBack: boolean = yield select(
    (_: Store) => _.ratingFeedBack.isShownFeedBack,
  );
  if (!isShownFeedBack) {
    yield put({
      type: FEEDBACK_CHECKING.SUCCESS,
      payload: Boolean(action.payload.data.storiesList.length),
    });
  }
  // yield put()
}

function* advocateStoryOverviewWatcher(): any {
  yield takeLatest(
    advocateStoryOverviewActions.REQUEST,
    advocateStoryOverviewRequest,
  );
  yield takeLatest(
    advocateStoryOverviewActions.SUCCESS,
    advocateStoryOverviewSuccess,
  );
}

export default advocateStoryOverviewWatcher;
