import { GetCompanyThresholdMeta, GetCompanyThresholdPayload } from 'types/store/GetCompanyThreshold';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_COMPANY_THRESHOLD_NS';

const asyncAction = createAsyncAction<GetCompanyThresholdMeta, GetCompanyThresholdPayload>(ns);

const getCompanyThresholdActions = { ...asyncAction };

export default getCompanyThresholdActions;
