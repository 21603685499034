import {
  UseCreditsMeta,
  UseCreditsPayload
  } from 'types/store/UseCredits';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { useCreditsActions } from '../actions';
  
  export default createAsyncReducer<UseCreditsMeta, UseCreditsPayload>(
    useCreditsActions
  );
  