import {
    CreateNewCustomerMeta,
    CreateNewCustomerPayload
  } from 'types/store/CreateNewCustomer';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { createNewCustomerActions } from '../actions';
  
  export default createAsyncReducer<CreateNewCustomerMeta, CreateNewCustomerPayload>(
    createNewCustomerActions
  );
  