import {
  GetMoreQuestionMeta,
  GetMoreQuestionPayload,
} from 'types/store/GetMoreQuestion';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_MORE_QUESTION';

const asyncAction = createAsyncAction<
  GetMoreQuestionMeta,
  GetMoreQuestionPayload
>(ns);
const clearAction = createClearAction(ns);

const getMoreQuestionActions = { ...asyncAction, ...clearAction };

export default getMoreQuestionActions;
