import { GetAdminsMeta, GetAdminsPayload } from 'types/store/GetAdmins';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADMINS_NS';

const asyncAction = createAsyncAction<GetAdminsMeta, GetAdminsPayload>(ns);

const getAdminsActions = { ...asyncAction };

export default getAdminsActions;
