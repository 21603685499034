import { GenericResponse } from 'types/GenericResponse';
import { createAsyncReducer } from 'utils/Redux';

import updateRemainingCreditActions, {
  UpdateRemainingCreditMeta,
} from '../../actions/settings/updateRemainingCredit';

export default createAsyncReducer<UpdateRemainingCreditMeta, GenericResponse>(
  updateRemainingCreditActions,
);
