import { TranscribeLibraryVideoMeta, TranscribeLibraryVideoPayload } from 'types/store/TranscribeLibraryVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'TRANSCRIBE_LIBRARY_VIDEO_NS';

const asyncAction = createAsyncAction<TranscribeLibraryVideoMeta, TranscribeLibraryVideoPayload>(ns);

const transcribeLibraryVideoActions = { ...asyncAction };

export default transcribeLibraryVideoActions;
