import {
  UpsertStoryApprovalsMeta,
  UpsertStoryApprovalsPayload,
} from 'types/store/UpsertStoryApprovalsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_STORY_APPROVALS';
const asyncAction = createAsyncAction<
  UpsertStoryApprovalsMeta,
  UpsertStoryApprovalsPayload
>(ns);

const upsertStoryApprovalsActions = {
  ...asyncAction,
};

export default upsertStoryApprovalsActions;
