import {
  GetShowcaseArticlesByUrlMeta,
  GetShowcaseArticlesByUrlPayload,
} from 'types/store/GetShowcaseArticlesByUrlState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcaseArticlesByUrlActions } from '../actions';

export default createAsyncReducer<
  GetShowcaseArticlesByUrlMeta,
  GetShowcaseArticlesByUrlPayload
>(getShowcaseArticlesByUrlActions);
