import {
  GetSaveTemplatesCompanyIdMeta,
  GetSaveTemplatesCompanyIdPayload,
} from 'types/store/GetSaveTemplatesByCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SAVE_TEMPLATES_COMPANY_ID';
const asyncAction = createAsyncAction<
  GetSaveTemplatesCompanyIdMeta,
  GetSaveTemplatesCompanyIdPayload
>(ns);

const getSaveTemplatesByCompanyIdActions = {
  ...asyncAction,
};

export default getSaveTemplatesByCompanyIdActions;
