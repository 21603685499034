import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getListeningPathsByCommunity } from 'services/community';
import { AsyncAction } from 'types/Action';
import {
  GetListeningPathsByCommunityMeta,
  GetListeningPathsByCommunityPayload,
} from 'types/store/GetListeningPathsByCommunityState';
import { Store } from 'types/store/Store';

import { getListeningPathsByCommunityActions } from '../actions';

function* getListeningPathsByCommunityRequest(
  action: AsyncAction<
    GetListeningPathsByCommunityMeta,
    GetListeningPathsByCommunityPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getListeningPathsByCommunity,
        action.meta,
        state.auth.token
      );
      yield put(getListeningPathsByCommunityActions.success(data));
      // yield put(getPagesbyCompanyIdActions.success(data));
    } catch (e: any) {
      // yield put(getPagesbyCompanyIdActions.failed(e.message));
    }
  } else {
    // yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* getListeningPathsByCommunityWatcher(): any {
  yield takeLatest(
    getListeningPathsByCommunityActions.REQUEST,
    getListeningPathsByCommunityRequest
  );
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default getListeningPathsByCommunityWatcher;
