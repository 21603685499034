import React from 'react';

import { SVGProps } from './SVG.props';

const Cursor = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 12"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24668 0.97783C2.07497 0.898516 1.88422 0.869743 1.69674 0.894878C1.50927 0.920014 1.33284 0.998017 1.18808 1.11976C1.04332 1.24151 0.936225 1.40196 0.879325 1.58235C0.822424 1.76274 0.818072 1.9556 0.866776 2.13837L3.2126 10.8931C3.45077 11.782 4.66304 11.9005 5.06877 11.0752L6.42073 8.3251C6.51487 8.1342 6.64871 7.96562 6.8133 7.83066C6.97789 7.69569 7.16942 7.59746 7.37507 7.54254L10.4639 6.71489C11.3633 6.47389 11.4696 5.23911 10.6238 4.84919L2.24643 0.976868L2.24668 0.97783ZM4.17507 10.6373L1.82873 1.88061L10.2061 5.75293L7.11731 6.58058C6.77441 6.67241 6.45511 6.83651 6.18083 7.06186C5.90656 7.28722 5.68364 7.56862 5.52703 7.88719L4.17507 10.6373Z"
        fill={fill || 'white'}
      />
    </svg>
  );
};

export default Cursor;
