import {
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload
} from 'types/store/GetHomePageHeaderState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_HOME_PAGE_HEADER';
const asyncAction = createAsyncAction<
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload
>(ns);

const getHomePageHeaderActions = {
  ...asyncAction,
};

export default getHomePageHeaderActions;
