import { put, call, takeLatest, select } from 'redux-saga/effects';
import { loadDocxByFinalStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { StoriesSummary } from 'types/store/GetStoriesByCompanyIdState';
import {
  LoadDocxByFinalStoryMeta,
  LoadDocxByFinalStoryPayload,
} from 'types/store/LoadDocxByFinalStory';
import { Store } from 'types/store/Store';

import {
  getLibraryAssetsActions,
  getStoriesLibraryByCompanyIdActions,
  loadDocxByFinalStoryActions,
} from '../actions';

function* loadDocxByFinalStoryRequest(
  action: AsyncAction<LoadDocxByFinalStoryMeta, LoadDocxByFinalStoryPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        loadDocxByFinalStory,
        action.meta,
        state.auth.token
      );
      if(action.meta.isUpload){
        if(state.getLibraryAssets.data){
          const getLibraryAssetsData = state.getLibraryAssets.data;
          const libraryAssets = [...getLibraryAssetsData.data];
          const index = libraryAssets.findIndex((_: any) => _.id == action.meta.id);
          if (index >= 0) {
            libraryAssets[index].final_story_text = data.data;
            delete libraryAssets[index].asset_preview;
            getLibraryAssetsData.data = libraryAssets;
            yield put(getLibraryAssetsActions.success(getLibraryAssetsData));
          }
        }
        
      }else{
        const getStoriesLibraryByCompanyIdData = state.getStoriesLibraryByCompanyId.data;
        if(getStoriesLibraryByCompanyIdData && getStoriesLibraryByCompanyIdData.data){
          const stories = [...getStoriesLibraryByCompanyIdData.data.stories];
            const index = stories.findIndex((_: StoriesSummary) => _.id == action.meta.id);
            if (index >= 0) {
              stories[index].final_story_text = data.data;
              delete stories[index].final_story_preview;

              // update approval version text_preview;
              const story_approval_versions = stories[index].story_approval_versions;
              let publicVersion = story_approval_versions?.find(p => p.status === 'PUBLISHED');
              if (publicVersion && story_approval_versions) {
                const approvalFile = publicVersion.story_approval_version_files?.find(p => p.status === 'PUBLISHED');
                if (approvalFile) {
                  approvalFile.text_preview = data.data;

                  publicVersion = {
                    ...publicVersion,
                    story_approval_version_files: [
                      ...publicVersion.story_approval_version_files?.filter(p => p.id !== approvalFile.id) || [],
                      approvalFile
                    ]
                  }

                  stories[index] = {
                    ... stories[index],
                    story_approval_versions: [
                      ...story_approval_versions.filter(p => p.id !== publicVersion?.id),
                      publicVersion
                    ]
                  }

                }
              }

              getStoriesLibraryByCompanyIdData.data.stories = stories;
              yield put(getStoriesLibraryByCompanyIdActions.update(getStoriesLibraryByCompanyIdData));
            }
        }
      }
      yield put(loadDocxByFinalStoryActions.success(data));
    } catch (e: any) {
      yield put(loadDocxByFinalStoryActions.failed(e.message));
    }
  }
}

function* loadDocxByFinalStorySuccess(
  action: AsyncAction<LoadDocxByFinalStoryMeta, LoadDocxByFinalStoryPayload>
) {
  // yield put()
}

function* loadDocxByFinalStoryWatcher(): any {
  yield takeLatest(
    loadDocxByFinalStoryActions.REQUEST,
    loadDocxByFinalStoryRequest
  );
  yield takeLatest(
    loadDocxByFinalStoryActions.SUCCESS,
    loadDocxByFinalStorySuccess
  );
}

export default loadDocxByFinalStoryWatcher;
