import React from 'react';

import { SVGProps } from './SVG.props';

const DataSetLine = (props: SVGProps): JSX.Element => {
  const { width = 14, height = 14, fill = '#D9A21F' } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.86258 0.528514C10.1229 0.268165 10.545 0.268165 10.8054 0.528514L13.4721 3.19518C13.7324 3.45553 13.7324 3.87764 13.4721 4.13799L4.13872 13.4713C4.0137 13.5963 3.84413 13.6666 3.66732 13.6666H1.00065C0.632461 13.6666 0.333984 13.3681 0.333984 12.9999V10.3333C0.333984 10.1564 0.404222 9.98687 0.529247 9.86185L9.86258 0.528514ZM9.27679 2.99992L11.0007 4.72378L12.0578 3.66659L10.334 1.94273L9.27679 2.99992ZM10.0578 5.66659L8.33398 3.94273L2.61013 9.66659L4.33398 11.3904L10.0578 5.66659ZM3.39118 12.3333L1.66732 10.6094V12.3333H3.39118Z"
        fill={fill}
      />
    </svg>
  );
};

export default DataSetLine;
