import {
    UpdateShowcaseArticleHighlightMeta,
    UpdateShowcaseArticleHighlightPayload
} from 'types/store/UpdateShowcaseArticleHighlightState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SHOWCASE_ARTICLE_HIGHLIGHT';

const asyncAction = createAsyncAction<
    UpdateShowcaseArticleHighlightMeta,
    UpdateShowcaseArticleHighlightPayload
>(ns);

const updateShowcaseArticleHighlightActions = {
    ...asyncAction,
};

export default updateShowcaseArticleHighlightActions;
