import {
    CreateLibraryMeta,
    CreateLibraryPayload,
  } from 'types/store/CreateLibraryState';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'CREATE_LIBRARY';
  
  const asyncAction = createAsyncAction<CreateLibraryMeta, CreateLibraryPayload>(ns);
  
  const createLibraryActions = {
    ...asyncAction,
  };
  
  export default createLibraryActions;
  