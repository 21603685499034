import React from 'react';

import { useMediaQuery } from 'utils/Theme';
import { useScroll } from 'utils/WindowSize';

import { FloatingAlertProps } from './FloatingAlert.props';
import { Container, AlertBox, AlertText, IconBox } from './FloatingAlert.style';

const FloatingAlert = (props: FloatingAlertProps): JSX.Element => {
  const { text, backgroundColor = '#070550', style, icon } = props;
  const { isTop } = useScroll();

  return (
    <Container>
      <AlertBox style={style} background={backgroundColor}>
        <IconBox>{icon && icon}</IconBox>
        <AlertText variant='caption' color='noshade'>
          {text}
        </AlertText>
      </AlertBox>
    </Container>
  );
};

export default React.memo(FloatingAlert);
