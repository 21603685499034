import {
  GetCategoriesMeta,
  GetCategoriesPayload,
} from 'types/store/GetCategories';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_CATEGORIES';
const asyncAction = createAsyncAction<GetCategoriesMeta, GetCategoriesPayload>(
  ns
);

const getCategoriesActions = {
  ...asyncAction,
};

export default getCategoriesActions;
