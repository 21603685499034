import React from 'react';

import { SVGProps } from './SVG.props';

const CalendarV4 = (props: SVGProps): JSX.Element => {
  const { width = 16, height = 16, fill = '#060548' } = props;
  const viewBox = `0 0 ${width} ${height}`;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={viewBox}
    >
      <path
        fill={fill}
        d="M4.667 9.334a.667.667 0 100-1.334.667.667 0 000 1.334zM8 8.667a.667.667 0 11-1.334 0 .667.667 0 011.334 0zM10 9.334A.667.667 0 1010 8a.667.667 0 000 1.334zM5.333 11.334a.667.667 0 11-1.333 0 .667.667 0 011.333 0zM7.333 12a.667.667 0 100-1.333.667.667 0 000 1.333z"
      ></path>
      <path
        fill={fill}
        fillRule="evenodd"
        d="M5.333 1.334a.667.667 0 00-1.333 0V2H2.667a2 2 0 00-2 2v8.667a2 2 0 002 2h10.666a2 2 0 002-2V4a2 2 0 00-2-2H12v-.666a.667.667 0 10-1.334 0V2H5.333v-.666zM2 4c0-.368.298-.666.667-.666h10.666c.368 0 .667.298.667.666v1.334H2V4zm0 2.667h12v6a.667.667 0 01-.667.667H2.667A.667.667 0 012 12.667v-6z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CalendarV4;
