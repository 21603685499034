import React from 'react';

import { SVGProps } from './SVG.props';

const Comment = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '11'}
      height={height || '12'}
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.24767 8.3248H3.23041L3.21821 8.33701L1.40197 10.1532V2.57699C1.40197 2.30887 1.50848 2.05173 1.69807 1.86213C1.88766 1.67254 2.14481 1.56603 2.41293 1.56603H9.25504C9.52316 1.56603 9.7803 1.67254 9.9699 1.86213C10.1595 2.05173 10.266 2.30887 10.266 2.57699V7.31384C10.266 7.58196 10.1595 7.8391 9.9699 8.0287C9.7803 8.21829 9.52316 8.3248 9.25504 8.3248H3.24767ZM1.3603 11.0397H1.37756L1.38976 11.0275L3.48282 8.93445H9.25504C9.68485 8.93445 10.0971 8.76371 10.401 8.45978C10.7049 8.15586 10.8757 7.74365 10.8757 7.31384V2.57699C10.8757 2.14718 10.7049 1.73497 10.401 1.43105C10.0971 1.12712 9.68485 0.95638 9.25504 0.95638H2.41293C1.98312 0.95638 1.57091 1.12712 1.26698 1.43105C0.963061 1.73497 0.792318 2.14718 0.792318 2.57699V10.998V11.0397H0.833984H1.3603Z"
        fill={fill || '#767676'}
        stroke="#767676"
        strokeWidth="0.0833333"
      />
    </svg>
  );
};

export default Comment;
