import React from 'react';

import { SVGProps } from './SVG.props';

const Bar = (props: SVGProps): JSX.Element => {
  const { height, width } = props;
  return (
    <svg
      width={38 || width}
      height={38 || height}
      viewBox="0 0 38 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.189 37.996H3.126A3.124 3.124 0 010 34.87v-6.742a3.124 3.124 0 013.126-3.126h8.882v-9.868a3.124 3.124 0 013.125-3.126h9.87v25.906h-7.814v.082z"
        fill="#FFC517"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.882 37.995h-9.879V3.125A3.124 3.124 0 0128.13 0h6.744A3.124 3.124 0 0138 3.126V34.87c-.086 1.726-1.47 3.125-3.117 3.125z"
        fill="#F57E02"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.913 25.002v9.868a3.124 3.124 0 01-3.125 3.125h-9.87V12.008l12.995 12.994z"
        fill="#F57E02"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={24.962}
          y1={25.002}
          x2={37.925}
          y2={25.002}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E96F0B" />
          <stop offset={1} stopColor="#F37901" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Bar;
