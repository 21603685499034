import { getFinalLibraryCalendarMeta, getFinalLibraryCalendarPayload } from 'types/store/getFinalLibraryCalendarState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_FINAL_LIBRARY_CANLENDAR';
const asyncAction = createAsyncAction<getFinalLibraryCalendarMeta, getFinalLibraryCalendarPayload>(ns);

const getFinalLibraryCalendarActions = {
  ...asyncAction,
};

export default getFinalLibraryCalendarActions;
