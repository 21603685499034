import { GetEmployeeHubDataMeta, GetEmployeeHubDataPayload } from 'types/store/GetEmployeeHubData';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_EMPLOYEE_HUB_DATA_NS';

const asyncAction = createAsyncAction<GetEmployeeHubDataMeta, GetEmployeeHubDataPayload>(ns);

const getEmployeeHubDataActions = { ...asyncAction };

export default getEmployeeHubDataActions;
