import {
  GetContentPackagesMeta,
  GetContentPackagesPayload,
  CreateContentPackageMeta,
  CreateContentPackagePayload,
  UpdateContentPackageMeta,
  UpdateContentPackagePayload,
  DeleteContentPackageMeta,
  DeleteContentPackagePayload,
  GetContentPackageInCartMeta,
  GetContentPackageInCartPayload,
  UpdateContentPackageInCartMeta,
  UpdateContentPackageInCartPayload,
  DeleteContentPackageInCartMeta,
  DeleteContentPackageInCartPayload,
  EditRecipeInCartMeta,
  EditRecipeInCartPayload,
  GetContactSaleInfoShoppingCartMeta,
  GetContactSaleInfoShoppingCartPayload,
  UpdateContactSaleInfoShoppingCartMeta,
  UpdateContactSaleInfoShoppingCartPayload
} from 'types/store/ContentPackage';
import { createAsyncAction } from 'utils/Redux';

const GET_CONTENT_PACKAGES_NS = 'GET_CONTENT_PACKAGES_NS';
const CREATE_CONTENT_PACKAGE_NS = 'CREATE_CONTENT_PACKAGE_NS';
const UPDATE_CONTENT_PACKAGE_NS = 'UPDATE_CONTENT_PACKAGE_NS';
const DELETE_CONTENT_PACKAGE_NS = 'DELETE_CONTENT_PACKAGE_NS';
const GET_CONTENT_PACKAGE_IN_CART_NS = 'GET_CONTENT_PACKAGE_IN_CART_NS';
const EDIT_CONTENT_PACKAGE_IN_CART_NS = 'EDIT_CONTENT_PACKAGE_IN_CART_NS';
const UPDATE_CONTENT_PACKAGE_IN_CART_NS = 'UPDATE_CONTENT_PACKAGE_IN_CART_NS';
const DELETE_CONTENT_PACKAGE_IN_CART_NS = 'DELETE_CONTENT_PACKAGE_IN_CART_NS';
const GET_CONTACT_SALES_INFO_SHOPPING_CART_NS = 'GET_CONTACT_SALES_INFO_SHOPPING_CART_NS';
const UPDATE_CONTACT_SALES_INFO_SHOPPING_CART_NS = 'UPDATE_CONTACT_SALES_INFO_SHOPPING_CART_NS';

const getContentPackagesActions = {
  ...createAsyncAction<GetContentPackagesMeta, GetContentPackagesPayload>(
    GET_CONTENT_PACKAGES_NS
  ),
};

const createContentPackageActions = {
  ...createAsyncAction<CreateContentPackageMeta, CreateContentPackagePayload>(
    CREATE_CONTENT_PACKAGE_NS
  ),
};

const updateContentPackageActions = {
  ...createAsyncAction<UpdateContentPackageMeta, UpdateContentPackagePayload>(
    UPDATE_CONTENT_PACKAGE_NS
  ),
};

const deleteContentPackageActions = {
  ...createAsyncAction<DeleteContentPackageMeta, DeleteContentPackagePayload>(
    DELETE_CONTENT_PACKAGE_NS
  ),
};

const getContentPackageInCartActions = {
  ...createAsyncAction<
    GetContentPackageInCartMeta,
    GetContentPackageInCartPayload
  >(GET_CONTENT_PACKAGE_IN_CART_NS),
};

const editRecipeInCartActions = {
  ...createAsyncAction<EditRecipeInCartMeta, EditRecipeInCartPayload>(
    EDIT_CONTENT_PACKAGE_IN_CART_NS
  ),
};

const updateContentPackageInCartActions = {
  ...createAsyncAction<
    UpdateContentPackageInCartMeta,
    UpdateContentPackageInCartPayload
  >(UPDATE_CONTENT_PACKAGE_IN_CART_NS),
};

const deleteContentPackageInCartActions = {
  ...createAsyncAction<
    DeleteContentPackageInCartMeta,
    DeleteContentPackageInCartPayload
  >(DELETE_CONTENT_PACKAGE_IN_CART_NS),
};

const getContactSaleInfoShoppingCartActions = {
  ...createAsyncAction<
    GetContactSaleInfoShoppingCartMeta,
    GetContactSaleInfoShoppingCartPayload
  >(GET_CONTACT_SALES_INFO_SHOPPING_CART_NS),
};

const updateContactSaleInfoShoppingCartActions = {
  ...createAsyncAction<
    UpdateContactSaleInfoShoppingCartMeta,
    UpdateContactSaleInfoShoppingCartPayload
  >(UPDATE_CONTACT_SALES_INFO_SHOPPING_CART_NS),
};

export {
  getContentPackagesActions,
  createContentPackageActions,
  updateContentPackageActions,
  deleteContentPackageActions,
  getContentPackageInCartActions,
  editRecipeInCartActions,
  updateContentPackageInCartActions,
  deleteContentPackageInCartActions,
  getContactSaleInfoShoppingCartActions,
  updateContactSaleInfoShoppingCartActions
};
