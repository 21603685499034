import { useState, useEffect } from 'react';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export default function useWindowDimensions(): any {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

export const useScroll = (): any => {
  const [isTop, setIsTop] = useState(false);

  const scrollTo = (options: any) => {
    window.scroll(options);
  };

  useEffect(() => {
    const onScroll = () => {
      if (window.pageYOffset !== 0) {
        setIsTop(false);
      } else {
        setIsTop(true);
      }
    };
    window.addEventListener('scroll', onScroll);
    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  return { isTop, scrollTo };
};

export const useDetectScroll = (): any => {
  const [scrolling, setScrolling] = useState<any>();
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = (e: any) => {
      if (e) {
        setScrollTop(e.target.documentElement.scrollTop);
        setScrolling(Math.random());
      }
    };
    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  return { scrolling };
};

export const getScrollbarSize = (): number => {
  const documentWidth = document.documentElement.clientWidth;
  const scrollbarWidth = Math.abs(window.innerWidth - documentWidth);
  return scrollbarWidth;
};
