import { GenericResponse } from 'types/GenericResponse';

export type UserPlatform = 'ADVOCATE' | 'EMPLOYER' | 'RECRUITER' | string;

export enum PlatForm {
  ADVOCATE = 'ADVOCATE',
  EMPLOYER = 'EMPLOYER',
  RECRUITER = 'RECRUITER',
}

export type LoginMeta = {
  email: string;
  password: string;
  fromSignup?: boolean;
  storyId?: string;
  companyId?: string;
  token?: string;
  logoutSSOUrl?: string;
  is_first?: boolean;
  refresh_token?: string;
};

export type LoginPayload = GenericResponse & {
  fromSignup?: boolean;
};
