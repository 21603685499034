import {
  AdvocateBulkCallMeta,
  AdvocateBulkCallPayload,
} from 'types/store/AdvocateBulkCall';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_BULK_CALL';
const asyncAction = createAsyncAction<
  AdvocateBulkCallMeta,
  AdvocateBulkCallPayload
>(ns);

const advocateBulkCallActions = {
  ...asyncAction,
};

export default advocateBulkCallActions;
