import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getDataRecipeDetail, getRecipeExamples } from 'services/recipe';
import { AsyncAction } from 'types/Action';
import {
  GetDataRecipeDetailMeta,
  GetDataRecipeDetailPayload,
} from 'types/store/GetDataRecipeDetail';
import {
  GetDataRecipeExampleDetailMeta,
  GetDataRecipeExampleDetailPayload,
} from 'types/store/GetDataRecipeExampleDetail';
import { Store } from 'types/store/Store';

import {
  getDataRecipeDetailActions,
  getDataRecipeExampleDetailActions,
} from '../actions';

function* getDataRecipeDetailRequest(
  action: AsyncAction<GetDataRecipeDetailMeta, GetDataRecipeDetailPayload>,
): any {
  const state: Store = yield select();
  try {
    const { data } = yield call(getDataRecipeDetail, action.meta, state.auth.token);
    yield put(getDataRecipeDetailActions.success(data));
  } catch (e: any) {
    yield put(getDataRecipeDetailActions.failed(e.message));
  }
}

function* getDataRecipeExampleDetailRequest(
  action: AsyncAction<
    GetDataRecipeExampleDetailMeta,
    GetDataRecipeExampleDetailPayload
  >,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getRecipeExamples,
        action.meta,
        state.auth.token,
      );
      yield put(getDataRecipeExampleDetailActions.success(data));
    } catch (e: any) {
      yield put(getDataRecipeExampleDetailActions.failed(e.message));
    }
  }
}

// function* getDataRecipeDetailSuccess(action: AsyncAction<GetDataRecipeDetailMeta, GetDataRecipeDetailPayload>): any {
//   yield put()
// }

function* getDataRecipeDetailWatcher(): any {
  yield takeLatest(
    getDataRecipeDetailActions.REQUEST,
    getDataRecipeDetailRequest,
  );
  yield takeLatest(
    getDataRecipeExampleDetailActions.REQUEST,
    getDataRecipeExampleDetailRequest,
  );
  // yield takeLatest(getDataRecipeDetailActions.SUCCESS, getDataRecipeDetailSuccess);
}

export default getDataRecipeDetailWatcher;
