import {
  UpdateFeedbackPositionMeta,
  UpdateFeedbackPositionPayload
} from 'types/store/UpdateFeedbackPosition';
import { createAsyncReducer } from 'utils/Redux';

import { updateFeedbackPositionActions } from '../actions';

export default createAsyncReducer<
  UpdateFeedbackPositionMeta,
  UpdateFeedbackPositionPayload
>(updateFeedbackPositionActions);
