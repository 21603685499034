import Typography from 'components/base/Typography';
import styled from 'utils/styled';

export const AddImageContainer = styled.div`
  position: relative;
  width: 90%;
`;

export const AcceptImageContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 124px;
  transform: translate(0, -50%);
`;

export const AcceptImageTitle = styled(Typography)`
  font-size: 14px;
  line-height: 24px;
  color: ${({ theme }) => theme.grey.shade6};
`;

export const ColorPickerContainer = styled.div`
  margin-top: 4px;
  width: 100%;
  padding: 10px 12px;
  box-sizing: border-box;
  border: 1px solid #ded9db;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
`;

export const ColorBox = styled.div<{
  bgColor: string;
}>`
  width: 32px;
  height: 20px;
  border-radius: 4px;
  background-color: ${({ bgColor }) => bgColor};
  cursor: pointer;
`;

export const ColorName = styled(Typography)`
  margin-left: 4px;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  margin-top: 2px;
  color: ${({ theme }) => theme.grey.shade6};
`;

export const ColorSelector = styled.div`
  position: absolute;
  top: 40px;
  left: 0;
  z-index: ${({ theme }) => theme.zIndex.tooltip};
`;

export const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const LabelContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const SVGContainer = styled.div<{
  horizontalPos: string;
  verticalPos: string;
}>`
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
  position: relative;
  
  .popup {
    width: auto;
    height: auto;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    visibility: hidden;
    position: absolute;
    left: -15px;
    background: ${({ theme }) => theme.grey.shade9};
    z-index: ${({ theme }) => theme.zIndex.tooltip};
    ${({ verticalPos }) => {
    if (verticalPos === 'bottom') {
      return `top: 40px;`
    } else {
      return `bottom: 40px;`
    }
  }}

  &::after {
    content: '';
    width: 12px;
    height: 12px;
    position: absolute;
    left: 15px;
    border-radius: 2px;
    transform: rotate(45deg);
    background: ${({ theme }) => theme.grey.shade9};
    ${({ verticalPos }) => {
    if (verticalPos === 'bottom') {
      return `top: -6px;`
    } else {
      return `bottom: -6px;`
    }
  }}
    }
  }

  &:hover .popup {
      visibility: visible;
  }
`;

export const WideScreen = styled.div`
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.brand.secondary};

  &::before {
      content: '1 : 1';
      font-size: 24px;
      letter-spacing: -.75px;
      color: #fff;
      font-family: 'Objective';
  }
`;

export const Square = styled.div`
  width: 160px;
  height: 90px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme }) => theme.brand.secondary};

  &::before {
      content: '16 : 9';
      font-size: 24px;
      letter-spacing: -.75px;
      color: #fff;
      font-family: 'Objective';
  }
`;