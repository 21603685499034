import { DeleteShowcaseMeta, DeleteShowcasePayload } from 'types/store/DeleteShowcaseState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_SHOWCASE';

const asyncAction = createAsyncAction<DeleteShowcaseMeta, DeleteShowcasePayload>(ns);

const deleteShowcaseActions = {
    ...asyncAction,
};

export default deleteShowcaseActions;
