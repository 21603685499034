export const InitialData = [
  {
    checked: false,
    link: '',
    shortLink: '',
    storyId: '',
    utmSources: {}
  },
]

export const InitialObjectData = {
  link: '',
  checked: false,
  storyId: '',
  utmSources: {},
  shortLink: '',
  status: 'IN_ACTIVE',
  id: ''
}
