import {
  GetHeadlineAIMeta, GetHeadlineAIPayload,
} from 'types/store/GetAdvocateInterest';
import { createAsyncReducer } from 'utils/Redux';

import { getHeadlineAIActions } from '../actions';

export default createAsyncReducer<GetHeadlineAIMeta, GetHeadlineAIPayload>(
  getHeadlineAIActions
);
