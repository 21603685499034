import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getLinkInvite } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  GetInviteLinkMeta,
  GetInviteLinkPayload
} from 'types/store/GetLinkInvite';
import { Store } from 'types/store/Store';

import {
  getLinkInviteActions,
} from '../actions';

function* getLinkInviteRequest(
  action: AsyncAction<GetInviteLinkMeta, GetInviteLinkPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getLinkInvite, state.auth.token);
      yield put(getLinkInviteActions.success(data));
    } catch (e: any) {
      yield put(getLinkInviteActions.failed(e.message));
    }
  }
}

function* getLinkInviteSuccess(
  action: AsyncAction<GetInviteLinkMeta, GetInviteLinkPayload>
) {
  // yield put(getLibraryAssetsActions.clear());
}

function* getLinkInviteWatcher(): any {
  yield takeLatest(getLinkInviteActions.REQUEST, getLinkInviteRequest);
  yield takeLatest(getLinkInviteActions.SUCCESS, getLinkInviteSuccess);
}

export default getLinkInviteWatcher;
