import {
  GetListSmartRecruiterMeta,
  GetListSmartRecruiterPayload,
  GetSRTokenMeta,
  GetSRTokenPayload,
  GetSmartRecruiterMeta,
  GetSmartRecruiterPayload,
} from 'types/store/SmartRecruiter';
import {
  createAsyncAction,
  createUpdateAction,
  createClearAction,
} from 'utils/Redux';

const getListSmartRecruiter = 'GET_LIST_SMART_RECRUITER';
const getSmartRecruiter = 'GET_SMART_RECRUITER';
const getCheckSmartRecruiter = 'GET_CHECK_SMART_RECRUITER';
const getSRToken = 'GET_SR_TOKEN';

const getListSmartRecruiterActions = {
  ...createAsyncAction<GetListSmartRecruiterMeta, GetListSmartRecruiterPayload>(
    getListSmartRecruiter,
  ),
};

const getSmartRecruiterActions = {
  ...createAsyncAction<GetSmartRecruiterMeta, GetSmartRecruiterPayload>(
    getSmartRecruiter,
  ),
};

const getCheckAuthSmartRecruiterActions = {
  ...createAsyncAction<GetSmartRecruiterMeta, GetSmartRecruiterPayload>(
    getCheckSmartRecruiter,
  ),
};

const getSRTokenActions = {
  ...createAsyncAction<GetSRTokenMeta, GetSRTokenPayload>(
    getSRToken,
  ),
};

export {
  getListSmartRecruiterActions,
  getSmartRecruiterActions,
  getCheckAuthSmartRecruiterActions,
  getSRTokenActions,
};
