import theme from 'utils/Theme';
import _get from 'lodash/get';
import { FONT_STYLE, WIDGET_TYPE } from 'types/store/Widget';

const itemIds = (values: any) => {
  return _get(values, 'contentSrc') === 'manual'
    ? values.itemsIds
      ? values.itemsIds.map((item: any) => item.id)
      : []
    : [];
};

const idDefault = (values: any) => {
  return _get(values, 'contentSrc') === 'manual'
    ? _get(values, 'itemsIds[0].id')
    : '';
};

const tags = (values: any) => {
  return _get(values, 'contentSrc') === 'auto'
    ? _get(values, 'tags', []).map((item: any) => item.id)
    : [];
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const landingPage = (values: any, name: string, widgetId: string) => {
  const embed = {
    isCreated: true,
    type: values.landingType,
    name,
    status: 'ACTIVE',
    category: 'LADING_PAGE',
    widgetId,
    setting: {
      themeColor: _get(values, 'colorTheme', theme.altGrey.shade7), //have compare with b.e
      enablement: {
        title: values.title,
        button: values.button,
        advocateName: values.advocateName,
        tag: values.tag,
        role: values.role,
      },
      ctaContent: {
        text: values.mgnButton, //have compare with b.e
        textColor: values.colorButtonMessage, //have compare with b.e
        url: _get(values, 'linkBtn', '') || '#', //have compare with b.e
        color: values.colorButton, //have compare with b.e
      },
      playIcon: {
        bgColor: values.colorBgBtnPlay || theme.altGrey.shade7,
        textColor: values.colorIconBtnPlay || theme.altGrey.shade7,
      }, //have compare with b.e
      layoutStyle: _get(values, 'layoutStyle.value', 1), //have compare with b.e
      contentSetting: {
        source: _get(values, 'contentSrc').toUpperCase(),
        tags: tags(values),
      },
      //font
      font: {
        fontLayout: {
          fontFamily:
            values.font === FONT_STYLE.GOOGLE_FONT
              ? values.fontGoogle?.family
              : values.fontCustome || '',
          fontType: _get(values, 'font', FONT_STYLE.GOOGLE_FONT),
        },
      },
    },
    content: {
      //have compare with b.e
      contentType: 'STORY', //have compare with b.e
      itemsIds: itemIds(values), //have compare with b.e
      idDefault: '', //have compare with b.e
    },
  };
  const popup = {
    isCreated: true,
    type: values.landingType,
    name,
    status: 'ACTIVE',
    category: 'LADING_PAGE',
    widgetId,
    setting: {
      themeColor: _get(values, 'colorTheme', theme.altGrey.shade7), //have compare with b.e
      bubbleButton: {
        textColor: values.colorButtonMessage, //have compare with b.e
        text: values.mgnButton, //have compare with b.e
        color: values.colorButton, //have compare with b.e
      },
      playIcon: {
        bgColor: values.colorBgBtnPlay || theme.altGrey.shade7, //have compare with b.e
        textColor: values.colorIconBtnPlay || theme.altGrey.shade7, //have compare with b.e
      },
      //font
      font: {
        fontLayout: {
          fontFamily:
            values.font === FONT_STYLE.GOOGLE_FONT
              ? values.fontGoogle?.family
              : values.fontCustome,
          fontType: _get(values, 'font', FONT_STYLE.GOOGLE_FONT),
        },
      },
    },
    content: {
      contentType: 'STORY',
      itemsIds: itemIds(values),
      idDefault: idDefault(values), // nhu job description dua vao is is_default
    }, // nhu job description dua vao is is_default
    contentSetting: {
      source: _get(values, 'contentSrc').toUpperCase(),
      tags: tags(values),
    },
  };
  return values.landingType === WIDGET_TYPE.EMBED ? embed : popup;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const jobDescription = (values: any, name: string, widgetId: string) => {
  const embed = {
    isCreated: true,
    type: values.landingType, //have compare with b.e
    name, //have compare with b.e
    status: 'ACTIVE', //have compare with b.e
    category: 'JOB_DESCRIPTION', //have compare with b.e
    widgetId, //have compare with b.e
    setting: {
      themeColor: _get(values, 'colorTheme', theme.altGrey.shade7), //have compare with b.e
      enablement: {
        title: values.title, //have compare with b.e
        button: values.button, //have compare with b.e
        advocateName: values.advocateName, //have compare with b.e
        tag: values.tag, //have compare with b.e
        role: values.role, //have compare with b.e
      },
      ctaContent: {
        text: values.mgnButton, //have compare with b.e
        textColor: values.colorButtonMessage, //have compare with b.e
        url: _get(values, 'linkBtn', '') || '#', //have compare with b.e
        color: _get(values, 'colorButton', '#91DBB6'), //have compare with b.e
      },
      playIcon: {
        bgColor: values.colorBgBtnPlay || theme.altGrey.shade7, //have compare with b.e
        textColor: values.colorIconBtnPlay || theme.altGrey.shade7, //have compare with b.e
      },
      layoutStyle: _get(values, 'layoutStyle.value', 1), //have compare with b.e
      contentSetting: {
        source: _get(values, 'contentSrc').toUpperCase(),
        tags: tags(values),
      },
      //font
      font: {
        fontLayout: {
          fontFamily:
            values.font === FONT_STYLE.GOOGLE_FONT
              ? values.fontGoogle?.family
              : values.fontCustome,
          fontType: _get(values, 'font', FONT_STYLE.GOOGLE_FONT),
        },
      },
    },
    content: {
      contentType: 'STORY',
      itemsIds: itemIds(values),
      idDefault: '', //have compare with b.e
    },
  };
  const popup = {
    isCreated: true,
    type: values.landingType,
    name,
    status: 'ACTIVE',
    category: 'JOB_DESCRIPTION',
    widgetId,
    setting: {
      themeColor: _get(values, 'colorTheme', theme.altGrey.shade7), //have compare with b.e
      bubbleButton: {
        textColor: values.colorButtonMessage, //have compare with b.e
        text: values.mgnButton, //have compare with b.e
        color: values.colorButton, //have compare with b.e
      },
      playIcon: {
        bgColor: values.colorBgBtnPlay || theme.altGrey.shade7, //have compare with b.e
        textColor: values.colorIconBtnPlay || theme.altGrey.shade7, //have compare with b.e
      },
      contentSetting: {
        source: _get(values, 'contentSrc').toUpperCase(),
        tags: tags(values),
      },
      //font
      font: {
        fontLayout: {
          fontFamily:
            values.font === FONT_STYLE.GOOGLE_FONT
              ? values.fontGoogle?.family
              : values.fontCustome,
          fontType: _get(values, 'font', FONT_STYLE.GOOGLE_FONT),
        },
      },
    },
    content: {
      contentType: 'STORY',
      itemsIds: itemIds(values), // compare with content
      idDefault: idDefault(values), // dụa vao bien is_default
    },
  };
  return values.landingType === WIDGET_TYPE.EMBED ? embed : popup;
}; // have completed compare with b.e

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const careerSite = (values: any, name: string, widgetId: string) => {
  const targetExclusion = _get(values, 'exclusion', []).map((item: any) => {
    if (item.pattern) {
      return {
        component: 'URL',
        matchOperation: item.matchOperation,
        negate: true,
        pattern: item.pattern || '/',
      };
    }
  });

  const targetApplyEmbed = _get(values, 'applyEmbed', []).map((item: any) => {
    if (item.pattern) {
      return {
        component: 'URL',
        matchOperation: item.matchOperation,
        negate: false,
        widgetType: item.widgetType,
        pattern: item.pattern || '/',
        // contents: _get(item, 'contents', []).map((idx: any) => ({
        //   id: idx.id,
        //   type: 'STORY',
        // })),
        // tagIds: _get(item, 'tagIds', []).map((idx: any) => idx.id),
      };
    }
  });

  const contentSettingConditions = _get(values, 'applyEmbed', []).map(
    (item: any) => {
      if (item.pattern) {
        return {
          component: 'URL',
          matchOperation: item.matchOperation,
          pattern: _get(item, 'pattern', ''),
          source: _get(item, 'contentSource', 'auto').toUpperCase(),
          tagIds: _get(item, 'tagIds', []),
          contents: _get(item, 'contents', []).map((idx: any) => ({
            id: idx.id,
            type: 'STORY',
          })),
        };
      }
    },
  );

  const popup = {
    isCreated: true,
    type: values.landingType,
    name,
    status: 'ACTIVE',
    category: 'CAREER_SITE',
    widgetId,
    setting: {
      //font
      font: {
        fontLayout: {
          fontFamily:
            values.font === FONT_STYLE.GOOGLE_FONT
              ? values.fontGoogle?.family
              : values.fontCustome,
          fontType: _get(values, 'font', FONT_STYLE.GOOGLE_FONT),
        },
      },
      logo: values.logo,
      themeColor: _get(values, 'colorTheme', theme.altGrey.shade7), //have compare with b.e
      sliders: {
        subTitle: values.subTitlePopup,
        title: values.titlePopup,
        bgImage: values.banner,
      },
      contentHighlights:
        values.contentHighlights?.map((item: any) => ({
          id: item.id,
          type: 'STORY',
        })) || [],

      bubbleButton: {
        textColor: values.colorButtonMessage,
        text: values.mgnButton,
        color: values.colorTheme,
      },
      playIcon: {
        bgColor: values.colorBgBtnPlay || theme.altGrey.shade7, //have compare with b.e
        textColor: values.colorIconBtnPlay || theme.altGrey.shade7, //have compare with b.e
      },
      teamMessage: values.teamMessage || '',
      //testing ctaContent
      cta: {
        text: values.ctaButtonMessage || '',
        textColor: values.ctaColorTextButtonMessage,
        url: _get(values, 'ctaLinkBtn', '#') || '#',
        color: values.ctaColorButton,
      },
      targets: [...targetExclusion, ...targetApplyEmbed],
      contentSetting: {
        source: _get(values, 'contentSrc').toUpperCase(),
        tags: tags(values),
      },
      contentSettingConditions,
      domainAccess: values.domainAccess.map((_: any) => _.pattern),
      headline: _get(values, 'headline', ''),
      // popupStyle: 'WINDOW',
    },
    content: {
      contentType: 'STORY',
      itemsIds: itemIds(values),
      idDefault: _get(values, 'itemsIds[0].id'),
    },
  };
  return popup;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const pageDraft = (name: string, category: string, router: string) => {
  const type = () => {
    switch (category) {
      case 'LADING_PAGE':
        return WIDGET_TYPE.EMBED;
      case 'JOB_DESCRIPTION':
        return WIDGET_TYPE.EMBED;
      default:
        return WIDGET_TYPE.POPUP;
    }
  };
  const dratData = {
    type: type(),
    name: name,
    status: 'DRAFT',
    category,
    setting: {
      //Information Enablement
      title: true,
      button: true,
      advocateName: true,
      tag: true,
      role: true,
    },
    router,
  };
  return dratData;
};

const targetExclusion = (values: any[]) => {
  return values.map((idx: any) => ({
    component: idx.component,
    matchOperation: idx.match_operation,
    pattern: _get(idx, 'pattern', ''),
  }));
};

const targetApplyEmbed = (values: any[]) => {
  return values.map((idx: any) => ({
    component: idx.component,
    matchOperation: idx.match_operation,
    pattern: _get(idx, 'pattern', ''),
    widgetType: _get(idx, 'widget_type', WIDGET_TYPE.EMBED).toLowerCase(),
    contents: _get(idx, 'contents', []),
    tagIds: _get(idx, 'tags', []),
    contentSource: _get(idx, 'source', 'auto'),
  }));
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initFormData = (value: any, listStory: any[]) => {
  if (_get(value, 'widget.type', '') === WIDGET_TYPE.EMBED) {
    return {
      //font
      font: _get(
        value,
        'setting.font.fontLayout.fontType',
        FONT_STYLE.GOOGLE_FONT,
      ),
      fontGoogle: {
        family: _get(value, 'setting.font.fontLayout.fontFamily', ''),
        label: _get(value, 'setting.font.fontLayout.fontFamily', ''),
        value: _get(value, 'setting.font.fontLayout.fontFamily', ''),
      },
      fontCustome:
        _get(
          value,
          'setting.font.fontLayout.fontType',
          FONT_STYLE.GOOGLE_FONT,
        ) === FONT_STYLE.GOOGLE_FONT
          ? ''
          : _get(value, 'setting.font.fontLayout.fontFamily', ''),
      //advance setting
      exclusion: targetExclusion(
        _get(value, 'setting.targets', []).filter((item: any) => item.negate),
      ),
      applyEmbed: targetApplyEmbed(
        _get(value, 'setting.content_setting_conditions', []),
      ),
      //domain_access,
      domainAccess: _get(value, 'setting.domain_access', []).map(
        (_: string) => ({
          pattern: _,
          isOpen: false,
        }),
      ),
      //color play icon
      colorIconBtnPlay: _get(value, 'setting.play_icon.text', '#070550'),
      colorBgBtnPlay: _get(value, 'setting.play_icon.bg', '#91DBB6'),

      //theme color
      colorTheme: _get(value, 'setting.theme_color', '#070550'),

      //link button -- cta-content
      linkBtn: _get(value, 'setting.cta_content.url', ''),
      mgnButton: _get(value, 'setting.cta_content.text', ''),
      colorButton: _get(value, 'setting.cta_content.bg_color', '#91DBB6'),
      colorButtonMessage: _get(
        value,
        'setting.cta_content.text_color',
        '#070550',
      ),

      //Information Enablement
      title: _get(value, 'setting.enablement.title', true),
      button: _get(value, 'setting.enablement.button', true),
      advocateName: _get(value, 'setting.enablement.advocate_name', true),
      tag: _get(value, 'setting.enablement.tag', true),
      role: _get(value, 'setting.enablement.role', true),

      landingType: _get(value, 'widget.type', ''),

      //Content source
      contentSrc: _get(
        value,
        'setting.content_setting.source',
        'MANUAL',
      ).toLowerCase(),
      layoutStyle: {
        label: `${_get(value, 'setting.layout_style', 1)} ${
          _get(value, 'setting.layout_style', 1) >= 1 ? 'Story' : 'Stories'
        }`,
        value: _get(value, 'setting.layout_style', 1),
      },
      //story content
      itemsIds: listStory || [],
      tags: _get(value, 'setting.content_setting.tags', []),
      //snippet code
      widgetCodeID: _get(value, 'widget.code', ''),
      widgetCodeEmbed: _get(value, 'widgetCodeEmbed', ''),
      widgetCodePopup: _get(value, 'widgetCodePopup', ''),

      visualCard: 'card',
      //team message
      teamMessage: _get(value, 'setting.team_message', ''),
    };
  }

  const formData = {
    //font
    font: _get(
      value,
      'setting.font.fontLayout.fontType',
      FONT_STYLE.GOOGLE_FONT,
    ),
    fontGoogle: {
      family: _get(value, 'setting.font.fontLayout.fontFamily', ''),
      label: _get(value, 'setting.font.fontLayout.fontFamily', ''),
      value: _get(value, 'setting.font.fontLayout.fontFamily', ''),
    },
    fontCustome:
      _get(
        value,
        'setting.font.fontLayout.fontType',
        FONT_STYLE.GOOGLE_FONT,
      ) === FONT_STYLE.GOOGLE_FONT
        ? ''
        : _get(value, 'setting.font.fontLayout.fontFamily', ''),

    //advance setting
    exclusion: targetExclusion(
      _get(value, 'setting.targets', []).filter((item: any) => item.negate),
    ),
    applyEmbed: targetApplyEmbed(
      _get(value, 'setting.content_setting_conditions', []),
    ),
    //domain_access,
    domainAccess: _get(value, 'setting.domain_access', []).map((_: string) => ({
      pattern: _,
      isOpen: false,
    })),

    //theme color
    colorTheme: _get(value, 'setting.theme_color', '#070550'),

    //play icon color
    colorIconBtnPlay: _get(value, 'setting.play_icon.text', '#070550'),
    colorBgBtnPlay: _get(value, 'setting.play_icon.bg', '#91DBB6'),

    //button color setting
    colorButton: _get(value, 'setting.bubble_button.color', '#91DBB6'),
    colorButtonMessage: _get(
      value,
      'setting.bubble_button.text_color',
      '#070550',
    ),
    mgnButton: _get(value, 'setting.bubble_button.text', ''),

    //story
    itemsIds: listStory || [],

    //Content source
    contentSrc: _get(
      value,
      'setting.content_setting.source',
      'MANUAL',
    ).toLowerCase(),

    //Information Enablement
    title:
      _get(value, 'widget.type', '') === WIDGET_TYPE.EMBED &&
      _get(value, 'widget.category', '') === 'LADING_PAGE'
        ? _get(value, 'setting.enablement.title', true)
        : true,
    button:
      _get(value, 'widget.type', '') === WIDGET_TYPE.EMBED &&
      _get(value, 'widget.category', '') === 'LADING_PAGE'
        ? _get(value, 'setting.enablement.button', true)
        : true,
    advocateName:
      _get(value, 'widget.type', '') === WIDGET_TYPE.EMBED &&
      _get(value, 'widget.category', '') === 'LADING_PAGE'
        ? _get(value, 'setting.enablement.advocate_name', true)
        : true,
    tag:
      _get(value, 'widget.type', '') === WIDGET_TYPE.EMBED &&
      _get(value, 'widget.category', '') === 'LADING_PAGE'
        ? _get(value, 'setting.enablement.tag', true)
        : true,
    role:
      _get(value, 'widget.type', '') === WIDGET_TYPE.EMBED &&
      _get(value, 'widget.category', '') === 'LADING_PAGE'
        ? _get(value, 'setting.enablement.role', true)
        : true,

    landingType: _get(value, 'widget.type', ''),

    //logo
    logo: _get(value, 'setting.logo', ''),
    //banner
    banner: _get(value, 'setting.sliders.bgImage', ''),

    //snipet
    widgetCodeID: _get(value, 'widget.code', ''),
    widgetCodeEmbed: _get(value, 'widgetCodeEmbed', ''),
    widgetCodePopup: _get(value, 'widgetCodePopup', ''),
    visualCard: 'card',

    //contentHighlights
    contentHighlights: _get(value, 'setting.content_highlights', []).map(
      (item: any) => ({
        ...item,
        ...item.detail,
      }),
    ),

    //team message
    teamMessage: _get(value, 'setting.team_message', ''),

    //cta button
    ctaColorButton: _get(value, 'setting.cta.bg_color', '#91DBB6'),
    ctaColorTextButtonMessage: _get(value, 'setting.cta.text_color', '#070550'),
    ctaButtonMessage: _get(value, 'setting.cta.text', ''),
    ctaLinkBtn: _get(value, 'setting.cta.url', ''),

    titlePopup: _get(value, 'setting.sliders.title', 'Enter a Text'),
    subTitlePopup: _get(value, 'setting.sliders.subTitle', 'SUBTITLE'),
    // tags: _get(value, 'setting.content_setting.tags', []),
    tags: _get(value, 'setting.content_setting.tags', []),
    headline: _get(value, 'setting.headline', ''),
  };
  return formData;
};
