import {
    GetShareSocialStoryStateMeta,
    GetShareSocialStoryStatePayload,
} from 'types/store/getShareSocialStoryState';
import { createAsyncReducer } from 'utils/Redux';

import { getShareSocialStoryActions } from '../actions';

export default createAsyncReducer<
    GetShareSocialStoryStateMeta,
    GetShareSocialStoryStatePayload
>(getShareSocialStoryActions);
