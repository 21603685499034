import {
  GetPagesbyCompanyIdMeta,
  GetPagesbyCompanyIdPayload,
} from 'types/store/GetPagesbyCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_PAGES_COMPANY_ID';
const asyncAction = createAsyncAction<
  GetPagesbyCompanyIdMeta,
  GetPagesbyCompanyIdPayload
>(ns);

const getPagesbyCompanyIdActions = {
  ...asyncAction,
};

export default getPagesbyCompanyIdActions;
