import { InviteToShareStoryMeta, InviteToShareStoryPayload } from 'types/store/InviteToShareStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'INVITE_TO_SHARE_STORY_NS';

const asyncAction = createAsyncAction<InviteToShareStoryMeta, InviteToShareStoryPayload>(ns);

const inviteToShareStoryActions = { ...asyncAction };

export default inviteToShareStoryActions;
