import React from 'react';

import { SVGProps } from './SVG.props';

const ChevronUp = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 12}
      height={height || 8}
      viewBox='0 0 12 8'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M10.9997 5.40982L6.7097 1.16982C6.61674 1.07609 6.50614 1.0017 6.38428 0.950931C6.26242 0.900162 6.13172 0.874023 5.9997 0.874023C5.86769 0.874023 5.73699 0.900162 5.61513 0.950931C5.49327 1.0017 5.38267 1.07609 5.2897 1.16982L1.0497 5.40982C0.955976 5.50279 0.881582 5.61339 0.830813 5.73525C0.780044 5.8571 0.753906 5.98781 0.753906 6.11982C0.753906 6.25183 0.780044 6.38254 0.830813 6.5044C0.881582 6.62626 0.955976 6.73686 1.0497 6.82982C1.23707 7.01607 1.49052 7.12062 1.7547 7.12062C2.01889 7.12062 2.27234 7.01607 2.4597 6.82982L5.9997 3.28982L9.53971 6.82982C9.72597 7.01457 9.97737 7.11872 10.2397 7.11982C10.3713 7.12058 10.5018 7.09536 10.6236 7.04559C10.7455 6.99583 10.8563 6.9225 10.9497 6.82982C11.0468 6.7402 11.1251 6.63224 11.1802 6.51216C11.2353 6.39209 11.2661 6.26228 11.2708 6.13025C11.2754 5.99821 11.2539 5.86656 11.2073 5.74291C11.1608 5.61926 11.0902 5.50605 10.9997 5.40982Z'
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default ChevronUp;
