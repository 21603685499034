import {
    GetDataShowcasePublicHomepageMeta,
    GetDataShowcasePublicHomepagePayload,
} from 'types/store/GetDataShowcasePublicHomepageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_DATA_SHOWCASE_PUBLIC_HOMEPAGE';
const asyncAction = createAsyncAction<
    GetDataShowcasePublicHomepageMeta,
    GetDataShowcasePublicHomepagePayload
>(ns);

const getDataShowcasePublicHomepageActions = {
    ...asyncAction,
};

export default getDataShowcasePublicHomepageActions;
