import {
    GetOwnPageDetailsMeta,
    GetOwnPageDetailsPayload
} from 'types/store/getOwnPageDetailsState';
import { createAsyncReducer } from 'utils/Redux';

import { getOwnPageDetailsActions } from '../actions';

export default createAsyncReducer<
    GetOwnPageDetailsMeta,
    GetOwnPageDetailsPayload
>(getOwnPageDetailsActions);