import React from 'react';

// import { useTheme } from 'utils/Theme';
import { TickBoxProps } from './TickBox.props';
import {
  Container,
  HiddenTickBox,
  CustomTickBox,
  InnerTick,
  Label,
} from './TickBox.style';

const TickBox = ({
  checked = false,
  label,
  size = 20,
  scale = 1,
  disabled = false,
  typographyProps,
  ...props
}: TickBoxProps): JSX.Element => {
  return (
    <Container className='checkbox' onClick={props.onClick}>
      <HiddenTickBox
        onChange={(event) => event.stopPropagation()}
        checked={checked}
        type="checkbox"
        {...props}
      />
      <CustomTickBox checked={checked} size={size} disabled={disabled}>
        {checked && <InnerTick scale={scale} disabled={disabled} />}
      </CustomTickBox>
      {label && (
        <Label {...typographyProps} variant="label">
          {label}
        </Label>
      )}
    </Container>
  );
};

export default React.memo(TickBox);
