import { GetContentPackageDiscussionByIdAndEmailMeta, GetContentPackageDiscussionByIdAndEmailPayload } from 'types/store/GetContentPackageDiscussionByIdAndEmail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_CONTENT_PACKAGE_DISCUSSION_BY_ID_AND_EMAIL_NS';

const asyncAction = createAsyncAction<GetContentPackageDiscussionByIdAndEmailMeta, GetContentPackageDiscussionByIdAndEmailPayload>(ns);

const getContentPackageDiscussionByIdAndEmailActions = { ...asyncAction };

export default getContentPackageDiscussionByIdAndEmailActions;
