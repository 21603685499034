import {
  GetShowcaseCompanyByUrlMeta,
  GetShowcaseCompanyByUrlPayload,
} from 'types/store/GetShowcaseCompanyByUrlState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcaseCompanyByUrlActions } from '../actions';

export default createAsyncReducer<
  GetShowcaseCompanyByUrlMeta,
  GetShowcaseCompanyByUrlPayload
>(getShowcaseCompanyByUrlActions);
