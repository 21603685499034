import {
  GetShowcaseFeedbacksByUrlMeta,
  GetShowcaseFeedbacksByUrlPayload,
} from 'types/store/GetShowcaseFeedbacksByUrlState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_FEEDBACKS_BY_URL';
const asyncAction = createAsyncAction<
  GetShowcaseFeedbacksByUrlMeta,
  GetShowcaseFeedbacksByUrlPayload
>(ns);

const getShowcaseFeedbacksByUrlActions = {
  ...asyncAction,
};

export default getShowcaseFeedbacksByUrlActions;
