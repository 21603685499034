import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStoryVersionOptions } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { GetStoryVersionOptionsMeta, GetStoryVersionOptionsPayload } from 'types/store/GetStoryVersionOptions';
import { Store } from 'types/store/Store';

import { getStoryVersionOptionsActions } from '../actions';

function* getStoryVersionOptionsRequest(action: AsyncAction<GetStoryVersionOptionsMeta, GetStoryVersionOptionsPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStoryVersionOptions, action.meta, state.auth.token);
      yield put(getStoryVersionOptionsActions.success(data));
    } catch (e: any) {
      yield put(getStoryVersionOptionsActions.failed(e.message));
    }
  }
}

// function* getStoryVersionOptionsSuccess(action: AsyncAction<GetStoryVersionOptionsMeta, GetStoryVersionOptionsPayload>): any {
//   yield put()
// }

function* getStoryVersionOptionsWatcher(): any {
  yield takeLatest(getStoryVersionOptionsActions.REQUEST, getStoryVersionOptionsRequest);
  // yield takeLatest(getStoryVersionOptionsActions.SUCCESS, getStoryVersionOptionsSuccess);
}

export default getStoryVersionOptionsWatcher;
