import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateRemainingCredit } from 'services/payment';
import {
  updateRemainingCreditActions,
  getCompanyCreditsActions,
} from 'store/actions';
import { UpdateRemainingCreditMeta } from 'store/actions/settings/updateRemainingCredit';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* updateRemainingCreditRequest(
  action: AsyncAction<UpdateRemainingCreditMeta, GenericResponse>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateRemainingCredit,
        action.meta,
        state.auth.token,
      );
      yield put(updateRemainingCreditActions.success(data));
    } catch (e: any) {
      const errorResponse = e?.response;
      yield put(
        updateRemainingCreditActions.failed(errorResponse.data?.message),
      );
    }
  }
}

function* updateCompanyCredits() {
  const state: Store = yield select();
  const companyCreditResponse = state.getCompanyCredits.data;
  yield put(
    getCompanyCreditsActions.success({
      ...companyCreditResponse,
      data: {
        ...companyCreditResponse?.data,
        credits: state.updateRemainingCredit.data?.data.balance,
      },
    }),
  );
}

function* updateRemainingCreditWatcher(): any {
  yield takeLatest(
    updateRemainingCreditActions.REQUEST,
    updateRemainingCreditRequest,
  );
  yield takeLatest(updateRemainingCreditActions.SUCCESS, updateCompanyCredits);
}

export default updateRemainingCreditWatcher;
