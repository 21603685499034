import React from 'react';

import { SVGProps } from './SVG.props';

const SVGComponent = (props: SVGProps): JSX.Element => (
  <svg width={34} height={42} fill='none' {...props}>
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M27.809 11.386A10.768 10.768 0 0 1 17 22.191 10.77 10.77 0 0 1 6.191 11.386C6.191 5.39 11.001.583 17 .583c6 0 10.809 4.807 10.809 10.803zM17 41.416c-8.856 0-16.333-1.439-16.333-6.992C.667 28.869 8.19 27.48 17 27.48c8.858 0 16.333 1.44 16.333 6.993 0 5.556-7.524 6.944-16.333 6.944z'
      fill='#070550'
    />
  </svg>
);

export default SVGComponent;
