import {
  UpdateEmployeeProfileByAuthMeta,
  UpdateEmployeeProfileByAuthPayload,
} from 'types/store/UpdateEmployeeProfileByAuth';
import { createAsyncReducer } from 'utils/Redux';

import { updateEmployeeProfileByAuthActions } from '../actions';

export default createAsyncReducer<
  UpdateEmployeeProfileByAuthMeta,
  UpdateEmployeeProfileByAuthPayload
>(updateEmployeeProfileByAuthActions);
