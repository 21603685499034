import { put, call, takeLatest, select } from 'redux-saga/effects';
import { markStoryAsPublished } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { MarkStoryAsPublishedMeta, MarkStoryAsPublishedPayload } from 'types/store/MarkStoryAsPublished';
import { Store } from 'types/store/Store';

import { markStoryAsPublishedActions } from '../actions';

function* markStoryAsPublishedRequest(action: AsyncAction<MarkStoryAsPublishedMeta, MarkStoryAsPublishedPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(markStoryAsPublished, action.meta, state.auth.token);
      yield put(markStoryAsPublishedActions.success(data));
      location.reload();
    } catch (e: any) {
      yield put(markStoryAsPublishedActions.failed(e.message));
    }
  }
}

// function* markStoryAsPublishedSuccess(action: AsyncAction<MarkStoryAsPublishedMeta, MarkStoryAsPublishedPayload>): any {
//   yield put()
// }

function* markStoryAsPublishedWatcher(): any {
  yield takeLatest(markStoryAsPublishedActions.REQUEST, markStoryAsPublishedRequest);
  // yield takeLatest(markStoryAsPublishedActions.SUCCESS, markStoryAsPublishedSuccess);
}

export default markStoryAsPublishedWatcher;
