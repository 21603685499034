import {
  GetReviewedStoriesMeta,
  GetReviewedStoriesPayload,
} from 'types/store/GetReviewedStoriesState';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_REVIEWED_STORIES';

const asyncAction = createAsyncAction<
  GetReviewedStoriesMeta,
  GetReviewedStoriesPayload
>(ns);
const clearAction = createClearAction(ns);

const getReviewedStoriesActions = {
  ...asyncAction,
  ...clearAction,
};

export default getReviewedStoriesActions;
