import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { GenericResponse } from 'types/GenericResponse';
import { ChangePasswordEBMeta } from 'types/store/ChangePasswordEBState';
import { CheckEbInviteMeta } from 'types/store/CheckEbInvite';
import { GetAppbarInfoMeta } from 'types/store/GetAppbarInfoState';
import { GetEmployeeProfileByAuthMeta } from 'types/store/GetEmployeeProfileByAuth';
import { RegisterEbWithExistedAccountMeta } from 'types/store/RegisterEbWithExistedAccount';
import { SendEmployerBrandingInvitationMeta } from 'types/store/SendEmployerBrandingInvitation';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const EMPLOYEE_URL = `${BASE_URL}/employee`;

export const getEmployeProfileByAuth = (
  data: GetEmployeeProfileByAuthMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/getEmployeeProfileByAuth`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const changePassword = (
  data: ChangePasswordEBMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${EMPLOYEE_URL}/changePassword`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmployeeProfileByAuth = (
  data: GetEmployeeProfileByAuthMeta,
  token: string
): any => {
  const fd = new FormData();
  if (data.profilePicture !== undefined) {
    fd.append('profile_picture', data.profilePicture);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${EMPLOYEE_URL}/updateEmployeeProfileByAuth`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};

export const sendEmployerBrandingInvitation = (
  data: SendEmployerBrandingInvitationMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${EMPLOYEE_URL}/sendEmployerBrandingInvitation`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAppbarInfo = (data: GetAppbarInfoMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${EMPLOYEE_URL}/getAppbarInfo`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const checkEBInvite = (data: CheckEbInviteMeta): any => {
  return axios({
    method: 'post',
    url: `${EMPLOYEE_URL}/checkEBInvite`,
    data: data,
  });
};

export const registerEbWithExistedAccount = (
  data: RegisterEbWithExistedAccountMeta
): any => {
  return axios({
    method: 'post',
    url: `${EMPLOYEE_URL}/signUpEbWithExistedAccount`,
    data: data,
  });
};

export const ratingPlatform = (rating: number, token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/product/rating`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: { rating }
  })
}

export const feedBackPlatform = (data: {
  scored: number,
  feedback: string
}, token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/product/feedback`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  })
}

export const feedbackStory = (data: {
  scored: number,
  feedback: string,
  storyId: string
}, token: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/feedback/story`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data
  })
}
