import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateSfpSkippedAll } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateSfpSkippedAllMeta, UpdateSfpSkippedAllPayload } from 'types/store/UpdateSfpSkippedAll';

import { updateSfpSkippedAllActions } from '../actions';

function* updateSfpSkippedAllRequest(action: AsyncAction<UpdateSfpSkippedAllMeta, UpdateSfpSkippedAllPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateSfpSkippedAll, action.meta, state.auth.token);
      yield put(updateSfpSkippedAllActions.success(data));
    } catch (e: any) {
      yield put(updateSfpSkippedAllActions.failed(e.message));
    }
  }
}

function* updateSfpSkippedAllSuccess(action: AsyncAction<UpdateSfpSkippedAllMeta, UpdateSfpSkippedAllPayload>) {
  // yield put()
}

function* updateSfpSkippedAllWatcher(): any {
  yield takeLatest(updateSfpSkippedAllActions.REQUEST, updateSfpSkippedAllRequest);
  yield takeLatest(updateSfpSkippedAllActions.SUCCESS, updateSfpSkippedAllSuccess);
}

export default updateSfpSkippedAllWatcher;
