import {
  GetStoryByIdMeta,
  GetStoryByIdPayload,
} from 'types/store/GetStoryByIdState';
import { createAsyncReducer } from 'utils/Redux';

import { getStoryByIdActions } from '../actions';

export default createAsyncReducer<GetStoryByIdMeta, GetStoryByIdPayload>(
  getStoryByIdActions
);
