import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getMagicLink } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
    GetMagicLinkMeta,
    GetMagicLinkPayload,
} from 'types/store/GetMagicLink';
import { Store } from 'types/store/Store';

import { getMagicLinkActions } from '../actions';

function* getMagicLinkRequest(
    action: AsyncAction<GetMagicLinkMeta, GetMagicLinkPayload>
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            const { data } = yield call(getMagicLink, action.meta, state.auth.token);
            yield put(getMagicLinkActions.success(data));
        } catch (e: any) {
            yield put(getMagicLinkActions.failed(e.message));
        }
    } else {
        yield put(getMagicLinkActions.failed('Token not found'));
    }
}

function* getMagicLinkSuccess(
    action: AsyncAction<GetMagicLinkMeta, GetMagicLinkPayload>
) {
    // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getMagicLinkWatcher(): any {
    yield takeLatest(
        getMagicLinkActions.REQUEST,
        getMagicLinkRequest
    );
    yield takeLatest(
        getMagicLinkActions.SUCCESS,
        getMagicLinkSuccess
    );
}

export default getMagicLinkWatcher;
