import {
  UpsertShowcaseArticleMeta,
  UpsertShowcaseArticlePayload,
} from 'types/store/UpsertShowcaseArticleState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_SHOWCASE_ARTICLE';
const asyncAction = createAsyncAction<
  UpsertShowcaseArticleMeta,
  UpsertShowcaseArticlePayload
>(ns);

const upsertShowcaseArticleActions = {
  ...asyncAction,
};

export default upsertShowcaseArticleActions;
