import { SendEmail } from 'types/store/EmployeeHub'

export enum QR_CODE {
  QR_CODE_REQUEST = '@@_QR_CODE_REQUEST',
  QR_CODE_SUCCESS = '@@_QR_CODE_SUCCESS'
}

export enum GET_TEMPLATE_INVITE {
  GET_TEMPLATE_INVITE_REQUEST = '@@_GET_TEMPLATE_INVITE_REQUEST',
  GET_TEMPLATE_INVITE_SUCCESS = '@@_GET_TEMPLATE_INVITE_SUCCESS'
}

export enum CREATE_INVITE_TEMPLATE {
  CREATE_INVITE_TEMPLATE_REQUEST = '@@_CREATE_INVITE_TEMPLATE_REQUEST',
  CREATE_INVITE_TEMPLATE_SUCCESS = '@@_CREATE_INVITE_TEMPLATE_SUCCESS',
  CREATE_INVITE_TEMPLATE_ERROR = '@@_CREATE_INVITE_TEMPLATE_ERROR',
  CREATE_INVITE_TEMPLATE_SMS_SUCCESS = '@@_CREATE_INVITE_TEMPLATE_SMS_SUCCESS',
  CREATE_INVITE_TEMPLATE_SMS_CLEAR = '@@_CREATE_INVITE_TEMPLATE_SMS_CLEAR',
  CREATE_INVITE_TEMPLATE_CLEAR_ID = '@@_CREATE_INVITE_TEMPLATE_CLEAR_ID',
  CREATE_NEW_INVITE = '@@_CREATE_NEW_INVITE',
  CLEAR_MESSAGE_CLEAR = '@@_CLEAR_MESSAGE_CLEAR',
  CREATE_INVITE_TEMPLATE_SAVE = '@@_CREATE_INVITE_TEMPLATE_SAVE'
}

export const clearTemplateId = (): any => ({
  type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_CLEAR_ID
})

export enum CHOOSE_EXAMPLE_TEMPLATE {
  CHOOSE_EXAMPLE_TEMPLATE_REQUEST = '@@_CHOOSE_EXAMPLE_TEMPLATE_REQUEST'
}

export enum SENT_INVITE {
  SENT_INVITE_REQUEST = '@@_SENT_INVITE_REQUEST',
  SENT_INVITE_SUCCESS = '@@_SENT_INVITE_SUCCESS',
  SENT_INVITE_CLEAR = '@@_SENT_INVITE_CLEAR',
  SENT_INVITE_SMS_REQUEST = '@@_SENT_INVITE_SMS_REQUEST',
  SENT_INVITE_SMS_SUCCESS = '@@_SENT_INVITE_SMS_SUCCESS'
}

export enum COPPY_MESSAGE {
  COPPY_MESSAGE_REQUEST = '@@_COPPY_MESSAGE_REQUEST',
  COPPY_MESSAGE_SUCCESS = '@@_COPPY_MESSAGE_SUCCESS'
}

export enum REPLACE_PLACHOLDER_ZOOM_IN {
  REPLACE_PLACHOLDER_ZOOM_IN_REQUEST = '@@_REPLACE_PLACHOLDER_ZOOM_IN_REQUEST',
  REPLACE_PLACHOLDER_ZOOM_IN_SUCCESS = '@@_REPLACE_PLACHOLDER_ZOOM_IN_SUCCESS'
}

export const getQRCode = (): any => ({
  type: QR_CODE.QR_CODE_REQUEST
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const sendInviteSms = (data: any): any => ({
  type: SENT_INVITE.SENT_INVITE_SMS_REQUEST,
  payload: data
})

export const getInvitedTemplate = (payload: { type: string, template: string }): any => ({
  type: GET_TEMPLATE_INVITE.GET_TEMPLATE_INVITE_REQUEST,
  payload: { ...payload }
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createTemplate = (data: any): any => ({
  type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_REQUEST,
  payload: data
})

export const chooseExampleTemplate = (payload: string): any => ({
  type: CHOOSE_EXAMPLE_TEMPLATE.CHOOSE_EXAMPLE_TEMPLATE_REQUEST,
  payload
})

export const clearTemplateSms = (): any => ({
  type: CREATE_INVITE_TEMPLATE.CREATE_INVITE_TEMPLATE_SMS_CLEAR
})

export const sendInvite = (payload: SendEmail): any => ({
  type: SENT_INVITE.SENT_INVITE_REQUEST,
  payload
})

export const clearSendInvite = (): any => ({
  type: SENT_INVITE.SENT_INVITE_CLEAR,
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getCoppyMessage = (payload: any): any => ({
  type: COPPY_MESSAGE.COPPY_MESSAGE_REQUEST,
  payload
})

export const createNewTemplateRequest = (): any => ({
  type: CREATE_INVITE_TEMPLATE.CREATE_NEW_INVITE
})

export const clearMessageWhenSuccess = (): any => ({
  type: CREATE_INVITE_TEMPLATE.CLEAR_MESSAGE_CLEAR
})

export const replacePlaceHolderCkEditor = (payload: string): any => ({
  type: REPLACE_PLACHOLDER_ZOOM_IN.REPLACE_PLACHOLDER_ZOOM_IN_REQUEST,
  payload
})
