import {
  GetStoriesAnalyticsPayload,
  GetStoriesAnalyticsMeta,
} from 'types/store/GetStoriesAnalytics';
import { createAsyncReducer } from 'utils/Redux';

import { getStoriesAnalyticsAction } from '../actions';

export default createAsyncReducer<
  GetStoriesAnalyticsMeta,
  GetStoriesAnalyticsPayload
>(getStoriesAnalyticsAction);
