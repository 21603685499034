import {
  UpdateStoryByIdMeta,
  UpdateStoryByIdPayload,
} from 'types/store/UpdateStoryById';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STORY_BY_ID';

const asyncAction = createAsyncAction<
  UpdateStoryByIdMeta,
  UpdateStoryByIdPayload
>(ns);

const updateStoryByIdActions = {
  ...asyncAction,
};

export default updateStoryByIdActions;
