import {
  UpdateBrandGuidelinesByCompanyIdMeta,
  UpdateBrandGuidelinesByCompanyIdPayload,
} from 'types/store/UpdateBrandGuidelinesByCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_RABND_GUIDELINES_BY_COMPANY_ID';
const asyncAction = createAsyncAction<
  UpdateBrandGuidelinesByCompanyIdMeta,
  UpdateBrandGuidelinesByCompanyIdPayload
>(ns);

const updateBrandGuidelinesByCompanyIdActions = {
  ...asyncAction,
};

export default updateBrandGuidelinesByCompanyIdActions;
