import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowTurnRight = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg width={width || "9"} height={height || "9"} viewBox="0 0 9 9" fill="none">
      <path
        d="M7.16912 4.63373L4.4525 6.88464V5.83421H1.91699V3.43324H4.4525V2.38281L7.16912 4.63373Z"
        fill={fill || "#B5ADB0"} />
    </svg>

  );
};

export default ArrowTurnRight;
