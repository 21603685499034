import React from 'react';

import CloudCheck from 'components/base/SVG/CloudCheck';
import SyncCircleV3 from 'components/base/SVG/SyncCircleV3';
import Typography from 'components/base/Typography';
import { translations } from 'locales/i18n';
import { useTranslation } from 'react-i18next';
import CountWords from 'utils/CountWords';

import { TextAreaProps } from './TextArea.props';
import {
  Container,
  FieldContainer,
  Field,
  Error,
  MainLabel,
  StateBox,
  StatusText,
  Header,
  SaveStatusBox,
  RightComponentContainer,
} from './TextArea.style';

const TextArea = (props: TextAreaProps): JSX.Element => {
  const { t } = useTranslation();
  const {
    id,
    label,
    value = '',
    disabled = false,
    onChange = () => null,
    onChangeText = () => null,
    placeholder,
    error = '',
    className = undefined,
    onBlur = () => null,
    rows,
    mt,
    readOnly,
    labelStyle = undefined,
    count = false,
    saveStatus = false,
    saving = true,
    LeftComponent = null,
    RightComponent = null,
    noRightCompBorder = false,
    RightComponentWidth,
    onClickIcon = () => null,
  } = props;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(event);
    onChangeText(event.target.value);
  };

  const length = CountWords(value as string);

  return (
    <Container className={className}>
      <Header>
        {LeftComponent ? (
          LeftComponent
        ) : (
          <MainLabel
            variant={labelStyle === undefined ? 'label' : labelStyle}
            color={'shade7'}
          >
            {label}
          </MainLabel>
        )}
        {(saveStatus || count) && (
          <StateBox>
            {saveStatus && (
              <SaveStatusBox saving={saving}>
                {saving ? <SyncCircleV3 fill="#B5ADB0" /> : <CloudCheck />}
                <Typography
                  variant="small"
                  color="shade6"
                  style={{ marginLeft: 4, marginBottom: -4 }}
                >
                  {saving ? 'Saving...' : 'Saved'}
                </Typography>
              </SaveStatusBox>
            )}
            {count && (
              <StatusText variant="small" length={length} max={100}>
                ({length}{' '}
                {t(
                  length === 1
                    ? translations.WriteUnit
                    : translations.WriteUnits,
                )}
                )
              </StatusText>
            )}
          </StateBox>
        )}
      </Header>
      <FieldContainer mt={mt}>
        <Field
          rows={rows || 3}
          id={id}
          disabled={disabled}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          style={
            (error || '').length > 0 ? { border: '1px solid #FF647C' } : {}
          }
          readOnly={readOnly}
        />
        {RightComponent && (
          <RightComponentContainer>{RightComponent}</RightComponentContainer>
        )}
      </FieldContainer>
      {(error || '').length > 0 && (
        <Error variant="caption" color="error" className='error'>
          {error}
        </Error>
      )}
    </Container>
  );
};

export default React.memo(TextArea);
