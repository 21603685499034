import {
    UpsertShowcasePageMeta,
    UpsertShowcasePagePayload
} from 'types/store/UpsertShowcasePageState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertShowcasePageActions } from '../actions';

export default createAsyncReducer<
    UpsertShowcasePageMeta,
    UpsertShowcasePagePayload
>(upsertShowcasePageActions);
