import { put, call, takeLatest, select } from 'redux-saga/effects';
import { AsyncAction } from 'types/Action';
import { AddContentToStateMeta, AddContentToStatePayload } from 'types/store/AddContentToState';
import { Store } from 'types/store/Store';

import { addContentToStateActions } from '../actions';

function* addContentToStateRequest(action: AsyncAction<AddContentToStateMeta, AddContentToStatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // const {data}  = yield call(addContentToState, action.meta, state.auth.token);
      yield put(addContentToStateActions.success(action.meta));
    } catch (e: any) {
      yield put(addContentToStateActions.failed(e.message));
    }
  }
}

// function* addContentToStateSuccess(action: AsyncAction<AddContentToStateMeta, AddContentToStatePayload>): any {
//   yield put()
// }

function* addContentToStateWatcher(): any {
  yield takeLatest(addContentToStateActions.REQUEST, addContentToStateRequest);
  // yield takeLatest(addContentToStateActions.SUCCESS, addContentToStateSuccess);
}

export default addContentToStateWatcher;
