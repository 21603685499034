import { CheckEbInviteMeta, CheckEbInvitePayload } from 'types/store/CheckEbInvite';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHECK_EB_INVITE_NS';

const asyncAction = createAsyncAction<CheckEbInviteMeta, CheckEbInvitePayload>(ns);

const checkEbInviteActions = { ...asyncAction };

export default checkEbInviteActions;
