import { AxiosError } from 'axios';
import {
  put,
  call,
  takeLatest,
  select,
  delay,
  takeEvery,
} from 'redux-saga/effects';
import {
  createDefaultBrandGuidelineStory,
  getBrandGuidlineStory,
  getDefaultBrandGuidelineStory,
  updateDefaultBrandGuidelineStory,
} from 'services/company';
import {
  getSuggestionTopicList,
  createNewStoryContent,
  createCustomPrompt,
  downloadContentStory,
  prevNextVerParagraph,
  storyVersion,
  updateStoryContent,
} from 'services/story-builder';
import { openToast } from 'store/actions';
import {
  PromptType,
  StylingReviewPayload,
  SuggestionTopicPayload,
  downloadContentPayload,
} from 'store/actions/storyDetailV2/storyDetailV2.action';
import {
  CREATE_AI_PROMP,
  DRAFT_AI,
  GENERATE_KEY_TOPIC,
  CREATE_NEW_STORY,
  GET_BRAND_GUIDELINE,
  UPDATE_BRAND_GUIDELINE,
  DOWNLOAD_CONTENT,
  DRAFT_PREVIEW,
  CONTENT_TYPE,
  GET_PARAGRAPH_VERSION,
  GET_STORY_VERSION,
  DRAFTAI,
  OVERWRITE_STORY,
} from 'store/actions/storyDetailV2/storyDetailV2.enum';
import { Action, AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { getLocation } from 'connected-react-router';
import { downloadLink } from 'utils/download';
import { DraftAI } from 'types/store/StoryEditorV2';

function* watchCreateAIPrompRequest(action: AsyncAction) {
  const state: Store = yield select();
  yield put({
    type: CREATE_AI_PROMP.CREATE_AI_PROMP_SUCCESS,
  });
}

function* watchDraftAIRequest(action: AsyncAction) {
  const state: Store = yield select();
  yield delay(2000);
  yield put({
    type: DRAFT_AI.DRAFT_AI_SUCCESS,
  });
}

function* getSuggestionTopicRequest(
  action: AsyncAction<SuggestionTopicPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getSuggestionTopicList,
        action.payload,
        state.auth.token,
      );
      switch (action.payload.type) {
        case PromptType.HEADLINE:
          yield put({
            type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_HEADLINE_REQUEST,
            payload: data.data,
          });
          break;
        case PromptType.INTRODUCTION:
          yield put({
            type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_INTRODUCTION_REQUEST,
            payload: data.data,
          });
          break;
        case PromptType.CONCLUSION:
          yield put({
            type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_CONCLUSION_REQUEST,
            payload: data.data,
          });
          break;
        case PromptType.OVERALL:
          yield put({
            type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_OVERALL_SUCCESS,
            payload: data.data,
          });
          break;
        default:
          yield put({
            type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_SUCCESS,
            payload: data.data,
          });
      }
    } catch (e: any) {
      // yield put(uploadAnswerVideoActions.failed(e.message));
    }
  }
}

function* createNewContentContentRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createNewStoryContent,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: DRAFTAI.DRAFTAI_VERSION_STORY_ID,
        payload: data.data.id,
      });
      // yield put({
      //   type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_SUCCESS,
      //   payload: data.data,
      // });
      // save story content / blocks answer-question after generate draft AI or Edit prompt
    } catch (e: any) {
      // yield put(uploadAnswerVideoActions.failed(e.message));
    }
  }
}
function* overwriteStoryContenRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateStoryContent,
        action.payload,
        state.auth.token,
      );
    } catch (e: any) {
      console.error(e);
    }
  }
}

function* createCustomPromptRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createCustomPrompt,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_CUSTOM_SUCCESS,
        payload: data.data,
      });
      // save story content / blocks answer-question after generate draft AI or Edit prompt
    } catch (e: any) {
      console.error(e);
    }
  }
}

function* getBrandGuideLineStory(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getBrandGuidlineStory,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: GET_BRAND_GUIDELINE.GET_BRAND_GUIDELINE_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_SUCCESS,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getParagraphVersion(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        prevNextVerParagraph,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: GET_PARAGRAPH_VERSION.GET_PARAGRAPH_VERSION_SUCCESS,
        payload: data.data,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      // yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}
function* getStoryVersion(action: Action) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        storyVersion,
        action.payload,
        state.auth.token,
      );
      yield put({
        type: GET_STORY_VERSION.GET_STORY_VERSION_SUCCESS,
        payload: data.data,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getDefaultSettingBrandGuideLine() {
  const state: Store = yield select();
  const brandGuidelineStory =
    state.getStoryForEditor.data?.data?.story?.story_brand_guideline;

  if (state.auth.token) {
    try {
      const { data } = yield call(
        getDefaultBrandGuidelineStory,
        state.auth.token,
      );
      yield put({
        type: GET_BRAND_GUIDELINE.GET_BRAND_GUIDELINE_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_ERROR,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getBrandGuideLineWatcher(action: AsyncAction) {
  const state: Store = yield select();
  const brandGuidelineStory =
    state.getStoryForEditor.data?.data?.story?.story_brand_guideline;

  if (state.auth.token) {
    try {
      if (brandGuidelineStory) {
        yield getBrandGuideLineStory({
          payload: action.payload,
          type: '',
        });
      } else {
        yield getDefaultSettingBrandGuideLine();
      }
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* updateBrandGuideLineWatcher(action: AsyncAction) {
  const state: Store = yield select();
  const brandGuidelineInit = state.storyDetailV2.brandGuidelineInit;
  if (state.auth.token) {
    try {
      if (!action.payload.id) {
        action.payload.id = brandGuidelineInit.id;
      }
      const { data } = yield call(
        action.meta
          ? updateDefaultBrandGuidelineStory
          : createDefaultBrandGuidelineStory,
        state.auth.token,
        action.payload,
      );
      yield put(openToast(data.message, 'success'));
      yield put({
        type: GET_BRAND_GUIDELINE.GET_BRAND_GUIDELINE_SUCCESS,
        payload: data.data,
      });
      yield put({
        type: UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_SUCCESS,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* downloadContentRequest(action: AsyncAction<downloadContentPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        downloadContentStory,
        action.payload,
        state.auth.token,
      );
      if (action.payload?.isDownload) {
        yield call(downloadLink, action.payload?.fileName, data?.data?.url);
      }

      yield put({
        type: DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_SUCCESS,
        payload: data,
      });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* watchStylingReviewRequest(action: Action<StylingReviewPayload>) {
  const responseDraftAI: DraftAI | undefined = yield select(
    (_: Store) => _.storyDetailV2.responseDraftAI,
  );
  const stylingPreviewDraftClone = { ...responseDraftAI };
  try {
    const {
      payload: { type, value, idx },
    } = action;

    switch (type) {
      case CONTENT_TYPE.CONCLUSION: {
        if (stylingPreviewDraftClone['conclusion']) {
          stylingPreviewDraftClone['conclusion']['data'] = value;
        }
        break;
      }
      case CONTENT_TYPE.INTRODUCTION: {
        if (stylingPreviewDraftClone['introduction']) {
          stylingPreviewDraftClone['introduction']['data'] = value;
        }
        break;
      }
      case CONTENT_TYPE.PARAGRAPH: {
        if (stylingPreviewDraftClone['sections'] && idx !== undefined) {
          return (stylingPreviewDraftClone['sections'][idx]['data'] = value);
        }
        break;
      }
      default:
        if (typeof stylingPreviewDraftClone?.headline === 'string') {
          stylingPreviewDraftClone.headline === value;
        } else if (stylingPreviewDraftClone?.headline) {
          stylingPreviewDraftClone['headline']['data'] = value;
        }
        break;
    }
    yield put({
      type: DRAFT_PREVIEW.DRAFT_PREVIEW_SUCCESS,
      payload: stylingPreviewDraftClone,
    });
  } catch (e: unknown) {
    console.log('error styling', e);
  }
}

function* storyDetailV2Watcher(): any {
  yield takeLatest(
    CREATE_AI_PROMP.CREATE_AI_PROMP_REQUEST,
    watchCreateAIPrompRequest,
  );
  yield takeEvery(
    GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_REQUEST,
    getSuggestionTopicRequest,
  );

  // yield takeLatest(DRAFT_AI.DRAFT_AI_REQUEST, watchDraftAIRequest);
  yield takeLatest(
    CREATE_NEW_STORY.CREATE_NEW_STORY_REQUEST,
    createNewContentContentRequest,
  );
  yield takeLatest(
    OVERWRITE_STORY.OVERWRITE_STORY_REQUEST,
    overwriteStoryContenRequest,
  );
  yield takeLatest(
    GET_BRAND_GUIDELINE.GET_BRAND_GUIDELINE_REQUEST,
    getBrandGuideLineWatcher,
  );
  yield takeLatest(
    UPDATE_BRAND_GUIDELINE.UPDATE_BRAND_GUIDELINE_REQUEST,
    updateBrandGuideLineWatcher,
  );
  yield takeLatest(
    GENERATE_KEY_TOPIC.GENERATE_KEY_TOPIC_CUSTOM_REQUEST,
    createCustomPromptRequest,
  );
  yield takeLatest(
    DOWNLOAD_CONTENT.DOWNLOAD_CONTENT_REQUEST,
    downloadContentRequest,
  );
  // yield takeLatest(
  //   DRAFT_PREVIEW.DRAFT_PREVIEW_REQUEST,
  //   watchStylingReviewRequest,
  // );
  yield takeLatest(
    GET_PARAGRAPH_VERSION.GET_PARAGRAPH_VERSION_REQUEST,
    getParagraphVersion,
  );
  yield takeLatest(
    GET_STORY_VERSION.GET_STORY_VERSION_REQUEST,
    getStoryVersion,
  );
}

export default storyDetailV2Watcher;
