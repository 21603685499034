import {
  GetAdvocateInvitesMeta,
  GetAdvocateInvitesPayload,
} from 'types/store/GetAdvocateInvites';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATE_INVITES';
const asyncAction = createAsyncAction<
  GetAdvocateInvitesMeta,
  GetAdvocateInvitesPayload
>(ns);

const getAdvocateInvitesAction = {
  ...asyncAction,
};

export default getAdvocateInvitesAction;
