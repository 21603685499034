import { put, call, takeLatest, select } from 'redux-saga/effects';
import { submitVideoComment } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  SubmitVideoCommentMeta,
  SubmitVideoCommentPayload,
} from 'types/store/SubmitVideoCommentState';

import { submitVideoCommentActions, getReviewedStoriesActions } from '../actions';

function* submitVideoCommentRequest(
  action: AsyncAction<SubmitVideoCommentMeta, SubmitVideoCommentPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        submitVideoComment,
        action.meta,
        state.auth.token
      );
      // if(action.meta.comment_status === false) {
      //   yield put(getReviewedStoriesActions.request({}));
      // }

      yield put(submitVideoCommentActions.success(data));
    } catch (e: any) {
      yield put(submitVideoCommentActions.failed(e.message));
    }
  }
}

function* submitVideoCommentSuccess(
  action: AsyncAction<SubmitVideoCommentMeta, SubmitVideoCommentPayload>
) {
  //
}

function* submitVideoCommentWatcher(): any {
  yield takeLatest(
    submitVideoCommentActions.REQUEST,
    submitVideoCommentRequest
  );
  yield takeLatest(
    submitVideoCommentActions.SUCCESS,
    submitVideoCommentSuccess
  );
}

export default submitVideoCommentWatcher;
