import React from 'react';

import { SVGProps } from './SVG.props';

const Refresh = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 21}
      height={height || 20}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M18.41 13.51h-4.53a1 1 0 000 2h2.4A8 8 0 012.5 10a1 1 0 00-2 0 10 10 0 0016.88 7.23V19a1 1 0 002 0v-4.5a1 1 0 00-.97-.99zM10.5 0a10 10 0 00-6.88 2.77V1a1 1 0 00-2 0v4.5a1 1 0 001 1h4.5a1 1 0 000-2h-2.4A8 8 0 0118.5 10a1 1 0 002 0 10 10 0 00-10-10z"
        fill={fill || "#fff"}
      />
    </svg>
  );
};

export default Refresh;
