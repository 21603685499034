import {
  TranscribeCheckMeta,
  TranscribeCheckPayload,
} from 'types/store/TranscribeCheck';
import { createAsyncReducer } from 'utils/Redux';

import { transcribeCheckActions } from '../actions';

export default createAsyncReducer<TranscribeCheckMeta, TranscribeCheckPayload>(
  transcribeCheckActions
);
