import {
    GetDataShowcasePageDetailsMeta,
    GetDataShowcasePageDetailsPayload,
} from 'types/store/GetDataShowcasePageDetailsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_DATA_SHOWCASE_PAGE_DETAILS';
const asyncAction = createAsyncAction<
    GetDataShowcasePageDetailsMeta,
    GetDataShowcasePageDetailsPayload
>(ns);

const getDataShowcasePageDetailsActions = {
    ...asyncAction,
};

export default getDataShowcasePageDetailsActions;
