import {
  GetSaveTemplatesCompanyIdMeta,
  GetSaveTemplatesCompanyIdPayload,
} from 'types/store/GetSaveTemplatesByCompanyId';
import { createAsyncReducer } from 'utils/Redux';

import { getSaveTemplatesByCompanyIdActions } from '../actions';

export default createAsyncReducer<
  GetSaveTemplatesCompanyIdMeta,
  GetSaveTemplatesCompanyIdPayload
>(getSaveTemplatesByCompanyIdActions);
