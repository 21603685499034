import { AxiosError } from 'axios';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  createTemplate,
  fetchBrandKit,
  fetchRecentVideo,
  fetchTemplate,
  getEditTemplate,
  setFavoriteServices,
  updateBrandKit,
  uploadFonts,
  uploadLogos,
  uploadVideoStudio,
} from 'services/creativeStudio';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import {
  BrandKitStudioMeta,
  SetFavoriteMeta,
  TemplateStudioMeta,
  UploadVideoStudioMeta,
} from 'types/store/CreativeStudio';
import { Store } from 'types/store/Store';
import { isSafari } from 'react-device-detect';

import { creativeStudioAction, openToast } from '../actions';
import * as switchTokenService from '../../services/temporarySwitchToken';

function* getBrandKitRequest(action: AsyncAction<null, GenericResponse>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(fetchBrandKit, state.auth.token);
      yield put(creativeStudioAction.getBrandKitAction.success(data));
    } catch (e: any) {
      yield put(creativeStudioAction.getBrandKitAction.failed(e.message));
    }
  }
}

function* getTemplateRequest(
  action: AsyncAction<TemplateStudioMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(fetchTemplate, action.meta, state.auth.token);
      yield put(creativeStudioAction.getTemplateAction.success(data));
      if (action.meta.level === 'All' && !action.meta?.keyword) {
        yield put(creativeStudioAction.getFavoritesList.success(data));
      }
    } catch (e: any) {
      yield put(creativeStudioAction.getTemplateAction.failed(e.message));
    }
  }
}

function* updateBrandKitPut(action: AsyncAction<null, GenericResponse>): any {
  const state: Store = yield select();
  const { logos, fonts, colors } = state.CreativeReducer;
  const formSend: BrandKitStudioMeta = {
    logos: logos.map((item: any) => {
      return {
        Link: item.Link,
        logoName: item.logoName,
      };
    }),
    fonts: fonts.map((item: any) => {
      return {
        Link: item.Link,
        fontName: item.fontName,
      };
    }),
    colors: colors.map((item) => item.color),
  };
  if (state.auth.token) {
    try {
      const { data } = yield call(updateBrandKit, formSend, state.auth.token);
      yield put(creativeStudioAction.updateBrandKitAction.success(data));
    } catch (e: any) {
      yield put(creativeStudioAction.updateBrandKitAction.failed(e.message));
    }
  }
}

function* addLogoRequest(
  action: AsyncAction<{ files: File[] }, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(uploadLogos, action.meta, state.auth.token);
      yield put(creativeStudioAction.addLogoBrandKitAction.success(data));
    } catch (e: any) {
      yield put(creativeStudioAction.addLogoBrandKitAction.failed(e.message));
    }
  }
}

function* addFontRequest(
  action: AsyncAction<{ files: File[] }, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(uploadFonts, action.meta, state.auth.token);
      yield put(creativeStudioAction.addFontBrandKitAction.success(data));
    } catch (e: any) {
      yield put(creativeStudioAction.addFontBrandKitAction.failed(e.message));
    }
  }
}

function* getRecentVideoRequest(
  action: AsyncAction<null, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(fetchRecentVideo, state.auth.token);

      yield put(creativeStudioAction.getRecentVideo.success(data));
    } catch (e: any) {
      yield put(creativeStudioAction.getRecentVideo.failed(e.message));
    }
  }
}

function* uploadVideoStudioRequest(
  action: AsyncAction<UploadVideoStudioMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadVideoStudio,
        action.meta,
        state.auth.token,
      );
      yield put(openToast(data.message, 'success'));
      yield put(creativeStudioAction.uploadVideoStudio.success(data));
    } catch (e: any) {
      yield put(openToast('Upload Failed', 'error'));
      yield put(creativeStudioAction.uploadVideoStudio.failed(e.message));
    }
  }
}

function* uploadVideoStudioSuccess(
  action: AsyncAction<any, GenericResponse>,
): any {
  const state: Store = yield select();
  const redirectStudio = document.createElement('a');
  const newWindow = window.open('about:blank', '_blank');
  try {
    if (
      !newWindow ||
      newWindow.closed ||
      typeof newWindow.closed === 'undefined'
    ) {
      alert(
        'Please allow popups for this website and follow this URL to enable https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop',
      );
    }
    if (newWindow) {
      newWindow.close();
      redirectStudio.setAttribute('target', '_blank');
    }
    if (state.CreativeReducer.templateId) {
      redirectStudio.setAttribute(
        'href',
        `${process.env.REACT_APP_STUDIO_DOMAIN}/home?contentId=${action.payload.data.id}&contentType=LIBRARY&template=${state.CreativeReducer.templateId}`,
      );
      document.body.appendChild(redirectStudio);
      redirectStudio.click();
    } else {
      redirectStudio.setAttribute(
        'href',
        `${process.env.REACT_APP_STUDIO_DOMAIN}/home?contentId=${action.payload.data.id}&contentType=LIBRARY`,
      );
      document.body.appendChild(redirectStudio);
      redirectStudio.click();
    }
  } catch (e) {
    console.log(e);
    alert(
      'Please allow popups for this website and follow this URL to enable https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop',
    );
    return false;
  }
}

function* createTemplateRequest(action: AsyncAction<any>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createTemplate, state.auth.token);
      const dataNavigate = {
        contentId: data.data.contentId,
        contentType: data.data.contentType,
        size: action.meta.size,
      };
      yield put(creativeStudioAction.createTemplate.success(dataNavigate));
    } catch (e: any) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage.response?.data.message, 'error'));
    }
  }
}

function* createTemplateSuccess(action: AsyncAction<GenericResponse>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    // const state: Store = yield select();
    // eslint-disable-next-line no-debugger
    const redirectStudio = document.createElement('a');
    const newWindow = window.open('about:blank', '_blank');
    try {
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        alert(
          'Please allow popups for this website and follow this URL to enable https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop',
        );
      }
      if (newWindow) {
        newWindow.close();
        // redirectStudio.setAttribute('target', '_blank');
      }
      redirectStudio.setAttribute(
        'href',
        `${process.env.REACT_APP_STUDIO_DOMAIN}/home?contentId=${action.payload.contentId}&contentType=${action.payload.contentType}&size=${action.payload.size}`,
      );
      document.body.appendChild(redirectStudio);
      redirectStudio.click();
    } catch (e) {
      alert(
        'Please allow popups for this website and follow this URL to enable https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop',
      );
      return false;
    }
  }
}

function* editTemplate(action: AsyncAction<string, GenericResponse>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getEditTemplate,
        action.meta,
        state.auth.token,
      );
      yield put(creativeStudioAction.editATemplate.success(data));
    } catch (e: any) {
      yield put(creativeStudioAction.editATemplate.failed(e.message));
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage.response?.data.message, 'error'));
    }
  }
}

function* editTemplateSuccess(action: AsyncAction<GenericResponse>): any {
  const state: Store = yield select();
  if (state.auth.token) {

    const { template_id, content_type, content_id } = action.payload.data;
    const url = `${process.env.REACT_APP_STUDIO_DOMAIN}/home?contentId=${content_id}&contentType=${content_type}&template=${template_id}&action=template_edit`;

    const response = yield switchTokenService.create(
      state.auth?.token || '',
      state.auth?.refresh_token || '',
    );
    const ssid = response.data;

    if (isSafari) {
      window.location.href = `${url}&ssid=${ssid}`;
      return;
    }

    try {
      const redirectStudio = document.createElement('a');
      const newWindow = window.open('about:blank', '_blank');
      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        alert(
          'Please allow popups for this website and follow this URL to enable https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop',
        );
      }
      if (newWindow) {
        newWindow.close();
        // redirectStudio.setAttribute('target', '_blank');
      }
      redirectStudio.setAttribute(
        'href',
        `${process.env.REACT_APP_STUDIO_DOMAIN}/home?contentId=${content_id}&contentType=${content_type}&template=${template_id}&action=template_edit`,
      );
      document.body.appendChild(redirectStudio);
      redirectStudio.click();
    } catch (e) {
      alert(
        'Please allow popups for this website and follow this URL to enable https://support.google.com/chrome/answer/95472?hl=en&co=GENIE.Platform%3DDesktop',
      );
      return false;
    }
  }
}

function* setFavoriteRequest(
  action: AsyncAction<SetFavoriteMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        setFavoriteServices,
        action.meta,
        state.auth.token,
      );
      yield put(creativeStudioAction.setFavoriteAction.success(action.meta));
    } catch (e: any) {
      yield put(creativeStudioAction.setFavoriteAction.failed(e.message));
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage.response?.data.message, 'error'));
    }
  }
}

function* brandKitWatcher(): any {
  yield takeLatest(
    creativeStudioAction.getBrandKitAction.REQUEST,
    getBrandKitRequest,
  );

  yield takeLatest(
    creativeStudioAction.getTemplateAction.REQUEST,
    getTemplateRequest,
  );

  yield takeLatest(
    creativeStudioAction.updateBrandKitAction.REQUEST,
    updateBrandKitPut,
  );

  yield takeLatest(
    creativeStudioAction.addLogoBrandKitAction.REQUEST,
    addLogoRequest,
  );
  yield takeLatest(
    creativeStudioAction.addFontBrandKitAction.REQUEST,
    addFontRequest,
  );
  yield takeLatest(
    creativeStudioAction.getRecentVideo.REQUEST,
    getRecentVideoRequest,
  );
  yield takeLatest(
    creativeStudioAction.setFavoriteAction.REQUEST,
    setFavoriteRequest,
  );
  yield takeLatest(
    creativeStudioAction.uploadVideoStudio.REQUEST,
    uploadVideoStudioRequest,
  );
  yield takeLatest(
    creativeStudioAction.uploadVideoStudio.SUCCESS,
    uploadVideoStudioSuccess,
  );
  yield takeLatest(
    creativeStudioAction.createTemplate.REQUEST,
    createTemplateRequest,
  );
  yield takeLatest(
    creativeStudioAction.createTemplate.SUCCESS,
    createTemplateSuccess,
  );
  yield takeLatest(creativeStudioAction.editATemplate.REQUEST, editTemplate);
  yield takeLatest(
    creativeStudioAction.editATemplate.SUCCESS,
    editTemplateSuccess,
  );
}

export default brandKitWatcher;
