import {
  UpdateRevisionApprovalMeta,
  UpdateRevisionApprovalPayload,
} from 'types/store/UpdateRevisionApprovalState';
import { createAsyncReducer } from 'utils/Redux';

import { updateRevisionApprovalActions } from '../actions';

export default createAsyncReducer<
  UpdateRevisionApprovalMeta,
  UpdateRevisionApprovalPayload
>(updateRevisionApprovalActions);
