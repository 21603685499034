import { AddApproversV2Meta, AddApproversV2Payload } from 'types/store/AddApproversV2';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADD_APPROVERS_V2_NS';

const asyncAction = createAsyncAction<AddApproversV2Meta, AddApproversV2Payload>(ns);

const addApproversV2Actions = { ...asyncAction };

export default addApproversV2Actions;
