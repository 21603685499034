import {
  GetAdvocatesByCompanyIdMeta,
  GetAdvocatesByCompanyIdPayload,
} from 'types/store/GetAdvocatesByCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATES_COMPANY_ID';
const asyncAction = createAsyncAction<
  GetAdvocatesByCompanyIdMeta,
  GetAdvocatesByCompanyIdPayload
>(ns);

const getAdvocatesbyCompanyIdActions = {
  ...asyncAction,
};

export default getAdvocatesbyCompanyIdActions;
