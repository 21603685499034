import {
 GetStripeCustomerIdMeta,
 GetStripeCustomerIdPayload
} from 'types/store/GetStripeCustomerId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STRIPE_CUSTOMER_ID';
const asyncAction = createAsyncAction<GetStripeCustomerIdMeta, GetStripeCustomerIdPayload>(
  ns
);

const getStripeCustomerIdActions = {
  ...asyncAction,
};

export default getStripeCustomerIdActions;