import { CreateAppointmentMeta, CreateAppointmentPayload } from 'types/store/CreateAppointment';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_APPOINTMENT_NS';

const asyncAction = createAsyncAction<CreateAppointmentMeta, CreateAppointmentPayload>(ns);

const createAppointmentActions = { ...asyncAction };

export default createAppointmentActions;
