import {
    UpdateCardDetailsMeta,
    UpdateCardDetailsPayload
  } from 'types/store/UpdateCardDetails';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'UPDATE_CARD_DETAILS';
  
  const asyncAction = createAsyncAction<UpdateCardDetailsMeta, UpdateCardDetailsPayload>(ns);
  
  const updateCardDetailsActions = {
    ...asyncAction,
  };
  
  export default updateCardDetailsActions;
  