import {
  UpdateAnswerByIdMeta,
  UpdateAnswerByIdPayload,
} from 'types/store/UpdateAnswerByIdState';
import { createAsyncReducer } from 'utils/Redux';

import { updateAnswerByIdActions } from '../actions';

export default createAsyncReducer<
  UpdateAnswerByIdMeta,
  UpdateAnswerByIdPayload
>(updateAnswerByIdActions);
