import {
  GetListeningPathsByCommunityMeta,
  GetListeningPathsByCommunityPayload,
} from 'types/store/GetListeningPathsByCommunityState';
import { createAsyncReducer } from 'utils/Redux';

import { getListeningPathsByCommunityActions } from '../actions';

export default createAsyncReducer<
  GetListeningPathsByCommunityMeta,
  GetListeningPathsByCommunityPayload
>(getListeningPathsByCommunityActions);
