import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'EDIT_WIDGET';

const asyncAction = createAsyncAction<GenericResponse>(ns);

const editWidgetAction = {
  ...asyncAction,
};

export default editWidgetAction;
