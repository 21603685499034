import {
  GetApprovalsByStoryIdMeta,
  GetApprovalsByStoryIdPayload,
} from 'types/store/GetApprovalsByStoryIdState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_APPROVALS_BY_STORY_ID';
const asyncAction = createAsyncAction<
  GetApprovalsByStoryIdMeta,
  GetApprovalsByStoryIdPayload
>(ns);

const getApprovalsByStoryIdActions = {
  ...asyncAction,
};

export default getApprovalsByStoryIdActions;
