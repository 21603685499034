import { SmartRecruiterState } from 'types/store/SmartRecruiter';

import {
  getCheckAuthSmartRecruiterActions,
  getListSmartRecruiterActions,
  getSRTokenActions,
  getSmartRecruiterActions,
} from '../actions';

const DEFAULT_STATE: SmartRecruiterState = {
  isConnected: false,
  authLink: '',
  jobDescriptions: { data: null, loading: null, error: null },
  hasToken: {
    loading: false,
    data: null,
  },
};

const DEFAULT_ACTION: any = {
  type: '',
  payload: {},
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  switch (action.type) {
    case getListSmartRecruiterActions.REQUEST:
      return {
        ...state,
        jobDescriptions: { ...state.jobDescriptions, loading: true },
      };
    case getListSmartRecruiterActions.SUCCESS:
      return {
        ...state,
        jobDescriptions: {
          ...state.jobDescriptions,
          data: action.payload,
          loading: false,
        },
      };
    case getListSmartRecruiterActions.FAILED:
      return {
        ...state,
        jobDescriptions: {
          ...state.jobDescriptions,
          error: action.error,
          loading: false,
        },
      };
      return {
        ...state,
        jobDescription: {
          error: action.error,
          loading: false,
        },
      };

    case getCheckAuthSmartRecruiterActions.REQUEST:
      return {
        ...state,
      };
    case getCheckAuthSmartRecruiterActions.SUCCESS:
      return {
        ...state,
        isConnected: action.payload.connected,
        authLink: action.payload.authLink,
      };
    case getCheckAuthSmartRecruiterActions.FAILED:
      return {
        ...state,
        isConnected: false,
      };

    // Get SR Token API
    case getSRTokenActions.REQUEST:
      return {
        ...state,
        hasToken: {
          loading: true,
          data: null,
        },
      };
    case getSRTokenActions.SUCCESS:
      return {
        ...state,
        hasToken: {
          loading: false,
          data: true,
        },
      };
    case getSRTokenActions.FAILED:
      return {
        ...state,
        hasToken: {
          loading: false,
          data: false,
        },
      };
    default:
      return state;
  }
};
