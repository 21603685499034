import {
  UploadAnswerVideoMeta,
  UploadAnswerVideoPayload,
} from 'types/store/UploadAnswerVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPLOAD_ANSWER_VIDEO';
const asyncAction = createAsyncAction<
  UploadAnswerVideoMeta,
  UploadAnswerVideoPayload
>(ns);

const uploadAnswerVideoActions = {
  ...asyncAction,
};

export default uploadAnswerVideoActions;
