import React from 'react';

import { SVGProps } from './SVG.props';

const Analytics = (props: SVGProps): JSX.Element => {
  return (
    <svg width={14} height={14} viewBox="0 0 14 14" fill="none" {...props}>
      <path
        d="M7 .333a6.667 6.667 0 103.287 12.46h.08A6.667 6.667 0 007 .333zm.667 1.38a5.333 5.333 0 014.62 4.62h-4.62v-4.62zM7 12.333a5.334 5.334 0 01-.667-10.62V7c.003.08.019.159.047.233v.1l2.667 4.58a5.208 5.208 0 01-2.047.42zm3.22-1.093L8.153 7.667h4.134a5.335 5.335 0 01-2.067 3.573z"
        fill="#070550"
      />
    </svg>
  );
};

export default Analytics;
