import { put, call, takeLatest, select } from 'redux-saga/effects';
import { shareStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { ShareStoryMeta, ShareStoryPayload } from 'types/store/ShareStory';
import { Store } from 'types/store/Store';

import { shareStoryActions } from '../actions';

function* shareStoryRequest(action: AsyncAction<ShareStoryMeta, ShareStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(shareStory, action.meta, state.auth.token);
      yield put(shareStoryActions.success(data));
    } catch (e: any) {
      yield put(shareStoryActions.failed(e.message));
    }
  }
}

// function* shareStorySuccess(action: AsyncAction<ShareStoryMeta, ShareStoryPayload>): any {
//   yield put()
// }

function* shareStoryWatcher(): any {
  yield takeLatest(shareStoryActions.REQUEST, shareStoryRequest);
  // yield takeLatest(shareStoryActions.SUCCESS, shareStorySuccess);
}

export default shareStoryWatcher;
