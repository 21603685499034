import { UpdateContentMarketplaceMeta, UpdateContentMarketplacePayload } from 'types/store/UpdateContentMarketplace';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_CONTENT_MARKETPLACE_NS';

const asyncAction = createAsyncAction<UpdateContentMarketplaceMeta, UpdateContentMarketplacePayload>(ns);

const updateContentMarketplaceActions = { ...asyncAction };

export default updateContentMarketplaceActions;
