import { UpdatePageMeta, UpdatePagePayload } from 'types/store/UpdatePageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_PAGE';

const asyncAction = createAsyncAction<UpdatePageMeta, UpdatePagePayload>(ns);

const updatePageActions = {
  ...asyncAction,
};

export default updatePageActions;
