import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getShowcasePageDetails } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcasePageDetailsMeta,
  GetShowcasePageDetailsPayload,
} from 'types/store/GetShowcasePageDetailsState';
import { Store } from 'types/store/Store';

import { getShowcasePageDetailsActions } from '../actions';

function* getShowcasePageDetailsRequest(
  action: AsyncAction<
    GetShowcasePageDetailsMeta,
    GetShowcasePageDetailsPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getShowcasePageDetails,
        action.meta,
        state.auth.token
      );
      yield put(getShowcasePageDetailsActions.success(data));
    } catch (e: any) {
      yield put(getShowcasePageDetailsActions.failed(e.message));
    }
  } else {
    yield put(getShowcasePageDetailsActions.failed('Token not found'));
  }
}

function* getShowcasePageDetailsSuccess(
  action: AsyncAction<
    GetShowcasePageDetailsMeta,
    GetShowcasePageDetailsPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcasePageDetailsWatcher(): any {
  yield takeLatest(
    getShowcasePageDetailsActions.REQUEST,
    getShowcasePageDetailsRequest
  );
  yield takeLatest(
    getShowcasePageDetailsActions.SUCCESS,
    getShowcasePageDetailsSuccess
  );
}

export default getShowcasePageDetailsWatcher;
