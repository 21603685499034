import {
    UpsertDataShowcasePageDetailsMeta,
    UpsertDataShowcasePageDetailsPayload,
} from 'types/store/UpsertDataShowcasePageDetailsState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertDataShowcasePageDetailsActions } from '../actions';

export default createAsyncReducer<
    UpsertDataShowcasePageDetailsMeta,
    UpsertDataShowcasePageDetailsPayload
>(upsertDataShowcasePageDetailsActions);
