import { CreateEmailTemplateMeta, CreateEmailTemplatePayload } from 'types/store/CreateEmailTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_EMAIL_TEMPLATE_NS';

const asyncAction = createAsyncAction<CreateEmailTemplateMeta, CreateEmailTemplatePayload>(ns);

const createEmailTemplateActions = { ...asyncAction };

export default createEmailTemplateActions;
