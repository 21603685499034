import {
  UpdateUserByIdMeta,
  UpdateUserByIdPayload,
} from 'types/store/UpdateUserById';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_USER_BY_ID';
const asyncAction = createAsyncAction<
  UpdateUserByIdMeta,
  UpdateUserByIdPayload
>(ns);

const updateUserByIdActions = {
  ...asyncAction,
};

export default updateUserByIdActions;
