import React from 'react';

import { SVGProps } from './SVG.props';

const Sort = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={8}
      height={13}
      viewBox="0 0 8 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M2.092 4.508L4 2.592l1.908 1.916a.833.833 0 001.184 0 .833.833 0 000-1.183l-2.5-2.5a.833.833 0 00-1.184 0l-2.5 2.5a.837.837 0 001.184 1.183zm3.816 3.817L4 10.242 2.092 8.325A.837.837 0 00.908 9.508l2.5 2.5a.834.834 0 001.184 0l2.5-2.5a.837.837 0 10-1.184-1.183z"
        fill="#070550"
      />
    </svg>
  );
};

export default Sort;
