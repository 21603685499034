import {
  RegisterAdvocateMeta,
  RegisterAdvocatePayload,
} from 'types/store/RegisterAdvocateState';
import { createAsyncReducer } from 'utils/Redux';

import { registerAdvocateActions } from '../actions';

export default createAsyncReducer<
  RegisterAdvocateMeta,
  RegisterAdvocatePayload
>(registerAdvocateActions);
