import {
  UpdateUserSettingMeta,
  UpdateUserSettingPayload,
} from 'types/store/UpdateUserSettingState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_USER_SETTING';
const asyncAction = createAsyncAction<
  UpdateUserSettingMeta,
  UpdateUserSettingPayload
>(ns);

const updateUserSettingActions = {
  ...asyncAction,
};

export default updateUserSettingActions;
