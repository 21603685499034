import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ACTION_LIST';

const asyncAction = createAsyncAction<any, GenericResponse>(ns);

const getActionList = { ...asyncAction };

export default getActionList;
