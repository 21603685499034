/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconAudio = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 2C8.36819 2 8.66667 2.29848 8.66667 2.66667L8.66667 13.3333C8.66667 13.7015 8.36819 14 8 14C7.63181 14 7.33333 13.7015 7.33333 13.3333L7.33333 2.66667C7.33333 2.29848 7.63181 2 8 2Z"
        fill={fill || '#F1B422'}
      />
      <path
        d="M2.66667 4.66667C3.03486 4.66667 3.33333 4.96514 3.33333 5.33333L3.33333 10.6667C3.33333 11.0349 3.03486 11.3333 2.66667 11.3333C2.29848 11.3333 2 11.0349 2 10.6667V5.33333C2 4.96514 2.29848 4.66667 2.66667 4.66667Z"
        fill={fill || '#F1B422'}
      />
      <path
        d="M14 5.33333C14 4.96514 13.7015 4.66667 13.3333 4.66667C12.9651 4.66667 12.6667 4.96514 12.6667 5.33333V10.6667C12.6667 11.0349 12.9651 11.3333 13.3333 11.3333C13.7015 11.3333 14 11.0349 14 10.6667V5.33333Z"
        fill={fill || '#F1B422'}
      />
      <path
        d="M5.33333 6C5.70152 6 6 6.29848 6 6.66667L6 9.33333C6 9.70152 5.70152 10 5.33333 10C4.96514 10 4.66667 9.70152 4.66667 9.33333L4.66667 6.66667C4.66667 6.29848 4.96514 6 5.33333 6Z"
        fill={fill || '#F1B422'}
      />
      <path
        d="M11.3333 6.66667C11.3333 6.29848 11.0349 6 10.6667 6C10.2985 6 10 6.29848 10 6.66667L10 9.33333C10 9.70152 10.2985 10 10.6667 10C11.0349 10 11.3333 9.70152 11.3333 9.33333V6.66667Z"
        fill={fill || '#F1B422'}
      />
    </svg>
  );
};

export default IconAudio;
