import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import { DashboardState, DashboardPayload } from 'types/store/DashboardState';

import { dashboardActions } from '../actions';

const DEFAULT_STATE: DashboardState = {
  page: 1,
  pathList: [
    'Stories',
    'Responses',
    'Choose How',
    'Story Editor',
    'Upload Finished Story',
  ],
  advocates: [],
  statusEvents: [],
  selectedStatuses: [],
  selectedStoryFormat: undefined,
  selectedAdvocates: [],
  // activeTab: 'Content Pipeline',
  order_by: undefined,
  order_type: undefined,
  smart_group_ids: undefined,
};

const DEFAULT_ACTION: Action<DashboardPayload> = {
  type: '',
  payload: {
    // storyId: '',
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  const localReducer = (): DashboardPayload => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [dashboardActions.UPDATE]: localReducer(),
    [dashboardActions.CLEAR]: DEFAULT_STATE,
  });
};
