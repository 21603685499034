import {
  DuplicatePageMeta,
  DuplicatePagePayload,
} from 'types/store/DuplicatePageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DUPLICATE_PAGE';
const asyncAction = createAsyncAction<DuplicatePageMeta, DuplicatePagePayload>(
  ns
);

const duplicatePageActions = {
  ...asyncAction,
};

export default duplicatePageActions;
