import {
  GetReviewedStoriesMeta,
  GetReviewedStoriesPayload,
} from 'types/store/GetReviewedStoriesState';
import { createAsyncReducer } from 'utils/Redux';

import { getReviewedStoriesActions } from '../actions';

export default createAsyncReducer<
  GetReviewedStoriesMeta,
  GetReviewedStoriesPayload
>(getReviewedStoriesActions);
