import {
    ShowcasePageCheckUrlMeta,
    ShowcasePageCheckUrlPayload,
} from 'types/store/ShowcasePageCheckUrlState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SHOWCASE_PAGE_CHECK_URL';

const asyncAction = createAsyncAction<
    ShowcasePageCheckUrlMeta,
    ShowcasePageCheckUrlPayload
>(ns);

const showcasePageCheckUrlActions = {
    ...asyncAction,
};

export default showcasePageCheckUrlActions;
