import {
  DeleteShowcaseArticleMeta,
  DeleteShowcaseArticlePayload,
} from 'types/store/DeleteShowcaseArticleState';
import { createAsyncReducer } from 'utils/Redux';

import { deleteShowcaseArticleActions } from '../actions';

export default createAsyncReducer<
  DeleteShowcaseArticleMeta,
  DeleteShowcaseArticlePayload
>(deleteShowcaseArticleActions);
