import {
    DownloadResponsesVideoStoryMeta,
    DownloadResponsesVideoStoryPayload,
} from 'types/store/DownloadResponsesVideoStoryState';
import { createAsyncReducer } from 'utils/Redux';

import { downloadResponsesActions } from '../actions';

export default createAsyncReducer<
    DownloadResponsesVideoStoryMeta,
    DownloadResponsesVideoStoryPayload
>(downloadResponsesActions);
