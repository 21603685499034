import React from 'react';

import { SVGProps } from './SVG.props';

const Individual = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={20 || width}
      height={21 || height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.71 11.212a6 6 0 10-7.42 0 10 10 0 00-6.22 8.18 1.006 1.006 0 102 .22 8 8 0 0115.9 0 1 1 0 001 .89h.11a1 1 0 00.88-1.1 10 10 0 00-6.25-8.19zm-3.71-.71a4 4 0 110-8 4 4 0 010 8z"
        fill={'#070550' || fill}
      />
    </svg>
  );
};

export default Individual;
