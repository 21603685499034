import {
    GetShowcasePagesByCompanyIdMeta,
    GetShowcasePagesByCompanyIdPayload,
} from 'types/store/GetShowcasePagesByCompanyId';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcasePagesByCompanyIdActions } from '../actions';

export default createAsyncReducer<
    GetShowcasePagesByCompanyIdMeta,
    GetShowcasePagesByCompanyIdPayload
>(getShowcasePagesByCompanyIdActions);
