import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateDefaultCard } from 'services/payment';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  DefaultCardMeta,
  DefaultCardPayload
} from 'types/store/UpdateDefaultCard';

import {
  getSavedCardsActions,
  updateDefaultCardActions
} from '../actions';

function* updateDefaultCardRequest(
  action: AsyncAction<DefaultCardMeta, DefaultCardPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateDefaultCard, action.meta, state.auth.token);
      yield put(updateDefaultCardActions.success(data));
    } catch (e: any) {
      yield put(updateDefaultCardActions.failed(e.message));
    }
  }
}

function* updateDefaultCardSuccess(
  action: AsyncAction<DefaultCardMeta, DefaultCardPayload>
) {
  yield put(getSavedCardsActions.request({}));
}

function* updateDefaultCardWatcher(): any {
  yield takeLatest(updateDefaultCardActions.REQUEST, updateDefaultCardRequest);
  yield takeLatest(updateDefaultCardActions.SUCCESS, updateDefaultCardSuccess);
}

export default updateDefaultCardWatcher;
