import { MaskAsDefaultSmsTemplateMeta, MaskAsDefaultSmsTemplatePayload } from 'types/store/MaskAsDefaultSmsTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'MASK_AS_DEFAULT_SMS_TEMPLATE_NS';

const asyncAction = createAsyncAction<MaskAsDefaultSmsTemplateMeta, MaskAsDefaultSmsTemplatePayload>(ns);

const maskAsDefaultSmsTemplateActions = { ...asyncAction };

export default maskAsDefaultSmsTemplateActions;
