import { put, call, takeLatest, select } from 'redux-saga/effects';
import { advocateSendEmail } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { AdvocateSendEmailMeta, AdvocateSendEmailPayload } from 'types/store/AdvocateSendEmail';
import { Store } from 'types/store/Store';

import { advocateSendEmailActions } from '../actions';

function* advocateSendEmailRequest(action: AsyncAction<AdvocateSendEmailMeta, AdvocateSendEmailPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(advocateSendEmail, action.meta, state.auth.token);
      yield put(advocateSendEmailActions.success(data));
    } catch (e: any) {
      yield put(advocateSendEmailActions.failed(e.message));
    }
  }
}

// function* advocateSendEmailSuccess(action: AsyncAction<AdvocateSendEmailMeta, AdvocateSendEmailPayload>): any {
//   yield put()
// }

function* advocateSendEmailWatcher(): any {
  yield takeLatest(advocateSendEmailActions.REQUEST, advocateSendEmailRequest);
  // yield takeLatest(advocateSendEmailActions.SUCCESS, advocateSendEmailSuccess);
}

export default advocateSendEmailWatcher;
