import React from 'react';

import { SVGProps } from './SVG.props';

const Clock = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '11'}
      height={height || '10'}
      viewBox='0 0 11 10'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5.5 0C4.5111 0 3.5444 0.293245 2.72215 0.842652C1.89991 1.39206 1.25904 2.17295 0.880605 3.08658C0.502166 4.00021 0.40315 5.00555 0.596076 5.97545C0.789002 6.94536 1.26521 7.83627 1.96447 8.53553C2.66373 9.2348 3.55465 9.711 4.52455 9.90393C5.49446 10.0969 6.49979 9.99784 7.41342 9.6194C8.32705 9.24096 9.10794 8.6001 9.65735 7.77785C10.2068 6.95561 10.5 5.98891 10.5 5C10.5 4.34339 10.3707 3.69321 10.1194 3.08658C9.86812 2.47995 9.49983 1.92876 9.03553 1.46447C8.57124 1.00017 8.02005 0.631876 7.41342 0.380602C6.80679 0.129329 6.15661 0 5.5 0ZM5.5 9C4.70888 9 3.93552 8.7654 3.27772 8.32588C2.61992 7.88635 2.10723 7.26164 1.80448 6.53073C1.50173 5.79983 1.42252 4.99556 1.57686 4.21964C1.7312 3.44372 2.11216 2.73098 2.67157 2.17157C3.23098 1.61216 3.94372 1.2312 4.71964 1.07686C5.49556 0.922518 6.29983 1.00173 7.03074 1.30448C7.76164 1.60723 8.38635 2.11992 8.82588 2.77772C9.2654 3.43552 9.5 4.20888 9.5 5C9.5 6.06087 9.07857 7.07828 8.32843 7.82843C7.57828 8.57857 6.56087 9 5.5 9ZM5.5 2C5.36739 2 5.24022 2.05268 5.14645 2.14645C5.05268 2.24022 5 2.36739 5 2.5V4.71L3.95 5.315C3.85368 5.36958 3.7782 5.45459 3.73539 5.55669C3.69259 5.65879 3.68488 5.77221 3.71347 5.87917C3.74207 5.98612 3.80536 6.08056 3.89341 6.14767C3.98146 6.21478 4.08929 6.25077 4.2 6.25C4.28759 6.25061 4.3738 6.22819 4.45 6.185L5.75 5.435L5.795 5.39L5.875 5.325C5.89455 5.30025 5.91132 5.27342 5.925 5.245C5.9413 5.21816 5.9547 5.18967 5.965 5.16C5.9786 5.12821 5.98704 5.09445 5.99 5.06L6 5V2.5C6 2.36739 5.94732 2.24022 5.85356 2.14645C5.75979 2.05268 5.63261 2 5.5 2Z'
        fill={fill || '#FF647C'}
      />
    </svg>
  );
};

export default Clock;
