import React from 'react';

import { SVGProps } from './SVG.props';

const Minus = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={"12" || width}
      height={"2" || height}
      fill="none"
      viewBox="0 0 12 2"
    >
      <path
        fill={fill || "#070632"}
        d="M10.668.333H1.335a.667.667 0 100 1.334h9.333a.667.667 0 000-1.334z"
      ></path>
    </svg>
  );
};

export default Minus;
