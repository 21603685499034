import {
  GeneratePageMeta,
  GeneratePagePayload,
} from 'types/store/GeneratePage';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GENERATE_PAGE';
const asyncAction = createAsyncAction<GeneratePageMeta, GeneratePagePayload>(
  ns
);

const generatePageActions = {
  ...asyncAction,
};

export default generatePageActions;
