import React from 'react';

import { SVGProps } from './SVG.props';

const Japan = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M40 30.0001C40 32.4546 38.01 34.4446 35.5556 34.4446H4.44444C1.99 34.4446 0 32.4546 0 30.0001V10.0001C0 7.54566 1.99 5.55566 4.44444 5.55566H35.5556C38.01 5.55566 40 7.54566 40 10.0001V30.0001Z" fill="#EEEEEE"/>
      <path d="M19.9999 27.7777C24.2955 27.7777 27.7777 24.2955 27.7777 19.9999C27.7777 15.7044 24.2955 12.2222 19.9999 12.2222C15.7044 12.2222 12.2222 15.7044 12.2222 19.9999C12.2222 24.2955 15.7044 27.7777 19.9999 27.7777Z" fill="#ED1B2F"/>
    </svg>
  );
};

export default Japan;
