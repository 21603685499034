import React from 'react';

import { SVGProps } from './SVG.props';

const Company = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={22 || width}
      height={17 || height}
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3 8.72A4.92 4.92 0 0013 5 5 5 0 003 5a4.92 4.92 0 001.7 3.72A8 8 0 000 16a1 1 0 102 0 6 6 0 1112 0 1 1 0 002 0 8 8 0 00-4.7-7.28zM8 8a3 3 0 110-6 3 3 0 010 6zm9.74.32A5 5 0 0014 0a1 1 0 100 2 3 3 0 013 3 3 3 0 01-1.5 2.59 1 1 0 00-.05 1.7l.39.26.13.07a7 7 0 014 6.38 1 1 0 002 0 9 9 0 00-4.23-7.68z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Company;
