import { AdvocateStoryOverviewMeta, AdvocateStoryOverviewPayload } from 'types/store/AdvocateStoryOverview';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_STORY_OVERVIEW_NS';

const asyncAction = createAsyncAction<AdvocateStoryOverviewMeta, AdvocateStoryOverviewPayload>(ns);

const advocateStoryOverviewActions = { ...asyncAction };

export default advocateStoryOverviewActions;
