import { put, call, takeLatest, select } from 'redux-saga/effects';
import { checkLibraryTranscribe } from 'services/library';
import { AsyncAction } from 'types/Action';
import { CheckLibraryTranscribeMeta, CheckLibraryTranscribePayload } from 'types/store/CheckLibraryTranscribe';
import { Store } from 'types/store/Store';

import { checkLibraryTranscribeActions } from '../actions';

function* checkLibraryTranscribeRequest(action: AsyncAction<CheckLibraryTranscribeMeta, CheckLibraryTranscribePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(checkLibraryTranscribe, action.meta, state.auth.token);
      yield put(checkLibraryTranscribeActions.success(data));
    } catch (e: any) {
      yield put(checkLibraryTranscribeActions.failed(e.message));
    }
  }
}

// function* checkLibraryTranscribeSuccess(action: AsyncAction<CheckLibraryTranscribeMeta, CheckLibraryTranscribePayload>): any {
//   yield put()
// }

function* checkLibraryTranscribeWatcher(): any {
  yield takeLatest(checkLibraryTranscribeActions.REQUEST, checkLibraryTranscribeRequest);
  // yield takeLatest(checkLibraryTranscribeActions.SUCCESS, checkLibraryTranscribeSuccess);
}

export default checkLibraryTranscribeWatcher;
