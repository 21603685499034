import {
  CheckColleagueEmailsMeta,
  CheckColleagueEmailsPayload,
} from 'types/store/CheckColleagueEmails';
import { createAsyncReducer } from 'utils/Redux';

import { checkColleagueEmailsActions } from '../actions';

export default createAsyncReducer<
  CheckColleagueEmailsMeta,
  CheckColleagueEmailsPayload
>(checkColleagueEmailsActions);
