import { put, call, takeLatest, select } from 'redux-saga/effects';
import _get from 'lodash/get'
import {
  importFromUrl,
} from 'services/jobs';
import { Store } from 'types/store/Store';
import { AsyncAction } from 'types/Action';
import {
  GetImportFromUrlMeta,
  GetImportFromUrlPayload
} from 'types/store/JobsState';

import {
  getImportFromUrlActions
} from '../../actions';

function* getImportFromUrlRequest(action: AsyncAction<GetImportFromUrlMeta, GetImportFromUrlPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(importFromUrl, action.meta, state.auth.token);
      yield put(getImportFromUrlActions.success(data));
    } catch (e: any) {
      yield put(getImportFromUrlActions.failed(e.message));
    }
  }
}

function* getImportFromUrlWatcher(): any {
  yield takeLatest(getImportFromUrlActions.REQUEST, getImportFromUrlRequest);
  // yield takeLatest(getStoryVersionOptionsActions.SUCCESS, getStoryVersionOptionsSuccess);
}

export default getImportFromUrlWatcher;
