import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticSocialReachMeta {
  time: string;
  communities: string[];
}

const socialReachNS = 'ANALYTIC_ROI_SOCIAL_REACH';

const getSocialReachAsync = createAsyncAction<
  IAnalyticSocialReachMeta,
  GenericResponse
>(socialReachNS);

export default getSocialReachAsync;
