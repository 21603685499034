import { GetCompanyInfoByCompanyIdMeta, GetCompanyInfoByCompanyIdPayload } from 'types/store/GetCompanyInfoByCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_COMPANY_INFO_BY_COMPANY_ID_NS';

const asyncAction = createAsyncAction<GetCompanyInfoByCompanyIdMeta, GetCompanyInfoByCompanyIdPayload>(ns);

const getCompanyInfoByCompanyIdActions = { ...asyncAction };

export default getCompanyInfoByCompanyIdActions;
