import { takeEvery, select, call } from 'redux-saga/effects';
import { trackActivity } from 'services/tracking';
import { Action } from 'types/Action';
import { Store } from 'types/store/Store';

import { USER_ACTIVITY, ITrackingActionPayLoad } from '../actions/userActivity';

function* trackUserActivities(action: Action<ITrackingActionPayLoad>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      yield call(trackActivity, action.payload, state.auth.token);
    } catch (e: any) {
      console.error(e);
    }
  }
}

function* userActivity(): any {
  yield takeEvery([USER_ACTIVITY.TRACKING_ACTION], trackUserActivities);
}

export default userActivity;
