import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fetchActionList } from 'services/tracking';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

import { getActionList } from '../actions';

function* getActionListRequest(action: AsyncAction<any, GenericResponse>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(fetchActionList, state.auth.token);
      yield put(getActionList.success(data));
    } catch (e: any) {
      console.error(e);
    }
  }
}

function* getActionListWatcher(): any {
  yield takeLatest(getActionList.REQUEST, getActionListRequest);
}

export default getActionListWatcher;
