import { MaskAsDefaultEmailTemplateMeta, MaskAsDefaultEmailTemplatePayload } from 'types/store/MaskAsDefaultEmailTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'MASK_AS_DEFAULT_EMAIL_TEMPLATE_NS';

const asyncAction = createAsyncAction<MaskAsDefaultEmailTemplateMeta, MaskAsDefaultEmailTemplatePayload>(ns);

const maskAsDefaultEmailTemplateActions = { ...asyncAction };

export default maskAsDefaultEmailTemplateActions;
