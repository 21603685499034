import {
  CreateStoryMeta,
  CreateStoryPayload,
} from 'types/store/CreateStoryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_STORY';

const asyncAction = createAsyncAction<CreateStoryMeta, CreateStoryPayload>(ns);

const createStoryActions = {
  ...asyncAction,
};

export default createStoryActions;
