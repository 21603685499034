import {
  GetBrandGuidelinesByCompanyIdMeta,
  GetBrandGuidelinesByCompanyIdPayload,
} from 'types/store/GetBrandGuidelinesByCompanyId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_RABND_GUIDELINES_BY_COMPANY_ID';
const asyncAction = createAsyncAction<
  GetBrandGuidelinesByCompanyIdMeta,
  GetBrandGuidelinesByCompanyIdPayload
>(ns);

const getBrandGuidelinesByCompanyIdActions = {
  ...asyncAction,
};

export default getBrandGuidelinesByCompanyIdActions;
