import { StylesConfig } from 'react-select';
import theme from 'utils/Theme';

const styledDropdown: StylesConfig<any, any> = {
    control: (styles, state) => ({
        ...styles,
        borderRadius: '12px',
        backgroundColor: 'white',
        fontFamily: 'Objective',
        fontSize: '14px',
        lineHeight: '24px',
        color: theme.brand.primary,
        boxShadow: 'none'
    }),
    singleValue: (provided, state) => ({
        ...provided,
        color: theme.brand.primary
    }),
    multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: theme.grey.shade3,
        color: theme.brand.primary,
        letterSpacing: '3px',
        padding: '4px 16px',
        borderRadius: '4px',
        textTransform: 'uppercase',
        fontFamily: 'Objective Bold',
        margin: '0 2px',
        fontSize: '14px'
    }),
    multiValueLabel: (provided, state) => ({
        ...provided,
        color: theme.brand.primary,
        fontSize: '10px',
        lineHeight: '15px',
        padding: '0'
    }),
    multiValueRemove: (provided, state) => ({
        ...provided,
        backgroundColor: theme.brand.primary,
        color: 'white',
        borderRadius: '50%',
        padding: '1px 1px',
        marginLeft: '3px',
        ':hover': {
            backgroundColor: theme.brand.secondary,
            color: theme.brand.primary
        },
        svg: {
            height: '12px',
            width: '12px'
        }
    }),
    option: (provided: any, state) => ({
        ...provided,
        color: theme.brand.primary,
        fontFamily: 'Objective',
        fontSize: '14px',
        backgroundColor: '#fff',
        ':active': {
            ...provided[':active'],
            backgroundColor: theme.brand.secondary
        },
        '&:hover': {
            backgroundColor: theme.product.success.light
        }
    }),
    valueContainer: (provided: any, state) => ({
        ...provided,
        padding: '8px 12px'
    }),
    input: (provided: any, state) => ({
        ...provided,
        padding: '0',
        margin: '0'
    }),
    indicatorSeparator: (provided: any) => ({
        ...provided,
        display: 'none'
    })
};

export default styledDropdown;