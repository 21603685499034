import { GetCustomeFieldMeta, GetCustomeFielPayload } from 'types/store/GetCustomerField';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_CUSTOMER_FIELD';
const asyncAction = createAsyncAction<GetCustomeFieldMeta, GetCustomeFielPayload>(ns);

const getCustomeField = {
  ...asyncAction,
};

export default getCustomeField;
