import {
   GetCategoryByStoryIdMeta,
   GetCategoryByStoryIdPayload
  } from 'types/store/GetCategoryByStoryId';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'GET_CATEGORY_BY_STORY_ID';
  
  const asyncAction = createAsyncAction<GetCategoryByStoryIdMeta, GetCategoryByStoryIdPayload>(ns);
  
  const getCategoryByStoryIdActions = {
    ...asyncAction,
  };
  
  export default getCategoryByStoryIdActions;
  