import React from 'react';

import { SVGProps } from './SVG.props';

const UploadImage = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 28 28"
      fill={fill || 'none'}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M26.5466 9.52147C26.5466 9.52147 26.5466 9.52147 26.5466 9.4548C25.6071 6.88977 23.9025 4.67518 21.6633 3.11062C19.424 1.54606 16.7583 0.707031 14.0266 0.707031C11.2949 0.707031 8.62918 1.54606 6.38995 3.11062C4.15072 4.67518 2.44607 6.88977 1.5066 9.4548C1.5066 9.4548 1.5066 9.4548 1.5066 9.52147C0.457435 12.416 0.457435 15.5869 1.5066 18.4815C1.5066 18.4815 1.5066 18.4815 1.5066 18.5481C2.44607 21.1132 4.15072 23.3278 6.38995 24.8923C8.62918 26.4569 11.2949 27.2959 14.0266 27.2959C16.7583 27.2959 19.424 26.4569 21.6633 24.8923C23.9025 23.3278 25.6071 21.1132 26.5466 18.5481C26.5466 18.5481 26.5466 18.5481 26.5466 18.4815C27.5958 15.5869 27.5958 12.416 26.5466 9.52147V9.52147ZM3.67994 16.6681C3.21757 14.9204 3.21757 13.0825 3.67994 11.3348H6.15993C5.94664 13.1062 5.94664 14.8968 6.15993 16.6681H3.67994ZM4.77327 19.3348H6.63993C6.95289 20.5239 7.40022 21.6736 7.97327 22.7615C6.66566 21.8707 5.5726 20.7002 4.77327 19.3348ZM6.63993 8.66814H4.77327C5.56109 7.30692 6.64019 6.13676 7.93327 5.24147C7.37401 6.33113 6.94012 7.4807 6.63993 8.66814V8.66814ZM12.6666 24.2681C11.029 23.0664 9.8788 21.3151 9.4266 19.3348H12.6666V24.2681ZM12.6666 16.6681H8.85327C8.60445 14.8991 8.60445 13.1039 8.85327 11.3348H12.6666V16.6681ZM12.6666 8.66814H9.4266C9.8788 6.68783 11.029 4.93656 12.6666 3.7348V8.66814ZM23.2266 8.66814H21.3599C21.047 7.47901 20.5996 6.32939 20.0266 5.24147C21.3342 6.13223 22.4273 7.30272 23.2266 8.66814ZM15.3333 3.7348C16.9709 4.93656 18.1211 6.68783 18.5733 8.66814H15.3333V3.7348ZM15.3333 24.2681V19.3348H18.5733C18.1211 21.3151 16.9709 23.0664 15.3333 24.2681V24.2681ZM19.1466 16.6681H15.3333V11.3348H19.1466C19.3954 13.1039 19.3954 14.8991 19.1466 16.6681ZM20.0666 22.7615C20.6396 21.6736 21.087 20.5239 21.3999 19.3348H23.2666C22.4673 20.7002 21.3742 21.8707 20.0666 22.7615ZM24.3199 16.6681H21.8399C21.9484 15.7834 22.0018 14.8928 21.9999 14.0015C22.0014 13.1101 21.948 12.2196 21.8399 11.3348H24.3199C24.7823 13.0825 24.7823 14.9204 24.3199 16.6681Z"
        fill={fill || '#91DBB6'}
      />
    </svg>
  );
};

export default UploadImage;
