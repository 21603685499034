import {
    PublishShowcaseMeta,
    PublishShowcasePayload,
} from 'types/store/PublishShowcaseState';
import { createAsyncReducer } from 'utils/Redux';

import { publishShowcaseAction } from '../actions';

export default createAsyncReducer<
    PublishShowcaseMeta,
    PublishShowcasePayload
>(publishShowcaseAction);
