import {
   GetTransactionByStripeIdMeta,
   GetTransactionByStripeIdPayload
  } from 'types/store/GetTransactionByStripeId';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { getTransactionByStripeIdActions } from '../actions';
  
  export default createAsyncReducer<GetTransactionByStripeIdMeta, GetTransactionByStripeIdPayload>(
    getTransactionByStripeIdActions
  );
  