/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { SuggestionTopicPayload } from 'store/actions/storyDetailV2/storyDetailV2.action';
// define payload
import { GenericResponse } from 'types/GenericResponse';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const getAssetLibrary = (
  // master
  token: string,
  data?: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset-library`,
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAssetLibraryFolder = (
  // folder
  folderId: string,
  token: string,
  advocateId?: string,
  data?: any,
): AxiosPromise<GenericResponse> => {
  const url = advocateId
    ? `${BASE_URL}/asset-library/folder/${folderId}?advocateId=${advocateId}`
    : `${BASE_URL}/asset-library/folder/${folderId}`;

  return axios({
    method: 'get',
    url: url,
    params: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFolderInfoDetail = (
  folderId: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset-library/folder/detail/${folderId}`,
    // data: {},
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getAssetInfoDetail = (
  assetId: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset-library/asset/detail/${assetId}`,
    // data: {},
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editAssetDetail = (
  assetId: string,
  token: string,
  data: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/asset-library/edit/${assetId}`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteAsset = (
  assetId: string,
  token: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'delete',
    url: `${BASE_URL}/asset-library/delete/${assetId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createFolder = (
  data: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/asset-library/folder/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadAssets = (
  data: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/asset-library/asset/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const searchAssetLibrarySv = (
  token: string,
  data?: any,
  params?: any,
): AxiosPromise<GenericResponse> => {
  const querySearch = data ? `/${data?.value ? data?.value : data}` : '';
  const urlFilter = params?.type
    ? `${BASE_URL}/asset-library/search/type${querySearch}`
    : `${BASE_URL}/asset-library/search${querySearch}`;

  return axios({
    method: 'get',
    url: urlFilter,
    params: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getTabsAssetLibrarySv = (
  token: string,
  // data?: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset-library/smart-upload`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getImageMyStoriesSv = (
  token: string,
  storyId: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset-library/advocate-story/${storyId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getImageByAdvocateSv = (
  token: string,
  storyId: string,
  advocateId: string,
): AxiosPromise<GenericResponse<any[]>> => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/asset-library/advocate-story/${storyId}?advocateId=${advocateId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const checkDuplicate = (
  token: string,
  data: any,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/asset-library/asset/validate`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
