import { GetAdvocateByStoryMeta, GetAdvocateByStoryPayload } from 'types/store/GetAdvocateByStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ADVOCATE_BY_STORY_NS';

const asyncAction = createAsyncAction<GetAdvocateByStoryMeta, GetAdvocateByStoryPayload>(ns);

const getAdvocateByStoryActions = { ...asyncAction };

export default getAdvocateByStoryActions;
