import {
  GetInterestByCommunityMeta,
  GetInterestByCommunityPayload,
} from 'types/store/GetInterestByCommunityState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_INTEREST_BY_COMMUNITY';

const asyncAction = createAsyncAction<
  GetInterestByCommunityMeta,
  GetInterestByCommunityPayload
>(ns);

const getInterestByCommunityActions = {
  ...asyncAction,
};

export default getInterestByCommunityActions;
