import {
    GetDataShowcasePublicPageDetailsMeta,
    GetDataShowcasePublicPageDetailsPayload,
} from 'types/store/GetDataShowcasePublicPageDetailsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_DATA_SHOWCASE_PUBLIC_PAGE_DETAILS';
const asyncAction = createAsyncAction<
    GetDataShowcasePublicPageDetailsMeta,
    GetDataShowcasePublicPageDetailsPayload
>(ns);

const getDataShowcasePublicPageDetailsActions = {
    ...asyncAction,
};

export default getDataShowcasePublicPageDetailsActions;
