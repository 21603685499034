import React from 'react';

import { SVGProps } from './SVG.props';

const CameraFill = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (

    <svg width={width || "28"} height={height || "22"} viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path opacity="0.32" fillRule="evenodd" clipRule="evenodd" d="M24.3519 21.9331C26.6727 21.9331 27.8386 20.7889 27.8386 18.5223V7.0026C27.8386 4.73607 26.6727 3.6028 24.3519 3.6028H21.3273C20.4612 3.6028 20.1836 3.42676 19.695 2.87663L18.7955 1.88639C18.2403 1.28125 17.674 0.951172 16.5303 0.951172H12.0997C10.956 0.951172 10.3897 1.28125 9.83447 1.88639L8.94614 2.87663C8.45756 3.41576 8.17995 3.6028 7.31383 3.6028H4.35585C2.03508 3.6028 0.869141 4.73607 0.869141 7.0026V18.5223C0.869141 20.7889 2.03508 21.9331 4.35585 21.9331H24.3519ZM14.294 18.216C10.9044 18.216 8.17697 15.5209 8.17697 12.1493C8.17697 8.77768 10.9044 6.08262 14.294 6.08262C17.6949 6.08262 20.4223 8.77768 20.4223 12.1493C20.4223 15.5209 17.6949 18.216 14.294 18.216ZM14.2944 16.266C16.5902 16.266 18.4543 14.4317 18.4543 12.1493C18.4543 9.86688 16.5902 8.03262 14.2944 8.03262C11.9985 8.03262 10.145 9.86688 10.145 12.1493C10.145 14.4317 12.009 16.266 14.2944 16.266Z"
        fill={fill || "black"}
      />
    </svg>

  );
};

export default CameraFill;
