import { DeleteSmsTemplateMeta, DeleteSmsTemplatePayload } from 'types/store/DeleteSmsTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_SMS_TEMPLATE_NS';

const asyncAction = createAsyncAction<DeleteSmsTemplateMeta, DeleteSmsTemplatePayload>(ns);

const deleteSmsTemplateActions = { ...asyncAction };

export default deleteSmsTemplateActions;
