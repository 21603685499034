import { UpsertContentMarketplaceMeta, UpsertContentMarketplacePayload } from 'types/store/UpsertContentMarketplace';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_CONTENT_MARKETPLACE_NS';

const asyncAction = createAsyncAction<UpsertContentMarketplaceMeta, UpsertContentMarketplacePayload>(ns);

const upsertContentMarketplaceActions = { ...asyncAction };

export default upsertContentMarketplaceActions;
