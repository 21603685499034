import React from 'react';

import { SVGProps } from './SVG.props';

const MartecText = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 87}
      height={height || 24}
      viewBox="0 0 87 24"
      fill="none"
      {...props}
    >
      <path
        d="M3.67986 8.84793H0.255859V6.60793H9.79186V8.84793H6.36786V17.9999H3.67986V8.84793Z"
        fill={fill || '#060548'}
      />
      <path
        d="M15.5344 9.48793C17.4064 9.48793 18.6544 10.8639 18.6544 12.8159V17.9999H16.2544V13.2959C16.2544 12.3039 15.6784 11.5999 14.7184 11.5999C13.7904 11.5999 13.0224 12.3519 13.0224 13.3919V17.9999H10.6224V6.12793H13.0224V10.7359C13.5664 9.93593 14.5424 9.48793 15.5344 9.48793Z"
        fill={fill || '#060548'}
      />
      <path
        d="M23.9681 16.4799C24.8321 16.4799 25.5681 16.0159 25.8561 15.3919L27.9521 15.8559C27.5201 17.2799 25.8401 18.3199 23.9041 18.3199C21.2641 18.3199 19.3921 16.4799 19.3921 13.8399C19.3921 11.2799 21.2641 9.48793 23.9041 9.48793C26.5281 9.48793 28.3201 11.2799 28.3201 13.8719C28.3201 14.0799 28.3201 14.4159 28.2881 14.5919L21.8721 14.5759C22.0801 15.7279 22.8801 16.4799 23.9681 16.4799ZM21.8561 13.1359H25.8881C25.7441 12.0959 24.9441 11.3279 23.8561 11.3279C22.8001 11.3279 22.0641 12.0479 21.8561 13.1359Z"
        fill={fill || '#060548'}
      />
      <path
        d="M34.2703 17.9999H31.6463V6.60793H35.1983L38.4143 14.9439L41.5983 6.60793H45.1983V17.9999H42.5743V9.85593L39.4223 17.9999H37.4063L34.2703 9.85593V17.9999Z"
        fill={fill || '#060548'}
      />
      <path
        d="M52.5858 10.9439V9.80793H54.9858V17.9999H52.5858V16.8639C51.9458 17.7759 50.9698 18.3199 49.8018 18.3199C47.4978 18.3199 45.8818 16.4959 45.8818 13.9039C45.8818 11.3119 47.4978 9.48793 49.8018 9.48793C50.9698 9.48793 51.9458 10.0319 52.5858 10.9439ZM50.4258 16.2079C51.6738 16.2079 52.5698 15.2319 52.5698 13.9039C52.5698 12.5759 51.6738 11.5999 50.4258 11.5999C49.1778 11.5999 48.2818 12.5759 48.2818 13.9039C48.2818 15.2319 49.1778 16.2079 50.4258 16.2079Z"
        fill={fill || '#060548'}
      />
      <path
        d="M60.513 9.50393C60.833 9.50393 61.169 9.58393 61.489 9.71193V11.8399C61.025 11.6479 60.497 11.5359 60.145 11.5359C59.105 11.5359 58.417 12.4159 58.417 13.6479V17.9999H56.017V9.80793H58.321V10.8479C58.801 10.0319 59.601 9.50393 60.513 9.50393Z"
        fill={fill || '#060548'}
      />
      <path
        d="M63.259 15.3439V11.6319H62.107V9.80793H63.371L63.643 7.74393H65.659V9.80793H67.515V11.6319H65.659V15.1039C65.659 15.7919 66.075 16.2559 66.747 16.2559C66.971 16.2559 67.323 16.2079 67.595 16.1439L67.579 18.0639C67.099 18.2239 66.459 18.3199 65.947 18.3199C64.315 18.3199 63.259 17.0879 63.259 15.3439Z"
        fill={fill || '#060548'}
      />
      <path
        d="M72.7095 16.4799C73.5735 16.4799 74.3095 16.0159 74.5975 15.3919L76.6935 15.8559C76.2615 17.2799 74.5815 18.3199 72.6455 18.3199C70.0055 18.3199 68.1335 16.4799 68.1335 13.8399C68.1335 11.2799 70.0055 9.48793 72.6455 9.48793C75.2695 9.48793 77.0615 11.2799 77.0615 13.8719C77.0615 14.0799 77.0615 14.4159 77.0295 14.5919L70.6135 14.5759C70.8215 15.7279 71.6215 16.4799 72.7095 16.4799ZM70.5975 13.1359H74.6295C74.4855 12.0959 73.6855 11.3279 72.5975 11.3279C71.5415 11.3279 70.8055 12.0479 70.5975 13.1359Z"
        fill={fill || '#060548'}
      />
      <path
        d="M86.3148 12.7359L84.0748 13.0239C83.8668 12.1439 83.0508 11.5039 82.1388 11.5039C80.9708 11.5039 80.0588 12.5119 80.0588 13.9039C80.0588 15.2959 80.9548 16.3039 82.1388 16.3039C83.0828 16.3039 83.8668 15.6639 84.0908 14.7839L86.3308 15.0719C86.0268 16.9599 84.2348 18.3199 82.1228 18.3199C79.5468 18.3199 77.6588 16.4639 77.6588 13.9039C77.6588 11.3439 79.5308 9.48793 82.1228 9.48793C84.2668 9.48793 86.0588 10.8479 86.3148 12.7359Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default MartecText;
