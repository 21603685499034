import {
  SendApprovalNotificationMeta,
  SendApprovalNotificationPayload,
} from 'types/store/SendApprovalNotificationState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SEND_APPROVAL_NOTIFICATION';

const asyncAction = createAsyncAction<
  SendApprovalNotificationMeta,
  SendApprovalNotificationPayload
>(ns);
const sendApprovalNotificationActions = {
  ...asyncAction,
};

export default sendApprovalNotificationActions;
