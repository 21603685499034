import axios, { AxiosResponse } from 'axios';
import { API } from 'consts';
import { EmailTemplateType } from 'routes/Employer/Settings/Items/EmailTemplate/EmailTemplate.props';
import {
  SendSMS,
  CreateEmail,
  CreateSMS,
  SendEmail,
  CopyMS,
} from 'types/store/EmployeeHub';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const qrCodeServices = (token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/employee/qrcode`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getEmailTemplateInvitePeople = (
  invitationType: string,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/email-template?type=${EmailTemplateType.InviteForAdvocate}&invitationType=${invitationType}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSMSTemplateInvitePeople = (
  invitationType: string,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/template-sms?type=${EmailTemplateType.InviteForAdvocate}&invitationType=${invitationType}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createInviteTemplate = (data: any, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/template-and-sending`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const createSMS = (data: CreateSMS, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/template-sms`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const sendSMS = (data: SendSMS, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/people-invitation/send-sms`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const createEmail = (data: CreateEmail, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/email-template`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const sendInvite = (data: SendEmail, token: string): any => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/people-invitation/send-email`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const coppyMessage = (
  data: CopyMS,
  token: string,
): Promise<AxiosResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/people-invitation/copied-message`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const replacePlaceHolder = (
  data: { template: string },
  token: string,
): Promise<AxiosResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/people-invitation/preview-message`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
