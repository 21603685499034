import {
    PurchaseCreditsMeta,
    PurchaseCreditsPayload
  } from 'types/store/PurchaseCredits';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'PURCHASE_CREDITS';
  
  const asyncAction = createAsyncAction<PurchaseCreditsMeta, PurchaseCreditsPayload>(ns);
  
  const purchaseCreditsActions = {
    ...asyncAction,
  };
  
  export default purchaseCreditsActions;
  