import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export type AccessRouterMeta = {
  platform: string;
};

const ns = 'ACCESS_ROUTER';
const asyncAction = createAsyncAction<AccessRouterMeta, GenericResponse>(ns);

const accessRouterActions = {
  ...asyncAction,
};

export default accessRouterActions;
