import {
  GetShowcaseCompanyMeta,
  GetShowcaseCompanyPayload,
} from 'types/store/GetShowcaseCompanyState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcaseCompanyActions } from '../actions';

export default createAsyncReducer<
  GetShowcaseCompanyMeta,
  GetShowcaseCompanyPayload
>(getShowcaseCompanyActions);
