import { OnboardPayload } from 'types/store/OnboardState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'ON_BOARD';
const updateAction = createUpdateAction<OnboardPayload>(ns);
const clearAction = createClearAction(ns);

const onboardActions = {
  ...updateAction,
  ...clearAction,
};

export default onboardActions;
