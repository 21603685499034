import { CommonState } from 'types/store/Common';
import { createUpdateAction } from 'utils/Redux';

const ns = 'COMMON_NS';

const updateAction = createUpdateAction<CommonState>(ns);

const commonActions = { ...updateAction };

export default commonActions;
