import React from 'react';

import { SVGProps } from './SVG.props';

const Text = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66699 2.66667C2.66699 2.29848 2.96547 2 3.33366 2H12.667C13.0352 2 13.3337 2.29848 13.3337 2.66667V4.66667C13.3337 5.03486 13.0352 5.33333 12.667 5.33333C12.2988 5.33333 12.0003 5.03486 12.0003 4.66667V3.33333H8.66699V12.6667H10.667C11.0352 12.6667 11.3337 12.9651 11.3337 13.3333C11.3337 13.7015 11.0352 14 10.667 14H5.33366C4.96547 14 4.66699 13.7015 4.66699 13.3333C4.66699 12.9651 4.96547 12.6667 5.33366 12.6667H7.33366V3.33333H4.00033V4.66667C4.00033 5.03486 3.70185 5.33333 3.33366 5.33333C2.96547 5.33333 2.66699 5.03486 2.66699 4.66667V2.66667Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default Text;
