import {
  SendApprovalNotificationMeta,
  SendApprovalNotificationPayload,
} from 'types/store/SendApprovalNotificationState';
import { createAsyncReducer } from 'utils/Redux';

import { sendApprovalNotificationActions } from '../actions';

export default createAsyncReducer<
  SendApprovalNotificationMeta,
  SendApprovalNotificationPayload
>(sendApprovalNotificationActions);
