import FileSaver from 'file-saver';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { transcribeAudio } from 'services/question';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  TranscribeAudioMeta,
  TranscribeAudioPayload,
} from 'types/store/TranscribeAudio';

import { transcribeAudioActions } from '../actions';
function* transcribeAudioRequest(
  action: AsyncAction<TranscribeAudioMeta, TranscribeAudioPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token || action?.meta?.token) {
    try {
      const { data } = yield call(
        transcribeAudio,
        action.meta,
        state.auth.token || action.meta.token || '',
      );
      yield put(transcribeAudioActions.success(data));
    } catch (e: any) {
      yield put(transcribeAudioActions.failed(e.message));
    }
  }
}

function* transcribeAudioSuccess(
  action: AsyncAction<TranscribeAudioMeta, TranscribeAudioPayload>,
) {
  //
}

function* transcribeAudioWatcher(): any {
  yield takeLatest(transcribeAudioActions.REQUEST, transcribeAudioRequest);
  yield takeLatest(transcribeAudioActions.SUCCESS, transcribeAudioSuccess);
}

export default transcribeAudioWatcher;
