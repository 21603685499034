import {
    GetSharingMeta,
    GetSharingPayload,
} from 'types/store/GetSharing';
import { createAsyncReducer } from 'utils/Redux';

import { getSharingActions } from '../actions';

export default createAsyncReducer<
    GetSharingMeta,
    GetSharingPayload
>(getSharingActions);
