import { GetHomePageMenuMeta, GetHomePageMenuPayload } from 'types/store/GetHomePageMenu';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_HOME_PAGE_MENU_NS';

const asyncAction = createAsyncAction<GetHomePageMenuMeta, GetHomePageMenuPayload>(ns);

const getHomePageMenuActions = { ...asyncAction };

export default getHomePageMenuActions;
