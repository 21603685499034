import { ArchiveStoryMeta, ArchiveStoryPayload } from 'types/store/ArchiveStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ARCHIVE_STORY_NS';

const asyncAction = createAsyncAction<ArchiveStoryMeta, ArchiveStoryPayload>(ns);

const archiveStoryActions = { ...asyncAction };

export default archiveStoryActions;
