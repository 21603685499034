import React from 'react';

import { useTheme } from 'utils/Theme';

import { SVGProps } from './SVG.props';

const Thumb = (props: SVGProps): JSX.Element => {
  const theme = useTheme();
  const { width, height, fill } = props;
  return (
    <svg
      width={width || 25}
      height={height || 16}
      viewBox="0 0 14 14"
      fill="none"
      {...props}
    >
      <path
        d="M13.2 5.72A2 2 0 0011.667 5h-3.04L9 4.046A2.753 2.753 0 006.407.333.667.667 0 005.8.726L3.9 5H2.333a2 2 0 00-2 2v4.666a2 2 0 002 2h8.487a2 2 0 001.967-1.64l.846-4.666a2 2 0 00-.433-1.64zm-9.533 6.613H2.333a.667.667 0 01-.666-.667V7a.667.667 0 01.666-.667h1.334v6zm8.666-5.213l-.846 4.666a.667.667 0 01-.667.547H5V5.806l1.813-4.08a1.407 1.407 0 01.92 1.854l-.353.953a1.333 1.333 0 001.247 1.8h3.04a.667.667 0 01.513.24.667.667 0 01.153.547z"
        fill={fill || theme.grey.shade8}
      />
    </svg>
  );
};

export default Thumb;
