import { put, call, takeLatest, select } from 'redux-saga/effects';
import { useCredits } from 'services/payment';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UseCreditsMeta,
  UseCreditsPayload
} from 'types/store/UseCredits';

import {
  useCreditsActions,
  getCompanyCreditsActions,
  getTransactionByStripeIdActions
} from '../actions';

function* useCreditsRequest(
  action: AsyncAction<UseCreditsMeta, UseCreditsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(useCredits, action.meta, state.auth.token);
      yield put(useCreditsActions.success(data));
    } catch (e: any) {
      yield put(useCreditsActions.failed(e.message));
    }
  }
}

function* useCreditsSuccess(
  action: AsyncAction<UseCreditsMeta, UseCreditsPayload>
) {
  yield put(getCompanyCreditsActions.request({}));
  yield put(getTransactionByStripeIdActions.request({}));
}

function* useCreditsWatcher(): any {
  yield takeLatest(useCreditsActions.REQUEST, useCreditsRequest);
  yield takeLatest(useCreditsActions.SUCCESS, useCreditsSuccess);
}

export default useCreditsWatcher;
