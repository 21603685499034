import { EditApproverMeta, EditApproverPayload } from 'types/store/EditApprover';
import { createAsyncAction } from 'utils/Redux';

const ns = 'EDIT_APPROVER_NS';

const asyncAction = createAsyncAction<EditApproverMeta, EditApproverPayload>(ns);

const editApproverActions = { ...asyncAction };

export default editApproverActions;
