import { GetRecruiterMeta, GetRecruiterPayload } from 'types/store/GetRecruiters';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_RECRUITER_NS';

const asyncAction = createAsyncAction<GetRecruiterMeta, GetRecruiterPayload>(ns);

const getRecruiterActions = { ...asyncAction };

export default getRecruiterActions;
