import {
    GetAllItemsMeta,
    GetAllItemsPayload
  } from 'types/store/GetAllItems';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { getAllItemsActions } from '../actions';
  
  export default createAsyncReducer<GetAllItemsMeta, GetAllItemsPayload>(
    getAllItemsActions
  );
  