import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateSmsTemplate } from 'services/sms-template';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateSmsTemplateMeta, UpdateSmsTemplatePayload } from 'types/store/UpdateSmsTemplate';

import { updateSmsTemplateActions } from '../actions';

function* updateSmsTemplateRequest(action: AsyncAction<UpdateSmsTemplateMeta, UpdateSmsTemplatePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(updateSmsTemplate, action.meta, state.auth.token);
      yield put(updateSmsTemplateActions.success(data));
    } catch (e: any) {
      yield put(updateSmsTemplateActions.failed(e.message));
    }
  }
}

// function* updateSmsTemplateSuccess(action: AsyncAction<UpdateSmsTemplateMeta, UpdateSmsTemplatePayload>): any {
//   yield put()
// }

function* updateSmsTemplateWatcher(): any {
  yield takeLatest(updateSmsTemplateActions.REQUEST, updateSmsTemplateRequest);
  // yield takeLatest(updateSmsTemplateActions.SUCCESS, updateSmsTemplateSuccess);
}

export default updateSmsTemplateWatcher;
