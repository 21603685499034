import { put, call, takeLatest, select } from 'redux-saga/effects';
import { upsertShowcaseFeedback, uploadCompanyLogo } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertShowcaseFeedbackMeta,
  UpsertShowcaseFeedbackPayload,
} from 'types/store/UpsertShowcaseFeedbackState';

import {
  upsertShowcaseFeedbackActions,
  getShowcaseFeedbacksActions,
} from '../actions';

function* upsertShowcaseFeedbackRequest(
  action: AsyncAction<UpsertShowcaseFeedbackMeta, UpsertShowcaseFeedbackPayload>
) {
  const state: Store = yield select();
  const image: any = action.meta?.image;
  const hero_image: any = action.meta?.hero_image;
  if (state.auth.token) {
    try {
      if (image instanceof File) {
        const { data } = yield call(
          uploadCompanyLogo,
          image,
          state.auth.token
        );
        action.meta.image = data.status === 200 ? data.data?.image?.Location : '';
      }

      if (hero_image instanceof File) {
        const { data } = yield call(
          uploadCompanyLogo,
          hero_image,
          state.auth.token
        );
        action.meta.hero_image = data.status === 200 ? data.data?.image?.Location : '';
      }

      const { data } = yield call(
        upsertShowcaseFeedback,
        action.meta,
        state.auth.token
      );
      yield put(upsertShowcaseFeedbackActions.success(data));
    } catch (e: any) {
      yield put(upsertShowcaseFeedbackActions.failed(e.message));
    }
  }
}

function* upsertShowcaseFeedbackSuccess(
  action: AsyncAction<UpsertShowcaseFeedbackMeta, UpsertShowcaseFeedbackPayload>
) {
  // yield put(showcaseArticleActions.clear());
  yield put(getShowcaseFeedbacksActions.request({}));
}

function* upsertShowcaseFeedbackWatcher(): any {
  yield takeLatest(
    upsertShowcaseFeedbackActions.REQUEST,
    upsertShowcaseFeedbackRequest
  );
  yield takeLatest(
    upsertShowcaseFeedbackActions.SUCCESS,
    upsertShowcaseFeedbackSuccess
  );
}

export default upsertShowcaseFeedbackWatcher;
