import {
  GetAllPlatformMeta,
  GetAllPlatformPayload,
} from 'types/store/GetAllPlatform';
import { createAsyncReducer } from 'utils/Redux';

import { getAllPlatformActions } from '../actions';

export default createAsyncReducer<GetAllPlatformMeta, GetAllPlatformPayload>(
  getAllPlatformActions
);
