import React from 'react';

import { SVGProps } from './SVG.props';

const AlignCenter = (props: SVGProps): JSX.Element => {
  const { width, height } = props;

  return (
    <svg width={width || "18"} height={height || "18"} viewBox="0 0 18 18" fill="#070550" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.6 6.32143H14.4C14.5591 6.32143 14.7117 6.26499 14.8243 6.16452C14.9368 6.06406 15 5.92779 15 5.78571C15 5.64363 14.9368 5.50737 14.8243 5.40691C14.7117 5.30644 14.5591 5.25 14.4 5.25H3.6C3.44087 5.25 3.28826 5.30644 3.17574 5.40691C3.06321 5.50737 3 5.64363 3 5.78571C3 5.92779 3.06321 6.06406 3.17574 6.16452C3.28826 6.26499 3.44087 6.32143 3.6 6.32143ZM6 7.39286C5.84087 7.39286 5.68826 7.4493 5.57574 7.54976C5.46321 7.65023 5.4 7.78649 5.4 7.92857C5.4 8.07065 5.46321 8.20691 5.57574 8.30738C5.68826 8.40784 5.84087 8.46429 6 8.46429H12C12.1591 8.46429 12.3117 8.40784 12.4243 8.30738C12.5368 8.20691 12.6 8.07065 12.6 7.92857C12.6 7.78649 12.5368 7.65023 12.4243 7.54976C12.3117 7.4493 12.1591 7.39286 12 7.39286H6ZM14.4 9.53571H3.6C3.44087 9.53571 3.28826 9.59216 3.17574 9.69262C3.06321 9.79309 3 9.92935 3 10.0714C3 10.2135 3.06321 10.3498 3.17574 10.4502C3.28826 10.5507 3.44087 10.6071 3.6 10.6071H14.4C14.5591 10.6071 14.7117 10.5507 14.8243 10.4502C14.9368 10.3498 15 10.2135 15 10.0714C15 9.92935 14.9368 9.79309 14.8243 9.69262C14.7117 9.59216 14.5591 9.53571 14.4 9.53571ZM12 11.6786H6C5.84087 11.6786 5.68826 11.735 5.57574 11.8355C5.46321 11.9359 5.4 12.0722 5.4 12.2143C5.4 12.3564 5.46321 12.4926 5.57574 12.5931C5.68826 12.6936 5.84087 12.75 6 12.75H12C12.1591 12.75 12.3117 12.6936 12.4243 12.5931C12.5368 12.4926 12.6 12.3564 12.6 12.2143C12.6 12.0722 12.5368 11.9359 12.4243 11.8355C12.3117 11.735 12.1591 11.6786 12 11.6786Z" fill="currentColor" />
    </svg>
  );
};

export default AlignCenter;


