import { GetCompanyCalendlyLinkMeta, GetCompanyCalendlyLinkPayload } from 'types/store/GetCompanyCalendlyLink';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_COMPANY_CALENDLY_LINK_NS';

const asyncAction = createAsyncAction<GetCompanyCalendlyLinkMeta, GetCompanyCalendlyLinkPayload>(ns);

const getCompanyCalendlyLinkActions = { ...asyncAction };

export default getCompanyCalendlyLinkActions;
