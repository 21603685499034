import { put, call, takeLatest, select } from 'redux-saga/effects';
import { changePassword } from 'services/employee';
import { AsyncAction } from 'types/Action';
import {
  ChangePasswordEBMeta,
  ChangePasswordEBPayload,
} from 'types/store/ChangePasswordEBState';
import { Store } from 'types/store/Store';

import {
  changePasswordEBActions,
} from '../actions';

function* changePasswordEBRequest(
  action: AsyncAction<ChangePasswordEBMeta, ChangePasswordEBPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(changePassword, action.meta, state.auth.token);
      yield put(changePasswordEBActions.success(data));
    } catch (e: any) {
      yield put(changePasswordEBActions.failed(e.message));
    }
  }
}

function* changePasswordEBSuccess(
  action: AsyncAction<ChangePasswordEBMeta, ChangePasswordEBPayload>
) {
  // yield put(changePasswordEBActions.clear());
}

function* changePasswordEBWatcher(): any {
  yield takeLatest(changePasswordEBActions.REQUEST, changePasswordEBRequest);
  yield takeLatest(changePasswordEBActions.SUCCESS, changePasswordEBSuccess);
}

export default changePasswordEBWatcher;
