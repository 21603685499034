import axios from 'axios';
import { API } from 'consts';
import { ITrackingActionPayLoad } from 'store/actions/userActivity';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const TRACKING_URL = `${BASE_URL}/tracker`;

export const fetchActionList = (token: string): any => {
  return axios({
    method: 'get',
    url: `${TRACKING_URL}/action-list`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const trackActivity = (
  data: ITrackingActionPayLoad,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${TRACKING_URL}/augment-writing-counter`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};
