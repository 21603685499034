import { GetListDefaultContentPackagesMeta, GetListDefaultContentPackagesPayload } from 'types/store/GetListDefaultContentPackages';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LIST_DEFAULT_CONTENT_PACKAGES_NS';

const asyncAction = createAsyncAction<GetListDefaultContentPackagesMeta, GetListDefaultContentPackagesPayload>(ns);

const getListDefaultContentPackagesActions = { ...asyncAction };

export default getListDefaultContentPackagesActions;
