import { put, call, takeLatest, select } from 'redux-saga/effects';
import { unClockAdvocate as unClockAdvocateCall } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  UnlockAdvocateCallPayload,
  UnlockAdvocateCalllMeta,
} from 'types/store/UnlockAdvocateCall';
import { Store } from 'types/store/Store';
import unClockAdvocateCallActions from 'store/actions/unClockAdvocate';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import { translations } from 'locales/i18n';

import { openToast } from '../actions';

function* unlockAdvocateRequest(
  action: AsyncAction<UnlockAdvocateCalllMeta, UnlockAdvocateCallPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        unClockAdvocateCall,
        state.auth.token,
        action.meta,
      );
      yield put(
        openToast(
          data.message,
          'success',
          i18next.t(translations.UnlockUserSuccess),
        ),
      );
      action.meta.callback?.();
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* advocateBulkCallWatcher(): any {
  yield takeLatest(unClockAdvocateCallActions.REQUEST, unlockAdvocateRequest);
}

export default advocateBulkCallWatcher;
