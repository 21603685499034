import React from 'react';

import { LoadingProps } from './Loading.props';
import { Container } from './Loading.style';

const Loading = (props: LoadingProps): JSX.Element => {
  return (
    <Container width={props.width} height={props.height}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Container>
  );
};

export default React.memo(Loading);
