import { push } from 'connected-react-router';
import { EMPLOYER_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  CreateStoryMeta,
  CreateStoryPayload,
} from 'types/store/CreateStoryState';
import { Store } from 'types/store/Store';
import { AxiosError } from 'axios';

import {
  createStoryActions,
  getSaveTemplatesByCompanyIdActions,
  openToast,
  storyBuilderActions,
} from '../actions';

function* createStoryRequest(
  action: AsyncAction<CreateStoryMeta, CreateStoryPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createStory, action.meta, state.auth.token);
      yield put(createStoryActions.success(data));
    } catch (e: any) {
      yield put(createStoryActions.failed(e.message));
      const errorMessage = e as AxiosError;
      yield put(
        openToast(
          errorMessage?.response?.data.message ??
            'Something went wrong when submitting this story. Please try again',
          'error',
          'Failed to submit story',
        ),
      );
    }
  }
}

function* createStorySuccess(
  action: AsyncAction<CreateStoryMeta, CreateStoryPayload>,
) {
  // if (action.payload.data.status === 'DRAFT') {
  //   yield put(storyBuilderActions.clear());
  //   yield put(getSaveTemplatesByCompanyIdActions.request({}));
  // }
}

function* createStoryWatcher(): any {
  yield takeLatest(createStoryActions.REQUEST, createStoryRequest);
  yield takeLatest(createStoryActions.SUCCESS, createStorySuccess);
}

export default createStoryWatcher;
