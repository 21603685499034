import { put, call, takeLatest, select } from 'redux-saga/effects';
import { uploadShowcaseArticlePhoto } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpsertShowcaseArticleImageMeta,
  UpsertShowcaseArticleImagePayload,
} from 'types/store/UpsertShowcaseArticleImageState';

import {
  upsertShowcaseArticleImageActions,
  showcaseArticleActions,
  getShowcaseArticlesActions,
} from '../actions';

function* upsertShowcaseArticleImageRequest(
  action: AsyncAction<
    UpsertShowcaseArticleImageMeta,
    UpsertShowcaseArticleImagePayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        uploadShowcaseArticlePhoto,
        action.meta,
        state.auth.token
      );
      yield put(upsertShowcaseArticleImageActions.success(data));
    } catch (e: any) {
      yield put(upsertShowcaseArticleImageActions.failed(e.message));
    }
  }
}

function* upsertShowcaseArticleImageSuccess(
  action: AsyncAction<
    UpsertShowcaseArticleImageMeta,
    UpsertShowcaseArticleImagePayload
  >
) {
  //
}

function* upsertShowcaseArticleImageWatcher(): any {
  yield takeLatest(
    upsertShowcaseArticleImageActions.REQUEST,
    upsertShowcaseArticleImageRequest
  );
  yield takeLatest(
    upsertShowcaseArticleImageActions.SUCCESS,
    upsertShowcaseArticleImageSuccess
  );
}

export default upsertShowcaseArticleImageWatcher;
