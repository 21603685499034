import {
  CheckInviteMeta,
  CheckInvitePayload,
} from 'types/store/CheckInviteState';
import { createAsyncReducer } from 'utils/Redux';

import { checkInviteActions } from '../actions';

export default createAsyncReducer<CheckInviteMeta, CheckInvitePayload>(
  checkInviteActions
);
