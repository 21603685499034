import { GetStoryForEditorMeta, GetStoryForEditorPayload } from 'types/store/GetStoryForEditor';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_FOR_EDITOR';

const asyncAction = createAsyncAction<GetStoryForEditorMeta, GetStoryForEditorPayload>(ns);

const getStoryForEditorActions = { ...asyncAction };

export default getStoryForEditorActions;
