import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowUpRight = (props: SVGProps): JSX.Element => {
  const { width, height, fill, rotate } = props;
  const transform = rotate?{transform: `rotate(${rotate}deg)`}:{}
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '14'}
      height={height || '14'}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ ...transform}}
    >
      <path
        d="M10.454 3.862a.583.583 0 00-.537-.362H4.084a.583.583 0 100 1.167H8.51L3.67 9.502a.583.583 0 00.19.957.584.584 0 00.638-.128l4.836-4.842v4.428a.583.583 0 101.167 0V4.083a.585.585 0 00-.047-.221z"
        fill={fill || '#EA4C60'}
      />
    </svg>
  );
};

export default ArrowUpRight;
