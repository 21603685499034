import { put, call, takeLatest, select } from 'redux-saga/effects';
import { deleteApprover } from 'services/team';
import { AsyncAction } from 'types/Action';
import { DeleteApproverMeta, DeleteApproverPayload } from 'types/store/DeleteApprover';
import { Store } from 'types/store/Store';

import { deleteApproverActions } from '../actions';

function* deleteApproverRequest(action: AsyncAction<DeleteApproverMeta, DeleteApproverPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(deleteApprover, action.meta, state.auth.token);
      yield put(deleteApproverActions.success(data));
    } catch (e: any) {
      yield put(deleteApproverActions.failed(e.message));
    }
  }
}

// function* deleteApproverSuccess(action: AsyncAction<DeleteApproverMeta, DeleteApproverPayload>): any {
//   yield put()
// }

function* deleteApproverWatcher(): any {
  yield takeLatest(deleteApproverActions.REQUEST, deleteApproverRequest);
  // yield takeLatest(deleteApproverActions.SUCCESS, deleteApproverSuccess);
}

export default deleteApproverWatcher;
