import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getApprovalsByStoryId } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetApprovalsByStoryIdMeta,
  GetApprovalsByStoryIdPayload,
} from 'types/store/GetApprovalsByStoryIdState';
import { Store } from 'types/store/Store';

import {
  getApprovalsByStoryIdActions,
} from '../actions';

function* getApprovalsByStoryIdRequest(
  action: AsyncAction<
    GetApprovalsByStoryIdMeta,
    GetApprovalsByStoryIdPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getApprovalsByStoryId,
        action.meta,
        state.auth.token
      );
      yield put(getApprovalsByStoryIdActions.success(data));
    } catch (e: any) {
      yield put(getApprovalsByStoryIdActions.failed(e.message));
    }
  } else {
    yield put(getApprovalsByStoryIdActions.failed('Token not found'));
  }
}

function* getApprovalsByStoryIdWatcher(): any {
  yield takeLatest(
    getApprovalsByStoryIdActions.REQUEST,
    getApprovalsByStoryIdRequest
  );
}

export default getApprovalsByStoryIdWatcher;
