import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticEmployeeAdvocacyMeta {
  time: string;
  communities: string[];
}

const employeeAdvocacyNS = 'ANALYTIC_ROI_EMPLOYEE_ADVOCACY';

const getEmployeeAdvocacyAsync = createAsyncAction<
  IAnalyticEmployeeAdvocacyMeta,
  GenericResponse
>(employeeAdvocacyNS);

export default getEmployeeAdvocacyAsync;
