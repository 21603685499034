import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import {
  getContentMarketplaceActions,
  upsertContentMarketplaceActions,
  updateContentMarketplaceActions,
  getBrandGuidelinesByCompanyIdActions
} from 'store/actions';
import { Store } from 'types/store/Store';

import { FormProps } from './WrittenForm.props';
import WrittenFormView from './WrittenForm.view';

const WrittenFormContainer = (props: FormProps): JSX.Element => {
  const { storyId, onClose, marketplaceId, activeStoryDetails, updatePaidStatus } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (marketplaceId) {
      dispatch(
        getContentMarketplaceActions.request({
          marketplaceId
        })
      );
    }

    if (!brandGuideline) {
      dispatch(getBrandGuidelinesByCompanyIdActions.request());
    }

    return () => {
      dispatch(getContentMarketplaceActions.clear());
    };
  }, []);

  const brandGuideline = useSelector((state: Store) => state.getBrandGuidelinesByCompanyId.data?.data?.data);

  const contentMarketplace = useSelector(
    (state: Store) => state.getContentMarketplace.data?.data
  );
  const upsertContentMarketplaceStatus = useSelector(
    (state: Store) => state.upsertContentMarketplace
  );
  const updateContentMarketplaceStatus = useSelector(
    (state: Store) => state.updateContentMarketplace
  );

  const handleUpsertContentMarketplace = (payload: any) => {
    if (payload.marketplace.id) {
      const newPayload = {
        ...payload,
        marketplace: {
          ...contentMarketplace,
          topic: payload.marketplace.topic,
          mail_items: [...payload.marketplace.mail_items],
          marketplace_attachments: [...payload.marketplace.marketplace_attachments],
        }
      }
      dispatch(updateContentMarketplaceActions.request(newPayload));
    } else {
      dispatch(upsertContentMarketplaceActions.request(payload));
    }
    updatePaidStatus(true);
  };

  useEffect(() => {
    if (
      upsertContentMarketplaceStatus.data?.status === 200 ||
      updateContentMarketplaceStatus.data?.status === 200
    ) {
      onClose();
      // dispatch(upsertContentMarketplaceActions.clear());
      // dispatch(updateContentMarketplaceActions.clear());
    }
  }, [upsertContentMarketplaceStatus, updateContentMarketplaceStatus]);

  const generatedProps = {
    onClose,
    storyId,
    handleUpsertContentMarketplace,
    contentMarketplace,
    pending:
      upsertContentMarketplaceStatus.pending ||
      updateContentMarketplaceStatus.pending,
    activeStoryDetails,
    brandGuideline
  };

  return <WrittenFormView {...generatedProps} />;
};

export default WrittenFormContainer;
