import {
  GetTimelineEventsMeta,
  GetTimelineEventsPayload,
} from 'types/store/GetTimelineEventsState';
import { createAsyncReducer } from 'utils/Redux';

import { getTimelineEventsActions } from '../actions';

export default createAsyncReducer<
  GetTimelineEventsMeta,
  GetTimelineEventsPayload
>(getTimelineEventsActions);
