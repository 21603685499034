import { AddContentToStateMeta, AddContentToStatePayload } from 'types/store/AddContentToState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADD_CONTENT_TO_STATE_NS';

const asyncAction = createAsyncAction<AddContentToStateMeta, AddContentToStatePayload>(ns);

const addContentToStateActions = { ...asyncAction };

export default addContentToStateActions;
