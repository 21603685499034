import React from 'react';

import { SVGProps } from './SVG.props';

const Asterisk = (props: SVGProps): JSX.Element => {
  const { height, width } = props;
  return (
    <svg
      width={width || 34}
      height={height || 38}
      viewBox="0 0 34 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.626 33.248l.28-7.288-6.079 3.847A4.374 4.374 0 01.701 28.3a4.319 4.319 0 011.767-5.995l6.38-3.307-6.38-3.305a4.353 4.353 0 01-2.167-2.623 4.31 4.31 0 01.4-3.372 4.342 4.342 0 012.738-2.058 4.383 4.383 0 013.388.552l6.08 3.845-.28-7.286a4.315 4.315 0 011.216-3.173A4.364 4.364 0 0116.985.25h.043a4.38 4.38 0 013.156 1.344 4.331 4.331 0 011.198 3.198l-.345 7.246 6.12-3.871a4.374 4.374 0 016.1 1.467 4.353 4.353 0 01-.4 4.984c-.379.458-.848.835-1.378 1.107L25.099 19l6.415 3.292a4.355 4.355 0 012.188 2.645 4.312 4.312 0 01-.425 3.4l-.018.03a4.345 4.345 0 01-2.739 2.024 4.387 4.387 0 01-3.365-.557l-6.12-3.873.346 7.248a4.312 4.312 0 01-1.2 3.198 4.36 4.36 0 01-3.155 1.344h-.043a4.385 4.385 0 01-3.14-1.33 4.334 4.334 0 01-1.217-3.172z"
        fill="#DED9DB"
      />
    </svg>
  );
};

export default Asterisk;
