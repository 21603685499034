import {
  GetBrandGuidelinesByCompanyIdMeta,
  GetBrandGuidelinesByCompanyIdPayload,
} from 'types/store/GetBrandGuidelinesByCompanyId';
import { createAsyncReducer } from 'utils/Redux';

import { getBrandGuidelinesByCompanyIdActions } from '../actions';

export default createAsyncReducer<
  GetBrandGuidelinesByCompanyIdMeta,
  GetBrandGuidelinesByCompanyIdPayload
>(getBrandGuidelinesByCompanyIdActions);
