import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateTags } from 'services/tags';
import updateTagsActions, {
  IUpdateTagsMeta,
} from 'store/actions/library/updateTags';
import { openToast } from 'store/actions';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* updateTagsSaga(
  action: AsyncAction<IUpdateTagsMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateTags, action.meta, state.auth.token);
      yield put(updateTagsActions.success(data));
      yield put(openToast('Tag(s) updated successfully', 'success', 'Success'));
      yield put(updateTagsActions.clear());
    } catch (e: any) {
      const errorMessage = e.response?.data?.message || e.message;
      yield put(updateTagsActions.failed(errorMessage));
      yield put(openToast(errorMessage, 'error', 'Error'));
      yield put(updateTagsActions.clear());
    }
  }
}

function* updateTagsWatcher(): any {
  yield takeLatest(updateTagsActions.REQUEST, updateTagsSaga);
}

export default updateTagsWatcher;
