import React from 'react';

import { SVGProps } from './SVG.props';

const BrandV3 = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || 59}
      height={height || 35}
      viewBox="0 0 59 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M58.045 13.755c0-7.445-5.99-13.47-13.393-13.47-7.403 0-13.393 6.025-13.393 13.47s5.99 13.47 13.393 13.47c1.643 0 3.214-.294 4.663-.845-2.155 2.191-4.858 3.82-7.792 4.653l.986 3.538c4.383-1.236 8.34-3.942 11.128-7.591 2.886-3.772 4.408-8.29 4.408-13.053v-.172zm-23.134 0c0-5.4 4.371-9.796 9.74-9.796 5.37 0 9.741 4.396 9.741 9.796 0 5.4-4.37 9.796-9.74 9.796s-9.74-4.396-9.74-9.796zM29.396 27.482c-2.447 0-3.945-1.274-4.006-1.335l.013.012-2.448 2.719c.256.232 2.606 2.265 6.441 2.265s6.185-2.033 6.44-2.265l-2.446-2.719c-.013.012-1.522 1.323-3.994 1.323zM27.241 13.755c0-7.445-5.99-13.47-13.393-13.47C6.446.286.455 6.31.455 13.756s5.99 13.47 13.393 13.47c7.403 0 13.393-6.025 13.393-13.47zm-13.393 9.796c-5.37 0-9.74-4.396-9.74-9.796 0-5.4 4.37-9.796 9.74-9.796s9.74 4.396 9.74 9.796c0 5.4-4.37 9.796-9.74 9.796z"
        fill="#91DBB6"
      />
      <path
        d="M44.652 17.429c2.017 0 3.652-1.645 3.652-3.674s-1.635-3.673-3.652-3.673c-2.017 0-3.653 1.644-3.653 3.673s1.636 3.674 3.653 3.674zM13.848 17.429c2.018 0 3.653-1.645 3.653-3.674s-1.636-3.673-3.653-3.673c-2.017 0-3.652 1.644-3.652 3.673s1.635 3.674 3.652 3.674z"
        fill={fill || '#91DBB6'}
      />
    </svg>
  );
};

export default BrandV3;
