import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getSavedCards } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  GetSavedCardsMeta,
  GetSavedCardsPayload
} from 'types/store/GetSavedCards';
import { Store } from 'types/store/Store';

import { getSavedCardsActions } from '../actions';

function* getSavedCardsRequest(
  action: AsyncAction<GetSavedCardsMeta, GetSavedCardsPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getSavedCards, action.meta, state.auth.token);
      yield put(getSavedCardsActions.success(data));
    } catch (e: any) {
      yield put(getSavedCardsActions.failed(e.message));
    }
  } else {
    yield put(getSavedCardsActions.failed('Token not found'));
  }
}

function* getSavedCardsSuccess(
  action: AsyncAction<GetSavedCardsMeta, GetSavedCardsPayload>
) {
  // 
}

function* getSavedCardsWatcher(): any {
  yield takeLatest(getSavedCardsActions.REQUEST, getSavedCardsRequest);
  yield takeLatest(getSavedCardsActions.SUCCESS, getSavedCardsSuccess);
}

export default getSavedCardsWatcher;
