import {
  GetSavedCardsMeta,
  GetSavedCardsPayload
} from 'types/store/GetSavedCards';
import { createAsyncReducer } from 'utils/Redux';

import { getSavedCardsActions } from '../actions';

export default createAsyncReducer<GetSavedCardsMeta, GetSavedCardsPayload>(
  getSavedCardsActions
);
