import { InviteTeamMeta, InviteTeamPayload } from 'types/store/InviteTeam';
import { createAsyncAction } from 'utils/Redux';

const ns = 'INVITE_TEAM_NS';

const asyncAction = createAsyncAction<InviteTeamMeta, InviteTeamPayload>(ns);

const inviteTeamActions = { ...asyncAction };

export default inviteTeamActions;
