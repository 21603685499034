/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconThunder = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.63518 0.738912C9.9124 0.879683 10.0555 1.19339 9.9801 1.49502L8.85386 6H13.3333C13.5977 6 13.8371 6.15623 13.9436 6.39823C14.05 6.64022 14.0034 6.92226 13.8248 7.11715L6.49144 15.1171C6.27572 15.3525 5.92345 15.4019 5.65135 15.2349C5.37924 15.0679 5.26369 14.7315 5.37579 14.4326L7.03801 10H2.66667C2.408 10 2.17267 9.85036 2.06295 9.6161C1.95323 9.38185 1.98893 9.10527 2.15453 8.90654L8.82119 0.906544C9.02024 0.667692 9.35796 0.598142 9.63518 0.738912Z"
        fill={fill || '#FFC937'}
      />
    </svg>
  );
};

export default IconThunder;
