import {
  UploadApprovalStoryMeta,
  UploadApprovalStoryPayload,
} from 'types/store/UploadApprovalStoryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPLOAD_APPROVAL_STORY';
const asyncAction = createAsyncAction<
  UploadApprovalStoryMeta,
  UploadApprovalStoryPayload
>(ns);

const uploadApprovalStoryActions = {
  ...asyncAction,
};

export default uploadApprovalStoryActions;
