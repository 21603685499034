import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getEmailTemplates } from 'services/email-template';
import { AsyncAction } from 'types/Action';
import { GetEmailTemplatesMeta, GetEmailTemplatesPayload } from 'types/store/GetEmailTemplates';
import { Store } from 'types/store/Store';

import { getEmailTemplatesActions } from '../actions';

function* getEmailTemplatesRequest(action: AsyncAction<GetEmailTemplatesMeta, GetEmailTemplatesPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(getEmailTemplates, action.meta, state.auth.token);
      yield put(getEmailTemplatesActions.success(data));
    } catch (e: any) {
      yield put(getEmailTemplatesActions.failed(e.message));
    }
  }
}

// function* getEmailTemplatesSuccess(action: AsyncAction<GetEmailTemplatesMeta, GetEmailTemplatesPayload>): any {
//   yield put()
// }

function* getEmailTemplatesWatcher(): any {
  yield takeLatest(getEmailTemplatesActions.REQUEST, getEmailTemplatesRequest);
  // yield takeLatest(getEmailTemplatesActions.SUCCESS, getEmailTemplatesSuccess);
}

export default getEmailTemplatesWatcher;
