import {
  UpdateShowcaseUrlMeta,
  UpdateShowcaseUrlPayload,
} from 'types/store/UpdateShowcaseUrlState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SHOWCASE_URL';

const asyncAction = createAsyncAction<
  UpdateShowcaseUrlMeta,
  UpdateShowcaseUrlPayload
>(ns);

const updateShowcaseUrlActions = {
  ...asyncAction,
};

export default updateShowcaseUrlActions;
