import { CommonState } from 'types/store/Common';
import { createUpdateAction } from 'utils/Redux';

const ns = 'ADVOCATE_PORTAL_NS';

const updateAction = createUpdateAction<CommonState>(ns);

const advocatePortalActions = { ...updateAction };

export default advocatePortalActions;
