import {
    SubmitVideoCommentMeta,
    SubmitVideoCommentPayload,
  } from 'types/store/SubmitVideoCommentState';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { submitVideoCommentActions } from '../actions';
  
  export default createAsyncReducer<
  SubmitVideoCommentMeta,
  SubmitVideoCommentPayload
  >(submitVideoCommentActions);
  