import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getFinalStoryPieces } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetFinalStoryPiecesMeta,
  GetFinalStoryPiecesPayload,
} from 'types/store/GetFinalStoryPieces';
import { Store } from 'types/store/Store';

import { getFinalStoryPiecesActions } from '../actions';

function* getFinalStoryPiecesRequest(
  action: AsyncAction<GetFinalStoryPiecesMeta, GetFinalStoryPiecesPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getFinalStoryPieces,
        action.meta,
        state.auth.token
      );
      yield put(getFinalStoryPiecesActions.success(data));
    } catch (e: any) {
      yield put(getFinalStoryPiecesActions.failed(e.message));
    }
  } else {
    yield put(getFinalStoryPiecesActions.failed('Token not found'));
  }
}

function* getFinalStoryPiecesSuccess(
  action: AsyncAction<GetFinalStoryPiecesMeta, GetFinalStoryPiecesPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getFinalStoryPiecesWatcher(): any {
  yield takeLatest(
    getFinalStoryPiecesActions.REQUEST,
    getFinalStoryPiecesRequest
  );
  yield takeLatest(
    getFinalStoryPiecesActions.SUCCESS,
    getFinalStoryPiecesSuccess
  );
}

export default getFinalStoryPiecesWatcher;
