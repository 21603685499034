import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getAllPlatform } from 'services/auth';
import { AsyncAction } from 'types/Action';
import {
  GetAllPlatformMeta,
  GetAllPlatformPayload,
} from 'types/store/GetAllPlatform';
import { Store } from 'types/store/Store';

import { getAllPlatformActions } from '../actions';

function* getAllPlatformRequest(
  action: AsyncAction<GetAllPlatformMeta, GetAllPlatformPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getAllPlatform, state.auth.token);
      yield put(getAllPlatformActions.success(data));
    } catch (e: any) {
      yield put(getAllPlatformActions.failed(e.message));
    }
  }
}

function* getAllPlatformSuccess(
  action: AsyncAction<GetAllPlatformMeta, GetAllPlatformPayload>
) {
  // yield put()
}

function* getAllPlatformWatcher(): any {
  yield takeLatest(getAllPlatformActions.REQUEST, getAllPlatformRequest);
  yield takeLatest(getAllPlatformActions.SUCCESS, getAllPlatformSuccess);
}

export default getAllPlatformWatcher;
