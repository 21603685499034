import {
  UpsertShowcaseArticleImageMeta,
  UpsertShowcaseArticleImagePayload,
} from 'types/store/UpsertShowcaseArticleImageState';
import { createAsyncReducer } from 'utils/Redux';

import { upsertShowcaseArticleImageActions } from '../actions';

export default createAsyncReducer<
  UpsertShowcaseArticleImageMeta,
  UpsertShowcaseArticleImagePayload
>(upsertShowcaseArticleImageActions);
