import {
  RemoveCardMeta,
  RemoveCardPayload
  } from 'types/store/RemoveCard';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'REMOVE_CARD';
  
  const asyncAction = createAsyncAction<RemoveCardMeta, RemoveCardPayload>(ns);
  
  const removeCardActions = {
    ...asyncAction,
  };
  
  export default removeCardActions;
  