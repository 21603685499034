import React from 'react';

import { SVGProps } from './SVG.props';

const NoResultsFile = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg
      width={width || '92'}
      height={height || '112'}
      viewBox="0 0 92 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M73.45 90.744H25.9C24.6981 90.7391 23.5468 90.2595 22.697 89.4096C21.8471 88.5597 21.3674 87.4084 21.3625 86.2065V18.5003C21.3625 17.7544 21.0662 17.039 20.5387 16.5115C20.0113 15.9841 19.2959 15.6878 18.55 15.6878H11.05C9.71386 15.6779 8.3889 15.9316 7.15092 16.4344C5.91295 16.9371 4.78628 17.6791 3.83538 18.6178C2.88449 19.5565 2.12805 20.6735 1.60934 21.9049C1.09064 23.1363 0.819859 24.4579 0.8125 25.794V101.15C0.812495 103.842 1.88061 106.424 3.78239 108.33C5.68418 110.235 8.26424 111.308 10.9563 111.313H66.1C68.7953 111.313 71.3801 110.242 73.286 108.336C75.1918 106.43 76.2625 103.846 76.2625 101.15V93.5003C76.2478 92.7642 75.945 92.0631 75.4191 91.5478C74.8932 91.0325 74.1863 90.7439 73.45 90.744Z"
        fill="#C0C0C0"
      />
      <path
        d="M90.382 28.8502L63.0258 1.51272C62.7604 1.24697 62.4445 1.03706 62.0967 0.895365C61.7489 0.753672 61.3763 0.683067 61.0008 0.687715H25.9008C23.2055 0.687715 20.6206 1.7584 18.7148 3.66424C16.809 5.57008 15.7383 8.15495 15.7383 10.8502V86.2065C15.7383 88.9017 16.809 91.4866 18.7148 93.3924C20.6206 95.2983 23.2055 96.369 25.9008 96.369H81.0445C83.7365 96.364 86.3166 95.2911 88.2184 93.3858C90.1202 91.4805 91.1883 88.8985 91.1883 86.2065V30.8377C91.1914 30.4697 91.1218 30.1046 90.9835 29.7635C90.8451 29.4224 90.6407 29.1121 90.382 28.8502Z"
        fill="#D0D0D0"
      />
      <path
        d="M90.2505 28.7377L63.1193 1.62516C62.8 1.27295 62.3967 1.00739 61.9471 0.853296C61.4974 0.699201 61.016 0.661594 60.5478 0.74399C60.0797 0.826385 59.64 1.02609 59.27 1.32446C58.8999 1.62283 58.6115 2.01012 58.4318 2.45016C58.2712 2.85515 58.2009 3.29023 58.2255 3.72516V30.8377C58.2255 31.5836 58.5218 32.299 59.0493 32.8264C59.5767 33.3538 60.2921 33.6502 61.038 33.6502H88.3755C89.1214 33.6502 89.8368 33.3538 90.3642 32.8264C90.8917 32.299 91.188 31.5836 91.188 30.8377C91.1922 30.4408 91.1105 30.0477 90.9487 29.6852C90.7869 29.3228 90.5488 28.9995 90.2505 28.7377Z"
        fill="#DEDEDE"
      />
    </svg>
  );
};

export default NoResultsFile;
