import { ActionCallback } from 'types/Action';

export interface IRegisterGlobal {
  email: string,
  firstName: string,
  lastName: string,
  company: string,
  role: string,
  storyId: string,
  reference: string,
  viaMagicLink: boolean,
  token: string
}

export enum REGISTER_GLOBAL {
  REGISTER_GLOBAL_REQUEST = '@@_REGISTER_GLOBAL_REQUEST',
  REGISTER_GLOBAL_SUCCESS = '@@_REGISTER_GLOBAL_SUCCESS',
  REGISTER_GLOBAL_EROR = '@@_REGISTER_GLOBAL_EROR',
  REGISTER_GLOBAL_EMAIL_EROR = '@@_REGISTER_GLOBAL_EMAIL_EROR'
}

const checkEmailExist = (payload: IRegisterGlobal, callback: (key: string, value: string) => void): ActionCallback => ({
  type: REGISTER_GLOBAL.REGISTER_GLOBAL_REQUEST,
  payload,
  callback
});

const registerEmailGlobal = {
  checkEmailExist
}

export default registerEmailGlobal;
