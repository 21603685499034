import {
   ChargeCardMeta,
   ChargeCardPayload
  } from 'types/store/ChargeCard';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { chargeCardActions } from '../actions';
  
  export default createAsyncReducer<ChargeCardMeta, ChargeCardPayload>(
    chargeCardActions
  );
  