import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticWebTrafficURLMeta {
  storyId?: string;
  isRoot?: boolean;
}

const webTrafficNS = 'ANALYTIC_URL_WEB_TRAFFIC';

const webTrafficURL = createAsyncAction<
  IAnalyticWebTrafficURLMeta,
  GenericResponse
>(webTrafficNS);

export default webTrafficURL;
