import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import { FeedbackOverlayState } from 'types/store/FeedbackOverlayState';

import { feedbackOverlayActions } from '../actions';

const DEFAULT_STATE: FeedbackOverlayState = {
  message: '',
  display: false,
};

const DEFAULT_ACTION: Action<FeedbackOverlayState> = {
  type: '',
  payload: {
    message: '',
    display: false,
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  return pathOr(state, [action.type], {
    [feedbackOverlayActions.UPDATE]: {
      ...state,
      ...action.payload,
    },
    [feedbackOverlayActions.CLEAR]: DEFAULT_STATE,
  });
};
