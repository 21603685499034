import React, { useEffect, useRef, useState } from 'react';

import { DownloadVideoProps } from './DownloadVideo.props';
import { PlayIcon, VideoContainer, VideoPlayer } from '../VideoPreview.style';
import ActionIcon from './ActionIcon';
import BlurDownload from './BlurDownload';

const DownloadVideo = (props: DownloadVideoProps): JSX.Element => {
  const { url, height, width, onClick, playIconSize, question, poster } = props;
  const [process, setProcess] = useState(false);
  const [processBar, setProcessBar] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (process) {
      timeout = setTimeout(() => {
        setProcess(false);
        setProcessBar(true);
      }, 500);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [process]);

  useEffect(() => {
    videoRef?.current?.load();
  }, [props.url]);

  if (videoRef !== undefined) {
    const video = videoRef.current;
    if (video !== undefined && video !== null) {
      if (video['videoWidth'] >= video['videoHeight']) {
        props.portrait();
      }
    }
  }

  const handleDownloadVideo = async (
    //e: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setProcess(true);
    //e.stopPropagation();
    try {
      await fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          const blobUrl = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          const filename = question?.replace('?', '') + '.mp4';
          a.href = blobUrl;
          a.download = filename; // Replace with the desired filename for the downloaded file
          a.click();
          window.URL.revokeObjectURL(blobUrl);
        });
      setProcessBar(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <VideoContainer onClick={onClick} heightCustom={height} widthCustom={width}>
      <BlurDownload process={process} processBar={processBar} />
      <VideoPlayer
        height={height || 265}
        width={width || 160}
        ref={videoRef}
        preload="metadata"
        poster={poster}
      >
        <source src={url} type={'video/mp4'}></source>
      </VideoPlayer>
      {!(process || processBar) && (
        <>
          <PlayIcon width={20} height={20} playIconSize={playIconSize} />
          <ActionIcon {...props} handleDownloadVideo={handleDownloadVideo} />
        </>
      )}
    </VideoContainer>
  );
};

export default React.memo(DownloadVideo);
