import React from 'react';

import { SVGProps } from './SVG.props';

const DragIndicator = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || 20}
      height={height || 20}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.16732 4.99992C9.16732 5.92039 8.42113 6.66659 7.50065 6.66659C6.58018 6.66659 5.83398 5.92039 5.83398 4.99992C5.83398 4.07944 6.58018 3.33325 7.50065 3.33325C8.42113 3.33325 9.16732 4.07944 9.16732 4.99992Z"
        fill={fill || '#898A94'}
      />
      <path
        d="M14.1673 4.99992C14.1673 5.92039 13.4211 6.66659 12.5007 6.66659C11.5802 6.66659 10.834 5.92039 10.834 4.99992C10.834 4.07944 11.5802 3.33325 12.5007 3.33325C13.4211 3.33325 14.1673 4.07944 14.1673 4.99992Z"
        fill={fill || '#898A94'}
      />
      <path
        d="M9.16732 9.99992C9.16732 10.9204 8.42113 11.6666 7.50065 11.6666C6.58018 11.6666 5.83398 10.9204 5.83398 9.99992C5.83398 9.07944 6.58018 8.33325 7.50065 8.33325C8.42113 8.33325 9.16732 9.07944 9.16732 9.99992Z"
        fill={fill || '#898A94'}
      />
      <path
        d="M14.1673 9.99992C14.1673 10.9204 13.4211 11.6666 12.5007 11.6666C11.5802 11.6666 10.834 10.9204 10.834 9.99992C10.834 9.07944 11.5802 8.33325 12.5007 8.33325C13.4211 8.33325 14.1673 9.07944 14.1673 9.99992Z"
        fill={fill || '#898A94'}
      />
      <path
        d="M9.16732 14.9999C9.16732 15.9204 8.42113 16.6666 7.50065 16.6666C6.58018 16.6666 5.83398 15.9204 5.83398 14.9999C5.83398 14.0794 6.58018 13.3333 7.50065 13.3333C8.42113 13.3333 9.16732 14.0794 9.16732 14.9999Z"
        fill={fill || '#898A94'}
      />
      <path
        d="M14.1673 14.9999C14.1673 15.9204 13.4211 16.6666 12.5007 16.6666C11.5802 16.6666 10.834 15.9204 10.834 14.9999C10.834 14.0794 11.5802 13.3333 12.5007 13.3333C13.4211 13.3333 14.1673 14.0794 14.1673 14.9999Z"
        fill={fill || '#898A94'}
      />
    </svg>
  );
};

export default DragIndicator;
