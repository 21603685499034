import { AssignAdvocatesToStoryMeta, AssignAdvocatesToStoryPayload } from 'types/store/AssignAdvocatesToStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ASSIGN_ADVOCATES_TO_STORY_NS';

const asyncAction = createAsyncAction<AssignAdvocatesToStoryMeta, AssignAdvocatesToStoryPayload>(ns);

const assignAdvocatesToStoryActions = { ...asyncAction };

export default assignAdvocatesToStoryActions;
