import {
  UpdateStoryToPublishMeta,
  UpdateStoryToPublishPayload,
} from 'types/store/UpdateStoryToPublishState';
import { createAsyncReducer } from 'utils/Redux';

import { updateStoryToPublishActions } from '../actions';

export default createAsyncReducer<
  UpdateStoryToPublishMeta,
  UpdateStoryToPublishPayload
>(updateStoryToPublishActions);
