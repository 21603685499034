import {
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload,
} from 'types/store/GetHomePageHeaderState';
import { createAsyncReducer } from 'utils/Redux';

import { getHomePageHeaderActions } from '../actions';

export default createAsyncReducer<
  GetHomePageHeaderMeta,
  GetHomePageHeaderMetaPayload
>(getHomePageHeaderActions);
