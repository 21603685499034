import {
    DeleteShowcasePageMeta,
    DeleteShowcasePagePayload
} from 'types/store/DeleteShowcasePageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_SHOWCASE_PAGE';

const asyncAction = createAsyncAction<
DeleteShowcasePageMeta,
DeleteShowcasePagePayload
>(ns);

const deleteShowcasePageActions = {
  ...asyncAction,
};

export default deleteShowcasePageActions;

