import {
  GetNotificationsByUserIdMeta,
  GetNotificationsByUserIdPayload,
} from 'types/store/GetNotificationsByUserIdState';
import { createAsyncReducer } from 'utils/Redux';

import { getNotificationsByUserIdActions } from '../actions';

export default createAsyncReducer<
  GetNotificationsByUserIdMeta,
  GetNotificationsByUserIdPayload
>(getNotificationsByUserIdActions);
