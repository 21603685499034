import {
    UpdateShowcaseArticleHighlightMeta,
    UpdateShowcaseArticleHighlightPayload,
} from 'types/store/UpdateShowcaseArticleHighlightState';
import { createAsyncReducer } from 'utils/Redux';

import { updateShowcaseArticleHighlightActions } from '../actions';

export default createAsyncReducer<
    UpdateShowcaseArticleHighlightMeta,
    UpdateShowcaseArticleHighlightPayload
>(updateShowcaseArticleHighlightActions);
