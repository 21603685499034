/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconThreeDot = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.00065 8.00001C4.00065 8.73638 3.4037 9.33334 2.66732 9.33334C1.93094 9.33334 1.33398 8.73638 1.33398 8.00001C1.33398 7.26363 1.93094 6.66667 2.66732 6.66667C3.4037 6.66667 4.00065 7.26363 4.00065 8.00001Z"
        fill={fill || '#060548'}
      />
      <path
        d="M9.33398 8.00001C9.33398 8.73638 8.73703 9.33334 8.00065 9.33334C7.26427 9.33334 6.66732 8.73638 6.66732 8.00001C6.66732 7.26363 7.26427 6.66667 8.00065 6.66667C8.73703 6.66667 9.33398 7.26363 9.33398 8.00001Z"
        fill={fill || '#060548'}
      />
      <path
        d="M13.334 9.33334C14.0704 9.33334 14.6673 8.73638 14.6673 8.00001C14.6673 7.26363 14.0704 6.66667 13.334 6.66667C12.5976 6.66667 12.0007 7.26363 12.0007 8.00001C12.0007 8.73638 12.5976 9.33334 13.334 9.33334Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default IconThreeDot;
