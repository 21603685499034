import { Check } from 'components/base/SVG';
import { Reducer } from 'redux';
import { FLOAT_MESSAGE, TOAST } from 'store/actions/toast.action';
import { Action } from 'types/Action';
import { Toast } from 'types/store/Toast';

const initState: Toast = {
  message: '',
  type: 'success',
  open: false,
  headline: '',
  text: '',
  isOpen: false
};

const ToastReducer: Reducer<Toast, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case TOAST.TOAST_OPEN: {
      return {
        ...state,
        message: payload.message,
        type: payload.type,
        open: true,
        headline: payload.headline ? payload.headline : payload.type
      }
    }
    case TOAST.TOAST_CLOSE: {
      return {
        ...state,
        message: '',
        open: false
      }
    }
    case FLOAT_MESSAGE.FLOAT_MESSAGE_OPEN: {
      return {
        ...state,
        text: payload.message,
        isOpen: true
      }
    }
    case FLOAT_MESSAGE.FLOAT_MESSAGE_CLOSE: {
      return {
        ...state,
        isOpen: false
      }
    }
    default: {
      return state;
    }
  }
};

export default ToastReducer;
