import {
  GetStripeCustomerIdMeta,
  GetStripeCustomerIdPayload
} from 'types/store/GetStripeCustomerId';
import { createAsyncReducer } from 'utils/Redux';

import { getStripeCustomerIdActions } from '../actions';

export default createAsyncReducer<GetStripeCustomerIdMeta, GetStripeCustomerIdPayload>(
  getStripeCustomerIdActions
);
