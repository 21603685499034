import {
  GetMoreQuestionForCustomMeta,
  GetMoreQuestionForCustomPayload,
} from 'types/store/GetMoreQuestionForCustom';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_MORE_QUESTION_FOR_CUSTOM';

const asyncAction = createAsyncAction<
  GetMoreQuestionForCustomMeta,
  GetMoreQuestionForCustomPayload
>(ns);
const clearAction = createClearAction(ns);
const getMoreQuestionForCustomActions = { ...asyncAction, ...clearAction };

export default getMoreQuestionForCustomActions;
