import { GenericResponse } from 'types/GenericResponse';

import { ColleagueNominate } from './AdvocatePortal';

export enum AdvocateStoryAction {
  accept = 'accept',
  answering = 'answering',
  submit = 'submit',
  mark_published = 'mark_published',
}

export type AdvocateUpdateStoryMeta = {
  id: string;
  user_story_question_answer?: { id: string; actual_answer: string };
  advocate_comment?: string;
  nomination?: ColleagueNominate[];
  action: keyof typeof AdvocateStoryAction;
  images?: string[];
};
export type AdvocateUpdateStoryPayload = GenericResponse;
