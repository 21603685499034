import Typography from 'components/base/Typography';
import styled from 'utils/styled';
import theme from 'utils/Theme';

export const Container = styled.div`
  bottom: 16px;
  display: flex;
  align-self: center;
  justify-content: center;
`;

export const AlertBox = styled.div<{
  background: string;
}>`
  padding: 6px 12px;
  border-radius: 6px;
  background: ${({ background }) => {
    return background;
  }};
  position: fixed;
  bottom: 16px;
  display: flex;

  svg {
    margin-left: 3px;
    margin-right: 13px;
  }

  @media screen and (max-width: ${theme.breakpoints.desktop}px) {
    max-width: 320px;

    p {
      text-align: start;
    }
  }
`;

export const AlertText = styled(Typography)`
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 16px;
  flex: 1;

  @media screen and (max-width: ${theme.breakpoints.desktop}px) {
    font-size: 11px;
  }
`;

export const IconBox = styled.div``;
