import { GuestCommentOnContentPackageMeta, GuestCommentOnContentPackagePayload } from 'types/store/GuestCommentOnContentPackage';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GUEST_COMMENT_ON_CONTENT_PACKAGE_NS';

const asyncAction = createAsyncAction<GuestCommentOnContentPackageMeta, GuestCommentOnContentPackagePayload>(ns);

const guestCommentOnContentPackageActions = { ...asyncAction };

export default guestCommentOnContentPackageActions;
