import React from 'react';

import { SVGProps } from './SVG.props';

const Save = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill={fill || 'none'}
    >
      <path
        d="M11.807 4.193l-4-4a.667.667 0 00-.214-.14.727.727 0 00-.26-.053H2a2 2 0 00-2 2v8a2 2 0 002 2h8a2 2 0 002-2V4.667a.666.666 0 00-.193-.474zM4 1.333h2.667v1.334H4V1.333zm4 9.334H4v-2A.667.667 0 014.667 8h2.666A.667.667 0 018 8.667v2zM10.667 10a.667.667 0 01-.667.667h-.667v-2a2 2 0 00-2-2H4.667a2 2 0 00-2 2v2H2A.667.667 0 011.333 10V2A.667.667 0 012 1.333h.667v2A.667.667 0 003.333 4h4A.667.667 0 008 3.333v-1.06l2.667 2.667V10z"
        fill={fill || 'none'}
      />
    </svg>
  );
};

export default Save;
