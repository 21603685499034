import axios from 'axios';
import { API } from 'consts';
import { BulkCreateContentPackagesMeta } from 'types/store/BulkCreateContentPackages';
import { CreateContentPackageMeta, UpdateContentPackageMeta, DeleteContentPackageMeta, UpdateContentPackageInCartMeta } from 'types/store/ContentPackage';
import { EbCommentOnContentPackageMeta } from 'types/store/EbCommentOnContentPackage';
import { GetAllContentPackageWithDiscussionStatusMeta } from 'types/store/GetAllContentPackageWithDiscussionStatus';
import { GuestCommentOnContentPackageMeta } from 'types/store/GuestCommentOnContentPackage';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CONTENT_PACKAGE_URL = `${BASE_URL}/content-package`;

export const getContentPackages = (token: string): any => {
    return axios({
        method: 'get',
        url: `${CONTENT_PACKAGE_URL}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const createContentPackage = (data: CreateContentPackageMeta, token: string): any => {
    return axios({
        method: 'post',
        url: `${CONTENT_PACKAGE_URL}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const bulkCreateContentPackages = (data: BulkCreateContentPackagesMeta, token: string): any => {
    return axios({
        method: 'put',
        url: `${CONTENT_PACKAGE_URL}/content-planner`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const updateContentPackage = (data: UpdateContentPackageMeta | UpdateContentPackageInCartMeta, token: string): any => {
    return axios({
        method: 'put',
        url: `${CONTENT_PACKAGE_URL}/${data.id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const deleteContentPackage = (data: DeleteContentPackageMeta, token: string): any => {
    return axios({
        method: 'delete',
        url: `${CONTENT_PACKAGE_URL}/${data.id}`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const getDiscussionStatus = (token: string): any => {
    return axios({
        method: 'get',
        url: `${CONTENT_PACKAGE_URL}/discussion-status`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getAllWithDiscussionStatus = (data: GetAllContentPackageWithDiscussionStatusMeta, token: string): any => {
    return axios({
        method: 'get',
        url: `${CONTENT_PACKAGE_URL}/with-discussion-status`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const guestCommentOnContentPackage = (data: GuestCommentOnContentPackageMeta): any => {
    return axios({
        method: 'post',
        url: `${CONTENT_PACKAGE_URL}/${data.id}/guest-comment`,
        data
    })
}

export const ebCommentOnContentPackage = (data: EbCommentOnContentPackageMeta, token: string): any => {
    return axios({
        method: 'post',
        url: `${CONTENT_PACKAGE_URL}/${data.id}/comment`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
        data
    })
}

export const getDefaultContentPackages = (token: string):any => {
    return axios({
        method: 'get',
        url: `${CONTENT_PACKAGE_URL}/content-planner`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

export const getContentPackageInCart = (token: string):any => {
    return axios({
        method: 'get',
        url: `${CONTENT_PACKAGE_URL}/purchase`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}