import { GetStoryDiscussionCommentsMeta, GetStoryDiscussionCommentsPayload } from 'types/store/GetStoryDiscussionComments';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_DISCUSSION_COMMENTS_NS';

const asyncAction = createAsyncAction<GetStoryDiscussionCommentsMeta, GetStoryDiscussionCommentsPayload>(ns);

const getStoryDiscussionCommentsActions = { ...asyncAction };

export default getStoryDiscussionCommentsActions;
