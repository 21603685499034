import { StoryEditorUploadFinalPayload } from 'types/store/StoryEditorUploadFinalState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'STORY_EDITOR_UPLOAD_FINAL';
const updateAction = createUpdateAction<StoryEditorUploadFinalPayload>(ns);
const clearAction = createClearAction(ns);

const storyEditorUploadFinalActions = {
    ...updateAction,
    ...clearAction
}

export default storyEditorUploadFinalActions;
