import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export type UpdateRemainingCreditMeta = {
  credits: string;
};
const ns = 'UPDATE_REMAINING_CREDIT';

const asyncAction = createAsyncAction<
  UpdateRemainingCreditMeta,
  GenericResponse
>(ns);

const updateRemainingCreditActions = {
  ...asyncAction,
};

export default updateRemainingCreditActions;
