import { put, call, takeLatest, select } from 'redux-saga/effects';
import { extractTextFromUrl } from 'services/text';
import { AsyncAction } from 'types/Action';
import {
  ExtractTextFromUrlMeta,
  ExtractTextFromUrlPayload,
} from 'types/store/ExtractTextFromUrl';
import { Store } from 'types/store/Store';

import { extractTextFromUrlActions } from '../actions';

function* extractTextFromUrlRequest(
  action: AsyncAction<ExtractTextFromUrlMeta, ExtractTextFromUrlPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        extractTextFromUrl,
        action.meta,
        state.auth.token
      );
      yield put(extractTextFromUrlActions.success(data));
    } catch (e: any) {
      yield put(extractTextFromUrlActions.failed(e.message));
    }
  } else {
    yield put(extractTextFromUrlActions.failed('Token not found'));
  }
}

// function* downloadResponsesSuccess(
//   action: AsyncAction<DownloadResponsesMeta, DownloadResponsesPayload>
// ) {
// }

function* extractTextFromUrlWatcher(): any {
  yield takeLatest(
    extractTextFromUrlActions.REQUEST,
    extractTextFromUrlRequest
  );
  // yield takeLatest(downloadResponsesActions.SUCCESS, downloadResponsesSuccess);
}

export default extractTextFromUrlWatcher;
