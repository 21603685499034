export enum TrackAutoType {
  auto = 'auto',
  close = 'close',
  swapTab = 'swap-tab',
}

export type TrackAutoLoginMeta = {
  action: TrackAutoType;
  status?: string;
};
