import React from 'react';

import { Close, Plus, ChevronDown } from 'components/base/SVG';
import TickBox from 'components/base/TickBox';
import { FieldArray } from 'formik';
import VideoPreview from 'routes/Employer/StoryEditor/V2/Shared/VideoPreview';
import theme from 'utils/Theme';

import {
  Label,
  TextField,
  Textarea,
} from '../../WrittenForm/WrittenForm.style';
import { MultiTextFieldGeneratedProps } from './MultiTextField.props';
import {
  Container,
  RowContainer,
  TextFieldContainer,
  ButtonContainer,
  CustomFormikTextArea,
  VideoContent,
  UserContent,
  MainContent,
  ShowButton,
  ArrowContainer,
  ItemContainer,
} from './MultiTextField.style';

const MultiTextFieldView = (
  props: MultiTextFieldGeneratedProps,
): JSX.Element => {
  const {
    title,
    data,
    name,
    type,
    handleDeleteIntroSlide,
    setSelected,
    setShowInfo,
  } = props;

  return (
    <Container>
      <Label variant="caption" color="primary">
        {title}
      </Label>
      <FieldArray
        name={name}
        render={(arrayHelpers) =>
          data &&
          data?.length > 0 &&
          data?.map((item, index) => (
            <ItemContainer key={index}>
              <RowContainer key={index}>
                <TickBox
                  checked={item.isSelected}
                  onClick={() => setSelected(index)}
                />
                <MainContent>
                  <VideoContent>
                    <VideoPreview
                      playIconSize={8}
                      width={74}
                      height={112}
                      question={item.questionTitle}
                      url={item.video_link}
                      fullscreen
                    />
                    <TextFieldContainer>
                      <Label variant="overline" color="shade6">
                        QUESTION
                      </Label>
                      <CustomFormikTextArea
                        name={`${name}[${index}].questionTitle`}
                        placeholder=""
                      />
                    </TextFieldContainer>
                  </VideoContent>
                  {item.isShowHide && (
                    <UserContent>
                      <Label variant="overline" color="shade6">
                        NAME
                      </Label>
                      <TextField
                        name={`${name}[${index}].username`}
                        placeholder=""
                      />
                      <Label variant="overline" color="shade6">
                        TITLE
                      </Label>
                      <TextField
                        name={`${name}[${index}].userrole`}
                        placeholder=""
                      />
                    </UserContent>
                  )}
                </MainContent>
                {/* {index === 0 ? (
                <ButtonContainer
                  variant='mint'
                  onClick={() => arrayHelpers.push({ content: '', type })}
                >
                  <Plus width={10.67} height={10.67} fill='#fff' />
                </ButtonContainer>
              ) : (
                <ButtonContainer
                  variant='error'
                  onClick={() => {
                    if (_.id) handleDeleteIntroSlide(_.id);
                    arrayHelpers.remove(index)
                  }}
                >
                  <Close width={8.9} height={8.9} fill='#fff' />
                </ButtonContainer>
              )} */}
              </RowContainer>
              <ShowButton onClick={() => setShowInfo(index)}>
                <ArrowContainer flipped={item.isShowHide}>
                  <ChevronDown
                    fill={theme.altGrey.shade7}
                    width={10}
                    height={5}
                  />
                </ArrowContainer>
                <Label altColor="shade7" altVariant="caption" weight="700">
                  {item.isShowHide ? 'Hide' : 'Show'}
                </Label>
              </ShowButton>
            </ItemContainer>
          ))
        }
      />
    </Container>
  );
};

export default MultiTextFieldView;
