import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowLeft = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '12'}
      height={height || '12'}
      viewBox="0 0 12 12"
      fill={'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M.668 5.747a.667.667 0 0 0 .142.726l4.714 4.714a.667.667 0 0 0 .943-.942L2.889 6.667l7.82.004a.667.667 0 0 0 .62-.926.667.667 0 0 0-.62-.412l-7.82.004L6.467 1.76a.667.667 0 0 0-.943-.943L.81 5.531a.667.667 0 0 0-.142.216z"
        fill={fill || '#111E2B'}
      />
    </svg>
  );
};

export default ArrowLeft;
