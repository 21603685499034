import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  getAdvocateStoryDetails,
  advocateUpdateStory,
  advocatePortalStatistical,
  checkColleagueEmails,
} from 'services/advocate';
import { AsyncAction } from 'types/Action';
import { ColleagueNominate } from 'types/store/AdvocatePortal';
import {
  AdvocateStoryDetailsMeta,
  AdvocateStoryDetailsPayload,
} from 'types/store/AdvocateStoryDetails';
import {
  AdvocateUpdateStoryMeta,
  AdvocateUpdateStoryPayload,
  AdvocateStoryAction,
} from 'types/store/AdvocateUpdateStory';
import { Store } from 'types/store/Store';
import { StoryStatus } from 'types/Story';

import {
  advocateStoryDetailsActions,
  advocateUpdateStoryActions,
  advocatePortalActions,
  advocatePortalStatisticalActions,
} from '../actions';

function* advocateStoryDetailsRequest(
  action: AsyncAction<AdvocateStoryDetailsMeta, AdvocateStoryDetailsPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getAdvocateStoryDetails,
        action.meta,
        state.auth.token,
      );
      yield put(advocateStoryDetailsActions.success(data));
    } catch (e: any) {
      yield put(advocateStoryDetailsActions.failed(e.message));
    }
  }
}

function* advocateUpdateStoryRequest(
  action: AsyncAction<AdvocateUpdateStoryMeta, AdvocateUpdateStoryPayload>,
) {
  const state: Store = yield select();
  const token = state.auth.token;
  if (token) {
    try {
      const currentStory = state.advocatePortal.selectedStory;
      const nomination = currentStory?.nomination;
      if (
        Array.isArray(nomination) &&
        action.meta.action === AdvocateStoryAction.submit
      ) {
        const emails = nomination.map((item: any) => item.email);
        const regex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let isValid = true;
        const checkedField = nomination.map((entry: any) => {
          const temp = Object.assign({}, entry);
          if (entry.name === '') {
            temp.nameErr = 'Please enter name';
          } else {
            temp.nameErr = '';
          }
          if (!regex.test(entry.email?.toLowerCase())) {
            temp.emailErr = 'Please enter a valid email address';
          } else if (
            nomination.filter((ele: any) => ele.email === entry.email).length &&
            nomination.filter((ele: any) => ele.email === entry.email).length >
              1
          ) {
            temp.emailErr = 'Please enter a unique email address';
          } else {
            temp.emailErr = '';
          }
          if (temp.name === '' && temp.email === '') {
            temp.nameErr = '';
            temp.emailErr = '';
          }

          if (temp.nameErr !== '' || temp.emailErr !== '') isValid = false;
          return temp;
        });
        let storyData: any = {
          ...currentStory,
          nomination: checkedField,
        };
        if (isValid) {
          const emailsClearEmpty = emails.filter((email) => email !== '');
          const { data: checkMailsResponse } = yield call(
            checkColleagueEmails,
            { emails: emailsClearEmpty },
            token,
          );
          if (checkMailsResponse && checkMailsResponse.status === 200) {
            if (checkMailsResponse.data.every((_: any) => !_.exist)) {
              const { data } = yield call(
                advocateUpdateStory,
                {
                  ...action.meta,
                  id: currentStory.id,
                  ...(nomination.filter((_: any) => !!_.email).length > 0
                    ? { nomination }
                    : {}),
                  advocate_comment: currentStory?.advocate_comment,
                },
                token,
              );
              if (data.status === 200) {
                yield put(
                  advocatePortalActions.update({ submitStoryState: true }),
                );
              } else {
                yield put(
                  advocatePortalActions.update({ submitStoryState: false }),
                );
              }
            } else {
              storyData = {
                ...storyData,
                nomination: nomination.map((item: ColleagueNominate) =>
                  checkMailsResponse.data.find(
                    (checked: any) => item.email === checked.email,
                  ),
                ),
              };
              yield put(
                advocatePortalActions.update({ selectedStory: storyData }),
              );
              yield window.scrollTo({
                top: document.body.scrollHeight,
                behavior: 'smooth',
              });
            }
          }
        } else {
          yield put(advocatePortalActions.update({ selectedStory: storyData }));
          yield window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
          });
        }
      } else {
        const { data } = yield call(advocateUpdateStory, action.meta, token);
        const storyData: any = {
          ...currentStory,
        };
        if (
          action.meta.action === AdvocateStoryAction.accept &&
          storyData.status === StoryStatus.ASSIGNED
        ) {
          storyData.status = StoryStatus.ACCEPTED;
          yield put(advocatePortalActions.update({ selectedStory: storyData }));
        } else if (
          action.meta.action === AdvocateStoryAction.answering &&
          storyData.status === StoryStatus.ACCEPTED
        ) {
          storyData.status = StoryStatus.IN_PROGRESS;
          yield put(advocatePortalActions.update({ selectedStory: storyData }));
        }
      }
      yield put(
        advocateUpdateStoryActions.success({ action: action.meta.action }),
      );
    } catch (e: any) {
      yield put(advocateUpdateStoryActions.failed(e));
    }
  }
}

function* advocatePortalStatisticalRequest() {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(advocatePortalStatistical, state.auth.token);
      yield put(advocatePortalStatisticalActions.success(data));
    } catch (e: any) {
      yield put(advocatePortalStatisticalActions.failed(e.message));
    }
  }
}

function* advocatePortalWatcher(): any {
  yield takeLatest(
    advocateStoryDetailsActions.REQUEST,
    advocateStoryDetailsRequest,
  );
  yield takeLatest(
    advocateUpdateStoryActions.REQUEST,
    advocateUpdateStoryRequest,
  );
  yield takeLatest(
    advocatePortalStatisticalActions.REQUEST,
    advocatePortalStatisticalRequest,
  );
}

export default advocatePortalWatcher;
