import {
  UpdateBillingAddressMeta,
  UpdateBillingAddressPayload
} from 'types/store/UpdateBillingAddress';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_BILLING_ADDRESS';

const asyncAction = createAsyncAction<
  UpdateBillingAddressMeta,
  UpdateBillingAddressPayload
>(ns);

const updateBillingAddressActions = {
  ...asyncAction,
};

export default updateBillingAddressActions;
