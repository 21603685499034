import {
  UploadAnswerVideoMeta,
  UploadAnswerVideoPayload,
} from 'types/store/UploadAnswerVideo';
import { createAsyncReducer } from 'utils/Redux';

import { uploadAnswerVideoActions } from '../../actions';

export default createAsyncReducer<
  UploadAnswerVideoMeta,
  UploadAnswerVideoPayload
>(uploadAnswerVideoActions);
