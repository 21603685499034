import React from 'react';

import { SVGProps } from './SVG.props';

const ArrowLeft = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 16}
      height={height || 16}
      viewBox="0 0 16 16"
      fill={fill || '#DED9DB'}
      {...props}
    >
      <path d="M13.328 8.256a.667.667 0 00-.142-.726L8.472 2.816a.667.667 0 10-.943.943l3.578 3.578-7.82-.005a.667.667 0 00-.62.926.667.667 0 00.62.413l7.82-.005-3.578 3.578a.667.667 0 10.943.943l4.714-4.714a.668.668 0 00.142-.217z" />
    </svg>
  );
};

export default ArrowLeft;
