import {
    GetTagsMeta,
    GetTagsPayload,
} from 'types/store/GetTagsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_TAGS';
const asyncAction = createAsyncAction<
    GetTagsMeta,
    GetTagsPayload
>(ns);

const getTagsActions = {
    ...asyncAction,
};

export default getTagsActions;
