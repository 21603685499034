import React, { useState, useRef, useEffect } from 'react';

import Button from 'components/base/Button';
import DropDown from 'components/base/DropDown';
import { ChevronLeft } from 'components/base/SVG';
import Close from 'components/base/SVG/Close';
import File from 'components/base/SVG/File';
import Plus from 'components/base/SVG/Plus';
import Typography from 'components/base/Typography';
import AddFile from 'components/module/AddFile';
import ConfirmDialogV2 from 'components/module/ConfirmDialogV2';
import { Form, Formik } from 'formik';
import { translations } from 'locales/i18n';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Dispatch, SetStateAction } from 'reactcss/node_modules/@types/react';
import { Store } from 'types/store/Store';
import { theme } from 'utils/Theme';

import styledDropdown from '../styledDropdown';
import { WrittenFormProps } from './WrittenForm.props';
import {
  Container,
  Title,
  FormContainer,
  FieldContainer,
  TextField,
  Label,
  Textarea,
  DropdownContainer,
  AttachFileContainer,
  AllowFileText,
  SelectedFileContainer,
  SelectedFileName,
  Description,
  ActionButton,
  ButtonContainer,
  CloseButton,
  Tag,
  TagText,
} from './WrittenForm.style';
import { validate } from './WrittenForm.validation';

const WrittenForm = (props: WrittenFormProps): JSX.Element => {
  const { t } = useTranslation();
  const ContentLengthOptions = [
    { id: 0, label: t(translations.ContentLength300), value: '300' },
    { id: 1, label: t(translations.ContentLength500), value: '500' },
    { id: 2, label: t(translations.ContentLength800), value: '800' },
  ];

  const ContentLanguageOptions = [
    {
      id: 0,
      label: t(translations.LanguageLabelAmerican),
      value: 'AMERICAN_ENGLISH',
    },
    {
      id: 1,
      label: t(translations.LanguageLabelBritish),
      value: 'BRITISH_ENGLISH',
    },
    {
      id: 2,
      label: t(translations.LanguageLabelCanadian),
      value: 'CANADIAN_ENGLISH',
    },
  ];

  const {
    onClose,
    storyId,
    handleUpsertContentMarketplace,
    contentMarketplace,
    pending,
    activeStoryDetails,
    brandGuideline,
  } = props;

  const filePicker = useRef<HTMLInputElement | null>(null);
  const formRef = useRef<any>(null);

  const storyEditorState = useSelector((state: Store) => state.storyEditor);
  const storyDetail = useSelector(
    (state: Store) => state.getStoryForEditor.data?.data?.story,
  );
  // const { activeStory } = storyEditorState;
  const storyTitle = activeStoryDetails?.audience_research?.headline ?? '';
  const typeOfStory = `${storyDetail?.type} ${storyDetail?.sub_type}`;
  const categoryName = activeStoryDetails?.category_name || '';

  const [toneOfVoiceAttachments, setToneOfVoiceAttachments] = useState<
    any[] | []
  >([]);
  const [brandGuidelineAttachments, setBrandGuidelineAttachments] = useState<
    any[] | []
  >([]);
  const [deleteMarketplaceAttachmentIds, setDeleteMarketplaceAttachmentIds] =
    useState<string[] | []>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const formikProps = {
    initialValues: {
      id: '',
      topic: storyTitle,
      content_length: undefined,
      content_language: 'AMERICAN_ENGLISH',
      tone_of_voice: '',
      target_audience: '',
      intent: '',
      key_points: '',
      brand_guideline: '',
      publish_place: '',
      call_to_action: '',
      suggested_keywords: '',
      additional_instructions: '',
      content_examples: '',
    },
    validate,
    onSubmit: (values: any) => {
      const marketplace_attachments: any[] = [
        ...toneOfVoiceAttachments,
        ...brandGuidelineAttachments,
      ];
      const payload = {
        storyId,
        marketplace: {
          id: values.id,
          topic: values.topic,
          written_form: {
            ...values,
            id: contentMarketplace?.written_form?.id,
            content_length:
              values.content_length?.label || values.content_length,
          },
          marketplace_attachments,
          mail_items: [
            {
              isAdditional: false,
              title: 'Topic',
              value: values.topic,
            },
            {
              isAdditional: false,
              title: 'Content length',
              value: `${values.content_length?.label ?? values.content_length}`,
            },
            {
              isAdditional: false,
              title: 'Content language',
              value:
                values.content_language === 'AMERICAN_ENGLISH'
                  ? 'American English'
                  : values.content_language === 'BRITISH_ENGLISH'
                    ? 'British English'
                    : 'Canadian English',
            },
            {
              isAdditional: false,
              title: 'Tone of voice',
              value: values.tone_of_voice,
              fileNames: toneOfVoiceAttachments,
            },
            {
              isAdditional: false,
              title: 'Target audience',
              value: values.target_audience,
            },
            {
              isAdditional: false,
              title: 'Intent',
              value: values.intent,
            },
            {
              isAdditional: false,
              title: 'Key points',
              value: values.key_points,
            },
            {
              isAdditional: brandGuidelineAttachments.length <= 0,
              title: 'Brand Guidelines',
              fileNames: brandGuidelineAttachments,
            },
            {
              isAdditional: false,
              title: 'Where will this be published?',
              value: values.publish_place ?? '',
            },
            {
              isAdditional: true,
              title: 'Call to action',
              value: values.call_to_action ?? '',
            },
            {
              isAdditional: true,
              title: 'Suggested Keywords',
              value: values.suggested_keywords ?? '',
            },
            {
              isAdditional: true,
              title: 'Additional Instructions',
              value: values.additional_instructions ?? '',
            },
            {
              isAdditional: true,
              title: 'Good content examples',
              value: values.content_examples ?? '',
            },
          ],
        },
        ...(values.id
          ? { deleteMarketplaceAttachmentIds: deleteMarketplaceAttachmentIds }
          : {}),
      };
      handleUpsertContentMarketplace(payload);
    },
  };

  const handleClick = () => {
    if (filePicker) {
      filePicker.current?.click();
    }
  };

  const handleAddFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    const newState = [...toneOfVoiceAttachments];
    newState.push({
      name: files[0].name,
      url: files[0],
      type: 'TONE_OF_VOICE',
    });
    setIsDisabled(false);
    setToneOfVoiceAttachments(newState);
  };

  const handleRemovefile = (
    index: number,
    array: any[],
    callback: Dispatch<SetStateAction<[] | File[]>>,
  ) => {
    const newState = [...array];
    if (newState[index].id)
      setDeleteMarketplaceAttachmentIds([
        ...deleteMarketplaceAttachmentIds,
        newState[index].id,
      ]);
    newState.splice(index, 1);
    callback(newState);
    setIsDisabled(false);
  };

  useEffect(() => {
    formRef.current.setFieldValue('topic', storyTitle);
  }, [storyTitle]);

  useEffect(() => {
    setIsDisabled(true);
    return () => {
      if (ContentLengthOptions.length > 3) {
        ContentLengthOptions.shift();
      }
    };
  }, []);

  useEffect(() => {
    if (brandGuideline) {
      formRef.current.setFieldValue(
        'tone_of_voice',
        `${brandGuideline.tone_content_a || ''}\n${brandGuideline.tone_content_c
          ? `${brandGuideline.tone_content_c}`
          : ''
        }`,
      );
      setTimeout(() => {
        setIsDisabled(true);
      }, 500);
    }
  }, [brandGuideline]);

  useEffect(() => {
    // const { activeStory } = storyEditorState;
    const storyTitle =
      storyDetail?.audience_research?.headline ??
      activeStoryDetails?.audience_research?.headline ??
      '';
    formRef.current.setFieldValue('topic', storyTitle);
    setTimeout(() => {
      setIsDisabled(true);
    }, 500);
  }, []);

  useEffect(() => {
    if (contentMarketplace) {
      const values = {
        id: contentMarketplace?.id,
        topic: formRef?.current?.values?.topic ?? storyTitle,
        content_length: contentMarketplace.written_form?.content_length,
        content_language: contentMarketplace.written_form?.content_language,
        tone_of_voice: `${brandGuideline?.tone_content_a || ''}\n${brandGuideline?.tone_content_c
            ? `${brandGuideline?.tone_content_c}`
            : ''
          }`,
        target_audience: contentMarketplace.written_form?.target_audience,
        intent: contentMarketplace.written_form?.intent,
        key_points: contentMarketplace.written_form?.key_points,
        brand_guideline: contentMarketplace.written_form?.brand_guideline,
        publish_place: contentMarketplace.written_form?.publish_place,
        call_to_action: contentMarketplace.written_form?.call_to_action,
        suggested_keywords: contentMarketplace.written_form?.suggested_keywords,
        additional_instructions:
          contentMarketplace.written_form?.additional_instructions,
        content_examples: contentMarketplace.written_form?.content_examples,
      };
      formRef.current.setValues(values);
      if (
        !ContentLengthOptions.find((_) =>
          _.label.includes(contentMarketplace.written_form?.content_length),
        )
      ) {
        ContentLengthOptions.unshift({
          id: 0,
          label: contentMarketplace.written_form?.content_length,
          value: contentMarketplace.written_form?.content_length,
        });
      }
      setToneOfVoiceAttachments(
        contentMarketplace.marketplace_attachments.filter(
          (_) => _.type === 'TONE_OF_VOICE',
        ),
      );
      setBrandGuidelineAttachments(
        contentMarketplace.marketplace_attachments.filter(
          (_) => _.type === 'BRAND_GUIDELINES',
        ),
      );
      setTimeout(() => {
        setIsDisabled(true);
      }, 500);
    }
  }, [contentMarketplace]);

  return (
    <>
      <Tag>
        <ChevronLeft fill={theme.brand.primary} width={8} height={7} />
        <TagText altVariant="overlineSmall">
          {categoryName}, {typeOfStory}
        </TagText>
      </Tag>
      <Title variant="title5" color="primary" style={{ border: 'none' }}>
        {`"${formRef?.current?.values?.topic ?? storyTitle}"`}
      </Title>
      <Container>
        <Title variant="title5" color="shade7">
          Brief
        </Title>
        <FormContainer>
          <Formik {...formikProps} innerRef={formRef}>
            {(formikProperties: any) => {
              const { values, errors, touched } = formikProperties;
              useEffect(() => {
                if (isDisabled) setIsDisabled(false);
              }, [values]);
              return (
                <Form>
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Topic
                    </Label>
                    <Textarea
                      name="topic"
                      rows={2}
                      placeholder="How an employee can uphold their mental wellbeing during remote working"
                    />
                  </FieldContainer>
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Content length
                    </Label>
                    <DropdownContainer>
                      <DropDown
                        value={ContentLengthOptions.find((_) =>
                          _.label.includes(
                            formikProperties.values?.content_length,
                          ),
                        )}
                        styles={styledDropdown}
                        isCreatable={true}
                        options={ContentLengthOptions}
                        onChange={(option: any) => {
                          formikProperties.setFieldValue(
                            'content_length',
                            option,
                          );
                        }}
                      />
                    </DropdownContainer>
                    {errors.content_length && touched.content_length && (
                      <Typography
                        variant="caption"
                        color="error"
                        style={{ marginTop: '4px' }}
                      >
                        {errors.content_length}
                      </Typography>
                    )}
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Content language
                    </Label>
                    <DropdownContainer>
                      <DropDown
                        value={ContentLanguageOptions.find(
                          (_) =>
                            _.value ===
                            formikProperties.values?.content_language,
                        )}
                        styles={styledDropdown}
                        isCreatable={false}
                        options={ContentLanguageOptions}
                        onChange={(option: any) =>
                          formikProperties.setFieldValue(
                            'content_language',
                            option?.value,
                          )
                        }
                      />
                    </DropdownContainer>
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Tone of voice
                    </Label>
                    <Textarea
                      name="tone_of_voice"
                      rows={2}
                      placeholder={`e.g.straightforward, professional, friendly, human`}
                    />
                    {toneOfVoiceAttachments?.map((_, index) => (
                      <SelectedFileContainer key={index}>
                        <File />
                        <SelectedFileName>{_.name}</SelectedFileName>
                        <CloseButton
                          onClick={() =>
                            handleRemovefile(
                              index,
                              toneOfVoiceAttachments,
                              setToneOfVoiceAttachments,
                            )
                          }
                        >
                          <Close width={5.34} height={5.34} fill="#070550" />
                        </CloseButton>
                      </SelectedFileContainer>
                    ))}
                    <AttachFileContainer>
                      <input
                        ref={filePicker}
                        type="file"
                        hidden
                        name="test"
                        onChange={handleAddFile}
                      // accept={acceptFile || ''}
                      />
                      <Button
                        text="Attach File"
                        icon={
                          <Plus width={10.67} height={10.67} fill="#070550" />
                        }
                        iconPosition="before"
                        size="xs"
                        variant="plain"
                        type="button"
                        onClick={handleClick}
                      />
                      <AllowFileText>
                        (e.g. doc, pdf, jpg, png etc)
                      </AllowFileText>
                    </AttachFileContainer>
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Target audience
                    </Label>
                    <TextField
                      name="target_audience"
                      placeholder="e.g. software engineers, HR leaders, graduates…"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Intent
                    </Label>
                    <Textarea
                      name="intent"
                      rows={3}
                      placeholder="e.g. to showcase the company culture"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Key points
                    </Label>
                    <Textarea
                      name="key_points"
                      rows={3}
                      placeholder="3-4 short points you want this piece to cover"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Brand Guidelines
                    </Label>
                    <AddFile
                      file={null}
                      onSelectFile={(f) => {
                        if (f) {
                          setBrandGuidelineAttachments((prev) => [
                            ...prev,
                            { name: f.name, url: f, type: 'BRAND_GUIDELINES' },
                          ]);
                          setIsDisabled(false);
                        }
                      }}
                      onRemoveFile={() => console.log('removeFile')}
                      height={'40px'}
                      width="calc(100% - 48px)"
                      fileTypes=".pdf,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    {/* <Description>
                      (Client to send brand guideline documents including
                      information on how to use logo, brand colours and brand
                      fonts)
                    </Description> */}
                    {brandGuidelineAttachments?.map((_, index) => (
                      <SelectedFileContainer key={index}>
                        <File />
                        <SelectedFileName>{_.name}</SelectedFileName>
                        <CloseButton
                          onClick={() =>
                            handleRemovefile(
                              index,
                              brandGuidelineAttachments,
                              setBrandGuidelineAttachments,
                            )
                          }
                        >
                          <Close width={5.34} height={5.34} fill="#070550" />
                        </CloseButton>
                      </SelectedFileContainer>
                    ))}
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer>
                    <Label variant="caption" color="primary">
                      Where will this be published?
                    </Label>
                    <TextField
                      name="publish_place"
                      placeholder="e.g. on the advocate’s LinkedIn profile, on the company blog…"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer optional>
                    <Label variant="caption" color="primary">
                      Call to action
                    </Label>
                    <TextField
                      name="call_to_action"
                      placeholder="e.g. Link to the company’s careers page"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer optional>
                    <Label variant="caption" color="primary">
                      Suggested keywords
                    </Label>
                    <TextField
                      name="suggested_keywords"
                      placeholder="e.g. workplace culture, diversity and inclusion…"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer optional>
                    <Label variant="caption" color="primary">
                      Additional instructions
                    </Label>
                    <TextField
                      name="additional_instructions"
                      placeholder="Anything else we should know?"
                    />
                  </FieldContainer>
                  {/*  */}
                  <FieldContainer optional>
                    <Label variant="caption" color="primary">
                      Good content examples
                    </Label>
                    <TextField
                      name="content_examples"
                      placeholder="e.g. I like this article on LinkedIn because…"
                    />
                  </FieldContainer>
                  {/*  */}
                  <ActionButton>
                    <ButtonContainer>
                      <Button
                        size="lg"
                        variant="outline"
                        type="button"
                        onClick={onClose}
                      >
                        Cancel
                      </Button>
                    </ButtonContainer>
                    <ButtonContainer>
                      <Button
                        size="lg"
                        variant="primary"
                        type="button"
                        disabled={isDisabled || !!pending}
                        loading={!!pending}
                        onClick={() => setOpenDialog(true)}
                      >
                        Submit Now
                      </Button>
                    </ButtonContainer>
                  </ActionButton>
                </Form>
              );
            }}
          </Formik>
        </FormContainer>
        <ConfirmDialogV2
          isOpen={openDialog}
          onClose={() => setOpenDialog(false)}
          onSubmit={() => {
            setOpenDialog(false);
            formRef.current?.submitForm();
          }}
          message="Are you sure you want to submit?"
          title="Confirmation"
        />
      </Container>
    </>
  );
};

export default React.memo(WrittenForm);
