import { Reducer } from 'redux';
import { LANGUAGE } from 'store/actions/getListLanguage';
import { Action } from 'types/Action';
import { LanguageStore } from 'types/store/Setting';

const initState: LanguageStore = {
  language: [],
  languageActive: '',
  activeTranslateMode: 0
};

const SettingReducer: Reducer<LanguageStore, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case LANGUAGE.GET_LANGUAGE_ACTION: {
      return {
        ...state,
        language: [],
        languageActive: ''
      }
    }
    case LANGUAGE.GET_LANGUAGE_SUCCESS: {
      return {
        ...state,
        language: payload,
      }
    }
    case LANGUAGE.LANGUAGE_CHOOSING: {
      return {
        ...state,
        languageActive: payload
      }
    }
    case LANGUAGE.CHANGE_ACTIVE_LANGUAGE_TRANSLATE: {
      return {
        ...state,
        activeTranslateMode: payload
      }
    }
    default: {
      return state;
    }
  }
};

export default SettingReducer;
