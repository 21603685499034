import { createAsyncAction } from 'utils/Redux';

export type StoryHubStudioMeta = {
  storyId: string;
};

const ns = 'STORY_HUB_STUDIO_VIDEO';
const asyncAction = createAsyncAction<StoryHubStudioMeta>(ns);

const storyHubStudioActions = {
  ...asyncAction,
};

export default storyHubStudioActions;
