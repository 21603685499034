import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getContentMarketplace } from 'services/marketplace';
import { AsyncAction } from 'types/Action';
import { GetContentMarketplaceMeta, GetContentMarketplacePayload } from 'types/store/GetContentMarketplace';
import { Store } from 'types/store/Store';

import { getContentMarketplaceActions } from '../actions';

function* getContentMarketplaceRequest(action: AsyncAction<GetContentMarketplaceMeta, GetContentMarketplacePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getContentMarketplace, action.meta, state.auth.token);
      yield put(getContentMarketplaceActions.success(data));
    } catch (e: any) {
      yield put(getContentMarketplaceActions.failed(e.message));
    }
  }
}

// function* getContentMarketplaceSuccess(action: AsyncAction<GetContentMarketplaceMeta, GetContentMarketplacePayload>): any {
//   yield put()
// }

function* getContentMarketplaceWatcher(): any {
  yield takeLatest(getContentMarketplaceActions.REQUEST, getContentMarketplaceRequest);
  // yield takeLatest(getContentMarketplaceActions.SUCCESS, getContentMarketplaceSuccess);
}

export default getContentMarketplaceWatcher;
