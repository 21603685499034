import React from 'react';

import { SVGProps } from './SVG.props';

const Globe = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg width={width || 18} height={height || 18} viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M8.99169 0.666656C4.39169 0.666656 0.666687 4.39999 0.666687 8.99999C0.666687 13.6 4.39169 17.3333 8.99169 17.3333C13.6 17.3333 17.3334 13.6 17.3334 8.99999C17.3334 4.39999 13.6 0.666656 8.99169 0.666656ZM14.7667 5.66666H12.3084C12.0417 4.62499 11.6584 3.62499 11.1584 2.69999C12.6917 3.22499 13.9667 4.29166 14.7667 5.66666ZM9.00002 2.36666C9.69169 3.36666 10.2334 4.47499 10.5917 5.66666H7.40835C7.76669 4.47499 8.30835 3.36666 9.00002 2.36666ZM2.55002 10.6667C2.41669 10.1333 2.33335 9.57499 2.33335 8.99999C2.33335 8.42499 2.41669 7.86666 2.55002 7.33332H5.36669C5.30002 7.88332 5.25002 8.43332 5.25002 8.99999C5.25002 9.56666 5.30002 10.1167 5.36669 10.6667H2.55002ZM3.23335 12.3333H5.69169C5.95835 13.375 6.34169 14.375 6.84169 15.3C5.30835 14.775 4.03335 13.7167 3.23335 12.3333ZM5.69169 5.66666H3.23335C4.03335 4.28332 5.30835 3.22499 6.84169 2.69999C6.34169 3.62499 5.95835 4.62499 5.69169 5.66666ZM9.00002 15.6333C8.30835 14.6333 7.76669 13.525 7.40835 12.3333H10.5917C10.2334 13.525 9.69169 14.6333 9.00002 15.6333ZM10.95 10.6667H7.05002C6.97502 10.1167 6.91669 9.56666 6.91669 8.99999C6.91669 8.43332 6.97502 7.87499 7.05002 7.33332H10.95C11.025 7.87499 11.0834 8.43332 11.0834 8.99999C11.0834 9.56666 11.025 10.1167 10.95 10.6667ZM11.1584 15.3C11.6584 14.375 12.0417 13.375 12.3084 12.3333H14.7667C13.9667 13.7083 12.6917 14.775 11.1584 15.3ZM12.6334 10.6667C12.7 10.1167 12.75 9.56666 12.75 8.99999C12.75 8.43332 12.7 7.88332 12.6334 7.33332H15.45C15.5834 7.86666 15.6667 8.42499 15.6667 8.99999C15.6667 9.57499 15.5834 10.1333 15.45 10.6667H12.6334Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Globe;
