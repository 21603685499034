import styled from 'utils/styled';

export const Container = styled.div<{
  direction?: 'row' | 'column';
}>`
  display: flex;
  ${({ direction }) =>
    direction
      ? `
          flex-direction: ${direction};
        `
      : `flex-direction: column;`}
`;

export const RadioContainer = styled.div<{
  direction?: 'row' | 'column';
}>`
  margin-top: 8px;

  ${({ direction }) => direction === 'row' ? `
    margin-right: 24px;
  ` : null}
`;
