import axios from 'axios';
import { API } from 'consts';
import { GetLinkedinConnectionStatusMeta } from 'types/store/GetLinkedinConnectionStatus';
import { AuthLinkedInMeta } from 'types/store/LinkedIn';
import { ShareVideoToLinkedinMeta } from 'types/store/ShareVideoToLinkedin';
import { VerifyCodeLinkedinMeta } from 'types/store/VerifyCodeLinkedin';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const NOTIFICATIONS_URL = `${BASE_URL}/linkedin`;

export const requestLinkAuth: any = () => {
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/getLinkAuth`,
  });
};

export const authWithLinkedin: any = (data: AuthLinkedInMeta) => {
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/authWithLinkedin`,
    data: data,
  });
};

export const verifyCodeLinkedin: any = (data: VerifyCodeLinkedinMeta, token: string) => {
  console.log("verifyAuthCode");
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/verifyAuthCode`,
    data,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

export const disconnectLinkedin: any = (_: any, token: string) => {
  return axios({
    method: 'put',
    url: `${NOTIFICATIONS_URL}/disconnect`,
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

export const getLinkedinConnectionStatus: any = (data: GetLinkedinConnectionStatusMeta, token: string) => {
  return axios({
    method: 'get',
    url: `${NOTIFICATIONS_URL}/status`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

export const shareToLinkedin: any = (data: GetLinkedinConnectionStatusMeta, token: string) => {
  return axios({
    method: 'post',
    url: `${NOTIFICATIONS_URL}/shares`,
    data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
}

// Upload and share video to linkedin
export const shareVideoToLinkedin: any = async (data: ShareVideoToLinkedinMeta, token: string) => {
  const downloadFile = await axios.get(data.url, {
    responseType: 'blob'
  })
  const file = new File([downloadFile.data], "file_name.mp4", { lastModified: 1534584790000 })
  const registerRes = await axios.get(`${NOTIFICATIONS_URL}/registerUploadVideo`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      fileSize: file.size
    }
  });
  const uploadInstructions = registerRes.data?.data?.value?.uploadInstructions;
  if (!uploadInstructions) {
    throw new Error("Register upload video fail.");
  }

  const form = new FormData();

  form.append('uploadInstructions', JSON.stringify(uploadInstructions));
  form.append('video', registerRes?.data?.data?.value?.video);
  form.append('file', file);

  await axios.post(`${NOTIFICATIONS_URL}/uploadVideo`, form, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`
    }
  });

  const response = await axios.post(`${NOTIFICATIONS_URL}/shareVideo`, {
    commentary: data.text,
    visibility: "PUBLIC",
    distribution: {
      feedDistribution: "MAIN_FEED",
    },
    content: {
      media: {
        id: registerRes?.data?.data?.value?.video
      }
    },
    lifecycleState: "PUBLISHED",
    isReshareDisabledByAuthor: true
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  // console.log(new File([downloadFile.data], "file_name.mp4", { lastModified: +new Date() }));
  // //new File([downloadFile.data], "file_name.mp4", { lastModified: 1534584790000 })
  // const response = await axios.put(uploadMechanism.uploadUrl, data.file, {
  //   headers: {
  //     // 'Content-Type': 'multipart/form-data',
  //     ...uploadMechanism.headers
  //   }
  // });
  return response.data;
}

// Upload and share to Linkedin without Martec Server
export const shareVideoToLinkedinV2: any = async (data: ShareVideoToLinkedinMeta, token: string) => {
  const downloadFile = await axios.get(data.url, {
    responseType: 'arraybuffer'
  })
  const fileData = downloadFile.data

  const registerRes = await axios.get(`${NOTIFICATIONS_URL}/registerUploadVideo`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      fileSize: fileData.byteLength
    }
  });
  const uploadInstructions = registerRes.data?.data?.value?.uploadInstructions;
  if (!uploadInstructions) {
    throw new Error("Register upload video fail.");
  }

  const promises = uploadInstructions.map(async (upload: { firstByte: number, lastByte: number, uploadUrl: string }) => {
    try {
      const uploaded = await axios.post(upload.uploadUrl, fileData.slice(upload.firstByte, upload.lastByte + 1), {
        headers: {
          'Content-Type': 'application/octet-stream'
        },
      })
      return uploaded.headers.etag
    } catch (error: any) {
      return null;
    }
  });
  const uploadeds = await Promise.all(promises)
  if (!uploadeds.filter(Boolean).length) {
    throw new Error("Upload video fail.");
  }

  await axios.post(`${NOTIFICATIONS_URL}/verifyUpload`, {
    uploadedPartIds: uploadeds,
    video: registerRes?.data?.data?.value?.video
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  const response = await axios.post(`${NOTIFICATIONS_URL}/shareVideo`, {
    commentary: data.text,
    visibility: "PUBLIC",
    distribution: {
      feedDistribution: "MAIN_FEED",
    },
    content: {
      media: {
        id: registerRes?.data?.data?.value?.video
      }
    },
    lifecycleState: "PUBLISHED",
    isReshareDisabledByAuthor: true
  }, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}