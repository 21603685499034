import {
  UpdateUserSettingMeta,
  UpdateUserSettingPayload,
} from 'types/store/UpdateUserSettingState';
import { createAsyncReducer } from 'utils/Redux';

import { updateUserSettingActions } from '../actions';

export default createAsyncReducer<
  UpdateUserSettingMeta,
  UpdateUserSettingPayload
>(updateUserSettingActions);
