import { push } from 'connected-react-router';
import { MAIN_ROUTES } from 'consts';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import { createRevision } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  CreateRevisionMeta,
  CreateRevisionPayload,
} from 'types/store/CreateRevisionState';
import { Store } from 'types/store/Store';

import { createRevisionActions } from '../actions';

function* createRevisionRequest(
  action: AsyncAction<CreateRevisionMeta, CreateRevisionPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createRevision,
        action.meta,
        state.auth.token
      );
      yield put(createRevisionActions.success(data));
    } catch (e: any) {
      if (e.response.status === 404) {
        yield put(push(MAIN_ROUTES.NOT_FOUND));
      } else {
        yield put(createRevisionActions.failed(e.message));
      }
    }
  }
}

// function* createRevisionSuccess(
//   action: AsyncAction<CreateRevisionMeta, CreateRevisionPayload>
// ) {
//   //   yield put(push(EMPLOYER_ROUTES.STORY_EDITOR));
//   //   window.location.reload();
// }

function* createRevisionWatcher(): any {
  yield takeLatest(createRevisionActions.REQUEST, createRevisionRequest);
  // yield takeLatest(createRevisionActions.SUCCESS, createRevisionSuccess);
}

export default createRevisionWatcher;
