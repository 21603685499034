import { put, call, takeLatest, select } from 'redux-saga/effects';
import { removeCard } from 'services/payment';
import { AsyncAction } from 'types/Action';
import {
  RemoveCardMeta,
  RemoveCardPayload
} from 'types/store/RemoveCard';
import { Store } from 'types/store/Store';

import {
  removeCardActions,
  getSavedCardsActions
} from '../actions';

function* removeCardRequest(
  action: AsyncAction<RemoveCardMeta, RemoveCardPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(removeCard, action.meta, state.auth.token);
      yield put(removeCardActions.success(data));
    } catch (e: any) {
      yield put(removeCardActions.failed(e.message));
    }
  }
}

function* removeCardSuccess(
  action: AsyncAction<RemoveCardMeta, RemoveCardPayload>
) {
  yield put(getSavedCardsActions.request({}));
}

function* removeCardWatcher(): any {
  yield takeLatest(removeCardActions.REQUEST, removeCardRequest);
  yield takeLatest(removeCardActions.SUCCESS, removeCardSuccess);
}

export default removeCardWatcher;
