import React from 'react';

import { SVGProps } from './SVG.props';

const Exit = (props: SVGProps): JSX.Element => {
  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg width={24} height={24} viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.334 8a.666.666 0 00-.667-.667h-5.06L9.14 5.806a.667.667 0 00-.217-1.093.667.667 0 00-.73.146L5.527 7.526a.667.667 0 00-.14.22.667.667 0 000 .507c.032.082.08.156.14.22l2.667 2.666a.67.67 0 10.946-.946L7.607 8.666h5.06a.667.667 0 00.667-.667zm-8.667 6.666h6.667a2 2 0 002-2v-2a.666.666 0 10-1.334 0v2a.667.667 0 01-.666.667H4.667A.667.667 0 014 12.666V3.333a.667.667 0 01.667-.667h6.667a.667.667 0 01.666.667v2a.667.667 0 101.334 0v-2a2 2 0 00-2-2H4.667a2 2 0 00-2 2v9.333a2 2 0 002 2z"
        fill="#B5ADB0"
      />
    </svg>
  );
};

export default Exit;
