import {
  DeleteShowcasePageMeta,
  DeleteShowcasePagePayload
} from 'types/store/DeleteShowcasePageState';
import { createAsyncReducer } from 'utils/Redux';

import { deleteShowcasePageActions } from '../actions';

export default createAsyncReducer<
  DeleteShowcasePageMeta,
  DeleteShowcasePagePayload
>(deleteShowcasePageActions);
