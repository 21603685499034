import { UpdateQuestionForStoryMeta, UpdateQuestionForStoryPayload } from 'types/store/UpdateQuestionForStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_QUESTION_FOR_STORY_NS';

const asyncAction = createAsyncAction<UpdateQuestionForStoryMeta, UpdateQuestionForStoryPayload>(ns);

const updateQuestionForStoryActions = { ...asyncAction };

export default updateQuestionForStoryActions;
