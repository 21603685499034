import { GetTopicsMeta, GetTopicsPayload } from 'types/store/GetTopics';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_TOPICS';
const asyncAction = createAsyncAction<GetTopicsMeta, GetTopicsPayload>(ns);

const getTopicsActions = {
  ...asyncAction,
};

export default getTopicsActions;
