import {
  RegisterAdvocateMeta,
  RegisterAdvocatePayload,
} from 'types/store/RegisterAdvocateState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'REGISTER_ADVOCATE';
const asyncAction = createAsyncAction<
  RegisterAdvocateMeta,
  RegisterAdvocatePayload
>(ns);

const registerAdvocateActions = {
  ...asyncAction,
};

export default registerAdvocateActions;
