import {
  GetShowcaseArticlesMeta,
  GetShowcaseArticlesPayload,
} from 'types/store/GetShowcaseArticlesState';
import { createAsyncReducer } from 'utils/Redux';

import { getShowcaseArticlesActions } from '../actions';

export default createAsyncReducer<
  GetShowcaseArticlesMeta,
  GetShowcaseArticlesPayload
>(getShowcaseArticlesActions);
