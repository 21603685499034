import { GetRecipeDiscussionDetailMeta, GetRecipeDiscussionDetailPayload } from 'types/store/GetRecipeDiscussionDetail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_RECIPE_DISCUSSION_DETAIL_NS';

const asyncAction = createAsyncAction<GetRecipeDiscussionDetailMeta, GetRecipeDiscussionDetailPayload>(ns);

const getRecipeDiscussionDetailActions = { ...asyncAction };

export default getRecipeDiscussionDetailActions;
