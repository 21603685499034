import {
  CreateRevisionMeta,
  CreateRevisionPayload,
} from 'types/store/CreateRevisionState';
import { createAsyncReducer } from 'utils/Redux';

import { createRevisionActions } from '../actions';

export default createAsyncReducer<
  CreateRevisionMeta,
  CreateRevisionPayload
>(createRevisionActions);
