import { CheckLibraryTranscribeMeta, CheckLibraryTranscribePayload } from 'types/store/CheckLibraryTranscribe';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHECK_LIBRARY_TRANSCRIBE_NS';

const asyncAction = createAsyncAction<CheckLibraryTranscribeMeta, CheckLibraryTranscribePayload>(ns);

const checkLibraryTranscribeActions = { ...asyncAction };

export default checkLibraryTranscribeActions;
