import {
  GetFinalWrittenStoryMeta,
  GetFinalWrittenStoryPayload,
} from 'types/store/GetFinalWrittenStoryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_FINAL_WRITTEN_STORY';
const asyncAction = createAsyncAction<
  GetFinalWrittenStoryMeta,
  GetFinalWrittenStoryPayload
>(ns);

const getFinalWrittenStoryActions = {
  ...asyncAction,
};

export default getFinalWrittenStoryActions;
