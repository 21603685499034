import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateEmployeeProfileByAuth } from 'services/employee';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateEmployeeProfileByAuthMeta,
  UpdateEmployeeProfileByAuthPayload,
} from 'types/store/UpdateEmployeeProfileByAuth';

import { updateEmployeeProfileByAuthActions } from '../actions';

function* updateEmployeeProfileByAuthRequest(
  action: AsyncAction<
    UpdateEmployeeProfileByAuthMeta,
    UpdateEmployeeProfileByAuthPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateEmployeeProfileByAuth,
        action.meta,
        state.auth.token
      );
      yield put(updateEmployeeProfileByAuthActions.success(data));
    } catch (e: any) {
      yield put(updateEmployeeProfileByAuthActions.failed(e.message));
    }
  }
}

function* updateEmployeeProfileByAuthSuccess(
  action: AsyncAction<
    UpdateEmployeeProfileByAuthMeta,
    UpdateEmployeeProfileByAuthPayload
  >
) {
  //
}

function* updateEmployeeProfileByAuthWatcher(): any {
  yield takeLatest(
    updateEmployeeProfileByAuthActions.REQUEST,
    updateEmployeeProfileByAuthRequest
  );
  yield takeLatest(
    updateEmployeeProfileByAuthActions.SUCCESS,
    updateEmployeeProfileByAuthSuccess
  );
}

export default updateEmployeeProfileByAuthWatcher;
