import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getMoreQuestion } from 'services/question';
import { AsyncAction } from 'types/Action';
import { GetMoreQuestionMeta, GetMoreQuestionPayload } from 'types/store/GetMoreQuestion';
import { Store } from 'types/store/Store';

import { getMoreQuestionActions } from '../actions';

function* getMoreQuestionRequest(action: AsyncAction<GetMoreQuestionMeta, GetMoreQuestionPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getMoreQuestion, action.meta, state.auth.token);
      yield put(getMoreQuestionActions.success(data));
    } catch (e: any) {
      yield put(getMoreQuestionActions.failed(e.message));
    }
  }
}

function* getMoreQuestionSuccess(action: AsyncAction<GetMoreQuestionMeta, GetMoreQuestionPayload>) {
  // yield put()
}

function* getMoreQuestionWatcher(): any {
  yield takeLatest(getMoreQuestionActions.REQUEST, getMoreQuestionRequest);
  yield takeLatest(getMoreQuestionActions.SUCCESS, getMoreQuestionSuccess);
}

export default getMoreQuestionWatcher;
