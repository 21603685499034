import {
  GetFinalWrittenStoryMeta,
  GetFinalWrittenStoryPayload,
} from 'types/store/GetFinalWrittenStoryState';
import { createAsyncReducer } from 'utils/Redux';

import { getFinalWrittenStoryActions } from '../actions';

export default createAsyncReducer<
  GetFinalWrittenStoryMeta,
  GetFinalWrittenStoryPayload
>(getFinalWrittenStoryActions);
