import React from 'react';

import { SVGProps } from './SVG.props';

const DefaultWorkSpace = (props: SVGProps): JSX.Element => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="16" height="16" rx="4" fill="#F0ECEE" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54344 6.62623C9.54344 7.48287 8.8566 8.16975 7.99936 8.16975C7.14241 8.16975 6.45528 7.48287 6.45528 6.62623C6.45528 5.7696 7.14241 5.08301 7.99936 5.08301C8.8566 5.08301 9.54344 5.7696 9.54344 6.62623ZM7.99935 10.9163C6.73421 10.9163 5.66602 10.7107 5.66602 9.91737C5.66602 9.12374 6.74092 8.9254 7.99935 8.9254C9.26478 8.9254 10.3327 9.13103 10.3327 9.92437C10.3327 10.718 9.25778 10.9163 7.99935 10.9163Z"
        fill="#B5ADB0"
      />
    </svg>
  );
};

export default DefaultWorkSpace;
