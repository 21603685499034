import {
  GetShowcaseCompanyMeta,
  GetShowcaseCompanyPayload,
} from 'types/store/GetShowcaseCompanyState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_COMPANY';
const asyncAction = createAsyncAction<
  GetShowcaseCompanyMeta,
  GetShowcaseCompanyPayload
>(ns);

const getShowcaseCompanyActions = {
  ...asyncAction,
};

export default getShowcaseCompanyActions;
