import { GetTranscribeVideoMeta, GetTranscribeVideoPayload } from 'types/store/GetTranscribeVideo';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_TRANSCRIBE_VIDEO_NS';

const asyncAction = createAsyncAction<GetTranscribeVideoMeta, GetTranscribeVideoPayload>(ns);

const getTranscribeVideoActions = { ...asyncAction };

export default getTranscribeVideoActions;
