import {
    UpdateShowcaseArticlePositionMeta,
    UpdateShowcaseArticlePositionPayload,
} from 'types/store/UpdateShowcaseArticlePositionState';
import { createAsyncReducer } from 'utils/Redux';

import { updateShowcaseArticlePositionActions } from '../actions';

export default createAsyncReducer<
    UpdateShowcaseArticlePositionMeta,
    UpdateShowcaseArticlePositionPayload
>(updateShowcaseArticlePositionActions);
