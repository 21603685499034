import React from 'react';

import { SVGProps } from './SVG.props';

const BendarrowDownLeft = (props: SVGProps): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <g id="bendarrow-downleft">
        <path
          id="line"
          d="M4.27614 9.33333L6.4714 7.13807C6.73175 6.87772 6.73175 6.45561 6.4714 6.19526C6.21106 5.93491 5.78894 5.93491 5.5286 6.19526L2.19526 9.5286C1.93491 9.78895 1.93491 10.2111 2.19526 10.4714L5.5286 13.8047C5.78894 14.0651 6.21106 14.0651 6.4714 13.8047C6.73175 13.5444 6.73175 13.1223 6.4714 12.8619L4.27614 10.6667H9.33333C11.9107 10.6667 14 8.57733 14 6V2.66667C14 2.29848 13.7015 2 13.3333 2C12.9651 2 12.6667 2.29848 12.6667 2.66667V6C12.6667 7.84095 11.1743 9.33333 9.33333 9.33333H4.27614Z"
          fill="#060548"
        />
      </g>
    </svg>
  );
};

export default BendarrowDownLeft;
