import axios from 'axios';
import { API } from 'consts';
import {
  BrandKitStudioMeta,
  SetFavoriteMeta,
  TemplateStudioMeta,
  UploadVideoStudioMeta,
} from 'types/store/CreativeStudio';

import { uploadFile } from './upload';

const BASE_URL = `${API.URL}/${API.VERSION}`;

export const fetchBrandKit = (token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/studio/brand-kit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const fetchTemplate = (data: TemplateStudioMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/studio/templates`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const fetchRecentVideo = (token: string): any => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/studio/recent-video?page=1&limit=15`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateBrandKit = (
  data: BrandKitStudioMeta,
  token: string,
): any => {
  return axios({
    method: 'put',
    url: `${BASE_URL}/studio/brand-kit`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadLogos = (data: { files: File[] }, token: string) => {
  const formData = new FormData();
  for (const element of data.files) {
    formData.append('logos', element);
  }

  return axios({
    method: 'post',
    url: `${BASE_URL}/studio/brand-kit/logos`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const uploadFonts = (data: { files: File[] }, token: string) => {
  const formData = new FormData();
  for (const element of data.files) {
    formData.append('fonts', element);
  }

  return axios({
    method: 'post',
    url: `${BASE_URL}/studio/brand-kit/fonts`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const setFavoriteServices = (data: SetFavoriteMeta, token: string) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/studio/set-template-favorite`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const uploadVideoStudio = async (
  data: UploadVideoStudioMeta,
  token: string,
): Promise<any> => {
  if (!data.video_file) throw new Error('Missing file');

  try {
    // Upload file
    const uploadUrl = await uploadFile({
      file: data.video_file,
      token,
      folder: 'library',
    });
    const fd = new FormData();
    fd.append(
      'data',
      JSON.stringify({
        category_id: data.category_id,
        content_type: 'VIDEO',
        status: 'RAW',
        content: '',
        asset_upload_type: 'UPLOAD',
        asset_url: '',
        advocate_selection: [],
        headline: data.headline,
        publish_date: '',
        file_link: uploadUrl,
      }),
    );
    return axios({
      method: 'post',
      url: `${BASE_URL}/library/create`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: fd,
    });
  } catch (error: any) {
    console.log('request error', error);
    throw new Error(error);
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const createTemplate = (token: string) => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/studio/templates`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  });
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getEditTemplate = (templateId: string, token: string) => {
  return axios({
    method: 'get',
    url: `${BASE_URL}/studio/get-content-template?templateId=${templateId}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
