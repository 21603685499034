import React, { useState } from 'react';

import { v4 as uuid } from 'uuid';

import SnackbarContext from './context';
import {
  ISnackbarProvider,
  ISnackbarConfig,
  ISnackbarContent,
  ISnackbar,
  SnackbarType,
  ISnackbarTimeout,
} from './types';

const DEFAULT_SNACKBAR_DISPLAY_DURATION = 3000;

const SnackbarProvider = ({ children }: ISnackbarProvider): JSX.Element => {
  const [queue, setQueue] = useState<Array<ISnackbar>>([]);
  const [timeoutQueue, setTimeoutQueue] = useState<Array<ISnackbarTimeout>>([]);

  const removeTimeoutQueue = (id: string) => {
    setTimeoutQueue((prev: Array<ISnackbarTimeout>) => {
      const getTimeout = prev.find((_: ISnackbarTimeout) => _.id === id);
      if (getTimeout) {
        clearTimeout(getTimeout.timeout);
        return prev.filter((_: any) => _.id !== id);
      }
      return prev;
    });
  };

  const createSnackbarByType = (
    content: ISnackbarContent,
    type: keyof typeof SnackbarType,
    config?: ISnackbarConfig
  ) => {
    const snackbarId = uuid();
    setTimeoutQueue((prev: any) => {
      if (prev) {
        prev.push({
          id: snackbarId,
          timeout: setTimeout(() => {
            setQueue((prev: any) =>
              prev.filter((_: any) => _.id !== snackbarId)
            );
            removeTimeoutQueue(snackbarId);
          }, config?.duration || DEFAULT_SNACKBAR_DISPLAY_DURATION),
        });
      }
      return prev;
    });
    setQueue([
      ...queue,
      {
        ...content,
        type,
        duration: config?.duration || DEFAULT_SNACKBAR_DISPLAY_DURATION,
        ...(config ? config : {}),
        id: snackbarId,
      },
    ]);
  };

  const info = (content: ISnackbarContent) =>
    createSnackbarByType(content, SnackbarType.info, content?.config);

  const alert = (content: ISnackbarContent) =>
    createSnackbarByType(content, SnackbarType.alert, content?.config);

  const warning = (content: ISnackbarContent) =>
    createSnackbarByType(content, SnackbarType.warning, content?.config);

  const success = (content: ISnackbarContent) =>
    createSnackbarByType(content, SnackbarType.success, content?.config);

  const error = (content: ISnackbarContent) =>
    createSnackbarByType(content, SnackbarType.error, content?.config);

  const remove = (id: string) => {
    setQueue((prev) => prev.filter((_) => _.id !== id));
    removeTimeoutQueue(id);
  };

  return (
    <SnackbarContext.Provider
      value={{ queue, info, success, alert, warning, error, remove }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export default SnackbarProvider;
