import { put, call, takeLatest, select } from 'redux-saga/effects';
import { deletePage } from 'services/page';
import { AsyncAction } from 'types/Action';
import { DeletePageMeta, DeletePagePayload } from 'types/store/DeletePageState';
import { Store } from 'types/store/Store';

import { deletePageActions, getPagesbyCompanyIdActions } from '../actions';

function* deletePageRequest(
  action: AsyncAction<DeletePageMeta, DeletePagePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(deletePage, action.meta, state.auth.token);
      yield put(deletePageActions.success(data));
    } catch (e: any) {
      yield put(deletePageActions.failed(e.message));
    }
  }
}

function* deletePageSuccess(
  action: AsyncAction<DeletePageMeta, DeletePagePayload>
) {
  yield put(getPagesbyCompanyIdActions.request({}));
}

function* deletePageWatcher(): any {
  yield takeLatest(deletePageActions.REQUEST, deletePageRequest);
  yield takeLatest(deletePageActions.SUCCESS, deletePageSuccess);
}

export default deletePageWatcher;
