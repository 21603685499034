import { RemoveStoryMeta, RemoveStoryPayload } from 'types/store/RemoveStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'REMOVE_STORY_NS';

const asyncAction = createAsyncAction<RemoveStoryMeta, RemoveStoryPayload>(ns);

const removeStoryActions = { ...asyncAction };

export default removeStoryActions;
