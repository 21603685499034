import { put, call, takeLatest, select } from 'redux-saga/effects';
import { extractTextFromFile } from 'services/text';
import { AsyncAction } from 'types/Action';
import {
  ExtractTextFromFileMeta,
  ExtractTextFromFilePayload,
} from 'types/store/ExtractTextFromFileState';
import { Store } from 'types/store/Store';

import { extractTextFromFileActions } from '../actions';

function* extractTextFromFileRequest(
  action: AsyncAction<ExtractTextFromFileMeta, ExtractTextFromFilePayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        extractTextFromFile,
        action.meta,
        state.auth.token
      );
      yield put(extractTextFromFileActions.success(data));
    } catch (e: any) {
      yield put(extractTextFromFileActions.failed(e.message));
    }
  } else {
    yield put(extractTextFromFileActions.failed('Token not found'));
  }
}

// function* downloadResponsesSuccess(
//   action: AsyncAction<DownloadResponsesMeta, DownloadResponsesPayload>
// ) {
// }

function* extractTextFromFileWatcher(): any {
  yield takeLatest(
    extractTextFromFileActions.REQUEST,
    extractTextFromFileRequest
  );
  // yield takeLatest(downloadResponsesActions.SUCCESS, downloadResponsesSuccess);
}

export default extractTextFromFileWatcher;
