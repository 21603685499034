import {
    UpdateShowcasePagePositionMeta,
    UpdateShowcasePagePositionPayload,
} from 'types/store/UpdateShowcasePagePositionState';
import { createAsyncReducer } from 'utils/Redux';

import { updateShowcasePagePositionActions } from '../actions';

export default createAsyncReducer<
    UpdateShowcasePagePositionMeta,
    UpdateShowcasePagePositionPayload
>(updateShowcasePagePositionActions);
