import {
    GetListOptionAdvocateMeta,
    GetListOptionAdvocatePayload,
} from 'types/store/GetListOptionAdvocateState';
import { createAsyncReducer } from 'utils/Redux';

import { getListOptionAdvocateActions } from '../actions';

export default createAsyncReducer<
    GetListOptionAdvocateMeta,
    GetListOptionAdvocatePayload
>(getListOptionAdvocateActions);
