import { put, call, takeLatest, select } from 'redux-saga/effects';
import { StoryDiscussionAction } from 'routes/Advocate/StoryManagement/TopicCalibration/StoryDiscussionBox/StoryDiscussionBox.props';
import { updateStoryDiscussion } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateStoryDiscussionMeta,
  UpdateStoryDiscussionPayload,
} from 'types/store/UpdateStoryDiscussion';
import { StoryStatus } from 'types/Story';

import {
  updateStoryDiscussionActions,
  discussionStoryDetailsActions,
} from '../actions';

function* updateStoryDiscussionRequest(
  action: AsyncAction<UpdateStoryDiscussionMeta, UpdateStoryDiscussionPayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateStoryDiscussion,
        action.meta,
        state.auth.token
      );
      if (data.status === 200) {
        const selectedStoryDiscussionState = state.discussionStoryDetails.data;
        const selectedStoryDiscussionDetails =
          selectedStoryDiscussionState?.data;
        const updateAction = action.meta.action;
        let newStoryData = {
          ...selectedStoryDiscussionDetails,
        };
        switch (updateAction) {
          case StoryDiscussionAction.ACCEPT:
          case StoryDiscussionAction.DECLINE:
            newStoryData = {
              ...selectedStoryDiscussionDetails,
              user_story_statuses: [
                {
                  ...selectedStoryDiscussionDetails.user_story_statuses[0],
                  status:
                    updateAction === StoryDiscussionAction.ACCEPT
                      ? StoryStatus.ACCEPTED
                      : StoryStatus.DECLINED,
                },
              ],
            };
            yield put(
              discussionStoryDetailsActions.success({
                ...selectedStoryDiscussionState,
                data: newStoryData,
              })
            );
            yield put(updateStoryDiscussionActions.clear());
            break;
          case StoryDiscussionAction.SUBMIT:
          case StoryDiscussionAction.CANCEL:
            yield put(updateStoryDiscussionActions.success(data));
            break;
          default:
            break;
        }
      }
    } catch (e: any) {
      yield put(updateStoryDiscussionActions.failed(e.message));
    }
  }
}

// function* updateStoryDiscussionSuccess(action: AsyncAction<UpdateStoryDiscussionMeta, UpdateStoryDiscussionPayload>): any {
//   yield put()
// }

function* updateStoryDiscussionWatcher(): any {
  yield takeLatest(
    updateStoryDiscussionActions.REQUEST,
    updateStoryDiscussionRequest
  );
  // yield takeLatest(updateStoryDiscussionActions.SUCCESS, updateStoryDiscussionSuccess);
}

export default updateStoryDiscussionWatcher;
