import React from 'react';

import { SVGProps } from './SVG.props';

const Pencil = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="13" height="12" viewBox="0 0 13 12" fill="none" {...props}>
      <g id="pencil">
        <path id="line" fillRule="evenodd" clipRule="evenodd" d="M8.64645 1.14645C8.84171 0.951184 9.15829 0.951184 9.35355 1.14645L11.3536 3.14645C11.5488 3.34171 11.5488 3.65829 11.3536 3.85355L4.35355 10.8536C4.25979 10.9473 4.13261 11 4 11H2C1.72386 11 1.5 10.7761 1.5 10.5V8.5C1.5 8.36739 1.55268 8.24021 1.64645 8.14645L8.64645 1.14645ZM8.20711 3L9.5 4.29289L10.2929 3.5L9 2.20711L8.20711 3ZM8.79289 5L7.5 3.70711L3.20711 8L4.5 9.29289L8.79289 5ZM3.79289 10L2.5 8.70711V10H3.79289Z" fill="white" />
      </g>
    </svg>
  );
};

export default Pencil;
