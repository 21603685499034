import { Reducer } from 'redux';
import {
  GENERATE_GIF,
  GET_EMBED_JS,
  GET_LIST_EMAIL,
  GET_LIST_MEDIA,
  GET_MAGIC_LINK,
  GET_OKTA_SETTING,
  OKTA_REMOVE_SSO,
  RENEW_SSO_CERT,
  SAVE_CONTENT_GENERATE,
  UPLOAD_SSO,
} from 'store/actions/libraryShareContent.action';
import { REGISTER_GLOBAL } from 'store/actions/registerGlobalEvent';
import { Action } from 'types/Action';
import { ShareLibrary } from 'types/store/ShareLibrary';

const initState: ShareLibrary = {
  images: [],
  videos: [],
  isLoading: false,
  isRenewingCert: false,
  jsCode: '',
  iframeCode: '',
  content: '',
  listEmail: [],
  inviteMagicLink: '',
  oktaSetting: {},
};

const LibraryShareContent: Reducer<ShareLibrary, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case GET_LIST_MEDIA.GET_LIST_MEDIA_REQUEST: {
      return {
        ...state,
        images: [],
        videos: [],
        isLoading: true,
      };
    }
    case GET_LIST_MEDIA.GET_LIST_MEDIA_SUCCESS: {
      return {
        ...state,
        images: payload.images,
        videos: payload.videos,
      };
    }
    case GENERATE_GIF.GENERATE_GIF_SUCCESS: {
      return {
        ...state,
        videos: payload,
        isLoading: false,
      };
    }
    case GENERATE_GIF.GENERATE_GIF_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GENERATE_GIF.GENERATE_GIF_CLEAR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case GET_EMBED_JS.GET_EMBED_JS_REQUEST: {
      return {
        ...state,
        jsCode: '',
        iframeCode: '',
        isLoading: true,
      };
    }
    case GET_EMBED_JS.GET_EMBED_JS_SUCCESS: {
      return {
        ...state,
        jsCode: payload.jsCode,
        iframeCode: payload.iframeCode,
        isLoading: false,
      };
    }
    case SAVE_CONTENT_GENERATE.SAVE_CONTENT_GENERATE_REQUEST: {
      // when payload = '' (action when close sharing modal) , also update inviteLink to empty to clear data
      if (payload === '') {
        return {
          ...state,
          content: payload,
          inviteMagicLink: '',
        };
      } else {
        return {
          ...state,
          content: payload,
        };
      }
    }
    case GET_LIST_EMAIL.GET_LIST_EMAIL_SUCCESS: {
      return {
        ...state,
        listEmail: payload,
      };
    }
    case GET_MAGIC_LINK.GET_MAGIC_LINK_REQUEST: {
      return {
        ...state,
        inviteMagicLink: '',
      };
    }
    case GET_MAGIC_LINK.GET_MAGIC_LINK_SUCCESS: {
      return {
        ...state,
        inviteMagicLink: payload,
      };
    }
    case GET_OKTA_SETTING.GET_OKTA_SETTING_REQUEST: {
      return {
        ...state,
        isLoading: true,
        oktaSetting: {},
      };
    }
    case OKTA_REMOVE_SSO.OKTA_REMOVE_SSO_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPLOAD_SSO.UPLOAD_SSO_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case UPLOAD_SSO.UPLOAD_SSO_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case RENEW_SSO_CERT.RENEW_SSO_CERT_REQUEST: {
      return {
        ...state,
        isLoading: true,
        // isRenewingCert: true,
      }
    }
    case RENEW_SSO_CERT.RENEW_SSO_CERT_ERROR: {
      return {
        ...state,
        isLoading: false,
        isRenewingCert: false,
      }
    }
    case RENEW_SSO_CERT.RENEW_SSO_CERT_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        isRenewingCert: false,
        oktaSetting: payload
      }
    }
    case GET_OKTA_SETTING.GET_OKTA_SETTING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oktaSetting: payload,
      };
    }
    case UPLOAD_SSO.UPLOAD_SSO_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        oktaSetting: payload,
      };
    }
    case GET_OKTA_SETTING.GET_OKTA_SETTING_ERROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case REGISTER_GLOBAL.REGISTER_GLOBAL_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case REGISTER_GLOBAL.REGISTER_GLOBAL_EMAIL_EROR: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case REGISTER_GLOBAL.REGISTER_GLOBAL_SUCCESS: {
      return {
        ...state,
        isLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default LibraryShareContent;
