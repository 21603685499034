import {
  GetInterestByCommunityMeta,
  GetInterestByCommunityPayload,
} from 'types/store/GetInterestByCommunityState';
import { createAsyncReducer } from 'utils/Redux';

import { getInterestByCommunityActions } from '../actions';

export default createAsyncReducer<
  GetInterestByCommunityMeta,
  GetInterestByCommunityPayload
>(getInterestByCommunityActions);
