import { put, call, takeLatest, select } from 'redux-saga/effects';
import { markReadNotifications } from 'services/notifications';
import { AsyncAction } from 'types/Action';
import {
  MarkReadNotificationsStateMeta,
  MarkReadNotificationsStatePayload,
} from 'types/store/MarkReadNotificationsState';
import { Store } from 'types/store/Store';

import {
  markReadNotificationsActions,
  getNotificationsByUserIdActions,
  getAppbarInfoActions,
} from '../actions';

function* markReadNotificationsRequest(
  action: AsyncAction<
    MarkReadNotificationsStateMeta,
    MarkReadNotificationsStatePayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        markReadNotifications,
        action.meta,
        state.auth.token
      );
      yield put(markReadNotificationsActions.success(data));
    } catch (e: any) {
      yield put(markReadNotificationsActions.failed(e.message));
    }
  } else {
    yield put(markReadNotificationsActions.failed('Token not found'));
  }
}

function* markReadNotificationsSuccess(
  action: AsyncAction<
    MarkReadNotificationsStateMeta,
    MarkReadNotificationsStatePayload
  >
) {
  yield put(getNotificationsByUserIdActions.request({}));
  yield put(getAppbarInfoActions.request({}));
}

function* markReadNotificationsWatcher(): any {
  yield takeLatest(
    markReadNotificationsActions.REQUEST,
    markReadNotificationsRequest
  );
  yield takeLatest(
    markReadNotificationsActions.SUCCESS,
    markReadNotificationsSuccess
  );
}

export default markReadNotificationsWatcher;
