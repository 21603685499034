import {
    CreateNewCustomerMeta,
    CreateNewCustomerPayload
  } from 'types/store/CreateNewCustomer';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'CREATE_NEW_CUSTOMER';
  
  const asyncAction = createAsyncAction<CreateNewCustomerMeta, CreateNewCustomerPayload>(ns);
  
  const createNewCustomerActions = {
    ...asyncAction,
  };
  
  export default createNewCustomerActions;
  