import axios from 'axios';
import { API } from 'consts';
import { CreateSmsTemplateMeta } from 'types/store/CreateSmsTemplate';
import { DeleteSmsTemplateMeta } from 'types/store/DeleteSmsTemplate';
import { GetSmsTemplateMeta } from 'types/store/GetSmsTemplate';
import { MaskAsDefaultSmsTemplateMeta } from 'types/store/MaskAsDefaultSmsTemplate';
import { UpdateSmsTemplateMeta } from 'types/store/UpdateSmsTemplate';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const SMS_TEMPLATE_URL = `${BASE_URL}/template-sms`;

export const getSmsTemplate = (
  data: GetSmsTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${SMS_TEMPLATE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createSmsTemplate = (
  data: CreateSmsTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${SMS_TEMPLATE_URL}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateSmsTemplate = (
  data: UpdateSmsTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'put',
    url: `${SMS_TEMPLATE_URL}/${data.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const maskAsDefaultSmsTemplate = (
  data: MaskAsDefaultSmsTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'put',
    url: `${SMS_TEMPLATE_URL}/${data.id}/mark-as-default`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteSmsTemplate = (
  data: DeleteSmsTemplateMeta,
  token: string
): any => {
  return axios({
    method: 'delete',
    url: `${SMS_TEMPLATE_URL}/${data.id}`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
