import React from 'react';

import { SVGProps } from './SVG.props';

const UploadImage = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.871 1.5h-17.8c-.873 0-1.571.7-1.571 1.517v17.966c0 .817.698 1.517 1.57 1.517h17.86c.872 0 1.57-.7 1.57-1.517V3.017A1.648 1.648 0 0020.871 1.5zM4.583 9.375h3.083v10.033H4.583V9.375zm1.57-4.958c.99 0 1.804.816 1.804 1.808 0 .992-.814 1.808-1.803 1.808A1.815 1.815 0 014.35 6.225c0-1.05.815-1.808 1.804-1.808zm6.458 4.958H9.644v9.917h3.141v-4.959c0-1.283.233-2.566 1.862-2.566s1.629 1.516 1.629 2.683v4.9h3.083v-5.483c0-2.684-.582-4.784-3.723-4.784-1.513 0-2.56.817-2.967 1.634h-.058V9.375z"
        fill={fill === undefined ? '#0065A1' : fill}
      />
    </svg>
  );
};

export default UploadImage;
