import {
  UpdateUserByIdMeta,
  UpdateUserByIdPayload,
} from 'types/store/UpdateUserById';
import { createAsyncReducer } from 'utils/Redux';

import { updateUserByIdActions } from '../actions';

export default createAsyncReducer<UpdateUserByIdMeta, UpdateUserByIdPayload>(
  updateUserByIdActions
);
