import { Action } from 'types/Action';

export enum SIGNIN_OKTA {
  SIGNIN_OKTA_REQUEST = '@@_SIGNIN_OKTA_REQUEST',
  SIGNIN_OKTA_SUCCESS = '@@_SIGNIN_OKTA_SUCCESS'
}

export enum SIGNOUT_OKTA {
  SIGNOUT_OKTA_REQUEST = '@@_SIGNOUT_OKTA_REQUEST',
  SIGNOUT_OKTA_SUCCESS = '@@_SIGNOUT_OKTA_SUCCESS',
  SIGNOUT_OKTA_EROR = '@@_SIGNOUT_OKTA_EROR'
}

export type SignInOKtaMeta = {
  email?: string;
  companyId?: string;
  storyId?: string;
  inviteToken?: string;
  inviteType?: string;
  reference?: string;
};

export const signinOktaRequest = (payload: SignInOKtaMeta): Action => ({
  type: SIGNIN_OKTA.SIGNIN_OKTA_REQUEST,
  payload,
});

export const signoutOktaRequest = (payload: { action: 'SSO' | '', companyId?: string, deepLink?: string }): Action => ({
  type: SIGNOUT_OKTA.SIGNOUT_OKTA_REQUEST,
  payload
})
