/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconGrid = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_2068_6839)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3327 2.66667C15.3327 1.5621 14.4373 0.666666 13.3327 0.666666H9.33268C8.96449 0.666666 8.66602 0.965143 8.66602 1.33333V6.66667C8.66602 7.03486 8.96449 7.33333 9.33268 7.33333H14.666C15.0342 7.33333 15.3327 7.03486 15.3327 6.66667V2.66667ZM13.3327 2C13.7009 2 13.9993 2.29848 13.9993 2.66667V6H9.99935V2L13.3327 2Z"
          fill="#060548"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.666016 13.3333C0.666016 14.4379 1.56145 15.3333 2.66602 15.3333H6.66602C7.03421 15.3333 7.33268 15.0349 7.33268 14.6667V9.33333C7.33268 8.96514 7.03421 8.66667 6.66602 8.66667H1.33268C0.964492 8.66667 0.666016 8.96514 0.666016 9.33333V13.3333ZM2.66602 14C2.29783 14 1.99935 13.7015 1.99935 13.3333L1.99935 10H5.99935V14H2.66602Z"
          fill="#060548"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.33268 15.3333C8.96449 15.3333 8.66602 15.0349 8.66602 14.6667V9.33333C8.66602 8.96514 8.96449 8.66667 9.33268 8.66667H14.666C15.0342 8.66667 15.3327 8.96514 15.3327 9.33333V13.3333C15.3327 14.4379 14.4373 15.3333 13.3327 15.3333H9.33268ZM9.99935 14H13.3327C13.7009 14 13.9993 13.7015 13.9993 13.3333V10H9.99935V14Z"
          fill="#060548"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.33268 1.33333C7.33268 0.965143 7.03421 0.666666 6.66602 0.666666H2.66602C1.56145 0.666666 0.666016 1.5621 0.666016 2.66667V6.66667C0.666016 7.03486 0.964492 7.33333 1.33268 7.33333H6.66602C7.03421 7.33333 7.33268 7.03486 7.33268 6.66667V1.33333ZM2.66602 2L5.99935 2L5.99935 6H1.99935L1.99935 2.66667C1.99935 2.29848 2.29783 2 2.66602 2Z"
          fill="#060548"
        />
      </g>
      <defs>
        <clipPath id="clip0_2068_6839">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default IconGrid;
