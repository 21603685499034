export interface ISnackbarContext extends ISnackbarAction {
  queue: Array<ISnackbar>;
}

export interface ISnackbarTimeout {
  id: string;
  timeout: NodeJS.Timeout;
}

export interface ISnackbarAction {
  info: (content: ISnackbarContent) => void;
  success: (content: ISnackbarContent) => void;
  alert: (content: ISnackbarContent) => void;
  warning: (content: ISnackbarContent) => void;
  error: (content: ISnackbarContent) => void;
  remove: (id: string) => void;
}

export interface ISnackbarContent {
  title: string;
  description?: string;
  config?: ISnackbarConfig;
}

export interface ISnackbarConfig {
  duration?: number;
  onClose?: () => void;
}

export interface ISnackbarProvider {
  children?: React.ReactNode;
}

export interface ISnackbar extends ISnackbarContent, ISnackbarConfig {
  id: string;
  type: keyof typeof SnackbarType;
}

export enum SnackbarType {
  info = 'info',
  success = 'success',
  alert = 'alert',
  warning = 'warning',
  error = 'error',
}
