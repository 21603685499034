import {
  GetStoriesByUserIdMeta,
  GetStoriesByUserIdPayload,
} from 'types/store/GetStoriesByUserId';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORIES_BY_USER_ID';
const asyncAction = createAsyncAction<
  GetStoriesByUserIdMeta,
  GetStoriesByUserIdPayload
>(ns);

const getStoriesByUserIdActions = {
  ...asyncAction,
};

export default getStoriesByUserIdActions;
