import { MarkStoryAsPublishedMeta, MarkStoryAsPublishedPayload } from 'types/store/MarkStoryAsPublished';
import { createAsyncAction } from 'utils/Redux';

const ns = 'MARK_STORY_AS_PUBLISHED';

const asyncAction = createAsyncAction<MarkStoryAsPublishedMeta, MarkStoryAsPublishedPayload>(ns);

const markStoryAsPublishedActions = { ...asyncAction };

export default markStoryAsPublishedActions;
