import {
  DeleteLibraryMeta,
  DeleteLibraryPayload,
} from 'types/store/DeleteLibraryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DELETE_LIBRARY';

const asyncAction = createAsyncAction<DeleteLibraryMeta, DeleteLibraryPayload>(ns);

const deleteLibraryActions = {
  ...asyncAction,
};

export default deleteLibraryActions;
