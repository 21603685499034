import {
  UploadApprovalStoryMeta,
  UploadApprovalStoryPayload,
} from 'types/store/UploadApprovalStoryState';
import { createAsyncReducer } from 'utils/Redux';

import { uploadApprovalStoryActions } from '../actions';

export default createAsyncReducer<
  UploadApprovalStoryMeta,
  UploadApprovalStoryPayload
>(uploadApprovalStoryActions);
