export default function countWords(s: string): any {
  if (typeof s === 'string') {
    s = s.replace(/(^\s*)|(\s*$)/gi, ''); //exclude  start and end white-space
    s = s.replace(/[ ]{2,}/gi, ' '); //2 or more space to 1
    s = s.replace(/\n /, ' '); // exclude newline with a start spacing
    s = s.replaceAll('\n', ' ');
    return s.split(' ').filter((_: any) => _ != '').length;
  }
  return 0;
}
