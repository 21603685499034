import { GenericResponse } from 'types/GenericResponse';
import { SaveDynamicMetaState } from 'types/store/saveDynamicFilter';
import {
  createAsyncAction,
  createClearAction,
  createUpdateAction,
} from 'utils/Redux';

const ns = 'SAVE_DYNAMIC_FILTER_STORY_HUB';
const asyncAction = createUpdateAction<SaveDynamicMetaState>(ns);

const clearAction = createClearAction(ns);

const saveDynamicFilterStoryHubActions = {
  ...asyncAction,
  ...clearAction,
};

export default saveDynamicFilterStoryHubActions;
