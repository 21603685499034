import Typography from 'components/base/Typography';
import { Theme } from 'types/Theme';
import styled from 'utils/styled';

import { TextFieldProps } from './TextField.props';

export const Container = styled.div``;

export const FieldContainer = styled.div<{
  bills?: boolean;
  error?: string | undefined | boolean;
  highlightError?: boolean;
}>`
  display: flex;
  flex-direction: row;
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  border: ${({ theme, bills, error, highlightError }) =>
    bills
      ? 'none'
      : `1px solid ${
          error && highlightError !== false
            ? theme.brand.error
            : theme.grey.shade4
        }`};
  background-color: ${({ theme }) => theme.grey.noshade};
  align-items: center;
  margin-top: 4px;
`;

export const Field = styled.input<TextFieldProps>`
  font-family: 'Objective';
  background-color: ${({ theme, bills }) =>
    bills ? theme.neutral.white : theme.grey.noshade};
  display: flex;
  flex: 1;
  border-radius: 12px;
  padding: ${({ staticText }) =>
    staticText ? '8px 12px 8px 0px' : '8px 12px'};
  border: 0;
  height: ${({ height }) => height};
  color: ${({ theme, bills }) =>
    bills ? theme.altGrey.shade6 : theme.brand.primary};

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;

  ::placeholder {
    font-weight: normal;
    color: ${({ theme }) => theme.grey.shade6};
  }
  :focus {
    outline: none;
    color: ${({ theme, bills }) => bills && theme.altGrey.shade7};
  }

  :disabled {
    background-color: ${({ theme }) => theme.grey.shade3};
    color: ${({ theme }) => theme.grey.shade6};
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 8px 12px;
  }
`;

export const LeftComponentContainer = styled.div<
  TextFieldProps & { theme: Theme; clickable: boolean }
>`
  display: flex;
  width: ${({ LeftComponentWidth }) => {
    if (LeftComponentWidth === undefined) return `48px`;
    return LeftComponentWidth;
  }};
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right: ${({ noLeftCompBorder, theme }) => {
    if (noLeftCompBorder) return `0`;
    return `1px solid ${theme.grey.shade5}`;
  }};

  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const RightComponentContainer = styled.div<
  TextFieldProps & { theme: Theme; clickable: boolean }
>`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: ${({ RightComponentWidth }) => {
    if (RightComponentWidth === undefined) return `48px`;
    return RightComponentWidth;
  }};

  border-left: ${({ theme, noRightCompBorder }) => {
    if (noRightCompBorder) return `none`;
    return `1px solid ${theme.grey.shade5}`;
  }};

  ${({ clickable }) => clickable && 'cursor: pointer;'}
`;

export const VisibilityContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding-right: 10px;
  padding-left: 4px;
`;

export const Error = styled(Typography)`
  margin-top: 4px;
`;

export const LabelMain = styled(Typography)<{
  type?: string;
}>`
  ${({ type }) => {
    if (type === 'overline') {
      return `line-height: 16px;`;
    }
  }}
`;
