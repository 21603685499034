import { put, call, takeLatest, select } from 'redux-saga/effects';
import { checkUrl } from 'services/page';
import { AsyncAction } from 'types/Action';
import { CheckUrlMeta, CheckUrlPayload } from 'types/store/CheckUrlState';
import { Store } from 'types/store/Store';

import { checkUrlActions } from '../actions';

function* checkUrlRequest(action: AsyncAction<CheckUrlMeta, CheckUrlPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(checkUrl, action.meta, state.auth.token);
      yield put(checkUrlActions.success(data));
      // yield put(getPagesbyCompanyIdActions.success(data));
    } catch (e: any) {
      // yield put(getPagesbyCompanyIdActions.failed(e.message));
    }
  } else {
    // yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  }
}

function* checkUrlWatcher(): any {
  yield takeLatest(checkUrlActions.REQUEST, checkUrlRequest);
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default checkUrlWatcher;
