import {
  GetShowcaseFeedbacksMeta,
  GetShowcaseFeedbacksPayload,
} from 'types/store/GetShowcaseFeedbacksState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_FEEDBACKS';
const asyncAction = createAsyncAction<
  GetShowcaseFeedbacksMeta,
  GetShowcaseFeedbacksPayload
>(ns);

const getShowcaseFeedbacksActions = {
  ...asyncAction,
};

export default getShowcaseFeedbacksActions;
