import { EbCommentOnRecipeMeta, EbCommentOnRecipePayload } from 'types/store/EbCommentOnRecipe';
import { createAsyncAction } from 'utils/Redux';

const ns = 'EB_COMMENT_ON_RECIPE_NS';

const asyncAction = createAsyncAction<EbCommentOnRecipeMeta, EbCommentOnRecipePayload>(ns);

const ebCommentOnRecipeActions = { ...asyncAction };

export default ebCommentOnRecipeActions;
