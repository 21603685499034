import React from 'react';

import { SVGProps } from './SVG.props';

const ChevronDown = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '12'}
      height={height || '8'}
      viewBox="0 0 12 8"
      fill={fill || 'none'}
    >
      <path
        d="M11 1.17a1 1 0 00-1.41 0L6 4.71 2.46 1.17a1 1 0 10-1.41 1.42l4.24 4.24a1 1 0 001.42 0L11 2.59a1.001 1.001 0 000-1.42z"
        fill={fill || '#E35D32'}
      />
    </svg>
  );
};

export default ChevronDown;
