import {
    GetListGoogleFontsMeta,
    GetListGoogleFontsPayload,
} from 'types/store/GetListGoogleFontsState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LIST_GOOGLE_FONTS';

const asyncAction = createAsyncAction<
    GetListGoogleFontsMeta,
    GetListGoogleFontsPayload
>(ns);

const getListGoogleFontsActions = {
    ...asyncAction,
};

export default getListGoogleFontsActions;