import {
  GetStoryLinkMeta,
  GetStoryLinkPayload,
} from 'types/store/GetStoryLink';
import { createAsyncReducer } from 'utils/Redux';

import { getStoryLink } from '../actions';

export default createAsyncReducer<GetStoryLinkMeta, GetStoryLinkPayload>(
  getStoryLink,
);
