import {
  UpdateRevisionApprovalMeta,
  UpdateRevisionApprovalPayload,
} from 'types/store/UpdateRevisionApprovalState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_REVISION_APPROVAL';
const asyncAction = createAsyncAction<
  UpdateRevisionApprovalMeta,
  UpdateRevisionApprovalPayload
>(ns);

const updateRevisionApprovalActions = {
  ...asyncAction,
};

export default updateRevisionApprovalActions;
