import { Action, AsyncAction } from 'types/Action';
import { StoryEditorGenerate } from 'types/store/StoryEditorState';
import { createAsyncAction } from 'utils/Redux';

export const CLEAR_STORY_HUB_NAVIGATE_STEP = 'CLEAR_STORY_HUB_NAVIGATE_STEP';
export const CLEAR_STORY_HUB_LOADING_STATE = 'CLEAR_STORY_HUB_LOADING_STATE';
const generateAIContent = 'STORY_EDITOR_GENERATE_AI_CONTENT';
const generateActionRequest =
  createAsyncAction<StoryEditorGenerate>(generateAIContent);

const getEmailTemplate = 'GET_EMAIL_TEMPLATE';
const getEmailTemplateRequest =
  createAsyncAction<StoryEditorGenerate>(getEmailTemplate);

const advocateRepsonse = 'AVOCATE_RESPONSE';
const advocateRepsonseRequest =
  createAsyncAction<StoryEditorGenerate>(advocateRepsonse);

export enum ADD_KEY_POINT {
  ADD_KEY_POINT = '@@_ADD_KEY_POINT',
  CLEAR_KEY_POINT = '@@_CLEAR_KEY_POINT',
}

export enum GET_MORE_QUESTIONS_CUSTOM {
  GET_MORE_QUESTIONS_CUSTOM_REQUEST = '@@_GET_MORE_QUESTIONS_CUSTOM_REQUEST',
  GET_MORE_QUESTIONS_CUSTOM_SUCCESS = '@@_GET_MORE_QUESTIONS_CUSTOM_SUCCESS',
  GET_MORE_QUESTIONS_CUSTOM_CLEAR = '@@_GET_MORE_QUESTIONS_CUSTOM_CLEAR',
}

export enum ADVOCATE_PARAMS {
  SAVE_AVOCATE_REQUEST = '@@_SAVE_AVOCATE_REQUEST',
  SAVE_AVOCATE_SUCCESS = '@@_SAVE_AVOCATE_SUCCESS',
  SAVE_AVOCATE_TO_STORE = '@@_SAVE_AVOCATE_TO_STORE',

  AVOCATE_ANSWER = '@@_AVOCATE_ANSWER',
}

export enum CONFIRMATION_BOX_STORY_HUB_SCREEN {
  THREE_OPTIONS_SCREEN = '@@_THREE_OPTIONS_SCREEN',
  FINAL_ARTICLE_SCREEN = '@@_FINAL_ARTICLE_SCREEN',
}

export enum DOWNLOAD_CONTENT_AI {
  DOWNLOAD_CONTENT_AI_REQUEST = '@@_DOWNLOAD_CONTENT_AI_REQUEST',
  DOWNLOAD_CONTENT_AI_CLEAR = '@@_DOWNLOAD_CONTENT_AI_CLEAR',
}

export enum EMPLOYEE_PROFILE {
  EMPLOYEE_PROFILE_REQUEST = '@@_EMPLOYEE_PROFILE_REQUEST',
  EMPLOYEE_PROFILE_SUCCESS = '@@_EMPLOYEE_PROFILE_SUCCESS',
}

export enum CONTENT_ARITCLE_COMPANY {
  CONTENT_ARITCLE_COMPANY_REQUEST = '@@_CONTENT_ARITCLE_COMPANY_REQUEST',
  CONTENT_ARITCLE_COMPANY_SUCCESS = '@@_CONTENT_ARITCLE_COMPANY_SUCCESS',
}

export enum AI_GENERATE {
  AI_GENERATE_REQUEST = '@@_AI_GENERATE_REQUEST',
  AI_GENERATE_SUCCESS = '@@_AI_GENERATE_SUCCESS',
  AI_GENERATE_LIMIT = '@@_AI_GENERATE_LIMIT',
  AI_GENERATE_CHECK_LIMIT = '@@_AI_GENERATE_CHECK_LIMIT',
}

export enum HANDLE_STEP_2 {
  HANDLE_STEP_2_NEXT = '@@_HANDLE_STEP_2_NEXT',
  HANDLE_STEP_2_BACK = '@@_HANDLE_STEP_2_BACK',
}

export enum BETA_VERSION {
  GET_BETA_VERSION_REQUEST = '@@_GET_BETA_VERSION_REQUEST',
  GET_BETA_VERSION_SUCCESS = '@@_GET_BETA_VERSION_SUCCESS',
}

export enum SAVE_LENG_WORD {
  SAVE_LENG_WORD_BEGIN = '@@_SAVE_LENG_WORD_BEGIN',
}

export enum CREATE_ADD_CUSTOME_QUESTION {
  CREATE_ADD_CUSTOME_QUESTION_SUCCESS = '@@_CREATE_ADD_CUSTOME_QUESTION_SUCCESS',
}

export enum GET_READY_AI {
  GET_READY_AI_SUCCESS = '@@_GET_READY_AI_SUCCESS',
}

export enum NAVIGATE_STORY_BUILDER {
  NAVIGATE_STORY_BUILDER_REQUEST = '@@_NAVIGATE_STORY_BUILDER_REQUEST',
  NAVIGATE_STORY_BUILDER_SUCCESS = '@@_NAVIGATE_STORY_BUILDER_SUCCESS',
  NAVIGATE_STORY_BUILDER_CLEAR = '@@_NAVIGATE_STORY_BUILDER_CLEAR',
}

export enum CONTENT_RECIPES {
  CONTENT_RECIPES_REQUEST = '@@_CONTENT_RECIPES_REQUEST',
  CONTENT_RECIPES_SUCCESS = '@@_CONTENT_RECIPES_SUCCESS',
}

export enum SHARE_STORY_LINK {
  SHARE_STORY_LINK_REQUEST = '@@_SHARE_STORY_LINK_REQUEST',
  SHARE_STORY_LINK_SUCCESS = '@@_SHARE_STORY_LINK_SUCCESS',
  SHARE_STORY_LINK_CLEAR = '@@_SHARE_STORY_LINK_CLEAR',
}

export enum UPLOAD_LINK_MODAL {
  UPLOAD_LINK_MODAL_REQUEST = '@@_UPLOAD_LINK_MOAL_REQUEST',
  UPLOAD_LINK_MODAL_SUCCESS = '@@_UPLOAD_LINK_MOAL_SUCCESS',
  UPLOAD_LINK_MODAL_ERROR = '@@_UPLOAD_LINK_MOAL_ERROR',
  UPLOAD_LINK_MODAL_CLEAR = '@@_UPLOAD_LINK_MODAL_CLEAR',
  UPLOAD_LINK_MODAL_CANCEL = '@@_UPLOAD_LINK_MODAL_CANCEL',
}

export enum DOWNLOAD_STORY {
  DOWNLOAD_STORY_REQUEST = '@@_DOWNLOAD_STORY_REQUEST',
  DOWNLOAD_STORY_SUCCESS = '@@_DOWNLOAD_STORY_SUCCESS',
  DOWNLOAD_STORY_CLEAR = '@@_DOWNLOAD_STORY_CLEAR',
}

export enum TURN_OFF_MODAL_FAILED {
  REFRESH = 'TURN_OFF_MODAL_REFRESH',
  OFF = '@@_TURN_OFF_MODAL_FAILED',
}

export enum HANDLE_QUESTION_BACK_NEXT {
  LIMIT_AI_SUPPORT = '@@_LIMIT_AI_SUPPORT',
}

export enum SETTING_AW {
  SETTING_AW_REQUEST = '@@_SETTING_AW_REQUEST',
  SETTING_AW_SUCCESS = '@@_SETTING_AW_SUCCESS',
  SETTING_AW_ERROR = '@@_SETTING_AW_ERROR',
}

export enum DOWNLOAD_DOCX {
  DOWNLOAD_DOCX_REQUEST = '@@_DOWNLOAD_DOCX_REQUEST',
  DOWNLOAD_DOCX_SUCCESS = '@@_DOWNLOAD_DOCX_SUCCESS',
  DOWNLOAD_DOCX_ERROR = '@@_DOWNLOAD_DOCX_SUCCESS',
  DOWNLOAD_DOCX_DELETE = '@@_DOWNLOAD_DOCX_DELETE',
}

export enum EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT {
  EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_REQUEST = '@@_EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_REQUEST',
  EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_CLEAR = '@@_EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_CLEAR',
}

export const deleteDownloadDocx = (): Action => ({
  type: DOWNLOAD_DOCX.DOWNLOAD_DOCX_DELETE,
  payload: {},
});

export const checkLimitAISupport = (payload: boolean, limit: number): any => ({
  type: HANDLE_QUESTION_BACK_NEXT.LIMIT_AI_SUPPORT,
  payload: {
    limitSupport: payload,
    limitButton: limit,
  },
});

export const uploadLinkModal = (link: string, recipeId: string): any => ({
  type: UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_REQUEST,
  payload: { url: link, recipeId },
});

export const clearUploadLinkModalMessage = (): any => ({
  type: UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_CLEAR,
});

export const cancelUploadLink = (): any => ({
  type: UPLOAD_LINK_MODAL.UPLOAD_LINK_MODAL_CANCEL,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const nagivateToStoryBuilder = (): any => ({
  type: NAVIGATE_STORY_BUILDER.NAVIGATE_STORY_BUILDER_REQUEST,
});

export const clearNavigate = (): any => ({
  type: NAVIGATE_STORY_BUILDER.NAVIGATE_STORY_BUILDER_CLEAR,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getContentRecipes = (payload: any): any => ({
  type: CONTENT_RECIPES.CONTENT_RECIPES_REQUEST,
  payload,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getShareContentLink = (payload: any): any => ({
  type: SHARE_STORY_LINK.SHARE_STORY_LINK_REQUEST,
  payload,
});

export const clearShareContentLink = (): any => ({
  type: SHARE_STORY_LINK.SHARE_STORY_LINK_CLEAR,
});

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const saveAdvocate = (payload: any): any => ({
  type: ADVOCATE_PARAMS.SAVE_AVOCATE_REQUEST,
  payload,
});

export const checkDownloadContentAI = (): any => ({
  type: DOWNLOAD_CONTENT_AI.DOWNLOAD_CONTENT_AI_REQUEST,
});

export const checkDownloadContentAIClear = (): any => ({
  type: DOWNLOAD_CONTENT_AI.DOWNLOAD_CONTENT_AI_CLEAR,
});

export const downloadDocx = (payload: {
  htmlString: string;
  fileName: string;
  isDownload?: boolean;
}): Action => ({
  type: DOWNLOAD_DOCX.DOWNLOAD_DOCX_REQUEST,
  payload: {
    htmlString: payload.htmlString,
    fileName: payload.fileName,
    isDownload: payload.isDownload && payload.isDownload,
  },
});

const storyEditorActions = {
  ...generateActionRequest,
};
export const emailTemplateAction = {
  ...getEmailTemplateRequest,
};

export const advocateRepsonseAction = {
  ...advocateRepsonseRequest,
};

export const getEmployeeProifileByAuth = (): Action => ({
  type: EMPLOYEE_PROFILE.EMPLOYEE_PROFILE_REQUEST,
  payload: {},
});

export const updateHeadLineForZipFile = (payload: {
  headLine: string;
}): Action => ({
  type: 'UPDATE_HEADLINE',
  payload,
});

export const getContentArticleByCompany = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  payload: any,
  meta?: () => void,
): AsyncAction => ({
  type: CONTENT_ARITCLE_COMPANY.CONTENT_ARITCLE_COMPANY_REQUEST,
  payload,
  meta: meta && meta(),
  error: '',
});

export const getAISupportGenerateContent = (payload: {
  headline: string;
  answer: string;
  question: string;
}): Action => ({
  type: AI_GENERATE.AI_GENERATE_REQUEST,
  payload,
});

export const checkAISupportLimit = (payload: {
  idAdvocate: string;
  idQuestion: string;
}): Action => ({
  type: AI_GENERATE.AI_GENERATE_CHECK_LIMIT,
  payload,
});

export const resetGenerateStatus = (): Action => ({
  type: CONFIRMATION_BOX_STORY_HUB_SCREEN.THREE_OPTIONS_SCREEN,
  payload: {},
});

export const handleNext = (): Action => ({
  type: HANDLE_STEP_2.HANDLE_STEP_2_NEXT,
  payload: {},
});

export const handleBack = (): Action => ({
  type: HANDLE_STEP_2.HANDLE_STEP_2_BACK,
  payload: {},
});

export const betaVersion = (): Action => ({
  type: BETA_VERSION.GET_BETA_VERSION_REQUEST,
  payload: {},
});

export const getWordCound = (payload: {
  length: number;
  answer: string;
}): Action => ({
  type: SAVE_LENG_WORD.SAVE_LENG_WORD_BEGIN,
  payload,
});

export const saveKeyPointWorld = (payload: string): Action => ({
  type: ADD_KEY_POINT.ADD_KEY_POINT,
  payload,
});

export const clearKeyPoint = (): Action => ({
  type: ADD_KEY_POINT.CLEAR_KEY_POINT,
  payload: {},
});

export const getMoreCustomeQuestion = (payload: {
  headline: string;
  keyPoint: string;
}): Action => ({
  type: GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_REQUEST,
  payload,
});

export const clearCustomQuestion = (): Action => ({
  type: GET_MORE_QUESTIONS_CUSTOM.GET_MORE_QUESTIONS_CUSTOM_CLEAR,
  payload: '',
});

export const getReadyForAI = (): any => ({
  type: GET_READY_AI.GET_READY_AI_SUCCESS,
});

export const downloadAllStoryRequest = (payload: {
  story_id: string;
  advocate_ids: string;
  nameFile: string;
}): Action => ({
  type: DOWNLOAD_STORY.DOWNLOAD_STORY_REQUEST,
  payload,
});

export const clearStoryHubNavigateStep = (): Action => ({
  type: CLEAR_STORY_HUB_NAVIGATE_STEP,
  payload: {},
});

export const clearStateLoading = (): any => ({
  type: DOWNLOAD_STORY.DOWNLOAD_STORY_CLEAR,
});
export const clearStoryHubLoadingState = (): Action => ({
  type: CLEAR_STORY_HUB_LOADING_STATE,
  payload: {},
});

export const turnOffFailModal = (): Action => ({
  type: TURN_OFF_MODAL_FAILED.OFF,
  payload: {},
});
export const refreshCounting = (): Action => ({
  type: TURN_OFF_MODAL_FAILED.REFRESH,
  payload: {},
});

export const settingAW = (): Action => ({
  type: SETTING_AW.SETTING_AW_REQUEST,
  payload: {},
});

export const editQuestionStoryBuilder = (): Action => ({
  type: EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT.EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_REQUEST,
  payload: {},
});

export const clearEditQuestionStoryBuilder = (): Action => ({
  type: EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT.EDIT_QUESTION_STORY_BUILDER_REVIEW_SUBMIT_CLEAR,
  payload: {},
});

export default storyEditorActions;
