import { Reducer } from 'react';

import { analyticURLWebTrafficActions } from 'store/actions';
import { AsyncAction } from 'types/Action';
import { WebTrafficURLType } from 'types/store/GetWebTraffic';

const DEFAULT_STATE: WebTrafficURLType = {
  data: [],
  loading: false,
  utmSource: {},
  websiteUrl: {},
  shortLink: ''
}

const webTraficReducer: Reducer<WebTrafficURLType, AsyncAction> = (
  state = DEFAULT_STATE,
  { type, payload, meta },
) => {
  switch (type) {
    case analyticURLWebTrafficActions.REQUEST: {
      return {
        ...state,
        loading: true,
        utmSource: {},
        data: [],
        shortLink: ''
      }
    }
    case analyticURLWebTrafficActions.SUCCESS: {
      return {
        ...state,
        loading: false,
        data: payload.data,
        utmSource: payload.meta,
        websiteUrl: payload.payload,
        shortLink: payload.shortLink
      }
    }
    case analyticURLWebTrafficActions.CLEAR: {
      return {
        data: [],
        loading: false,
        utmSource: {},
        websiteUrl: {},
        shortLink: ''
      }
    }
    default: {
      return state;
    }
  }
}

export default webTraficReducer;
