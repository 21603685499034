import { Action, ActionCallback } from 'types/Action';

export type UpdateThumpnailMeta = {
  file: File[] | any;
  story_id?: string;
  library_id?: string | null;
  story_video_id?: string;
  callback: (value: string) => void;
  story_approval_version_file_id?: string;
};

export enum UPDATE_THUMPNAIL {
  UPDATE_THUMPNAIL_REQUEST = '@@_UPDATE_THUMPNAIL_REQUEST',
  UPDATE_THUMPNAIL_SUCCESS = '@@_UPDATE_THUMPNAIL_SUCCESS',
}

export const updateThumbnail = (
  payload: UpdateThumpnailMeta,
): ActionCallback => ({
  type: UPDATE_THUMPNAIL.UPDATE_THUMPNAIL_REQUEST,
  payload,
});
