import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStoriesLibraryByCompanyId } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload,
} from 'types/store/GetStoriesByCompanyIdState';
import { Store } from 'types/store/Store';

import { getStoriesLibraryByCompanyIdActions } from '../actions';

function* getStoriesLibraryByCompanyIdRequest(
  action: AsyncAction<GetStoriesByCompanyIdMeta, GetStoriesByCompanyIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getStoriesLibraryByCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(getStoriesLibraryByCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getStoriesLibraryByCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getStoriesLibraryByCompanyIdActions.failed('Token not found'));
  }
}

function* getStoriesLibraryByCompanyIdSuccess(
  action: AsyncAction<GetStoriesByCompanyIdMeta, GetStoriesByCompanyIdPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getStoriesLibraryByCompanyIdWatcher(): any {
  yield takeLatest(
    getStoriesLibraryByCompanyIdActions.REQUEST,
    getStoriesLibraryByCompanyIdRequest
  );
  yield takeLatest(
    getStoriesLibraryByCompanyIdActions.SUCCESS,
    getStoriesLibraryByCompanyIdSuccess
  );
}

export default getStoriesLibraryByCompanyIdWatcher;
