import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticWebTrafficMeta {
  time: string;
  communities: string[];
}

const webTrafficNS = 'ANALYTIC_ROI_WEB_TRAFFIC';

const getWebTrafficAsync = createAsyncAction<
  IAnalyticWebTrafficMeta,
  GenericResponse
>(webTrafficNS);

export default getWebTrafficAsync;
