import {
  DuplicatePageMeta,
  DuplicatePagePayload,
} from 'types/store/DuplicatePageState';
import { createAsyncReducer } from 'utils/Redux';

import { duplicatePageActions } from '../actions';

export default createAsyncReducer<DuplicatePageMeta, DuplicatePagePayload>(
  duplicatePageActions
);
