import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getShowcaseCompanyByUrl } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
  GetShowcaseCompanyByUrlMeta,
  GetShowcaseCompanyByUrlPayload,
} from 'types/store/GetShowcaseCompanyByUrlState';

import { getShowcaseCompanyByUrlActions } from '../actions';

function* getShowcaseCompanyByUrlRequest(
  action: AsyncAction<
    GetShowcaseCompanyByUrlMeta,
    GetShowcaseCompanyByUrlPayload
  >
) {
  try {
    // company id already embeded in the token
    const { data } = yield call(getShowcaseCompanyByUrl, action.meta);
    yield put(getShowcaseCompanyByUrlActions.success(data));
  } catch (e: any) {
    yield put(getShowcaseCompanyByUrlActions.failed(e.message));
  }
}

function* getShowcaseCompanyByUrlSuccess(
  action: AsyncAction<
    GetShowcaseCompanyByUrlMeta,
    GetShowcaseCompanyByUrlPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getShowcaseCompanyByUrlWatcher(): any {
  yield takeLatest(
    getShowcaseCompanyByUrlActions.REQUEST,
    getShowcaseCompanyByUrlRequest
  );
  yield takeLatest(
    getShowcaseCompanyByUrlActions.SUCCESS,
    getShowcaseCompanyByUrlSuccess
  );
}

export default getShowcaseCompanyByUrlWatcher;
