import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import { PageNameState } from 'types/store/PageNameState';

import { pageNameActions } from '../actions';

const DEFAULT_STATE: PageNameState = {
  page_name: null,
};

const DEFAULT_ACTION: Action<PageNameState> = {
  type: '',
  payload: {
    page_name: null,
  },
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  return pathOr(state, [action.type], {
    [pageNameActions.UPDATE]: {
      ...state,
      ...action.payload,
    },
    [pageNameActions.CLEAR]: DEFAULT_STATE,
  });
};
