import {
  GetEmployeeProfileByAuthMeta,
  GetEmployeeProfileByAuthPayload,
} from 'types/store/GetEmployeeProfileByAuth';
import { createAsyncReducer } from 'utils/Redux';

import { getEmployeeProfileByAuthActions } from '../actions';

export default createAsyncReducer<
  GetEmployeeProfileByAuthMeta,
  GetEmployeeProfileByAuthPayload
>(getEmployeeProfileByAuthActions);
