import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getBrandGuidelinesByCompanyId } from 'services/company';
import { AsyncAction } from 'types/Action';
import {
  GetBrandGuidelinesByCompanyIdMeta,
  GetBrandGuidelinesByCompanyIdPayload,
} from 'types/store/GetBrandGuidelinesByCompanyId';
import { Store } from 'types/store/Store';

import { getBrandGuidelinesByCompanyIdActions } from '../actions';

function* getBrandGuidelinesByCompanyIdRequest(
  action: AsyncAction<
    GetBrandGuidelinesByCompanyIdMeta,
    GetBrandGuidelinesByCompanyIdPayload
  >
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getBrandGuidelinesByCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(getBrandGuidelinesByCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getBrandGuidelinesByCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getBrandGuidelinesByCompanyIdActions.failed('Token not found'));
  }
}

function* getBrandGuidelinesByCompanyIdSuccess(
  action: AsyncAction<
    GetBrandGuidelinesByCompanyIdMeta,
    GetBrandGuidelinesByCompanyIdPayload
  >
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getBrandGuidelinesByCompanyIdWatcher(): any {
  yield takeLatest(
    getBrandGuidelinesByCompanyIdActions.REQUEST,
    getBrandGuidelinesByCompanyIdRequest
  );
  yield takeLatest(
    getBrandGuidelinesByCompanyIdActions.SUCCESS,
    getBrandGuidelinesByCompanyIdSuccess
  );
}

export default getBrandGuidelinesByCompanyIdWatcher;
