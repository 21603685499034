import {
   RemoveCardMeta,
   RemoveCardPayload
  } from 'types/store/RemoveCard';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { removeCardActions } from '../actions';
  
  export default createAsyncReducer<RemoveCardMeta, RemoveCardPayload>(
    removeCardActions
  );
  