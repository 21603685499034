import { AdvocateSendEmailMeta, AdvocateSendEmailPayload } from 'types/store/AdvocateSendEmail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'ADVOCATE_SEND_EMAIL_NS';

const asyncAction = createAsyncAction<AdvocateSendEmailMeta, AdvocateSendEmailPayload>(ns);

const advocateSendEmailActions = { ...asyncAction };

export default advocateSendEmailActions;
