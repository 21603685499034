import styled from 'utils/styled';

export const TranscribeButton = styled.div`
  margin: 4px 4px 0 4px;
`;

export const FloatingAlertContainer = styled.div<{
  open: boolean;
}>`
  opacity: 0;
  transition: 0.5s;

  ${({ open }) => open ? 'opacity: 1;' : 'opacity: 0;'}
`;

export const DisabledMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  cursor: not-allowed;
`;
