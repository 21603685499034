import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticAverageTimeMeta {
  time: string;
  communities: string[];
}

const averageTimeNS = 'ANALYTIC_AVERAGE_TIME';

const getAverageTimeAsync = createAsyncAction<
  IAnalyticAverageTimeMeta,
  GenericResponse
>(averageTimeNS);

export default getAverageTimeAsync;
