import { PreviewPagePayload } from 'types/store/PreviewPageState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'PREVIEW_PAGE';
const updateAction = createUpdateAction<PreviewPagePayload>(ns);
const clearAction = createClearAction(ns);

const currentAddressActions = {
  ...updateAction,
  ...clearAction,
};

export default currentAddressActions;
