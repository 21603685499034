import Typography from 'components/base/Typography';
import styled from 'utils/styled';

export const Container = styled.div`
  position: relative;
`;

export const Content = styled.div<{ isPreview?: boolean }>`
  max-width: 740px;
  height: 100%;
  max-height: ${({ isPreview }) =>
    isPreview ? 'calc(100vh - 255px)' : 'calc(100vh - 300px)'};
  margin: 0 auto;
  background: #fff;
  padding: 40px;
  overflow: auto;
  .ql-editor {
    height: auto !important;
  }

  blockquote {
    border-left: 4px solid #ccc !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-left: 16px !important;
    font-size: 13px;
    line-height: 1.42;
  }
`;

export const HTMLPlay = styled.div`
  /* font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.75px;
  color: #060548;
  margin-top: 24px; */
  color: #060548;
  p {
    line-height: 22px;
  }
`;

export const Paragraph = styled.p`
  /* font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.25px;
  color: #060548;
  margin: 0; */
`;

export const ImageWrapper = styled.div`
  /* border-radius: 8px;
  position: relative; */
  margin-top: 24px;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  width: fit-content;
  position: relative;
`;

export const ProgressBorder = styled.div`
  width: 70%;
  height: 4px;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.3);
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  position: absolute;
`;

export const Layer = styled.div`
  inset: 0;
  position: absolute;
  background: rgba(243, 241, 242, 0.5);
`;

export const Percent = styled.div`
  height: 4px;
  border-radius: 20px;
  background: #91dbb6;
  animation-name: progress;
  animation-duration: 4s;
  width: 75%;
  animation-timing-function: linear;

  @keyframes progress {
    0% {
      width: 0;
    }
    25% {
      width: 25%;
    }
    50% {
      width: 50%;
    }
    75% {
      width: 75%;
    }
  }
`;

export const PreviewContainer = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: #ffff;
  padding-top: 2%;
  padding-bottom: 2%;
`;

export const MenuIcon = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 6px;
  background-color: #e6e6ed;
  position: absolute;
  right: 10px;
  top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ActionOption = styled.div`
  cursor: pointer;
  font-weight: 600;
  color: '#060548';
  font-size: 14px;
  line-height: 32px;
`;

const Button = styled.button`
  cursor: pointer;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
  border-radius: 128px;
  padding: 12px;
  border: none;
  display: flex;
  align-items: center;
  svg {
    margin-left: 5px;
  }
`;

export const PreviewButton = styled(Button)`
  position: fixed;
  background-color: #070550;
  right: 20px;
  bottom: 20px;
`;

export const DowloadButton = styled(Button)`
  position: fixed;
  background-color: #167e60;
  right: 130px;
  bottom: 20px;
`;

export const ExitPreview = styled(Button)`
  background-color: rgba(0, 0, 0, 0.75);
  /* margin: 0 auto;
  margin-top: 20px; */
`;
