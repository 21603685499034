import { put, call, takeLatest, select } from 'redux-saga/effects';
import { sendMailWithTemplate } from 'services/email-template';
import { AsyncAction } from 'types/Action';
import {
  SendMailWithTemplateMeta,
  SendMailWithTemplatePayload,
} from 'types/store/SendMailWithTemplate';
import { Store } from 'types/store/Store';

import { sendMailWithTemplateActions } from '../actions';

function* sendMailWithTemplateRequest(
  action: AsyncAction<SendMailWithTemplateMeta, SendMailWithTemplatePayload>
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        sendMailWithTemplate,
        action.meta,
        state.auth.token
      );
      yield put(sendMailWithTemplateActions.success(data));
    } catch (e: any) {
      yield put(sendMailWithTemplateActions.failed(e.message));
    }
  }
}

// function* sendMailWithTemplateSuccess(action: AsyncAction<SendMailWithTemplateMeta, SendMailWithTemplatePayload>): any {
//   yield put()
// }

function* sendMailWithTemplateWatcher(): any {
  yield takeLatest(
    sendMailWithTemplateActions.REQUEST,
    sendMailWithTemplateRequest
  );
  // yield takeLatest(sendMailWithTemplateActions.SUCCESS, sendMailWithTemplateSuccess);
}

export default sendMailWithTemplateWatcher;
