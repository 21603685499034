import {
  UpdatePageNameMeta,
  UpdatePageNamePayload,
} from 'types/store/UpdatePageNameState';
import { createAsyncReducer } from 'utils/Redux';

import { updatePageNameActions } from '../actions';

export default createAsyncReducer<UpdatePageNameMeta, UpdatePageNamePayload>(
  updatePageNameActions
);
