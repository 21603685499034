import axios, { AxiosPromise } from 'axios';
import { API } from 'consts';
import { IRegisterGlobal } from 'store/actions/registerGlobalEvent';
import { FilterOption } from 'types/FilterDynamic';
import { GenericResponse } from 'types/GenericResponse';
import { ForgotPasswordMeta } from 'types/store/ForgotPasswordState';
import { GetSharingMeta } from 'types/store/GetSharing';
import { LoginMeta } from 'types/store/LoginState';
import { RegisterMeta } from 'types/store/RegisterState';
import { ResetPasswordMeta } from 'types/store/ResetPasswordState';
import { SwitchPlatformsMeta } from 'types/store/SwitchPlatforms';
import { TrackAutoLoginMeta } from 'types/store/TrackLoginMeta';
import { createBasicToken } from 'utils/Token';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const AUTH_URL = `${BASE_URL}/auth`;
const COMPANY_URL = `${BASE_URL}/company-settings`;

export const login = (data: LoginMeta): any => {
  return axios({
    method: 'post',
    url: `${AUTH_URL}/login`,
    headers: {
      Authorization: createBasicToken(data),
    },
    data: data,
  });
};

export const trackLogin = (data: TrackAutoLoginMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${AUTH_URL}/track-login`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const register = (data: RegisterMeta): any => {
  const fd = new FormData();

  if (data.profilePicture !== undefined) {
    fd.append('profile_picture', data.profilePicture);
  }
  fd.append('data', JSON.stringify(data));

  return axios({
    method: 'post',
    url: `${AUTH_URL}/signup`,
    headers: {
      Authorization: createBasicToken(data),
      'Content-Type': 'multipart/form-data',
    },
    data: fd,
  });
};

export const forgotPassword = (data: ForgotPasswordMeta): any => {
  return axios({
    method: 'post',
    url: `${AUTH_URL}/forgot-password`,
    data: data,
  });
};

export const resetPassword = (data: ResetPasswordMeta): any => {
  return axios({
    method: 'post',
    url: `${AUTH_URL}/reset-password`,
    data: data,
  });
};

export const getAllPlatform = (token: string): any => {
  return axios({
    method: 'get',
    url: `${AUTH_URL}/getAllPlatforms`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const switchPlatforms = (
  data: SwitchPlatformsMeta,
  token: string,
): any => {
  return axios({
    method: 'post',
    url: `${AUTH_URL}/switchPlatforms`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSharing = (data: GetSharingMeta, token: string): any => {
  return axios({
    method: 'get',
    url: `${AUTH_URL}/getSharing`,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const registerGlobalEvent = (
  data: IRegisterGlobal,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${BASE_URL}/user-events/signup`,
    data: { data: data },
  });
};

export const validateLink = (query: string): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${AUTH_URL}/check-forgot-token?${query}`,
  });
};

export const getCustomeField = (
  companyId: string,
): AxiosPromise<GenericResponse> => {
  return axios.get(
    `${BASE_URL}/custom-field/get-custom-field?companyId=${companyId}`,
  );
};

export const controlFieldRegister = (
  companyId: string,
): AxiosPromise<GenericResponse> => {
  return axios.get(`${COMPANY_URL}/signup-advocate?companyId=${companyId}`);
};

export const accessRouter = (
  platform: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'GET',
    url: `${AUTH_URL}/get-access-routes`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      role: platform,
    },
  });
};

export const getDynamicFieldFilter = (
  token: string,
): Promise<GenericResponse<FilterOption>> => {
  return axios({
    method: 'get',
    url: BASE_URL + '/custom-field/get-fields-for-filter',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then((res) => res.data);
};

export const getTCSettings = (params: {
  companyId?: string;
  reference?: string;
  token: string;
}): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'GET',
    url: `${COMPANY_URL}/tc-setting`,
    params: params,
  });
};

export const enableTCSettings = (
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'GET',
    url: `${COMPANY_URL}/enable-custom-written-tc`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
