import axios from 'axios';
import { API } from 'consts';
import { CheckUrlMeta } from 'types/store/CheckUrlState';
import { CreatePageMeta } from 'types/store/CreatePageState';
import { DeletePageMeta } from 'types/store/DeletePageState';
import { DuplicatePageMeta } from 'types/store/DuplicatePageState';
import { GeneratePageMeta } from 'types/store/GeneratePage';
import { GetPagesbyCompanyIdMeta } from 'types/store/GetPagesbyCompanyId';
import { GetPageMeta } from 'types/store/GetPageState';
import { UpdatePageNameMeta } from 'types/store/UpdatePageNameState';
import { UpdatePageMeta } from 'types/store/UpdatePageState';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const PAGE_URL = `${BASE_URL}/page`;
const COMPANY_URL = `${BASE_URL}/company`;

//company_id is already embeded in the token
export const getPagesbyCompanyId = (
  data: GetPagesbyCompanyIdMeta,
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${PAGE_URL}/getPagesbyCompanyId`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const createPageDeprecated = async (
  data: CreatePageMeta,
  token: string
): Promise<any> => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const fd = new FormData();

  if (data.values !== undefined) {
    for (let x = 0; x < data.values.length; x++) {
      if (data.values[x].image !== undefined) {
        fd.append('value_images', data.values[x].image);
      }
    }
  }

  if (data.photo_list !== undefined) {
    for (let x = 0; x < data.photo_list.length; x++) {
      if (data.photo_list[x] !== null) {
        fd.append('photo_list', data.photo_list[x]);
      }
    }
  }

  // fd.append('company_logo', data.company_logo);
  // fd.append('hero_image',data.hero_image);
  // fd.append('data',JSON.stringify(data));

  return axios.post(`${PAGE_URL}/create`, fd, config);
};

export const createPage = async (
  data: CreatePageMeta,
  token: string
): Promise<any> => {
  return axios({
    method: 'post',
    url: `${PAGE_URL}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const generatePage = (data: GeneratePageMeta): any => {
  return axios({
    method: 'get',
    url: `${COMPANY_URL}/${data.url}`,
  });
};

export const updatePage = async (
  data: UpdatePageMeta,
  token: string
): Promise<any> => {
  return axios({
    method: 'put',
    url: `${PAGE_URL}/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updatePageName = (
  data: UpdatePageNameMeta,
  token: string
): any => {
  return axios({
    method: 'put',
    url: `${PAGE_URL}/page-name`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const getPage = (data: GetPageMeta, token: string): any => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'get',
    url: `${PAGE_URL}/${data.page_id}`,
  });
};

export const deletePage = (data: DeletePageMeta, token: string): any => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: PAGE_URL,
    data: data,
  });
};

export const checkUrl = (data: CheckUrlMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${PAGE_URL}/check-url`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const duplicatePage = (data: DuplicatePageMeta, token: string): any => {
  return axios({
    method: 'post',
    url: `${PAGE_URL}/duplicate`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};
