import { Action } from 'types/Action';
import { StoryState } from 'types/store/Story';

import {
  getAssignedAdvocatesByStoryActions,
  updateAdvocatesForStoryActions,
} from '../actions';

const DEFAULT_STATE: StoryState = {
  detail: {
    id: null,
    assignedAdvocates: [],
    loading: null,
  },
  update: {
    status: null,
    loading: null,
  },
};

const DEFAULT_ACTION: Action<StoryState> = {
  type: '',
  payload: DEFAULT_STATE,
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  switch (action.type) {
    case getAssignedAdvocatesByStoryActions.REQUEST:
      return {
        ...state,
        detail: { ...state.detail, loading: true },
      };
    case getAssignedAdvocatesByStoryActions.SUCCESS:
      return {
        ...state,
        detail: { ...action.payload, loading: false },
      };
    case getAssignedAdvocatesByStoryActions.CLEAR:
      return {
        ...state,
        detail: DEFAULT_STATE.detail,
      };
    case updateAdvocatesForStoryActions.SUCCESS:
      return {
        ...state,
        update: {
          loading: false,
          status: action.payload,
        },
      };
    case updateAdvocatesForStoryActions.CLEAR:
      return {
        ...state,
        update: DEFAULT_STATE.update,
      };
    default:
      return state;
  }
};
