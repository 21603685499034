import React from 'react';

import { SVGProps } from './SVG.props';

const Blush = (props: SVGProps): JSX.Element => {
  const { width, height } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || '63'}
      height={height || '40'}
      viewBox="0 0 63 40"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M46.652 16.837c-3.836 0-6.185 2.032-6.441 2.265l2.447 2.718-.012.013c.061-.05 1.559-1.335 4.006-1.335s3.944 1.273 4.005 1.335l-.012-.012 2.447-2.719c-.255-.233-2.605-2.265-6.44-2.265zM35.402 30.147c-.061.049-1.559 1.335-4.006 1.335s-3.945-1.274-4.006-1.335l.012.012-2.447 2.719c.256.232 2.606 2.265 6.441 2.265s6.185-2.033 6.44-2.265l-2.446-2.719.012-.012zM16.262 16.837c-3.835 0-6.185 2.032-6.44 2.265l2.447 2.718-.012.013c.06-.05 1.558-1.335 4.005-1.335s3.945 1.273 4.006 1.335l-.012-.012 2.447-2.719c-.256-.233-2.606-2.265-6.44-2.265z"
        fill="#91DBB6"
      />
      <path
        d="M29.241 17.755c0-7.445-5.99-13.47-13.393-13.47-7.402 0-13.393 6.025-13.393 13.47s5.99 13.47 13.393 13.47c7.403 0 13.393-6.025 13.393-13.47zm-13.393 9.796c-5.37 0-9.74-4.396-9.74-9.796 0-5.4 4.37-9.796 9.74-9.796s9.74 4.396 9.74 9.796c0 5.4-4.37 9.796-9.74 9.796zM60.045 17.755c0-7.445-5.99-13.47-13.393-13.47-7.403 0-13.393 6.025-13.393 13.47s5.99 13.47 13.393 13.47a13.08 13.08 0 004.663-.845c-2.155 2.192-4.858 3.82-7.792 4.653l.986 3.538c4.383-1.236 8.34-3.942 11.128-7.591 2.886-3.772 4.408-8.29 4.408-13.053v-.172zm-23.134 0c0-5.4 4.371-9.796 9.74-9.796 5.37 0 9.741 4.396 9.741 9.796 0 5.4-4.37 9.796-9.74 9.796s-9.74-4.396-9.74-9.796z"
        fill="#91DBB6"
      />
    </svg>
  );
};

export default Blush;
