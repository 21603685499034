import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getPage } from 'services/page';
import { AsyncAction } from 'types/Action';
import { GetPageMeta, GetPagePayload } from 'types/store/GetPageState';
import { Store } from 'types/store/Store';

import { getPageActions } from '../actions';

function* getPageRequest(action: AsyncAction<GetPageMeta, GetPagePayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getPage, action.meta, state.auth.token);
      yield put(getPageActions.success(data));
      // yield put(getPagesbyCompanyIdActions.success(data));
    } catch (e: any) {
      // yield put(getPagesbyCompanyIdActions.failed(e.message));
    }
  } else {
    // yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  }
}

//   function* forgotPasswordSuccess(action: AsyncAction<ForgotPasswordMeta, ForgotPasswordPayload>) {
//     yield put(push(EMPLOYER_ROUTES.LOGIN));
//   }

function* getPageWatcher(): any {
  yield takeLatest(getPageActions.REQUEST, getPageRequest);
  // yield takeLatest(forgotPasswordActions.SUCCESS, forgotPasswordSuccess);
}

export default getPageWatcher;
