import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateVersionDetail1 } from 'services/story-editor';
import { AsyncAction } from 'types/Action';
import { ApproversApproveMeta, ApproversApprovePayload } from 'types/store/ApproversApprove';
import { Store } from 'types/store/Store';

import { approversApproveActions } from '../actions';

function* approversApproveRequest(action: AsyncAction<ApproversApproveMeta, ApproversApprovePayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(updateVersionDetail1, action.meta, state.auth.token);
      yield put(approversApproveActions.success(data));
    } catch (e: any) {
      yield put(approversApproveActions.failed(e.message));
    }
  }
}

// function* approversApproveSuccess(action: AsyncAction<ApproversApproveMeta, ApproversApprovePayload>): any {
//   yield put()
// }

function* approversApproveWatcher(): any {
  yield takeLatest(approversApproveActions.REQUEST, approversApproveRequest);
  // yield takeLatest(approversApproveActions.SUCCESS, approversApproveSuccess);
}

export default approversApproveWatcher;
