import {
  GetDataRecipeExampleDetailMeta,
  GetDataRecipeExampleDetailPayload,
} from 'types/store/GetDataRecipeExampleDetail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_DATA_RECIPE_EXAMPLE_DETAIL_NS';

const asyncAction = createAsyncAction<
  GetDataRecipeExampleDetailMeta,
  GetDataRecipeExampleDetailPayload
>(ns);

const getDataRecipeExampleDetailActions = { ...asyncAction };

export default getDataRecipeExampleDetailActions;
