import {
    UpdateArticlePositionStoryPageMeta,
    UpdateArticlePositionStoryPagePayload
} from 'types/store/UpdateArticlePositionStoryPage';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_ARTICLE_POSITION_STORY_PAGE';

const asyncAction = createAsyncAction<
    UpdateArticlePositionStoryPageMeta,
    UpdateArticlePositionStoryPagePayload
>(ns);

const updateArticlePositionStoryPageActions = {
    ...asyncAction,
};

export default updateArticlePositionStoryPageActions;
