import { MarkStoryAsReadyToPublishMeta, MarkStoryAsReadyToPublishPayload } from 'types/store/MarkStoryAsReadyToPublish';
import { createAsyncAction } from 'utils/Redux';

const ns = 'MARK_STORY_AS_READY_TO_PUBLISH_NS';

const asyncAction = createAsyncAction<MarkStoryAsReadyToPublishMeta, MarkStoryAsReadyToPublishPayload>(ns);

const markStoryAsReadyToPublishActions = { ...asyncAction };

export default markStoryAsReadyToPublishActions;
