import {
  GetAssetStateMeta,
  GetAssetStatePayload
} from 'types/store/getAssetState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ASSET_HEADER';
const asyncAction = createAsyncAction<
  GetAssetStateMeta,
  GetAssetStatePayload
>(ns);

const getAssetActions = {
  ...asyncAction,
};

export default getAssetActions;
