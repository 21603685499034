import {
  TranscribeAudioMeta,
  TranscribeAudioPayload,
} from 'types/store/TranscribeAudio';
import { createAsyncAction } from 'utils/Redux';

const ns = 'TRANSCRIBE_AUDIO';

const asyncAction = createAsyncAction<
  TranscribeAudioMeta,
  TranscribeAudioPayload
>(ns);

const transcribeAudioActions = {
  ...asyncAction,
};

export default transcribeAudioActions;
