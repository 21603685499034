import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IRecipeStoryMeta {
  recipeId: string;
  contentPackageId: string;
}

const recipeStoryNS = 'CONTENT_PLANNER_RECIPE_STORY';

const getRecipeStoryAsync = createAsyncAction<
  IRecipeStoryMeta,
  GenericResponse
>(recipeStoryNS);

export default getRecipeStoryAsync;
