import {
    GetShareSocialStoryStateMeta,
    GetShareSocialStoryStatePayload,
} from 'types/store/getShareSocialStoryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHARE_SOCIAL_STORY';
const asyncAction = createAsyncAction<
    GetShareSocialStoryStateMeta,
    GetShareSocialStoryStatePayload
>(ns);

const getShareSocialStoryActions = {
    ...asyncAction,
};

export default getShareSocialStoryActions;
