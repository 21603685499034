import { GenericResponse } from 'types/GenericResponse';
import { widgetFilterParam } from 'types/store/Widget';
import { createAsyncAction } from 'utils/Redux';

const ns = 'LIST_WIDGET';
const ahihi = 'ahii';

const asyncAction = createAsyncAction<widgetFilterParam, GenericResponse>(ns);
const asyncAction1 = createAsyncAction<GenericResponse>(ahihi);

const widgetAction = {
  list_widget: { ...asyncAction },
  ...asyncAction1,
};

export default widgetAction;
