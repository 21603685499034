import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticContentCostMeta {
  time: string;
  communities: string[];
}

const contentCostNS = 'ANALYTIC_ROI_CONTENT_COST';

const getContentCostAsync = createAsyncAction<
  IAnalyticContentCostMeta,
  GenericResponse
>(contentCostNS);

export default getContentCostAsync;
