import Axios from 'axios';
import { API } from 'consts';
import flagsmith from 'flagsmith';
const BASE_URL = `${API.URL}/${API.VERSION}`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
async function addUserProperties(authObj: any): Promise<any> {
    const getUserById = await Axios.get(`${BASE_URL}/advocate/getUserById`, {
        headers: { Authorization: `Bearer ${authObj.token}` }
    });
    const profile = getUserById?.data?.data?.profile;
    const session = authObj?.platform === 'EMPLOYER'
        ? 'EB'
        : (authObj?.platform === 'RECRUITER'
            ? 'RE'
            : 'BA');

    // Heap needs them to be UPPER case for a funny reason :)
    const userInfo = {
        Email: profile?.email?.toLowerCase(),
        Name: `${profile?.firstName} ${profile?.lastName}`,
        Session: session,
        Company: profile?.company_name,
        Id: profile?.user_id?.split('-')[0]
    }

    /*
    if ((window as any).heap) {
        (window as any).heap.identify(`${userInfo.Name} - ${userInfo.Company} - ${session}`);
        (window as any).heap?.addUserProperties(userInfo);
    }
    */

    // Below code snippet from Pendo

    // This function creates anonymous visitor IDs in Pendo unless you change the visitor id field to use your app's values
    // This function uses the placeholder 'ACCOUNT-UNIQUE-ID' value for account ID unless you change the account id field to use your app's values
    // Call this function in your authentication promise handler or callback when your visitor and account id values are available
    // Please use Strings, Numbers, or Bools for value types.
    if ((window as any).pendo) {
        (window as any).pendo.initialize({
            visitor: {
                id: userInfo.Email, // 'VISITOR-UNIQUE-ID', // Required if user is logged in, default creates anonymous ID
                email: userInfo.Email,      // Recommended if using Pendo Feedback, or NPS Email
                full_name: userInfo.Name,   // Recommended if using Pendo Feedback
                role: userInfo.Session
                // You can add any additional visitor level key-values here,
                // as long as it's not one of the above reserved names.
            },

            account: {
                id: userInfo.Company, // 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
                // name: userInfo.Company  // Optional
                // Enable later for payment / subscription
                // is_paying:    // Recommended if using Pendo Feedback
                // monthly_value:// Recommended if using Pendo Feedback
                // planLevel:    // Optional
                // planPrice:    // Optional
                // creationDate: // Optional

                // You can add any additional account level key-values here,
                // as long as it's not one of the above reserved names.
            }
        });
    }

    if ((window as any).analytics) {
        (window as any).analytics.identify(userInfo.Id, {
            name: userInfo.Name,
            email: userInfo.Email,
            session: userInfo.Session,
            company: userInfo.Company,
        });
    }

    if ((window as any).FS) {
        (window as any).FS.identify(userInfo.Id, {
            displayName: userInfo.Name,
            email: userInfo.Email,
            // Add your own custom user variables here, details at
            // https://help.fullstory.com/hc/en-us/articles/360020623294-FS-setUserVars-API-Capturing-custom-user-properties
            // reviewsWritten_int: 14
            account: userInfo.Company,
            session: userInfo.Session
        });
    }

    flagsmith.identify('themartec_email');
    flagsmith.setTrait('email', profile?.email || "");
    flagsmith.setTrait('company', profile?.company_name || "");

    return;
}

export default addUserProperties;
