import {
    GetDataShowcasePublicHomepageMeta,
    GetDataShowcasePublicHomepagePayload,
} from 'types/store/GetDataShowcasePublicHomepageState';
import { createAsyncReducer } from 'utils/Redux';

import { getDataShowcasePublicHomepageActions } from '../actions';

export default createAsyncReducer<
    GetDataShowcasePublicHomepageMeta,
    GetDataShowcasePublicHomepagePayload
>(getDataShowcasePublicHomepageActions);
