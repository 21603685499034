import { UpdateVersionDetailMeta, UpdateVersionDetailPayload } from 'types/store/UpdateVersionDetail';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_VERSION_DETAIL';

const asyncAction = createAsyncAction<UpdateVersionDetailMeta, UpdateVersionDetailPayload>(ns);

const updateVersionDetailActions = { ...asyncAction };

export default updateVersionDetailActions;
