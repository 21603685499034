import { Action } from 'types/Action';
import { ITranslate } from 'types/store/Setting';

export enum LANGUAGE {
  GET_LANGUAGE_ACTION = '@@_GET_LANGUAGE_ACTION',
  GET_LANGUAGE_SUCCESS = '@@_GET_LANGUAGE_SUCCESS',
  LANGUAGE_CHOOSING = '@@_LANGUAGE_CHOOSING',
  CHANGE_ACTIVE_LANGUAGE_TRANSLATE = '@@_CHANGE_ACTIVE_LANGUAGE_TRANSLATE'
}

export enum TRANSLATE {
  TRANSLATE_LANGUAGE_REQUEST = '@@_TRANSLATE_LANGUAGE_REQUEST',
}

export const listLanguage = (
): Action => ({
  type: LANGUAGE.GET_LANGUAGE_ACTION,
  payload: {},
});

export const chooseLanguage = (payload: string): Action => ({
  type: LANGUAGE.LANGUAGE_CHOOSING,
  payload
})

export const activeModeTranslate = (payload: number): Action => ({
  type: LANGUAGE.CHANGE_ACTIVE_LANGUAGE_TRANSLATE,
  payload
})

export const translateLanguage = (payload: ITranslate): Action => ({
  type: TRANSLATE.TRANSLATE_LANGUAGE_REQUEST,
  payload
})

const languageActions = {
  listLanguage,
  chooseLanguage,
  activeModeTranslate,
  translateLanguage
};
export default languageActions;
