import axios from 'axios';
import { API } from 'consts';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const PHOTO_URL = `${BASE_URL}/photo`;

export const uploadPhoto = (file: File, token: string): any => {
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };

  const fd = new FormData();
  fd.append('photo', file);
  return axios.post(`${PHOTO_URL}/create`, fd, config);
};

export const deletePhoto = (key: string, token: string): any => {
  return axios({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'delete',
    url: `${PHOTO_URL}/delete`,
    data: {
      key: key,
    },
  });
};
