import {
    DeleteLibraryMeta,
    DeleteLibraryPayload,
  } from 'types/store/DeleteLibraryState';
  import { createAsyncReducer } from 'utils/Redux';

  import { deleteLibraryActions } from '../actions';

  export default createAsyncReducer<DeleteLibraryMeta, DeleteLibraryPayload>(
    deleteLibraryActions
  );