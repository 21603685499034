import { GetSharingMeta, GetSharingPayload } from 'types/store/GetSharing';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHARING';

const asyncAction = createAsyncAction<GetSharingMeta, GetSharingPayload>(ns);

const getSharingActions = { ...asyncAction };

export default getSharingActions;
