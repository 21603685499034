import { all, delay, put, select, takeLatest } from 'redux-saga/effects';
import { authOktaLogout, authWithOkta } from 'services/okta';
import {
  SignInOKtaMeta,
  SIGNIN_OKTA,
  SIGNOUT_OKTA,
} from 'store/actions/okta.action';
import { Action } from 'types/Action';
import { push } from 'connected-react-router';
import { AxiosError } from 'axios';
import { authActions, openToast, TOAST } from 'store/actions';
import { EMPLOYER_ROUTES } from 'consts';
import { Store } from 'types/store/Store';
import { call } from 'ramda';

function* watchLoginRequest(action: Action<SignInOKtaMeta>) {
  try {
    const { data } = yield authWithOkta(action.payload);
    if (data.data.entryPoint) {
      window.location.href = data.data.entryPoint;
    } else {
      yield put(openToast(data.message, 'warning'));
    }
  } catch (error: any) {
    const errorMessage = error as AxiosError;
    if (errorMessage.response?.data.status === 400) {
      const message = errorMessage.response?.data?.message;
      yield put(openToast(message, 'error'));
    }
  }
}

function* watchRemoveStore(deepLink?: string) {
  yield put(authActions.clear());
  if (deepLink) {
    yield delay(5000);
  }
  yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* watchOktaLogoutRequest(
  action: Action<{ companyId: string; action: 'SSO' | ''; deepLink?: string }>,
) {
  const state: Store = yield select();
  try {
    // if (state.auth.token) {
    if (action.payload?.deepLink) {
      yield put(
        push(
          `${EMPLOYER_ROUTES.OKATLOGOUT}?companyId=${action.payload.companyId}&isMobile=true&logoutMobile=true`,
        ),
      );
    }
    yield all([
      call(authOktaLogout, action.payload, state.auth.token),
      watchRemoveStore(action.payload?.deepLink),
    ]);
    //
    // }
  } catch (error: any) {
    console.log('errrrr', error);
    yield put(push(EMPLOYER_ROUTES.LOGIN));
  }
}

function* watchOktaRequest(): any {
  yield takeLatest(SIGNIN_OKTA.SIGNIN_OKTA_REQUEST, watchLoginRequest);
  yield takeLatest(SIGNOUT_OKTA.SIGNOUT_OKTA_REQUEST, watchOktaLogoutRequest);
}

export default watchOktaRequest;
