import {
  UpdateStoryByIdMeta,
  UpdateStoryByIdPayload,
} from 'types/store/UpdateStoryById';
import { createAsyncReducer } from 'utils/Redux';

import { updateStoryByIdActions } from '../actions';

export default createAsyncReducer<UpdateStoryByIdMeta, UpdateStoryByIdPayload>(
  updateStoryByIdActions
);
