import { StoryType } from 'components/module/StoryListings/StoryListings.props';
import { GenericResponse } from 'types/GenericResponse';
import {
  FinalPieceStatus as FinalPieceStatusType,
  StoryStatus,
  StorySubType,
} from 'types/Story';

import { ApprovalStatus } from './GetReviewedStoriesState';
import { QuestionsSummary } from './GetSuggestedQuestions';
import { TopicsSummary } from './GetTopics';
import { Feedback } from './WriteStoryState';

// eslint-disable-next-line @typescript-eslint/ban-types
export type GetSaveTemplatesCompanyIdMeta = {};
export type GetSaveTemplatesCompanyIdPayload = GenericResponse<{
  saveTemplatesList: SaveStoriesSummary[];
}>;

export type Status =
  | StoryStatus.IDEATION
  | StoryStatus.DRAFT
  | StoryStatus.ASSIGNED
  | StoryStatus.ACCEPTED
  | StoryStatus.IN_PROGRESS
  | StoryStatus.REVIEW
  | StoryStatus.TO_PUBLISH
  | StoryStatus.PUBLISHED
  | StoryStatus.READY_TO_PUBLISH
  | StoryStatus.DELETED
  | StoryStatus.ARCHIVED;

export type SubType =
  | StorySubType.WRITTEN
  | StorySubType.VIDEO
  | StorySubType.ADVERT;

export enum VIDEO_STATUS {
  PENDING = 'PENDING',
  SENT_BACK = 'SENT_BACK',
  APPROVED = 'APPROVED',
}

export type VideoStatus = 'PENDING' | 'SENT_BACK' | 'APPROVED';

export type Category = {
  name: string;
};

export type StoryRevision = {
  story_final_piece_id: string;
  eb_comment: string;
  translated_eb_comment?: string;
  ad_comment: string;
  final_piece_path: string;
  link_path: string;
};

export type FinalPieceStatus =
  | FinalPieceStatusType.pending
  | FinalPieceStatusType.skipped
  | FinalPieceStatusType.working
  | FinalPieceStatusType.declined
  | FinalPieceStatusType.approved;

export type StoryFinalPiece = {
  id: string;
  status?: FinalPieceStatus;
  story_revisions: StoryRevision[];
};

export type UserStoryStatus = {
  id: string;
  user_id: string;
  story_id: string;
  status: Status;
};

export type SaveStoriesSummary = {
  id: string;
  title: string;
  company_id: string;
  category_id: string;
  audience_research_id: string;
  status: StoryStatus;
  final_story: string;
  employee_name: string;
  category_name: string;
  category_price: number;
  audience_research: TopicsSummary;
  story_questions: QuestionsList[];
  story_discussion_comments: { id: string; comment: string }[];
  feedbacks: Feedback[];
  sub_type: SubType;
  paid: boolean;
  approvers?: ApprovalStatus[];
  story_final_pieces?: StoryFinalPiece[];
  user_story_statuses: UserStoryStatuses[];
  type: StoryType;
  is_custom_topic: boolean;
  marketplace_id?: string | null;
  notes?: string;
};

export type QuestionsList = {
  id: string;
  story_id: string;
  question_id: string;
  sort_order: number;
  question: QuestionsSummary;
  user_story_question_answers: UserStoryQuestionAnswer[];
  user_story_question_answer: UserStoryQuestionAnswer; // Retain method use for displaying answers in Advocate Side
  complete?: boolean;
};

export type UserStoryQuestionAnswer = {
  id: string;
  user_id: string;
  story_question_id: string;
  answer?: string;
  actual_answer?: string;
  answer_en_version?: string | null;
  original_answer_lang?: string | null;
  user?: User;
  translated_answer?: string;
  story_video: StoryVideo;
};

export type User = {
  id: string;
  first_name: string;
  last_name: string;
  profile_picture: string;
  advocate: AdvocateData;
  email: string;
};

export type AdvocateData = {
  id: string;
  department: string;
  role: string;
  is_stakeholder: boolean;
};

export type StoryVideo = {
  answer_id: string;
  comment?: string;
  id: string;
  status: VideoStatus;
  video_link: string;
  revised_video: string;
};

export type UserStoryStatuses = {
  user_id: string;
  status: Status;
  comment: string;
  comment_en_version: string;
  user: {
    first_name: string;
    last_name: string;
    profile_picture: string;
    email: string;
    role: string;
  };
};
