import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  createWorkspaceTag,
  getWorkspaceTag,
  getWorkspaces,
  updateWorkspace,
} from 'services/workspaces';
import { getWorkspacesAction, openToast } from 'store/actions';
import { UPDATE_WORKSPACE } from 'store/actions/settings/updateWorkspaces';
import {
  CREATE_WORKSPACES_TAG,
  GET_WORKSPACES_TAG,
} from 'store/actions/settings/workspacesReferences';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';

function* getWorkSpacesRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getWorkspaces, action.meta, state.auth.token);
      yield put(getWorkspacesAction.success(data.data));
    } catch (e: any) {
      console.log('e', e);
      yield put(getWorkspacesAction.failed(e.message));
    }
  }
}

function* updateWorkspacesRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const updateData = { ...action.payload };
      delete updateData.company_id;
      const { data } = yield call(
        updateWorkspace,
        updateData,
        action.payload.company_id,
        state.auth.token,
      );
      yield put(openToast(data.message, 'success'));
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* getWorkSpacesTagRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // const { data } = yield call(getWorkspaceTag, state.auth.token);
      // yield put({
      //   type: GET_WORKSPACES_TAG.SUCCESS,
      //   payload: data.data.formattedTags,
      // });
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* createTagsRequest(action: AsyncAction) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        createWorkspaceTag,
        action.payload,
        state.auth.token,
      );

      toast.success(data.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
      });
      
      // yield put(openToast(data.message, 'success'));
    } catch (e: unknown) {
      const errorMessage = e as AxiosError;
      yield put(openToast(errorMessage?.response?.data.message, 'error'));
    }
  }
}

function* workspacesWatcher(): any {
  yield takeLatest(getWorkspacesAction.REQUEST, getWorkSpacesRequest);
  yield takeLatest(UPDATE_WORKSPACE.REQUEST, updateWorkspacesRequest);
  yield takeLatest(GET_WORKSPACES_TAG.REQUEST, getWorkSpacesTagRequest);
  yield takeLatest(CREATE_WORKSPACES_TAG.REQUEST, createTagsRequest);
}

export default workspacesWatcher;
