import {
GetSavedCardsMeta,
GetSavedCardsPayload
} from 'types/store/GetSavedCards';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SAVED_CARDS';
const asyncAction = createAsyncAction<GetSavedCardsMeta, GetSavedCardsPayload>(
  ns
);

const getSavedCardsActions = {
  ...asyncAction,
};

export default getSavedCardsActions;
