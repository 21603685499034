import { GetInvitedTeamsMeta, GetInvitedTeamsPayload } from 'types/store/GetInvitedTeams';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_INVITED_TEAMS_NS';

const asyncAction = createAsyncAction<GetInvitedTeamsMeta, GetInvitedTeamsPayload>(ns);

const getInvitedTeamsActions = { ...asyncAction };

export default getInvitedTeamsActions;
