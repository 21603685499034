import { GetAllContentPackageWithDiscussionStatusMeta, GetAllContentPackageWithDiscussionStatusPayload } from 'types/store/GetAllContentPackageWithDiscussionStatus';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ALL_CONTENT_PACKAGE_WITH_DISCUSSION_STATUS_NS';

const asyncAction = createAsyncAction<GetAllContentPackageWithDiscussionStatusMeta, GetAllContentPackageWithDiscussionStatusPayload>(ns);

const getAllContentPackageWithDiscussionStatusActions = { ...asyncAction };

export default getAllContentPackageWithDiscussionStatusActions;
