import React from 'react';

import { Transcribe } from 'components/base/SVG';

import { TranscribeProps } from './Transcribe.props';
import { Action, ActionItem, TextActionItem } from '../../VideoPreview.style';
import { TranscribeButton } from '../DownloadVideo.style';

const TranscribeView = (props: TranscribeProps) => {
  const {
    transcribe,
    noneTranscribe,
    wasTranscribed,
    onTranscribe,
    showPopupAction,
  } = props;

  return (
    <>
      {transcribe || noneTranscribe || wasTranscribed ? null : (
        <ActionItem onClick={showPopupAction}>
          <div
            title={'Transcribe'}
            onClick={() => {
              onTranscribe();
            }}
          >
            <Action>
              <TranscribeButton>
                <Transcribe width={16} height={16} />
              </TranscribeButton>
              <TextActionItem>Transcribe</TextActionItem>
            </Action>
          </div>
        </ActionItem>
      )}
    </>
  );
};

export default React.memo(TranscribeView);
