import {
  DownloadResponsesMeta,
  DownloadResponsesPayload,
} from 'types/store/DownloadResponsesState';
import { createAsyncReducer } from 'utils/Redux';

import { downloadResponsesActions } from '../actions';

export default createAsyncReducer<
  DownloadResponsesMeta,
  DownloadResponsesPayload
>(downloadResponsesActions);
