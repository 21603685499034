/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const VideoRecord = (props: SVGProps): JSX.Element => {
  const { width, height, fill, rotate, style } = props;
  return (
    <svg
      width={width || 10}
      height={height || 10}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.5C0 1.80964 0.559644 1.25 1.25 1.25H6.25C6.94036 1.25 7.5 1.80964 7.5 2.5V3.17191L9.45157 2.52138C9.57863 2.47903 9.71831 2.50034 9.82696 2.57865C9.93562 2.65696 10 2.78273 10 2.91667V7.29167C10 7.43098 9.93037 7.56108 9.81446 7.63835C9.69854 7.71563 9.55167 7.72986 9.42308 7.67628L7.5 6.875V7.5C7.5 8.19036 6.94036 8.75 6.25 8.75H1.25C0.559644 8.75 0 8.19036 0 7.5V2.5Z"
        fill={fill || '#060548'}
      />
    </svg>
  );
};

export default VideoRecord;
