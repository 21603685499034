import { put, call, takeLatest, select } from 'redux-saga/effects';
import { assignAdvocatesToStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { AssignAdvocatesToStoryMeta, AssignAdvocatesToStoryPayload } from 'types/store/AssignAdvocatesToStory';
import { Store } from 'types/store/Store';

import { assignAdvocatesToStoryActions } from '../actions';

function* assignAdvocatesToStoryRequest(action: AsyncAction<AssignAdvocatesToStoryMeta, AssignAdvocatesToStoryPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(assignAdvocatesToStory, action.meta, state.auth.token);
      yield put(assignAdvocatesToStoryActions.success(data));
    } catch (e: any) {
      yield put(assignAdvocatesToStoryActions.failed(e.message));
    }
  }
}

function* assignAdvocatesToStorySuccess(action: AsyncAction<AssignAdvocatesToStoryMeta, AssignAdvocatesToStoryPayload>) {
  // yield put()
}

function* assignAdvocatesToStoryWatcher(): any {
  yield takeLatest(assignAdvocatesToStoryActions.REQUEST, assignAdvocatesToStoryRequest);
  yield takeLatest(assignAdvocatesToStoryActions.SUCCESS, assignAdvocatesToStorySuccess);
}

export default assignAdvocatesToStoryWatcher;
