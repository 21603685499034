import {
  CreateCustomQuestionMeta,
  CreateCustomQuestionPayload,
} from 'types/store/CreateCustomQuestionState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_CUSTOM_QUESTION';

const asyncAction = createAsyncAction<
  CreateCustomQuestionMeta,
  CreateCustomQuestionPayload
>(ns);

const createCustomQuestionActions = {
  ...asyncAction,
};

export default createCustomQuestionActions;
