import {
  SendEmployerBrandingInvitationMeta,
  SendEmployerBrandingInvitationPayload,
} from 'types/store/SendEmployerBrandingInvitation';
import { createAsyncReducer } from 'utils/Redux';

import { sendEmployerBrandingInvitationActions } from '../actions';

export default createAsyncReducer<
  SendEmployerBrandingInvitationMeta,
  SendEmployerBrandingInvitationPayload
>(sendEmployerBrandingInvitationActions);
