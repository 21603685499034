import React from 'react';

import Spin from 'components/base/SVG/Spin';

import { SpinnerProps } from './Spinner.props';
import { Container } from './Spinner.style';

const Spinner = (props: SpinnerProps): JSX.Element => {
  return (
    <Container style={props?.styleWrapper}>
      <Spin {...props} style={props?.style} />
    </Container>
  );
};

export default React.memo(Spinner);
