import {
    UpsertShowcasePageMeta,
    UpsertShowcasePagePayload
} from 'types/store/UpsertShowcasePageState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPSERT_SHOWCASE_PAGE';

const asyncAction = createAsyncAction<UpsertShowcasePageMeta, UpsertShowcasePagePayload>(ns);

const upsertShowcasePageActions = {
    ...asyncAction
};

export default upsertShowcasePageActions;