import React from 'react';

import { SVGProps } from './SVG.props';

const AddUnfilledCircle = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill={fill || 'none'}
      {...props}
    >
      <rect width={width || 24} height={height || 24} rx="8" fill={fill || "#F1B422"}/>
      <path
        d="M11.9987 5.3335C10.6802 5.3335 9.39123 5.72449 8.2949 6.45703C7.19857 7.18957 6.34409 8.23077 5.8395 9.44894C5.33492 10.6671 5.2029 12.0076 5.46013 13.3008C5.71737 14.594 6.35231 15.7819 7.28466 16.7142C8.21701 17.6466 9.40489 18.2815 10.6981 18.5387C11.9913 18.796 13.3318 18.6639 14.5499 18.1594C15.7681 17.6548 16.8093 16.8003 17.5418 15.704C18.2744 14.6076 18.6654 13.3187 18.6654 12.0002C18.6654 11.1247 18.4929 10.2578 18.1579 9.44894C17.8229 8.6401 17.3318 7.90517 16.7127 7.28612C16.0937 6.66706 15.3588 6.176 14.5499 5.84097C13.7411 5.50593 12.8742 5.3335 11.9987 5.3335ZM11.9987 17.3335C10.9439 17.3335 9.91272 17.0207 9.03566 16.4347C8.1586 15.8486 7.47501 15.0157 7.07134 14.0411C6.66768 13.0666 6.56206 11.9942 6.76785 10.9597C6.97363 9.92512 7.48158 8.97481 8.22746 8.22893C8.97334 7.48305 9.92365 6.9751 10.9582 6.76931C11.9928 6.56352 13.0651 6.66914 14.0397 7.07281C15.0142 7.47647 15.8472 8.16006 16.4332 9.03712C17.0192 9.91418 17.332 10.9453 17.332 12.0002C17.332 13.4147 16.7701 14.7712 15.7699 15.7714C14.7697 16.7716 13.4132 17.3335 11.9987 17.3335ZM14.6654 11.3335H12.6654V9.3335C12.6654 9.15669 12.5951 8.98712 12.4701 8.86209C12.3451 8.73707 12.1755 8.66683 11.9987 8.66683C11.8219 8.66683 11.6523 8.73707 11.5273 8.86209C11.4023 8.98712 11.332 9.15669 11.332 9.3335V11.3335H9.33203C9.15522 11.3335 8.98565 11.4037 8.86063 11.5288C8.73561 11.6538 8.66537 11.8234 8.66537 12.0002C8.66537 12.177 8.73561 12.3465 8.86063 12.4716C8.98565 12.5966 9.15522 12.6668 9.33203 12.6668H11.332V14.6668C11.332 14.8436 11.4023 15.0132 11.5273 15.1382C11.6523 15.2633 11.8219 15.3335 11.9987 15.3335C12.1755 15.3335 12.3451 15.2633 12.4701 15.1382C12.5951 15.0132 12.6654 14.8436 12.6654 14.6668V12.6668H14.6654C14.8422 12.6668 15.0117 12.5966 15.1368 12.4716C15.2618 12.3465 15.332 12.177 15.332 12.0002C15.332 11.8234 15.2618 11.6538 15.1368 11.5288C15.0117 11.4037 14.8422 11.3335 14.6654 11.3335Z"
        fill={"#070550"}
      />
    </svg>
  );
};

export default AddUnfilledCircle;
