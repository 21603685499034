import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getPagesbyCompanyId } from 'services/page';
import { AsyncAction } from 'types/Action';
import {
  GetPagesbyCompanyIdMeta,
  GetPagesbyCompanyIdPayload,
} from 'types/store/GetPagesbyCompanyId';
import { Store } from 'types/store/Store';

import { getPagesbyCompanyIdActions } from '../actions';

function* getPagesbyCompanyIdRequest(
  action: AsyncAction<GetPagesbyCompanyIdMeta, GetPagesbyCompanyIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getPagesbyCompanyId,
        action.meta,
        state.auth.token
      );
      yield put(getPagesbyCompanyIdActions.success(data));
    } catch (e: any) {
      yield put(getPagesbyCompanyIdActions.failed(e.message));
    }
  } else {
    yield put(getPagesbyCompanyIdActions.failed('Token not found'));
  }
}

function* getPagesbyCompanyIdSuccess(
  action: AsyncAction<GetPagesbyCompanyIdMeta, GetPagesbyCompanyIdPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* forgotPasswordWatcher(): any {
  yield takeLatest(
    getPagesbyCompanyIdActions.REQUEST,
    getPagesbyCompanyIdRequest
  );
  yield takeLatest(
    getPagesbyCompanyIdActions.SUCCESS,
    getPagesbyCompanyIdSuccess
  );
}

export default forgotPasswordWatcher;
