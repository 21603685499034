import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateStoryForBuilder } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import {
  UpdateStoryForBuilderMeta,
  UpdateStoryForBuilderPayload,
} from 'types/store/UpdateStoryForBuilder';
import { AxiosError } from 'axios';

import { openToast, updateStoryForBuilderActions } from '../actions';

function* updateStoryForBuilderRequest(
  action: AsyncAction<UpdateStoryForBuilderMeta, UpdateStoryForBuilderPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        updateStoryForBuilder,
        action.meta,
        state.auth.token,
      );
      yield put(updateStoryForBuilderActions.success(data));
    } catch (e: unknown) {
      // yield put(updateStoryForBuilderActions.failed(e.message));
      const errorMessage = e as AxiosError;
      yield put(
        openToast(errorMessage?.response?.data.message ?? 'Error', 'error'),
      );
    }
  }
}

function* updateStoryForBuilderSuccess(
  action: AsyncAction<UpdateStoryForBuilderMeta, UpdateStoryForBuilderPayload>,
) {
  // yield put()
}

function* updateStoryForBuilderWatcher(): any {
  yield takeLatest(
    updateStoryForBuilderActions.REQUEST,
    updateStoryForBuilderRequest,
  );
  yield takeLatest(
    updateStoryForBuilderActions.SUCCESS,
    updateStoryForBuilderSuccess,
  );
}

export default updateStoryForBuilderWatcher;
