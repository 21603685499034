import { RegisterEbWithExistedAccountMeta, RegisterEbWithExistedAccountPayload } from 'types/store/RegisterEbWithExistedAccount';
import { createAsyncAction } from 'utils/Redux';

const ns = 'REGISTER_EB_WITH_EXISTED_ACCOUNT_NS';

const asyncAction = createAsyncAction<RegisterEbWithExistedAccountMeta, RegisterEbWithExistedAccountPayload>(ns);

const registerEbWithExistedAccountActions = { ...asyncAction };

export default registerEbWithExistedAccountActions;
