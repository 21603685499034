import {
    UpdateShowcaseFeedbackHighlightMeta,
    UpdateShowcaseFeedbackHighlightPayload
} from 'types/store/UpdateShowcaseFeedbackHighlightState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SHOWCASE_FEEDBACK_HIGHLIGHT';

const asyncAction = createAsyncAction<
    UpdateShowcaseFeedbackHighlightMeta,
    UpdateShowcaseFeedbackHighlightPayload
>(ns);

const updateShowcaseFeedbackHighlightActions = {
    ...asyncAction,
};

export default updateShowcaseFeedbackHighlightActions;
