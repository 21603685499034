import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getRecipeStory } from 'services/recipe-detail';
import { recipeStoryActions } from 'store/actions';
import { IRecipeStoryMeta } from 'store/actions/contentPlanner/recipeStory';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getRecipeStorySaga(
  action: AsyncAction<IRecipeStoryMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getRecipeStory,
        action.meta,
        state.auth.token,
      );
      yield put(recipeStoryActions.success(data));
    } catch (e: any) {
      yield put(recipeStoryActions.failed(e.message));
    }
  }
}

function* recipeStoryWatcher(): any {
  yield takeLatest(recipeStoryActions.REQUEST, getRecipeStorySaga);
}

export default recipeStoryWatcher;
