import { StoryBuilderPayload } from 'types/store/StoryBuilderState';
import { createUpdateAction, createClearAction } from 'utils/Redux';

const ns = 'STORY_BUILDER';
const updateAction = createUpdateAction<StoryBuilderPayload>(ns);
const clearAction = createClearAction(ns);

const storyBuilderActions = {
  ...updateAction,
  ...clearAction,
};

export default storyBuilderActions;
