import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStoryCost, setStoryCost } from 'services/payment';
import { SetStoryCostMeta } from 'store/actions/settings/setStoryCost';
import {
  getStoryCostActions,
  setStoryCostActions,
  getCompanyCreditsActions,
} from 'store/actions';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getStoryCostRequest(action: AsyncAction<any, GenericResponse>) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getStoryCost, state.auth.token);
      yield put(getStoryCostActions.success(data));
    } catch (e: any) {
      const errorResponse = e?.response;
      yield put(getStoryCostActions.failed(errorResponse.data?.message));
    }
  }
}

function* setStoryCostRequest(
  action: AsyncAction<SetStoryCostMeta, GenericResponse>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(setStoryCost, action.meta, state.auth.token);
      yield put(setStoryCostActions.success(data));
    } catch (e: any) {
      const errorResponse = e?.response;
      yield put(setStoryCostActions.failed(errorResponse.data?.message));
    }
  }
}

function* updateCompanyCredits() {
  const state: Store = yield select();
  const companyCreditResponse = state.getCompanyCredits.data;
  yield put(
    getCompanyCreditsActions.success({
      ...companyCreditResponse,
      data: {
        ...companyCreditResponse?.data,
        credits: state.setStoryCost.data?.data?.remainingCredit,
      },
    }),
  );
}

function* storyCostWatcher(): any {
  yield takeLatest(getStoryCostActions.REQUEST, getStoryCostRequest);
  yield takeLatest(setStoryCostActions.REQUEST, setStoryCostRequest);
  yield takeLatest(setStoryCostActions.SUCCESS, updateCompanyCredits);
}

export default storyCostWatcher;
