import React from 'react';

import {
  DropdownArrow,
  Info,
  PlusCircle,
  CloseFilled,
} from 'components/base/SVG';
import ToolTip from 'components/base/ToolTip';
import Typography from 'components/base/Typography';
import Select, { OptionProps, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import theme from 'utils/Theme';

import { DropDownProps } from './DropDown.props';
import {
  Container,
  LabelContainer,
  SelectWrapper,
  TipIcon,
  CreateLabel,
} from './DropDown.style';

const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <DropdownArrow fill="#070550" />
    </components.DropdownIndicator>
  );
};

const ClearIndicator = (props: any) => {
  return (
    <components.ClearIndicator {...props}>
      <CloseFilled width={16.67} height={16.67} fill={'#B5ADB0' || '#F0ECEE'} />
    </components.ClearIndicator>
  );
};

const Option = (props: OptionProps<any, false>) => {
  return (
    <components.Option {...props}>
      <div
        style={
          props.data.select_disabled || props.data.isDisabled
            ? { opacity: 0.4 }
            : {}
        }
      >
        {props.data.label}
      </div>
    </components.Option>
  );
};

const DropDown = (props: DropDownProps): JSX.Element => {
  const {
    label,
    showLabel = true,
    isCreatable,
    tooltip,
    isFullWidth,
    customMargin,
    customLabel,
    useCustomStyles = false,
    placeholderCreatable = 'Create',
    createOptionPosition = 'first',
    isColorBlack,
    preventCreateDuplicateValue = false,
    menuPlacement = 'auto',
    marginLabel = '4px',
    ...selectProps
  } = props;

  const isDuplicateWithOptions = (value: string) => {
    return props.options?.some((option) => option.value === value) || !value;
  };

  return (
    <Container margin={customMargin}>
      {label && (
        <LabelContainer marginLabel={marginLabel}>
          <Typography
            variant="overline"
            color={isColorBlack ? 'black' : 'shade6'}
          >
            {label}
          </Typography>
        </LabelContainer>
      )}
      {customLabel && customLabel}
      <SelectWrapper isFullWidth={isFullWidth}>
        {isCreatable ? (
          <CreatableSelect
            menuPlacement={menuPlacement}
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder="Select or create"
            {...selectProps}
            styles={
              !useCustomStyles
                ? {
                    ...selectProps.styles,
                    option: (provided: any, state) => ({
                      ...provided,
                      cursor: 'pointer',
                      color: theme.brand.primary,
                      padding: '10px 20px',
                      backgroundColor: state.isSelected
                        ? theme.brand.secondary
                        : state.isFocused
                        ? theme.grey.shade2
                        : theme.grey.noshade,
                    }),
                    menuList: (provided: any, state) => ({
                      ...provided,
                      // padding: 20,
                      '& > div:first-of-type': {
                        // padding: 0,
                        backgroundColor: '#fff',
                        position: 'relative',
                        // margin: '0 20px 12px 20px',
                        marginBottom: 12,
                        borderBottom: '1px solid #F0ECEE',
                      },
                    }),
                  }
                : {
                    ...selectProps.styles,
                  }
            }
            createOptionPosition={createOptionPosition}
            // remove this code to allow validation duplicate name when input to avoid create the same value-- PLAT-2944
            // isValidNewOption={() => true}
            formatCreateLabel={(inputValue) => {
              const inputValWithColon = inputValue ? ': ' + inputValue : '';
              return !preventCreateDuplicateValue ||
                !isDuplicateWithOptions(inputValue) ? (
                <CreateLabel
                  active={inputValue !== ''}
                  onClick={(e) => e.preventDefault()}
                >
                  <PlusCircle
                    width={16.67}
                    height={16.67}
                    fill={
                      inputValue !== ''
                        ? theme.brand.secondary
                        : theme.brand.primary
                    }
                  />
                  <Typography>
                    {' '}
                    {placeholderCreatable}
                    {inputValWithColon}
                  </Typography>
                </CreateLabel>
              ) : (
                <> </>
              );
            }}
            onCreateOption={(inputValue) => {
              if (inputValue && selectProps.onChange) {
                selectProps.onChange(
                  selectProps.isMulti
                    ? [
                        ...(selectProps.value?.length > 0
                          ? [...selectProps.value]
                          : []),
                        ...[{ value: null, label: inputValue }],
                      ]
                    : { value: null, label: inputValue },
                  { action: 'create-option' },
                );
              }
            }}
            // menuIsOpen
          ></CreatableSelect>
        ) : (
          <Select
            menuPlacement={menuPlacement}
            placeholder="Select"
            components={{ DropdownIndicator, ClearIndicator, Option }}
            {...selectProps}
          ></Select>
        )}
        {tooltip && (
          <TipIcon>
            <ToolTip arrow content={tooltip}>
              <Info width={24} height={24} />
            </ToolTip>
          </TipIcon>
        )}
      </SelectWrapper>
    </Container>
  );
};

export default React.memo(DropDown);
