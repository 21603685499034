import {
  GetCompanyCreditsMeta,
  GetCompanyCreditsPayload,
} from 'types/store/GetCompanyCredits';
import { createAsyncAction, createClearAction } from 'utils/Redux';

const ns = 'GET_COMPANY_CREDITS';

const asyncAction = createAsyncAction<
  GetCompanyCreditsMeta,
  GetCompanyCreditsPayload
>(ns);
const clearAction = createClearAction(ns);

const getCompanyCreditsActions = {
  ...asyncAction,
  ...clearAction,
};

export default getCompanyCreditsActions;
