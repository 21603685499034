import React from 'react';

import { SVGProps } from './SVG.props';

const AngleUp = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg width={width || 12}
      height={height || 12} viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" fill={fill || '#DED9DB'} {...props}   >
      <path d="M8.49998 6.70542L6.35497 4.5854C6.30849 4.53854 6.25319 4.50134 6.19226 4.47595C6.13133 4.45057 6.06598 4.4375 5.99997 4.4375C5.93397 4.4375 5.86862 4.45057 5.80769 4.47595C5.74676 4.50134 5.69146 4.53854 5.64497 4.5854L3.52497 6.70542C3.47811 6.7519 3.44091 6.8072 3.41553 6.86813C3.39014 6.92906 3.37708 6.99442 3.37708 7.06042C3.37708 7.12643 3.39014 7.19178 3.41553 7.25271C3.44091 7.31365 3.47811 7.36895 3.52497 7.41543C3.61866 7.50855 3.74538 7.56083 3.87747 7.56083C4.00957 7.56083 4.13629 7.50855 4.22997 7.41543L5.99997 5.64541L7.76998 7.41543C7.86311 7.5078 7.98881 7.55988 8.11998 7.56043C8.18578 7.56081 8.25101 7.5482 8.31193 7.52332C8.37285 7.49843 8.42826 7.46177 8.47498 7.41543C8.52351 7.37062 8.56269 7.31664 8.59024 7.2566C8.6178 7.19656 8.63318 7.13166 8.63551 7.06564C8.63783 6.99962 8.62705 6.93379 8.60379 6.87197C8.58052 6.81014 8.54524 6.75353 8.49998 6.70542Z" fill="#070550" />
    </svg>

  );
};

export default AngleUp;
