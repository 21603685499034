import { fork, all } from 'redux-saga/effects';

/* PLOP IMPORT */
import addApproversV2 from './addApproversV2';
import addCard from './addCard';
import addContentToState from './addContentToState';
import addGetSupportNotification from './addGetSupportNotification';
import addQuestionsForAdvocate from './addQuestionsForAdvocate';
import addStoryDiscussionComment from './addStoryDiscussionComment';
import advocateBulkCall from './advocateBulkCall';
import advocatePortal from './advocatePortal';
import advocateSendEmail from './advocateSendEmail';
import advocateStoryOverview from './advocateStoryOverview';
import getAverageTime from './analytics/averageTime';
import getBrandAdvocates from './analytics/brandAdvocates';
import getContentMetrics from './analytics/contentMetrics';
import getContentPipeline from './analytics/contentPipeline';
import getContentCost from './analytics/ROI/contentCost';
import getEmployeeAdvocacy from './analytics/ROI/employeeAdvocacy';
import getSocialReach from './analytics/ROI/socialReach';
import getWebTraffic from './analytics/ROI/webTraffic';
import answerMultiQuestion from './answerMultiQuestion';
import answerQuestions from './answerQuestions';
import approversApprove from './approversApprove';
import assignAdvocatesToStory from './assignAdvocatesToStory';
import bulkCreateContentPackages from './bulkCreateContentPackages';
import changePassword from './changePassword';
import changePasswordEB from './changePasswordEB';
import changeStoryDueDate from './changeStoryDueDate';
import chargeCard from './chargeCard';
import checkColleagueEmails from './checkColleagueEmails';
import checkEbInvite from './checkEbInvite';
import checkEmailRegister from './checkEmailRegister';
import checkInvite from './checkInvite';
import checkLibraryTranscribe from './checkLibraryTranscribe';
import checkUrl from './checkUrl';
import cloneStory from './cloneStory';
import company from './company';
import contentPackages from './contentPackages';
import contentPlanner from './contentPlanner';
import countDashboardStories from './countDashboardStories';
import createAppointment from './createAppointment';
import createAppointmentByAdvocate from './createAppointmentByAdvocate';
import createCustomQuestion from './createCustomQuestion';
import createDiscussStory from './createDiscussStory';
import createEmailTemplate from './createEmailTemplate';
import createLibrary from './createLibrary';
import createNewCustomer from './createNewCustomer';
import createPage from './createPage';
import createRevision from './createRevision';
import createShowcase from './createShowcase';
import createSmsTemplate from './createSmsTemplate';
import createStory from './createStory';
import createStoryVersion from './createStoryVersion';
import deleteApprover from './deleteApprover';
import deleteEmailTemplate from './deleteEmailTemplate';
import deleteInvitedTeam from './deleteInvitedTeam';
import deleteLibrary from './deleteLibrary';
import deletePage from './deletePage';
import deleteShowcase from './deleteShowcase';
import deleteShowcaseArticle from './deleteShowcaseArticle';
import deleteShowcaseFeedback from './deleteShowcaseFeedback';
import deleteShowcasePage from './deleteShowcasePage';
import deleteSmsTemplate from './deleteSmsTemplate';
import disconnectLinkedin from './disconnectLinkedin';
import discussionStories from './discussionStories';
import discussionStoryDetails from './discussionStoryDetails';
import downloadResponses from './downloadResponses';
import downloadResponsesVideoStory from './downloadResponsesVideoStory';
import downloadStoryVideo from './downloadStoryVideo';
import duplicatePage from './duplicatePage';
import ebCommentOnContentPackage from './ebCommentOnContentPackage';
import ebCommentOnRecipe from './ebCommentOnRecipe';
import ebGetStoriesEditor from './ebGetStoriesEditor';
import editApprover from './editApprover';
import employeeHubWatcher from './employeeHub';
import extractTextFromFile from './extractTextFromFile';
import extractFromUrl from './extractTextFromUrl';
import forgotPassword from './forgotPassword';
import generatePage from './generatePage';
import generateSocialCopy from './generateSocialCopy';
import getActionList from './getActionList';
import getAdmins from './getAdmins';
import getRecruiter from './getRecruiter';
import getAdvocateAnalytics from './getAdvocateAnalytics';
import getAdvocateByStory from './getAdvocateByStory';
import getAdvocateDetails from './getAdvocateDetails';
import getAdvocateInterest from './getAdvocateInterest';
import getAdvocateInvites from './getAdvocateInvites';
import getAdvocatesByCompanyId from './getAdvocatesByCompanyId';
import getAdvocatesByUserId from './getAdvocatesByUserId';
import getAllContentPackageWithDiscussionStatus from './getAllContentPackageWithDiscussionStatus';
import getAllItems from './getAllItems';
import getAllPlatform from './getAllPlatform';
import getAppbarInfo from './getAppbarInfo';
import getApprovalsByStoryId from './getApprovalsByStoryId';
import getApprovers from './getApprovers';
import getAsset from './getAsset';
import getBillingAddress from './getBillingAddress';
import getBrandGuidelinesByCompanyId from './getBrandGuidelinesByCompanyId';
import getCategories from './getCategories';
import getCategoryByStoryId from './getCategoryByStoryId';
import getCommunities from './getCommunities';
import getCompanyCalendlyLink from './getCompanyCalendlyLink';
import getCompanyCredits from './getCompanyCredits';
import getCompanyInfoByCompanyId from './getCompanyInfoByCompanyId';
import getCompanyThreshold from './getCompanyThreshold';
import getContentMarketplace from './getContentMarketplace';
import getContentPackageDiscussionByIdAndEmail from './getContentPackageDiscussionByIdAndEmail';
import getContentPackageDiscussionStatus from './getContentPackageDiscussionStatus';
import getDashboardStories from './getDashboardStories';
import getDataRecipeDetail from './getDataRecipeDetail';
import getDataShowcasePageDetails from './getDataShowcasePageDetails';
import getDataShowcasePublicHomepage from './getDataShowcasePublicHomepage';
import getDataShowcasePublicPageDetails from './getDataShowcasePublicPageDetails';
import getEmailTemplates from './getEmailTemplates';
import getEmployeeHubData from './getEmployeeHubData';
import getEmployeeProfileByAuth from './getEmployeeProfileByAuth';
import getFinalLibraryCalendar from './getFinalLibraryCalendar';
import getFinalStoryPieces from './getFinalStoryPieces';
import getFinalWrittenStory from './getFinalWrittenStory';
import getHomePageHeader from './getHomePageHeader';
import getHomePageMenu from './getHomePageMenu';
import getHomePageShortcuts from './getHomePageShortcuts';
import getInterestByCommunity from './getInterestByCommunity';
import getInterestsByCategoryId from './getInterestsByCategoryId';
import getInvitedTeams from './getInvitedTeams';
import getInviteLinkTeam from './getInviteLinkTeam';
import getLibraryAssets from './getLibraryAssets';
import getLibraryStories from './getLibraryStories';
import getLinkedinConnectionStatus from './getLinkedinConnectionStatus';
import getLinkInvite from './getLinkInvite';
import getListDefaultContentPackages from './getListDefaultContentPackages';
import getListeningPathsByCommunity from './getListeningPathsByCommunity';
import getListGoogleFonts from './getListGoogleFonts';
import getListOptionAdvocate from './getListOptionAdvocate';
import getMagicLink from './getMagicLink';
import getMoreQuestion from './getMoreQuestion';
import getMoreQuestionForCustom from './getMoreQuestionForCustom';
import getNotificationsByUserId from './getNotificationsByUserId';
import getOnboard from './getOnboard';
import getOwnPageDetails from './getOwnPageDetails';
import getPage from './getPage';
import getPagesbyCompanyId from './getPagesbyCompanyId';
import getRecipeDiscussionByEmail from './getRecipeDiscussionByEmail';
import getRecipeDiscussionDetail from './getRecipeDiscussionDetail';
import getRecipes from './getRecipes';
import getReviewedStories from './getReviewedStories';
import getSavedCards from './getSavedCards';
import getSaveTemplatesByCompanyId from './getSaveTemplatesByCompanyId';
import getShareSocialStory from './getShareSocialStory';
import getSharing from './getSharing';
import getShowcaseArticles from './getShowcaseArticles';
import getShowcaseArticlesByUrl from './getShowcaseArticlesByUrl';
import getShowcaseCompany from './getShowcaseCompany';
import getShowcaseCompanyByUrl from './getShowcaseCompanyByUrl';
import getShowcaseFeedbacks from './getShowcaseFeedbacks';
import getShowcaseFeedbacksByUrl from './getShowcaseFeedbacksByUrl';
import getShowcasePageDetails from './getShowcasePageDetails';
import getShowcasePagesByCompanyId from './getShowcasePagesByCompanyId';
import getSmsTemplate from './getSmsTemplate';
import getStoriesAnalytics from './getStoriesAnalytics';
import getStoriesByCompanyId from './getStoriesByCompanyId';
import getStoriesByUserId from './getStoriesByUserId';
import getStoriesLibraryByCompanyId from './getStoriesLibraryByCompanyId';
import getStoryActivityLogs from './getStoryActivityLogs';
import getStoryById from './getStoryById';
import getStoryCalendarByCompany from './getStoryCalendarByCompany';
import getStoryDiscussionComments from './getStoryDiscussionComments';
import getStoryEventLog from './getStoryEventLog';
import getStoryEvents from './getStoryEvents';
import getStoryForBuilder from './getStoryForBuilder';
import getStoryForEditor from './getStoryForEditor';
import getStoryLink from './getStoryLink';
import getStoryVersionOptions from './getStoryVersionOptions';
import getStripeCustomerId from './getStripeCustomerId';
import getSuggestedQuestions from './getSuggestedQuestions';
import getTags from './getTags';
import getTimelineEvents from './getTimelineEvents';
import getTopics from './getTopics';
import getTransactionByStripeId from './getTransactionByStripeId';
import getTranscribeVideo from './getTranscribeVideo';
import getTrendingByCommunity from './getTrendingByCommunity';
import getUnpaidStories from './getUnpaidStories';
import getUserById from './getUserById';
import getUserSettings from './getUserSettings';
import getValueCompanyTrackingContentPackage from './getValueCompanyTrackingContentPackage';
import guestCommentOnContentPackage from './guestCommentOnContentPackage';
import guestCommentOnRecipe from './guestCommentOnRecipe';
import inviteAdvocate from './inviteAdvocate';
import inviteTeam from './inviteTeam';
import inviteToShareStory from './inviteToShareStory';
import libraryShareContent from './libraryShareContent';
import loadDocxByFinalStory from './loadDocxByFinalStory';
import login from './login';
import markReadNotifications from './markReadNotifications';
import markStoryAsPublished from './markStoryAsPublished';
import markStoryAsReadyToPublish from './markStoryAsReadyToPublish';
import maskAsDefaultEmailTemplate from './maskAsDefaultEmailTemplate';
import maskAsDefaultSmsTemplate from './maskAsDefaultSmsTemplate';
import nudgeApproval from './nudgeApproval';
import publishShowcase from './publishShowcase';
import purchaseCredits from './purchaseCredits';
import register from './register';
import registerAdvocate from './registerAdvocate';
import registerEbWithExistedAccount from './registerEbWithExistedAccount';
import registerUploadVideoLinkedin from './registerUploadVideoLinkedin';
import removeCard from './removeCard';
import removeStory from './removeStory';
import archiveStory from './archiveStory';
import resendInvitation from './resendInvitation';
import resetPassword from './resetPassword';
import sendApprovalNotification from './sendApprovalNotification';
import sendEmployerBrandingInvitation from './sendEmployerBrandingInvitation';
import sendMailWithTemplate from './sendMailWithTemplate';
import sendUpgradeMail from './sendUpgradeMail';
import updateRemainingCredit from './settings/updateRemainingCredit';
import shareStory from './shareStory';
import shareToLinkedin from './shareToLinkedin';
import shareVideoToLinkedin from './shareVideoToLinkedin';
import showcasePageCheckUrl from './showcasePageCheckUrl';
import story from './story';
import uploadAnswerVideoWatcher from './story/uploadAnswerVideo';
import watchStoryEditorGenerateAIcontent from './storyHubEditor';
import submitVideoComment from './submitVideoComment';
import switchPlatforms from './switchPlatforms';
import transcribeAudio from './transcribeAudio';
import transcribeCheck from './transcribeCheck';
import transcribeLibraryVideo from './transcribeLibraryVideo';
import transcribeVideo from './transcribeVideo';
import transcribeStory from './transcribeStory';
import updateAnswerById from './updateAnswerById';
import updateArticlePosition from './updateArticlePosition';
import updateArticlePositionStoryPage from './updateArticlePositionStoryPage';
import updateBillingAddress from './updateBillingAddress';
import updateBrandGuidelinesByCompanyId from './updateBrandGuidelinesByCompanyId';
import updateCardDetails from './updateCardDetails';
import updateContentMarketplace from './updateContentMarketplace';
import updateDefaultCard from './updateDefaultCard';
import updateEmailTemplate from './updateEmailTemplate';
import updateEmployeeProfileByAuth from './updateEmployeeProfileByAuth';
import updateFeedbackPosition from './updateFeedbackPosition';
import updateFinalStory from './updateFinalStory';
import updatePage from './updatePage';
import updatePageName from './updatePageName';
import updateQuestionForStory from './updateQuestionForStory';
import updateRevision from './updateRevision';
import updateRevisionApproval from './updateRevisionApproval';
import updateSfpSkippedAll from './updateSfpSkippedAll';
import updateSfpStatus from './updateSfpStatus';
import updateShowcaseArticleHighlight from './updateShowcaseArticleHighlight';
import updateShowcaseArticlePosition from './updateShowcaseArticlePosition';
import updateShowcaseFeedbackHighlight from './updateShowcaseFeedbackHighlight';
import updateShowcaseFeedbackPosition from './updateShowcaseFeedbackPosition';
import updateShowcasePagePosition from './updateShowcasePagePosition';
import updateShowcaseUrl from './updateShowcaseUrl';
import updateSmsTemplate from './updateSmsTemplate';
import updateStoryById from './updateStoryById';
import updateStoryDiscussion from './updateStoryDiscussion';
import updateStoryDiscussionComment from './updateStoryDiscussionComment';
import updateStoryForBuilder from './updateStoryForBuilder';
import updateStoryTopic from './updateStoryTopic';
import updateStoryToPublish from './updateStoryToPublish';
import updateStripeCustomerDetails from './updateStripeCustomerDetails';
import updateUserById from './updateUserById';
import updateUserSetting from './updateUserSetting';
import updateVersionDetail from './updateVersionDetail';
import uploadApprovalStory from './uploadApprovalStory';
import uploadFinalStory from './uploadFinalStory';
import uploadRevisedVideo from './uploadRevisedVideo';
import upsertApprovers from './upsertApprovers';
import upsertContentMarketplace from './upsertContentMarketplace';
import upsertDataShowcasePageDetails from './upsertDataShowcasePageDetails';
import upsertShowcaseArticle from './upsertShowcaseArticle';
import upsertShowcaseArticleImage from './upsertShowcaseArticleImage';
import upsertShowcaseFeedback from './upsertShowcaseFeedback';
import upsertShowcaseFeedbackImage from './upsertShowcaseFeedbackImage';
import upsertShowcasePage from './upsertShowcasePage';
import upsertStoryApprovals from './upsertStoryApprovals';
import useCredits from './useCredits';
import userActivity from './userActivity';
import verifyCodeLinkedin from './verifyCodeLinkedin';
import writeStory from './writeStory';
import recipeStory from './contentPlanner/recipeStory';
import setStoryToRecipeWatcher from './recipeDetails/setStoryToRecipe';
import storyCostWatcher from './settings/storyCost';
import updateTagsWatcher from './library/updateTags';
import createTagsWatcher from './library/createTags';
import oktaWatcher from './oktaAuth';
import registerGlobalWatcher from './registerGlobal';
import ratingFeedbackWatcher from './ratingFeedBack';
import toastWatcher from './toast';
import watcherLanguage from './getLanguage';
import watchSuggestionAdvocates from './contentPlanner/suggestionAdvocates';
import watchWidget from './widget/widget';
import brandKitWatcher from './creativeStudio';
import getCustomeFieldWatcher from './getCustomeField';
import getImportFromUrlWatcher from './jobs/importLink';
import generateJobAIContentWatcher from './jobs/generateContent';
import extractFileContentWatcher from './jobs/extractFileContent';
import getCategoriesWatcher from './getHeadlineAi';
import getSubtitles from './getSubtitles';
import updateThumpnailWatcher from './library/thumpnailWritten';
import trackAutoLoginWatcher from './trackAutoLogin';
import storyDetailV2Watcher from './storyDetailV2/storyDetailV2.saga';
// import assetLibraryWatcher from './assetLibrary/assetLibrary.saga';
import smartRecruiterWatcher from './smartRecuiter';
import assetLibraryWatcher from './assetLibrary/assetLibrary.saga';
import workspacesWatcher from './settings/Workspaces/Workspaces.saga';
import dynamicFilterWatcher from './dynamicFilter';
import unClockAdvocateWatcher from './unClockAdvocate';
import advocateStoryOverviewEarchTabsNumberStories from './advocateStoryOverviewEarchTabsNumberStories';

const sagas = [
  /* PLOP EXPORT */
  addContentToState,
  getLibraryStories,
  getValueCompanyTrackingContentPackage,
  shareVideoToLinkedin,
  registerUploadVideoLinkedin,
  checkLibraryTranscribe,
  transcribeLibraryVideo,
  getListDefaultContentPackages,
  bulkCreateContentPackages,
  getRecipes,
  getHomePageMenu,
  contentPackages,
  getContentPackageDiscussionByIdAndEmail,
  ebCommentOnContentPackage,
  guestCommentOnContentPackage,
  getAllContentPackageWithDiscussionStatus,
  getContentPackageDiscussionStatus,
  company,
  story,
  getTranscribeVideo,
  transcribeVideo,
  transcribeStory,
  getInterestsByCategoryId,
  ebCommentOnRecipe,
  getRecipeDiscussionByEmail,
  guestCommentOnRecipe,
  shareStory,
  getDataRecipeDetail,
  getRecipeDiscussionDetail,
  inviteToShareStory,
  getAdvocateByStory,
  getStoryEventLog,
  getStoryLink,
  shareToLinkedin,
  disconnectLinkedin,
  addApproversV2,
  deleteApprover,
  editApprover,
  getLinkedinConnectionStatus,
  generateSocialCopy,
  verifyCodeLinkedin,
  updateSmsTemplate,
  maskAsDefaultSmsTemplate,
  deleteSmsTemplate,
  createSmsTemplate,
  getSmsTemplate,
  countDashboardStories,
  sendMailWithTemplate,
  deleteEmailTemplate,
  maskAsDefaultEmailTemplate,
  updateEmailTemplate,
  createEmailTemplate,
  getEmailTemplates,
  ebGetStoriesEditor,
  addQuestionsForAdvocate,
  contentPlanner,
  approversApprove,
  updateVersionDetail,
  markStoryAsReadyToPublish,
  nudgeApproval,
  createStoryVersion,
  markStoryAsPublished,
  getStoryVersionOptions,
  answerQuestions,
  getStoryForEditor,
  advocateSendEmail,
  getCompanyCalendlyLink,
  advocatePortal,
  createAppointmentByAdvocate,
  createAppointment,
  updateStoryDiscussionComment,
  updateQuestionForStory,
  getAdvocateDetails,
  getStoryActivityLogs,
  getEmployeeHubData,
  getStoryDiscussionComments,
  discussionStoryDetails,
  discussionStories,
  answerMultiQuestion,
  updateStoryDiscussion,
  addStoryDiscussionComment,
  createDiscussStory,
  updateStoryTopic,
  getCompanyInfoByCompanyId,
  getAdvocatesByUserId,
  updateContentMarketplace,
  upsertContentMarketplace,
  getContentMarketplace,
  checkEmailRegister,
  getOnboard,
  getInviteLinkTeam,
  resendInvitation,
  deleteInvitedTeam,
  getAdmins,
  getInvitedTeams,
  getRecruiter,
  inviteTeam,
  removeStory,
  archiveStory,
  advocateStoryOverview,
  updateStoryForBuilder,
  cloneStory,
  getStoryForBuilder,
  changeStoryDueDate,
  downloadStoryVideo,
  updateSfpSkippedAll,
  sendUpgradeMail,
  getCompanyThreshold,
  registerEbWithExistedAccount,
  getMoreQuestionForCustom,
  switchPlatforms,
  getAllPlatform,
  getAsset,
  getMoreQuestion,
  assignAdvocatesToStory,
  getDashboardStories,
  loadDocxByFinalStory,
  checkEbInvite,
  login,
  register,
  forgotPassword,
  resetPassword,
  getPagesbyCompanyId,
  getApprovers,
  getApprovalsByStoryId,
  createPage,
  createStory,
  generatePage,
  deletePage,
  getPage,
  updatePage,
  updatePageName,
  getCategories,
  getAdvocatesByCompanyId,
  getTopics,
  getSuggestedQuestions,
  getSaveTemplatesByCompanyId,
  getCommunities,
  getInterestByCommunity,
  getListeningPathsByCommunity,
  getTrendingByCommunity,
  checkInvite,
  getAdvocateInterest,
  checkUrl,
  getStoriesByUserId,
  writeStory,
  updateStoryById,
  registerAdvocate,
  inviteAdvocate,
  getCompanyCredits,
  checkColleagueEmails,
  getReviewedStories,
  getStoryById,
  updateAnswerById,
  downloadResponses,
  updateStoryToPublish,
  getUserById,
  updateUserById,
  duplicatePage,
  getEmployeeProfileByAuth,
  updateEmployeeProfileByAuth,
  sendEmployerBrandingInvitation,
  transcribeAudio,
  transcribeCheck,
  getAppbarInfo,
  extractTextFromFile,
  getNotificationsByUserId,
  markReadNotifications,
  addGetSupportNotification,
  getUserSettings,
  updateUserSetting,
  advocateBulkCall,
  getStoriesByCompanyId,
  getStoriesLibraryByCompanyId,
  getStoryCalendarByCompany,
  upsertShowcaseArticle,
  getShowcaseArticles,
  deleteShowcaseArticle,
  getShareSocialStory,
  getSharing,
  deleteShowcasePage,
  upsertShowcaseArticleImage,
  createCustomQuestion,
  upsertShowcaseFeedback,
  getShowcaseFeedbacks,
  deleteShowcaseFeedback,
  upsertShowcaseFeedbackImage,
  getShowcaseArticlesByUrl,
  getShowcaseFeedbacksByUrl,
  getShowcaseCompany,
  getOwnPageDetails,
  updateShowcaseUrl,
  getShowcaseCompanyByUrl,
  getStoriesAnalytics,
  getAdvocateAnalytics,
  createLibrary,
  deleteLibrary,
  changePassword,
  changePasswordEB,
  getLibraryAssets,
  submitVideoComment,
  uploadFinalStory,
  getBrandGuidelinesByCompanyId,
  updateBrandGuidelinesByCompanyId,
  upsertApprovers,
  upsertStoryApprovals,
  createNewCustomer,
  purchaseCredits,
  getStripeCustomerId,
  updateStripeCustomerDetails,
  getSavedCards,
  chargeCard,
  addCard,
  updateDefaultCard,
  removeCard,
  useCredits,
  updateFinalStory,
  getTransactionByStripeId,
  getFinalWrittenStory,
  updateBillingAddress,
  getBillingAddress,
  getAllItems,
  updateCardDetails,
  getAdvocateInvites,
  uploadRevisedVideo,
  getCategoryByStoryId,
  getUnpaidStories,
  updateFeedbackPosition,
  updateArticlePosition,
  createRevision,
  updateRevision,
  updateRevisionApproval,
  updateSfpStatus,
  getFinalLibraryCalendar,
  getFinalStoryPieces,
  extractFromUrl,
  uploadApprovalStory,
  sendApprovalNotification,
  getStoryEvents,
  getTimelineEvents,
  getShowcasePageDetails,
  upsertShowcasePage,
  updateShowcasePagePosition,
  getDataShowcasePublicHomepage,
  getDataShowcasePublicPageDetails,
  getDataShowcasePageDetails,
  upsertDataShowcasePageDetails,
  updateShowcaseArticleHighlight,
  updateShowcaseFeedbackHighlight,
  updateShowcaseArticlePosition,
  updateShowcaseFeedbackPosition,
  showcasePageCheckUrl,
  getListGoogleFonts,
  getShowcasePagesByCompanyId,
  createShowcase,
  getTags,
  publishShowcase,
  deleteShowcase,
  getListOptionAdvocate,
  getMagicLink,
  updateArticlePositionStoryPage,
  downloadResponsesVideoStory,
  getHomePageHeader,
  getHomePageShortcuts,
  getLinkInvite,
  watchStoryEditorGenerateAIcontent,
  employeeHubWatcher,
  userActivity,
  getActionList,
  getAverageTime,
  getContentPipeline,
  getContentMetrics,
  getBrandAdvocates,
  getContentCost,
  getEmployeeAdvocacy,
  getSocialReach,
  getWebTraffic,
  updateRemainingCredit,
  libraryShareContent,
  uploadAnswerVideoWatcher,
  recipeStory,
  setStoryToRecipeWatcher,
  storyCostWatcher,
  oktaWatcher,
  updateTagsWatcher,
  createTagsWatcher,
  registerGlobalWatcher,
  ratingFeedbackWatcher,
  toastWatcher,
  watcherLanguage,
  watchSuggestionAdvocates,
  watchWidget,
  brandKitWatcher,
  getCustomeFieldWatcher,
  getImportFromUrlWatcher,
  generateJobAIContentWatcher,
  extractFileContentWatcher,
  getCategoriesWatcher,
  getSubtitles,
  updateThumpnailWatcher,
  trackAutoLoginWatcher,
  storyDetailV2Watcher,
  // assetLibraryWatcher,
  smartRecruiterWatcher,
  assetLibraryWatcher,
  smartRecruiterWatcher,
  workspacesWatcher,
  dynamicFilterWatcher,
  unClockAdvocateWatcher,
  advocateStoryOverviewEarchTabsNumberStories,
];

export default function* root(): any {
  yield all(sagas.map(fork));
}
