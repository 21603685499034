import { put, call, takeLatest, select } from 'redux-saga/effects';
import { updateQuestionForStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import { Store } from 'types/store/Store';
import { UpdateQuestionForStoryMeta, UpdateQuestionForStoryPayload } from 'types/store/UpdateQuestionForStory';

import { updateQuestionForStoryActions } from '../actions';

function* updateQuestionForStoryRequest(action: AsyncAction<UpdateQuestionForStoryMeta, UpdateQuestionForStoryPayload>): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const {data}  = yield call(updateQuestionForStory, action.meta, state.auth.token);
      yield put(updateQuestionForStoryActions.success(data));
    } catch (e: any) {
      yield put(updateQuestionForStoryActions.failed(e.message));
    }
  }
}

// function* updateQuestionForStorySuccess(action: AsyncAction<UpdateQuestionForStoryMeta, UpdateQuestionForStoryPayload>): any {
//   yield put()
// }

function* updateQuestionForStoryWatcher(): any {
  yield takeLatest(updateQuestionForStoryActions.REQUEST, updateQuestionForStoryRequest);
  // yield takeLatest(updateQuestionForStoryActions.SUCCESS, updateQuestionForStorySuccess);
}

export default updateQuestionForStoryWatcher;
