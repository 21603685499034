/* eslint-disable @typescript-eslint/no-empty-function */
import 'react-loading-skeleton/dist/skeleton.css';
import React, { useState } from 'react';

import flagsmith from 'flagsmith';
import { ToastContainer } from 'react-toastify';
import { FlagsmithProvider } from 'flagsmith/react';
import FeedBackOverlay from 'components/module/FeedBackOverlay';
import SnackbarContainer from 'components/module/SnackbarContainer';
import { ConnectedRouter } from 'connected-react-router';
import { ThemeProvider } from 'emotion-theming';
import { i18n } from 'locales/i18n';
import { SnackbarProvider } from 'providers/snackbar';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'routes/index.routes';
import { store, persistor, sagaMiddleware, history } from 'store';
import sagas from 'store/sagas';
import theme from 'utils/Theme';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'utils/QueryClient';

import 'react-toastify/dist/ReactToastify.css';
import 'react-virtualized/styles.css';
import './index.css';

import * as serviceWorker from './serviceWorker';

// Initialize language to English
i18n('en');

// create the saga middleware
sagaMiddleware.run(sagas);

history.listen(() => {
  window.scrollTo(0, 0);
});

const App = () => {
  const [rehydrated, setRehydrated] = useState(false);
  const onRehydate = async () => {
    setRehydrated(true);
  };

  // listen service workers
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('/sw.js')
      .then(() => {})
      .catch(() => {});
  }

  return (
    // <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor(onRehydate)}>
          <ConnectedRouter history={history}>
            <ThemeProvider theme={theme}>
              <SnackbarProvider>
                <FlagsmithProvider
                  options={{
                    environmentID:
                      process.env.REACT_APP_FEATURE_FLAGS_ENVIRONMENT_ID || '',
                  }}
                  flagsmith={flagsmith}
                >
                  <Routes />
                  <ToastContainer />
                </FlagsmithProvider>
                <FeedBackOverlay />
                <SnackbarContainer />
              </SnackbarProvider>
            </ThemeProvider>
          </ConnectedRouter>
        </PersistGate>
      </ReduxProvider>
    </QueryClientProvider>
  );
};

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
