import { TrackAutoLoginMeta } from 'types/store/TrackLoginMeta';
import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'AUTO_TRACK_LOGIN_NS';

const asyncAction = createAsyncAction<TrackAutoLoginMeta, GenericResponse>(ns);

const autoTrackLoginAction = { ...asyncAction };

export default autoTrackLoginAction;
