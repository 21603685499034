/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const Music = (props: SVGProps): JSX.Element => {
  const { width, height, fill, opacity } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 24}
      height={height || 24}
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19 7.30915V12.535C18.4117 12.1947 17.7286 11.9999 17 11.9999C14.7909 11.9999 13 13.7907 13 15.9999C13 18.209 14.7909 19.9999 17 19.9999C19.2091 19.9999 21 18.209 21 15.9999V4.6184C21 2.64028 19.1191 1.20363 17.2106 1.72411L10.2106 3.6332C8.90546 3.98916 8 5.17463 8 6.52749V14.535C7.41165 14.1947 6.72857 13.9999 6 13.9999C3.79086 13.9999 2 15.7907 2 17.9999C2 20.209 3.79086 21.9999 6 21.9999C8.20913 21.9999 9.99999 20.209 10 17.9999C10 17.9999 10 17.9999 10 17.9999V9.76369L19 7.30915ZM19 4.6184C19 3.95902 18.373 3.48014 17.7369 3.65363L10.7369 5.56272C10.3018 5.68138 10 6.07654 10 6.52749V7.69065L19 5.2361V4.6184ZM8 17.9999C8 16.8953 7.10457 15.9999 6 15.9999C4.89543 15.9999 4 16.8953 4 17.9999C4 19.1044 4.89543 19.9999 6 19.9999C7.10456 19.9999 7.99999 19.1044 8 17.9999C8 17.9999 8 17.9999 8 17.9999ZM17 13.9999C18.1046 13.9999 19 14.8953 19 15.9999C19 17.1044 18.1046 17.9999 17 17.9999C15.8954 17.9999 15 17.1044 15 15.9999C15 14.8953 15.8954 13.9999 17 13.9999Z"
        fill={fill || '#fff'}
      />
    </svg>
  );
};

export default Music;
