import axios from 'axios';
import { API } from 'consts';
import { ISuggestionAdvocatesMeta } from 'store/actions/contentPlanner/suggestionAdvocates';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const CONTENT_PLANNER_URL = `${BASE_URL}/content-planner`;

export const fetchSuggestionAdvocates = (
  data: ISuggestionAdvocatesMeta,
  token: string,
): any => {
  return axios({
    method: 'get',
    url: `${CONTENT_PLANNER_URL}/suggestion-advocates`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};
