import React, { useEffect, useState } from 'react';

import Button from 'components/base/Button';
import {
  Close,
  ChevronLeft,
  Visa,
  Mastercard,
  CloseFilled,
  ArrowLeft,
} from 'components/base/SVG';
import Typography from 'components/base/Typography';
import Modal from 'components/layout/Modal';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { Col, Row } from 'react-grid-system';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import AirTasker from 'res/img/upselling/airtasker.png';
import Canva from 'res/img/upselling/canva.png';
import Elmo from 'res/img/upselling/elmo.png';
import EY from 'res/img/upselling/ey.png';
import FlightCentre from 'res/img/upselling/flight_centre.png';
import Optus from 'res/img/upselling/optus.png';
import Origin from 'res/img/upselling/origin.png';
import Philips from 'res/img/upselling/philips.png';
import SalesForce from 'res/img/upselling/salesforce.png';
import Telstra from 'res/img/upselling/telstra.png';
import Uber from 'res/img/upselling/uber.png';
import UpsellingApproval from 'res/img/upselling/UpsellingApproval.png';
import UpsellingBriefing from 'res/img/upselling/UpsellingBriefing.png';
import UpsellingProduction from 'res/img/upselling/UpsellingProduction.png';
import Westpac from 'res/img/upselling/westpac.png';
import {
  chargeCardActions,
  // getAllItemsActions,
  // getCategoriesActions,
  useCreditsActions
} from 'store/actions';
import { Item } from 'types/store/GetAllItems';
import { CategorySummary } from 'types/store/GetCategories';
import { Store } from 'types/store/Store';
import { useTheme } from 'utils/Theme';

import AddImage from '../AddImage';
import FormikTextArea from '../FormikTextArea';
import FormikTextField from '../FormikTextField';
import { StoryEditorUpsellingProps } from './StoryEditorUpselling.props';
import {
  Container,
  Header,
  Title,
  Description,
  ExitButton,
  ButtonContainer,
  RightContainer,
  MainContainer,
  CardContainer,
  Icons,
  CardIcon,
  // BasicField,
  // FormRow,
  OrContainer,
  IconContainer,
  Image,
  BrandingContainer,
  Root,
  TextAreaField,
  AddImageContainer
} from './StoryEditorUpselling.style';
import { validate } from './StoryEditorUpselling.validation';
import VideoForm from './VideoForm';
import WrittenForm from './WrittenForm';

const listImageBrandingTeams = [
  { img: EY, alt: 'ey' },
  { img: Westpac, alt: 'westpac' },
  { img: SalesForce, alt: 'sales_force' },
  { img: Origin, alt: 'origin' },
  { img: Uber, alt: 'uber' },
  { img: Optus, alt: 'optus' },
  { img: Canva, alt: 'canva' },
  { img: Telstra, alt: 'telstra' },
  { img: Philips, alt: 'philips' },
  { img: AirTasker, alt: 'airtasker' },
  { img: Elmo, alt: 'elmo' },
  { img: FlightCentre, alt: 'flight_centre' }
];

const StoryEditorUpsellingView = (props: StoryEditorUpsellingProps): JSX.Element => {
  const dispatch = useDispatch();
  const history = useHistory();
  const EMAIL_SENDER = process.env.REACT_APP_EMAIL_PAYMENT;
  const chargeCardLoading =
    useSelector((state: Store) => state.chargeCard.pending) || false;
  const creditsAvailable =
    useSelector((state: Store) => state.getCompanyCredits.data?.data.credits) ||
    0;
  const useCreditsLoading =
    useSelector((state: Store) => state.useCredits.pending) || false;
  const {
    view,
    setView,
    storyTitle,
    typeOfStory,
    categoryPrice,
    storyId,
    storyType,
    updatePaidStatus,
    marketplaceId,
    activeStoryDetails
  } = props;
  const theme = useTheme();

  const [cardNumber, setCardNumber] = useState('');
  const [cardExp, setCardExp] = useState('');
  const [cardCvc, setCardCvc] = useState('');
  const [cardName, setCardName] = useState('');
  const [heroImage, setHeroImage] = useState<string | File | null>();
  const [bannerSizeValidate, setBannerSizeValidate] = useState('');
  // code by dom
  // const chargeCardState = useSelector((state: Store) => state.chargeCard);
  // const chargeCardStatus = chargeCardState.data?.status || 500;

  // const useCreditsState = useSelector((state: Store) => state.useCredits);
  // const useCreditsStatus = useCreditsState.data?.status || 500;

  // useEffect(() => {
  //   // if (chargeCardStatus === 200 || useCreditsStatus === 200) {
  //   //   updatePaidStatus(true);
  //   // } else {
  //   //   console.log('d00m');
  //   //   setMessage('Payment failed');
  //   // }
  // }, [chargeCardStatus, useCreditsStatus]);
  // // end; suggestion; you can refactor selectors like this

  const cardMessage =
    useSelector((state: Store) => state.chargeCard.data?.message) || undefined;
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (cardMessage !== undefined) {
      setMessage(cardMessage);

      // payment successful; close modal
      if (cardMessage === 'Card has been charged') {
        updatePaidStatus(true);
      }
    }
  }, [cardMessage]);

  useEffect(() => {
    setMessage('');
    return () => {
      dispatch(useCreditsActions.clear());
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessage('');
    }, 3000);
    return () => clearTimeout(timer);
  }, [message]);

  const creditMessage =
    useSelector((state: Store) => state.useCredits.data?.message) || undefined;
  const [messageCredit, setMessageCredit] = useState('');
  useEffect(() => {
    if (creditMessage !== undefined) {
      setMessageCredit(creditMessage);
      if (creditMessage === 'success') {
        updatePaidStatus(true);
      }
    }
  }, [creditMessage]);

  useEffect(() => {
    setMessageCredit('');
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setMessageCredit('');
    }, 3000);
    return () => clearTimeout(timer);
  }, [messageCredit]);

  const selectedCategoryId =
    useSelector((state: Store) => state.storyBuilder.selectedCategoryId) ||
    null;
  const productItems =
    useSelector((state: Store) => state.getAllItems.data?.data.items) || [];
  const categoryList: CategorySummary[] | undefined = useSelector(
    (state: Store) => state.getCategories.data?.data.categoryList || undefined
  );

  // useEffect(() => {
  //   dispatch(getAllItemsActions.request({}));
  //   dispatch(getCategoriesActions.request({}));
  // }, []);

  const [item, setItem] = useState<Item | undefined>({
    id: '',
    description: '',
    price: 0
  });

  useEffect(() => {
    if (selectedCategoryId && productItems && categoryList) {
      const state = categoryList.find((cat) => cat.id === selectedCategoryId);
      const selectedItem = productItems.find(
        (p) => p.description === state?.name
      );
      setItem(selectedItem);
    }
  }, []);

  useEffect(() => {
    if (item !== undefined) {
      const tempItem = { ...item };
      tempItem.price = categoryPrice ?? 0;
      setItem(tempItem);
    }
  }, [categoryPrice]);

  const useCredits = (amount: number | string) => {
    if (item != undefined) {
      dispatch(
        useCreditsActions.request({
          amount: amount,
          description: item.description, //this is the category name
          itemId: item.id, // no longer required
          storyId: storyId,
          emailSender: EMAIL_SENDER
        })
      );
    }
  };

  return (
    <Root>
      <Header>
        <Title variant='title3' color='noshade' weight='normal'>
          Hire an expert content creator in minutes.
        </Title>
        <Typography altVariant='copy' color='shade5'>
          Get professional written or video content, created by an expert matched to your specific requirements.
        </Typography>
        <Typography altVariant='copy' color='shade5'>
          Delivered in the exact format you need.
        </Typography>
        <ExitButton
          onClick={(e: any) => {
            e.preventDefault();
            setView();
          }}
        >
          <ArrowLeft width={23} height={23} fill='#070550' />
        </ExitButton>
      </Header>
      <Container>
        <MainContainer>
          <Title variant='title4' color='primary'>
            How it works
          </Title>
          <Image
            src={UpsellingBriefing}
            alt='up_selling_briefing'
            loading='lazy'
          />
          <Title variant='title4' color='primary'>
            1. Briefing
          </Title>
          <Description variant='body' color='shade6'>
            Tell us a few details about what you’re looking for so we can match you to the right subject matter expert.
          </Description>
          <Image
            src={UpsellingProduction}
            alt='up_selling_production'
            loading='lazy'
          />
          <Title variant='title4' color='primary'>
            2. Production
          </Title>
          <Description variant='body' color='shade6'>
            Focus on the important stuff while your subject matter expert gets to work creating a polished final piece.
          </Description>
          <Image
            src={UpsellingApproval}
            alt='up_selling_approval'
            loading='lazy'
          />
          <Title variant='title4' color='primary'>
            3. Approval
          </Title>
          <Description variant='body' color='shade6'>
            Review your piece to make sure it ticks all the right boxes. Get one round of edits to add the finishing touches.
          </Description>
          <ButtonContainer>
            <Title variant='title4' color='primary'>
              Have more questions?
            </Title>
            <Button textVariant='label' text='Talk to our Team' smaller />
          </ButtonContainer>
          <Title variant='title4' color='primary'>
            Join the world’s best employer branding teams
          </Title>
          <BrandingContainer>
            {listImageBrandingTeams.map((e, i) => (
              <IconContainer key={i}>
                <img src={e.img} alt={`${e.alt}_logo`} />
              </IconContainer>
            ))}
          </BrandingContainer>
        </MainContainer>
        <RightContainer>
          <br />            {storyType === 'WRITTEN' && (
            <WrittenForm
              storyId={storyId}
              onClose={() => setView()}
              marketplaceId={marketplaceId}
              activeStoryDetails={activeStoryDetails}
              updatePaidStatus={updatePaidStatus}
            />
          )}
          {storyType === 'VIDEO' && (
            <VideoForm
              storyId={storyId}
              onClose={() => setView()}
              marketplaceId={marketplaceId}
              activeStoryDetails={activeStoryDetails}
              updatePaidStatus={updatePaidStatus}
            />
          )}

          {/* <CardContainer>
              <Typography altVariant='overlineSmall' altColor='shade6'>
                Subtotal
              </Typography>
              <Typography variant='title4' color='primary'>
                {item && `$${item.price}`}
              </Typography>
              <Icons>
                <CardIcon>
                  <Visa />
                </CardIcon>
                <CardIcon>
                  <Mastercard />
                </CardIcon>
              </Icons>
              <Formik
                initialValues={{
                  cardNumber: cardNumber,
                  cardExp: cardExp,
                  cardCvc: cardCvc,
                  cardName: cardName
                }}
                onSubmit={(values, { resetForm }) => {
                  // amount to pay is TODO
                  dispatch(
                    chargeCardActions.request({
                      amount: item != undefined ? item.price : 0,
                      cardNumber: values.cardNumber,
                      cardCvc: values.cardCvc,
                      cardName: values.cardName,
                      cardExpMonth: values.cardExp.split('/')[0].trim(),
                      cardExpYear: moment(
                        values.cardExp.split('/')[1],
                        'YY'
                      ).format('YYYY'),
                      oneTime: true,
                      description: item != undefined ? item.description : '',
                      itemId: item != undefined ? item.id : '',
                      storyId: storyId,
                      emailSender: EMAIL_SENDER
                    })
                  );
                  resetForm();
                }}
                validate={validate}
                enableReinitialize
              >
                {({ setFieldValue }) => (
                  <Form style={{ width: '100%' }}>
                    <Row style={{ marginBottom: '24px', marginTop: '24px' }}>
                      <Col md={12}>
                        <FormikTextField
                          labelStyle='overline'
                          type='text'
                          name='cardNumber'
                          id='cardNumber'
                          label='Card Number'
                          placeholder='•••• •••• •••• ••••'
                          maxLength={19}
                          onChangeText={(value) => {
                            const digits = value.trim().replace(/\s/g, '');
                            const isMaxLen = digits.length > 16;
                            if (digits.length == 16) {
                              const visaString =
                                digits.substring(0, 4) +
                                ' ' +
                                digits.substring(4, 8) +
                                ' ' +
                                digits.substring(8, 12) +
                                ' ' +
                                digits.substring(12);
                              setFieldValue(
                                'cardNumber',
                                visaString.trim(),
                                false
                              );
                            } else if (digits.length === 15) {
                              const amexString =
                                digits.substring(0, 4) +
                                ' ' +
                                digits.substring(4, 10) +
                                ' ' +
                                digits.substring(10, 15) +
                                ' ' +
                                digits.substring(15);
                              setFieldValue(
                                'cardNumber',
                                amexString.trim(),
                                false
                              );
                            } else if (isMaxLen) {
                              setFieldValue(
                                'cardNumber',
                                value.slice(0, 19).trim(),
                                false
                              );
                            }
                          }}
                        />
                      </Col>
                    </Row>

                    <Row style={{ marginBottom: '24px' }} gutterWidth={10}>
                      <Col md={6}>
                        <FormikTextField
                          labelStyle='overline'
                          type='text'
                          name='cardExp'
                          id='cardExp'
                          label='EXPIRY DATE'
                          placeholder='MM / YY'
                          onChangeText={(value) => {
                            const text = value.trim().replace(/\s/g, '');
                            const isFirstHalf = value.length === 2;
                            const isMaxLen = text.replace('/', '').length >= 4;

                            if (isFirstHalf) {
                              setFieldValue('cardExp', value + ' / ', false);
                            } else if (isMaxLen) {
                              setFieldValue(
                                'cardExp',
                                value.substr(0, 7),
                                false
                              );
                            }
                          }}
                          onKeyUp={(e) => {
                            if (e.key === 'Backspace' || e.key === 'Delete') {
                              setFieldValue('cardExp', '', false);
                            }
                          }}
                          className='smallInput'
                        />
                      </Col>
                      <Col md={6}>
                        <FormikTextField
                          labelStyle='overline'
                          type='text'
                          name='cardCvc'
                          id='cardCvc'
                          label='CVC'
                          placeholder='CVC'
                          maxLength={3}
                          onChangeText={(value) => {
                            if (value.length >= 4) {
                              setFieldValue(
                                'cardCvc',
                                value.slice(0, 3),
                                false
                              );
                            }
                          }}
                          className='smallInput'
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginBottom: '24px' }}>
                      <Col md={12}>
                        <FormikTextField
                          labelStyle='overline'
                          type='text'
                          name='cardName'
                          id='cardName'
                          label='NAME ON CARD'
                          placeholder='Steve Jobs'
                        />
                      </Col>
                    </Row>
                    {message != 'Card has been charged' ? (
                      <Typography
                        altVariant='caption'
                        style={{ color: theme.product.error.primary }}
                      >
                        {message}
                      </Typography>
                    ) : (
                      <Typography
                        altVariant='caption'
                        style={{ color: theme.product.success.primary }}
                      >
                        Payment successful
                      </Typography>
                    )}
                    <Button
                      text='Pay Now'
                      type='submit'
                      textVariant='label'
                      smaller
                      disabled={chargeCardLoading}
                      loading={chargeCardLoading}
                    />
                  </Form>
                )}
              </Formik>
            </CardContainer>
            <OrContainer>
              <hr />
              <Typography altVariant='overlineSmall' altColor='shade6'>
                OR
              </Typography>
              <hr />
            </OrContainer>
            <CardContainer>
              <Typography altVariant='overlineSmall' altColor='shade6'>
                Credits available
              </Typography>
              <Typography variant='title4' color='primary'>
                ${creditsAvailable}
              </Typography>
              <br />
              <Button
                textVariant='label'
                text='Use credits'
                smaller
                onClick={() => useCredits(item != undefined ? item.price : 0)}
                disabled={useCreditsLoading}
                loading={useCreditsLoading}
              />
              {messageCredit === 'success' ? (
                <Typography
                  altVariant='caption'
                  style={{ color: theme.product.success.primary }}
                >
                  Payment successful
                </Typography>
              ) : messageCredit === 'failed' ? (
                <Typography
                  altVariant='caption'
                  style={{ color: theme.product.error.primary }}
                >
                  Payment failed
                </Typography>
              ) : (
                ''
              )}
            </CardContainer> */}
        </RightContainer>
      </Container>
    </Root>
  );
};

export default React.memo(StoryEditorUpsellingView);
