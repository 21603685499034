import {
   GetUnpaidStoriesMeta,
   GetUnpaidStoriesPayload
  } from 'types/store/GetUnpaidStories';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_UNPAID_STORIES';
const asyncAction = createAsyncAction<GetUnpaidStoriesMeta, GetUnpaidStoriesPayload>(
  ns
);

const getUnpaidStoriesActions = {
  ...asyncAction,
};

export default getUnpaidStoriesActions;
