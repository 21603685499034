import React from 'react';

import { SVGProps } from './SVG.props';

const SEUploadFinal = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g id="upload">
        <g id="line">
          <path d="M4.19522 5.1384C3.93487 4.87805 3.93487 4.45594 4.19522 4.19559L7.52855 0.862254C7.7889 0.601905 8.21101 0.601905 8.47136 0.862254L11.8047 4.19559C12.065 4.45594 12.065 4.87805 11.8047 5.1384C11.5443 5.39875 11.1222 5.39875 10.8619 5.1384L8.66663 2.94313L8.66663 10.667C8.66663 11.0352 8.36815 11.3337 7.99996 11.3337C7.63177 11.3337 7.33329 11.0352 7.33329 10.667L7.33329 2.94313L5.13803 5.1384C4.87768 5.39875 4.45557 5.39875 4.19522 5.1384Z" fill={fill || "#060548"} />
          <path d="M1.33329 10.0003C1.70148 10.0003 1.99996 10.2988 1.99996 10.667V13.3337C1.99996 13.7018 2.29844 14.0003 2.66663 14.0003H13.3333C13.7015 14.0003 14 13.7018 14 13.3337V10.667C14 10.2988 14.2984 10.0003 14.6666 10.0003C15.0348 10.0003 15.3333 10.2988 15.3333 10.667V13.3337C15.3333 14.4382 14.4379 15.3337 13.3333 15.3337H2.66663C1.56206 15.3337 0.666626 14.4382 0.666626 13.3337V10.667C0.666626 10.2988 0.965103 10.0003 1.33329 10.0003Z" fill={fill || "#060548"} />
        </g>
      </g>
    </svg>
  );
};

export default SEUploadFinal;


