import Button from 'components/base/Button';
import { Theme } from 'types/Theme';
import styled from 'utils/styled';

export const Backdrop = styled.div`
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  background-color: #07063269;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  z-index: ${({ theme }) => theme.zIndex.appBar};
`;

export const BackdropDialog = styled.div`
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  z-index: 9999;
  background-color: #07063269;
`;

export const ContainerDialog = styled.div<{
  minWidth?: string;
  customWidth?: string;
  padding?: string;
}>`
  width: ${({ customWidth }) => customWidth || 'max-content'};
  height: auto;
  max-width: 30%;
  min-width: ${({ minWidth }) => minWidth || '350px'};
  border-radius: 12px;
  background-color: #fff;
  padding: ${({ padding }) => padding || '20px'};
  margin: auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  -webkit-box-shadow: 0px 5px 5px 1px #8080808a;
  box-shadow: 0px 5px 5px 1px #8080808a;
  position: relative;
`;

export const Container = styled.div`
  width: 30%;
  height: auto;
  min-width: 350px;
  border-radius: 12px;
  background-color: #fff;
  padding: 20px;
  margin: auto;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const BrandContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Title = styled.h3`
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.5px;
  margin: 0;
  color: #070632;
  font-family: 'Objective';
  font-weight: normal;
`;

export const Strong = styled.strong`
  font-size: 20px;
`;

export const MessageContainer = styled.div`
  padding: 20px 10px;
  width: 100%;
  box-sizing: border-box;
`;

export const MessageContainerDiaLog = styled.div`
  padding: 5px 0 32px;
  width: 100%;
  box-sizing: border-box;
`;

export const Message = styled.p`
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: #070550;
  font-family: 'Objective';
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const StyledButton = styled.button<{
  variant: 'contained' | 'outlined';
  color: keyof Theme['brand'] | keyof Theme['grey'] | keyof Theme['opacity'];
  ml?: number;
}>`
  outline: none;
  padding: 15px 16px;
  font-family: 'Objective';
  font-size: 11px;
  line-height: 12px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 12px;
  cursor: pointer;
  margin-left: ${({ ml }) => ml}px;
  &:hover {
    opacity: 0.6;
  }
  ${({ theme, variant, color }) =>
    variant === 'contained'
      ? `
            background-color: ${
              { ...theme.brand, ...theme.grey, ...theme.opacity }[color]
            };
            border: 1px solid ${
              { ...theme.brand, ...theme.grey, ...theme.opacity }[color]
            };
        `
      : `
            border: 1px solid ${
              { ...theme.brand, ...theme.grey, ...theme.opacity }[color]
            };
              color: ${
                { ...theme.brand, ...theme.grey, ...theme.opacity }[color]
              }
      `}
`;

export const ActionButton = styled(Button)<{
  ml?: number;
  gray?: boolean;
  border?: boolean;
  white?: boolean;
}>`
  height: 36px;
  min-width: 90px;
  ${({ white }) => (white ? `background-color: #FFFFFF;` : '')}
  margin-left: ${({ ml }) => ml ?? 0}px;
  ${({ gray, theme }) =>
    gray ? `background-color: ${theme.grey.shade3};` : ''}
  ${({ border, theme }) =>
    border
      ? `border: 1px solid;
     border-color: ${theme.grey.shade7};
    `
      : ''}
`;

export const CloseButton = styled.div`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0px 6px 16px rgba(41, 43, 50, 0.12);
  cursor: pointer;

  position: absolute;
  top: -24px;
  right: -24px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
