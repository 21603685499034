import pathOr from 'ramda/es/pathOr';
import { onboardActions } from 'store/actions';
import { Action } from 'types/Action';
import {
    OnboardState,
    OnboardPayload
} from 'types/store/OnboardState';


const initState: OnboardState = {
    isOpen: false
}

const initAction: Action<OnboardPayload> = {
    type: '',
    payload: {
        isOpen: false
    }
}

export default (state = initState, action = initAction): any => {
    const reducer = () => {
        return {
            ...state,
            ...action.payload
        }
    }

    return pathOr(state, [action.type], {
        [onboardActions.UPDATE]: reducer(),
        [onboardActions.CLEAR]: initState
    })
}