/* eslint-disable react/no-unknown-property */
import React from 'react';

import { SVGProps } from './SVG.props';

const IconVideo = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 4C0 2.89543 0.895431 2 2 2H10C11.1046 2 12 2.89543 12 4V5.07505L15.1225 4.03421C15.3258 3.96644 15.5493 4.00054 15.7231 4.12584C15.897 4.25114 16 4.45237 16 4.66667V11.6667C16 11.8896 15.8886 12.0977 15.7031 12.2214C15.5177 12.345 15.2827 12.3678 15.0769 12.2821L12 11V12C12 13.1046 11.1046 14 10 14H2C0.895431 14 0 13.1046 0 12V4ZM12 9.55556L14.6667 10.6667V5.59162L12 6.48051V9.55556ZM2 3.33333C1.63181 3.33333 1.33333 3.63181 1.33333 4V12C1.33333 12.3682 1.63181 12.6667 2 12.6667H10C10.3682 12.6667 10.6667 12.3682 10.6667 12V4C10.6667 3.63181 10.3682 3.33333 10 3.33333H2Z"
        fill={fill || '#9D0D11'}
      />
    </svg>
  );
};

export default IconVideo;
