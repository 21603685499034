import { DisconnectLinkedinMeta, DisconnectLinkedinPayload } from 'types/store/DisconnectLinkedin';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DISCONNECT_LINKEDIN_NS';

const asyncAction = createAsyncAction<DisconnectLinkedinMeta, DisconnectLinkedinPayload>(ns);

const disconnectLinkedinActions = { ...asyncAction };

export default disconnectLinkedinActions;
