import React from 'react';

import { SVGProps } from './SVG.props';

const EllipsisH = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  // Paste converted svg below
  // https://react-svgr.com/playground/?typescript=true
  return (
    <svg
      width={width || 12}
      height={height || 4}
      viewBox="0 0 12 4"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M6 .668a1.333 1.333 0 100 2.667A1.333 1.333 0 006 .668zm-4.667 0a1.333 1.333 0 100 2.667 1.333 1.333 0 000-2.667zm9.334 0a1.334 1.334 0 100 2.667 1.334 1.334 0 000-2.667z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default EllipsisH;
