import {
  GetAppbarInfoMeta,
  GetAppbarInfoPayload,
} from 'types/store/GetAppbarInfoState';
import { createAsyncReducer } from 'utils/Redux';

import { getAppbarInfoActions } from '../actions';

export default createAsyncReducer<GetAppbarInfoMeta, GetAppbarInfoPayload>(
  getAppbarInfoActions
);
