import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

export interface IAnalyticBrandAdvocatesMeta {
  time: string;
  communities: string[];
}

const brandAdvocatesNS = 'ANALYTIC_BRAND_ADVOCATES';

const getBrandAdvocatesAsync = createAsyncAction<
  IAnalyticBrandAdvocatesMeta,
  GenericResponse
>(brandAdvocatesNS);

export default getBrandAdvocatesAsync;
