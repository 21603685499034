import { Reducer } from 'redux';
import { FEEDBACK_CHECKING, RATING } from 'store/actions/rating.action';
import { Action } from 'types/Action';
import { EmployeeHubStore } from 'types/store/EmployeeHub';
import { RatingFeedBack } from 'types/store/RatingFeedBack';

const initState: RatingFeedBack = {
  isLoading: false,
  isOpen: false,
  isShownFeedBack: false
};

const RatingFeedBackReducer: Reducer<RatingFeedBack, Action> = (
  state = initState,
  { type, payload },
) => {
  switch (type) {
    case RATING.RATING_REQUEST: {
      return {
        ...state,
        isOpen: false,
        isLoading: true
      }
    }
    case RATING.RATTING_SUCCESS: {
      return {
        ...state,
        isOpen: true,
        isLoading: false
      }
    }
    case FEEDBACK_CHECKING.SUCCESS: {
      return {
        ...state,
        isShownFeedBack: payload
      }
    }
    case RATING.RATING_CLOSE: {
      return {
        ...state,
        isOpen: false,
      }
    }
    default: {
      return state;
    }
  }
};

export default RatingFeedBackReducer;
