import { put, call, takeLatest, select } from 'redux-saga/effects';
import { downloadResponsesVideoStory } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
    DownloadResponsesVideoStoryMeta,
    DownloadResponsesVideoStoryPayload,
} from 'types/store/DownloadResponsesVideoStoryState';
import { Store } from 'types/store/Store';

import { downloadResponsesVideoStoryActions } from '../actions';

function* downloadResponsesVideoStoryRequest(
    action: AsyncAction<DownloadResponsesVideoStoryMeta, DownloadResponsesVideoStoryPayload>
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            const { data } = yield call(
                downloadResponsesVideoStory,
                action.meta,
                state.auth.token
            );
            yield put(downloadResponsesVideoStoryActions.success(data));
        } catch (e: any) {
            yield put(downloadResponsesVideoStoryActions.failed(e.message));
        }
    } else {
        yield put(downloadResponsesVideoStoryActions.failed('Token not found'));
    }
}

// function* downloadResponsesSuccess(
//   action: AsyncAction<DownloadResponsesMeta, DownloadResponsesPayload>
// ) {
// }

function* downloadResponsesVideoStoryWatcher(): any {
    yield takeLatest(downloadResponsesVideoStoryActions.REQUEST, downloadResponsesVideoStoryRequest);
    // yield takeLatest(downloadResponsesActions.SUCCESS, downloadResponsesSuccess);
}

export default downloadResponsesVideoStoryWatcher;
