import pathOr from 'ramda/es/pathOr';
import { Action } from 'types/Action';
import {
  EmployeeHubState,
  EmployeeHubPayload,
} from 'types/store/EmployeeHubState';

import { employeeHubActions } from '../actions';

const DEFAULT_STATE: EmployeeHubState = {
  selectedStatuses: [],
  selectedCommunities: null,
  isStarAdvocate: false,
  page: 1,
  keyword: '',
  advocate_type: '',
  order_by: undefined,
  order_type: undefined,
  emails: [],
};

const DEFAULT_ACTION: Action<EmployeeHubPayload> = {
  type: '',
  payload: {},
};

export default (state = DEFAULT_STATE, action = DEFAULT_ACTION): any => {
  const localReducer = (): EmployeeHubPayload => {
    return {
      ...state,
      ...action.payload,
    };
  };
  return pathOr(state, [action.type], {
    [employeeHubActions.UPDATE]: localReducer(),
    [employeeHubActions.CLEAR]: DEFAULT_STATE,
  });
};
