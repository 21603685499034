import {
    ShowcasePageCheckUrlMeta,
    ShowcasePageCheckUrlPayload,
} from 'types/store/ShowcasePageCheckUrlState';
import { createAsyncReducer } from 'utils/Redux';

import { showcasePageCheckUrlActions } from '../actions';

export default createAsyncReducer<
    ShowcasePageCheckUrlMeta,
    ShowcasePageCheckUrlPayload
>(showcasePageCheckUrlActions);
