import { put, call, takeLatest, select } from 'redux-saga/effects';
import { fetchAverageTime } from 'services/analytics';
import { analyticAverageTimeActions } from 'store/actions';
import { IAnalyticAverageTimeMeta } from 'store/actions/analytics/averageTime';
import { AsyncAction } from 'types/Action';
import { GenericResponse } from 'types/GenericResponse';
import { Store } from 'types/store/Store';

function* getAverageTime(
  action: AsyncAction<IAnalyticAverageTimeMeta, GenericResponse>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        fetchAverageTime,
        action.meta,
        state.auth.token,
      );
      yield put(analyticAverageTimeActions.success(data));
    } catch (e: any) {
      yield put(analyticAverageTimeActions.failed(e.message));
    }
  }
}

function* analyticWatcher(): any {
  yield takeLatest(analyticAverageTimeActions.REQUEST, getAverageTime);
}

export default analyticWatcher;
