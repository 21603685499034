import React from 'react';

import { Action } from 'types/Action';
import { JobGenerateMeta, JobGeneratePayLoad } from 'types/store/JobsState';
import { createAsyncAction } from 'utils/Redux';

export type PayloadSync = {
  jobId: string;
  jobAdsId: string;
  provider: string;
  title?: string;
  language: {
    code: string;
  };
  jobAd?: {
    companyDescription: {
      title: string;
      text: string;
    };
    jobDescription: {
      title: string;
      text: string;
    };
    qualifications: {
      title: string;
      text: string;
    };
    additionalInformation: {
      title: string;
      text: string;
    };
    videos: {
      urls: string[];
    };
  };
};

export interface JobSync {
  id: string;
  title: string;
  createdOn: string;
  updatedOn: string;
  postingStatus: string;
  default: boolean;
  jobAd: JobAd;
  creator: Creator;
  language: Language;
  visibility: string;
  actions: Actions;
}

export interface JobAd {
  companyDescription: CompanyDescription;
  jobDescription: JobDescription;
  qualifications: Qualifications;
  additionalInformation: AdditionalInformation;
  videos: Videos;
}

export interface CompanyDescription {
  title: string;
  text: string;
}

export interface JobDescription {
  title: string;
  text: string;
}

export interface Qualifications {
  title: string;
  text: string;
}

export interface AdditionalInformation {
  title: string;
  text: string;
}

export interface Videos {
  urls: string[];
}

export interface Creator {
  id: string;
}

export interface Language {
  code: string;
  label: string;
  labelNative: string;
}

export interface Actions {
  postings: Postings;
}

export interface Postings {
  url: string;
  method: string;
}

const ns = 'JOB_GENERATE_REQUEST';
export enum GET_ALL_JOB_GENERATE {
  JOB_DETAIL = 'JOB_DETAIL',
  JOB_DESCRIPTION = 'JOB_DESCRIPTION',
  QUALIFICATIONS = 'QUALIFICATIONS',
  ADITIONAL = 'ADITIONAL',
  TITLE = 'TITLE',
  VIDEOS = 'VIDEOS',
  SYNC_VIDEO = 'SYNC_VIDEO',
}
export enum SYNC_TO_API {
  SYNC_TO_API_REQUEST = 'SYNC_TO_API_REQUEST',
  SYNC_TO_API_SUCCESS = 'SYNC_TO_API_SUCCESS',
  SYNC_TO_API_ERROR = 'SYNC_TO_API_ERROR',
}

export enum SYNC_TO_EDIT {
  SYNC_TO_EDIT_REQUEST = 'SYNC_TO_EDIT_REQUEST',
  SYNC_TO_EDIT_CLEAR = 'SYNC_TO_EDIT_CLEAR',
}

export enum JOB_GENERATE {
  JOB_GENERATE_CLEAR = 'JOB_GENERATE_CLEAR',
}

const asyncAction = createAsyncAction<JobGenerateMeta, JobGeneratePayLoad>(ns);

const generateJobAIContentActions = { ...asyncAction };

export const getJobDetail = (payload: string): Action => ({
  type: GET_ALL_JOB_GENERATE.JOB_DETAIL,
  payload,
});

export const getJobDescription = (payload: string): Action => ({
  type: GET_ALL_JOB_GENERATE.JOB_DESCRIPTION,
  payload,
});

export const getQualifications = (payload: string): Action => ({
  type: GET_ALL_JOB_GENERATE.QUALIFICATIONS,
  payload,
});

export const getAddditional = (payload: string): Action => ({
  type: GET_ALL_JOB_GENERATE.ADITIONAL,
  payload,
});

export const getTitle = (payload: string): Action => ({
  type: GET_ALL_JOB_GENERATE.TITLE,
  payload,
});

export const clearJobsState = (): any => ({
  type: JOB_GENERATE.JOB_GENERATE_CLEAR,
});

export const syncToAPI = (jobId: string, jobAdsId: string): Action => ({
  type: SYNC_TO_API.SYNC_TO_API_REQUEST,
  payload: { jobId, jobAdsId },
});

export const syncJob = (payload: JobSync): Action => ({
  type: SYNC_TO_EDIT.SYNC_TO_EDIT_REQUEST,
  payload: payload,
});

export const getVideos = (payload: {
  value: string;
  index?: number;
}): Action => ({
  type: GET_ALL_JOB_GENERATE.VIDEOS,
  payload,
});

export const syncVideo = (payload: string[]): Action => ({
  type: GET_ALL_JOB_GENERATE.SYNC_VIDEO,
  payload,
});

export default generateJobAIContentActions;
