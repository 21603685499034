import {
    SubmitVideoCommentMeta,
    SubmitVideoCommentPayload,
  } from 'types/store/SubmitVideoCommentState';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'SUBMIT_VIDEO_COMMENT';
  
  const asyncAction = createAsyncAction<
  SubmitVideoCommentMeta,
  SubmitVideoCommentPayload
  >(ns);
  
  const submitVideoCommentActions = {
    ...asyncAction,
  };
  
  export default submitVideoCommentActions;
  