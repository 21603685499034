import { UpdateStoryForBuilderMeta, UpdateStoryForBuilderPayload } from 'types/store/UpdateStoryForBuilder';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_STORY_FOR_BUILDER_NS';

const asyncAction = createAsyncAction<UpdateStoryForBuilderMeta, UpdateStoryForBuilderPayload>(ns);

const updateStoryForBuilderActions = { ...asyncAction };

export default updateStoryForBuilderActions;
