import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getStoriesByUserId } from 'services/story';
import { AsyncAction } from 'types/Action';
import {
  GetStoriesByUserIdMeta,
  GetStoriesByUserIdPayload,
} from 'types/store/GetStoriesByUserId';
import { Store } from 'types/store/Store';

import { getStoriesByUserIdActions } from '../actions';

function* getStoriesByUserIdRequest(
  action: AsyncAction<GetStoriesByUserIdMeta, GetStoriesByUserIdPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      // company id already embeded in the token
      const { data } = yield call(
        getStoriesByUserId,
        action.meta,
        state.auth.token
      );
      yield put(getStoriesByUserIdActions.success(data));
    } catch (e: any) {
      yield put(getStoriesByUserIdActions.failed(e.message));
    }
  } else {
    yield put(getStoriesByUserIdActions.failed('Token not found'));
  }
}

function* getStoriesByUserIdSuccess(
  action: AsyncAction<GetStoriesByUserIdMeta, GetStoriesByUserIdPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getStoriesByUserIdWatcher(): any {
  yield takeLatest(
    getStoriesByUserIdActions.REQUEST,
    getStoriesByUserIdRequest
  );
  yield takeLatest(
    getStoriesByUserIdActions.SUCCESS,
    getStoriesByUserIdSuccess
  );
}

export default getStoriesByUserIdWatcher;
