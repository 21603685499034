import { UpdateSmsTemplateMeta, UpdateSmsTemplatePayload } from 'types/store/UpdateSmsTemplate';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_SMS_TEMPLATE_NS';

const asyncAction = createAsyncAction<UpdateSmsTemplateMeta, UpdateSmsTemplatePayload>(ns);

const updateSmsTemplateActions = { ...asyncAction };

export default updateSmsTemplateActions;
