import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getOwnPageDetails } from 'services/showcase';
import { AsyncAction } from 'types/Action';
import {
    GetOwnPageDetailsMeta,
    GetOwnPageDetailsPayload
} from 'types/store/getOwnPageDetailsState';
import { Store } from 'types/store/Store';

import { getOwnPageDetailsActions } from '../actions';

function* getOwnPageDetailsRequest(
    action: AsyncAction<GetOwnPageDetailsMeta, GetOwnPageDetailsPayload>
) {
    const state: Store = yield select();
    if (state.auth.token) {
        try {
            const { data } = yield call(
                getOwnPageDetails,
                action.meta,
                state.auth.token
            );
            yield put(getOwnPageDetailsActions.success(data));
        } catch (e: any) {
            yield put(getOwnPageDetailsActions.failed(e.message));
        }
    } else {
        yield put(getOwnPageDetailsActions.failed('Token not found'));
    }
}


function* getOwnPageDetailsWatcher(): any {
    yield takeLatest(
        getOwnPageDetailsActions.REQUEST,
        getOwnPageDetailsRequest
    );
    yield takeLatest(
        getOwnPageDetailsActions.SUCCESS,
        getOwnPageDetailsRequest
    );
}

export default getOwnPageDetailsWatcher;
