import { GetOnboardMeta, GetOnboardPayload } from 'types/store/GetOnboardState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_ON_BOARD_NS';

const asyncAction = createAsyncAction<GetOnboardMeta, GetOnboardPayload>(ns);

const getOnboardActions = { ...asyncAction };

export default getOnboardActions;
