import { put, call, takeLatest, select } from 'redux-saga/effects';
import { advocateBulkCall } from 'services/advocate';
import { AsyncAction } from 'types/Action';
import {
  AdvocateBulkCallMeta,
  AdvocateBulkCallPayload,
} from 'types/store/AdvocateBulkCall';
import { Store } from 'types/store/Store';
import { AxiosError } from 'axios';

import { advocateBulkCallActions, openToast } from '../actions';

function* advocateBulkCallRequest(
  action: AsyncAction<AdvocateBulkCallMeta, AdvocateBulkCallPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        advocateBulkCall,
        action.meta,
        state.auth.token,
      );
      yield put(advocateBulkCallActions.success(data));
    } catch (e: any) {
      if (!e.response) {
        yield put(
          openToast('Please try again later!', 'error', 'Something went wrong'),
        );
      } else yield put(advocateBulkCallActions.failed(e.message));
    }
  }
}

function* advocateBulkCallSuccess(
  action: AsyncAction<AdvocateBulkCallMeta, AdvocateBulkCallPayload>,
) {
  //
}

function* advocateBulkCallWatcher(): any {
  yield takeLatest(advocateBulkCallActions.REQUEST, advocateBulkCallRequest);
  yield takeLatest(advocateBulkCallActions.SUCCESS, advocateBulkCallSuccess);
}

export default advocateBulkCallWatcher;
