import {
  UpdateFeedbackPositionMeta,
  UpdateFeedbackPositionPayload
} from 'types/store/UpdateFeedbackPosition';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_FEEDBACK_POSITION';

const asyncAction = createAsyncAction<
  UpdateFeedbackPositionMeta,
  UpdateFeedbackPositionPayload
>(ns);

const updateFeedbackPositionActions = {
  ...asyncAction,
};

export default updateFeedbackPositionActions;
