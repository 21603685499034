import axios from 'axios';
import { API } from 'consts';
import { UpdateRevisionApprovalMeta } from 'types/store/UpdateRevisionApprovalState';
import { UploadApprovalStoryMeta } from 'types/store/UploadApprovalStoryState';
import { UpsertApproversStateMeta } from 'types/store/UpsertApproversState';

import { uploadFile } from './upload';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const APPROVERS_URL = `${BASE_URL}/approver`;

export const getApprovers = (
  token: string
): any => {
  return axios({
    method: 'get',
    url: `${APPROVERS_URL}/getApprovers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const upsertApprovers = (
  data: UpsertApproversStateMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${APPROVERS_URL}/upsertApprovers`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const updateRevisionApproval = (
  data: UpdateRevisionApprovalMeta,
  token: string
): any => {
  return axios({
    method: 'post',
    url: `${APPROVERS_URL}/updateStatus`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  });
};

export const uploadApprovalStory = async (
  data: UploadApprovalStoryMeta,
  token: string
): Promise<any> => {
  const params: any = { ...data };
  const fd = new FormData();

  if (data.story !== undefined) {
    if (data.storyType === 'VIDEO') {
      const publicUrl = await uploadFile({
        file: data.story,
        token,
        customFileName: `${Math.random().toString(36).substring(7)}-${data.story.name}`.replace(/\s+/g, '')
      });

      params['fileLink'] = publicUrl;
      delete params.story;
    } else {
      fd.append('story', data.story);
    }
  }

  fd.append('data', JSON.stringify(params));

  return axios({
    method: 'post',
    url: `${APPROVERS_URL}/uploadApprovalStory`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: fd,
  });
};
