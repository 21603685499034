import React from 'react';

import { SVGProps } from './SVG.props';

const Up = (props: SVGProps): JSX.Element => {
  const { width = 10, height = 7, fill, rotate, style } = props;
  const transform = rotate ? { transform: `rotate(${rotate}deg)` } : {};
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 7"
      fill="none"
      style={{ ...transform, ...style }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.16397 4.95086L5.43789 0.603858C5.08784 0.195469 4.47299 0.148174 4.06459 0.498222C4.02673 0.530678 3.99141 0.565994 3.95895 0.603858L0.232878 4.95086C-0.117178 5.35925 -0.0698815 5.97408 0.338516 6.32413C0.515036 6.47543 0.739858 6.55859 0.972348 6.55859L8.4245 6.55859C8.96239 6.55859 9.39844 6.12256 9.39844 5.58467C9.39844 5.35219 9.31527 5.12737 9.16397 4.95086Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default Up;
