import React from 'react';

import { SVGProps } from './SVG.props';

const SERemove = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;

  return (
    <svg width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="multiply-incircle">
        <g id="line">
          <path d="M4.86193 4.86193C5.12228 4.60158 5.54439 4.60158 5.80474 4.86193L7.99997 7.05716L10.1953 4.86187C10.4556 4.60152 10.8777 4.60152 11.1381 4.86187C11.3984 5.12222 11.3984 5.54433 11.1381 5.80468L8.94278 7.99997L11.1381 10.1953C11.3984 10.4556 11.3984 10.8777 11.1381 11.1381C10.8777 11.3984 10.4556 11.3984 10.1953 11.1381L7.99997 8.94278L5.80474 11.138C5.54439 11.3984 5.12228 11.3984 4.86193 11.138C4.60158 10.8777 4.60158 10.4556 4.86193 10.1952L7.05716 7.99997L4.86193 5.80474C4.60158 5.54439 4.60158 5.12228 4.86193 4.86193Z" fill={fill || "#060548"} />
          <path fillRule="evenodd" clipRule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8 14.6667C11.6819 14.6667 14.6667 11.6819 14.6667 8C14.6667 4.3181 11.6819 1.33333 8 1.33333C4.3181 1.33333 1.33333 4.3181 1.33333 8C1.33333 11.6819 4.3181 14.6667 8 14.6667Z" fill={fill || "#060548"} />
        </g>
      </g>
    </svg>
  );
};

export default SERemove;



