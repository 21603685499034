import { GenericResponse } from 'types/GenericResponse';
import { createAsyncReducer } from 'utils/Redux';

import suggestionAdvocatesActions, {
  ISuggestionAdvocatesMeta,
} from '../../actions/contentPlanner/suggestionAdvocates';

export default createAsyncReducer<ISuggestionAdvocatesMeta, GenericResponse>(
  suggestionAdvocatesActions,
);
