import {
    UploadFinalStoryMeta,
    UploadFinalStoryPayload,
  } from 'types/store/UploadFinalStoryState';
  import { createAsyncAction } from 'utils/Redux';
  
  const ns = 'UPLOAD_FINAL_STORY';
  const asyncAction = createAsyncAction<
    UploadFinalStoryMeta,
    UploadFinalStoryPayload
  >(ns);
  
  const uploadFinalStoryActions = {
    ...asyncAction,
  };
  
  export default uploadFinalStoryActions;
  