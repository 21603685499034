import { GetLibraryStoriesMeta, GetLibraryStoriesPayload } from 'types/store/GetLibraryStories';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_LIBRARY_STORIES_NS';

const asyncAction = createAsyncAction<GetLibraryStoriesMeta, GetLibraryStoriesPayload>(ns);

const getLibraryStoriesActions = { ...asyncAction };

export default getLibraryStoriesActions;
