import { DiscussionStoryDetailsMeta, DiscussionStoryDetailsPayload } from 'types/store/DiscussionStoryDetails';
import { createAsyncAction, createUpdateAction } from 'utils/Redux';

const ns = 'DISCUSSION_STORY_DETAILS_NS';

const asyncAction = createAsyncAction<DiscussionStoryDetailsMeta, DiscussionStoryDetailsPayload>(ns);

const discussionStoryDetailsActions = { ...asyncAction };

export default discussionStoryDetailsActions;
