import {
    UploadFinalStoryMeta,
    UploadFinalStoryPayload,
  } from 'types/store/UploadFinalStoryState';
  import { createAsyncReducer } from 'utils/Redux';
  
  import { uploadFinalStoryActions } from '../actions';
  
  export default createAsyncReducer<
  UploadFinalStoryMeta,
  UploadFinalStoryPayload
  >(uploadFinalStoryActions);
  