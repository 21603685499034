import { CountDashboardStoriesMeta, CountDashboardStoriesPayload } from 'types/store/CountDashboardStories';
import { createAsyncAction } from 'utils/Redux';

const ns = 'COUNT_DASHBOARD_STORIES_NS';

const asyncAction = createAsyncAction<CountDashboardStoriesMeta, CountDashboardStoriesPayload>(ns);

const countDashboardStoriesActions = { ...asyncAction };

export default countDashboardStoriesActions;
