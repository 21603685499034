import {
  DownloadResponsesMeta,
  DownloadResponsesPayload,
} from 'types/store/DownloadResponsesState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'DOWNLOAD_RESPONSES';
const asyncAction = createAsyncAction<
  DownloadResponsesMeta,
  DownloadResponsesPayload
>(ns);

const downloadResponsesActions = {
  ...asyncAction,
};

export default downloadResponsesActions;
