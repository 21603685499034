import { LoadDocxByFinalStoryMeta, LoadDocxByFinalStoryPayload } from 'types/store/LoadDocxByFinalStory';
import { createAsyncAction } from 'utils/Redux';

const ns = 'LOAD_DOCX_BY_FINAL_STORY';

const asyncAction = createAsyncAction<LoadDocxByFinalStoryMeta, LoadDocxByFinalStoryPayload>(ns);

const loadDocxByFinalStoryActions = { ...asyncAction };

export default loadDocxByFinalStoryActions;
