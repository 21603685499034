import * as React from 'react';

function IconList(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  const { width, height, fill } = props;

  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill={fill || 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.33398 5C2.88627 5 3.33398 4.55228 3.33398 4C3.33398 3.44772 2.88627 3 2.33398 3C1.7817 3 1.33398 3.44772 1.33398 4C1.33398 4.55228 1.7817 5 2.33398 5Z"
        fill={fill || '#070550'}
      />
      <path
        d="M5.33398 3.33333C4.96579 3.33333 4.66732 3.63181 4.66732 4C4.66732 4.36819 4.96579 4.66667 5.33398 4.66667L14.0007 4.66667C14.3688 4.66667 14.6673 4.36819 14.6673 4C14.6673 3.63181 14.3688 3.33333 14.0007 3.33333L5.33398 3.33333Z"
        fill={fill || '#070550'}
      />
      <path
        d="M5.33398 7.33333C4.96579 7.33333 4.66732 7.63181 4.66732 8C4.66732 8.36819 4.96579 8.66667 5.33398 8.66667L14.0007 8.66667C14.3688 8.66667 14.6673 8.36819 14.6673 8C14.6673 7.63181 14.3688 7.33333 14.0007 7.33333L5.33398 7.33333Z"
        fill={fill || '#070550'}
      />
      <path
        d="M4.66732 12C4.66732 11.6318 4.96579 11.3333 5.33398 11.3333H14.0007C14.3688 11.3333 14.6673 11.6318 14.6673 12C14.6673 12.3682 14.3688 12.6667 14.0007 12.6667H5.33398C4.96579 12.6667 4.66732 12.3682 4.66732 12Z"
        fill={fill || '#070550'}
      />
      <path
        d="M3.33398 8C3.33398 8.55229 2.88627 9 2.33398 9C1.7817 9 1.33398 8.55229 1.33398 8C1.33398 7.44772 1.7817 7 2.33398 7C2.88627 7 3.33398 7.44772 3.33398 8Z"
        fill={fill || '#070550'}
      />
      <path
        d="M2.33398 13C2.88627 13 3.33398 12.5523 3.33398 12C3.33398 11.4477 2.88627 11 2.33398 11C1.7817 11 1.33398 11.4477 1.33398 12C1.33398 12.5523 1.7817 13 2.33398 13Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
}

export default IconList;
