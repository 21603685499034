import {
  CheckColleagueEmailsMeta,
  CheckColleagueEmailsPayload,
} from 'types/store/CheckColleagueEmails';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CHECK_COLLEAGUE_EMAILS';

const asyncAction = createAsyncAction<
  CheckColleagueEmailsMeta,
  CheckColleagueEmailsPayload
>(ns);

const checkColleagueEmailsActions = {
  ...asyncAction,
};

export default checkColleagueEmailsActions;
