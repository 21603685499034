import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
  getRecipe,
  getAllRecipeDetail,
  createRecipeDetail,
  updateRecipeDetail,
  createRecipe,
} from 'services/recipe';
import { AsyncAction } from 'types/Action';
import {
  GetAllContentPlannerRecipeDetailsMeta,
  GetAllContentPlannerRecipeDetailsPayload,
  GetContentPlannerRecipeMeta,
  GetContentPlannerRecipePayload,
  EditPlanMeta,
  EditPlanPayload,
  CreateRecipeMeta,
  CreateRecipePayload,
  MovingPlanMeta,
} from 'types/store/ContentPlanner';
import { Store } from 'types/store/Store';

import {
  getContentPlannerRecipesActions,
  editPlanActions,
  getAllContentPlannerRecipeDetailsActions,
  createRecipeActions,
  movingPlanActions,
  editRecipeActions,
} from '../actions';
import { openToast } from '../actions/toast.action';

function* getAllContentPlannerRecipeDetailsRequest(
  action: AsyncAction<
    GetAllContentPlannerRecipeDetailsMeta,
    GetAllContentPlannerRecipeDetailsPayload
  >,
): any {
  const state: Store = yield select();
  // if (state.auth.token) {
  try {
    const { data } = yield call(
      getAllRecipeDetail,
      action.meta,
      !action.meta.isShare ? state.auth.token || '' : '',
    );
    yield put(getAllContentPlannerRecipeDetailsActions.success(data.data));
  } catch (e: any) {
    console.log(e);
    yield put(getAllContentPlannerRecipeDetailsActions.failed(e));
  }
  // }
}

function* getAllContentPlannerRecipe(
  action: AsyncAction<
    GetContentPlannerRecipeMeta,
    GetContentPlannerRecipePayload
  >,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getRecipe, action.meta, state.auth.token);
      yield put(getContentPlannerRecipesActions.success(data.data));
    } catch (e: any) {
      yield put(getContentPlannerRecipesActions.failed(e));
    }
  }
}

function* editPlanRequest(action: AsyncAction<EditPlanMeta, EditPlanPayload>) {
  const state: Store = yield select();
  if (state.auth.token) {
    delete action.meta.type;

    const requestData = { ...action.meta };
    try {
      let responseData: any = null;
      if (requestData.id) {
        const { data } = yield call(
          updateRecipeDetail,
          requestData,
          requestData.id,
          state.auth.token,
        );
        responseData = data.data ? { ...data.data } : { ...action.meta };
      } else {
        const { data } = yield call(
          createRecipeDetail,
          requestData,
          state.auth.token,
        );
        responseData = data.data ? { ...data.data } : { ...action.meta };
      }
      const currentKeyRecipes = [...state.contentPlanner.recipes.data];
      const editedItemIndex = currentKeyRecipes.findIndex(
        (_: any) => _.id === responseData.recipe_id,
      );
      if (editedItemIndex > -1) {
        const editedRecipeDetail =
          currentKeyRecipes[editedItemIndex].recipe_details || [];
        const editedItemRecipeDetailIndex = editedRecipeDetail.findIndex(
          (_: any) => _.id === responseData.id,
        );
        if (editedItemRecipeDetailIndex > -1) {
          currentKeyRecipes[editedItemIndex].recipe_details[
            editedItemRecipeDetailIndex
          ] = {
            ...currentKeyRecipes[editedItemIndex].recipe_details[
              editedItemRecipeDetailIndex
            ],
            ...requestData,
            ...responseData,
          };
        } else {
          currentKeyRecipes[editedItemIndex].recipe_details.push({
            ...requestData,
            ...responseData,
          });
        }
      }
      if (!requestData.is_active) {
        const calendarEvents = state.contentPlanner.calendarEvents.data;
        yield put(
          getAllContentPlannerRecipeDetailsActions.success(
            calendarEvents.filter((_: any) => _.id !== requestData.id),
          ),
        );
      }
      yield put(editPlanActions.success(currentKeyRecipes));
    } catch (e: any) {
      yield put(editPlanActions.failed(e.message));
    }
  }
}

function* editRecipeDetails(
  action: AsyncAction<EditPlanMeta, EditPlanPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    delete action.meta.type;

    const requestData = { ...action.meta };
    try {
      const { data } = yield call(
        updateRecipeDetail,
        requestData,
        requestData.id,
        state.auth.token,
      );

      yield put(editPlanActions.success(data));
      yield put(openToast('Edit Recipe successfully', 'success'));
    } catch (e: any) {
      yield put(editPlanActions.failed(e.message));
      yield put(openToast('Error on edit recipe', 'error'));
    }
  }
}

function* movingPlanRequest(
  action: AsyncAction<MovingPlanMeta, EditPlanPayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    const {
      selectedYear,
      id,
      from_date,
      is_active,
      recipe_id,
      selectedContentPackageId,
    } = action.meta;

    const requestData = { ...action.meta };
    try {
      const events = state.contentPlanner.calendarEvents.data;
      const event = events.find((e) => e.id === id);
      if (event) {
        event.from_date = from_date;
        event.updated_at = new Date().toISOString();
        yield put(
          movingPlanActions.success({
            ...state.contentPlanner.calendarEvents,
            data: events,
          }),
        );
      }

      yield call(
        updateRecipeDetail,
        { id, recipe_id, is_active, from_date },
        requestData.id,
        state.auth.token,
      );

      yield put(
        getAllContentPlannerRecipeDetailsActions.request({
          year: selectedYear,
          contentPackageId: selectedContentPackageId,
        }),
      );
    } catch (e: any) {
      yield put(
        openToast(e.response?.data?.message || e.message, 'error', 'Error'),
      );
      yield put(
        getAllContentPlannerRecipeDetailsActions.request({
          year: selectedYear,
          contentPackageId: selectedContentPackageId,
        }),
      );
      yield put(editPlanActions.failed(e.message));
    }
  }
}

function* createRecipeRequest(
  action: AsyncAction<CreateRecipeMeta, CreateRecipePayload>,
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(createRecipe, action.meta, state.auth.token);
      const { recipes } = state.contentPlanner;
      yield put(createRecipeActions.success([...recipes.data, data.data]));
    } catch (e: any) {
      yield put(createRecipeActions.failed(e));
    }
  }
}

function* contentPlannerWatcher(): any {
  yield takeLatest(
    getAllContentPlannerRecipeDetailsActions.REQUEST,
    getAllContentPlannerRecipeDetailsRequest,
  );
  yield takeLatest(
    getContentPlannerRecipesActions.REQUEST,
    getAllContentPlannerRecipe,
  );
  yield takeLatest(editPlanActions.REQUEST, editPlanRequest);
  yield takeLatest(createRecipeActions.REQUEST, createRecipeRequest);
  yield takeLatest(movingPlanActions.REQUEST, movingPlanRequest);
  yield takeLatest(editRecipeActions.REQUEST, editRecipeDetails);
}

export default contentPlannerWatcher;
