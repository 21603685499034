import { GenericResponse } from 'types/GenericResponse';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_STORY_COST';

const asyncAction = createAsyncAction<any, GenericResponse>(ns);

const getStoryCostActions = {
  ...asyncAction,
};

export default getStoryCostActions;
