import React from 'react';

import { SVGProps } from './SVG.props';

const CurlyLine = (props: SVGProps): JSX.Element => {
  const { height, width } = props;
  return (
    <svg
      width={202 || width}
      height={116 || height}
      viewBox="0 0 202 116"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.5}
        d="M4 101.473c40.5 28.5 51.5-10.5 51.5-10.5s11.2-43.635 50.844-23.182c59.745 30.823 90.921-63.028 90.921-63.028"
        stroke="#DED9DB"
        strokeWidth={8}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CurlyLine;
