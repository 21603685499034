import {
  GetShowcaseArticlesByUrlMeta,
  GetShowcaseArticlesByUrlPayload,
} from 'types/store/GetShowcaseArticlesByUrlState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'GET_SHOWCASE_ARTICLES_BY_URL';
const asyncAction = createAsyncAction<
  GetShowcaseArticlesByUrlMeta,
  GetShowcaseArticlesByUrlPayload
>(ns);

const getShowcaseArticlesByUrlActions = {
  ...asyncAction,
};

export default getShowcaseArticlesByUrlActions;
