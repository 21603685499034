import { put, call, takeLatest, select } from 'redux-saga/effects';
import { getTopics } from 'services/interest';
import { AsyncAction } from 'types/Action';
import {
  GetAdvocateInterestMeta,
  GetAdvocateInterestPayload,
} from 'types/store/GetAdvocateInterest';
import { Store } from 'types/store/Store';

import { getAdvocateInterestActions } from '../actions';

function* getAdvocateInterestRequest(
  action: AsyncAction<GetAdvocateInterestMeta, GetAdvocateInterestPayload>
) {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getTopics, action.meta, state.auth.token);
      yield put(getAdvocateInterestActions.success(data));
    } catch (e: any) {
      yield put(getAdvocateInterestActions.failed(e.message));
    }
  } else {
    yield put(getAdvocateInterestActions.failed('Token not found'));
  }
}

function* getAdvocateInterestSuccess(
  action: AsyncAction<GetAdvocateInterestMeta, GetAdvocateInterestPayload>
) {
  // yield put(push(EMPLOYER_ROUTES.LOGIN));
}

function* getAdvocateInterestWatcher(): any {
  yield takeLatest(
    getAdvocateInterestActions.REQUEST,
    getAdvocateInterestRequest
  );
  yield takeLatest(
    getAdvocateInterestActions.SUCCESS,
    getAdvocateInterestSuccess
  );
}

export default getAdvocateInterestWatcher;
