import React from 'react';

import { SVGProps } from './SVG.props';

const CheckDone = (props: SVGProps): JSX.Element => {
  const { width, height, fill } = props;
  return (
    <svg {...props} width={width || 16} height={height || 16} viewBox="0 0 16 16" fill="none">
      <path
        d="M12.4749 4.80657C12.413 4.74409 12.3392 4.69449 12.258 4.66065C12.1767 4.6268 12.0896 4.60938 12.0016 4.60938C11.9136 4.60938 11.8264 4.6268 11.7452 4.66065C11.664 4.69449 11.5902 4.74409 11.5283 4.80657L6.56159 9.77991L4.47493 7.68657C4.41058 7.62441 4.33462 7.57554 4.25138 7.54274C4.16815 7.50993 4.07926 7.49385 3.98981 7.49539C3.90036 7.49694 3.81208 7.51609 3.73003 7.55176C3.64798 7.58742 3.57375 7.63889 3.51159 7.70324C3.44944 7.76759 3.40056 7.84355 3.36776 7.92679C3.33495 8.01002 3.31887 8.09891 3.32041 8.18836C3.32196 8.27781 3.34111 8.36609 3.37678 8.44814C3.41244 8.53019 3.46391 8.60441 3.52826 8.66657L6.08826 11.2266C6.15024 11.2891 6.22397 11.3387 6.30521 11.3725C6.38645 11.4063 6.47359 11.4238 6.56159 11.4238C6.6496 11.4238 6.73674 11.4063 6.81798 11.3725C6.89922 11.3387 6.97295 11.2891 7.03493 11.2266L12.4749 5.78657C12.5426 5.72415 12.5966 5.64838 12.6335 5.56404C12.6705 5.47971 12.6895 5.38864 12.6895 5.29657C12.6895 5.20451 12.6705 5.11344 12.6335 5.0291C12.5966 4.94477 12.5426 4.869 12.4749 4.80657V4.80657Z"
        fill={fill || '#070550'}
      />
    </svg>
  );
};

export default CheckDone;
