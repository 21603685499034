import {
  UpdateFinalStoryMeta,
  UpdateFinalStoryPayload,
} from 'types/store/UpdateFinalStoryState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'UPDATE_FINAL_STORY';
const asyncAction = createAsyncAction<
  UpdateFinalStoryMeta,
  UpdateFinalStoryPayload
>(ns);

const updateFinalStoryActions = {
  ...asyncAction,
};

export default updateFinalStoryActions;
