import {
  CreateRevisionMeta,
  CreateRevisionPayload,
} from 'types/store/CreateRevisionState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_REVISION';
const asyncAction = createAsyncAction<
  CreateRevisionMeta,
  CreateRevisionPayload
>(ns);

const createRevisionActions = {
  ...asyncAction,
};

export default createRevisionActions;
