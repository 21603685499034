import Typography from 'components/base/Typography';
import FormikTextField from 'components/module/FormikTextField';
import { Col, Row } from 'react-grid-system';
import styled from 'utils/styled';

import FormikTextArea from '../FormikTextArea';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  margin-bottom: 20px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    .smallInput {
      input {
        max-width: 100px;
      }
    }
  }
`;

export const Container = styled.div`
  padding: 64px 90px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 30px;
  background-color: #fff;
  border-radius: 0 0 4px 4px;
  background-color: ${({ theme }) => theme.grey.shade1};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 50px;
  }

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    flex-direction: column;
  }
`;

export const ButtonContainer = styled.div`
  padding-top: 30px;
  padding-bottom: 56px;
`;

export const Header = styled.div`
  position: relative;
  padding: 90px;
  background: ${({ theme }) => theme.brand.primary};
  border-radius: 4px 4px 0 0;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 96px 112px;
  }
`;

export const Title = styled(Typography)`
  margin-bottom: 16px;
`;

export const Description = styled(Typography)`
  margin-bottom: 32px;
`;

export const ExitButton = styled.button`
  position: absolute;
  top: 24px;
  left: 56px;
  background: ${({ theme }) => theme.grey.noshade};
  box-shadow: 0px 12px 24px rgba(41, 43, 50, 0.25);
  border-radius: 50%;
  height: 64px;
  width: 64px;
  border: none;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  :focus {
    outline: none;
  }
  z-index: 2;
`;

export const MainContainer = styled.div`
  grid-column: span 3;

  svg {
    width: 100%;
  }
`;

export const RightContainer = styled.div`
  grid-column: span 2;
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 32px;
  margin-top: 8px;
  margin-bottom: 8px;

  border: 1px solid ${({ theme }) => theme.grey.shade4};
  box-sizing: border-box;
  border-radius: 8px;
`;

export const Icons = styled.div`
  display: flex;
  position: absolute;
  top: 32px;
  right: 32px;
  align-items: center;
`;

export const CardIcon = styled.div`
  min-width: 48px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ theme }) => theme.grey.shade3};
  margin-left: 10px;
`;

export const TextAreaField = styled(FormikTextArea)`
  textarea {
    resize: none;
  }
`;

export const BasicField = styled(FormikTextField) <{
  fullWidth?: boolean;
}>`
  ${({ fullWidth }) => {
    if (fullWidth) {
      return `width: 100%;`;
    } else {
      return ``;
    }
  }}

  :not(:first-of-type) {
    margin-left: 20px;
  }
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 24px;

  div {
    width: 100%;
  }
`;

export const OrContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  hr {
    flex: 1;
    border: 1px solid ${({ theme }) => theme.grey.shade4};
  }

  p {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 23%;
`;

export const BrandingContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px 0;
  margin-top: 32px;
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export const AddImageContainer = styled.div`
  margin-top: 4px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;