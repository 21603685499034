import {
    CreateShowcaseMeta,
    CreateShowcasePayload,
} from 'types/store/CreateShowcaseState';
import { createAsyncAction } from 'utils/Redux';

const ns = 'CREATE_SHOWCASE';
const asyncAction = createAsyncAction<
    CreateShowcaseMeta,
    CreateShowcasePayload
>(ns);

const createShowcaseActions = {
    ...asyncAction,
};

export default createShowcaseActions;
