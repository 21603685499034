import axios, { AxiosPromise, AxiosResponse } from 'axios';
import { GenericResponse } from 'types/GenericResponse';
import { API } from 'consts';
import { widgetFilterParam } from 'types/store/Widget';

const BASE_URL = `${API.URL}/${API.VERSION}`;
const WIDGET_URL = `${BASE_URL}/widget`;

export const getWidgetByCompany = (
  data: widgetFilterParam,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${WIDGET_URL}/list-by-company/`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: data,
  });
};

export const createWidget = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${WIDGET_URL}/create`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const updateWidget = (
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  data: any,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'post',
    url: `${WIDGET_URL}/update`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  });
};

export const getWidgetById = (
  widgetId: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${WIDGET_URL}/list-by-id`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      widgetId,
    },
  });
};

export const getListContentById = (
  widgetId: string,
  token: string,
): AxiosPromise<GenericResponse> => {
  return axios({
    method: 'get',
    url: `${WIDGET_URL}/list-content-by-id`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params: {
      widgetId,
    },
  });
};
