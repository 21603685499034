import { SwitchOtherCompanyMeta, SwitchOtherCompanyMetaPayload } from 'types/store/SwitchOtherCompany';
import { createAsyncAction } from 'utils/Redux';

const ns = 'SWITCH_OTHERCOMPANY_NS';

const asyncAction = createAsyncAction<SwitchOtherCompanyMeta, SwitchOtherCompanyMetaPayload>(ns);

const switchOtherCompanyActions = { ...asyncAction };

export default switchOtherCompanyActions;
