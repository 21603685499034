export const APP_URL = {
  GOOGLE_PLAY:
    'https://play.google.com/store/apps/details?id=com.themartec.mobile',
  APP_STORE: 'https://apps.apple.com/au/app/the-martec/id1549620997',
};

export const API_GOOGLE_FONTS_URL =
  'https://www.googleapis.com/webfonts/v1/webfonts?sort=popularity&key=AIzaSyBDmXIS5vcJKwo3HhlOAu4tZ5IFyrDqVP8';

export {
  MAIN_ROUTES,
  EMPLOYER_ROUTES,
  ADVOCATE_ROUTES,
  COMPANY_ROUTES,
} from './routes';

export const isProd = process.env.REACT_APP_ENV === 'production'

export const API = {
  URL: process.env.REACT_APP_API_URL || 'http://localhost:3000',
  VERSION: process.env.REACT_APP_API_VERSION || 'v1',
};

export const PUBLISH_LINK_URL = process.env.REACT_APP_PUBLISH_URL || 'http://localhost:3000'

//Open app
export const DEEP_LINK_URL = process.env.DEEP_LINK_URL || 'themartec://localhost:3000';

// Integration
export const MS_CLOUD_INSTANCE = 'https://login.microsoftonline.com/';
