import {
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload,
} from 'types/store/GetStoriesByCompanyIdState';
import { createAsyncAction, createUpdateAction } from 'utils/Redux';

const ns = 'GET_STORIES_LIBRARY_COMPANY_ID';
const asyncAction = createAsyncAction<
  GetStoriesByCompanyIdMeta,
  GetStoriesByCompanyIdPayload
>(ns);

const updateAction = createUpdateAction(ns);

const getStoriesLibraryByCompanyIdActions = {
  ...asyncAction,
  ...updateAction
};

export default getStoriesLibraryByCompanyIdActions;
