import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getCheckAuthSmartRecruiter,
  getListJobAds,
  getListSmartRecuiter,
  getSRToken,
  getSmartRecuiter,
} from 'services/smart-recuiter';
import { AsyncAction } from 'types/Action';
import {
  GetCheckSmartRecruiterMeta,
  GetCheckSmartRecruiterPayload,
  GetListSmartRecruiterMeta,
  GetListSmartRecruiterPayload,
  GetSRTokenMeta,
  GetSRTokenPayload,
  GetSmartRecruiterMeta,
  GetSmartRecruiterPayload,
} from 'types/store/SmartRecruiter';
import { Store } from 'types/store/Store';
import { jobDescriptionSRDB } from 'indexDB';

import {
  getCheckAuthSmartRecruiterActions,
  getListSmartRecruiterActions,
  getSRTokenActions,
  getSmartRecruiterActions,
} from '../actions';

function* getListSmartRecruiterRequest(
  action: AsyncAction<GetListSmartRecruiterMeta, GetListSmartRecruiterPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(
        getListSmartRecuiter,
        action.meta,
        state.auth.token,
      );

      const result = [];
      const result2: Array<any> = [];
      const jobDescriptionContent = data.data.content || [];
      for (const job of jobDescriptionContent) {
        const responseAds = yield call(
          getListJobAds,
          {
            provider: action.meta.provider,
            mode: action.meta.mode,
            idAds: job.id,
          },
          state.auth.token,
        );
        const ads = responseAds.data.data;

        result2.push(
          ...[
            ...ads.map((_: any) => {
              return {
                ..._,
                job_id: job.id,
                job,
              };
            }),
          ],
        );
        job.ads = responseAds.data.data;
        result.push(job);
      }

      yield put(
        getListSmartRecruiterActions.success({ ...data.data, content: result }),
      );
    } catch (e: any) {
      console.log(e);
      yield put(getListSmartRecruiterActions.failed(e));
    }
  }
}

function* getCheckAuthSmartRecruiterRequest(
  action: AsyncAction<
    GetCheckSmartRecruiterMeta,
    GetCheckSmartRecruiterPayload
  >,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const { data } = yield call(getCheckAuthSmartRecruiter, state.auth.token);
      yield put(getCheckAuthSmartRecruiterActions.success(data.data));
    } catch (e: any) {
      console.log(e);
      yield put(getCheckAuthSmartRecruiterActions.failed(e));
    }
  }
}

function* getSRTokenRequest(
  action: AsyncAction<GetSRTokenMeta, GetSRTokenPayload>,
): any {
  const state: Store = yield select();
  if (state.auth.token) {
    try {
      const resp = yield call(getSRToken, state.auth.token, action.meta.code);
      if (resp?.data?.connected) yield put(getSRTokenActions.success());
      else yield put(getSRTokenActions.failed());
    } catch (err) {
      console.log(err);
      yield put(getSRTokenActions.failed(err));
    }
  }
}

function* smartRecruiterWatcher(): any {
  yield takeLatest(getSRTokenActions.REQUEST, getSRTokenRequest);
  yield takeLatest(
    getListSmartRecruiterActions.REQUEST,
    getListSmartRecruiterRequest,
  );
  yield takeLatest(
    getCheckAuthSmartRecruiterActions.REQUEST,
    getCheckAuthSmartRecruiterRequest,
  );
}

export default smartRecruiterWatcher;
